import styled from 'styled-components';

export const ContentSalaryTransaction = styled.div`
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: white;
      border: 1px solid #ced4da;
      padding-top: 9px;
      padding-bottom: 9px;

      span {
        color: #24292e;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      width: 100%;
    }
  }

  .show {
    .dropdown-toggle {
      background-color: white !important;
      border: 1px solid #ced4da !important;
    }
  }
`;
