import React from 'react';

const Companies = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99999 3.00004H10.5H10.4925C10.905 2.99254 11.2425 3.33004 11.2425 3.74254V14.9925H2.99249H2.98499C2.56499 14.985 2.23499 14.655 2.23499 14.235V3.73504C2.22749 3.31504 2.56499 2.97754 2.97749 2.97754L2.99999 3.00004Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 6.00018H15H14.9925C15.405 5.99268 15.7425 6.33018 15.7425 6.74268V14.2427C15.7425 14.6552 15.405 14.9927 14.9925 14.9927H11.2425V5.99268L11.25 6.00018Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24996 5.90649H5.24246C5.18996 5.90649 5.14496 5.94399 5.14496 5.99649C5.14496 6.04149 5.18246 6.08649 5.23496 6.08649C5.27996 6.07899 5.32496 6.04149 5.32496 5.98899C5.32496 5.93649 5.27996 5.89149 5.22746 5.89524"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24996 5.90649H8.24246C8.18996 5.90649 8.14496 5.94399 8.14496 5.99649C8.14496 6.04149 8.18246 6.08649 8.23496 6.08649C8.27996 6.07899 8.32496 6.04149 8.32496 5.98899C8.32496 5.93649 8.27996 5.89149 8.22746 5.89524"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24996 8.90236L5.24246 8.90161C5.18996 8.90161 5.14496 8.93911 5.14496 8.99161C5.14496 9.03661 5.18246 9.08161 5.23496 9.08161C5.27996 9.07411 5.32496 9.03661 5.32496 8.98411C5.32496 8.93161 5.27996 8.88661 5.22746 8.89036"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24996 8.90258H8.24246C8.18996 8.90258 8.14496 8.94008 8.14496 8.99258C8.14496 9.03758 8.18246 9.08258 8.23496 9.08258C8.27996 9.07508 8.32496 9.03758 8.32496 8.98508C8.32496 8.93258 8.27996 8.88758 8.22746 8.89133"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24996 11.9024L5.24246 11.9016C5.18996 11.9016 5.14496 11.9391 5.14496 11.9916C5.14496 12.0366 5.18246 12.0816 5.23496 12.0816C5.27996 12.0741 5.32496 12.0366 5.32496 11.9841C5.32496 11.9316 5.27996 11.8866 5.22746 11.8904"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24996 11.9024L8.24246 11.9016C8.18996 11.9016 8.14496 11.9391 8.14496 11.9916C8.14496 12.0366 8.18246 12.0816 8.23496 12.0816C8.27996 12.0741 8.32496 12.0366 8.32496 11.9841C8.32496 11.9316 8.27996 11.8866 8.22746 11.8904"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 8.90258H13.4925C13.44 8.90258 13.395 8.94008 13.395 8.99258C13.395 9.03758 13.4325 9.08258 13.485 9.08258C13.53 9.07508 13.575 9.03758 13.575 8.98508C13.575 8.93258 13.53 8.88758 13.4775 8.89133"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 11.9024L13.4925 11.9016C13.44 11.9016 13.395 11.9391 13.395 11.9916C13.395 12.0366 13.4325 12.0816 13.485 12.0816C13.53 12.0741 13.575 12.0366 13.575 11.9841C13.575 11.9316 13.53 11.8866 13.4775 11.8904"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Companies;
