import React from 'react';

const Groups = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.84 34.9999C19.4 36.5599 19.4 39.0799 17.84 40.6399C16.26 42.1999 13.74 42.1999 12.18 40.6399C10.6 39.0599 10.6 36.5399 12.178 34.9799C13.738 33.3999 16.258 33.3999 17.818 34.9799"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8 34.9999C37.36 36.5599 37.36 39.0799 35.8 40.6399C34.22 42.1999 31.7 42.1999 30.14 40.6399C28.56 39.0599 28.56 36.5399 30.14 34.9799C31.7 33.3999 34.22 33.3999 35.78 34.9799"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2799 17.9003C13.8399 19.4603 13.8399 21.9803 12.2799 23.5403C10.6999 25.1003 8.17994 25.1003 6.61994 23.5403C5.03994 21.9603 5.03994 19.4403 6.61794 17.8803C8.17794 16.3003 10.6979 16.3003 12.2579 17.8803"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.82 7.34002C28.38 8.90002 28.38 11.42 26.82 12.98C25.24 14.54 22.72 14.54 21.16 12.98C19.58 11.4 19.58 8.88002 21.16 7.32002C22.72 5.74002 25.24 5.74002 26.8 7.31802"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.3599 17.9003C42.9199 19.4603 42.9199 21.9803 41.3599 23.5403C39.7799 25.1003 37.2599 25.1003 35.6999 23.5403C34.1199 21.9603 34.1199 19.4403 35.6999 17.8803C37.2599 16.3003 39.7799 16.3003 41.3399 17.8803"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.82 22.04C28.38 23.6 28.38 26.12 26.82 27.68C25.24 29.24 22.72 29.24 21.16 27.68C19.58 26.1 19.58 23.58 21.16 22.02C22.72 20.44 25.24 20.44 26.8 22.02"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2799 28.1602L30.6999 34.5602"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7199 28.1602L17.2999 34.5402"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1799 23.8003L13.2799 21.8203"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 20.8797V14.1797"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8199 23.8003L34.7199 21.8203"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Groups;
