import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { AccountCircle } from '@material-ui/icons';
import { ContentSalaryTransaction } from './styles';
import { optionsTransaction } from '../../services/Constants/getDefaults';
import ListDropdown from "../Icons/ListDropdown";

const DropdownSalaryTransaction = ({ value, onSelectItem }) => {
  return (
    <ContentSalaryTransaction>
      <Dropdown className='clickLabel'>
        <Dropdown.Toggle>
          <span className="text-normal">
            {value?.label  || value || 'Select Type'}
          </span>
          <ListDropdown /> 
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {optionsTransaction.map((item, i) => (
            item.type === "startingSalary" 
            ? ( null )
            : (
            <Dropdown.Item
              key={`item-salary-transaction-${i}`}
              onClick={() => onSelectItem(item)}
            >
              {item.label}
            </Dropdown.Item> )
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </ContentSalaryTransaction>
  );
};

export default DropdownSalaryTransaction;
