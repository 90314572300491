import React from 'react';

const Settings = () => {
  return (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2499_71373)">
        <rect width="48" height="32" rx="6" fill="white" />
        <g clipPath="url(#clip1_2499_71373)">
          <path
            d="M25.0436 9.89483C25.3328 9.89483 25.5876 10.0739 25.6909 10.3493L26.0351 11.3202C26.0902 11.4716 26.1935 11.5956 26.3381 11.6782L27.371 12.2773C27.5087 12.353 27.6739 12.3805 27.8323 12.353L28.8514 12.1671C29.1337 12.112 29.4229 12.2428 29.5675 12.5004L30.1183 13.4506C30.2629 13.6985 30.2354 14.0152 30.0495 14.2355L29.3747 15.0205C29.2645 15.1445 29.2094 15.2959 29.2094 15.4612V16.6593C29.2094 16.8177 29.2645 16.976 29.3678 17.1L30.0357 17.8849C30.2216 18.1053 30.2492 18.422 30.1046 18.6699L29.5537 19.6201H29.553C29.4015 19.868 29.1192 19.9988 28.83 19.9506L27.811 19.7647V19.764C27.6457 19.7296 27.4804 19.7572 27.3427 19.8398L26.303 20.4388C26.1584 20.5146 26.0482 20.6454 25.9931 20.7969L25.642 21.7678C25.5387 22.0363 25.2839 22.2222 24.9878 22.2222H23.8861C23.59 22.2153 23.3353 22.0363 23.232 21.7609L22.8808 20.7831C22.8189 20.6247 22.7156 20.5008 22.571 20.4182L21.5326 19.8191C21.388 19.7365 21.2228 19.709 21.0644 19.7365L20.0453 19.9155C19.7561 19.9637 19.4669 19.8329 19.3223 19.5781L18.7715 18.6279C18.62 18.3731 18.6475 18.0564 18.8403 17.8361L19.5082 17.0442C19.6115 16.9134 19.6666 16.7619 19.6666 16.5966V15.3985C19.6597 15.2333 19.6046 15.0749 19.5013 14.951L18.8334 14.1591H18.8327C18.6399 13.9319 18.6124 13.6151 18.7639 13.3673L19.3147 12.417H19.3079C19.4525 12.1623 19.7348 12.0314 20.024 12.0838L21.043 12.2628C21.2014 12.2903 21.3667 12.2628 21.5044 12.1802L22.5372 11.5811C22.6749 11.4985 22.7851 11.3677 22.8402 11.2162L23.1845 10.2419V10.2391C23.2809 9.96368 23.5356 9.78466 23.8248 9.77777L25.0436 9.89483Z"
            stroke="#24292E"
            strokeWidth="1.125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.4927 14.1984C23.9905 14.1984 23.5089 14.3979 23.1538 14.753C22.7987 15.1081 22.5992 15.5897 22.5992 16.0919C22.5992 16.5941 22.7987 17.0758 23.1538 17.4309C23.5089 17.786 23.9905 17.9855 24.4927 17.9855C24.9949 17.9855 25.4766 17.786 25.8317 17.4309C26.1868 17.0758 26.3863 16.5941 26.3863 16.0919C26.3863 15.5897 26.1868 15.1081 25.8317 14.753C25.4766 14.3979 24.9949 14.1984 24.4927 14.1984V14.1984Z"
            stroke="#24292E"
            strokeWidth="1.125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2499_71373">
          <rect width="48" height="32" rx="6" fill="white" />
        </clipPath>
        <clipPath id="clip1_2499_71373">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Settings;
