import React, { useState, useEffect, useContext } from 'react';

import { useGetSignedUrlToPutS3Query } from '../../../graphql/container/query/files';
import { MAX_FILE_SIZE } from '../../../services/Constants/getDefaults';
import { AlertMessageContext } from '../../../Context/AlertMessageContext';

const UploadFile = ({
  children,
  inputRef,
  section,
  userId,
  onSuccess,
  setLoading,
  deleteData
}) => {
  const { setErrorMessage } = useContext(AlertMessageContext);
  const [fileLoad, setFileLoad] = useState();
  const [fileData, setFileData] = useState();
  const [
    getUrl,
    { data, loading: getSignedUrlLoading }
  ] = useGetSignedUrlToPutS3Query();

  useEffect(() => {
    setFileData(data);
  }, [data]);

  useEffect(() => {
    if (deleteData) {
      document.location.reload();
    }
  }, [deleteData, data]);

  useEffect(() => {
    if (fileData?.getSignedUrlToPuts3 && fileLoad && !getSignedUrlLoading) {
      async function fetchData() {
        try {
          await fetch(fileData?.getSignedUrlToPuts3, {
            method: 'PUT',
            body: fileLoad,
            headers: {
              'Content-Type': fileLoad.type
            }
          });
          onSuccess(fileData.getSignedUrlToPuts3?.split('?')[0], fileLoad.name);
          setFileLoad();
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [fileData]);

  const openFile = event => {
    if (event.target.files[0]?.size > MAX_FILE_SIZE) {
      setErrorMessage(`Doesn't allow load file larger than 20Mb`);
      event.target.value = null;
    } else {
      setFileLoad(event.target.files[0]);
      setLoading(true);
      getUrl({
        variables: {
          id: userId,
          contentType: event.target.files[0].type,
          name: event.target.files[0].name,
          section
        }
      });
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className="d-none"
        type="file"
        onChange={openFile}
      />
      {children}
    </>
  );
};

export default UploadFile;
