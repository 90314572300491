import gql from 'graphql-tag';

// {
//   id
//   status
//   type
//   message
//   daysOff
//   createdAt
//   updatedAt
//   startDate
//   endDate
//   employee {
//     id
//   }
//   abscence {
//     id
//     daysOff
//     dateTakenEnd
//     dateTakenStart
//   }
// }
export const CREATE_ABSCENCE_REQUEST_MUTATION = gql`
  mutation CREATE_ABSCENCE_REQUEST_MUTATION(
    $employeeId: String!
    $dateTakenStart: String!
    $dateTakenEnd: String!
    $type: TYPE_DAY_OFF!
    $message: String!
    $status: String!
    $emails: String!
    $idAbscenceRequest: String!
  ) {
    createOneAbscenceRequest(
      abscenceRequest: {
        employeeId: $employeeId
        startDate: $dateTakenStart
        endDate: $dateTakenEnd
        type: $type
        message: $message
        status: $status
        emails: $emails
        idAbscenceRequest: $idAbscenceRequest
      }
    )
  }
`;

export const CREATE_ME_ABSCENCE_REQUEST_MUTATION = gql`
  mutation CREATE_ME_ABSCENCE_REQUEST_MUTATION(
    $employeeId: String!
    $dateTakenStart: String!
    $dateTakenEnd: String!
    $type: TYPE_DAY_OFF!
    $message: String!
    $status: String!
    $emails: String!
    $idAbscenceRequest: String!
  ) {
    createMeAbscenceRequest(
      abscenceRequest: {
        employeeId: $employeeId
        startDate: $dateTakenStart
        endDate: $dateTakenEnd
        type: $type
        message: $message
        status: $status
        emails: $emails
        idAbscenceRequest: $idAbscenceRequest
      }
    )
  }
`;

export const UPDATE_ABSCENCE_REQUEST_MUTATION = gql`
  mutation UPDATE_ABSCENCE_REQUEST_MUTATION(
    $id: String!
    $status: String!
    $startDate: DateTime
    $endDate: DateTime
    $type: TYPE_DAY_OFF
    $message: String
    $isNewAbscence: Boolean
  ) {
    updateOneAbscenceRequest(
      where: { id: $id }
      status: $status
      startDate: $startDate
      endDate: $endDate
      type: $type
      message: $message
      isNewAbscence: $isNewAbscence
    ) {
      id
      status
      type
      message
      daysOff
      createdAt
      updatedAt
      startDate
      endDate
      employee {
        id
      }
      abscence {
        id
        daysOff
        dateTakenEnd
        dateTakenStart
        note
        type
        createdAt
        updatedAt
        abscenceRequest {
          id
          status
          type
          message
          daysOff
          startDate
          endDate
        }
      }
    }
  }
`;

export const ACTION_PTO_PENDING_MUTATION = gql`
  mutation ACTION_PTO_PENDING_MUTATION($id: String!, $status: String!) {
    actionPTOPending(where: { id: $id }, status: $status) {
      id
      status
      type
      message
      daysOff
      createdAt
      updatedAt
      startDate
      endDate
      employee {
        id
      }
      abscence {
        id
        daysOff
        dateTakenEnd
        dateTakenStart
        note
        type
        createdAt
        updatedAt
        abscenceRequest {
          id
          status
          type
          message
          daysOff
          startDate
          endDate
        }
      }
    }
  }
`;

export const DELETE_ABSCENCE_REQUEST_MUTATION = gql`
  mutation DELETE_ABSCENCE_REQUEST_MUTATION($id: String!) {
    deleteOneAbscenceRequest(where: { id: $id }) {
      id
    }
  }
`;

export const DELETE_PTO_MUTATION = gql`
  mutation DELETE_PTO_MUTATION(
    $PtoId: String!
    $AmountDays: String!
    $Type: String!
    $EmployeeId: String!
    $Status: String!
    $PtoCurrentYear: Boolean!
  ) {
    DeletePto(
      PtoId: $PtoId
      AmountDays: $AmountDays
      Type: $Type
      EmployeeId: $EmployeeId
      Status: $Status
      PtoCurrentYear: $PtoCurrentYear
    )
  }
`;
export const EDIT_PTO_MUTATION = gql`
  mutation EDIT_PTO_MUTATION(
    $ptoId: String!
    $startDate: String!
    $endDate: String!
    $message: String
    $amountDays: String!
    $employeeId: String!
    $type: String!
  ) {
    EditPto(
      amountDays: $amountDays
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
      message: $message
      ptoId: $ptoId
      type: $type
    )
  }
`;
export const REMINDER_ABSCENCE_REQUEST_MUTATION = gql`
  mutation REMINDER_ABSCENCE_REQUEST_MUTATION($id: String!) {
    reminderAbscenceRequest(where: { id: $id }) {
      id
    }
  }
`;
