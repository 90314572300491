import styled from 'styled-components';

export const ContentSalary = styled.div`
  margin: 15px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 5px;
  height: 579px;

  .salary-header {
    border-bottom: 1px solid #e1e4e8;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;

    .sub-heading {
      margin: 0;
    }

    .btn-danger {
      margin-left: 5px;
      span {
        margin-left: 3px;
      }
    }
  }

  .info-salary {
    height: 310px;
    overflow-y: auto;
    table {
      border: none;
      border-bottom: 1px solid #e1e4e8;
      thead {
        th {
          padding: 20px 20px;
        }
      }
      thead th:nth-child(1) {
        width: 19%;
      }

      thead th:nth-child(2) {
        width: 19%;
      }

      thead th:nth-child(3) {
        width: 19%;
      }

      thead th:nth-child(4) {
        width: 30%;
      }

      thead th:nth-child(5) {
        width: 10%;
      }
      tbody {
        td:nth-child(5) {
          padding: 10px 0 7px 60px;
          div {
            padding: 0px 0px 0px 12px
            border-left: 1px solid #959da5;
          }
        }
      }
    }
  }

  .salary-inputs {
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
     }

     input[type=number] { -moz-appearance:textfield; }

  
    > div {
      display: flex;
      flex-direction: column;
      padding: 0px 10px 0px 0px;

      spam {
        padding: 0px 0px 8px 0px;
        inner-calendar-salaryfont-weight: 600;
      }
    }

    > div:nth-child(1) {
      width: 20%;
      .rw-widget-picker {
        min-height: 32px;
      }
    }
    > div:nth-child(2) {
      width: 20%;

      .dropdown-toggle {
        height: 44px;
        align-items: center;
      }
      .btn-primary:focus {
        box-shadow: 0 0 0 0.1rem #444d56;
      }
    }

    > div:nth-child(3) {
      width: 20%;
      .input-group {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        span {
          background-color: #ffffff;
          border: none;
        }
        input {
          border: none;
          padding-left: 5px;
        }
        .form-control {
          box-shadow: none;
          height: 44px;
        }
      }
    }
    > div:nth-child(4) {
      width: 30%;
      .input-group {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        span {
          background-color: #ffffff;
          border: none;
        }

        input {
          border: none;
          padding-left: 5px;
        }
        .form-control {
          box-shadow: none;
          height: 44px;
        }
      }
    }
    > div:nth-child(5) {
      margin: 20px 0px 0px 0px;
      height: 44px;
      width: 120px;

      button {
        width: 100%;
      }

      .btn:disabled {
        background-color: #f6f8fa !important;
        color: #6a737d !important;
      }
    }


    .pto-calendar-fields {
      display: flex;

      span {
        margin-bottom: 0px;
      }

      & .input-group-text {
        border: 1px solid #ced4da;
        border-radius: 0.25rem 0.25rem 0rem 0.25rem;
        background-color: white !important;
        border-right: none !important;
        border-top-right-radius: 0;
        padding-right: 5px;
      }
    }
  }

  .noData {
    width: 100%;
    text-align: center;
    height: 250px;
    padding: 100px;

    svg {
      margin-bottom: 20px;
    }
  }

  .salary-personal-info {
    padding: 20px;
    border-bottom: 1px solid #e1e4e8;

    > div {
      display: inline-block;
      vertical-align: top;
      p {
        margin: 0;
      }
    }

    > div:nth-child(1) {
      padding: 0 30px 0 0;
    }

    > div:nth-child(2) {
      padding: 0 30px 0 30px;
      border-left: 1px solid #e1e4e8;
    }

    > div:nth-child(3) {
      padding: 0 30px 0 30px;
      border-left: 1px solid #e1e4e8;
      span {
        color: #18d399;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    border-radius: 0;
    padding-bottom: 100px;
    margin: 0px;
  }
  @media screen and (max-width: 400px) {
    .salary-personal-info {
      display: flex;
      flex-wrap: wrap;
      
    }
    .salary-personal-info > div:nth-child(2) {
      padding: 0 60px 0 0;
      margin-top: 10px;
      border-left: 0;

    }
  }

  .lower-windows {
    margin-left: 0px !important;
    margin-top: 0px !important;
    padding: 40px 0px 40px 0px;
     
      .inner-window-left {
        width: 100%;
        margin-right: 0px
    }
  }

`;
