import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginWithAdmin from '../../../components/Icons/LoginWithAdmin';
import LoginWithEmployee from '../../../components/Icons/LoginWithEmployee';
import { AppContext } from '../../../Context/App/AppContext';
import { PermissionsEmployee } from '../../../services/Constants/getDefaults';
import { routesAdmin, routesEmployee } from '../../../services/Constants/paths';
import { updateRole } from '../../../services/Utils/employeeService';
import { ContentSelectProfile } from './styles';

const Selectprofile = () => {
  const { setroleUser } = useContext(AppContext);
  const navigate = useNavigate();
  const updatePermission = role => {
    updateRole(role);
    setroleUser(role);
    if (role === PermissionsEmployee.EMPLOYEE) {
      navigate(routesEmployee.pto);
    }
    if (role === PermissionsEmployee.ADMIN) {
      navigate(routesAdmin.home);
    }
  };
  return (
    <ContentSelectProfile>
      <div className="content-text">
        <span className="title-select">Select Profile</span>
        <p className="text-select">
          With TOB you can separately use your assigned profiles and switch
          between them whenever you want.
        </p>
      </div>
      <div className="cards-select">
        <div className="card-select">
          <LoginWithAdmin />
          <span
            onClick={() => updatePermission(PermissionsEmployee.ADMIN)}
            className="btn-select"
          >
            Login as administrator
          </span>
        </div>
        <div className="card-select">
          <LoginWithEmployee />
          <span
            onClick={() => updatePermission(PermissionsEmployee.EMPLOYEE)}
            className="btn-select"
          >
            Login as Employee
          </span>
        </div>
      </div>
    </ContentSelectProfile>
  );
};

export default Selectprofile;
