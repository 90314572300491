import React from 'react';

const Calendar = ({ color = 'white', width = '48', height = '48' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 6H12C8.68629 6 6 8.68629 6 12V36C6 39.3137 8.68629 42 12 42H36C39.3137 42 42 39.3137 42 36V12C42 8.68629 39.3137 6 36 6Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 16H6"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6 23C34.6 23.04 34.54 23.1 34.5 23.1C34.44 23.1 34.4 23.04 34.4 23C34.4 22.94 34.44 22.9 34.5 22.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 22.9H34.48C34.52 22.88 34.58 22.94 34.58 23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.598 23C27.598 23.04 27.538 23.1 27.498 23.1C27.438 23.1 27.398 23.04 27.398 23C27.398 22.94 27.438 22.9 27.498 22.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.48 22.9H27.46C27.5 22.88 27.56 22.94 27.56 23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.598 23C20.598 23.04 20.538 23.1 20.498 23.1C20.438 23.1 20.398 23.04 20.398 23C20.398 22.94 20.438 22.9 20.498 22.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.48 22.9H20.46C20.5 22.88 20.56 22.94 20.56 23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.598 29C13.598 29.04 13.538 29.1 13.498 29.1C13.438 29.1 13.398 29.04 13.398 29C13.398 28.94 13.438 28.9 13.498 28.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.48 28.9H13.46C13.5 28.88 13.56 28.94 13.56 29"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.598 29C20.598 29.04 20.538 29.1 20.498 29.1C20.438 29.1 20.398 29.04 20.398 29C20.398 28.94 20.438 28.9 20.498 28.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.48 28.9H20.46C20.5 28.88 20.56 28.94 20.56 29"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.598 29C27.598 29.04 27.538 29.1 27.498 29.1C27.438 29.1 27.398 29.04 27.398 29C27.398 28.94 27.438 28.9 27.498 28.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.48 28.9H27.46C27.5 28.88 27.56 28.94 27.56 29"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6 29C34.6 29.04 34.54 29.1 34.5 29.1C34.44 29.1 34.4 29.04 34.4 29C34.4 28.94 34.44 28.9 34.5 28.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 28.9H34.48C34.52 28.88 34.58 28.94 34.58 29"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6 35C34.6 35.04 34.54 35.1 34.5 35.1C34.44 35.1 34.4 35.04 34.4 35C34.4 34.94 34.44 34.9 34.5 34.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 34.9H34.48C34.52 34.88 34.58 34.94 34.58 35"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.598 35C27.598 35.04 27.538 35.1 27.498 35.1C27.438 35.1 27.398 35.04 27.398 35C27.398 34.94 27.438 34.9 27.498 34.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.48 34.9H27.46C27.5 34.88 27.56 34.94 27.56 35"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.598 35C20.598 35.04 20.538 35.1 20.498 35.1C20.438 35.1 20.398 35.04 20.398 35C20.398 34.94 20.438 34.9 20.498 34.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.48 34.9H20.46C20.5 34.88 20.56 34.94 20.56 35"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.598 35C13.598 35.04 13.538 35.1 13.498 35.1C13.438 35.1 13.398 35.04 13.398 35C13.398 34.94 13.438 34.9 13.498 34.9"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.48 34.9H13.46C13.5 34.88 13.56 34.94 13.56 35"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Calendar;
