import React from 'react';

const Vector = ({ rotate = 0, size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate})` }}
    >
      <path
        d="M11.6666 6.66699L8.33325 10.0003L11.6666 13.3337"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Vector;
