import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import Table from '../../../components/GenericTable';
import NotTeams from '../../../components/Icons/NotTeams';
import { routesManager, routesOwner } from '../../../services/Constants/paths';
import Trash from '../../../components/Icons/Trash';
import { ContentTeams, HeaderTeams } from '../Teams/styles';
import { AppContext } from '../../../Context/App/AppContext';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { DELETE_GROUPS_MUTATION } from '../../../graphql/queries/groups';
import { ModalAlert } from '../../../components/Modals';
import { useTeamsByManagerQuery } from '../../../graphql/container/query/teams';
import PtoIcon from '../../../components/Icons/Pto';
import { TableManager } from './styles';
import { SearchEmployeeContainer } from '../../../components/SearchEmployee/styles.js';
import Search from '../../../components/Icons/Search';
import TabsPto from '../../../components/TabsPto';
import Team from '../../../containers/Views/Teams';
import Pagination from '../../../components/Pagination';
import IconProfileTeams from '../../../components/Icons/IconProfileTeams';
import Edit from '../../../components/Icons/Edit';
import { PermissionsEmployee } from '../../../services/Constants/getDefaults';
import { readCookies } from '../../../services/Utils/getUtils';

const initialsColumns = [
  {
    name: 'Team Name',
    key: 'teamName',
    active: true
    // width: '40%'
  },
  {
    name: 'Members',
    key: 'members',
    active: true
  },
  {
    name: '',
    key: 'check',
    active: true
  },
  {
    name: '',
    key: 'edit',
    active: true
  }
];

const Teams = () => {
  const isMounted = useRef(false);
  const location = useLocation();
  const { currentUser } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;
  const [columnsTable] = useState(initialsColumns);
  const [allTeams, setAllTeams] = useState([]);
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tabActive, setTabActive] = useState(
    location?.state?.tab === 1 ? 1 : 0
  );

  //Pagination Data
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [allTeamsPagination, setAllTeamsPagination] = useState([]);

  const handlePage = num => {
    setOffset(limit * (num - 1));
    setCurrentPage(num);
  };
  // Finish Pagination Data

  const {
    data: { getAllTeamsByManager }
  } = useTeamsByManagerQuery(currentUser?.employee?.id, companyId);

  const [removeGroups, { loading: deleteDocLoading }] = useMutation(
    DELETE_GROUPS_MUTATION
  );

  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
    setLimit(10);
    setAllTeamsPagination(allTeams);
  }, [tabActive, allTeams]);

  useEffect(() => {
    if (getAllTeamsByManager) {
      setAllTeams(getAllTeamsByManager);
    }
  }, [getAllTeamsByManager]);

  useEffect(() => {
    if (getAllTeamsByManager) {
      setAllTeamsPagination(allTeams);
    }
  }, [getAllTeamsByManager, tabActive, allTeams]);

  useEffect(() => {
    if (!deleteDocLoading && !isMounted.current) isMounted.current = true;
  }, [isMounted, deleteDocLoading]);

  useEffect(() => {
    if (loadingTable && !deleteDocLoading);
    // eslint-disable-next-line
  }, [deleteDocLoading]);

  const deleteGroup = () => {
    setOpenModal(false);
    removeGroups({
      variables: {
        list: selected
      }
    });
    setLoadingTable(true);
    setSelected([]);
  };

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }
  return (
    <>
      <HeaderTeams>
        <Pagination
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          allElements={allTeamsPagination}
          handlePage={handlePage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </HeaderTeams>
      <ContentTeams>
        <TabsPto
          tabActive={tabActive}
          setTabActive={value => {
            setTabActive(value);
          }}
          nameTabs={['My Teams', 'Company Teams']}
        />
        <div className="content-tabs">
          {tabActive === 0 ? (
            <>
              <div className="permission-header">
                <div>
                  <h3 className="sub-heading">Teams ({allTeams.length})</h3>
                </div>
                <div className="content-search">
                  <SearchTeams
                    setAllTeams={setAllTeams}
                    TeamsData={getAllTeamsByManager}
                  />
                </div>
                <div>
                  <Button
                    className="btn-danger trash"
                    hidden={!selected.length}
                    onClick={() => setOpenModal(true)}
                  >
                    <Trash color="white" />
                    <span>{'Delete'}</span>
                  </Button>
                  <ModalAlert
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    onConfirm={deleteGroup}
                    content={
                      <div className="content" style={{ padding: '0 6%' }}>
                        <p className="text-normal">
                          Are you sure you want to delete the next team?:
                        </p>
                        <ul>
                          {selected?.map(item => {
                            return (
                              <li key={`li-${item.id}`} className="text-normal">
                                {item.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    }
                    centered
                  />
                </div>
              </div>
              <div className="teams-table">
                <TableTeams
                  columns={columnsTable}
                  dataSource={allTeams}
                  setAllTeams={setAllTeams}
                  loading={loadingTable}
                  selected={selected}
                  setSelected={setSelected}
                  getAllTeamsByManager={getAllTeamsByManager}
                  offset={offset}
                  limit={limit}
                  tabActive={tabActive}
                />
              </div>
            </>
          ) : null}
          {tabActive === 1 ? (
            <>
              <Team
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                setLimit={setLimit}
                handlePage={handlePage}
                setAllTeamsPagination={setAllTeamsPagination}
                tabActive={tabActive}
              />
            </>
          ) : null}
        </div>
      </ContentTeams>
    </>
  );
};

const NoData = () => {
  return (
    <div className="noData" style={{ paddingTop: '100px' }}>
      <NotTeams color="black" size="48" />
      <div>
        <span className="text-normal">You don't have teams assigned yet</span>
      </div>
    </div>
  );
};

const TableTeams = ({
  dataSource = [],
  setAllTeams,
  loading = false,
  columns = [],
  selected,
  setSelected,
  getAllTeamsByManager = [],
  offset,
  limit,
  tabActive
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];
  const { roleUser } = useContext(AppContext);
  const [cacheFiltersTeams, setCacheFiltersTeams] = useState(
    readCookies('ManagerTeams')
  );
  const [sortChoiceTeam, setSortChoiceTeam] = useState(
    cacheFiltersTeams?.[2] ? JSON.parse(cacheFiltersTeams[2]) : true
  );
  const [itemTeam, setItemTeam] = useState(
    cacheFiltersTeams?.[1] ? JSON.parse(cacheFiltersTeams[1]) : ''
  );
  const permissionsEdit =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN ||
    roleUser === PermissionsEmployee.ADMIN_EMPLOYEE;

  useEffect(() => {
    if (itemTeam) {
      saveCookies();
      setCacheFiltersTeams(readCookies('ManagerTeams'));
    }
    // eslint-disable-next-line
  }, [itemTeam, sortChoiceTeam]);

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `ManagerTeams=; expires=${dateExpires}`;
    document.cookie = `ManagerTeams=***${JSON.stringify(
      itemTeam
    )}***${JSON.stringify(
      sortChoiceTeam
    )}; expires=31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
  }

  useEffect(() => {
    handleSortTeam(itemTeam);
    // eslint-disable-next-line
  }, [sortChoiceTeam, itemTeam]);

  const handleSortTeam = value => {
    const data = dataSource?.length
      ? dataSource?.length < getAllTeamsByManager.length
        ? dataSource
        : getAllTeamsByManager
      : getAllTeamsByManager;
    if (value === 'Team Name') {
      const sortTeamName = sortChoiceTeam
        ? data &&
          data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
      return setAllTeams(sortTeamName);
    }
    if (value === 'Members') {
      const sortTeamEmployees = sortChoiceTeam
        ? data &&
          data.sort((a, b) => {
            return a.employees - b.employees;
          })
        : data &&
          data.sort((a, b) => {
            return b.employees - a.employees;
          });
      return setAllTeams(sortTeamEmployees);
    }
  };

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'teamName':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div className="title-teams">
                  <IconProfileTeams width="36" height="36" />
                  <span> {item.name} </span>
                </div>
              )
            });
          break;
        case 'members':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div
                  style={{ cursor: 'pointer', display: 'flex' }}
                  onClick={() => {
                    if (item.employees > 1) {
                      window.history.pushState(null, null, location.pathname);
                      navigate(routesManager.ptosTeam(item.id), {
                        state: { teamName: item?.name },
                        replace: true
                      });
                    } else {
                      return;
                    }
                  }}
                >
                  {item.employees - 1}
                </div>
              )
            });
          break;
        case 'check':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div
                  className="check-team"
                  onClick={() => {
                    if (item.employees > 1) {
                      window.history.pushState(null, null, location.pathname);
                      navigate(routesManager.ptosTeam(item.id), {
                        state: { teamName: item?.name },
                        replace: true
                      });
                    } else {
                      return;
                    }
                  }}
                >
                  {item.cantPto > 0 ? (
                    <span className="number-pto">
                      {item.cantPto < 9 ? item.cantPto : '+' + item.cantPto}
                    </span>
                  ) : (
                    <span
                      style={{
                        paddingLeft: '6px',
                        borderLeft: '1px solid rgb(149, 157, 165)'
                      }}
                    ></span>
                  )}
                  <PtoIcon />
                </div>
              )
            });
          break;
        case 'edit':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: permissionsEdit ? (
                <div
                  className="edit-team"
                  onClick={() =>
                    navigate(routesOwner.createEditTeams(), {
                      state: { teamId: item.id, tab: tabActive },
                      replace: true
                    })
                  }
                >
                  <Edit />
                </div>
              ) : null
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id,
      name: item.name
    });
  });

  const handleNavigate = item => {
    navigate(routesManager.ptosTeam(item.value), {
      state: { teamName: item?.name },
      replace: true
    });
  };

  return (
    <TableManager>
      <Table
        data={data?.slice(offset, offset + limit)}
        dataSource={dataSource}
        columns={columnsActives}
        loading={loading}
        component={<NoData />}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        dataSelected={selected}
        setDataSelected={setSelected}
        attributesSelected={attributes}
        onClick={handleNavigate}
        sort
        setItemTeam={setItemTeam}
        setSortChoiceTeam={setSortChoiceTeam}
        sortChoiceTeam={sortChoiceTeam}
      />
    </TableManager>
  );
};

/**
 * This function is a React component that renders a search bar that filters the data in the table
 * below
 */
const SearchTeams = ({ setAllTeams, TeamsData }) => {
  const [labelSearch, setLabelSearch] = useState('');

  const searchTeam = search => {
    if (search.trim() === '') {
      setAllTeams(TeamsData);
    } else {
      const newState = TeamsData.filter(obj => {
        if (obj?.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return obj;
        } else {
          return false;
        }
      });
      setAllTeams(newState);
    }
  };
  return (
    <div className="search">
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setLabelSearch(target.value);
                searchTeam(target.value);
              }}
              placeholder={'Search Team'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search-permissions"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default Teams;
