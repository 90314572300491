import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { FormControl, InputGroup } from 'react-bootstrap';
import { SearchEmployeeContainer } from '../../../components/SearchEmployee/styles.js';
import Search from '../../../components/Icons/Search';
import { ContentPermissions, HeaderPermissions } from './styles';
import TablePermissions from './TablePermissions';
import { AppContext } from '../../../Context/App/AppContext';
import { useGetEmployeePermissions } from '../../../graphql/container/query/groups';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { ModalAlert } from '../../../components/Modals';
import {
  useGetEmployeesByCompanyQuery,
  useGetEmployeesSortByCompanyQuery
} from '../../../graphql/container/query/employees';
import { UPDATE_PERMISSION_EMPLOYEES_MUTATION } from '../../../graphql/mutations/permissions/index.js';
import ButtonFilterEmployees from '../../../components/Button/ButtonFilterEmployees/index.jsx';
import { useRolesByCompanyQuery } from '../../../graphql/container/query/roles/index.js';
import { initialsColumns } from './fields';
import { CSVLink } from 'react-csv';
import { readCookies } from '../../../services/Utils/getUtils.js';
import Pagination from '../../../components/Pagination/index.jsx';

const Permissions = () => {
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;
  const [openModal, setOpenModal] = useState(false);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [currentSearch, setCurrentSearch] = useState([]);
  const [cacheFiltersPermissions, setCacheFiltersPermissions] = useState(
    readCookies('Permissions')
  );
  const [allEmployees, setAllEmployees] = useState([]);
  const [permissionSelect, setpermissionSelect] = useState({});
  const [permissionEmployee, setpermissionEmployee] = useState([]);
  const [inactivePermissions, setInactivePermissions] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  //Checkboxs
  const [selectEmployees, setSelectEmployees] = useState([]);

  const [sortState] = useState('');
  const [sortAscDesc] = useState('');
  const [orderBy, setOrderBy] = useState(
    cacheFiltersPermissions?.[1] ? JSON.parse(cacheFiltersPermissions[1]) : ''
  );

  const [columnsTable, setColumnsTable] = useState(initialsColumns);
  const [activeRoles, setActiveRoles] = useState([]);
  const [initialRoles, setInitialRoles] = useState();
  const [order, setOrder] = useState(
    cacheFiltersPermissions?.[2] ? JSON.parse(cacheFiltersPermissions[2]) : true
  );

  // Pagination Data
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const handlePage = num => {
    setOffset(limit * (num - 1));
    setCurrentPage(num);
  };
  // Finish Pagination Data

  const {
    data: { getEmployeesByCompany },
    loading: loadingEmployees,
    refetch: refetchGetEmployees
  } = useGetEmployeesSortByCompanyQuery(
    companyId,
    //limit,
    offset,
    sortState,
    sortAscDesc
  );

  const {
    data: { getEmployeePermissions },
    loading: loadingPermissions
  } = useGetEmployeePermissions();

  const {
    data: dataAllEmployees,
    loading: loadingAllEmployees
  } = useGetEmployeesByCompanyQuery(companyId, 0, 0, '', '', '');

  const [updatePermissionEmployess] = useMutation(
    UPDATE_PERMISSION_EMPLOYEES_MUTATION
  );

  const {
    data: { getAllRolesByCompanyId }
  } = useRolesByCompanyQuery(companyId);

  useEffect(() => {
    if (getAllRolesByCompanyId) setInitialRoles(getAllRolesByCompanyId);
  }, [getAllRolesByCompanyId]);

  useEffect(() => {
    const rolesNames = getAllRolesByCompanyId?.map(el => el.name);
    const objRolesCache = [];
    const objRoles = rolesNames?.reduce((total, actual) => {
      return {
        ...total,
        [actual]:
          objRolesCache[actual] || Object.entries(objRolesCache)?.length === 0
            ? true
            : false
      };
    }, {});
    setActiveRoles(objRoles);
    // eslint-disable-next-line
  }, [initialRoles]);

  const applyPermissions = () => {
    try {
      const employeesList = Object.values(selectEmployees).map(e => e.id);
      updatePermissionEmployess({
        variables: {
          permissionId: permissionSelect,
          employeesList
        }
      });
      setOpenModal(false);
      refetchGetEmployees({
        companyId: companyId,
        offset,
        orderField: sortState,
        sort: sortAscDesc
      });
      setOpenModal(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (dataAllEmployees) {
      const filtereActive = dataAllEmployees?.getEmployeesByCompany?.employees?.filter(
        employee => {
          return employee.status === 'ACTIVE';
        }
      );
      setAllEmployees(
        filtereActive?.filter(
          el => el?.permissionEmployee?.description !== 'Owner'
        )
      );
    }
  }, [dataAllEmployees]);

  useEffect(() => {
    if (orderBy) {
      saveCookies();
      setCacheFiltersPermissions(readCookies('Permissions'));
    }
    // eslint-disable-next-line
  }, [orderBy, order]);

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `Permissions=; expires=${dateExpires}`;
    document.cookie = `Permissions=***${JSON.stringify(
      orderBy
    )}***${JSON.stringify(order)}; expires=31 Dec ${new Date().getFullYear() +
      1} 23:59:59 GMT`;
  }

  useEffect(() => {
    if (getEmployeesByCompany) {
      setTotalEmployees(getEmployeesByCompany.totalEmployees);
    }
    if (allEmployees) {
      filterRoles(
        allEmployees?.filter(
          el => el?.permissionEmployee?.description !== 'Owner'
        )
      );
    }
    // eslint-disable-next-line
  }, [getEmployeesByCompany, orderBy, order]);

  useEffect(() => {
    if (getEmployeePermissions) {
      setpermissionSelect(
        getEmployeePermissions[getEmployeePermissions.length - 1]?.id
      );
      const newPermissions = getEmployeePermissions?.filter(object => {
        return object.description !== 'Owner';
      });
      setpermissionEmployee(newPermissions);
    }
  }, [getEmployeePermissions]);

  useEffect(() => {
    if (getAllRolesByCompanyId) {
      dataToExportFunction(
        dataAllEmployees?.getEmployeesByCompany?.employees?.filter(
          el => el?.status !== 'SEPARATED'
        )
      );
    }
    // eslint-disable-next-line
  }, [dataAllEmployees, permissionEmployee, activeRoles]);

  useEffect(() => {
    if (allEmployees) {
      const stateToFilter = currentSearch?.length
        ? currentSearch
        : allEmployees;
      filterRoles(stateToFilter);
    }
    // eslint-disable-next-line
  }, [activeRoles, permissionEmployee]);

  const activeFields = columnsTable => {
    const activeFields = columnsTable
      ?.filter(el => el.active === true)
      ?.map(el => el.name);

    const inactiveFields = columnsTable
      ?.filter(el => el.active === false)
      ?.map(el => el.name);

    const filterInactiveFields = getEmployeePermissions?.filter(el =>
      inactiveFields?.includes(el.description)
    );
    const filterFields = getEmployeePermissions?.filter(el =>
      activeFields?.includes(el.description)
    );
    setInactivePermissions(filterInactiveFields);
    setpermissionEmployee(filterFields);
  };

  const filterRoles = data => {
    const rolesFalse = getAllRolesByCompanyId?.filter(
      el => activeRoles[el.name] === false
    );
    // me guarda el Id de los permisos que removi
    let permissionsId = inactivePermissions?.map(el => el.id);

    let filteredExport = data?.filter(el => {
      return !permissionsId?.includes(el?.permissionEmployee?.id);
    });

    const rolesFalseNames = rolesFalse?.map(el => el.name);
    if (data) {
      const filterEmployees = filteredExport?.filter(
        el => !rolesFalseNames?.includes(el?.employeeRoleInfo?.name)
      );

      const origin = currentSearch?.length ? 'searchState' : 'regular';
      const dataToSort = currentSearch?.length
        ? currentSearch
        : filterEmployees;

      handleOrder(orderBy, dataToSort, origin);
    }
  };

  const handleOrder = (value, data = currentEmployees, origin = 'regular') => {
    if (order) {
      const sortedData = data.sort((a, b) => {
        const choiceA =
          value === 'role' ? a?.employeeRoleInfo?.name : a.fullName;
        const choiceB =
          value === 'role' ? b?.employeeRoleInfo?.name : b.fullName;

        if (choiceA.toLowerCase() > choiceB.toLowerCase()) {
          return 1;
        }
        if (choiceA.toLowerCase() < choiceB.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
      return origin === 'regular'
        ? setCurrentEmployees(sortedData)
        : origin === 'searchState'
        ? setCurrentSearch(sortedData)
        : sortedData;
    } else {
      const sortedDataB = data.sort((a, b) => {
        const choiceA =
          value === 'role' ? a?.employeeRoleInfo?.name : a.fullName;
        const choiceB =
          value === 'role' ? b?.employeeRoleInfo?.name : b.fullName;
        if (choiceA.toLowerCase() < choiceB.toLowerCase()) {
          return 1;
        }
        if (choiceA.toLowerCase() > choiceB.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
      return origin === 'regular'
        ? setCurrentEmployees(sortedDataB)
        : origin === 'searchState'
        ? setCurrentSearch(sortedDataB)
        : sortedDataB;
    }
  };

  const dataToExportFunction = data => {
    const employeesInfo = data?.map(el => Object.assign({}, el));

    employeesInfo &&
      employeesInfo.map(el => {
        el.Name = el?.fullName;
        el.Role = el?.employeeRoleInfo?.name;
        el.Permission = el?.permissionEmployee?.description;
        delete el?.firstName;
        delete el?.lastName;
        delete el?.id;
        delete el?.middleName;
        delete el?.mothersMaidenName;
        delete el?.permissionId;
        delete el?.__typename;
        delete el?.salary;
        delete el?.schedule;
        delete el?.startDate;
        delete el?.status;
        delete el?.dateOfBirth;
        delete el?.contactInformation;
        delete el?.employeeRoleInfo;
        delete el?.employeeTeam;
        delete el?.paymentInformation;
        delete el?.customInformation;
        delete el?.user;
      });

    const completeExport = employeesInfo?.map(el => Object.assign({}, el));

    completeExport &&
      completeExport.map(el => {
        delete el?.permissionEmployee;
        delete el?.fullName;
      });

    let filteredExport = [];

    for (let i = 0; i < permissionEmployee?.length; i++) {
      completeExport &&
        completeExport.map(el => {
          if (el?.Permission === permissionEmployee[i]?.description) {
            return filteredExport.push(el);
          }
        });
    }
    completeExport &&
      completeExport.map(el => {
        if (el?.Permission === undefined || el?.Permission === '') {
          return filteredExport.push(el);
        }
      });

    const rolesFalse =
      getAllRolesByCompanyId &&
      getAllRolesByCompanyId?.filter(el => activeRoles[el?.name] === false);
    const rolesFalseNames = rolesFalse?.map(el => el?.name);

    const filterEmployees = filteredExport?.filter(
      el => !rolesFalseNames?.includes(el?.Role)
    );

    setDataToExport(filterEmployees);
  };

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;
  }, [isMounted]);

  useEffect(() => {
    activeFields(columnsTable);
  }, [columnsTable]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      refetchGetEmployees({
        companyId: companyId,
        offset,
        orderField: sortState,
        sort: sortAscDesc
      });
    }
    // eslint-disable-next-line
  }, [offset, limit, sortState, sortAscDesc]);

  if (loadingPermissions || loadingAllEmployees) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <div>
      <HeaderPermissions>
        <Pagination
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          handlePage={handlePage}
          currentPage={currentPage}
          allElements={currentSearch?.length ? currentSearch : currentEmployees}
          setCurrentPage= {setCurrentPage}
        />
      </HeaderPermissions>
      <ContentPermissions>
        <div className="permission-header">
          <div className="header">
            <div className="content-search">
              <h3 className="sub-heading">Permissions</h3>
              <SearchPermission
                setCurrentEmployees={setCurrentEmployees}
                setCurrentSearch={setCurrentSearch}
                totalEmployees={handleOrder(
                  orderBy,
                  currentEmployees,
                  'search'
                )}
                employeesData={handleOrder(orderBy, currentEmployees, 'search')}
                handlePage={handlePage}
              />
            </div>
            <div className="content-select">
              <ButtonFilterEmployees
                classButton="green-button"
                activeRoles={activeRoles}
                setActiveRoles={setActiveRoles}
                from={'permissions'}
                initialRoles={initialRoles}
                data={JSON.parse(JSON.stringify(columnsTable))}
                setData={setColumnsTable}
              />
              <button className="green-button">
                <CSVLink
                  data={dataToExport ? dataToExport : ''}
                  filename="Permissions"
                  className="csv-export"
                >
                  <span className="pad-icon">Export</span>
                </CSVLink>
              </button>
              {selectEmployees.length > 0 && (
                <div className="content-select">
                  <Button
                    className="btn-success btn-permissions"
                    onClick={() => setOpenModal(true)}
                  >
                    Apply permissions
                  </Button>
                  <form>
                    <select
                      id="selectLimit"
                      className="select select-permissions"
                      onChange={e => setpermissionSelect(e.target.value)}
                    >
                      {permissionEmployee?.map((el, i) => {
                        return (
                          <option
                            key={i}
                            selected={el.description}
                            value={el.id}
                          >
                            {el.description}
                          </option>
                        );
                      })}
                    </select>
                  </form>
                </div>
              )}
            </div>
            <ModalAlert
              show={openModal}
              confirmLabel="Apply permissions"
              onClose={() => setOpenModal(false)}
              onConfirm={() => {
                applyPermissions();
              }}
              buttonCancel="Cancel"
              buttonCancelStyle="btn-danger-without-bg"
              content={
                <div>
                  <div className="content-alert">
                    <p className="label-alert">
                      {`Are you sure you want to apply this permission to the ${selectEmployees.length} selected?`}
                    </p>
                  </div>
                </div>
              }
              centered
            />
          </div>
        </div>

        <TablePermissions
          columns={permissionEmployee}
          totalEmployees={totalEmployees}
          currentEmployees={
            currentSearch?.length
              ? currentSearch?.slice(offset, offset + limit)
              : currentEmployees?.slice(offset, offset + limit)
          }
          setCurrentEmployees={setCurrentEmployees}
          setCurrentSearch={setCurrentSearch}
          loading={loadingEmployees}
          selectEmployees={selectEmployees}
          setSelectEmployees={setSelectEmployees}
          handleOrder={handleOrder}
          setOrder={setOrder}
          order={order}
          setOrderBy={setOrderBy}
        />
      </ContentPermissions>
    </div>
  );
};

const SearchPermission = ({
  setCurrentEmployees,
  setCurrentSearch,
  totalEmployees,
  employeesData,
  handlePage
}) => {
  const [labelSearch, setlabelSearch] = useState('');

  const searchEmployee =
    search => {
      if (search.trim() === '') {
        handlePage(1);
        setCurrentSearch([]);
        setCurrentEmployees(employeesData);
      } else {
        const newState = totalEmployees?.filter(obj => {
          if (obj?.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            return obj;
          }
        });
        handlePage(1);
        setCurrentSearch(newState);
      }
    };


  return (
    <div className="search">
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setlabelSearch(target.value);
                searchEmployee(target.value);
              }}
              placeholder={'Search employee'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search-permissions"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default Permissions;
