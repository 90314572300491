import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import 'react-circular-progressbar/dist/styles.css';
import Moment from 'react-moment';
import moment from 'moment';
import { CircularProgress, FormControlLabel, Radio } from '@material-ui/core';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

import { useGetPtoRulesByCompanyLazyQuery } from '../../../graphql/container/query/timeOffRules';

import {
  calculateDaysOffFromStartAndEnd,
  formatDateToCalendar,
  verifyHolidays
} from '../../../services/Utils/getUtils';
import { useGetPTOEmployeeLazyQuery } from '../../../graphql/container/query/employees';
import { Col } from '../../../components/Grid/Grid';
import { ContentPTO } from './styles';
import { IconEvents } from '../../../components/Icons/IconEvents';
import { DATE_FORMAT } from '../../../services/Constants/getDefaults';
import CalendarPto from '../../../components/Icons/CalendarPto';
import Bell from '../../../components/Icons/Bell';
import Check from '../../../components/Icons/Check';
import Wrong from '../../../components/Icons/Wrong';
import Edit from '../../../components/Icons/Edit';
import { AppContext } from '../../../Context/App/AppContext';
import {
  DATE_FORMAT_BETWEEN,
  ABSCENCE_TYPE,
  ABSCENCE_REQUEST_STATUS
} from '../../../services/Constants/getDefaults';
import {
  ABSCENCE_REQUEST_EXCEED_DAYS,
  ABSCENCE_REQUEST_CONTAINS_USED_DAYS,
  EXCEEDED_AMOUNT_OF_DAYS,
  YOU_CAN_NOT_REQUEST_SAME_DAY,
  ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY,
  CHOOSE_START_AND_END_DATE,
  YOU_CAN_NOT_REQUEST_BOOKED_DAYS,
  YOU_CAN_NOT_REQUEST_WEEKENDS,
  YOU_DO_NOT_HAVE_DAYS_LEFT,
  CHOOSE_START_GREATER_END_DATE
} from '../../../services/Constants/getLabels';
import {
  formatZeroGmtDate,
  transformUTCDate,
  simpleFormatDate
} from '../../../services/Utils/getUtils';
import { CREATE_ME_ABSCENCE_REQUEST_MUTATION } from '../../../graphql/mutations/abscenceRequest';
import CheckMark from '../../../components/Icons/CheckMark';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { ModalAlert } from '../../../components/Modals';
import DatePickerModule from '../../../components/DatePickerModal';
import {
  useTeamsByCompanyQueryEmployee,
  useTeamsByCompanyQueryEmployeeByTeams,
  useTeamsByCompanyQueryEmployeeNotTeams
} from '../../../graphql/container/query/teams';
import Exclamation from '../../../components/Icons/Exclamation';
import TabsPto from '../../../components/TabsPto';
import Salary from '../Salary';
import DropdownPto from '../../../components/DropdownPto';

const Pto = () => {
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const [advanceDays] = useState(
    currentUser.companyRole[0]?.company?.timeOffRules
      ?.daysAdvanceToRequestTimeOff
  );
  const [typeTime, setTypeTime] = useState(ABSCENCE_TYPE.DAY_OFF);
  const userName = currentUser?.employee.fullName;
  const companyId = currentUser?.companyRole?.[0].company?.id;
  const [check, setCheck] = useState(false);
  const [listPending, setListPending] = useState([]);
  const [listPast, setListPast] = useState([]);
  const [infoTimeOff, setInfoTimeOff] = useState();
  const [startDate, setStartDate] = useState();
  const [initialDate, setInitialDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [disabledReminder, setDisabledReminder] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [approvedDays, setApprovedDays] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const {
    data: { getAllManagerByCompanyByEmployeeByTeams }
  } = useTeamsByCompanyQueryEmployeeByTeams(
    companyId,
    currentUser?.employee.id
  );

  const {
    data: { getAllTeamsByCompanyByEmployee }
  } = useTeamsByCompanyQueryEmployee(companyId, currentUser?.employee.id);

  const {
    data: { getAllEmailsOwnerAndAdminByCompany }
  } = useTeamsByCompanyQueryEmployeeNotTeams(companyId);

  const [
    getPtoEmployee,
    { data: getPtoEmployeeData, loading, refetch }
  ] = useGetPTOEmployeeLazyQuery(currentUser.id, currentUser.email);

  const [
    getPtoRules,
    { data: getPtoRulesByCompany }
  ] = useGetPtoRulesByCompanyLazyQuery(companyId);

  useEffect(() => {
    getPtoRules(companyId);
  }, [companyId, getPtoRules]);

  useEffect(() => {
    if (currentUser?.email) {
      getPtoEmployee(currentUser?.id, currentUser?.email);
    }
  }, [currentUser, getPtoEmployee]);

  const [
    createMeAbscenceRequest,
    { loading: createOneAbscenceRequestLoading }
  ] = useMutation(CREATE_ME_ABSCENCE_REQUEST_MUTATION);

  const initialDatePto = new Date(new Date().setHours(0, 0, 0, 0));

  useEffect(() => {
    initialDatePto.setDate(
      initialDatePto.getDate() +
        (typeTime === ABSCENCE_TYPE.DAY_OFF ? advanceDays : 0)
    );
    while (
      initialDatePto.getDay() === 0 ||
      initialDatePto.getDay() === 6 ||
      approvedDays?.some(
        day => new Date(day).getTime() === initialDatePto.getTime()
      )
    ) {
      initialDatePto.setDate(initialDatePto.getDate() + 1);
    }

    setStartDate(initialDatePto);
    setInitialDate(initialDatePto);
    // eslint-disable-next-line
  }, [getPtoEmployeeData, typeTime, approvedDays]);

  useEffect(() => {
    if (getPtoEmployeeData?.getPtoEmployee) {
      isMounted.current = true;
      const {
        approved,
        pending,
        declined,
        timeOffAccrual
      } = getPtoEmployeeData?.getPtoEmployee;
      if (pending) setListPending(pending);
      if (approved || declined) {
        const order = [...approved, ...declined].sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setListPast(order.slice(0, 20));
      }
      if (timeOffAccrual) setInfoTimeOff(timeOffAccrual);
      setLoadingTable(false);
    }
  }, [getPtoEmployeeData]);

  useEffect(() => {
    setHolidays(
      getPtoRulesByCompany?.getPtoRulesByCompany?.holidays
        ?.map(el => formatDateToCalendar(el.date))
        .flat()
    );
  }, [getPtoEmployeeData, getPtoRulesByCompany, getPtoRules]);

  useEffect(() => {
    const startApproved = getPtoEmployeeData?.getPtoEmployee?.approved?.map(
      el =>
        formatDateToCalendar(
          el.dateTakenStart,
          moment(el.dateTakenEnd).diff(moment(el.dateTakenStart), 'days') + 1
        )
    );
    const startAndEndPending = getPtoEmployeeData?.getPtoEmployee?.pending?.map(
      el => calculateDaysOffFromStartAndEnd(el.startDate, el.endDate)
    );

    const startPending = getPtoEmployeeData?.getPtoEmployee?.pending
      ?.map((el, i) =>
        formatDateToCalendar(el.startDate, startAndEndPending[i])
      )
      .flat()
      .concat(holidays);

    setApprovedDays(startApproved?.flat().concat(startPending));
  }, [getPtoEmployeeData, getPtoRulesByCompany, holidays]);

  useEffect(() => {
    if (loadingTable && !createOneAbscenceRequestLoading) refetch();
    // eslint-disable-next-line
  }, [createOneAbscenceRequestLoading]);

  const isBetweenDate = (arrayDate, dateTakenStart, dateTakenEnd) => {
    let isBetween = false;
    const isBetweenArray = arrayDate.filter(item => {
      return moment(item)
        .utc()
        .isBetween(
          moment(dateTakenStart)
            .utc()
            .format(DATE_FORMAT_BETWEEN),
          moment(dateTakenEnd)
            .utc()
            .format(DATE_FORMAT_BETWEEN),
          null,
          '[]'
        );
    });
    if (isBetweenArray.length > 0) {
      isBetween = true;
    }
    return isBetween;
  };

  const FORMAT_DATE = 'MM/DD/YYYY';
  const formatStringDate = (date, format = FORMAT_DATE) => {
    return transformUTCDate(date).format(format);
  };

  const isValidDateAvalibledDays = (dateStart, dateEnd, type) => {
    let isAvalible = false;
    let dateCurrent = dateStart;
    let arrayDate = [];
    while (
      dateCurrent.utc().format(DATE_FORMAT_BETWEEN) <
      dateEnd.utc().format(DATE_FORMAT_BETWEEN)
    ) {
      arrayDate.push(
        moment(dateCurrent)
          .utc()
          .format(DATE_FORMAT_BETWEEN)
      );
      dateCurrent = moment(dateCurrent).add(1, 'day');
    }
    const abscencesArray =
      getPtoEmployeeData?.getPtoEmployee?.approved?.filter(abc => {
        return isBetweenDate(arrayDate, abc.dateTakenStart, abc.dateTakenEnd);
      }) || [];
    const abscencesRequestArray =
      getPtoEmployeeData?.getPtoEmployee?.pending?.filter(request => {
        return isBetweenDate(arrayDate, request.startDate, request.endDate);
      }) || [];

    if (abscencesArray?.length > 0 || abscencesRequestArray?.length > 0) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_USED_DAYS}!.`);
      isAvalible = true;
      return isAvalible;
    }
    if (
      infoTimeOff?.daysOff < arrayDate.length &&
      type === ABSCENCE_TYPE.DAY_OFF
    ) {
      setMessage(`${EXCEEDED_AMOUNT_OF_DAYS}: ${infoTimeOff?.daysOff}`);
      isAvalible = true;
      return isAvalible;
    }

    return isAvalible;
  };

  const validationForm = params => {
    const {
      newDateStart,
      newDateEnd,
      today,
      daysAdvanceToRequestTimeOff,
      type
    } = params;

    let response = {
      valid: true
    };
    if (
      newDateStart.diff(today, 'days') + 1 < daysAdvanceToRequestTimeOff &&
      type === ABSCENCE_TYPE.DAY_OFF
    ) {
      response = {
        message: `${ABSCENCE_REQUEST_EXCEED_DAYS} (${daysAdvanceToRequestTimeOff}) to generate a day off!.`,
        valid: false
      };
      setMessage(
        `${ABSCENCE_REQUEST_EXCEED_DAYS} (${daysAdvanceToRequestTimeOff}) to generate a day off!.`
      );
      setAlert(true);
    } else if (isValidDateAvalibledDays(newDateStart, newDateEnd, typeTime)) {
      response = {
        message: `${ABSCENCE_REQUEST_CONTAINS_USED_DAYS}!.`,
        valid: false
      };
      setAlert(true);
    }

    return response;
  };

  const onSubmit = (item = '') => {
    if (item === '') {
      const startRequest = simpleFormatDate(startDate);
      const endRequest = simpleFormatDate(endDate);
      const starts = listPending.map(el => el.startDate.slice(0, 10));
      const ends = listPending.map(el => el.endDate.slice(0, 10));
      const startsApproved = getPtoEmployeeData?.getPtoEmployee?.approved.map(
        el => el.dateTakenStart.slice(0, 10)
      );
      const endsApproved = getPtoEmployeeData?.getPtoEmployee?.approved.map(
        el => el.dateTakenEnd.slice(0, 10)
      );

      if (
        (infoTimeOff?.daysOff > 0 && infoTimeOff?.daysUsed >= 0) ||
        typeTime === 'SICK'
      ) {
        if (startDate === null && endDate === null) {
          setMessage(`${CHOOSE_START_AND_END_DATE}`);
          setAlert(true);
          return;
        }
        if (startRequest > endRequest) {
          setMessage(`${CHOOSE_START_GREATER_END_DATE}`);
          setAlert(true);
          return;
        }
        if (
          startDate?.toString().includes('Sat') ||
          startDate?.toString().includes('Sun') ||
          endDate?.toString().includes('Sat') ||
          endDate?.toString().includes('Sun')
        ) {
          setMessage(`${YOU_CAN_NOT_REQUEST_WEEKENDS}`);
          setAlert(true);
          return;
        }
        if (
          verifyHolidays(
            startDate,
            getPtoRulesByCompany?.getPtoRulesByCompany?.holidays
          ) ||
          verifyHolidays(
            endDate,
            getPtoRulesByCompany?.getPtoRulesByCompany?.holidays
          )
        ) {
          setMessage(`${ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY}`);
          setAlert(true);
          return;
        }
        if (
          starts.includes(startRequest) ||
          starts.includes(endRequest) ||
          ends.includes(startRequest) ||
          ends.includes(endRequest)
        ) {
          setMessage(`${YOU_CAN_NOT_REQUEST_SAME_DAY}`);
          setAlert(true);
          return;
        }
        if (
          startsApproved.includes(startRequest) ||
          startsApproved.includes(endRequest) ||
          endsApproved.includes(startRequest) ||
          endsApproved.includes(endRequest)
        ) {
          setMessage(`${YOU_CAN_NOT_REQUEST_BOOKED_DAYS}`);
          setAlert(true);
          return;
        }

        setStartDate(new Date());
        setEndDate(null);
        const newDateStart = transformUTCDate(startDate);
        const newDateEnd = transformUTCDate(endDate);
        const today = transformUTCDate(new Date());
        const {
          daysAdvanceToRequestTimeOff
        } = currentUser.companyRole[0].company.timeOffRules;
        let emailsManagerTeams = '';
        if (getAllManagerByCompanyByEmployeeByTeams?.length !== 0) {
          getAllManagerByCompanyByEmployeeByTeams.forEach(teamManager => {
            emailsManagerTeams =
              emailsManagerTeams + teamManager.emailEmployee + ';';
          });
        } else {
          getAllEmailsOwnerAndAdminByCompany.forEach(teamManager => {
            emailsManagerTeams =
              emailsManagerTeams + teamManager.emailEmployee + ';';
          });
        }

        let variables = {
          employeeId: currentUser?.employee?.id,
          dateTakenStart: formatZeroGmtDate(startDate),
          dateTakenEnd: formatZeroGmtDate(endDate || startDate),
          type: typeTime,
          message: description,
          status: ABSCENCE_REQUEST_STATUS.PENDING,
          emails: emailsManagerTeams.slice(0, emailsManagerTeams.length - 1),
          idAbscenceRequest: item
        };

        const correctForm = validationForm({
          newDateStart,
          newDateEnd,
          today,
          daysAdvanceToRequestTimeOff,
          typeTime,
          startDate,
          endDate
        });
        if (correctForm.valid) {
          createMeAbscenceRequest({ variables });
          setLoadingTable(true);
        } else {
          setStartDate(initialDate);
        }
      } else {
        setMessage(`${YOU_DO_NOT_HAVE_DAYS_LEFT}`);
        setAlert(true);
        return;
      }
    } else {
      let emailsManagerTeams = '';
      if (getAllManagerByCompanyByEmployeeByTeams?.length !== 0) {
        getAllManagerByCompanyByEmployeeByTeams.forEach(teamManager => {
          emailsManagerTeams =
            emailsManagerTeams + teamManager.emailEmployee + ';';
        });
      } else {
        getAllEmailsOwnerAndAdminByCompany.forEach(teamManager => {
          emailsManagerTeams =
            emailsManagerTeams + teamManager.emailEmployee + ';';
        });
      }

      let variables = {
        employeeId: currentUser?.employee?.id,
        dateTakenStart: formatZeroGmtDate(formatStringDate(item.startDate)),
        dateTakenEnd: formatZeroGmtDate(
          formatStringDate(item.endDate) || formatStringDate(item.startDate)
        ),
        type: item.type,
        message: item.message,
        status: item.status,
        emails: emailsManagerTeams.slice(0, emailsManagerTeams.length - 1),
        idAbscenceRequest: item.id
      };
      createMeAbscenceRequest({ variables });
      return;
    }
  };

  if (loading) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  const resetFunction = () => {
    endDate && setEndDate();
  };

  const userTeams = getAllTeamsByCompanyByEmployee
    ?.filter(({ managerName, nameEmployee }) => managerName !== nameEmployee)
    .map(({ managerName, nameTeam }) => ({
      manager: managerName,
      name: nameTeam
    }))
    .slice(0, 6);

  return (
    <>
      <ModalAlert
        show={alert}
        onClose={() => setAlert(false)}
        content={message}
        buttonCancel={'Ok, Close'}
        confirmButton={false}
        buttonCancelStyle={'buttonAlertPto'}
        alertClass={'alertIcon'}
        oneButton
      />
      <ContentPTO>
        <div className="card-main-pto">
          <div className="card-pto-title">
            <span className="sub-heading">
              PTO
              <div className="little-subtitle">
                <Exclamation />
                <p>These days will be renewed the following year</p>
              </div>
            </span>
          </div>
          <div className="card-pto-avaliable">
            <div className="avaliable">
              <div>Avaliable:</div>
              <span>{infoTimeOff?.daysOff || 0}</span>
            </div>
            <div className="used">
              <div>PTO days used:</div>
              <span>{infoTimeOff?.daysUsed || 0}</span>
            </div>
            <div className="sick">
              <div>Sick days used:</div>
              <span>{infoTimeOff?.daysSick || 0}</span>
            </div>
          </div>
          <div className="card-pto-title mobile-margin-top">
            <span className="sub-heading">
              Make your request here
              <div className="little-subtitle">
                <div className="exclamation-space">
                  <Exclamation />
                </div>
                <p>
                  Your PTO request must be created for at least 10 days
                  anticipated.{' '}
                </p>
              </div>
            </span>
          </div>
          <div className="card-pto-filter">
            <div className="pto-check">
              <div className="pto-mobile">
                <FormControlLabel
                  value="pto"
                  label="PTO"
                  control={
                    <Radio
                      color="default"
                      checked={!check}
                      onClick={() => {
                        setCheck(!check);
                        setTypeTime(ABSCENCE_TYPE.DAY_OFF);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  value="sick"
                  label="SICK"
                  control={
                    <Radio
                      color="default"
                      checked={check}
                      onClick={() => {
                        setCheck(!check);
                        setTypeTime(ABSCENCE_TYPE.SICK);
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className="pto-dates">
              <div className="pto-fields">
                <span>Start Date</span>
                <div className="pto-calendar-fields">
                  <InputGroup.Text>
                    <CalendarPto />
                  </InputGroup.Text>
                  <DatePickerModule
                    principalClass={'inner-Calendar-pto'}
                    weekendDisabled
                    onChange={value => setStartDate(value)}
                    onClick={() => resetFunction()}
                    minDate={
                      typeTime === ABSCENCE_TYPE.DAY_OFF
                        ? initialDate
                        : new Date()
                    }
                    maxDate={new Date('2000-01-01').setFullYear(
                      new Date().getFullYear() // + 1
                    )}
                    selected={startDate}
                    placeholderText="MM/DD/YYYY"
                    excludeDates={approvedDays}
                  />
                </div>
              </div>
              <div className="pto-fields mobile-right">
                <span>End Date</span>
                <div className="pto-calendar-fields">
                  <InputGroup.Text>
                    <CalendarPto />
                  </InputGroup.Text>
                  <DatePickerModule
                    principalClass={'inner-Calendar-pto'}
                    weekendDisabled
                    onChange={value => setEndDate(value)}
                    minDate={startDate}
                    maxDate={new Date('2000-01-01').setFullYear(
                      new Date().getFullYear() // + 1
                    )}
                    selected={endDate}
                    placeholderText={'MM/DD/YYYY'}
                    excludeDates={approvedDays}
                  />
                </div>
              </div>
            </div>
            <div className="note-pto">
              <span>Note</span>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <Edit />
                </InputGroup.Text>
                <FormControl
                  onChange={({ target }) => setDescription(target.value)}
                  placeholder="Add Note"
                  aria-label="Note"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="btn-pto">
              <Button
                className="btn-success"
                onClick={() => onSubmit()}
                disabled={loadingTable || !startDate || !endDate}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="card-pto-data">
            {loadingTable ? (
              <div className="loading">
                <CircularProgress className="circular-progress" />
              </div>
            ) : (
              <>
                <TabsPto
                  tabActive={tabActive}
                  setTabActive={value => {
                    setTabActive(value);
                  }}
                />

                <div className="content-tabs">
                  {tabActive === 0 ? (
                    listPending?.length ? (
                      listPending?.map((item, i) => (
                        <DropdownPto
                          key={`pto-${i}`}
                          id={`pto-${i}`}
                          item={item}
                          teams={userTeams}
                        >
                          <div className="pto-item">
                            <div className="d-flex">
                              <div className="pto-icon">
                                <IconEvents
                                  icon={
                                    item.type === 'SICK'
                                      ? item.type.toLowerCase()
                                      : 'pto'
                                  }
                                />
                              </div>
                              <div className="pto-date-status">
                                <span className="pto-name-employee">
                                  {userName}
                                </span>
                                <div className="pto-dates">
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(item.startDate)}
                                  </Moment>
                                  {' - '}
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(item.endDate)}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                            <div className="ptobtns-container d-flex align-items-center">
                              <div className="btn-bell">
                                <Button
                                  className="btn-success"
                                  disabled={disabledReminder}
                                  onClick={() => {
                                    setDisabledReminder(true);
                                    onSubmit(item);
                                  }}
                                >
                                  <Bell />
                                  <span>Reminder</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </DropdownPto>
                      ))
                    ) : (
                      <div className="card-pto-no-data">
                        <CheckMark color="black" />
                        <h5 className="text-normal">
                          You have no pending approval
                        </h5>
                      </div>
                    )
                  ) : null}

                  {tabActive === 1 ? (
                    listPast?.length ? (
                      listPast.map((item, i) => {
                        return (
                          <DropdownPto
                            key={`pto-past-${i}`}
                            id={`pto-past-${i}`}
                            item={item}
                            teams={userTeams}
                          >
                            <div className="pto-item">
                              <div className="d-flex">
                                <div className="pto-icon">
                                  <IconEvents
                                    icon={
                                      item.type === 'SICK'
                                        ? item.type.toLowerCase()
                                        : 'pto'
                                    }
                                  />
                                </div>
                                <div className="pto-date-status">
                                  <div className="pto-name-employee">
                                    <Moment format={DATE_FORMAT}>
                                      {transformUTCDate(
                                        item.dateTakenStart || item.startDate
                                      )}
                                    </Moment>
                                    {' - '}
                                    <Moment format={DATE_FORMAT}>
                                      {transformUTCDate(
                                        item.dateTakenEnd || item.endDate
                                      )}
                                    </Moment>
                                  </div>
                                  <div className="pto-status">
                                    {item.status
                                      ? `Declined on ${moment(
                                          item.updatedAt
                                        ).format(DATE_FORMAT)}`
                                      : `Approved on ${moment(
                                          item.updatedAt
                                        ).format(DATE_FORMAT)}`}
                                  </div>
                                </div>
                              </div>
                              <div className="ptobtns-container d-flex align-items-center">
                                <div className="ptobtns-">
                                  <div
                                    className={
                                      item.status
                                        ? 'icon-status-declined'
                                        : 'icon-status-approved'
                                    }
                                  >
                                    <Col>
                                      {item.status ? <Wrong /> : <Check />}
                                      <span>
                                        {item.status ? 'Declined' : 'Approved'}
                                      </span>
                                    </Col>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DropdownPto>
                        );
                      })
                    ) : (
                      <div className="card-pto-no-data">
                        <CheckMark color="black" />
                        <h5 className="text-normal">
                          No PTO or sick Days request
                        </h5>
                      </div>
                    )
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="salary-container">{<Salary home={true} />}</div>
      </ContentPTO>
    </>
  );
};

export default Pto;
