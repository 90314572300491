import styled from 'styled-components';

export const LayoutContainer1 = styled.div`
  background-color: #f0f5f9;
  flex-grow: 1;
  height: 100vh;
  width: 100%;
  @media screen and (max-width: 992px) {
    height: 100%;
    margin-bottom: 80px;
  }
`;

export const LayoutContainer = styled.div`
  background-color: #f0f5f9;
  height: 100vh;
  width: 100%;

  .main-container {
    display: flex;
    justify-content: center;
  }

  .main-container-watchEvents {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .aside-container {
    height: calc(100% - 62px);
    width: 24%;
  }

  .aside-container,
  .sites-container-home {
    display: inline-block;
    vertical-align: top;
    margin: 85px -10px 0px 10px;
    max-width: 360px;
    flex-grow: 1;
    overflow: hidden;
    border-radius: 8px;
  }

  .aside-container,
  .sites-container-else {
    > div:nth-child(1) {
      > div:nth-child(2) {
        margin-top: 36px;
      }
    }
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
    min-width: 360px;
    max-width: 360px;
    height: 100%;
    min-height: 266px;
    max-height: 1080px;
    border-radius: 8px;
    overflow: hidden;
    flex-grow: 1;
  }

  .sites-container-else-watchEvents {
    > div:nth-child(1) {
      > div:nth-child(2) {
        margin-top: 36px;
      }
    }
    display: inline-block-;
    vertical-align: top;
    margin-left: 30px;

    min-width: 30px;
    width: 38px;
    height: 30px;
    border-radius: 8px;
  }

  .sites-container-if-watchEvents {
    > div:nth-child(1) {
      > div:nth-child(2) {
        margin-top: 36px;
      }
    }
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
    max-width: 360px;
    height: 100%;
    border-radius: 8px;
    flex-grow: 1;
  }

  .sites-container {
    max-width: 76%;
    height: calc(100% - 62px);
    margin: 0 15px;
    flex-grow: 1;
  }
  .sites-container-profile {
    width: 90%;
    height: calc(100% - 62px);
    margin: 0 15px;
  }
  .sites-container-watchEvents {
    width: 98%;
    height: calc(100% - 62px);
    margin: 0 15px;
  }
  .lower-windows {
    display: flex;
    margin-left: 25px;
    margin-top: 20px;
  }
  .inner-window-right {
    background-color: white;
    width: 50%;
    height: 300px;
    border-radius: 8px;
    margin-left: 10px;
  }
  .inner-window-left {
    background-color: white;
    width: 50%;
    height: 300px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .inner-window-left > hr,
  .inner-window-right > hr {
    background-color: #e1e4e8;
    opacity: 1;
  }
  .heading {
    height: 40px;
    padding: 10px 0 10px 20px;
  }
  .logoCompensation {
    display: flex;
    flex-direction: column;
    height: 220px;
    align-items: center;
    justify-content: center;
  }
  .underLogo {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 25px;
  }

  @media screen and (max-width: 1024px) {
    .lower-windows {
      margin: 17px 0 0 0;
      flex-direction: column;
    }
    .inner-window-left {
      width: 100%;
    }
    .inner-window-right {
      width: 100%;
      margin: 17px 0 0 0;
    }

    .main-container {
      flex-direction: column;
      align-items: center;
    }
    .main-container-watchEvents {
      flex-direction: column;
      align-items: center;
    }
    .sites-container {
      width: 100%;
      margin: 0px;
      max-width: 100%;
    }
    .sites-container-calendar {
      width: 100%;
      opacity: 0.3;
    }
    .sites-container-profile-opacity {
      width: 100%;
      opacity: 0.3;
    }
    .sites-container-profile {
      width: 95%;
      margin-top: 0px;
    }
    .sites-container-watchEvents {
      width: 95%;
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    .aside-container,
    .sites-container-home {
      margin: 0px;
      height: 0px;
    }
    .aside-container,
    .sites-container-else {
      height: 0;
      margin: 0;
    }
  }
`;
