import React from 'react';

const File = ({ color = 'white', size = '16' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 4.62667H8.35266C8.126 4.62667 7.92533 4.51334 7.80133 4.33334L6.86133 2.95334C6.73466 2.76667 6.528 2.66 6.308 2.66H3.32C2.58333 2.66 1.98666 3.25334 1.98666 3.99334V11.9933C1.98666 12.7267 2.58333 13.3267 3.32 13.3267H12.6533C13.3867 13.3267 13.9867 12.7267 13.9867 11.9933V5.95334C13.9867 5.21734 13.3867 4.62 12.6533 4.62L12.6667 4.62667Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default File;
