import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { AppContext } from '../../Context/App/AppContext';
import { LayoutContainer } from './styles';
import Loadingsimpleteamonboardnew from '../../components/Loader';
import Navbar from '../../components/Navbar';
import Events from '../Views/Events/Events';
import SideConfig from '../Views/SideConfig';
import SearchEmployee from '../../components/SearchEmployee';
import SideDetailEmployee from '../Views/SideDetailEmployee';
import {
  routesAdmin,
  routesOwner,
  routesEmployee
} from '../../services/Constants/paths';
import { PermissionsEmployee } from '../../services/Constants/getDefaults';
import { useMobile } from '../../customHooks/useMobile';
import BarChartAdmin from '../../components/Dashboard/BarChartAdmin';

const showSearch = [routesAdmin.assets];

const Layout = ({ children }) => {
  const location = useLocation();
  const params = useParams();
  const {
    currentUser,
    roleUser,
    watchEvents,
    calendar,
    burgerMenu,
    setContainerHeight
  } = useContext(AppContext);
  const locationEmployee = location.pathname.includes(routesAdmin.employee);

  const locationSkip =
    routesAdmin.home.includes(location.pathname) ||
    location.pathname.includes(routesOwner.config) ||
    routesEmployee.pto.includes(location.pathname) ||
    (location.pathname.includes(routesEmployee.profile) && locationEmployee) ||
    (location.pathname.includes(routesEmployee.pto) && locationEmployee) ||
    (location.pathname.includes(routesEmployee.salary()) && locationEmployee) ||
    (location.pathname.includes('/salary') && locationEmployee) ||
    (location.pathname.includes(routesEmployee.files) && locationEmployee);

  const { width } = useMobile();

  useEffect(() => {
    const heightContainer = document.getElementsByClassName(
      'sites-container'
    )[0]?.clientHeight;
    setContainerHeight(heightContainer - 115);
  }, [width, setContainerHeight]);

  const roleOwnerOrAdmin =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN;
  const company = currentUser?.companyRole
    ? currentUser.companyRole[0].company
    : null;
  const pathConfig = location.pathname.includes('/config/');
  const pathSelectProfile = location.pathname.includes('/selectProfile');
  const pathDetailEmployee = !location?.state?.newEmployee
    ? location.pathname.includes('/employee/')
    : false;
  const pathProfileEmployee =
    location.pathname.includes(routesOwner.createEditTeams()) ||
    location.pathname.includes(routesOwner.createEmployee()) ||
    (location.pathname.includes(routesOwner.editEmployee()) &&
      !location.pathname.includes(routesAdmin.employee));

  if (currentUser && currentUser.companyRole !== undefined) {
    return (
      <LayoutContainer>
        <Navbar
          user={currentUser}
          photo={roleOwnerOrAdmin ? company.logo : currentUser?.photo}
          hideLinks={pathSelectProfile}
        />
        <div
          className={
            locationSkip || watchEvents
              ? 'main-container'
              : 'main-container-watchEvents'
          }
        >
          {pathSelectProfile ? (
            <>{children}</>
          ) : (
            <>
              <div
                className={
                  !pathProfileEmployee
                    ? routesAdmin.home.includes(location.pathname)
                      ? 'sites-container-home'
                      : locationSkip
                      ? 'sites-container-else'
                      : watchEvents
                      ? 'sites-container-if-watchEvents'
                      : 'sites-container-else-watchEvents'
                    : ''
                }
                style={{
                  background:
                    pathConfig || pathDetailEmployee ? 'white' : '#f0f5f9'
                }}
              >
                {pathConfig ? (
                  <SideConfig comapnyName={company?.name} />
                ) : pathDetailEmployee ? (
                  <SideDetailEmployee id={params.id} />
                ) : pathProfileEmployee ? (
                  <></>
                ) : (
                  <Events />
                )}
              </div>
              <div
                className={
                  watchEvents
                    ? pathProfileEmployee && burgerMenu
                      ? 'sites-container-profile-opacity'
                      : pathProfileEmployee
                      ? 'sites-container-profile'
                      : calendar || burgerMenu
                      ? 'sites-container-calendar'
                      : 'sites-container'
                    : 'sites-container-watchEvents'
                }
              >
                {showSearch.includes(location.pathname) ? (
                  <>
                    <SearchEmployee />
                    {children}
                  </>
                ) : (
                  <>{children}</>
                )}
                {routesAdmin.home.includes(location.pathname) && (
                  <div className="lower-windows">
                    <div className="inner-window-left">
                      <div className="heading">
                        <span className="sub-heading">{'Compensation'}</span>
                      </div>
                      <hr />
                      <div className="logoCompensation">
                        <BarChartAdmin
                          bars={[
                            {
                              dataKey: 'low',
                              fill: '#7094C6',
                              stackId: 'a',
                              radius: [0, 0, 5, 5]
                            },
                            {
                              dataKey: 'dashboardhigh',
                              fill: '#314E76',
                              stackId: 'a',
                              radius: [5, 5, 0, 0]
                            }
                          ]}
                          sortBy="high"
                          format={true}
                        />
                      </div>
                    </div>

                    <div className="inner-window-right">
                      <div className="heading">
                        <span className="sub-heading">{'Roles'}</span>
                      </div>
                      <hr />
                      <div className="logoCompensation">
                        <BarChartAdmin
                          bars={[
                            {
                              dataKey: 'totalEmployee',
                              stackId: 'a',
                              fill: '#314E76',
                              radius: [5, 5, 5, 5]
                            }
                          ]}
                          sortBy="totalEmployee"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </LayoutContainer>
    );
  }

  return (
    <div className="fullPageLoader">
      <Loadingsimpleteamonboardnew loop={true} />
    </div>
  );
};

export default Layout;
