import React from 'react';

const ArrowDesc = () => {
  return (

    <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >


       <path   
       d="M8.875 3L8.875 15.75" 
       stroke="#0366D6" 
       strokeWidth="1.13" 
       strokeLinecap="round" 
       strokeLinejoin="round"
       />

       <path
        d="M8.875 3L7 4.875" 
        stroke="#0366D6" 
        strokeWidth="1.13" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />

       <path 
       d="M10.75 4.875L8.875 3" 
       stroke="#0366D6" 
       strokeWidth="1.13" 
       strokeLinecap="round" 
       strokeLinejoin="round"
       />
    
    </svg>


  );
};

export default ArrowDesc;
