import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownEmployeeRoleContainer } from './styles';
import ListDropdown from "../Icons/ListDropdown";

const DropdownEmployeeRole = ({ roles,  roleUserSeparate, setRoleUserSeparate }) => {

  return (
    <DropdownEmployeeRoleContainer>
        <Dropdown className='clickLabel'>
        <Dropdown.Toggle>
          <span className="text-normal">
            {roleUserSeparate?.name || 'Select Role'}
          </span>
          <ListDropdown /> 
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {roles.map((item, i) => (
            <Dropdown.Item
              key={`item-salary-transaction-${i}`}
              onClick={() => setRoleUserSeparate(item)}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>


    </DropdownEmployeeRoleContainer>
  );
};

export default DropdownEmployeeRole;
