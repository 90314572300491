import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import { ContentSalary } from './styles';
import DropdownSalaryTransaction from '../../../components/DropdownSalaryTransaction';
import Edit from '../../../components/Icons/Edit';
import Coin from '../../../components/Icons/Coin';
import TableSalary from './TableSalary';
import { useSalaryHistoryQuery } from '../../../graphql/container/query/salary';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import {
  CREATE_HISTORY_SALARY_BY_EMPLOYEE,
  UPDATE_HISTORY_SALARY_MUTATION
} from '../../../graphql/queries/employees';
import { ModalEditHistorySalary } from '../../../components/Modals';
import { AppContext } from '../../../Context/App/AppContext';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { routesEmployee, routesAdmin } from '../../../services/Constants/paths';
import { useGetProfileQuery } from '../../../graphql/container/query/profile';
import DatePickerModule from '../../../components/DatePickerModal';
import CalendarPto from '../../../components/Icons/CalendarPto';
import BarChartAdmin from '../../../components/Dashboard/BarChartAdmin';


const Salary = ({ home }) => {

  const { currentUser, isAdmin } = useContext(AppContext);

  const initialsColumns = [
    {
      name:  isAdmin ? '' :'Date',
      key: 'date',
      active: true
    },
    {
      name: isAdmin ? '' : 'Type',
      key: 'type_of_transaction',
      active: true
    },
    {
      name: isAdmin ? '' :'Amount',
      key: 'amount',
      active: true
    },
    {
      name: isAdmin ? '' : 'Note',
      key: 'description',
      active: true
    },
    {
      name: '',
      key: 'action',
      active: true
      // width: '10%'
    }
  ];

  const location = useLocation();

  const employee = isAdmin
    ? location.pathname.split('/')[2]
    : currentUser?.employee?.id;
  const initColumns = initialsColumns;
  if (!isAdmin) {
    initColumns[4].active = false;
  }
  const isMounted = useRef(false);
  const [columnsTable] = useState(initColumns);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCharCompensation, setLoadingCharCompensation] = useState(false);
  const [salaryInfo, setSalaryInfo] = useState();
  const [description, setDescription] = useState('');
  const [typeTransaction, setTypeTransaction] = useState();
  const [amountSalary, setAmountSalary] = useState();
  const [date, setDate] = useState();
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [salaryToEdit, setSalaryToEdit] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});

  const {
    data: dataHistorySalary,
    loading: loadingSalaryHistory,
    refetch: refetchSalaryHistory
  } = useSalaryHistoryQuery(employee, isAdmin);

  const {
    data: dataProfile,
    loading: loadingEmployee,
    refetch: refetchEmployee
  } = useGetProfileQuery(employee, isAdmin);

  const [createSalary, { loading: createSalaryLoading }] = useMutation(
    CREATE_HISTORY_SALARY_BY_EMPLOYEE
  );

  const [updateSalary, { loading: updateSalaryLoading }] = useMutation(
    UPDATE_HISTORY_SALARY_MUTATION
  );

  useEffect(() => {
    const historySalary = isAdmin
      ? dataHistorySalary?.getHistorySalaryByEmployeeId
      : dataHistorySalary?.getMeHistorySalaryByEmployeeId;
    if (historySalary) {
      isMounted.current = true;
      setSalaryInfo(historySalary);
      setLoadingTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHistorySalary]);

  useEffect(() => {
    const profile = isAdmin
      ? dataProfile?.getProfileInfo
      : dataProfile?.getMeProfileInfo;
    if (profile) {
      isMounted.current = true;
      setEmployeeInfo(profile);
      setLoadingTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProfile]);

  useEffect(() => {
    if (loadingTable && !createSalaryLoading && !updateSalaryLoading) {
      refetchSalaryHistory({ employeeId: employee });
      refetchEmployee({ employeeId: employee });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSalaryLoading, updateSalaryLoading]);

  const submitNewHistorySalary = () => {
    setLoadingTable(true);
    createSalary({
      variables: {
        transactionDate: new Date(date),
        type: typeTransaction.type,
        employee: employee,
        amount: parseFloat(amountSalary),
        description,
        newSalary:
          parseFloat(employeeInfo?.salary || 0) + parseFloat(amountSalary || 0)
      }
    });
    setTypeTransaction();
    setAmountSalary();
    setDescription('');
    setDate();
  };

  const editSalary = salary => {
    setLoadingTable(true);
    updateSalary({
      variables: {
        id: salary.id,
        transactionDate: new Date(salary.transactionDate || salary.createdAt),
        employee: employee,
        amount: parseFloat(salary.amount), 
        type: salary.type,
        description: salary.description,
        newSalary:  parseFloat(employeeInfo?.salary || 0) + parseFloat(salary.amountAdjustment || 0)
      }
    });
    setSalaryToEdit({});
    setOpenModalEdit(false);
  };

  if (loadingSalaryHistory && loadingEmployee && !isMounted.current && !loadingCharCompensation) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={!isAdmin ? routesEmployee.profile : routesAdmin.employees}
        subtitle={
          !isAdmin
            ? 'Salary History'
            : (employeeInfo?.firstName ? employeeInfo?.firstName : '') +
              ' ' +
              (employeeInfo?.lastName ? employeeInfo?.lastName : '')
        }
        section={!isAdmin ? 'Profile' : 'Employees'}
        home={home}
      />
      <ContentSalary>
        <div className="salary-header">
          <h3 className="sub-heading">Salary</h3>
        </div>
        <div className="salary-personal-info">
          <div>
            <p className="text-normal">Role</p>
            <span className="heading-h1">
              {employeeInfo?.employeeRoleInfo?.name || ''}
            </span>
          </div>
          <div>
            <p className="text-normal">Current Salary</p>
            <span className="heading-h1">
              $ {employeeInfo?.salary?.toLocaleString('en-US') || ''}
            </span>
          </div>
          {typeTransaction?.type === 'salaryAdjustment' ? (
            <div>
              <p className="text-normal">New Salary</p>
              <span className="heading-h1">
              $ {parseFloat(employeeInfo?.salary || 0) +
                  parseFloat(amountSalary || 0)}
              </span>
            </div>
          ) : null}
        </div>
        {isAdmin ? (
          <div className="salary-inputs">
            <div>
              <span> Date </span>
              <div className="pto-calendar-fields clickLabel">
              <InputGroup.Text>
                <CalendarPto />
              </InputGroup.Text>
              <DatePickerModule
                principalClass={'inner-calendar-salary'}
                onChange={value => setDate(value)}
                selected={date}
                placeholderText="MM/DD/YYYY"
              />
              </div>
            </div>
            <div>
            <span> Type </span>
            <DropdownSalaryTransaction
              onSelectItem={setTypeTransaction}
              value={typeTransaction}
            />
            </div>
            <div>
            <span> Amount </span>
              <InputGroup className='clickLabel'>
                <InputGroup.Text id="basic-addon">
                  <Coin color="black" />
                </InputGroup.Text>
                <Form.Control
                  value={amountSalary || ''}
                  onChange={({ target }) => setAmountSalary(target.value)}
                  type="number"
                  min={-5000}
                  max={5000}
                  placeholder="Type the Amount"
                  aria-describedby="basic-addon"
                />
              </InputGroup>
            </div>
            <div>
            <span> Note </span>
              <InputGroup className='clickLabel'>
                <InputGroup.Text id="basic-addon1">
                  <Edit />
                </InputGroup.Text>
                <Form.Control
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  placeholder="Add Note"
                  aria-label="Note"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div>
              <Button
                className="btn-success"
                onClick={() => submitNewHistorySalary()}
                disabled={
                  loadingTable ||
                  !date ||
                  !parseFloat(amountSalary) ||
                  !typeTransaction
                }
              >
                Add
              </Button>
            </div>
          </div>
        ) : null}
        <div className="info-salary">
          <TableSalary
            dataSource={salaryInfo}
            loading={loadingTable}
            columns={columnsTable}
            onClickEdit={item => {
              item.amountAdjustment = 0; 
              setSalaryToEdit(item);
              setOpenModalEdit(true);
            }}
          />
        </div>
        <ModalEditHistorySalary
          salary={salaryToEdit}
          show={openModalEdit}
          onClose={() => setOpenModalEdit(false)}
          onConfirm={item => editSalary(item)}
          setAmountSalary={setAmountSalary}
          amountSalary={amountSalary}
        />

         {isAdmin ? (
                  <div className="lower-windows">
                    <div className="inner-window-left">
                      <div className="heading">
                        <span className="sub-heading">{'Compensation'}</span>
                      </div>
                      <hr />
                      <div className="logoCompensation">
                        <BarChartAdmin
                          bars={[
                            {
                              dataKey: 'low',
                              fill: '#7094C6',
                              stackId: 'a',
                              radius: [0, 0, 5, 5]
                            },
                            {
                              dataKey: 'dashboardhigh',
                              fill: '#314E76',
                              stackId: 'a',
                              radius: [5, 5, 0, 0]
                            }
                          ]}
                          sortBy="high"
                          format={true}
                        />
                      </div>
                    </div>
                  </div>
         ) : null
        }
      </ContentSalary>
    </>
  );
};

export default Salary;
