import React, { useEffect } from 'react';

import { useGetSignedUrlToGetS3Query } from '../../../graphql/container/query/files';

const DownloadFile = ({ children, url, filename }) => {
  const [
    getUrl,
    { data, loading: getSignedUrlLoading }
  ] = useGetSignedUrlToGetS3Query();

  useEffect(() => {
    if (data?.getSignedUrlToGets3 && !getSignedUrlLoading) {
      async function downloadFileUrl() {
        try {
          const link = document.createElement('a');
          link.style = 'display: none';
          link.href = data?.getSignedUrlToGets3;
          link.target = '_blank';
          link.download = `${filename}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.log(error);
        }
      }
      downloadFileUrl();
    }
    // eslint-disable-next-line
  }, [data]);

  const getSignedUrl = () => {
    if (url) {
      getUrl({
        variables: {
          url,
          filename
        }
      });
    }
  };

  return (
    <>
      <div onClick={getSignedUrl} style={{ cursor: 'pointer' }}>
        {children}
      </div>
    </>
  );
};

export default DownloadFile;
