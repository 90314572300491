import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import TableEmployees from './TableEmployees';
import { ContentEmployees, HeaderEmployees } from './styles';
import { useGetEmployeesSortByCompanyQuery } from '../../../graphql/container/query/employees';
import { AppContext } from '../../../Context/App/AppContext';
import Arrowleft from '../../../components/Icons/ArrowLeft';
import ArrowRight from '../../../components/Icons/ArrowRight';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { formatStringDate } from '../../../services/Utils/getUtils';
import Download from '../../../components/Icons/Download';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { routesOwner } from '../../../services/Constants/paths';
import AlertMessage from '../../../components/AlertMessage';
import { AlertMessageContext } from '../../../Context/AlertMessageContext';
import ButtonFilterEmployees from '../../../components/Button/ButtonFilterEmployees';
import { useRolesByCompanyQuery } from '../../../graphql/container/query/roles';
import { SearchEmployeeContainer } from '../../../components/SearchEmployee/styles.js';
import { initialsColumns } from './fields';
import { useTeamsByCompanyQuery } from '../../../graphql/container/query/teams';
import { useGetCountriesQuery } from '../../../graphql/container/query/company';
import Search from '../../../components/Icons/Search';
import { FormControl, InputGroup } from 'react-bootstrap';

const Employees = () => {
  const location = useLocation();
  const [cacheFilters, setCacheFilters] = useState(readCookies('Filters'));
  const { currentUser, watchEvents } = useContext(AppContext);
  const { errorMessage, successMessage } = useContext(AlertMessageContext);
  const companyId = currentUser?.companyRole[0].to;

  const {
    data: { getEmployeesByCompany },
    loading: loadingEmployees
  } = useGetEmployeesSortByCompanyQuery(companyId);

  const isMounted = useRef(false);
  const navigate = useNavigate();
  const cacheFiltersSize = cacheFilters?.length;

  const [columnsTable, setColumnsTable] = useState(
    cacheFiltersSize
      ? cacheFilters[2]
        ? JSON.parse(cacheFilters[2])
        : initialsColumns
      : initialsColumns
  );
  const [sortState, setSortState] = useState(
    cacheFiltersSize
      ? cacheFilters[4]
        ? JSON.parse(cacheFilters[4])
        : 'startDate'
      : 'startDate'
  );
  const [sortAscDesc, setSortAscDesc] = useState(
    cacheFiltersSize
      ? cacheFilters[5]
        ? JSON.parse(cacheFilters[5])
        : 'DESC'
      : 'DESC'
  );
  const [status, setStatus] = useState(true);
  const [teams, setTeams] = useState();
  const [countries, setCountries] = useState();
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialRoles, setInitialRoles] = useState();
  const [employeesAmount, setEmployeesAmount] = useState();
  const [sliderOn, setSliderOn] = useState(
    cacheFiltersSize
      ? cacheFilters[1]
        ? JSON.parse(cacheFilters[1])
        : true
      : true
  );
  const [employeesToExport, setEmployeesToExpor] = useState([]);
  const [columnsActives, setColumnsActive] = useState();
  const [columnsInactive, setColumnsInactive] = useState();
  const [loadingDataEmployees, setLoadingDataEmployees] = useState(true);
  const prev = offset - (limit - 1) > 0;
  const amountLimit = [10, 25, 50, 100];
  const next = currentPage * limit < employeesAmount;

  const {
    data: { getAllTeamsByCompanyIdInfoTable }
  } = useTeamsByCompanyQuery(companyId);

  const {
    data: { getCountries }
  } = useGetCountriesQuery();

  const sortByTeamsNames = data => {
    const sortTeam = data
      ?.sort((a, b) => {
        const primaryValue = a.team.name;
        const secondaryValue = b.team.name;
        if (primaryValue > secondaryValue) {
          return 1;
        }
        if (primaryValue < secondaryValue) {
          return -1;
        } else {
          return 0;
        }
      })
      .map(el => el?.team.name)
      .join(', ');

    return sortTeam;
  };

  const sortByManagerTeams = data => {
    const listAllManager = data
      .map(item => teams?.filter(team => ( (team?.id === item?.teamId) && !(item?.isManager))))
      .flat()
      .map(el => el?.manager);

    const listManager = listAllManager.filter((item, index) => {
      return listAllManager.indexOf(item) === index;
    });
    const sortTeam = listManager
      ?.sort((a, b) => {
        const primaryValue = a.toLowerCase();
        const secondaryValue = b.toLowerCase();
        if (primaryValue > secondaryValue) {
          return 1;
        }
        if (primaryValue < secondaryValue) {
          return -1;
        } else {
          return 0;
        }
      })
      .join(', ');

    return sortTeam;
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [getEmployeesByCompany]);

  useEffect(() => {
    if (getEmployeesByCompany?.employees) {
      getEmployeesByCompany.employees.forEach(function(object) {
        object.teamsForName = sortByTeamsNames(object.employeeTeam);
        object.managersForName = sortByManagerTeams(object.employeeTeam);
      });
    }
    setLoadingDataEmployees(false);
  }, [currentEmployees, getEmployeesByCompany?.employees]);

  useEffect(() => {
    if (getCountries) setCountries(getCountries);
  }, [getCountries]);

  useEffect(() => {
    if (getAllTeamsByCompanyIdInfoTable)
      setTeams(
        getAllTeamsByCompanyIdInfoTable?.map(item => ({
          ...item,
          active: false
        }))
      );
  }, [getAllTeamsByCompanyIdInfoTable]);

  const [activeRoles, setActiveRoles] = useState(
    cacheFilters?.[3]
      ? cacheFilters[3]
        ? JSON.parse(cacheFilters[3])
        : []
      : []
  );

  useEffect(() => {
    if (
      columnsTable?.length &&
      activeRoles !== {} &&
      activeRoles &&
      sortState &&
      sortAscDesc
    ) {
      saveCookies();
      setCacheFilters(readCookies('Filters'));
    }
    // eslint-disable-next-line
  }, [sliderOn, columnsTable, activeRoles, sortState, sortAscDesc]);


  const orderBySortField = (data, field) => {
    const sortTeam =
      sortAscDesc === 'ASC'
        ? data &&
          data.sort((a, b) => {
            const primaryValue = a[field] ? a[field].toLowerCase() : '';
            const secondaryValue = b[field] ? b[field].toLowerCase() : '';
            if (primaryValue > secondaryValue) {
              return 1;
            }
            if (primaryValue < secondaryValue) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            const primaryValue = a[field] ? a[field].toLowerCase() : '';
            const secondaryValue = b[field] ? b[field].toLowerCase() : '';
            if (primaryValue < secondaryValue) {
              return 1;
            }
            if (primaryValue > secondaryValue) {
              return -1;
            } else {
              return 0;
            }
          });
    return sliderOn
      ? setActiveEmployees(sortTeam)
      : setCurrentEmployees(sortTeam);
  };

  const orderBySortFields = (data, fieldOne, fieldTwo) => {
    const sortTeam =
      sortAscDesc === 'ASC'
        ? data &&
          data.sort((a, b) => {
            const primaryValue = a[fieldOne][fieldTwo]
              ? a[fieldOne][fieldTwo].toLowerCase()
              : '';
            const secondaryValue = b[fieldOne][fieldTwo]
              ? b[fieldOne][fieldTwo].toLowerCase()
              : '';
            if (primaryValue > secondaryValue) {
              return 1;
            }
            if (primaryValue < secondaryValue) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            const primaryValue = a[fieldOne][fieldTwo]
              ? a[fieldOne][fieldTwo].toLowerCase()
              : '';
            const secondaryValue = b[fieldOne][fieldTwo]
              ? b[fieldOne][fieldTwo].toLowerCase()
              : '';
            if (primaryValue < secondaryValue) {
              return 1;
            }
            if (primaryValue > secondaryValue) {
              return -1;
            } else {
              return 0;
            }
          });
    return sliderOn
      ? setActiveEmployees(sortTeam)
      : setCurrentEmployees(sortTeam);
  };

  const orderBySortNumberField = (data, field) => {
    const sortEmployee =
      sortAscDesc === 'ASC'
        ? data &&
          data.sort((a, b) => {
            const primaryValue = a[field] ? a[field] : 0;
            const secondaryValue = b[field] ? b[field] : 0;
            return primaryValue - secondaryValue;
          })
        : data &&
          data.sort((a, b) => {
            const primaryValue = a[field] ? a[field] : 0;
            const secondaryValue = b[field] ? b[field] : 0;
            return secondaryValue - primaryValue;
          });
    return sliderOn
      ? setActiveEmployees(sortEmployee)
      : setCurrentEmployees(sortEmployee);
  };

  const orderBySortNumberFields = (data, fieldOne, fieldTwo) => {
    const sortEmployee =
      sortAscDesc === 'ASC'
        ? data &&
          data.sort((a, b) => {
            const primaryValue = a[fieldOne][fieldTwo]
              ? a[fieldOne][fieldTwo]
              : 0;
            const secondaryValue = b[fieldOne][fieldTwo]
              ? b[fieldOne][fieldTwo]
              : 0;
            return primaryValue - secondaryValue;
          })
        : data &&
          data.sort((a, b) => {
            const primaryValue = a[fieldOne][fieldTwo]
              ? a[fieldOne][fieldTwo]
              : 0;
            const secondaryValue = b[fieldOne][fieldTwo]
              ? b[fieldOne][fieldTwo]
              : 0;
            return secondaryValue - primaryValue;
          });
    return sliderOn
      ? setActiveEmployees(sortEmployee)
      : setCurrentEmployees(sortEmployee);
  };

  const handleSortTeam = value => {
    const data = sliderOn ? activeEmployees : currentEmployees;

    if (data.length > 0) {
      switch (value) {
        case 'name':
          orderBySortField(data, 'fullName');
          break;
        case 'role':
          orderBySortFields(data, 'employeeRoleInfo', 'name');
          break;
        case 'salary':
          orderBySortNumberField(data, 'salary');
          break;
        case 'startDate':
          orderBySortField(data, 'startDate');
          break;
        case 'schedule':
          orderBySortField(data, 'schedule');
          break;
        case 'teams':
          orderBySortField(data, 'teamsForName');
          break;
        case 'managers':
          orderBySortField(data, 'managersForName');
          break;
        case 'status':
          orderBySortField(data, 'status');
          break;
        case 'documentId':
          orderBySortNumberFields(data, 'contactInformation', 'documentId');
          break;
        case 'address':
          orderBySortFields(data, 'contactInformation', 'addressOne');
          break;
        case 'addressTwo':
          orderBySortFields(data, 'contactInformation', 'addressTwo');
          break;
        case 'city':
          orderBySortFields(data, 'contactInformation', 'city');
          break;
        case 'state':
          orderBySortFields(data, 'contactInformation', 'state');
          break;
        case 'country':
          orderBySortFields(data, 'contactInformation', 'country');
          break;
        case 'postalCode':
          orderBySortFields(data, 'contactInformation', 'postalCode');
          break;
        case 'nationality':
          orderBySortFields(data, 'contactInformation', 'nationality');
          break;
        case 'dateOfBirth':
          orderBySortField(data, 'dateOfBirth');
          break;
        case 'value':
          orderBySortFields(data, 'customInformation', 'value');
          break;
        case 'companyEmail':
          orderBySortFields(data, 'user', 'email');
          break;
        case 'personalEmail':
          orderBySortFields(data, 'contactInformation', 'personalEmail');
          break;
        case 'phone':
          orderBySortFields(data, 'contactInformation', 'phone');
          break;
        case 'bankName':
          orderBySortFields(data, 'paymentInformation', 'bankName');
          break;
        case 'bankAccount':
          orderBySortFields(data, 'paymentInformation', 'bankAccount');
          break;
        case 'bankSwiftCode':
          orderBySortFields(data, 'paymentInformation', 'bankSwiftCode');
          break;
        case 'routingNumber':
          orderBySortNumberFields(data, 'paymentInformation', 'routingNumber');
          break;
        case 'intermediaryRouting':
          orderBySortNumberFields(
            data,
            'paymentInformation',
            'intermediaryRouting'
          );
          break;
        case 'default':
          break;
      }
    }
  };

  useEffect(() => {
    const active = columnsTable?.filter(item => item.active === true);
    const inactive = columnsTable?.filter(item => item.active === false);
    setColumnsInactive(inactive);
    setColumnsActive(active);
  }, [columnsTable]);

  const handlePage = num => {
    setOffset(limit * (num - 1));
    setCurrentPage(num);
  };
  const handleLimit = value => {
    let numLimit = parseInt(value);
    setOffset(0);
    setCurrentPage(1);
    setLimit(numLimit);
  };

  useEffect(() => {
    if (getEmployeesByCompany) {
      dataToExport(sliderOn ? activeEmployees : currentEmployees);
    }
  }, [activeEmployees, currentEmployees, activeRoles, columnsInactive, sliderOn ]);

  useEffect(() => {
    setEmployeesAmount(
      sliderOn ? activeEmployees?.length : currentEmployees?.length
    );
    handleSortTeam(sortState);
  }, [activeEmployees, currentEmployees, sliderOn]);

  const {
    data: { getAllRolesByCompanyId }
  } = useRolesByCompanyQuery(companyId);

  useEffect(() => {
    if (getAllRolesByCompanyId)
      setInitialRoles(sortFieldsForName(getAllRolesByCompanyId));
  }, [getAllRolesByCompanyId]);

  useEffect(() => {
    let rolesNames = initialRoles?.map(el => el.name);

    if (rolesNames) {
      rolesNames.sort();

      const objRolesCache = cacheFilters?.length
        ? JSON.parse(cacheFilters[3])
        : [];
      const objRoles = rolesNames?.reduce((total, actual) => {
        return {
          ...total,
          [actual]:
            objRolesCache[actual] || Object.entries(objRolesCache)?.length === 0
              ? true
              : false
        };
      }, {});

      setActiveRoles(objRoles);
    }
  }, [initialRoles]);

  useEffect(() => {
    if (getEmployeesByCompany) {
      setFilterEmployees(getEmployeesByCompany?.employees)
    }
  }, [getEmployeesByCompany, activeRoles]);

  const dataToExport = async data => {
    const fieldsInactive = columnsInactive.map(el =>
      el.name
        .replace(' ', '')
        .replace(' ', '')
        .replace('-', '')
    );

    let innerExport = data.map(el => Object.assign({}, el));

    innerExport.map(el => {
      delete el.firstName;
      delete el.lastName;
      delete el.id;
      delete el.middleName;
      delete el.mothersMaidenName;
      delete el.permissionId;
      delete el.__typename;
      el.Name = el?.fullName;
      el.Role = el?.employeeRoleInfo?.name;
      el.Salary = '$' + el?.salary;
      el.StartDate = el?.startDate ? formatStringDate(el?.startDate) : '';
      el.Dedication = el?.schedule;
      el.Teams = el?.teamsForName;
      el.Managers = el?.managersForName;
      el.Status = el?.status;
      el.ID = el?.contactInformation?.documentId;
      el.Address = el?.contactInformation?.addressOne;
      el.Address2 = el?.contactInformation?.addressTwo;
      el.City = el?.contactInformation?.city;
      el.State = el?.contactInformation?.state;
      el.Country = el?.contactInformation?.country;
      el.ZIP = el?.contactInformation?.postalCode;
      el.Nationality = el?.contactInformation?.nationality;
      el.Birthday = el?.dateOfBirth ? formatStringDate(el?.dateOfBirth) : '';
      el.TshirtSize = el?.customInformation?.value;
      el.CompanyEmail = el?.user?.email;
      el.PersonalEmail = el?.contactInformation?.personalEmail;
      el.Phone = el?.contactInformation?.phone;
      el.BankName = el?.paymentInformation?.bankName;
      el.AccountNumber = el?.paymentInformation?.bankAccount;
      el.SWIFTCode = el?.paymentInformation?.bankSwiftCode;
      el.Routing = el?.paymentInformation?.routingNumber;
      el.IntermediaryBankRouting = el?.paymentInformation?.intermediaryRouting;
    });

    const secondExport = innerExport.map(el => Object.assign({}, el));

    secondExport.map(el => {
      delete el.fullName;
      delete el.salary;
      delete el.schedule;
      delete el.startDate;
      delete el.status;
      delete el.dateOfBirth;
      delete el.contactInformation;
      delete el.employeeRoleInfo;
      delete el.employeeTeam;
      delete el.paymentInformation;
      delete el.customInformation;
      delete el.permissionEmployee;
      delete el.user;
      delete el.managersForName;
      delete el.teamsForName;
    });

    secondExport.map(el => {
      if (fieldsInactive.map(item => !el[item])) {
        fieldsInactive.map(item => {
          return delete el[item];
        });
      }
    });

    setEmployeesToExpor(secondExport);
  };

  useEffect(() => {
    if (getEmployeesByCompany) {
      filterRoles();
    }
    // eslint-disable-next-line
  }, [activeRoles, getEmployeesByCompany]);

  const filterRoles = () => {
    const rolesFalse = initialRoles?.filter(
      el => activeRoles[el.name] === false
    );
    const rolesFalseNames = rolesFalse?.map(el => el.name);
    if (rolesFalse?.length && getEmployeesByCompany?.employees) {
        setFilterEmployees(getEmployeesByCompany?.employees?.filter(
        el => !rolesFalseNames?.includes(el?.employeeRoleInfo?.name)
      ))
    }
  };

  useEffect(() => {
    setActiveEmployees(filterEmployees.filter(el => el.status === 'ACTIVE'));
    setCurrentEmployees(filterEmployees);
  }, [filterEmployees]);

  useEffect(() => {
    handleSortTeam(sortState);
  }, [sortState, sortAscDesc, getEmployeesByCompany]);

  if (loadingEmployees) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `Filters=; expires=${dateExpires}`;
    document.cookie = `Filters=***${JSON.stringify(
      sliderOn
    )}***${JSON.stringify(columnsTable)}***${JSON.stringify(
      activeRoles
    )}***${JSON.stringify(sortState)}***${JSON.stringify(
      sortAscDesc
    )}; expires=31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
  }

  function readCookies() {
    const filters = document?.cookie
      ?.split(';')
      ?.filter(e => e.includes('Filters'))[0]
      ?.split('***');
    return filters ? filters : [];
  }

  function sortFieldsForName(list) {
    if (list.length) {
      list.sort(function(a, b) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
    return list;
  }

  return (
    <div>
      <HeaderEmployees>
        <div className={watchEvents ? 'header-w' : 'header-w-watchEvents'}>
          <div className="pagination">
            <span className="text-header">
              {offset === 0 ? 1 : offset} -{' '}
              {offset +
                (sliderOn
                  ? activeEmployees?.slice(offset, offset + limit).length
                  : currentEmployees?.slice(offset, offset + limit)
                      .length)}{' '}
              of {employeesAmount}
            </span>
            <div className="buttons-paginations">
              <div
                className="btnPagination"
                onClick={() => (prev ? handlePage(currentPage - 1) : null)}
              >
                <Arrowleft />
              </div>
              <span> </span>
              <div
                className="btnPagination"
                onClick={() => (next ? handlePage(currentPage + 1) : null)}
              >
                <ArrowRight />
              </div>
            </div>
            <form>
              <select
                id="selectLimit"
                className="select select-pagination"
                onChange={e => handleLimit(e.target.value)}
              >
                {amountLimit.map((el, i) => {
                  return (
                    <option key={i} value={el} selected={el === 50}>
                      {el}
                    </option>
                  );
                })}
              </select>
            </form>
          </div>
        </div>
      </HeaderEmployees>
      <ContentEmployees>
        {/* Search Component */}
        <div className="section-employees">
          <table className="upper-table">
            <tbody>
              <tr className="upper-row">
                <th className="employees-title">
                  Employees ({employeesAmount})
                  <SearchEmployees
                    employeesData={filterEmployees}
                    setActiveEmployees={setActiveEmployees}
                    setCurrentEmployees={setCurrentEmployees}
                    handlePage={handlePage}
                  />
                  {/* <SearchEmployee incommingClass={'employeesSearchClass'} /> */}
                </th>
                <th className="d-flex">
                  <ButtonFilterEmployees
                    data={JSON.parse(JSON.stringify(columnsTable))}
                    setData={setColumnsTable}
                    classButton="button button_md button_blue-800 me-2"
                    setStatus={setStatus}
                    activeRoles={activeRoles}
                    setActiveRoles={setActiveRoles}
                    initialRoles={initialRoles}
                    filterRoles={filterRoles}
                    sliderOn={sliderOn}
                    setSliderOn={setSliderOn}
                    activeMenu={true}
                    iconColor="#FFF"
                  />

                  <button className="button button_md button_blue-800 me-2">
                    <CSVLink
                      data={employeesToExport}
                      filename="Employees"
                      className="csv-export"
                    >
                      <Download color="#FFF" />
                      <span className="pad-icon text-light">Export</span>
                    </CSVLink>
                  </button>

                  <button
                    className="button button_md button_green-300"
                    onClick={() => {
                      window.history.pushState(null, null, location.pathname);
                      navigate(routesOwner.createEmployee(), {
                        state: { newEmployee: true },
                        replace: true
                      });
                    }}
                  >
                    <span className="pad-icon">Add New</span>
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
          <div className="employees-inner-table">
            <TableEmployees
              columns={columnsTable}
              currentEmployees={
                sliderOn
                  ? activeEmployees?.slice(offset, offset + limit)
                  : currentEmployees?.slice(offset, offset + limit)
              }
              sortState={sortState}
              setSortState={setSortState}
              setSortAscDesc={setSortAscDesc}
              sortAscDesc={sortAscDesc}
              loading={loadingEmployees}
              teams={teams}
              countries={countries}
            />
          </div>
          <div className="bottom-row"></div>
        </div>

        <AlertMessage
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </ContentEmployees>
    </div>
  );
};

const SearchEmployees = ({
  employeesData,
  setActiveEmployees,
  setCurrentEmployees,
  handlePage
}) => {
  const [labelSearch, setLabelSearch] = useState('');

  const searchEmployees = search => {
    if (search.trim() === '') {
      handlePage(1);
      setActiveEmployees(employeesData.filter(el => el.status === 'ACTIVE'));
      setCurrentEmployees(employeesData);
    } else {
      const newState = employeesData.filter(obj => {
        if (
          obj?.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          obj?.lastName.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          return obj;
        }
      });
      handlePage(1);
      setActiveEmployees(newState.filter(el => el.status === 'ACTIVE'));
      setCurrentEmployees(newState);
    }
  };
  return (
    <div className="search">
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setLabelSearch(target.value);
                searchEmployees(target.value);
              }}
              placeholder={'Search employee'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default Employees;
