import styled, { createGlobalStyle } from 'styled-components';
import { typography } from '../components/Typography/typography';

export const GlobalStyles = createGlobalStyle`

/* roboto-regular */
@font-face {
 Font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 400;
  src: url('/fonts/roboto-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 */
@font-face {
 Font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 500;
  src: url('/fonts/roboto-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 */
@font-face {
 Font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 700;
  src: url('/fonts/roboto-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/roboto-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

*{
  box-sizing:border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  font-size: 14px;
  background-color: #f0f5f9;
}
body *{
  font-size: 14px;  
}
.noButtonStyle {
  padding: 0;
  border: none;
  background-color: transparent;
  white-space: nowrap;
}

.clickLabel:focus-within {
  border: 1px solid #444d56 !important;
  border-radius: 4px 4px 4px 4px !important;
  box-shadow: none;
  outline:none;

  span{
    border:none
  }
}

.noButtonStyle-activity{
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: start;
}

.MuiBreadcrumbs-separator{
  display: none !important;
}
.font-small,h3{
  font-size: 14px;  
}
.font-medium, h2{
  font-size: 18px;  
}
.font-large, h1{
  font-size: 24px;  
}
.font-extra-large{
  font-size: 64px;
}

${typography}

.button {
  outline: none;
  border: 0px;
  border-radius: 6px;
}

.button_sm{
  height: 32px;
  padding: 7px 10px;
}

.button_md{
  height: 36px;
  padding: 8px 10px;
}

.button_lg{
  height: 44px;
  padding: 12px 20px;
}

.button_green-300{
  background: #18D399;
  color: #24292E;
}

.button_green-300:hover{
  background: #22C498;
}
.button_green-300:active{
  background: #28AA87;
}

.button_blue-800{
  background: #25446A;
  color: #FFFFFF;
}

.button_blue-800:hover{
  background: #3E6193;
}

.button_blue-800:active{
  background: #172C45;
}

.button_red-400{
  background: #D73A49;
  color: #FFFFFF;
}

.button_red-400:hover{
  background: #CB2431;
}

.button_red-400:active{
  background: #FFFFFF;
  color: #D73A49;
}

.button_gray-900{
  background: #24292E;
  color: #FFFFFF;
}

.button_gray-900:hover{
  background: #6A737D;
}
.button_gray-900:active{
  background: #444D56;
}

.button_green-300:disabled, 
.button_blue-800:disabled, 
.button_gray-900:disabled, 
.button_red-400:disabled {
  background: #F6F8FA;
  color: #6A737D;
}

.btn-success {
  border-radius: 6px !important;
  display: inline-block;
  border: none !important;
  background-color: #18d399 !important;
  color: black !important;
  height: 44px;
}

.btn-success-accept{
  border-radius: 6px !important;
  display: inline-block;
  border: none !important;
  background-color: #18d399 !important;
  color: black !important;
  width: 72px;
  height: 36px;
}

.btn-success-width {
  border-radius: 6px !important;
  display: inline-block;
  border: none !important;
  background-color: #18d399 !important;
  color: black !important;
  width: 47%
}

.btn-danger {
  border-radius: 6px !important;
  display: inline-block;
  border: none;
  background-color: #D73A49 !important;
  
 
  span {
    color: white !important;
  }

  &:hover {
    background-color: #CB2431 !important;
  }
}

.btn-danger-declined{
  border-radius: 6px !important;
  display: inline-block;
  border: none;
  background-color: #D73A49 !important;
  border-color: #D73A49 !important;
  width: 72px;
  height: 36px;
 
  span {
    color: white !important;
  }

  &:hover {
    background-color: #CB2431 !important;
  }
}

.btn-danger-without-bg {
  border-radius: 6px !important;
  display: inline-block;
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #EA4A5A !important;
  height: 44px;
}

.btn-danger-without-bg-width {
  border-radius: 6px !important;
  display: inline-block;
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #EA4A5A !important;
  width: 48%;
}

.buttonDeleteRole{
  width: 95% !important;
  border-radius: 6px !important;
  display: inline-block;
  border: none !important;
  background-color: #18d399 !important;
  color: black !important;
}

.flexButtons{
  display: flex;
  justify-content: space-between;
}
.marginPrms{
  margin: 0 3.5px 0 3.5px;
}

.modalPermission{
  display: flex;
  flex-direction: column; 
}
.permissionsTeams{
  text-align: initial;
  margin-top: 6px;
  margin-bottom: -3px;
}
.weightPermissions{
  weight: 700 !important;
}
.labelsSeparation{
  display: flex;
  flex-direction: column;
  margin: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.labelsSeparationConfirm{
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  justify-content: left;
}
.titleLabelsSeparationConfirmTeams{
  display: flex;
  margin-top: 0 20px 30px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #24292E;
  justify-content: center;
}
.labelsSeparationConfirmTeams{
  display: flex;
  margin-top: 30px 29px 0 29px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #24292E;
  flex-direction: column;
  text-align: left;
    
  li {
    cursor: pointer;
  }
  .title-list{
    margin-bottom: 0;
  }

}
.firstLabes{
  margin-bottom: 8px;
}
.inputsSeparate{
  border: 1px solid #D1D5DA;
  border-radius: 5px;
}
.inputNote{
  padding: 7px;
}
.inputNote:focus{
  border: 1px solid #D1D5DA;
}
.subtitleAlert{
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}
.separateTitle{
  margin-top: 20px;
  margin-left: 20px;
  color: black;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.contentSeparate{
  width: 400px;
  radius: 10px;
  margin-bottom: -10px;
}
.input-amount{
  padding: 0;
}

.sideBorder{
  display: flex;
  align-items: center;
  border-left: 2px solid black;
  padding-left: 4px;
}

.sideNoBorder{
  display: flex;
  align-items: center;
}

.motherClass{
  padding: 15px;
  background-color: #25446a;
  height: 68px;
  display: flex;
  justify-content: center;

}



.fatherClass{
  padding: 0px;
  border-radius: 4px;
}

.modal-alert-delete-role{
  background: #FFFFFF;
}

.modalClose{
  top: 15px;
  right: 23px;
  position: absolute;
}

.svgBox{
  margin: 0 0 29px 0;
}

.less-margin-bottom{
  margin-bottom: -18px;
}

.modal-alert-inside{
  padding: 19px 20px 2px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.innerText{
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.modal-alert {
  .modal-dialog {
    max-width: 400px !important;
    .modal-content {
      border-radius: 10px !important;
      width: 400px !important;

      .alert-location{
        margin-bottom: 29px;
      }

      .modal-body {
        text-align: center;
        .less-margin-bottom{
          margin-bottom: -18px;
        }
        .text-left {
          text-align: left !important;
        }
        .title-input-edit {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0.30000001192092896px;
        }
       
        .content {
          text-align: initial;
          p {
            margin: 29px 0 0 0;
            text-align: center;
          }
          ul {
            margin: 0;
          }
        }
      }
      .modal-footer {
        display: block !important;
        text-align: center !important;
        border: none !important;
        padding: 7px 16px;
        
        button {
          margin: 3px auto 9px auto !important;
          height: 44px;
          width: 176px;
        }
        .oneButton{
          margin: 18px -5px 4px -5px;
        }
      }
    }
  }
}



.content-alert-edit{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.innerLabel{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
}

.label-input-edit {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15000000596046448px;

}

.note-ptoadmin{
  width: 360px;
  margin: 8px 0 4px 0;
  textarea{
    padding: 9px;
    border: 1px solid #D1D5DA;
    border-radius: 5px;
  }
}
.bottom-ptoadmin{
  margin-bottom: -1px;
}
.bottom-salary{
 margin-bottom: -14px;
}
.label-input-salary{
  width: 360px;
  height: 44px;
  border: 1px solid #D1D5DA;
  border-radius: 5px;
  padding: 9px;
}

.sub-heading-editpto{
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  display: flex;
}

.react-datepicker{
  border: 1px solid #efefef !important;
}
.react-datepicker__month-container{
  width: 278px;
}
.react-datepicker__day-names{
  display: flex;
  justify-content: space-around;
}
.react-datepicker__week{
  display: flex;
  justify-content: space-around;
}
.react-datepicker__header{
  background-color: #ffffff !important;
  padding-top: 14px !important;
  height: 87px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  border: 1px solid #efefef !important;
}
.date-header{
  display: flex;
  justify-content: space-around;
}
.button-calendar{
  border: none;
  background-color: #ffffff;
}
.select-calendar{
  border-color: #efefef;
}

.react-datepicker-wrapper{
width: 100%;
}

.inner-Calendar{
  border: 1px solid #efefef;
  border-radius: 4px;
  padding-left: 10px;
}

.inner-calendar-modalalert{
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 10px;
  width: 358px;
  height: 34px;
}

.inner-calendar-salary{
  border: 1px solid #ced4da;
  border-left-width: 0px
  padding-left: 10px;
  width: 113%;
  height: 44px;
  border-radius: 4px 4px 4px 0px;
}:focus {
  border: none;
  outline: none;
  border-radius: 4px 4px 4px 4px;
}

.inner-Calendar-pto{
  border: 1px solid #efefef;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  height: 44px;
}
.inner-calendar-pto-alert{
  border: 1px solid #efefef;
  border-radius: 4px;
  height: 44px;
  width: 360px;
  padding-left: 10px;
  margin-top: 8px;
}
.inner-Calendar-Separate{
  width: 360px;
  height: 44px;
  border: 1px solid #d1d5da;
  border-radius: 5px; 
  padding-left: 10px;
  color: #767676;
}
.MuiInput-root{
  height: 44px;
  padding-left: 10px;
}
.datepicker-container{
  position:relative;
}
.inner-image-calendar{
  position: absolute;
  top: 2px;
  right: 4px;
}



.buttonAlertPto{
  width: 90% !important;
  height: 40px;
  background-color: #18D399 !important;
  border-color: #18D399 !important;
  border-radius: 6px !important;
}

.alertIcon{
  margin-bottom: 29px;
}
.alertPermissions{
  margin-bottom: 29px;
}

.modal-edit-role {
  .modal-dialog {
    max-width: 400px !important;
    .modal-content {
      border-radius: 10px !important;
      width: 400px !important;
      padding: 6px 6px 0 6px;
      .modal-body {
        > div {
          .form-control{
            // border-color: #444D56;
            border-radius: 5px;
            height: 36px;
          }
          p {
            margin: 0 0 8px 0;
          }
        }
        > div:nth-child(4) {
          margin-bottom: 0;
        }
      }
      .modal-footer {
        display: block !important;
        text-align: center !important;
        border: none !important;
        
        button {
          font-weight: 600;
          height: 44px;
          width: 48%;
        }
      }
    }
  }
}

.modal-edit-salary-history {
  .modal-dialog {
    max-width: 400px !important;
    .modal-content {
      border-radius: 10px !important;
      width: 400px !important;
      padding: 6px 6px 0 6px;
      .modal-body {
        > div {
          margin-bottom: 10px;
          p {
            margin: 0 0 8px 0;
          }
        }
        > div:nth-child(3) {
          .input-group {
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            height: 44px;
            .label-sign-currency{ margin-top: 10px; }
            span {
              background-color: #ffffff;
              border: none;
            }
            input {
              border: none;
              padding-left: 5px;
            }
            .form-control {
              box-shadow: none;
            }
          }
        }
        > div:nth-child(4) {
          margin-bottom: 0;

          .input-group {
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            span {
              background-color: #ffffff;
              border: none;
            }
            input {
              border: none;
              padding-left: 5px;
            }
            .form-control {
              box-shadow: none;
            }
          }
        }
      }
      .modal-footer {
        display: block !important;
        text-align: center !important;
        border: none !important;
        
        button {
          width: 48%;
        }
      }
    }
  }
}

.modal-custom-img {
  .modal-dialog {
    max-width: 700px !important;
    .modal-content {
      border-radius: 10px !important;
      width: 700px !important;

      .modal-body {
        > div:nth-child(1) {
          > div:nth-child(1) {
            > div:nth-child(1) {
              padding: 5% 0;
              > div:nth-child(1) {
                > div:nth-child(2) {
                  border: black;
                  border-style: dashed;
                  > div:nth-child(2) {
                    p {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 20px;
                      color: #24292e;
                      font-style: normal;
                    }
                  }
                }
              }
            }
            > div:nth-child(2) {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.icon-add-input {
  cursor: pointer;
}

.bold-text{
  font-weight: bold;  
}
.poup-toast{
  cursor: pointer;
  border-radius: 0px;
}
.title-toast{
  color: #FFFFFF !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
}
.image-toast{
  margin: 0px !important;
  height: 20px;
  width: 20px;
}

.d-flex{
  display: flex;
}

.wrap{
  flex-wrap: wrap;
}

.swalAlertLook{
  background-color: #F0F4F8;
    font-size: 14px;
    color: #484848;
    text-align: initial;
    padding: 15px;
    margin-bottom: 18px;
    margin-top: 24px;
    span{
      color: #172C45;
      font-weight: bold;
    }
}

.swalAlertSpan{
  font-size: 18px;
  color: #484848;
}

h1,h2,h3,h4,h4,h6{
 Font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
h3{
 Font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  margin: 0.5rem 0;
  color: #172C45;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
img{
  max-width:100%;
}
// input[type=number]::-webkit-inner-spin-button, 
// input[type=number]::-webkit-outer-spin-button { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }
.space{
  width: 100%;
  height: 25px;  
}
/* SPINNER */
.fullPageLoader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
} 
.fullPageLoader > div{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  width: 100%;
  height: 100%;
} 
/* daypicker */
.DayPicker-Day--selected{
  border-radius: 0 !important;
  background-color: #18d399 !important;
}
.DayPickerInput-OverlayWrapper{
  width:100%;
}
.DayPickerInput{
  width:46%;
  margin: 0.5rem 1rem;
}
.DayPickerInput input{
  height: 48px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  border: 1px solid #d8d8d8;
  background-color: #eeeeee;
  color: #172c45;
  padding: 1rem;
  outline: 0;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #707070;
  }
  :-ms-input-placeholder {
    color: #707070;
  }
  &.error {
    border: solid 1px #ff5656;
    color: #ff5656;
  }
  &.error::placeholder,
  &.error::-webkit-input-placeholder {
    color: #ff5656;
  }
  &.error:-ms-input-placeholder {
    color: #ff5656;
  }
  &.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
.Header {
  text-decoration:  none;
}
a {
  color: white
}
.form-container {
  margin-right: auto;
  margin-left:  auto;
  max-width: 1024px;
  padding-right: 10px;
  padding-left:  10px;

  @media screen and (max-width: 992px) {
    .subtitlePTO{
      display: flex;
      flex-wrap: wrap;
      font-size: 24px;
      span{
        width: 100%;
      }
    }
  }
  
  h1{
    color:#172C45;
    line-height: 1.2;
  }
}
.citySelect select{
  text-transform: capitalize; 
}
.container {
  margin-right: auto;
  margin-left:  auto;
  padding-right: 10px;
  padding-left:  10px;
}
.container-dashboard{
  margin-right: auto;
  margin-left:  auto;
  max-width: 1170px;
  padding-right: 10px;
  padding-left:  10px;
}
.avatar {
  color: #fff;
  background-color: #172c45;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-shrink: 0;
  margin: 0.5rem;
  text-transform: capitalize;
}

.m-t5 {
    margin-top: 5%;
  }

.fullSize{
  width:100%;
  /* max-width: 770px; */
}

.smallSize{
  width:120px
}

.midSize{
  width: 49.5%;
  flex-grow: inherit!important;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.url-midSize{
  width: 49.7%;
  flex-grow: inherit!important;
  margin-bottom: 1rem;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.url-midSize .midSize{
  width: 100%;
}

label.inputFile {
  height: 36px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #dce1e7;
  color: #172c45;
  padding-left: 8px;
  margin: 1.5rem 0rem;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;

  &:focus {
    border: 1px solid #172c45;
  }

  .inputFileSpan{
      color: #172c45;
      font-size: 14px;
      position: absolute;
      top: -23px;
      left: 0;
    }

  &.error {
    border: solid 1px #ff5656;
    span:nth-last-child(3n){
      color: #ff5656
    }
  }
  & .placeholder{
    color: #484848;
    width: -webkit-fill-available;
  }
  & span{
    margin: 0;
  }
  & div{
    background-color: #172c45;
    color: white;
    height: 36px;
    line-height: 48px;
    padding: 0 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label{
      margin-left: 15px;
      font-size: 14px;
    }
  }
  
}
.nameIconSelect{
  // width:100%;
}
.formContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  @media screen and (max-width: 992px) {
    >hr{
        display: none;
      }
  }  

  .timeOffRulesInputContainer{
    display: flex;
    flex-direction: column;
    width:49.5%;
    @media screen and (max-width: 992px) {
      width: 100%;
    }  
  }
  .updateTimeOffRulesInputContainer{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 992px) {
      margin-top: 15px;
      }  
    .labelupdate-timeOffRules{      
      margin-right: 2%;
      width: 86%;
      @media screen and (max-width: 992px) {
      width: 73%;
      }  
      label{
        display:block;
        margin-bottom: 10px;
        color: #172C45;        
        font-weight: bold;
      }
      span{
        color: #484848;        
      }
    }
    .inputUpdate-timeOffRules{
      width: 12%;
      position:relative;
      @media screen and (max-width: 992px) {
        width: 20%;
      }
    }
  }
  .buttons-container{
    margin-top:34px;
    @media screen and (max-width: 992px) {
        margin-top:20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button{
          width: 48%;
        }
    }
  }
}

.employeeStatus {
    display: block;
    width: 70px;
    height: 22px;
    font-size: 12px;
    text-transform: lowercase;
    text-align: center;
    line-height: 22px;
    &.active {
      background: #d7e1ea;
      color: #1d4069;
    }
    &.inactive {
      background: #ffe7e2;
      color: #ff3333;
    }
    :first-letter {
      text-transform: capitalize;
    }
  }
.arrow-buttons{
  position:absolute;
  z-index:2;
  right: 0;
  top: 0;
  margin-top: 4px;
  margin-right: 7px;

  >span{
    background-color: #72809D;
    height: 14px;
    display: block;
    width:16px;
    border: 1px solid #DCE1E7;
    background: #F7FAFC;
    cursor: pointer;
    svg{
      color: #72809D;
      font-size: 16px;
      width: 16px;
      position: relative;
      top: -1px;
      left: -.5px;
    }
  }
}

.daysOff{
  font: 600 14px Arial;
}

.dropFiles {
  width:100%;
  padding:0 0 1rem 0;
}
.dropFiles span{
  margin:0 0 1rem 0;
  color: #172c45;
  font-size: 14px;
  display: block;
  font-family: 'system-ui';
}

.inline {
  display:flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }
}
.inlineButtons{
  display:flex;
  justify-content: left;
  align-items: center;
}

.fourColumns{
  width:24.3%;
}
.threeColumn{
  width:32.5%;
}

.requiredLabel{
  color:red;
  margin-left: 4px;
}

.container-inputcodephone{
  align-items: start;
}
.container-inputcodephone > div{
  width: 78.4%;
}
.container-inputcodephone > label{
  width: 20%;
  margin:0;
  height: 36px;
  font-size: 14px;
  font-weight: 300;
  line-height: 34px;
  border: 2px solid #dce1e7;
  margin-bottom: 1rem;
  color: #484848;
  outline: 0;
  text-align: left;
  padding-left: 12px;
}
.birthday{
  width:34%;
}
.t-shirt{
  width:14.5%;
}

.buttonWithIcon{
  display:flex;
  justify-content: space-evenly;
  align-items: center
}

.tooltip-error{
  color: #FFFFFF;
  font-family: 'system-ui';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  background-color: #FF3333;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -26px;
  padding: 2px 7px;

  svg{
    position:absolute!important;
    right: 0!important;
    bottom: 0!important;
    left: inherit!important;
    top: inherit!important;
    margin-top: 13px;
  }
}

.inputRelativeSelect{
  position: relative;

  .tooltip-error svg{
    margin-top: 12px;
  }
}
.tooltip-error-document{
  color: #FFFFFF;
  font-family: 'system-ui';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  background-color: #FF3333;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -6px;
  padding: 2px 7px; 
  display:initial!important;
  svg{
    position:absolute!important;
    right: 0!important;
    bottom: 0!important;
    left: inherit!important;
    top: inherit!important;
    margin-top: 0.7rem;
  }
}
.stepTwo .tooltip-error{
  display:none;
}
.profile-view{
  width: 90%;
  max-width: 1024px;
}

.margin-none {
  .personal-data-title{
    margin-bottom:0;
    p{
      margin-bottom:0;
    }
  }
}

.dropdownTeam{
  width: 100% !important;
}

.row-container{
  margin-bottom: 20px;
}

.block{
  display:block!important;
} 
.dropFiles{
 
  .label-info{
    position:relative;
    >div{
      display:flex;
    }
    .tooltip-error svg{
      margin-top: 12px;
    }
  }
}
.buttonWithIcon{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dropFiles .dropZone{
  outline: none;
  padding-top: 3rem;
  padding: 1rem;
  display: flex; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #707070;
  width: 100%;
  min-height:105px;
  /* border: 1px dashed #D8D8D8; */
  background: url('data:image/svg+xml,\
          <svg xmlns="http://www.w3.org/2000/svg">\
            <rect width="100%" height="100%" style="stroke:rgb(216,216,216);\
            strokeWidth: 4px; fill:none; stroke-dasharray: 10px 10px;"/>\
          </svg>');
 /* &:hover{
   background-color: lightblue;
 } */
 & span{
   color: #172C45;
   
   font-family: 'system-ui';
   cursor: pointer;
   display: inline;
 }
 & ul{
   padding: 0;
   list-style-type: none;
   width: 100%;
   margin: 0;
 }
 & li{
   display: flex;
   align-items: center; 
   justify-content: space-between;
   width: 100%;
   border: 1px solid #18D399;
   color: #172C45; 
   padding: .5rem 1rem;
   margin-top: .5rem;
 }
 & i{
  cursor: pointer;
 }
}

.newDropFile{
  border: 1px solid #18D399;
}
.existingDropFile{
  border: 1px solid #172C45;
}
.newDropFile, .existingDropFile{
  span{
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
}
`;

export const ButtonBlue = styled.button`
  height: 48px;
  min-width: 170px;
  background-color: #172c45;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin: 0.5rem 1rem 0.5rem 0;
  outline: none;
  cursor: pointer;
  border: none;
  &:disabled {
    background-color: #d8d8d8;
    color: #707070;
    cursor: not-allowed;
  }
  &.small {
    min-width: 100px;
  }
  &.medium {
    min-width: 170px;
  }
  .circular-progress {
    width: 25px !important;
    height: 25px !important;
    color: #172c45;
  }
  @media screen and (max-width: 992px) {
    /* width: 100%; */
    margin-left: 0;
    margin-right: 0;
  }
`;

export const InputText = styled.input`
  height: 36px;
  font-weight: 300;
  line-height: 22px;
  border: 2px solid #dce1e7;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #484848;
  padding: 0 1rem;
  /*margin: 0.5rem 1rem;*/
  outline: 0;
  width: 100%;
  &:focus {
    border: 2px solid #172c45;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #c6cfda;
  }
  :-ms-input-placeholder {
    font-size: 14px;
    color: #c6cfda;
  }
  &.error {
    border: solid 1px #ff5656;
    // color: #ff5656;
  }
  &.error::placeholder,
  &.error::-webkit-input-placeholder {
    // color: #ff5656;
  }
  &.error:-ms-input-placeholder {
    // color: #ff5656;
  }
  &.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const LabelInput = styled.div`
  /* margin-bottom: 0.2rem; */
  span {
    color: #172c45;
    display: inline-block;
    margin-bottom: 9px;
  }
`;

export const TextArea = styled.textarea`
  line-height: 22px;
  border: 2px solid #dce1e7;
  background-color: #fff;
  color: #484848;
  padding: 1rem;
  margin: 0.5rem 1rem;
  outline: 0;
  resize: none;
  font-family: 'system-ui';
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #707070;
  }
  :-ms-input-placeholder {
    color: #707070;
  }
  &.error {
    border: solid 1px #ff5656;
    color: #ff5656;
  }
  &.error::placeholder,
  &.error::-webkit-input-placeholder {
    color: #ff5656;
  }
  &.error:-ms-input-placeholder {
    color: #ff5656;
  }
  &.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const Select = styled.select`
  height: 36px;
  font-weight: 300;
  line-height: 22px;
  border: 2px solid #dce1e7;
  color: #484848;
  padding: 0.4rem 0.7rem;
  margin: 0.5rem 1rem;
  outline: 0;
  background: none;
  border-radius: none;
  &:focus {
    border: 2px solid #172c45;
  }
  & > option.default {
    color: #707070;
  }
  &.error {
    border: solid 1px #ff5656;
    // color: #ff5656;
  }
  &.error::placeholder,
  &.error::-webkit-input-placeholder {
    // color: #ff5656;
  }
  &.error:-ms-input-placeholder {
    // color: #ff5656;
  }
`;

export const HR = styled.hr`
  width: 100%;
  margin: 1rem auto;
  background-color: #d8d8d8;
  border: none;
  height: 1px;
`;

export const HR0 = styled.hr`
  width: 100%;
  margin: 0;
  background-color: #eeeeee;
  border: none;
  height: 1px;
`;

export const ValidationAlert = styled.div`
  width: 100%;
  margin: 0;
  border: none;
  color: #ff5656;
  & span {
    display: flex;
    align-items: center;
    margin: 0px 16px;
  }
`;

export const H1 = styled.h1`
  font-size: 36px;
  color: #172c45;
  @media screen and (max-width: 992px) {
    font-size: 21px;
  }
`;

export const H2 = styled.h2`
  font-size: 38px;
  color: #172c45;
  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
`;

export const DeleteContainer = styled.div`
  width: 100%;

  hr {
    height: 1px;
    width: 100%;
    background: #d8d8d8;
    border: none;
    margin-top: 2%;
  }

  .deleteContainer {
    display: flex;
    width: 100%;
    margin-top: 1%;
    border-top: 1px solid #eeeeee;
    padding-top: 2%;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      button {
        width: 126px;
      }
    }

    .textContainer {
      width: 90%;

      h3 {
        color: #172c45;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        color: #484848;
        font-size: 14px;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    div button {
      margin-right: 0px;
      margin-top: 10px;
    }
  }
`;

export const OptionsDropdown = styled.div`
  position: relative;
  display: inline-block;
  button {
    background-color: transparent;
    color: #172c45;
    padding: 0;
    font-size: 30px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    :focus {
      border: none;
      outline: none;
    }
    svg {
      font-size: 2.3rem;
    }
  }
  .dropdown-content {
    text-align: left;
    font-size: 14px;
    color: #172c45;
    width: 103px;
    display: block;
    position: absolute;
    right: 0;
    background-color: #fff;
    max-width: 130px;
    z-index: 1;
    border: 1px solid #eeeeee;

    svg {
      color: #172c45;
      height: 26px;
      margin: 0px 10px;
      font-size: 15px;
    }
    div {
      cursor: pointer;
      display: flex;
      justify-content: initial;
      align-items: center;
      height: 32px;
    }
    div:hover {
      background-color: #d7e1ea;
    }
    .delete,
    .delete svg {
      color: #ff5656 !important;
    }
  }
`;
