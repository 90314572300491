import React from 'react';

const NoTeam = ({ width = '48', height = '48' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" fill="#F0F5FA" stroke="white" />
      <g clipPath="url(#clip0_9279_540905)">
        <path
          d="M18.3602 19.3599L19.6872 18.6199C20.5872 18.1099 21.1372 17.1699 21.1372 16.1399C21.0272 14.4199 19.5972 13.1399 17.9172 13.2399C16.4972 13.1699 15.1772 14.1799 14.8472 15.6299C14.7472 16.0299 14.9972 16.4299 15.4072 16.5299C15.8072 16.6199 16.2072 16.3699 16.3072 15.9599C16.4772 15.2099 17.1572 14.6899 17.9172 14.7199C18.8372 14.6699 19.5572 15.3099 19.6172 16.1599C19.6072 16.5899 19.3472 17.0399 18.9272 17.2799L17.5972 18.0099C17.2272 18.2099 17.0972 18.6599 17.2972 19.0199C17.4972 19.3799 17.9472 19.5099 18.3072 19.3099L18.3602 19.3599Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M26.25 18C26.25 22.55 22.55 26.25 18 26.25C13.44 26.25 9.75 22.55 9.75 18C9.75 13.44 13.44 9.75 18 9.75C22.55 9.75 26.25 13.44 26.25 18ZM27.75 18C27.75 12.61 23.38 8.25 18 8.25C12.61 8.25 8.25 12.61 8.25 18C8.25 23.38 12.61 27.75 18 27.75C23.38 27.75 27.75 23.38 27.75 18Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M17.3496 21.8917C17.3496 21.5317 17.6396 21.2417 17.9996 21.2417C18.3496 21.2417 18.6396 21.5317 18.6396 21.8917C18.6296 22.2417 18.3396 22.5317 17.9896 22.5317C17.6296 22.5317 17.3296 22.2317 17.3296 21.8817C17.3296 22.2917 17.6596 22.6217 18.0796 22.6217C18.4896 22.6117 18.8196 22.2817 18.8196 21.8617C18.8096 21.3917 18.4296 21.0117 17.9596 21.0117C17.4896 21.0117 17.1096 21.3917 17.1096 21.8517C17.0996 22.3117 17.4796 22.7017 17.9496 22.7017C18.4096 22.7017 18.7996 22.3217 18.7996 21.8517C18.7996 21.4317 18.4596 21.0917 18.0496 21.0917C17.6296 21.0817 17.2896 21.4217 17.2896 21.8317L17.3496 21.8917Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_9279_540905">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoTeam;
