import React from 'react';

const CalendarMobile = ({ width = '25', height = '25', color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 7.33594H1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4169 13.169H18.4053C21.2986 13.1574 23.6553 15.514 23.6553 18.4074C23.6553 21.3007 21.2986 23.6574 18.4053 23.6574C15.5003 23.6574 13.1553 21.3007 13.1436 18.4074C13.1319 15.5024 15.4886 13.1457 18.3819 13.1457C18.3819 13.1445 18.3819 13.1445 18.3819 13.1445"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.115 22.5012H4.98829L4.97663 22.5C3.03996 22.4883 1.47663 20.925 1.47663 19V5.00001C1.46496 3.06335 3.03996 1.48835 4.96496 1.48835H18.965H18.9533C20.8783 1.47668 22.4533 3.05168 22.4533 4.98835V15.1068"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.292 16.6484V18.6901L19.902 19.6736"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.03 11.3593H14.0183C13.9833 11.3593 13.96 11.3826 13.96 11.4059C13.96 11.4293 13.9833 11.4526 14.0066 11.4526C14.03 11.4409 14.0533 11.4176 14.0533 11.3943C14.0533 11.3593 14.0183 11.3359 13.995 11.3359"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86297 11.3592C5.82797 11.3476 5.80464 11.3826 5.80464 11.4059C5.79297 11.4059 5.79297 11.4059 5.80464 11.4059L5.79297 11.3942C5.79297 11.4176 5.8163 11.4409 5.83964 11.4409C5.86297 11.4292 5.8863 11.4059 5.8863 11.3826C5.87464 11.3476 5.8513 11.3242 5.82797 11.3242C5.8163 11.3242 5.8163 11.3242 5.8163 11.3242"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5143 11.3592C10.4793 11.3476 10.456 11.3826 10.456 11.4059C10.4443 11.4059 10.4443 11.4059 10.456 11.4059L10.4443 11.3942C10.4443 11.4176 10.4677 11.4409 10.491 11.4409C10.5143 11.4292 10.5377 11.4059 10.5377 11.3826C10.526 11.3476 10.5027 11.3242 10.4793 11.3242C10.4677 11.3242 10.4677 11.3242 10.4677 11.3242"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5143 15.1483C10.4793 15.1366 10.456 15.1716 10.456 15.1949C10.4443 15.1949 10.4443 15.1949 10.456 15.1949L10.4443 15.1833C10.4443 15.2066 10.4677 15.2299 10.491 15.2299C10.5143 15.2183 10.5377 15.1949 10.5377 15.1716C10.526 15.1366 10.5027 15.1133 10.4793 15.1133C10.4677 15.1133 10.4677 15.1133 10.4677 15.1133"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84734 15.1483C5.81234 15.1366 5.78901 15.1716 5.78901 15.1949C5.77734 15.1949 5.77734 15.1949 5.78901 15.1949L5.77734 15.1833C5.77734 15.2066 5.80068 15.2299 5.82401 15.2299C5.84734 15.2183 5.87068 15.1949 5.87068 15.1716C5.85901 15.1366 5.83568 15.1133 5.81234 15.1133C5.80068 15.1133 5.80068 15.1133 5.80068 15.1133"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86316 18.3592C5.82816 18.3476 5.80483 18.3826 5.80483 18.4059C5.79316 18.4059 5.79316 18.4059 5.80366 18.4059L5.79199 18.3942C5.79199 18.4176 5.81533 18.4409 5.83866 18.4409C5.86199 18.4292 5.88533 18.4059 5.88533 18.3826C5.87366 18.3476 5.85033 18.3242 5.82699 18.3242C5.81533 18.3242 5.81533 18.3242 5.81533 18.3242"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarMobile;
