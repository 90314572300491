import React from 'react';

const Loader = ({ width = '150', height = '150' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 620 620"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M610 413.125V206.875M610 413.125L413.125 610V413.125M610 413.125H413.125M610 206.875H413.125M610 206.875L413.125 413.125M413.125 206.875V10M413.125 206.875L206.875 413.125M413.125 206.875V413.125M413.125 206.875H206.875M413.125 10H206.875M413.125 10L206.875 206.875M206.875 10L10 206.875H206.875M206.875 10V206.875M206.875 206.875V413.125M206.875 413.125H413.125"
        stroke="url(#paint0_linear_158_69)"
        strokeWidth="20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_158_69"
          x1="310"
          y1="10"
          x2="310"
          y2="610"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37E0B0" />
          <stop offset="1" stopColor="#A1FCB9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Loader;
