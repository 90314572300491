import React from 'react';

const BiggerVectorRight = ({ width = 9, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15L8 8L1 1"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BiggerVectorRight;
