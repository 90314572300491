import React from 'react';

const OpenCalendar = () => {
  return (
     
     <svg 
     width="38" 
     height="30" 
     viewBox="0 0 38 30" 
     fill="none" 
     xmlns="http://www.w3.org/2000/svg"
     >
        
     <path 
     d="M0 0H38V24C38 27.3137 35.3137 30 32 30H6C2.68629 30 0 27.3137 0 24V0Z" 
     fill="#18D399"
     />

     <path 
     d="M23.6667 8H14.3333C13.0447 8 12 9.04467 12 10.3333V19.6667C12 20.9553 13.0447 22 14.3333 22H23.6667C24.9553 22 26 20.9553 26 19.6667V10.3333C26 9.04467 24.9553 8 23.6667 8Z" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M26 11.8906H12" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M23.1222 14.6092C23.1222 14.6248 23.0989 14.6481 23.0833 14.6481C23.06 14.6481 23.0444 14.6248 23.0444 14.6092C23.0444 14.5859 23.06 14.5703 23.0833 14.5703" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M23.0832 14.5718H23.0754C23.091 14.5641 23.1143 14.5874 23.1143 14.6107" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M20.3993 14.6092C20.3993 14.6248 20.376 14.6481 20.3604 14.6481C20.3371 14.6481 20.3215 14.6248 20.3215 14.6092C20.3215 14.5859 20.3371 14.5703 20.3604 14.5703" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M20.3532 14.5718H20.3455C20.361 14.5641 20.3843 14.5874 20.3843 14.6107" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M17.6769 14.6092C17.6769 14.6248 17.6536 14.6481 17.638 14.6481C17.6147 14.6481 17.5991 14.6248 17.5991 14.6092C17.5991 14.5859 17.6147 14.5703 17.638 14.5703" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />

     <path 
     d="M17.6311 14.5718H17.6233C17.6388 14.5641 17.6622 14.5874 17.6622 14.6107" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M14.9547 16.9451C14.9547 16.9607 14.9314 16.984 14.9158 16.984C14.8925 16.984 14.877 16.9607 14.877 16.9451C14.877 16.9218 14.8925 16.9062 14.9158 16.9062" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M14.9089 16.9039H14.9011C14.9167 16.8961 14.94 16.9194 14.94 16.9428"
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M17.6769 16.9451C17.6769 16.9607 17.6536 16.984 17.638 16.984C17.6147 16.984 17.5991 16.9607 17.5991 16.9451C17.5991 16.9218 17.6147 16.9062 17.638 16.9062" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M17.6311 16.9039H17.6233C17.6388 16.8961 17.6622 16.9194 17.6622 16.9428" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M20.3993 16.9451C20.3993 16.9607 20.376 16.984 20.3604 16.984C20.3371 16.984 20.3215 16.9607 20.3215 16.9451C20.3215 16.9218 20.3371 16.9062 20.3604 16.9062" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M20.3532 16.9039H20.3455C20.361 16.8961 20.3843 16.9194 20.3843 16.9428"
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M23.1222 16.9451C23.1222 16.9607 23.0989 16.984 23.0833 16.984C23.06 16.984 23.0444 16.9607 23.0444 16.9451C23.0444 16.9218 23.06 16.9062 23.0833 16.9062" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M23.0832 16.9039H23.0754C23.091 16.8961 23.1143 16.9194 23.1143 16.9428" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M23.1222 19.2772C23.1222 19.2927 23.0989 19.3161 23.0833 19.3161C23.06 19.3161 23.0444 19.2927 23.0444 19.2772C23.0444 19.2538 23.06 19.2383 23.0833 19.2383" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M23.0832 19.2398H23.0754C23.091 19.232 23.1143 19.2554 23.1143 19.2787" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M20.3993 19.2772C20.3993 19.2927 20.376 19.3161 20.3604 19.3161C20.3371 19.3161 20.3215 19.2927 20.3215 19.2772C20.3215 19.2538 20.3371 19.2383 20.3604 19.2383" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M20.3532 19.2398H20.3455C20.361 19.232 20.3843 19.2554 20.3843 19.2787" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M17.6769 19.2772C17.6769 19.2927 17.6536 19.3161 17.638 19.3161C17.6147 19.3161 17.5991 19.2927 17.5991 19.2772C17.5991 19.2538 17.6147 19.2383 17.638 19.2383" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M17.6311 19.2398H17.6233C17.6388 19.232 17.6622 19.2554 17.6622 19.2787" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
     
     <path 
     d="M14.9547 19.2772C14.9547 19.2927 14.9314 19.3161 14.9158 19.3161C14.8925 19.3161 14.877 19.2927 14.877 19.2772C14.877 19.2538 14.8925 19.2383 14.9158 19.2383" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" s
     strokeLinejoin="round"
     />
     
     <path 
     d="M14.9089 19.2398H14.9011C14.9167 19.232 14.94 19.2554 14.94 19.2787" 
     stroke="#24292E" 
     strokeWidth="1.125" 
     strokeLinecap="round" 
     strokeLinejoin="round"
     />
   </svg>
  );
};

export default OpenCalendar;