import React from 'react';

const Doc = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="5"
        fill="url(#paint0_linear_1314_1820)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 6.59998C6.22386 6.59998 6 6.82383 6 7.09998C6 7.37612 6.22386 7.59998 6.5 7.59998H13.5C13.7761 7.59998 14 7.37612 14 7.09998C14 6.82383 13.7761 6.59998 13.5 6.59998H6.5ZM6.5 8.59998C6.22386 8.59998 6 8.82383 6 9.09998C6 9.37612 6.22386 9.59997 6.5 9.59997H13.5C13.7761 9.59997 14 9.37612 14 9.09998C14 8.82383 13.7761 8.59998 13.5 8.59998H6.5ZM6 11.1C6 10.8238 6.22386 10.6 6.5 10.6H13.5C13.7761 10.6 14 10.8238 14 11.1C14 11.3761 13.7761 11.6 13.5 11.6H6.5C6.22386 11.6 6 11.3761 6 11.1ZM6.5 12.6C6.22386 12.6 6 12.8238 6 13.1C6 13.3761 6.22386 13.6 6.5 13.6H9.5C9.77614 13.6 10 13.3761 10 13.1C10 12.8238 9.77614 12.6 9.5 12.6H6.5Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1314_1820"
          x1="0"
          y1="0"
          x2="0"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5895FF" />
          <stop offset="1" stopColor="#387CF4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Doc;
