import React from 'react';

const Excel = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="5"
        fill="url(#paint0_linear_1314_2047)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9535 6.44359C13.8611 6.1868 13.6154 6.00317 13.3268 6.00317H6.66851C6.30079 6.00317 6.00269 6.30128 6.00269 6.669H6.00506C6.00504 6.67114 6.00503 6.67328 6.00503 6.67543V7.9777H6.00269V8.64353H6.00503V10.6553H6.00319V11.3211H6.00503V13.3329H6.00365C6.00365 13.5762 6.13416 13.789 6.32899 13.9052C6.42892 13.9651 6.54586 13.9996 6.67086 13.9996V13.9987H8.67753V13.9996H9.34336V13.9987H10.6612V13.9996H11.3271V13.9987H13.3278C13.3282 13.9987 13.3287 13.9987 13.3292 13.9987V13.9996C13.6969 13.9996 13.995 13.7015 13.995 13.3337V6.67543C13.995 6.5939 13.9803 6.51579 13.9535 6.44359ZM13.3292 13.3329V11.3211H11.3271V13.3329H13.3292ZM10.6612 13.3329V11.3211H9.34336V13.3329H10.6612ZM8.67753 13.3329V11.3211H6.67086V13.3329H8.67753ZM6.67086 10.6553H8.67753V8.64353H6.67086V10.6553ZM6.67086 7.9777H13.3292V6.669H6.67086V7.9777ZM13.3292 8.64353H11.3271V10.6553H13.3292V8.64353ZM10.6612 10.6553V8.64353H9.34336V10.6553H10.6612Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1314_2047"
          x1="0"
          y1="0"
          x2="0"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1ABF74" />
          <stop offset="1" stopColor="#009F57" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Excel;
