import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Table from '../../../components/GenericTable';
import { routesOwner } from '../../../services/Constants/paths';

const TableEmployees = ({
  loading = false,
  columns = [],
  currentEmployees,
  sortState,
  setSortState,
  setSortAscDesc,
  sortAscDesc,
  teams,
  countries
}) => {
  const navigate = useNavigate();
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  const handleNavigate = el => {
    navigate(routesOwner.editEmployee(el.id), {
      state: { site: 'profile', user: el },
      replace: true
    });
  };

  return (
    <Table
      data={data}
      columns={columnsActives}
      loading={loading}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      attributesSelected={attributes}
      currentEmployees={currentEmployees}
      sort
      sortState={sortState}
      setSortState={setSortState}
      setSortAscDesc={setSortAscDesc}
      sortAscDesc={sortAscDesc}
      redirectEdit
      onClick={handleNavigate}
      isTableEmployee={true}
      teams={teams}
      countries={countries}
    />
  );
};

export default TableEmployees;
