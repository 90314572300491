import gql from 'graphql-tag';

export const COMPANY_QUERY = gql`
  query COMPANY_QUERY($id: String!) {
    company(where: { id: $id }) {
      name
      logo
      industry
      website
      linkedIn
      twitter
      country
      city
      email
      userNameSchema
      slogan
      phone
      addressOne
      addressTwo
      allCountries
      countries {
        id
        label
        country
      }
      employeeRoles {
        id
        name
        salaryLow
        salaryHigh
        salaryMid
      }
      systems {
        id
        name
        type
        logo
        active
      }
      employees {
        firstName
        lastName
        employeeRole {
          name
        }
        systemsEnabled {
          name
          logo
        }
      }
    }
  }
`;

export const SYSTEM_QUERY = gql`
  query SYSTEM_QUERY {
    systems {
      id
      name
      type
    }
  }
`;

export const All_COMPANY_ROLES = gql`
  query All_COMPANY_ROLES($id: String) {
    allcompanyRole(where: { to: { id: { equals: $id } } }) {
      count
    }
  }
`;

export const GET_COUNTRIES = gql`
  query Query {
    getCountries {
      company
      label
      country
    }
  }
`;

export const GET_COMPANY_DETAIL_BY_ID = gql`
  query GET_COMPANY_DETAIL_BY_ID($companyId: String!) {
    getCompanyDetailById(companyId: $companyId) {
      id
      name
      logo
      slogan
      website
      linkedIn
      twitter
      allCountries
      addressOne
      addressTwo
      allCountries
      city
      country
      email
      employeeNameSchema
      industry
      nameLogo
      phone
      userNameSchema
      postalCode
      state
    }
  }
`;

export const GET_PTO_PENDING_BY_COMPANY = gql`
  query GET_PTO_PENDING_BY_COMPANY($companyId: String!) {
    getPTOPendingByCompany(companyId: $companyId) {
      createdAt
      startDate
      endDate
      employee
      status
      message
      type
      id
      employeeInfo {
        id
        fullName
        user {
          id
          email
          photo
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_WITH_TEAM_BY_COMPANY_BY_TEAMS = gql`
  query GET_EMPLOYEE_WITH_TEAM_BY_COMPANY_BY_TEAMS(
    $companyId: String!
    $idTeam: String!
  ) {
    getEmployeesForTeamByCompanyByTeams(
      companyId: $companyId
      idTeam: $idTeam
    ) {
      team
      companyId
      teamId
      employeeId
      fullName
      isManager
      teams
      teamsId
      employeeIdManager
      fullNameManager
      isManagerTeam
      emailManager
    }
  }
`;

export const GET_PTO_REQUEST_BY_COMPANY = gql`
  query GET_PTO_REQUEST_BY_COMPANY($companyId: String!) {
    getPTORequestByCompany(companyId: $companyId) {
      createdAt
      startDate
      endDate
      employee
      status
      message
      type
      id
      daysOff
      employeeInfo {
        id
        fullName
        user {
          id
          email
          photo
        }
      }
    }
  }
`;

export const GET_PTO_PENDING_BY_TEAM = gql`
  query GET_PTO_PENDING_BY_TEAM($companyId: String!, $teamId: String!) {
    getPTOPendingByTeam(companyId: $companyId, teamId: $teamId) {
      createdAt
      startDate
      endDate
      employee
      status
      message
      type
      daysOff
      id
      employeeInfo {
        id
        fullName
        user {
          id
          email
          photo
        }
      }
    }
  }
`;
