import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import Table from '../../../components/GenericTable';
import Edit from '../../../components/Icons/Edit';
import Groups from '../../../components/Icons/Groups';
import { routesOwner } from '../../../services/Constants/paths';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import IconProfileTeams from '../../../components/Icons/IconProfileTeams';

const NoData = () => {
  return (
    <div className="noData">
      <Groups color="black" size="48" />
      <div>
        <span className="text-normal">You have no teams</span>
      </div>
    </div>
  );
};

const TableTeams = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected,
  setSortChoiceTeam,
  setItemTeam,
  sortChoiceTeam,
  tabActive
}) => {
  const navigate = useNavigate();
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];
  const isMounted = useRef(false);

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'teamName':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value:               
              <div className='title-teams'>
              <IconProfileTeams
              width='36' 
              height='36'
              />
              <span> {item.name} </span>
              </div>
            });
          break;
        case 'employees':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: item.employees > 0 ? item.employees - 1 : 0
            });
          break;
        case 'managerCompany':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: item.manager
            });
          break;
        case 'edit':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div
                  style={{ cursor: 'pointer' , display: 'flex' }}
                  onClick={() =>
                    navigate(routesOwner.createEditTeams(), {
                      state: { teamId: item.id,  tab: tabActive },
                      replace: true
                    })
                  }
                >
                  <Edit />
                </div>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  const handleNavigate = el => {
    navigate(routesOwner.createEditTeams(), {
      state: { teamId: el.value },
      replace: true
    });
  };

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;
  }, [isMounted]);

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
      onClick={handleNavigate}
      sort
      setItemTeam={setItemTeam}
      setSortChoiceTeam={setSortChoiceTeam}
      sortChoiceTeam={sortChoiceTeam}
    />
  );
};

export default TableTeams;
