import Swal from 'sweetalert2';
import {
  REVERT_THIS,
  ARE_YOU,
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_BUTTON_TEXT,
  ALERT_DELETE_TITLE,
  INFO,
  ERROR,
  SEPARATION_CONFIRMATION_BUTTON,
  SEPARATION_CANCEL_BUTTON,
  EVERYTHING_LOOK,
  NOTES,
  SEPARATION_DATE,
  EMPLOYEE_SEPARATE_ELEGIBLE,
  SEPARATION_EMPLOYEE
} from '../services/Constants/getLabels';

let customClass = {
  container: 'container-class',
  popup: 'popup-class',
  title: 'title-class',
  confirmButton: 'btn btn-success',
  cancelButton: 'btn btn-danger'
};

const getCustomStyle = () => {
  return Swal.mixin({
    customClass: customClass,
    buttonsStyling: true
  });
};

export const alertAreYouSure = () => {
  customClass = {
    ...customClass,
    icon: 'icon-class',
    closeButton: 'close-button-class'
  };

  const swalWithStyles = getCustomStyle();

  return swalWithStyles.fire({
    title: ARE_YOU,
    text: REVERT_THIS,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#18D399',
    cancelButtonColor: '#FF5656',
    confirmButtonText: CONFIRMATION_BUTTON_TEXT,
    cancelButtonText: CANCEL_BUTTON_TEXT,
    showCloseButton: true,
    reverseButtons: true
  });
};

export const alertLookRight = (date, rehire, note, employeeName) => {
  customClass = {
    ...customClass,
    icon: 'icon-class',
    closeButton: 'close-button-class'
  };

  const swalWithStyles = getCustomStyle();

  return swalWithStyles.fire({
    title: ARE_YOU,
    html: ` <div class="swalAlertLook">
    <p><span>${SEPARATION_EMPLOYEE}</span>: ${employeeName}</p>
      <p><span>${SEPARATION_DATE}</span>: ${date}</p>
      <p><span>${EMPLOYEE_SEPARATE_ELEGIBLE}</span>: ${rehire}</p>
      ${note === '' ? '' : `<p><span>${NOTES}</span>: ${note}</p>`}
    </div>
    <span class="swalAlertSpan">${EVERYTHING_LOOK}</span>`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#18D399',
    cancelButtonColor: '#FF5656',
    confirmButtonText: SEPARATION_CONFIRMATION_BUTTON,
    cancelButtonText: SEPARATION_CANCEL_BUTTON,
    showCloseButton: true,
    reverseButtons: true
  });
};

export const alertAreYouSureCustom = (
  html,
  confirmButtonText,
  cancelButtonText
) => {
  customClass = {
    ...customClass,
    icon: 'icon-class',
    closeButton: 'close-button-class'
  };

  const swalWithStyles = getCustomStyle();

  return swalWithStyles.fire({
    title: ARE_YOU,
    html,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#18D399',
    cancelButtonColor: '#FF5656',
    confirmButtonText,
    cancelButtonText,
    showCloseButton: true,
    reverseButtons: true
  });
};

export const alertDeleted = text => {
  customClass = {
    ...customClass,
    icon: 'success-icon-class'
  };

  const swalWithStyles = getCustomStyle();

  return swalWithStyles.fire(ALERT_DELETE_TITLE, text, 'success');
};

export const alertInfo = text => {
  customClass = {
    ...customClass,
    icon: 'success-icon-class'
  };
  const swalWithStyles = getCustomStyle();

  swalWithStyles.fire(`${INFO}!`, text, 'info');
};

/**
 * Doc : https://sweetalert2.github.io/
 * It takes a string as an argument, and displays it in a popup window
 */
export const alertError = text => {
  customClass = {
    ...customClass,
    icon: 'error-icon-class'
  };
  const swalWithStyles = getCustomStyle();

  swalWithStyles.fire(`${ERROR}!`, text.replace('GraphQL error:', ''), 'error');
};

export const alertImg = text => {
  customClass = {
    ...customClass,
    icon: 'error-icon-class'
  };
  const swalWithStyles = getCustomStyle();

  swalWithStyles.fire(`${ERROR}!`, text, 'error');
};

export const customAlertImageIcon = (
  imageUrl,
  imageAlt,
  imageWidth,
  imageHeight,
  html
) => {
  customClass = {
    ...customClass,
    icon: 'success-icon-class'
  };
  const swalWithStyles = getCustomStyle();

  swalWithStyles.fire({
    imageUrl: imageUrl,
    imageHeight: imageHeight,
    imageWidth: imageWidth,
    imageAlt: imageAlt,
    html: html,
    showConfirmButton: false,
    showCloseButton: true
  });
};
