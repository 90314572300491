import styled from 'styled-components';

export const DropdownState = styled.div`

  .pto-item__input {
    display: none;
  }

  .pto-item-label {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    border-radius: 10px;
    height: 55px;
    @media screen and (max-width: 600px) {
      padding: 5px 20px 5px 0px;
    }
  }

  .pto-item-label-movile {
    display: none;
  }

  .pto-item-label:hover {
    background: #d1dff0;
    cursor: pointer;
  }

  .pto-item__separate{
    width: 2px; 
    height: 26px; 
    display: inline-block;
    color: #959DA5;
    margin: 0px 15px 0px 10px;
  }

  .pto-item__icon {
    display: inline-block;
    transition: transform 0.3s ease;
    transform: rotate(270deg);
  }
  
  .pto-item__container {
    max-height: 0;
    width: 100%
    overflow: hidden;
    overflow-x: auto;
    transition: max-height 0.3s;
  }

  .pto-item__content {
    background : #F0F5FA;
    border-radius: 0px 0px 10px 10px;
    padding: 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    margin-bottom: 8px;
  }

  .pto-item__delimiter {
    max-width: 20vw;
    @media screen and (max-width: 600px) {
      max-width: 100vw;
    }
  }

  .pto-item__delimiter > div{
    display: flex;
    @media screen and (max-width: 600px) {
      flex-wrap: wrap
    }
  }

  .pto-item__status {
    border-radius: 12px;
    color: #24292E;
    padding: 2px 6px;
  }

  .approved {
    background: rgba(24, 211, 153, 0.3);
  }

  .pending {
    background : #FFD1AC;
  }

  .declined {
    background: #FDAEB7;
  }

  .pto-item__small {
    width: 25%;
    min-width: 235px;
    @media screen and (max-width: 600px) {
      width: 50%;
      min-width: 150px;
    }
  }

  .teams-container {
    @media screen and (max-width: 600px) {
      .pto-item__small {
        width: 50%;
        min-width: 250px;
      }
    }
  }

  .pto-item__medium {
    width: 50%;
    min-width: 475px;
    @media screen and (max-width: 600px) {
      width: 100%;
      min-width: 300px
    }
  }
  
  .pto-item__large {
    width: 75%;
    min-width: 750px;
    @media screen and (max-width: 600px) {
      width: 100%;
      min-width: 250px
    }
  }

  .pto-item__input:checked ~ .pto-item-label {
    background: #D1DFF0;
    border-radius: 10px 10px 0px 0px;
  }

  .pto-item__input:checked ~ .pto-item-label-movile {
    @media screen and (max-width: 600px) {
      display: flex;
      position: fixed;
      bottom: 408px;
      left: 0;
      width: 100%;
      padding: 10px;
      background: #fff;
      border-top: 1px solid #E1E4E8;
      z-index: 1;

      .ptobtns-edit{
        display: none !important;
      }
    }
  }

  .card-buttons-movil,
  .ptobtns-container,
  .pto-buttons,
  .btn-actions{
    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }

  .ptobtns-edit{
    @media screen and (max-width: 600px) {
      width: 38px !important;
      span{
        display: none;
      }
    }
  }
  .pto-item__input:checked ~ .pto-item__container{
    max-height: 235px;

    @media screen and (max-width: 600px) {
      .pto-item__content{
        position: fixed;
        bottom: 100px;
        left: 0;
        height: 310px;
        width: 100%;
        margin-bottom: 0px;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;
        border-top: 1px solid #E1E4E8;
        z-index: 1;
      }
    }
  }

  .pto-item__input:checked ~ .pto-item-label .pto-item__icon {
    transform: rotate(90deg);
  }

  .pto-item__input:checked ~ .pto-item-label .card-buttons,
  .pto-item__input:checked ~ .pto-item-label .card-buttons-edit,
  .pto-item__input:checked ~ .pto-item-label .pto-buttons,
  .pto-item__input:checked ~ .pto-item-label .ptobtns-container
  {
    @media screen and (max-width: 600px) {
      background: #fff;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      position: fixed;
      bottom: 0;
      left: 0;
      border-top: 1px solid #E1E4E8;
      z-index: 1;

      .btn-danger-declined,
      .ptobtns-,
      .btn-bell,
      .btn-success-accept{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
      
      .btn-danger-declined,
      .ptobtns-,
      .btn-bell,
      .btn-danger,
      .btn-success-accept,
      .btn-success {
        width: 165px;
        height: 44px;
      }
    }
  }
`;
