import styled from 'styled-components';

export const ContentSideDetailEmployee = styled.div`

  .name-employee {
    border-bottom: 1px solid #e1e4e8;
    .sub-heading{
      padding: 10px 20px;
      margin-bottom: 0;
    }
  }

  .side-link {
    cursor: pointer;
    margin-left: 20px;
    padding-left: 5px;
    margin-top: 20px;
    span {
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  a{
    text-decoration: none;
  }
`;
