import React from 'react';

const ClosedCalendar = () => {
  return (
     
      <svg 
      width="38" 
      height="30" 
      viewBox="0 0 38 30" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      >

      <path d="M0 0H38V24C38 27.3137 35.3137 30 32 30H6C2.68629 30 0 27.3137 0 24V0Z" 
      fill="#18D399"
      />

      <path 
      d="M26 18L19 11L12 18" 
      stroke="#24292E" 
      strokeWidth="1.125" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      />
   </svg>
  );
};

export default ClosedCalendar;