import React from 'react';

const Compensation = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3465_68852)">
        <path
          d="M17.7679 42.6666H7.4044C6.24144 42.6666 5.33301 41.723 5.33301 40.582V32.2877C5.33301 31.1247 6.2546 30.2031 7.4044 30.2031H17.7657"
          stroke="#24292E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.2011 7.43561V42.6668H40.5581C41.6991 42.6668 42.6207 41.7233 42.6207 40.5823V7.40489C42.6207 6.24192 41.6771 5.3335 40.5361 5.3335H32.2418C31.0788 5.3335 30.1572 6.25509 30.1572 7.40489L30.2011 7.43561Z"
          stroke="#24292E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.2006 42.6667H17.7656V19.8682C17.7656 18.7053 18.6872 17.7837 19.8282 17.7837H30.1852"
          stroke="#24292E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3465_68852">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Compensation;
