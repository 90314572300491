import { useQuery } from '@apollo/react-hooks';
import {
  EMPLOYEE_SALARY_HISTORY,
  EMPLOYEE_ME_SALARY_HISTORY
} from '../../../queries/employees';
import { SALARIES_MEDIA_GROUP_ROLE } from '../../../queries/roles';
export const useSalaryHistoryQuery = (employeeId, isAdmin) =>
  useQuery(isAdmin ? EMPLOYEE_SALARY_HISTORY : EMPLOYEE_ME_SALARY_HISTORY, {
    variables: { employee: employeeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

export const useSalariesMediaGroupRoleAndBonus = (companyId, employeeId) =>
  useQuery(SALARIES_MEDIA_GROUP_ROLE, {
    variables: { id: companyId, employeeId: employeeId }
  });

export const readCacheSalariesMediaGroupRoleAndBonus = (
  companyId,
  employeeId
) => {
  return {
    query: SALARIES_MEDIA_GROUP_ROLE,
    variables: {
      id: companyId,
      employeeId: employeeId
    }
  };
};
