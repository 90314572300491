import styled from 'styled-components';

export const ContentPTO = styled.div`
  margin: 15px;
  height: -webkit-fill-available;

  .CircularProgressbar {
    width: 100%;

    & .CircularProgressbar-path {
      stroke: #25446a;
    }

    & .CircularProgressbar-text {
      fill: #25446a;
    }
  }

  .inner-Calendar-pto{
    border-left: none;
    border-top-left-radius: 0;
  }

  .your-progress {
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;

    & .progress-bar-circle {
      text-align: center;
      padding: 18px;
    }

    & .progress-list {
      display: flex;
      flex-direction: column;
      padding: 2px 10px;
      flex-flow: column wrap;
    }
  }

  .list-check {
    display: inline-block;
    padding: 0% !important;
    margin: 10px;
    & span {
      font-size: 18px;
    }
  }

  .circle-list {
    display: inline-block !important;
    background: #d1dff0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 7px;
    text-align: center;
    font-size: 22px;
    margin-right: 10px;
  }

  .card-main-pto {
    margin-top: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    height: 100%;

    & .card-pto-title {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;

      .little-subtitle {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 10px;
        
        p {
          color: #6A737D
          line-height: 20px;
          font-size: 14px;
          padding-left: 10px;
          margin-bottom: 0px;
        }
        .exclamation-space{
          width: 18px;
        }
      }
    }

    & .card-pto-avaliable {
      padding: 15px;
      display: flex;
      white-space: nowrap;

      & > div {
        display: inline-block;
        width: 15%;
        margin: 0 2% 0 0;
      }

      & span {
        font-weight: 600;
        font-size: 32px;
      }

      & .avaliable {
        width: 15%;
      }

      .used {
        border-left: 1px solid #e1e4e8;
        padding-left: 10px;
        margin-right: 30px;
      }

      .sick{
        border-left: 1px solid #e1e4e8;
        padding-left: 10px;
      }

    }
  }

  & .card-pto-filter {
    border-bottom: 1px solid #e1e4e8;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;

    .pto-check{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 27px;

      span{
        font-weight: 400;
        size: 14px;
        color: #24292E;
      }

    }

    .pto-dates{
      display: flex;
    }
    .pto-fields{
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-right: 10px;

      span {
        margin-bottom: 8px;
        font-weight: 600;
        size: 14px;
        color: #24292E;
      }

      .pto-calendar-fields {
        display: flex;

        span {
          margin-bottom: 0px;
        }

        & .input-group-text {
          border: 1px solid #efefef;
          border-radius: 0.25rem 0.25rem 0rem 0.25rem;
          background-color: white !important;
          border-right: none !important;
          border-top-right-radius: 0;
          padding-right: 5px;
        }
      }
    }

    & .note-pto {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      margin: 0;
      margin-right: 10px;

      span{
        margin-bottom: 8px;
        font-weight: 600;
        size: 14px;
        color: #24292E;
      }

      & .input-group {
        height: 44px;
        width: 347px;

        span{
          margin-bottom: 0px;
        }

        & .input-group-text {
          background-color: white !important;
          border-right: none !important;
          padding-right: 5px;
        }

        & .form-control {
          border-left: none !important;
          padding-left: 0px;
        }
      }
    }

    & .btn-pto{
      height: 44px;
      width: 120px;
      padding: 0;
      margin: 0;
      margin-top: 29px;

      & button {
        height: 100%
        color: black;
      }
    }
  }

  & .btn-success {
    background-color: #18D399 !important;
    width: 100%;
    border-radius: 10px !important;
    border: none;
  }

  & .card-pto-data {
    height: 72%;
    overflow-y: auto;
    overflow-x: hidden;

    .loading {
      text-align: center;
      padding: 50px;
    }

    & .pto-title {
      margin-bottom: 5px;
      margin-top: 5px;
      border-bottom: 1px solid #e1e4e8;
    }

    & .pto-dates{
      font-weight: 600;
      font-size: 14px;
      color: #24292e;
    }

    & .pto-status {
      color: #24292e;
      font-size: 14px;
    }

    & .icon-pending {
      margin: auto;
      text-align: center;
      padding: 0 15px;
      display: inline-block;    

      & span {
        margin-left: 5px;
        font-weight: 600;
      }
    }

    & .btn-bell {
      padding: 0;
      display: inline-block;

      & span {
        margin-left: 5px;
        color: black;
      }
      .btn {
        padding: 8px 10px 8px 10px;
        height: 36px;
        width: 114px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .icon-status {
      text-align: right;

      & span {
        font-weight: 600;
        margin-left: 5px;
        color: black;
      }
    }
  }

  .rw-btn {
    border-right: none !important;
    padding: 0 5px !important;
  }

  .rw-widget-input  {
    box-shadow: none !important;
    text-align: left !important;
    padding: 0 5px !important;
  }

  .card-pto-no-data {
    text-align: center;
    padding: 100px 0;
    & svg {
      margin-bottom: 20px;
    }
  }

  .content-tabs {
    height: 408px;
    padding: 20px;
    padding-left: 10px;

    & .card-pto-data {
      height: 72%;
      overflow-y: auto;
      overflow-x: hidden;

      .loading {
        text-align: center;
        padding: 50px;
      }
    }

    & .pto-item {
      display: flex;
      justify-content: space-between;
      width: 100%

      & .pto-icon {
        padding-top: 4px;
        vertical-align: text-top;
      }

      & .pto-date-status {
        min-width: 160px;
        padding: 0 10px;

        & .pto-dates{
          font-weight: 400;
          font-size: 14px;
          color: #24292E;
        }

        & .pto-status {
          color: #24292e;
          font-size: 14px;
        }

        & .pto-name-employee {
          color: #24292E;
          font-weight: 600;
        } 
      }

      .ptobtns- {
        background: #F6F8FA;
        border-radius: 6px;
        height: 32px;
        width: 115px;
        display: flex;
        align-items: center;
        padding: 8px 10px;

        & .icon-status-approved {
          text-align: right;

          & span {
            font-weight: 600;
            margin-left: 5px;
            color: #24292E;
          }

        }

        & .icon-status-declined {
          text-align: right;

          & span {
            font-weight: 600;
            margin-left: 12px;
            color: #24292E;
          }

        }
      }

    }
  }
  .salary-container{
    margin: -15px -15px 25px -15px;
  }
 @media screen and (max-width: 1024px){
    margin: 0px;

    .card-pto-filter{
      flex-direction: column;
      position: relative;
      
      .pto-check{
        flex-direction: row;
        margin-bottom: 11px;
        .pto-mobile{
          margin-right: 30px;
        }
      }
      .pto-dates{
        margin-bottom: 20px;
      }
      .btn-pto{
        margin-top: 20px
        width: 335px;
        margin-right: auto;
        margin-left: auto;
      }
      .note-pto{
        margin-right: 0px;
      }
      .mobile-right{
        margin-right: 0px;
      }
    }
    .card-main-pto{
      margin-top: 0;
      border-radius: 0;

      .mobile-margin-top{
        margin-top: -15px;
      }

      .card-pto-avaliable{
        white-space: nowrap;
        
        .avaliable{
          width: 20%;
        }
        .used{
          width: 30%;
        }
      }
    }
    .input-group{
      width: 100% !important;
    }
    .inner-Calendar-pto{
      width: 100%;
    }
    .title-style-bread{
      display: none;
    }
    .content-tabs{
      height: auto !important;
    }
    .card-pto-data{
      height: auto;
    }
    .salary-container{
      margin: 0;
    }
  }
`;
