import React from 'react';


const IconProfileTeams = ({width='48', height='48' }) => {
  return (
            <svg 
            width={width}
            height={height}
            viewBox="0 0 36 36" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            >

            <rect 
            width="36" 
            height="36" 
            rx="18" 
            fill="#FAFBFC"
            />

            <path 
            d="M18.0725 25.1922C16.9307 25.1922 15.7889 25.1922 14.6471 25.1922C14.1726 25.1922 13.8927 24.9229 13.891 24.4444C13.8861 23.0087 13.8738 21.573 13.8935 20.1373C13.9198 18.2608 15.385 16.839 17.2648 16.8349C17.8189 16.8341 18.3721 16.8333 18.9262 16.8349C20.6796 16.8415 22.0751 18.1204 22.2442 19.8648C22.2836 20.2678 22.2655 20.6708 22.2672 21.0739C22.2721 22.1476 22.2688 23.2205 22.268 24.2942C22.268 24.3894 22.268 24.4862 22.2532 24.5806C22.1925 24.9796 21.9437 25.1922 21.5374 25.193C20.3816 25.1938 19.2267 25.193 18.0709 25.193L18.0725 25.1922Z" 
            fill="black" 
            fillOpacity="0.1"
            />

            <path 
            d="M11.1356 25.1915C10.547 25.1915 9.95927 25.1923 9.37071 25.1915C8.94714 25.1907 8.64588 24.9412 8.64424 24.5233C8.64014 23.2592 8.57775 21.9918 8.67215 20.7301C8.7608 19.5497 9.82957 18.6016 11.0157 18.5975C11.6519 18.595 12.2881 18.5967 12.9234 18.5975C13.0055 18.5975 13.0876 18.6041 13.1689 18.6139C13.2862 18.6279 13.3371 18.682 13.2904 18.8076C13.0901 19.3494 13.0695 19.9142 13.0728 20.4847C13.0802 21.7636 13.0745 23.0425 13.0753 24.3214C13.0753 24.5603 13.0638 24.8008 13.1566 25.0298C13.223 25.194 13.1122 25.1923 13.0014 25.1923C12.3792 25.1923 11.757 25.1923 11.1339 25.1923L11.1356 25.1915Z" 
            fill="black" 
            fillOpacity="0.1"
            />

            <path 
            d="M24.9979 25.1924C24.3822 25.1924 23.7666 25.19 23.1518 25.1941C23.0048 25.1949 22.9465 25.1579 23.0073 25.0053C23.0771 24.8312 23.0828 24.6457 23.0828 24.4602C23.0828 23.0171 23.0803 21.574 23.0828 20.1318C23.0836 19.7041 23.0229 19.2879 22.8924 18.8808C22.8177 18.6476 22.8398 18.6074 23.0951 18.6025C23.5466 18.5926 23.9981 18.5926 24.4487 18.5984C24.8444 18.6033 25.2417 18.5623 25.6341 18.6526C26.7299 18.9054 27.5048 19.8724 27.5188 21.0405C27.5319 22.1618 27.5188 23.2839 27.5229 24.4052C27.5245 24.883 27.2339 25.2212 26.7201 25.1982C26.1471 25.1727 25.5717 25.1924 24.9971 25.1924H24.9979Z" 
            fill="black" 
            fillOpacity="0.1"
            />

            <path 
            d="M18.0953 10.8003C19.6631 10.8028 20.9543 12.0973 20.9543 13.6659C20.9543 15.2469 19.6475 16.5373 18.0518 16.5324C16.4929 16.5275 15.1902 15.2108 15.2033 13.6536C15.2165 12.0677 16.5036 10.7978 18.0953 10.8003Z" 
            fill="black" 
            fillOpacity="0.1"
            />

            <path 
            d="M11.9569 13.3433C13.3014 13.3449 14.3915 14.4334 14.3883 15.7706C14.385 17.0963 13.2752 18.2028 11.9478 18.2028C10.6065 18.2028 9.52708 17.1045 9.52955 15.741C9.53201 14.4112 10.6139 13.3416 11.9569 13.3433Z" 
            fill="black" 
            fillOpacity="0.1"
            />

            <path 
            d="M24.21 13.3433C25.5628 13.3433 26.6315 14.4129 26.6315 15.7665C26.6315 17.1102 25.5373 18.2094 24.2083 18.2028C22.8785 18.1954 21.7777 17.0938 21.772 15.7648C21.7671 14.4342 22.8646 13.3433 24.21 13.3433Z" 
            fill="black" 
            fillOpacity="0.1"
            />
</svg>
  );
};

export default IconProfileTeams;