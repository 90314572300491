import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

export const ThemePopover = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation8: {
        boxShadow: 'none'
      },
      rounded: {
        borderRadius: '0px'
      }
    }
  }
});

export const NavBarContainer1 = styled.div`
  background: #172c45;
  height: 60px;
  display: flex;
  justify-content: center;
  .flexContainer {
    justify-content: space-between;
    display: flex;
    max-width: 1024px;
    width: 100%;
    > div {
      display: flex;
      align-items: center;
      span {
        color: white;
        font-size: 16px;
        margin-left: 5px;
      }
    }
    .logo {
      padding-left: 10px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      text-decoration: none;
      img {
        width: 32px;
        margin-right: 5px;
      }
      @media screen and (max-width: 992px) {
        margin-left: 15px;
      }
    }
  }
  a {
    display: block;
    color: #f2f2f2;
    text-align: center;
    font-size: 17px;
  }
  .notificationDot {
    position: absolute;
    font-size: 11px;
    top: 13px;
    margin-left: 19px;
    background-color: red;
    padding: 1px 5px;
    border-radius: 50%;
    border: 2px solid #172c45;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .icon {
    color: #fff;
    margin: 0.5rem;
    @media screen and (max-width: 992px) {
      margin: 0 0 0 -8px;
    }
    img {
      display: inline-block;
      user-select: none;
      border-radius: 50%;
      width: 44px;
      height: 44px;
    }
  }
  svg {
    height: 48px;
    width: 48px;
  }
  .alert-icon {
    color: #fff;
    margin: 0.5rem;
    margin-right: 2rem;
    @media screen and (max-width: 992px) {
      margin-right: 1rem;
    }
  }
  .alert-icon svg {
    height: 35px;
    width: 35px;
  }
  .ArrowDropDownIconMenu {
    cursor: pointer;
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px) {
      margin: 0 1.5rem 0 0.5rem;
    }
  }
`;

export const UserInfo = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-weight: bold;
  @media screen and (max-width: 992px) {
    display: none;
  }
  .role {
    color: #172c45;
    text-transform: capitalize;
    font-weight: 100;
    background: #18d399;
    font-weight: bold;
    font-size: 14px;
    height: 15px;
    padding: 0 3px;
  }
  .name {
    font-size: 18px;
    font-weight: normal;
  }
`;

export const NavMenu = styled.div`
  top: 80px;
  box-sizing: border-box;
  width: 209px;
  border: 1px solid #d8d8d8;
  background-color: #fff;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0;
  }

  li {
    padding-left: 7px;
    cursor: pointer;
    height: 33px;
    line-height: 35px;
    font-weight: 500;
    display: flex;
    align-items: center;
    :hover {
      background-color: #d7e1ea;
    }
    svg {
      height: 20px;
      width: 20px;
      margin-right: 12px;
    }
  }

  .sign-out {
    color: #ff5656;
  }
`;

export const NavBarContainer = styled.div`
  .header {
    background: #172c45;
    height: 63px;
    padding: 16px;
    display: flex;
    align-items: center;
  }

  .search-employees {
    width: 470px;
  }  

  .main-logo,
  .sites-link,
  .profile-link {
    display: inline;
    width: 100%;
  }

  .profile-link {
    > div {
      cursor: pointer;
      width: fit-content;
    }

    .text-burger-company{
      margin-left: 4px;
    }
  }
  .header-menu-nav{
    display: flex;
  }
  .buttons-uper-menu{
    padding: 24px 16px;
    position: relative;
    z-index: 1;
  }
  .buttons-uper-menu: hover{
    background-color: #25446A;
    padding: 24px 16px;
  } 
  a:hoover{
    background-color: #25446A;
  }
  .buttons-uper-menu-mark{
    padding: 25px 16px;
    background-color: #18D399;
  }

  .text-mark{
    color: black !important;
  }

  .main-logo {
    .image-logo {
      cursor: pointer;
      width: 139px;
      span {
        font-size: 16px;
      }
      span:nth-child(2) {
        padding-left: 5px;
        color: white;
      }
      span:nth-child(3) {
        color: #a1fcb9;
      }
      span:nth-child(4) {
        color: #22c498;
      }
    }
  }

  .sites-link {
    color: white;
    text-align: center;
    width: 1100px;

    div {
      display: inline;
      cursor: pointer;
      vertical-align: middle;
      span {
        color: white;
        padding-left: 5px;
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }

  .profile-link {
    text-align: left;
    color: black;
  }

  .company-link{
    width: 280px !important;
    height: 51px;
    background-color: #F0F5FA;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 12px;
    display: flex;
    align-items: center;
  }
  .company-name{
    weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: black;
  }
  a {
    text-decoration: none;
  }
  .company-settings{
    width: 48px;
    height: 32px;
    border-radius: 6px;
    background-color: #FFFFFF;
    right: 26px;
    position: absolute;
    top: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  .swicth-profile {
    width: 270px !important;
    height: 51px;
    background: #F0F5FA;
    border: 1px solid #E1E4E8;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .searchContainer{
    width: 100%
    padding: 0px;
    position: absolute;
  }
  .searchMobile{
    width: 100%;
    padding: 0px;
    position: absolute;    
  }

  .info-user {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 12px;
      border: 1px #172C45 solid;
      padding: 2px;
    }

    .icon-profile-main {
      margin-right: 2px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: none;
        padding: 0px;
      }
    }

    > div {
      display: inline-block;
      vertical-align: middle;
    }

    > div:nth-child(1) {
      p {
        margin: 0;
        color: white;
      }
    }

    > div:nth-child(2) {
      margin-left: 10px;
    }
  }
  .pop-menu{
    background-color: white;
    border-radius: 10px;
    width: 310px;
    padding: 20px 15px;
    box-shadow: 0px 14px 13px rgba(0, 0, 0, 0.08);
    position: absolute;
    z-index:100;
    top: 40px;
    right: 12px;
  }

  .links-pop-menu{
    width: 270px !important;
    height: 32px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px 18px;
    display: flex;
    align-items: center;
  }
  .links-pop-menu: hover{
    background-color: #F6F8FA;
  }
  .text-pop{
    margin-left: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .upper-menu-right{
    cursor: pointer;    

    img, svg {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 12px;
      border: 1px #172C45 solid;
      padding: 2px;
    }

  }

  .upper-menu-right-active{
    cursor: pointer;

    img, svg {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 12px;
      border: 1px #18D399 solid;
      padding: 2px;
    }
    
  }
  .nameMenu{
    cursor: pointer;
  }

  @media screen and (max-width: 1024px){
   
    .searchContainer{
      display: none;
    }
    .searchMobile{
      z-index: 1;
      width: 78%
      top: 0;
    }

    .mobile-header{
      background-color: #172C45;
      height: 64px;
      width: 100%;
      padding: 20px 20px;
      position: fixed;
      z-index: 1;
      top: 0px;
      
    }
    .mobile-menu{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .mobile-items{
      display: flex;
    }
    .mobile-item{
      margin-left: 20px;
      cursor: pointer;
    }
    .compensation-margin{
      margin-right: 3px;
    }
    .mobile-search{
      cursor: pointer;
    }
    .motherClass{
      background-color: #172C45;
      padding: 15px 0;
      margin: 0 15%;
    }
    .dropdownClass{
      display: flex;
    }
    .burgerStyle{
      position: fixed;
      background-color: white;
      width: 375px;
      top: 64px;
      right: 0;
      border-radius: 6px;
    }
    .burgerHeader{
      height: 101px;
      border-bottom: 1px #E1E4E8 solid;
      padding: 30px 23px 20px 23px;
      display: flex;
      align-items: center;
    }
    .burgerAvatar{
      margin-right: 20px;
      width: 60px;
      height: 60px;
    }
    .borderGreen{
      width: 66px;
      border-radius: 50px;
      border: 1px #18D399 solid;
      padding: 2px;
    }
    .borderImage{
      border-radius: 50px;
    }
    .burgerProfile{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .burgerName{
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      height: 13px;
      white-space: nowrap;
    }
    .burgerText{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #6A737D;
    }
    .burgerPrimaryRoutes{
      height: 450px;
      padding: 20px;
      
    }
    .buttons-burger-menu{
      padding: 12px 22px;
      margin-bottom: 5px;
      display: flex;
    }
    .buttons-burger-menu-mark{
      width: 335px;
      height: 44px;
      margin-bottom: 5px;
      padding: 12px 22px;
      background-color: #18D399;
      border-radius: 6px;
      display: flex;
    }
    .text-burger-mark{
      margin-left: 11px;
      color: #24292E;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .text-burger-normal{
      margin-left: 11px;
      color: #6A737D;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      
    }
    .text-burger-company{
      margin-left: 11px;
      color: #24292E;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
       
    .ownerBurgerAccess{
      padding: 20px;
      position: absolute;
      bottom: 120px;
      border-top: 1px #E1E4E8 solid;
      width: 100%;
    }
    .company-link-burger{
      background-color: #F0F5FA;
      width: 335px;
      height: 54px;
      border-radius: 6px;
      padding: 13px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .links-burger{
      height: 32px;
      padding: 5px 17px;
      margin-top: 11px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      :hover{
        background-color: #F6F8FA;
      }
    }
    .icon-profile-burger{
      margin-right: 15px;
      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: none;
        padding: 0px;
      }
    }
    .company-settings-burger{
      width: 48px;
      height: 32px;
      border-radius: 6px;
      background-color: #FFFFFF;
      right: 26px;
      position: absolute;
      top: 581px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calendarPush{ 
      height: 64px;
      width: 47px;
      background-color: #18D399;
      margin: -20px -13px 0 -14px;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .buttonCalendar{
      width: 105px;
      height: 36px;
      border-radius: 6px;
      margin-top: -5px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .buttons-uper-menu{
      display: none;
      z-index: -1;
    }
    .sites-link{
      a{
        visibility: hidden;
      }
    }
    .nameMenu{
      visibility: hidden;
    }
 }

  @media screen and (min-width: 1025px){
    .mobile-header{
      display: none;
    }
  }
  @media screen and (max-width: 600px){
    .search-employees{
      width: 200px;
    }
  }
`;
