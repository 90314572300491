import React, { useContext, useState } from 'react';
import { AppContext } from '../../Context/App/AppContext';
import GoogleLogin from 'react-google-login';
import { CLIENTE_ID } from '../../services/Utils/getUtils';
import {
  LoginContainer,
  Div,
  LoginHeader,
  LoginContent,
  Button,
  H1
} from './styles';
import {
  SIGN_GOOGLE,
  WELCOME,
  TEAMONBOARD
} from '../../services/Constants/getLabels';
import Loadingsimpleteamonboardnew from '../../components/Loader';

const Login = props => {
  const consoleStyle =
    'color: blue; background:#505050; -webkit-text-stroke: 1px black; font-size:24px;';
  const { activateAuth, removeAuth, loadingLogin } = useContext(AppContext);
  const [loading, setLoading] = useState();

  function responseGoogle(response) {
    if (response && !response.error) {
      console.log('%c🚀 Go Teamonboard!', consoleStyle);
      const basicProfile = response.getBasicProfile();

      const authResponse = response.getAuthResponse();

      let tokenId = response.tokenId ? response.tokenId : authResponse.id_token;
      let email = response.profileObj.email
        ? response.profileObj.email
        : basicProfile.yu;
      let name = response.profileObj.name
        ? response.profileObj.name
        : basicProfile.Ad;

      activateAuth(
        tokenId,
        props.params,
        email,
        name,
        response.profileObj.imageUrl,
        () => {
          setLoading(false);
        }
      );
      return
    } else if (response && response.error === 'popup_closed_by_user') {
      console.log('%c🛑 Stopped Login!', consoleStyle);
      setLoading(false);
    } else {
      console.log('%c🛑 Failed Login!', consoleStyle);
      setLoading(false);
      removeAuth(false);
    }
  }

  if (loadingLogin || loading) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <LoginContainer>
      <Div>
        <LoginHeader>
          <img src="/images/logo-tob.svg" alt="logo" />
          <h1>{TEAMONBOARD}</h1>
        </LoginHeader>
        <LoginContent>
          <H1>
            {WELCOME}! <img src="/images/hi-palm.svg" alt="hi" />
          </H1>
          <GoogleLogin
            clientId={CLIENTE_ID}
            render={renderProps => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                </svg>
                {SIGN_GOOGLE}
              </Button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            onRequest={() => {
              setLoading(true);
            }}
            cookiePolicy={'single_host_origin'}
            prompt="consent"
          />
        </LoginContent>
      </Div>
    </LoginContainer>
  );
};

export default Login;
