import React, { useState, useContext, useEffect, useRef } from 'react';
import { AccountCircle } from '@material-ui/icons';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import moment from 'moment';
import { AppContext } from '../../../../Context/App/AppContext';
import { IconEvents } from '../../../../components/Icons/IconEvents';
import CheckMark from '../../../../components/Icons/CheckMark';
import { useGetPtoPendingByTeam } from '../../../../graphql/container/query/company';
import Loadingsimpleteamonboardnew from '../../../../components/Loader';
import {
  DATE_FORMAT,
  COPY_BY_MAIL_TO_THE_POSITION
} from '../../../../services/Constants/getDefaults';
import { ACCEPT_OR_DECLINE_PTO_MUTATION } from '../../../../graphql/queries/timeOffAccruals';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import TabsPto from '../../../../components/TabsPto';
import { ContentPtoTeam } from './styles';
import {
  routesManager,
  routesOwner
} from '../../../../services/Constants/paths';
import Moment from 'react-moment';
import {
  transformUTCDate,
  transformUTCDateNoTime,
  simpleFormatDate,
  verifyHolidays,
  formatStringDate
} from '../../../../services/Utils/getUtils';
import { Col } from '../../../../components/Grid/Grid';
import Wrong from '../../../../components/Icons/Wrong';
import Check from '../../../../components/Icons/Check';
import Exclamation from '../../../../components/Icons/Exclamation';
import IconProfileTeams from '../../../../components/Icons/IconProfileTeams';
import EmployeeTeams from './EmployeeTeams';
import { readCookies } from '../../../../services/Utils/getUtils';
import { SearchEmployeeContainer } from '../../../../components/SearchEmployee/styles.js';
import Search from '../../../../components/Icons/Search';
import { ModalAlert } from '../../../../components/Modals';
import DatePickerModule from '../../../../components/DatePickerModal';
import { useGetPtoRulesByCompanyQuery } from '../../../../graphql/container/query/timeOffRules';
import {
  EDIT_PTO_MUTATION,
  DELETE_PTO_MUTATION
} from '../../../../graphql/mutations/abscenceRequest';
import { PermissionsEmployee } from '../../../../services/Constants/getDefaults';
import {
  EXCEEDED_AMOUNT_OF_DAYS,
  YOU_CAN_NOT_REQUEST_WEEKENDS,
  ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY,
  YOU_CAN_NOT_REQUEST_SAME_DAY,
  YOU_CAN_NOT_REQUEST_BOOKED_DAYS,
  YOU_CAN_NOT_UPDATE_BOOKED_DECLINED,
  CHOOSE_START_GREATER_END_DATE,
  YOU_UPDATED_THE_REGISTER
} from '../../../../services/Constants/getLabels';
import { useEmployeesByTeamsAndCompany } from '../../../../graphql/container/query/teams';
import { useGetEmployeeWithTeamsQuery } from '../../../../graphql/container/query/company';
import { useTeamsByCompanyQueryEmployeeNotTeamsForRole } from '../../../../graphql/container/query/teams';
import DropdownPto from '../../../../components/DropdownPto';
import Trash from '../../../../components/Icons/Trash';

const initialsColumns = [
  {
    name: 'Name',
    key: 'nameEmployeeTeam',
    active: true
    // width: '40%'
  },
  {
    name: 'Role',
    key: 'rolEmployeeTeam',
    active: true
  },
  {
    name: 'Email',
    key: 'emailEmployeeTeam',
    active: true
  }
];

const PtosTeam = () => {
  const { roleUser } = useContext(AppContext);
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const navigate = useNavigate();
  const companyId = currentUser?.companyRole[0].to;
  const location = useLocation();
  const params = useParams();
  const teamName = location?.state?.teamName;
  const [listPending, setListPending] = useState([]);
  const [listPast, setListPast] = useState([]);
  const idTeam = params?.id;
  const [tabActive, setTabActive] = useState(0);
  const [columnsTable] = useState(initialsColumns);
  const [listEmployeeByTeam, setListEmployeeByTeam] = useState([]);
  const [allTeamEmployees, setAllTeamEmployees] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editInitialsStartDate, seteditInitialsStartDate] = useState(
    new Date()
  );
  const [editInitialsEndDate, seteditInitialsEndDate] = useState(new Date());
  const [editEndDate, seteditEndDate] = useState(new Date());
  const [editStartDate, seteditStartDate] = useState(new Date());
  const [note, setnote] = useState('');
  const [ptoselect, setptoselect] = useState({});
  const [editModal, seteditModal] = useState(false);
  const [endDate, setEndDate] = useState();
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(false);
  const [cacheFiltersTeams, setCacheFiltersTeams] = useState(
    readCookies('TeamsEmployeeTable')
  );
  const [sortChoiceTeam, setSortChoiceTeam] = useState(
    cacheFiltersTeams?.[2] ? JSON.parse(cacheFiltersTeams[2]) : true
  );
  const [itemEmployeesTeam, setItemEmployeesTeam] = useState(
    cacheFiltersTeams?.[1] ? JSON.parse(cacheFiltersTeams[1]) : 'Name'
  );
  const [statusPto, setStatusPto] = useState();
  const [deleteAlert, setdeleteAlert] = useState(false);
  const currentDate = moment();
  const currentYear = currentDate.year();

  const {
    data: { getAllEmployeeByTeams },
    loading: loadingEmployees
  } = useEmployeesByTeamsAndCompany(companyId, idTeam);

  const {
    data: { getEmployeesForTeamByCompanyByTeams }
  } = useGetEmployeeWithTeamsQuery(companyId, idTeam);

  const {
    data: { getAllEmailsOwnerAndAdminByCompany }
  } = useTeamsByCompanyQueryEmployeeNotTeamsForRole(companyId);

  const {
    data: { getPTOPendingByTeam },
    loading: loadingPtoPending,
    refetch: refetchPtoPendind
  } = useGetPtoPendingByTeam(companyId, idTeam);
  const {
    data: { getPtoRulesByCompany }
  } = useGetPtoRulesByCompanyQuery(companyId);
  const [
    acceptOrDeclinePto,
    { loading: acceptOrDeclinePtoLoading }
  ] = useMutation(ACCEPT_OR_DECLINE_PTO_MUTATION);
  const [editPtoMutation] = useMutation(EDIT_PTO_MUTATION);
  const [deletePtoMutation, { loading: deletePtoLoading }] = useMutation(
    DELETE_PTO_MUTATION
  );
  const permissionsEdit =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN ||
    roleUser === PermissionsEmployee.ADMIN_EMPLOYEE;

  const onSubmitPtoPending = (item, status) => {
    let emails = '';

    const employeeHr = getAllEmailsOwnerAndAdminByCompany?.filter(
      item =>
        item.role.toLowerCase() === COPY_BY_MAIL_TO_THE_POSITION.toLowerCase()
    );

    employeeHr.forEach(item => {
      emails = emails + item?.emailEmployee + ';';
    });

    acceptOrDeclinePto({
      variables: {
        status,
        id: item.id,
        emails: emails.slice(0, emails.length - 1)
      }
    });
  };

  useEffect(() => {
    if (getAllEmployeeByTeams) {
      const employeeByTeam = getAllEmployeeByTeams?.filter(
        el => el.isManager !== '1'
      );
      setListEmployeeByTeam(employeeByTeam);
    }
  }, [getAllEmployeeByTeams]);

  useEffect(() => {
    handleSortTeam(itemEmployeesTeam);
    // eslint-disable-next-line
  }, [sortChoiceTeam, itemEmployeesTeam, listEmployeeByTeam]);

  const handleSortTeam = value => {
    const data = listEmployeeByTeam;
    if (value === 'Name') {
      const sortTeamName = sortChoiceTeam
        ? data &&
          data.sort((a, b) => {
            if (a.nameEmployee.toLowerCase() > b.nameEmployee.toLowerCase()) {
              return 1;
            }
            if (a.nameEmployee < b.nameEmployee) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            if (a.nameEmployee.toLowerCase() < b.nameEmployee.toLowerCase()) {
              return 1;
            }
            if (a.nameEmployee.toLowerCase() > b.nameEmployee.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
      return setAllTeamEmployees(sortTeamName);
    } else if (value === 'Role') {
      const sortTeamName = sortChoiceTeam
        ? data &&
          data.sort((a, b) => {
            if (a.role.toLowerCase() > b.role.toLowerCase()) {
              return 1;
            }
            if (a.role < b.role) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            if (a.role.toLowerCase() < b.role.toLowerCase()) {
              return 1;
            }
            if (a.role.toLowerCase() > b.role.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
      return setAllTeamEmployees(sortTeamName);
    } else if (value === 'Email') {
      const sortTeamName = sortChoiceTeam
        ? data &&
          data.sort((a, b) => {
            if (a.emailEmployee.toLowerCase() > b.emailEmployee.toLowerCase()) {
              return 1;
            }
            if (a.emailEmployee < b.emailEmployee) {
              return -1;
            } else {
              return 0;
            }
          })
        : data &&
          data.sort((a, b) => {
            if (a.emailEmployee.toLowerCase() < b.emailEmployee.toLowerCase()) {
              return 1;
            }
            if (a.emailEmployee.toLowerCase() > b.emailEmployee.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          });
      return setAllTeamEmployees(sortTeamName);
    }
  };

  useEffect(() => {
    if (itemEmployeesTeam) {
      saveCookies();
      setCacheFiltersTeams(readCookies('TeamsEmployeeTable'));
    }
    // eslint-disable-next-line
  }, [itemEmployeesTeam, sortChoiceTeam]);

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `TeamsEmployeeTable=; expires=${dateExpires}`;
    document.cookie = `TeamsEmployeeTable=***${JSON.stringify(
      itemEmployeesTeam
    )}***${JSON.stringify(
      sortChoiceTeam
    )}; expires=31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
  }

  useEffect(() => {
    if (isMounted.current) {
      if (!acceptOrDeclinePtoLoading) {
        refetchPtoPendind({ companyId });
      }
    }
    // eslint-disable-next-line
  }, [acceptOrDeclinePtoLoading]);

  function sortFieldsForDate(list) {
    if (list.length) {
      list.sort(function(a, b) {
        if (a.startDate.toLowerCase() < b.startDate.toLowerCase()) {
          return 1;
        }
        if (a.startDate.toLowerCase() > b.startDate.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    }
    return list;
  }

  useEffect(() => {
    if (getPTOPendingByTeam) {
      setListPending(
        sortFieldsForDate(
          getPTOPendingByTeam.filter(pto => pto.status === 'PENDING')
        )
      );
      setListPast(getPTOPendingByTeam.filter(pto => pto.status !== 'PENDING'));
      isMounted.current = true;
    }
  }, [getPTOPendingByTeam]);

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }
  const handleNavigate = item => {
    window.history.pushState(null, null, location.pathname);
    navigate(routesOwner.editEmployee(item.id), {
      state: { site: 'profile', user: item },
      replace: true
    });
  };

  const validateEditPTO = item => {
    if (item.status === 'DECLINED') {
      setMessage(`${YOU_CAN_NOT_UPDATE_BOOKED_DECLINED}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  const deletePto = async (ptoselect, employeeId) => {
    const startDate = moment(ptoselect.startDate)
      .utc()
      .year();
    const endDate = moment(ptoselect.endDate)
      .utc()
      .year();

    const ptoCurrentYear =
      currentYear === startDate && currentYear === endDate ? true : false;
    const ptoIdAbscenceRequest = ptoselect.id;

    const amountDaysOff = ptoselect?.daysOff
      ? ptoselect?.daysOff?.toString()
      : '0';

    try {
      await deletePtoMutation({
        variables: {
          PtoId: ptoIdAbscenceRequest,
          AmountDays: amountDaysOff,
          Type: ptoselect.type,
          EmployeeId: employeeId,
          Status: statusPto,
          PtoCurrentYear: ptoCurrentYear
        }
      });
      setdeleteAlert(false);
      refetchPtoPendind({ companyId });
    } catch (error) {
      console.log(error);
    }
  };

  const editPTOvalue = item => {
    seteditInitialsStartDate(new Date(transformUTCDateNoTime(item.startDate)));
    seteditInitialsEndDate(new Date(transformUTCDateNoTime(item.endDate)));
    seteditStartDate(new Date(transformUTCDateNoTime(item.startDate)));
    seteditEndDate(new Date(transformUTCDateNoTime(item.endDate)));
    setnote(item.message);
    setptoselect(item);
    if (validateEditPTO(item)) {
      seteditModal(true);
    }
  };

  const resetFunction = () => {
    endDate && setEndDate();
  };

  const validateCalendarRulesPtoEdit = employeeId => {
    const initialStartRequest = simpleFormatDate(editInitialsStartDate);
    const initialEndRequest = simpleFormatDate(editInitialsEndDate);
    const startRequest = simpleFormatDate(editStartDate);
    const endRequest = simpleFormatDate(editEndDate);
    const starts = listPending
      .filter(pto => pto.employee === employeeId)
      .map(el => el.startDate.slice(0, 10));
    const ends = listPending
      .filter(pto => pto.employee === employeeId)
      .map(el => el.endDate.slice(0, 10));
    const startsApproved = listPast
      .filter(pto => pto.employee === employeeId && pto.status === 'APPROVED')
      .map(el => el.startDate.slice(0, 10))
      .filter(item => item !== initialStartRequest);
    const endsApproved = listPast
      .filter(pto => pto.employee === employeeId && pto.status === 'APPROVED')
      .map(el => el.endDate.slice(0, 10))
      .filter(item => item !== initialEndRequest);

    if (
      initialStartRequest === startRequest &&
      initialEndRequest === endRequest
    ) {
      return false;
    }

    if (editStartDate > editEndDate) {
      setMessage(`${CHOOSE_START_GREATER_END_DATE}`);
      setAlert(true);
      return false;
    }
    if (
      editStartDate?.toString().includes('Sat') ||
      editStartDate?.toString().includes('Sun') ||
      editEndDate?.toString().includes('Sat') ||
      editEndDate?.toString().includes('Sun')
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_WEEKENDS}`);
      setAlert(true);
      return false;
    }
    if (
      verifyHolidays(editStartDate, getPtoRulesByCompany.holidays) ||
      verifyHolidays(editEndDate, getPtoRulesByCompany.holidays)
    ) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY}`);
      setAlert(true);
      return false;
    }
    if (
      starts.includes(startRequest || endRequest) ||
      ends.includes(startRequest || endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_SAME_DAY}`);
      setAlert(true);
      return false;
    }
    if (
      startsApproved.includes(startRequest || endRequest) ||
      endsApproved.includes(startRequest || endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_BOOKED_DAYS}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  const editPto = async (ptoselect, employeeId) => {
    if (validateCalendarRulesPtoEdit(employeeId)) {
      try {
        const updatePto = await editPtoMutation({
          variables: {
            startDate: editStartDate,
            endDate: editEndDate,
            message: note ? note : '',
            ptoId: ptoselect.id,
            amountDays: ptoselect.daysOff.toString(),
            type: ptoselect.type,
            employeeId: employeeId
          }
        });
        if (updatePto.data.EditPto) {
          setMessage(`${YOU_UPDATED_THE_REGISTER}`);
          setAlert(true);
        } else {
          setMessage(`${EXCEEDED_AMOUNT_OF_DAYS}`);
          setAlert(true);
        }
        seteditModal(false);
        refetchPtoPendind({ companyId });
      } catch (error) {
        console.log(error);
      }
    }
    seteditModal(false);
  };

  const getTeamsById = idEmployee => {
    return getEmployeesForTeamByCompanyByTeams
      ?.filter(
        ({ employeeId, employeeIdManager }) =>
          employeeId === idEmployee && employeeId !== employeeIdManager
      )
      .map(({ teams, fullNameManager }) => ({
        manager: fullNameManager,
        name: teams
      }))
      .slice(0, 6);
  };

  return (
    <>
      <ModalAlert
        show={alert}
        onClose={() => setAlert(false)}
        content={message}
        buttonCancel={'Ok, Close'}
        confirmButton={false}
        buttonCancelStyle={'buttonAlertPto'}
        alertClass={'alertIcon'}
        oneButton
      />
      <Breadcrumbs
        routes={routesManager.Teams}
        section={'Team'}
        subtitle={teamName}
      />
      <ContentPtoTeam>
        <div className="title-teams">
          <IconProfileTeams />
          <span> {teamName} </span>
        </div>
        <div className="section-activity section-scroll">
          <div className="permission-header">
            <div>
              <h3 className="sub-heading">
                Team Members ({listEmployeeByTeam.length})
              </h3>
            </div>
            <div className="content-search">
              <SearchTeams
                setAllTeams={setAllTeamEmployees}
                TeamsData={listEmployeeByTeam}
              />
            </div>
          </div>
          <div className="teams-table">
            <EmployeeTeams
              columns={columnsTable}
              dataSource={allTeamEmployees}
              loading={loadingEmployees}
              selected={selected}
              setSelected={setSelected}
              setItemEmployeesTeam={setItemEmployeesTeam}
              setSortChoiceTeam={setSortChoiceTeam}
              sortChoiceTeam={sortChoiceTeam}
            />
          </div>
        </div>

        <div className="section-activity" style={{ marginTop: 0 }}>
          <div className="activities-header">
            <span className="sub-heading">
              PTO
              <div className="little-subtitle">
                <Exclamation />
                <p>
                  Here you will be able to check your pending assigned requests
                  and the PTO history of your team.
                </p>
              </div>
            </span>
          </div>
          <div className="section-pending-activities">
            <TabsPto
              tabActive={tabActive}
              setTabActive={value => {
                setTabActive(value);
              }}
            />
            <div className="content-tabs">
              {tabActive === 0 ? (
                loadingPtoPending || acceptOrDeclinePtoLoading ? (
                  <div className="loading">
                    <CircularProgress className="circular-progress" />
                  </div>
                ) : listPending.length ? (
                  <>
                    {listPending.map((item, i) => {
                      return (
                        <DropdownPto
                          key={`ptoteams-${i}`}
                          id={`ptoteams-${i}`}
                          item={item}
                          teams={getTeamsById(item?.employeeInfo?.id)}
                        >
                          <div className="pto-item">
                            <div className="d-flex">
                              <div className="pto-icon">
                                <IconEvents
                                  icon={
                                    item.type === 'SICK'
                                      ? item.type.toLowerCase()
                                      : 'pto'
                                  }
                                />
                              </div>
                              <div className="card-icon">
                                <span className="icon-profile">
                                  {item.employeeInfo?.user?.photo ? (
                                    <img
                                      src={item.employeeInfo?.user?.photo}
                                      alt="Profile"
                                      onErrorCapture={e =>
                                        (e.target.onErrorCapture = null)
                                      }
                                      referrerPolicy="no-referrer"
                                    />
                                  ) : (
                                    <AccountCircle
                                      style={{ fontSize: 43, margin: '-3px' }}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="pto-date-status">
                                <div>
                                  <button
                                    className="noButtonStyle"
                                    onClick={() => {
                                      handleNavigate(item.employeeInfo);
                                    }}
                                  >
                                    <span className="pto-name-employee">
                                      {item?.employeeInfo?.fullName}
                                    </span>
                                  </button>
                                </div>
                                <span className="pto-dates">
                                  {moment(item.startDate)
                                    .utc()
                                    .format(DATE_FORMAT)}
                                  {' - '}
                                  {moment(item.endDate)
                                    .utc()
                                    .format(DATE_FORMAT)}
                                </span>
                              </div>
                            </div>

                            <div className="pto-buttons">
                              <Button
                                className="btn-danger"
                                onClick={() =>
                                  onSubmitPtoPending(item, 'DECLINED')
                                }
                              >
                                <span className="text-bold">Decline</span>
                              </Button>
                              <Button
                                className="btn-success "
                                onClick={() =>
                                  onSubmitPtoPending(item, 'APPROVED')
                                }
                              >
                                <span className="text-bold">Accept</span>
                              </Button>
                            </div>
                          </div>
                        </DropdownPto>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-Data">
                    <CheckMark color="black" />
                    <div>
                      <span className="text-normal">
                        No PTO or Sick Days requests
                      </span>
                    </div>
                  </div>
                )
              ) : tabActive === 1 ? (
                listPast?.length ? (
                  <>
                    {listPast.map((item, i) => {
                      return (
                        <DropdownPto
                          key={`teams-past-${i}`}
                          id={`teams-past-${i}`}
                          item={item}
                          teams={getTeamsById(item?.employeeInfo?.id)}
                        >
                          <div className="pto-item">
                            <div className="d-flex">
                              <div className="pto-icon">
                                <IconEvents
                                  icon={
                                    item.type === 'SICK'
                                      ? item.type.toLowerCase()
                                      : 'pto'
                                  }
                                />
                              </div>
                              <div className="card-icon">
                                <span className="icon-profile">
                                  {item.employeeInfo?.user?.photo ? (
                                    <img
                                      src={item.employeeInfo?.user?.photo}
                                      alt="Profile"
                                      onErrorCapture={e =>
                                        (e.target.onErrorCapture = null)
                                      }
                                      referrerPolicy="no-referrer"
                                    />
                                  ) : (
                                    <AccountCircle
                                      style={{ fontSize: 43, margin: '-3px' }}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="pto-date-status">
                                <div>
                                  <button
                                    className="noButtonStyle"
                                    onClick={() => {
                                      handleNavigate(item.employeeInfo);
                                    }}
                                  >
                                    <span className="pto-name-employee">
                                      {item?.employeeInfo?.fullName}
                                    </span>
                                  </button>
                                </div>
                                <div className="pto-dates">
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(
                                      item.dateTakenStart || item.startDate
                                    )}
                                  </Moment>
                                  {' - '}
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(
                                      item.dateTakenEnd || item.endDate
                                    )}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="card-buttons-edit">
                                <Button
                                  className="btn-danger-declined me-3"
                                  onClick={() => {
                                    setStatusPto(
                                      item.status === 'DECLINED'
                                        ? 'DECLINED'
                                        : 'APPROVED'
                                    );
                                    setptoselect(item);
                                    setdeleteAlert(true);
                                  }}
                                >
                                  <Trash color="#fff" />
                                  <span className="text-bold text-white">
                                    Delete
                                  </span>
                                </Button>
                                <Button
                                  className="btn-success-accept"
                                  onClick={() => editPTOvalue(item)}
                                >
                                  <img
                                    src="/images/editPTO.svg"
                                    alt="editPto"
                                  />
                                  <span className="text-bold">Edit</span>
                                </Button>
                              </div>
                              <div
                                className={
                                  permissionsEdit ? 'ptobtns-edit' : 'ptobtns-'
                                }
                              >
                                <div
                                  className={
                                    item.status === 'DECLINED'
                                      ? 'icon-status-declined'
                                      : 'icon-status-approved'
                                  }
                                >
                                  <Col>
                                    {item.status === 'DECLINED' ? (
                                      <Wrong />
                                    ) : (
                                      <Check />
                                    )}
                                    <span>
                                      {item.status === 'DECLINED'
                                        ? 'Declined'
                                        : 'Approved'}
                                    </span>
                                  </Col>
                                </div>
                              </div>
                              <div
                                className={
                                  permissionsEdit
                                    ? 'btn-actions'
                                    : 'btn-actions-none'
                                }
                              >
                                <img
                                  src="/images/deletePTO.svg"
                                  alt=""
                                  onClick={() => {
                                    setStatusPto(
                                      item.status === 'DECLINED'
                                        ? 'DECLINED'
                                        : 'APPROVED'
                                    );
                                    setptoselect(item);
                                    setdeleteAlert(true);
                                  }}
                                />
                                <img
                                  src="/images/editPTO.svg"
                                  alt=""
                                  onClick={() => {
                                    editPTOvalue(item);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </DropdownPto>
                      );
                    })}

                    <ModalAlert
                      show={editModal}
                      onClose={() => seteditModal(false)}
                      noIcon
                      onConfirm={() => {
                        editPto(ptoselect, ptoselect.employee);
                      }}
                      disableUpdate={
                        editStartDate && editEndDate ? false : true
                      }
                      buttonCancel="Cancel"
                      confirmLabel="Update PTO"
                      buttonCancelStyle="btn-danger-without-bg"
                      content={
                        <div className="text-left">
                          <div>
                            <span className="sub-heading-editpto">
                              Edit PTO
                            </span>
                          </div>
                          <div className="content-alert-edit">
                            <div className="innerLabel">
                              <label className="label-input-edit">
                                Start Date
                              </label>
                              <DatePickerModule
                                principalClass={'inner-calendar-pto-alert'}
                                weekendDisabled
                                onChange={value => seteditStartDate(value)}
                                onClick={() => resetFunction()}
                                minDate={new Date(new Date().setMonth(0, 1))}
                                selected={editStartDate}
                                maxDate={new Date().setMonth(
                                  new Date().getMonth() + 24
                                )}
                                placeholderText="MM/DD/YYYY"
                              />
                            </div>
                            <div className="innerLabel">
                              <label>End Date</label>
                              <DatePickerModule
                                principalClass={'inner-calendar-pto-alert'}
                                weekendDisabled
                                onChange={value => seteditEndDate(value)}
                                onClick={() => resetFunction()}
                                minDate={new Date(editStartDate)}
                                selected={editEndDate}
                                maxDate={new Date().setMonth(
                                  new Date().getMonth() + 24
                                )}
                                placeholderText="MM/DD/YYYY"
                              />
                            </div>
                            <div className="innerLabel ">
                              <label className="label-input-edit">Note</label>
                              <div className="note-ptoadmin bottom-ptoadmin">
                                <textarea
                                  onChange={({ target }) =>
                                    setnote(target.value)
                                  }
                                  placeholder="Text Example"
                                  rows="6"
                                  cols="43"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                    <ModalAlert
                      show={deleteAlert}
                      onClose={() => setdeleteAlert(false)}
                      confirmLabel="Delete activity"
                      onConfirm={() => {
                        deletePto(ptoselect, ptoselect.employee);
                      }}
                      buttonCancel="Cancel"
                      buttonCancelStyle="btn-danger-without-bg"
                      content={
                        <div>
                          <div className="content-alert">
                            <p className="label-alert">
                              Are you sure you want to delete this{' '}
                              {ptoselect.type === 'DAY_OFF'
                                ? 'PTO'
                                : 'SICK DAY'}{' '}
                              activity from
                              <b>
                                {' '}
                                {ptoselect.dateTakenStart
                                  ? formatStringDate(ptoselect.dateTakenStart)
                                  : formatStringDate(ptoselect.startDate)}{' '}
                              </b>{' '}
                              to{' '}
                              <b>
                                {' '}
                                {ptoselect.dateTakenEnd
                                  ? formatStringDate(ptoselect.dateTakenEnd)
                                  : formatStringDate(ptoselect.endDate)}
                              </b>
                              ? <br></br>
                              <br></br>
                              {(currentYear ===
                                (ptoselect.startDate
                                  ? moment(ptoselect.startDate)
                                      .utc()
                                      .year()
                                  : moment(ptoselect.dateTakenStart)
                                      .utc()
                                      .year()) &&
                              currentYear ===
                                (ptoselect.endDate
                                  ? moment(ptoselect.endDate)
                                      .utc()
                                      .year()
                                  : moment(ptoselect.dateTakenEnd)
                                      .utc()
                                      .year())
                              ? true
                              : false)
                                ? `Remember that these days will be returned to the employee.`
                                : `These days won't be returned to the employee.`}
                            </p>
                          </div>
                        </div>
                      }
                      centered
                      disabled={deletePtoLoading}
                      disableUpdate={deletePtoLoading}
                    />
                  </>
                ) : (
                  <div className="no-Data">
                    <CheckMark color="black" />
                    <div>
                      <h5 className="text-normal">
                        No PTO or sick Days request
                      </h5>
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
      </ContentPtoTeam>
    </>
  );
};

/**
 * This function is a React component that renders a search bar that filters the data in the table
 * below
 */
const SearchTeams = ({ setAllTeams, TeamsData }) => {
  const [labelSearch, setLabelSearch] = useState('');

  const searchTeam = search => {
    if (search.trim() === '') {
      setAllTeams(TeamsData);
    } else {
      const newState = TeamsData.filter(obj => {
        if (
          obj?.nameEmployee.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          return obj;
        } else {
          return false;
        }
      });
      setAllTeams(newState);
    }
  };
  return (
    <div className="search">
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setLabelSearch(target.value);
                searchTeam(target.value);
              }}
              placeholder={'Search member'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search-permissions"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default PtosTeam;
