import gql from 'graphql-tag';

export const TIME_OFF_ACCRUALS_QUERY = gql`
  query TIME_OFF_ACCRUALS_QUERY($id: String!) {
    timeOffAccrualsPlusCalc(where: { employee: { id: { equals: $id } } }) {
      id
      employee {
        id
        middleName
        firstName
      }
      daysOff
      daysUsed
      daysSick
      createdAt
      updatedAt
    }
  }
`;

export const ACCEPT_OR_DECLINE_PTO_MUTATION = gql`
  mutation ACCEPT_OR_DECLINE_PTO_MUTATION(
    $status: String!
    $id: String!
    $emails: String!
  ) {
    acceptOrDeclinePto(status: $status, id: $id, emails: $emails)
  }
`;
