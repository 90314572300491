import React from 'react';

const Persons = ({ color = 'white', size = '16' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83334 12.6667C1.83334 11.2 3.03334 10 4.5 10H7.16667C8.63334 10 9.83334 11.2 9.83334 12.6667"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4C8.43334 4.93333 8.43334 6.4 7.5 7.26667C6.56667 8.13333 5.1 8.2 4.23334 7.26667C3.36667 6.33333 3.3 4.93333 4.16667 4C5.03334 3.06667 6.56667 3.13333 7.5 4Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1667 9.33331H13.1667C14.3 9.33331 15.1667 10.2 15.1667 11.3333"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3667 4.46666C14.0333 5.13333 14.0333 6.2 13.3667 6.8C12.7 7.4 11.6333 7.46666 11.0333 6.8C10.4333 6.13333 10.3667 5.06666 11.0333 4.46666C11.6333 3.86666 12.7 3.86666 13.3667 4.46666"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Persons;
