import styled from 'styled-components';

export const ButtonContentSideCalendar = styled.div`
   {
    .button-calendar {
      margin-right: 320px;
    }

    .button-calendar-watchEvents {
      display: none;
      margin-right: 320px;
    }
  }
`;

export const ContentSideCalendar = styled.div`
  
  height: 100%

  .rw-calendar-contained {
    border: white;
    border-radius: 8px 8px 0px 0px;

    .rw-calendar-header {
      border-bottom: 1px solid #e1e4e8;
    }

    .rw-calendar-transition-group {
      width: 97% !important;
      margin: auto !important;
    }

    .rw-calendar-month {
      .rw-calendar-row {
        height: 45px;

        .rw-state-selected,
        .rw-cell {
          border-radius: 50%;
        }
      }
    }

    .rw-calendar-head {
      .rw-calendar-row {
        .rw-head-cell {
          border: none;
          font-weight: 600;
        }
      }
    }

    .rw-calendar-btn-view {
      font-size: 16px;
    }

    .rw-state-selected {
      background: #24292e !important;
      border: none;
    }

    .rw-calendar-btn-today {
      background: #18d399 !important;
      border-radius: 6px !important;
    }
  }

  .underCalendar {
    contain: content;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
  }
  .aside-menu-border {
    border-radius: 8px;
    width: 360px;
    height: 100%
    background-color: white;
  }

  .aside-menu-border-none {
    display: none;
  }

  .react-calendar-datetime-picker {
    z-index: 0 !important;
  }

  .calender-modal {
    box-shadow: none !important;
    width: 100% !important;

    .todayBtn {
      text-decoration: none;
      background: #18d399;
      width: 80%;
      color: white;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
    }
  }

  .custom-header {
    padding: 15px 20px !important;
    border-bottom: solid #e1e4e8 !important;
    background-color: #ffffff !important;
  }
  .custom-header > div,
  .custom-header > a {
    color: #1d4069 !important;
  }
  .custom-days {
    height: 100% !important;
    margin: auto !important;
    width: 80% !important;

    .is-selected-day-from,
    .is-selected-day-to,
    .is-selected-day {
      background-color: #1d4069 !important;
    }

    .is-selected-day-range {
      background-color: #d7e1ea !important;
      color: #1d4069 !important;
    }

    .is-today {
      box-shadow: inset 0px 0px 0px 2px #1d4069 !important;
    }

    .is-disabled {
      background-color: white !important;
      color: #d1d5da !important;
    }

    .is-week-days {
      color: black !important;
      font-size: 10pt !important;
    }

    .is-prev-month,
    .is-pointer,
    .is-next-month {
      font-size: 14pt !important;
    }
  }

  .title-day {
    margin: 0 0 0 0;
  }
  .title-next-days {
    margin: 0;
  }
  .title-day,
  .title-next-days {
    & > div {
      padding: 10px 0 10px 20px;
    }
  }

  .title-calendar {
    border-top: 1px solid #e1e4e8;
  }

  .title-calendar > div > span {
    font-size: 16px;
  }

  .card-section-event {
    padding: 25px 35px 0px 35px;
  }
  .card-event {
    margin: 0 0 15px 0;
    & .card-event-icon {
      text-align: center;
      padding: 0%;
      width: 36px;
      height: 36px;
      margin-left: 8px;

      & img {
        border-radius: 50%;
        width: 100%;
      }
    }
  }

  .card-event-type {
    display: inline-block;
    width: auto;
    padding: 0;
  }

  .card-event-name {
    inline-size: 100%;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: left;
  }

  .event-no-data {
    padding: 100px 0;
    text-align: center;

    & svg {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    .aside-menu-border {
      display: none;
    }
    .aside-menu-mobile {
      position: fixed;
      z-index: 1;
      width: 375px;
      right: 0;
      overflow-y: auto;
    }
    .underCalendar {
      height: 520px;
      border-radius: 0;
    }
  }
`;
