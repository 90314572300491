import React from 'react';

const ListDropdown = ({ rotate = 0, size = 20 }) => {
  return (

    <svg 

    width="16" 
    height="9" 
    viewBox="0 0 16 9" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >

    <path 
    d="M1 1L8 8L15 1" 
    stroke="#24292E" 
    strokeWidth="1.125" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    />

    </svg>
  );
};

export default ListDropdown;
