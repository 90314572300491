import React from 'react';

const PtoIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 6H2.25"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1254 9.75012H13.1179C14.9779 9.74262 16.4929 11.2576 16.4929 13.1176C16.4929 14.9776 14.9779 16.4926 13.1179 16.4926C11.2504 16.4926 9.74288 14.9776 9.73538 13.1176C9.72788 11.2501 11.2429 9.73512 13.1029 9.73512C13.1029 9.73437 13.1029 9.73438 13.1029 9.73438"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0029 15.7505H4.49289L4.48539 15.7497C3.24039 15.7422 2.23539 14.7372 2.23539 13.4997V4.49973C2.22789 3.25473 3.24039 2.24223 4.47789 2.24223H13.4779H13.4704C14.7079 2.23473 15.7204 3.24723 15.7204 4.49223V10.997"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0449 11.9883V13.3008L14.0799 13.933"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3057 8.58922H10.2982C10.2757 8.58922 10.2607 8.60422 10.2607 8.61922C10.2607 8.63422 10.2757 8.64922 10.2907 8.64922C10.3057 8.64172 10.3207 8.62672 10.3207 8.61172C10.3207 8.58922 10.2982 8.57422 10.2832 8.57422"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.05574 8.58891C5.03324 8.58141 5.01824 8.60391 5.01824 8.61891C5.01074 8.61891 5.01074 8.61891 5.01824 8.61891L5.01074 8.61141C5.01074 8.62641 5.02574 8.64141 5.04074 8.64141C5.05574 8.63391 5.07074 8.61891 5.07074 8.60391C5.06324 8.58141 5.04824 8.56641 5.03324 8.56641C5.02574 8.56641 5.02574 8.56641 5.02574 8.56641"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.045 8.58891C8.0225 8.58141 8.0075 8.60391 8.0075 8.61891C8 8.61891 8 8.61891 8.0075 8.61891L8 8.61141C8 8.62641 8.015 8.64141 8.03 8.64141C8.045 8.63391 8.06 8.61891 8.06 8.60391C8.0525 8.58141 8.0375 8.56641 8.0225 8.56641C8.015 8.56641 8.015 8.56641 8.015 8.56641"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.045 11.0225C8.0225 11.015 8.0075 11.0375 8.0075 11.0525C8 11.0525 8 11.0525 8.0075 11.0525L8 11.045C8 11.06 8.015 11.075 8.03 11.075C8.045 11.0675 8.06 11.0525 8.06 11.0375C8.0525 11.015 8.0375 11 8.0225 11C8.015 11 8.015 11 8.015 11"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.045 11.0225C5.0225 11.015 5.0075 11.0375 5.0075 11.0525C5 11.0525 5 11.0525 5.0075 11.0525L5 11.045C5 11.06 5.015 11.075 5.03 11.075C5.045 11.0675 5.06 11.0525 5.06 11.0375C5.0525 11.015 5.0375 11 5.0225 11C5.015 11 5.015 11 5.015 11"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.05552 13.0889C5.03302 13.0814 5.01802 13.1039 5.01802 13.1189C5.01052 13.1189 5.01052 13.1189 5.01727 13.1189L5.00977 13.1114C5.00977 13.1264 5.02477 13.1414 5.03977 13.1414C5.05477 13.1339 5.06977 13.1189 5.06977 13.1039C5.06227 13.0814 5.04727 13.0664 5.03227 13.0664C5.02477 13.0664 5.02477 13.0664 5.02477 13.0664"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PtoIcon;
