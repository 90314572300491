import styled from 'styled-components';

export const BreadcrumbsContainer2 = styled.div`
  height: 40px;
  background-color: #f0f4f8;
  line-height: 24px;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  span {
    font-weight: 500;
    margin-right: 9px;
    color: #172c45;
    cursor: default;
    :nth-child(2) {
      color: #18d399;
    }
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    svg {
      width: 24px;
    }
    :focus {
      border: none;
      outline-style: none;
    }
  }
`;

export const BreadcrumbsContainer = styled.div`
  padding-top: 20px;

  .slashSeparator{
    padding: 0 7px 0 0;
  }

  .title-style-bread{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .bread-nav {
    cursor: pointer;
  }
`;
