import React from 'react';

const Trash = ({ color = '#24292E' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6551 15.7508H6.33761C5.45261 15.7433 4.71761 15.0683 4.65011 14.1908L3.92261 4.77832H14.0476L13.3201 14.1833V14.1758C13.2451 15.0533 12.5176 15.7283 11.6326 15.7283L11.6551 15.7508Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4.78516H3"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88512 2.25035H11.1039H11.0964C11.5614 2.24285 11.9364 2.62535 11.9364 3.09035V4.77785H6.02637V3.09035V3.08285C6.02637 2.61035 6.40137 2.23535 6.86637 2.23535L6.88512 2.25035Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4775 8.16016V12.3752"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.52246 8.16016V12.3752"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trash;
