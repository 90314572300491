import React, {useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import { Checkbox } from '@material-ui/core';


export const Banking = ({
  idEmployee,
  bankingFiltered,
  clickParent,
  setInfo,
  info,
  edit,
  setEdit,
  updateField,
  formBanking,
  toolTip,
  handleChangeBanking,
  QuestionMark,
  QuestionMarkHover,
  setIsChecked,
  isChecked,
  employee,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading
}) => {
  const [iconQuestionMark, setIconQuestionMark] = useState(true);
  return (
    <div className="banking" onClick={clickParent}>
      <div className="heading-info">
        <h4 className="heading-h4">Banking:</h4>
      </div>
      <div className="section-info">
        {bankingFiltered?.map((item, i) => {
          return (
            <div className="generalDiv" key={i}>
              <div className="question text-bold">
                <p>{item.title}</p>
              </div>
              <div className="answer text-normal">
                {item.title === edit ? (
                  <div className="edit">
                    <Form.Control
                      value={
                        idEmployee
                          ? info !== item.placeholder
                            ? info
                            : item.value(employee[item.table])
                          : formBanking[item.input]
                      }
                      onChange={e =>
                        idEmployee
                          ? setInfo(e.target.value)
                          : handleChangeBanking(e.target.value, item.input)
                      }
                      type={item.type}
                      autoFocus
                    />
                    {idEmployee && (
                      <div className="asideQuestionClass">
                        <div>
                          <Button
                            className="btn-success"
                            onClick={e => updateField(item, e.target.value)}
                            disabled={
                              info === item.placeholder ||
                              getEmployeeLoading ||
                              updateProfileLoading
                            }
                          >
                            Save
                          </Button>
                        </div>
                        <div>
                          {item.title.includes('SWIFT Code') && (
                            <div className="questionInside">
                              <QuestionMark />
                              <div
                                className="textQuestion"
                                style={{ width: '222px', height: '46px' }}
                              >
                                {toolTip('Code for banks ouside of the US.')}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : item.edit === 'checkbox' ? (
                  <div style={{ paddingBottom: '9px' }}>
                    <Checkbox
                      color="primary"
                      onClick={() => setIsChecked(!isChecked)}
                      checked={isChecked}
                    />
                  </div>
                ) : (
                  <div className="normal">
                    <div
                      onClick={() => {
                        setEdit(item.title);
                        setInfo(item.placeholder);
                      }}
                    >
                      <span>
                        {(employee && employee?.[item.table]
                          ? employee?.[item.table][item.input]
                          : employee?.[item.input]) ||
                          formBanking[item.input] || (
                            <span className="placeHolder">
                              {item.placeholder}
                            </span>
                          )}
                      </span>
                    </div>
                    <div>
                      {item.title.includes('SWIFT Code') && (
                        <div className="questionInside"
                        onMouseOver={() => setIconQuestionMark(false)}
                        onMouseOut={() => setIconQuestionMark(true)}
                        >
                         { iconQuestionMark ? <QuestionMark /> : <QuestionMarkHover />}
                          <div
                            className="textQuestion"
                            style={{ width: '222px', height: '46px' }}
                          >
                            {toolTip('Code for banks ouside of the US.')}
                          </div>
                        </div>
                      )}
                    </div>
                    {itemLoading &&
                      item.title.includes(itemLoading?.title) &&
                      (getEmployeeLoading || updateProfileLoading) && (
                        <div
                          className="questionInside"
                          style={{
                            textAlign: 'center',
                            marginLeft: '10px'
                          }}
                        >
                          <CircularProgress className="circular-progress-employee" />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
