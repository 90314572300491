import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import { Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

import { ContentHome } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import { IconEvents } from '../../../components/Icons/IconEvents';
import CheckMark from '../../../components/Icons/CheckMark';
import { useGetPtoPendingQuery } from '../../../graphql/container/query/company';
import { useGetPtoRequestQuery } from '../../../graphql/container/query/company';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import {
  DATE_FORMAT,
  COPY_BY_MAIL_TO_THE_POSITION,
  PermissionsEmployee
} from '../../../services/Constants/getDefaults';
import { ACCEPT_OR_DECLINE_PTO_MUTATION } from '../../../graphql/queries/timeOffAccruals';
import {
  useGetEmployeesByCompanyQuery,
  useGetRecentEmployeesQuery
} from '../../../graphql/container/query/employees';
import { useTeamsByCompanyQuery } from '../../../graphql/container/query/teams';
import { useNavigate } from 'react-router-dom';
import { routesAdmin, routesOwner } from '../../../services/Constants/paths';
import Cross from '../../../components/Icons/Cross';
import Check from '../../../components/Icons/Check';
import TabsPto from '../../../components/TabsPto';
import {
  transformUTCDate,
  transformUTCDateNoTime,
  simpleFormatDate,
  verifyHolidays,
  formatStringDate
} from '../../../services/Utils/getUtils';
import Moment from 'react-moment';
import { Col } from '../../../components/Grid/Grid';
import Wrong from '../../../components/Icons/Wrong';
import {
  EXCEEDED_AMOUNT_OF_DAYS,
  YOU_CAN_NOT_REQUEST_WEEKENDS,
  ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY,
  YOU_CAN_NOT_REQUEST_SAME_DAY,
  YOU_CAN_NOT_REQUEST_BOOKED_DAYS,
  YOU_CAN_NOT_UPDATE_BOOKED_DECLINED,
  CHOOSE_START_GREATER_END_DATE,
  YOU_UPDATED_THE_REGISTER
} from '../../../services/Constants/getLabels';
import { ModalAlert } from '../../../components/Modals';
import DatePickerModule from '../../../components/DatePickerModal';
import {
  EDIT_PTO_MUTATION,
  DELETE_PTO_MUTATION
} from '../../../graphql//mutations/abscenceRequest';
import { useGetPtoRulesByCompanyQuery } from '../../../graphql/container/query/timeOffRules';
import { useTeamsByCompanyQueryEmployeeNotTeamsForRole } from '../../../graphql/container/query/teams';
import DropdownPto from '../../../components/DropdownPto';
import Trash from '../../../components/Icons/Trash';

const Home = () => {
  const location = useLocation();
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const navigate = useNavigate();
  const companyId = currentUser?.companyRole[0].to;
  const [listPending, setListPending] = useState([]);
  const [listPast, setListPast] = useState([]);
  const [newEmployees, setNewEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tabActive, setTabActive] = useState(0);
  const currentDate = moment();
  const { roleUser } = useContext(AppContext);
  const [statusPto, setStatusPto] = useState();
  const [ptoselect, setptoselect] = useState({});
  const [deleteAlert, setdeleteAlert] = useState(false);
  const [editInitialsStartDate, seteditInitialsStartDate] = useState(
    new Date()
  );
  const [editInitialsEndDate, seteditInitialsEndDate] = useState(new Date());
  const [editStartDate, seteditStartDate] = useState(new Date());
  const [editEndDate, seteditEndDate] = useState(new Date());
  const [note, setnote] = useState('');
  const [editModal, seteditModal] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(false);
  const [editPtoMutation] = useMutation(EDIT_PTO_MUTATION);
  const [endDate, setEndDate] = useState();
  const {
    data: { getPtoRulesByCompany }
  } = useGetPtoRulesByCompanyQuery(companyId);
  const currentYear = currentDate.year();
  const [deletePtoMutation, { loading: deletePtoLoading }] = useMutation(
    DELETE_PTO_MUTATION
  );

  const {
    data: { getPTOPendingByCompany },
    loading: loadingPtoPending,
    refetch: refetchPtoPendind
  } = useGetPtoPendingQuery(companyId);

  const {
    data: { getPTORequestByCompany },
    refetch: refetchPtoRequest
  } = useGetPtoRequestQuery(companyId);

  const {
    data: { getAllEmailsOwnerAndAdminByCompany }
  } = useTeamsByCompanyQueryEmployeeNotTeamsForRole(companyId);

  const {
    data: { getRecentEmployeesByCompany }
  } = useGetRecentEmployeesQuery(companyId);

  const {
    data: { getAllTeamsByCompanyIdInfoTable }
  } = useTeamsByCompanyQuery(companyId);

  const {
    data: { getEmployeesByCompany }
  } = useGetEmployeesByCompanyQuery(companyId);

  const [
    acceptOrDeclinePto,
    { loading: acceptOrDeclinePtoLoading }
  ] = useMutation(ACCEPT_OR_DECLINE_PTO_MUTATION);

  const onSubmitPtoPending = (item, status) => {
    let emails = '';

    const employeeHr = getAllEmailsOwnerAndAdminByCompany?.filter(
      item =>
        item.role.toLowerCase() === COPY_BY_MAIL_TO_THE_POSITION.toLowerCase()
    );

    employeeHr.forEach(item => {
      emails = emails + item?.emailEmployee + ';';
    });

    acceptOrDeclinePto({
      variables: {
        status,
        id: item.id,
        emails: emails.slice(0, emails.length - 1)
      }
    });
  };

  useEffect(() => {
    if (getEmployeesByCompany) {
      setEmployees(getEmployeesByCompany?.employees);
    }
  }, [getEmployeesByCompany]);

  useEffect(() => {
    if (getAllTeamsByCompanyIdInfoTable)
      setTeams(getAllTeamsByCompanyIdInfoTable);
  }, [getAllTeamsByCompanyIdInfoTable]);

  useEffect(() => {
    if (isMounted.current) {
      if (!acceptOrDeclinePtoLoading) {
        refetchPtoPendind({ companyId });
        refetchPtoRequest({ companyId });
      }
    }
    // eslint-disable-next-line
  }, [acceptOrDeclinePtoLoading]);

  useEffect(() => {
    if (getRecentEmployeesByCompany)
      setNewEmployees(getRecentEmployeesByCompany);
  }, [getRecentEmployeesByCompany]);

  useEffect(() => {
    if (getPTOPendingByCompany) {
      setListPending(getPTOPendingByCompany);
      setListPast(getPTORequestByCompany);
      isMounted.current = true;
    }
  }, [getPTOPendingByCompany, getPTORequestByCompany]);

  const extractManager = el => {
    const employeeId = el.employee;
    const managers = employees
      ?.filter(employee => employeeId === employee.id)
      ?.map(el =>
        el.employeeTeam?.map(item =>
          teams?.filter(team => team?.id === item?.teamId)
        )
      )
      ?.flat(2)
      ?.map(el => el?.manager);

    const dataArr = new Set(managers);
    let result = [...dataArr];
    return result.toString();
  };

  const extractTeams = el => {
    const employeeName = el.employeeInfo.fullName;
    const employeeId = el.employee;
    const employeeTeams = employees
      ?.filter(employee => employeeId === employee.id)
      ?.map(el =>
        el.employeeTeam?.map(item =>
          teams?.filter(team => team?.id === item?.teamId)
        )
      )
      ?.flat(2);
    return employeeTeams
      .filter(({ manager }) => manager !== employeeName)
      .slice(0, 6);
  };

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }
  const handleNavigate = item => {
    window.history.pushState(null, null, location.pathname);
    navigate(routesOwner.editEmployee(item.id), {
      state: { site: 'profile', user: item },
      replace: true
    });
  };

  const permissionsEdit =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN ||
    roleUser === PermissionsEmployee.ADMIN_EMPLOYEE;

  const editPTOvalue = item => {
    seteditInitialsStartDate(new Date(transformUTCDateNoTime(item.startDate)));
    seteditInitialsEndDate(new Date(transformUTCDateNoTime(item.endDate)));
    seteditStartDate(new Date(transformUTCDateNoTime(item.startDate)));
    seteditEndDate(new Date(transformUTCDateNoTime(item.endDate)));
    setnote(item.message);
    setptoselect(item);
    if (validateEditPTO(item)) {
      seteditModal(true);
    }
  };

  const validateEditPTO = item => {
    if (item.status === 'DECLINED') {
      setMessage(`${YOU_CAN_NOT_UPDATE_BOOKED_DECLINED}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  const resetFunction = () => {
    endDate && setEndDate();
  };

  const validateCalendarRulesPtoEdit = employeeId => {
    const initialStartRequest = simpleFormatDate(editInitialsStartDate);
    const initialEndRequest = simpleFormatDate(editInitialsEndDate);
    const startRequest = simpleFormatDate(editStartDate);
    const endRequest = simpleFormatDate(editEndDate);
    const starts = listPending
      .filter(pto => pto.employee === employeeId)
      .map(el => el.startDate.slice(0, 10));
    const ends = listPending
      .filter(pto => pto.employee === employeeId)
      .map(el => el.endDate.slice(0, 10));
    const startsApproved = listPast
      .filter(pto => pto.employee === employeeId && pto.status === 'APPROVED')
      .map(el => el.startDate.slice(0, 10))
      .filter(item => item !== initialStartRequest);
    const endsApproved = listPast
      .filter(pto => pto.employee === employeeId && pto.status === 'APPROVED')
      .map(el => el.endDate.slice(0, 10))
      .filter(item => item !== initialEndRequest);

    if (
      initialStartRequest === startRequest &&
      initialEndRequest === endRequest
    ) {
      return false;
    }

    if (editStartDate > editEndDate) {
      setMessage(`${CHOOSE_START_GREATER_END_DATE}`);
      setAlert(true);
      return false;
    }
    if (
      editStartDate?.toString().includes('Sat') ||
      editStartDate?.toString().includes('Sun') ||
      editEndDate?.toString().includes('Sat') ||
      editEndDate?.toString().includes('Sun')
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_WEEKENDS}`);
      setAlert(true);
      return false;
    }
    if (
      verifyHolidays(editStartDate, getPtoRulesByCompany.holidays) ||
      verifyHolidays(editEndDate, getPtoRulesByCompany.holidays)
    ) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY}`);
      setAlert(true);
      return false;
    }
    if (
      starts.includes(startRequest || endRequest) ||
      ends.includes(startRequest || endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_SAME_DAY}`);
      setAlert(true);
      return false;
    }
    if (
      startsApproved.includes(startRequest || endRequest) ||
      endsApproved.includes(startRequest || endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_BOOKED_DAYS}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  const editPto = async (ptoselect, employeeId) => {
    if (validateCalendarRulesPtoEdit(employeeId)) {
      try {
        const updatePto = await editPtoMutation({
          variables: {
            startDate: editStartDate,
            endDate: editEndDate,
            message: note ? note : '',
            ptoId: ptoselect.id,
            amountDays: ptoselect.daysOff.toString(),
            type: ptoselect.type,
            employeeId: employeeId
          }
        });
        if (updatePto.data.EditPto) {
          setMessage(`${YOU_UPDATED_THE_REGISTER}`);
          setAlert(true);
        } else {
          setMessage(`${EXCEEDED_AMOUNT_OF_DAYS}`);
          setAlert(true);
        }
        seteditModal(false);
        refetchPtoPendind({ companyId });
        refetchPtoRequest({ companyId });
      } catch (error) {
        console.log(error);
      }
    }
    seteditModal(false);
  };

  const deletePto = async (ptoselect, employeeId) => {
    const startDate = moment(ptoselect.startDate)
      .utc()
      .year();
    const endDate = moment(ptoselect.endDate)
      .utc()
      .year();

    const ptoCurrentYear =
      currentYear === startDate && currentYear === endDate ? true : false;
    const ptoIdAbscenceRequest = ptoselect.id;

    const amountDaysOff = ptoselect?.daysOff
      ? ptoselect?.daysOff?.toString()
      : '0';

    try {
      await deletePtoMutation({
        variables: {
          PtoId: ptoIdAbscenceRequest,
          AmountDays: amountDaysOff,
          Type: ptoselect.type,
          EmployeeId: employeeId,
          Status: statusPto,
          PtoCurrentYear: ptoCurrentYear
        }
      });
      setdeleteAlert(false);
      refetchPtoPendind({ companyId });
      refetchPtoRequest({ companyId });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentHome>
      {console.log(
        'TO MAKE SURE DEV AND PROD ARE RUNNING AT THE SAME VERSION: TOB 42'
      )}

      <div className="section-header-activity"></div>

      {newEmployees.length ? (
        <>
          <div className="section-new-Employee">
            <div className="activities-header">
              <h3 className="sub-heading">New Employees</h3>
            </div>
            <div className="content-tabs">
              <div className="new-Employee">
                {newEmployees.length ? (
                  <>
                    {newEmployees.map((item, i) => {
                      return (
                        <div className="pto-item" key={`new-employee-${i}`}>
                          <div className="pto-icon">
                            <IconEvents icon="new-profile" />
                          </div>
                          <div className="card-icon-request-newEmployee">
                            <span>
                              {item.user?.photo ? (
                                <img
                                  src={item.user?.photo}
                                  alt="Profile"
                                  onErrorCapture={e =>
                                    (e.target.onErrorCapture = null)
                                  }
                                  referrerPolicy="no-referrer"
                                />
                              ) : (
                                <AccountCircle
                                  style={{ fontSize: 43, margin: '-3px' }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="card-info">
                            <div>
                              <button
                                className="noButtonStyle"
                                onClick={() => handleNavigate(item)}
                              >
                                <p className="text-bold">{item.fullName}</p>
                              </button>
                            </div>
                            <div>
                              <p className="text-normal">
                                {moment(item.startDate)
                                  .utc()
                                  .format(DATE_FORMAT)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="section-activity">
        <div className="activities-header">
          <h3 className="sub-heading">PTO</h3>
        </div>
        <div className="section-pending-activities">
          <TabsPto
            tabActive={tabActive}
            setTabActive={value => {
              setTabActive(value);
            }}
          />
          <div className="content-tabs">
            {tabActive === 0 ? (
              <>
                {loadingPtoPending || acceptOrDeclinePtoLoading ? (
                  <div className="loading">
                    <CircularProgress className="circular-progress" />
                  </div>
                ) : listPending.length ? (
                  <>
                    {listPending.map((item, i) => {
                      return (
                        <DropdownPto
                          key={`ptohome-${i}`}
                          id={`ptohome-${i}`}
                          item={item}
                          teams={extractTeams(item)}
                        >
                          <div className="pto-item">
                            <div className="d-flex">
                              <div className="pto-icon-request">
                                <IconEvents
                                  icon={
                                    item.type === 'SICK'
                                      ? item.type.toLowerCase()
                                      : 'pto'
                                  }
                                />
                              </div>
                              <div className="card-icon-request">
                                <span>
                                  {item.employeeInfo?.user?.photo ? (
                                    <img
                                      src={item.employeeInfo?.user?.photo}
                                      alt="Profile"
                                      onErrorCapture={e =>
                                        (e.target.onErrorCapture = null)
                                      }
                                      referrerPolicy="no-referrer"
                                    />
                                  ) : (
                                    <AccountCircle
                                      style={{ fontSize: 43, margin: '-3px' }}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="pto-date-status">
                                <div>
                                  <button
                                    className="noButtonStyle"
                                    onClick={() => {
                                      handleNavigate(item.employeeInfo);
                                    }}
                                  >
                                    <span className="pto-name-employee">
                                      {item?.employeeInfo?.fullName}
                                    </span>
                                  </button>
                                </div>
                                <div className="pto-dates">
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(item.startDate)}
                                  </Moment>
                                  {' - '}
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(item.endDate)}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="card-buttons">
                                <Button
                                  className="btn-danger-declined"
                                  onClick={() =>
                                    onSubmitPtoPending(item, 'DECLINED')
                                  }
                                >
                                  <span className="text-bold">Decline</span>
                                </Button>
                                <Button
                                  className="btn-success-accept"
                                  onClick={() =>
                                    onSubmitPtoPending(item, 'APPROVED')
                                  }
                                >
                                  <span className="text-bold">Accept</span>
                                </Button>
                              </div>
                              <div className="card-buttons-movil">
                                <Button
                                  className="btn-danger-movil"
                                  onClick={() =>
                                    onSubmitPtoPending(item, 'DECLINED')
                                  }
                                >
                                  <Cross />
                                </Button>
                                <Button
                                  className="btn-success-movil"
                                  onClick={() =>
                                    onSubmitPtoPending(item, 'APPROVED')
                                  }
                                >
                                  <Check />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </DropdownPto>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-Data">
                    <CheckMark color="black" />
                    <div>
                      <span className="text-normal">
                        You don't have PTO pending
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {tabActive === 1 ? (
              listPast?.length ? (
                <>
                  <div>
                    {listPast.map((item, i) => {
                      return (
                        <DropdownPto
                          key={`ptohome-active${i}`}
                          id={`ptohome-active${i}`}
                          item={item}
                          teams={extractTeams(item)}
                        >
                          <div className="pto-item">
                            <div className="d-flex">
                              <div className="pto-icon-request">
                                <IconEvents
                                  icon={
                                    item.type === 'SICK'
                                      ? item.type.toLowerCase()
                                      : 'pto'
                                  }
                                />
                              </div>
                              <div className="card-icon-request">
                                <span>
                                  {item.employeeInfo?.user?.photo ? (
                                    <img
                                      src={item.employeeInfo?.user?.photo}
                                      alt="Profile"
                                      onErrorCapture={e =>
                                        (e.target.onErrorCapture = null)
                                      }
                                      referrerPolicy="no-referrer"
                                    />
                                  ) : (
                                    <AccountCircle
                                      style={{ fontSize: 43, margin: '-3px' }}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="pto-date-status">
                                <div>
                                  <button
                                    className="noButtonStyle"
                                    onClick={() => {
                                      handleNavigate(item.employeeInfo);
                                    }}
                                  >
                                    <span className="pto-name-employee">
                                      {item?.employeeInfo?.fullName}
                                    </span>
                                  </button>
                                </div>
                                <div className="pto-dates">
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(
                                      item.dateTakenStart || item.startDate
                                    )}
                                  </Moment>
                                  {' - '}
                                  <Moment format={DATE_FORMAT}>
                                    {transformUTCDate(
                                      item.dateTakenEnd || item.endDate
                                    )}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="card-buttons-edit">
                                <Button
                                  className="btn-danger-declined me-3"
                                  onClick={() => {
                                    setStatusPto(
                                      item.status === 'DECLINED'
                                        ? 'DECLINED'
                                        : 'APPROVED'
                                    );
                                    setptoselect(item);
                                    setdeleteAlert(true);
                                  }}
                                >
                                  <Trash color="#fff" />
                                  <span className="text-bold text-white">
                                    Delete
                                  </span>
                                </Button>
                                <Button
                                  className="btn-success-accept"
                                  onClick={() => editPTOvalue(item)}
                                >
                                  <img
                                    src="/images/editPTO.svg"
                                    alt="editPto"
                                  />
                                  <span className="text-bold">Edit</span>
                                </Button>
                              </div>
                              <div
                                className={
                                  permissionsEdit ? 'ptobtns-edit' : 'ptobtns-'
                                }
                              >
                                <div
                                  className={
                                    item.status === 'DECLINED'
                                      ? 'icon-status-declined'
                                      : 'icon-status-approved'
                                  }
                                >
                                  <Col>
                                    {item.status === 'DECLINED' ? (
                                      <Wrong />
                                    ) : (
                                      <Check />
                                    )}
                                    <span>
                                      {item.status === 'DECLINED'
                                        ? 'declined'
                                        : 'approved'}
                                    </span>
                                  </Col>
                                </div>
                              </div>
                              <div
                                className={
                                  permissionsEdit
                                    ? 'btn-actions'
                                    : 'btn-actions-none'
                                }
                              >
                                <img
                                  src="/images/deletePTO.svg"
                                  alt=""
                                  onClick={() => {
                                    setStatusPto(
                                      item.status === 'DECLINED'
                                        ? 'DECLINED'
                                        : 'APPROVED'
                                    );
                                    setptoselect(item);
                                    setdeleteAlert(true);
                                  }}
                                />
                                <img
                                  src="/images/editPTO.svg"
                                  alt=""
                                  onClick={() => {
                                    editPTOvalue(item);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </DropdownPto>
                      );
                    })}
                  </div>
                  <ModalAlert
                    show={editModal}
                    onClose={() => seteditModal(false)}
                    noIcon
                    onConfirm={() => {
                      editPto(ptoselect, ptoselect.employee);
                    }}
                    disableUpdate={editStartDate && editEndDate ? false : true}
                    buttonCancel="Cancel"
                    confirmLabel="Update PTO"
                    buttonCancelStyle="btn-danger-without-bg"
                    content={
                      <div className="text-left">
                        <div>
                          <span className="sub-heading-editpto">Edit PTO</span>
                        </div>
                        <div className="content-alert-edit">
                          <div className="innerLabel">
                            <label className="label-input-edit">
                              Start Date
                            </label>
                            <DatePickerModule
                              principalClass={'inner-calendar-pto-alert'}
                              weekendDisabled
                              onChange={value => seteditStartDate(value)}
                              onClick={() => resetFunction()}
                              minDate={new Date(new Date().setMonth(0, 1))}
                              selected={editStartDate}
                              maxDate={new Date().setMonth(
                                new Date().getMonth() + 24
                              )}
                              placeholderText="MM/DD/YYYY"
                            />
                          </div>
                          <div className="innerLabel">
                            <label>End Date</label>
                            <DatePickerModule
                              principalClass={'inner-calendar-pto-alert'}
                              weekendDisabled
                              onChange={value => seteditEndDate(value)}
                              onClick={() => resetFunction()}
                              minDate={new Date(editStartDate)}
                              selected={editEndDate}
                              maxDate={new Date().setMonth(
                                new Date().getMonth() + 24
                              )}
                              placeholderText="MM/DD/YYYY"
                            />
                          </div>
                          <div className="innerLabel ">
                            <label className="label-input-edit">Note</label>
                            <div className="note-ptoadmin bottom-ptoadmin">
                              <textarea
                                onChange={({ target }) => setnote(target.value)}
                                placeholder="Text Example"
                                rows="6"
                                cols="43"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <ModalAlert
                    show={deleteAlert}
                    onClose={() => setdeleteAlert(false)}
                    confirmLabel="Delete activity"
                    onConfirm={() => {
                      deletePto(ptoselect, ptoselect.employee);
                    }}
                    buttonCancel="Cancel"
                    buttonCancelStyle="btn-danger-without-bg"
                    content={
                      <div>
                        <div className="content-alert">
                          <p className="label-alert">
                            Are you sure you want to delete this{' '}
                            {ptoselect.type === 'DAY_OFF' ? 'PTO' : 'SICK DAY'}{' '}
                            activity from
                            <b>
                              {' '}
                              {ptoselect.dateTakenStart
                                ? formatStringDate(ptoselect.dateTakenStart)
                                : formatStringDate(ptoselect.startDate)}{' '}
                            </b>{' '}
                            to{' '}
                            <b>
                              {' '}
                              {ptoselect.dateTakenEnd
                                ? formatStringDate(ptoselect.dateTakenEnd)
                                : formatStringDate(ptoselect.endDate)}
                            </b>
                            ? <br></br>
                            <br></br>
                            {(currentYear ===
                              (ptoselect.startDate
                                ? moment(ptoselect.startDate)
                                    .utc()
                                    .year()
                                : moment(ptoselect.dateTakenStart)
                                    .utc()
                                    .year()) &&
                            currentYear ===
                              (ptoselect.endDate
                                ? moment(ptoselect.endDate)
                                    .utc()
                                    .year()
                                : moment(ptoselect.dateTakenEnd)
                                    .utc()
                                    .year())
                            ? true
                            : false)
                              ? `Remember that these days will be returned to the employee.`
                              : `These days won't be returned to the employee.`}
                          </p>
                        </div>
                      </div>
                    }
                    centered
                    disabled={deletePtoLoading}
                    disableUpdate={deletePtoLoading}
                  />
                </>
              ) : (
                <div className="no-Data">
                  <CheckMark color="black" />
                  <div>
                    <span className="text-normal">
                      {' '}
                      No PTO or sick Days request
                    </span>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>

      <ModalAlert
        show={alert}
        onClose={() => setAlert(false)}
        content={message}
        buttonCancel={'Ok, Close'}
        confirmButton={false}
        buttonCancelStyle={'buttonAlertPto'}
        alertClass={'alertIcon'}
        oneButton
      />
    </ContentHome>
  );
};

export default Home;
