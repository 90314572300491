import gql from 'graphql-tag';

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UPDATE_COMPANY_MUTATION(
    $name: String!
    $nameLogo: String
    $logo: Json
    $id: String!
  ) {
    updateCompany(
      companyInput: { name: $name, nameLogo: $nameLogo, logo: $logo }
      where: $id
    ) {
      id
      name
      logo
    }
  }
`;

export const UPDATE_ALL_COUNTRIES_MUTATION = gql`
  mutation UPDATE_ALL_COUNTRIES_MUTATION(
    $allCountries: BoolFieldUpdateOperationsInput
    $id: String!
    $systems: SystemUpdateManyWithoutCompaniesInput
  ) {
    updateOneCompany(
      data: { allCountries: $allCountries, systems: $systems }
      where: { id: $id }
    ) {
      allCountries
      systems {
        id
        name
        logo
        active
      }
    }
  }
`;

export const UPDATE_COMPANY_DETAILS_MUTATION = gql`
  mutation UPDATE_COMPANY_MUTATION(
    $id: String!
    $field: String!
    $value: String!
  ) {
    updateCompany(companyInput: { id: $id, field: $field, value: $value })
  }
`;
