import React from 'react';
import { CheckboxContainer, InputContainer } from './styles';

const Checkbox = ({ id, value = false, onChange, label }) => {
  return (
    <CheckboxContainer>
      <div className="checkbox">
        <input
          id={id}
          type="checkbox"
          onChange={e => onChange(!value, e)}
          checked={value ? true : false}
        />
        <label htmlFor={id}>{label ? <span>{label}</span> : null}</label>
      </div>
    </CheckboxContainer>
  );
};

const InputNumber = ({ id, value = 0, min = 0, max = 1000, onChange }) => {
  return (
    <InputContainer
      id={id}
      type="number"
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  );
};

const Input = ({ id, value = 0, min = 0, max = 1000, onChange }) => {
  return (
    <InputContainer
      id={id}
      type="number"
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  );
};

export { Checkbox, InputNumber };

export default Input;
