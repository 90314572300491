import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';

import ProfilePicture from '@dsalvagni/react-profile-picture';
import '@dsalvagni/react-profile-picture/dist/ProfilePicture.css';

import Warning from '../Icons/Warning';
import Close from '../Icons/Close';
import DatePickerModule from '../DatePickerModal';
import Coin from '../Icons/Coin';

const ModalAlert = props => {
  const {
    show = false,
    icon,
    noIcon,
    ShowBtCancel = true,
    content,
    onClose,
    onConfirm,
    buttonCancel = 'Cancel',
    subtitle,
    buttonCancelStyle = 'btn-danger-without-bg',
    buttonSuccess = 'btn-success',
    disableUpdate,
    disabled = false,
    confirmButton = true,
    confirmLabel = 'Delete role',
    alertClass = 'alert-location',
    oneButton
  } = props;
  return (
    <Modal show={show} className="modal-alert" centered>
      <div className="modal-alert-inside">
        {!noIcon && (
          <div className={alertClass}>{icon ? icon : <Warning />}</div>
        )}
        <>
          <div className="innerText">
            {subtitle}
            {content}
          </div>
        </>
      </div>
      <Modal.Footer>
        {!oneButton && (
          <div className="flexButtons">
            {ShowBtCancel && (
              <div>
                <Button
                  className={buttonCancelStyle}
                  onClick={onClose}
                  disabled={disabled}
                >
                  {buttonCancel}
                </Button>
              </div>
            )}
            {confirmButton && (
              <div>
                <Button
                  className={buttonSuccess}
                  disabled={disableUpdate}
                  onClick={() => onConfirm()}
                >
                  {confirmLabel}
                </Button>
              </div>
            )}
          </div>
        )}
        {oneButton && confirmButton && (
          <div className="oneButton">
            <Button
              className={buttonSuccess}
              disabled={disableUpdate}
              onClick={() => onConfirm()}
            >
              {confirmLabel}
            </Button>
          </div>
        )}
        {oneButton && ShowBtCancel && (
          <div className="oneButton">
            <Button
              className={buttonCancelStyle}
              onClick={onClose}
              disabled={disabled}
            >
              {buttonCancel}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const ModalAddRole = props => {
  const {
    show = false,
    icon,
    noIcon,
    ShowBtCancel = true,
    content,
    onClose,
    onConfirm,
    buttonCancel = 'Cancel',
    subtitle,
    buttonCancelStyle = 'btn-danger-without-bg',
    disableUpdate,
    disabled = false,
    confirmButton = true,
    confirmLabel = 'Delete role',
    alertClass = '',
    closeButton = true
  } = props;
  return (
    <Modal show={show} className="modal-edit-role" centered>
      {closeButton && (
        <div onClick={onClose} className="modalClose">
          <Close />
        </div>
      )}
      <Modal.Body>
        {!noIcon && (
          <div className={alertClass}>{icon ? icon : <Warning />}</div>
        )}
        <>
          <div>
            {subtitle}
            {content}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        {ShowBtCancel && (
          <Button
            className={buttonCancelStyle}
            onClick={onClose}
            disabled={disabled}
          >
            {buttonCancel}
          </Button>
        )}
        {confirmButton && (
          <Button
            className="btn-success"
            disabled={disableUpdate}
            onClick={() => onConfirm()}
          >
            {confirmLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const ModalEditRole = props => {
  const { show = false, onClose, onConfirm, role } = props;
  const [roleToEdit, setRoleToEdit] = useState(role);
  useEffect(() => setRoleToEdit(role), [role]);

  const changeAttribute = (value, attribute) => {
    setRoleToEdit(prev => {
      const newObjt = { ...prev, [attribute]: value };
      return { ...newObjt };
    });
  };

  return (
    <Modal show={show} className="modal-edit-role" centered>
      <Modal.Body>
        <h2 className="heading-h2">Edit Role</h2>
        <div>
          <p className="text-bold">Role Name</p>
          <Form.Control
            value={roleToEdit?.name || ''}
            type="text"
            placeholder={`Role`}
            onChange={e => changeAttribute(e.target.value, 'name')}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-danger-without-bg" onClick={onClose}>
          Cancel
        </Button>
        <Button className="btn-success" onClick={() => onConfirm(roleToEdit)}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalCustomImg = ({ show = false, onClose, onConfirm, image }) => {
  const profileRef = useRef(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(false);
  }, [show]);

  const handleUpload = async () => {
    setDisabled(true);
    const data = profileRef.current;
    const imageAsDataURL = data.getImageAsDataUrl(1);

    onConfirm({
      data,
      file64: imageAsDataURL
    });
  };

  return (
    <Modal show={show} centered className="modal-custom-img">
      <Modal.Body>
        <ProfilePicture
          image={image}
          ref={profileRef}
          useHelper={true}
          debug={false}
          frameFormat={'circle'}
          cropSize={400}
          minImageSize={1}
          onImageLoaded={() => true}
          messages={{
            DEFAULT: 'Drag your photo here or click to upload',
            DRAGOVER: 'Drop your photo',
            INVALID_FILE_TYPE: 'Only images allowed.',
            INVALID_IMAGE_SIZE: 'Your photo maximum be sizer than 3 MB'
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-danger-without-bg"
          onClick={() => {
            onClose();
            // setScale(1);
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-success"
          disabled={disabled}
          onClick={() => handleUpload()}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalEditHoliday = props => {
  const { show = false, onClose, onConfirm, holiday } = props;
  const [holidayToEdit, setHolidayToEdit] = useState(holiday);
  const [disabled, setDisabled] = useState();

  useEffect(
    () =>
      setDisabled(
        holidayToEdit === holiday ||
          holidayToEdit === null ||
          (holidayToEdit?.name === holiday?.name &&
            new Date(holidayToEdit?.date)?.toString()?.slice(0, 15) ===
              new Date(holiday?.date)?.toString()?.slice(0, 15))
          ? true
          : false
      ),

    [holidayToEdit, holiday]
  );

  useEffect(() => {
    setHolidayToEdit(holiday);
  }, [holiday]);

  const changeAttribute = (value, attribute) => {
    setHolidayToEdit(prev => {
      const newObjt = { ...prev, [attribute]: value };
      return { ...newObjt };
    });
  };
  return (
    <Modal show={show} className="modal-edit-role" centered>
      <Modal.Body>
        <h2 className="heading-h2">Update Holiday</h2>
        <div>
          <p className="text-bold">Description</p>
          <Form.Control
            value={holidayToEdit.name ? holidayToEdit.name : ''}
            type="text"
            placeholder={
              holidayToEdit.name
                ? holidayToEdit.name
                : holiday?.name
                ? holiday?.name
                : 'description'
            }
            onChange={e => changeAttribute(e.target.value, 'name')}
          />
        </div>
        <div>
          <p className="text-bold">Date</p>
          <DatePickerModule
            principalClass={'inner-calendar-modalalert'}
            onChange={value => changeAttribute(value, 'date')}
            selected={
              holidayToEdit.date
                ? new Date(holidayToEdit.date)
                : holiday?.date
                ? new Date(holiday?.date)
                : new Date()
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-danger-without-bg" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="btn-success"
          onClick={() =>
            holidayToEdit !== null ? onConfirm(holidayToEdit) : onClose
          }
          disabled={disabled || !holidayToEdit.name || !holidayToEdit.date}
        >
          Update holiday
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalEditHistorySalary = ({
  show = false,
  onClose,
  onConfirm,
  salary,
  setAmountSalary,
  amountSalary
}) => {
  const [salaryToEdit, setSalaryToEdit] = useState();

  useEffect(() => { setSalaryToEdit(salary); setAmountSalary(salary.amount)  }, [show, salary]);

  const changeAttribute = (value, attribute) => {

    setSalaryToEdit(prev => {
      const newObjt = { ...prev, [attribute]: value };
      return { ...newObjt };
    });
  };

  return (
    <Modal show={show} className="modal-edit-salary-history" centered>
      <Modal.Body>
        <h2 className="sub-heading-editpto">Edit {salaryToEdit?.type}</h2>

        <div>
          <p className="text-bold">Date</p>
          <DatePickerModule
            principalClass={'label-input-salary'}
            onChange={value => changeAttribute(value, 'transactionDate')}
            selected={
              show
                ? salaryToEdit?.transactionDate
                  ? new Date(salaryToEdit?.transactionDate)
                  : salary?.transactionDate
                  ? new Date(salary?.transactionDate)
                  : new Date(salary?.createdAt)
                  ? new Date(salary?.createdAt)
                  : new Date()
                : new Date()
            }
          />
        </div>
        <div>
          <p className="text-bold">Amount</p>
          <div>
            <InputGroup>
              <InputGroup.Text id="basic-addon">
                <Coin color="black" />
              </InputGroup.Text>
              <span class="label-sign-currency">$</span>
              <Form.Control
                value={ amountSalary || '' }
                onChange={({ target }) => {
                  changeAttribute(parseFloat(target.value  - salary.amount), 'amountAdjustment')
                  setAmountSalary(target.value)
                  changeAttribute(target.value, 'amount') }
                 }  
                type="number"
                min={-5000}
                max={5000}
                placeholder="Amount"
                aria-describedby="basic-addon"
              />
            </InputGroup>
          </div>
        </div>
        <div>
          <p className="text-bold">Note</p>
          <div className="note-ptoadmin bottom-salary">
            <textarea
              value={salaryToEdit?.description}
              onChange={({ target }) =>
                changeAttribute(target.value, 'description')
              }
              placeholder="Text Example"
              rows="6"
              cols="43"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-danger-without-bg" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!salaryToEdit?.amount } className="btn-success" onClick={() => onConfirm(salaryToEdit)}>
          Edit Salary
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalAlertNote = props => {
  const {
    show = false,
    content,
    onClose,
    onConfirm,
    buttonSuccess,
    buttonSuccessStyle = 'btn-success',
    buttonCancelStyle = 'btn-danger-without-bg',
    flexButtons = '',
    disableSuccess
  } = props;
  return (
    <Modal show={show} className="modal-alert" centered>
      <>{content}</>

      <Modal.Footer>
        <div className={flexButtons}>
          <Button className={buttonCancelStyle} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={buttonSuccessStyle}
            disabled={disableSuccess}
            onClick={() => onConfirm()}
          >
            {buttonSuccess}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export {
  ModalAlert,
  ModalEditRole,
  ModalCustomImg,
  ModalEditHoliday,
  ModalEditHistorySalary,
  ModalAlertNote,
  ModalAddRole
};
