import React, { useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { useGetEmployeeLazyQuery } from '../../../graphql/container/query/employees';
import { ContentFiles } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import UploadFile from '../../../components/Document/UploadFile';
import Upload from '../../../components/Icons/Upload';
import TableFiles from './TableFiles';
import {
  ADD_DOCUMENT_MUTATION,
  DELETE_DOCUMENTS_MUTATION
} from '../../../graphql/mutations/documents';
import { useGetDocByEmployeeIdQuery } from '../../../graphql/container/query/files';
import ButtonFilter from '../../../components/Button/ButtonFilter';
import Trash from '../../../components/Icons/Trash';
import { routesAdmin } from '../../../services/Constants/paths';
import { AlertMessageContext } from '../../../Context/AlertMessageContext';
import AlertMessage from '../../../components/AlertMessage';
import Breadcrumbs from '../../../components/Breadcrumbs';

const initialsColumns = [
  {
    name: 'File Name',
    key: 'filename',
    active: true
  },
  {
    name: 'Owner',
    key: 'owner',
    active: true
  },
  {
    name: 'Type',
    key: 'type',
    active: true
  },
  {
    name: 'Date',
    key: 'date',
    active: true
  },
  {
    name: '',
    key: 'download',
    active: true
  }
];

const Files = () => {
  const inputRef = useRef(null);
  const { currentUser, isAdmin } = useContext(AppContext);
  const { errorMessage, successMessage } = useContext(AlertMessageContext);
  const [allFiles, setAllFiles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [columnsTable, setColumnsTable] = useState(initialsColumns);
  const params = useParams();
  const idEmployee = params.id;
  const {
    refetch,
    data: { getDocumentsByEmployeeId },
    loading: getDocsByEmployeeLoading
  } = useGetDocByEmployeeIdQuery(params.id || currentUser?.employee?.id);

  const [addDocument, { loading: createDocLoading }] = useMutation(
    ADD_DOCUMENT_MUTATION
  );

  const [deleteDocument, { loading: deleteDocLoading }] = useMutation(
    DELETE_DOCUMENTS_MUTATION
  );
  const [employee, setEmployee] = useState({});
  const [getEmployee, { data: getEmployeeData }] = useGetEmployeeLazyQuery(
    idEmployee
  );

  useEffect(() => {
    if (idEmployee) getEmployee(idEmployee);
    // eslint-disable-next-line
  }, [idEmployee]);

  useEffect(() => {
    if (getEmployeeData) {
      setEmployee(getEmployeeData?.getEmployee);
    }
  }, [getEmployeeData]);
  useEffect(() => {
    if (loadingTable) {
      refetch();
    }
    // eslint-disable-next-line
  }, [createDocLoading, deleteDocLoading]);

  const createDoc = (url, name) => {
    addDocument({
      variables: {
        employeeId: idEmployee || currentUser.employee.id,
        createdBy: currentUser.id,
        url,
        name
      }
    });
  };

  const deleteFile = () => {

   deleteDocument({
      variables: {
        list: selected
      }
    }).then(function(response) {
      if(response.data.deleteDocuments) {
      setLoadingTable(true);
      setSelected([]);
      setDeleteData(true);
      }
    })

 
  };

  useEffect(() => {
    if (getDocumentsByEmployeeId) {
      setAllFiles(getDocumentsByEmployeeId);
      setLoadingTable(false);
    }
  }, [getDocumentsByEmployeeId]);

  return (
    <>
      {isAdmin && (
        <Breadcrumbs
          routes={isAdmin && routesAdmin.employees}
          subtitle={
            isAdmin &&
            (employee?.firstName ? employee?.firstName : '') +
              ' ' +
              (employee?.lastName ? employee?.lastName : '')
          }
          section={isAdmin && 'Employees'}
        />
      )}
      <ContentFiles>
        <div className="header-files">
          <div>
            <h3 className="sub-heading">Files ({allFiles.length})</h3>
          </div>
          <div>
            <Button
              className="btn-danger trash"
              hidden={!selected.length}
              onClick={() => deleteFile()}
            >
              <Trash />
              <span>{'Delete'}</span>
            </Button>
            <UploadFile
              inputRef={inputRef}
              userId={currentUser.employee.id}
              section={'employee'}
              onSuccess={createDoc}
              setLoading={setLoadingTable}
              deleteData={deleteData}
            >
              <Button
                className="btn-success"
                onClick={() => inputRef.current?.click() || null}
                disabled={loadingTable}
              >
                <span>Upload</span>
              </Button>
            </UploadFile>
            <ButtonFilter
              //It's necessary copy deep the data for that filter of the fields works correctly
              data={JSON.parse(JSON.stringify(columnsTable))}
              setData={setColumnsTable}
            />
            {/* <Button className="btn-success" onClick={() => console.log('update')}>
            <GoogleDrive />
            <span>Google Drive</span>
          </Button> */}
          </div>
        </div>
        <div className="info-files">
          <TableFiles
            dataSource={allFiles}
            loading={loadingTable ? loadingTable : getDocsByEmployeeLoading}
            columns={columnsTable}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <AlertMessage
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      </ContentFiles>
    </>
  );
};

export default Files;
