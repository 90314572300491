import React from 'react';

const QuestionMark = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 15.75C5.27175 15.75 2.25 12.7283 2.25 9C2.25 5.27175 5.27175 2.25 9 2.25C12.7283 2.25 15.75 5.27175 15.75 9C15.75 12.7283 12.7283 15.75 9 15.75Z"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.93751V9.75001C9 9.13726 9.37875 8.80501 9.75825 8.55001C10.1287 8.30026 10.5 7.97476 10.5 7.37476C10.5 6.54601 9.82875 5.87476 9 5.87476C8.17125 5.87476 7.5 6.54601 7.5 7.37476"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99802 11.5C8.72203 11.5 8.49803 11.724 8.50003 12C8.50003 12.276 8.72403 12.5 9.00002 12.5C9.27602 12.5 9.50002 12.276 9.50002 12C9.50002 11.724 9.27602 11.5 8.99802 11.5Z"
        fill="#24292E"
      />
    </svg>
  );
};

export default QuestionMark;
