import styled from 'styled-components';

export const AlertFragment = styled.div`
  .alert {
    position: fixed;
    right: 600px;
    border-radius: 5px;
  }

  .alert-danger: {
    background: #cb2431;
    span {
      margin-left: 5px;
      color: #cb2431;
    }
  }
  .alert-success: {
    background: #a1fcb9;
    span {
      margin-left: 5px;
      color: #22863a;
    }
  }
`;
