import { PermissionsEmployee } from './getDefaults';

export const routesPublic = {
  default: '/',
  login: '/login',
  activateEmail: '/activate-email',
  linkError: '/link-error',
  tokenExpired: '/401'
};

export const routesManager = {
  Teams: '/Manager/Teams',
  PtosTeams: '/Manager/Team/',
  listTeams: function() {
    return `/Manager/Teams`;
  },
  ptosTeam: function(id) {
    return `/Manager/Team/${id}`;
  },
  role: [
    PermissionsEmployee.MANAGER,
    PermissionsEmployee.ADMIN,
    PermissionsEmployee.ADMIN_EMPLOYEE,
    PermissionsEmployee.OWNER
  ]
};

export const routesEmployee = {
  pto: '/pto',
  files: '/files',
  profile: '/profile',
  salary: function() {
    return `${this.profile}/salary-history`;
  },
  role: [
    PermissionsEmployee.EMPLOYEE,
    PermissionsEmployee.MANAGER,
    PermissionsEmployee.ADMIN_EMPLOYEE
  ]
};

export const routesAdmin = {
  home: '/home',
  employees: '/employees',
  selectProfile: '/selectProfile',
  assets: '/assets',
  config: '/config',
  externalTools: function() {
    return `${this.config}/external-tools`;
  },
  ptoHolidays: function() {
    return `${this.config}/pto-holiday`;
  },
  employee: '/employee',
  createEmployee: function() {
    return `${this.employee}/create-employee`;
  },
  editEmployee: function(id) {
    return `${this.employee}/${id}/profile`;
  },
  pto: function(id) {
    return `${this.employee}/${id}/pto`;
  },
  salary: function(id) {
    return `${this.employee}/${id}/salary`;
  },
  files: function(id) {
    return `${this.employee}/${id}/files`;
  },
  createEditTeams: function() {
    return `/teams/create-edit-teams`;
  },
  role: [
    PermissionsEmployee.OWNER,
    PermissionsEmployee.ADMIN,
    PermissionsEmployee.ADMIN_EMPLOYEE
  ]
};

export const routesOwner = {
  home: '/home',
  employees: '/employees',
  assets: '/assets',
  config: '/config',
  companyDetail: function() {
    return `${this.config}/company-details`;
  },
  roles: function() {
    return `${this.config}/roles`;
  },
  permissions: function() {
    return `${this.config}/permissions`;
  },
  teams: function() {
    return `${this.config}/teams`;
  },
  createEditTeams: function() {
    return `/teams/create-edit-teams`;
  },
  externalTools: function() {
    return `${this.config}/external-tools`;
  },
  ptoHolidays: function() {
    return `${this.config}/pto-holiday`;
  },
  employee: '/employee',
  createEmployee: function() {
    return `${this.employee}/create-employee`;
  },
  editEmployee: function(id) {
    return `${this.employee}/${id}/profile`;
  },
  pto: function(id) {
    return `${this.employee}/${id}/pto`;
  },
  salary: function(id) {
    return `${this.employee}/${id}/salary`;
  },
  files: function(id) {
    return `${this.employee}/${id}/files`;
  },
  role: PermissionsEmployee.OWNER
};
