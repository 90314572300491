import React, { useState } from 'react';
import moment from 'moment';

import Table from '../../../components/GenericTable';
import Edit from '../../../components/Icons/Edit';
import Schedule from '../../../components/Icons/Schedule';
import { DATE_FORMAT } from '../../../services/Constants/getDefaults';

const NoData = () => {
  return (
    <div className="noData">
      <Schedule color="black" size="48" />
      <div>
        <span className="text-normal">No Company Holidays</span>
      </div>
    </div>
  );
};

const TableHolidays = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected,
  onClickEdit
}) => {
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'description':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <>
                  <span style={{ paddingLeft: '5px' }}>{item.name}</span>
                </>
              )
            });
          break;
        case 'date':
          if (col.active)
            data[i].dataRow.push({
              key: 'date',
              value: String(moment(item.date).format(DATE_FORMAT))
            });
          break;
        case 'action':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onClickEdit(item)}
                >
                  <Edit />
                </div>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
      checkbox
    />
  );
};

export default TableHolidays;
