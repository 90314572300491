import gql from 'graphql-tag';

export const QUERY_SEPARATION = gql`
  query QUERY_SEPARATION($id: String) {
    separation(where: { id: $id }) {
      id
      separationDate
      note
    }
  }
`;
export const GET_EMPLOYEE_SEPARATE = gql`
  query GET_EMPLOYEE_SEPARATE($employeeId: String!) {
    getEmployeeSeparate(employeeId: $employeeId) {
      id
      availableForRehire
      employee
      note
      separationDate
      status
      createdAt
    }
  }
`;
