import React from 'react';

const Download = ({ width = '18', height = '18', color = '#24292E' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6625 8.33245L8.99251 10.995L6.32251 8.32495"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3V11.0025"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12.333C15 13.803 13.8 14.9955 12.33 14.9955H5.66254L5.65504 14.9947C4.17754 14.9872 2.98504 13.7947 2.98504 12.3247H2.97754"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Download;
