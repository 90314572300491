import styled from 'styled-components';

export const ContentIcons = styled.div`
  .circle-events {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    color: white;

    & div {
      font-size: 8px;
      margin-top: -1px;
    }
  }

  .pto {
    background: #f97583;
  }

  .bday {
    background: #0366d6;
  }

  .year {
    background: #b392f0;
  }

  .add-user {
    background: #18d399;
  }
`;
