import styled from 'styled-components';

export const TableManager = styled.div`
  .sorting_disabled:nth-child(1) {
    padding-left: 16px;
  }

  .sorting_disabled:nth-child(3) {
    div {
      justify-content: flex-end;
      margin-right: 98px;
    }
  }
  .row-data {
    .title-teams {
      span {
        padding-left: 10px;
      }
    }
    td {
      :nth-child(1) {
        padding-left: 16px;
        width: 15% !important;
      }

      :nth-child(2) {
        width: 75% !important;
      }
      :nth-child(3) {
        padding: 7px 6px 9px 83px !important;
        width: 7% !important;
      }

      :nth-child(4) {
        padding: 7px 0px 7px 0px !important;
        width: 4%;
        min-width: 10px;
      }

      .check-team {
        display: flex;
        cursor: pointer;
        padding: 7px 0px 7px 0x;
        justify-content: flex-end;
        div {
          padding-left: 10px;
          border-left: 1px solid #959da5;
        }

        .number-pto {
          background: #d73a49;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          font-style: normal;
          font-weight: 700;
          font-size: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-right: 6px;
          margin-left: 6px;
        }
      }

      .check-team:has(.number-pto) {
        border-left: 1px solid #959da5;
      }

      .edit-team {
        display: flex;
        cursor: pointer;
        padding: 0px 30px 0px 0px;
        div {
          border-left: 1px solid #959da5;
          padding-left: 10px;
        }
      }
    }
  }
`;
