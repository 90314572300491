import styled from 'styled-components';

export const ContentProfile = styled.div`
  margin: 30px 15px 15px 15px;
  border-radius: 10px;
  background: #ffffff;

  .placeHolder{
    color: rgba(106, 115, 125, 1);
  }

  .main-data,
  .personal,
  .contact,
  .banking {
    padding: 20px;
  }

  .validate-email{
    width: 100%;
    font-size: 12px;
    position: absolute;
    color: red;
    margin-left: 10px;
    margin-top: 5px;
  }

  .main-data,
  .personal,
  .contact {
    border-bottom: 1px solid #e1e4e8;
  }

  .main-data {
    .photo,
    .info {
      vertical-align: top;
      display: inline-block;
    }

    .info {
      padding-left: 10px;

      p,
      h2 {
        margin: 0;
      }
    }

    .photo:hover::after {
      content: 'EDIT';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 75px;
      left: 2px;
      width: 100px;
      padding: 6px 35px;
      font-weight: 600;
      font-size: 12px;
      color: white;
    }

    .photo {
      width: 100px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      img {
        border-radius: 50%;
      }
    }
  }

  .personal,
  .contact,
  .banking {
    > div,
    h4 {
      display: inline-block;
    }

    .check-email {
      margin-left: 20px;
      display: inline-block;
    }

    .heading-info {
      vertical-align: top;
      width: 20%;
    }

    .section-info {
      vertical-align: top;
      width: 80%;
      > div {
        width: 100%;
        position: relative;
        p {
          margin-bottom: 12px;
        }
        .question {
          width: 25%;
          text-align: right;
          display: inline-block;
        }
        .answer {
          width: 75%;
          padding-left: 10px;
          display: inline-block;

          .edit {
            cursor: pointer;
            width: 40%;
            display: inline-flex;
            button {
              margin-left: 10px;
              background: #18d399;
              border: none;
              color: black;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          .edit-calendar {
            width: 20%;
            display: inline-block;
          }

          .normal {
            cursor: pointer;
          }

          .select {
            > div:nth-child(1) {
              padding: 2px 32px 5px 10px !important;
            }
          }
        }
      }
    }
  }
`;
