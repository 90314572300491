import React, { useState } from 'react';
import moment from 'moment';

import Table from '../../../components/GenericTable';
import Edit from '../../../components/Icons/Edit';
import {
  DATE_FORMAT,
  optionsTransaction
} from '../../../services/Constants/getDefaults';
import Coin from '../../../components/Icons/Coin';

const NoData = () => {
  return (
    <div className="noData">
      <Coin color="black" size="48" />
      <div>
        <span className="text-normal">No salary data</span>
      </div>
    </div>
  );
};

const TableSalary = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected,
  onClickEdit
}) => {
  const attributes = [
    'id',
    'type',
    'amount',
    'description',
    'transactionDate',
    'employee'
  ];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'date':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: String(
                moment(
                  item.transactionDate ? item.transactionDate : item.createdAt
                ).format(DATE_FORMAT)
              )
            });
          break;
        case 'type_of_transaction':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <>
                  <span style={{ paddingLeft: '5px' }}>
                    {item?.type?.length
                      ? item.type
                      : item.description || optionsTransaction[2].label}
                  </span>
                </>
              )
            });
          break;
        case 'amount':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: '$ ' + item.amount.toLocaleString('en-US')
            });
          break;
        case 'description':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value:  item.description === '' || item.description === null ? 'No additional notes were provided.' : item.description
            });
          break;
        case 'action':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                item.type === "Starting salary" ? null  :
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onClickEdit(item)}
                >
                  <Edit />
                </div>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
    />
  );
};

export default TableSalary;
