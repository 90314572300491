import React from 'react';

const Bell = ({ color = '#24292E' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.06656 13.3333H6.93323"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 6.688V6.66133C11.3333 4.82067 9.83995 3.328 7.99995 3.328C6.15328 3.328 4.66661 4.82067 4.66661 6.66133V6.68133V8.328C4.66661 8.54133 4.53995 8.73467 4.34661 8.83467L4.00661 9.00133C3.58661 9.208 3.31995 9.63467 3.31995 10.1013C3.31995 10.7813 3.87328 11.3347 4.55595 11.3347H11.4159C12.0959 11.3347 12.6493 10.7813 12.6493 10.0947C12.6493 9.62667 12.3826 9.19467 11.9626 8.988L11.6226 8.81467C11.4293 8.71467 11.3026 8.51467 11.3026 8.30133V6.64667L11.3333 6.688Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6339 4.03267C12.1539 3.19933 11.4672 2.506 10.6339 2.03267"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36597 4.03267C3.8393 3.19933 4.53263 2.506 5.36597 2.03267"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bell;
