import React from 'react';

const Home = ({ color = 'white', width = '16', height = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.228 5.53264L9.228 2.4193C8.50133 1.8573 7.49467 1.8573 6.768 2.4193L2.768 5.52597C2.27467 5.8993 1.99467 6.48597 1.99467 7.0993V11.988C1.99467 13.0926 2.89 13.988 3.99467 13.988H11.9947C13.0993 13.988 13.9947 13.0926 13.9947 11.988V7.0993C13.9947 6.4793 13.708 5.8993 13.2213 5.5193L13.228 5.53264Z"
        stroke={color}
        strokeWidth="1.125"
      />
      <path
        d="M6 11.3333H10"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Home;
