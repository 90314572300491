import React, { useEffect, useState } from 'react';

import { Form, Button } from 'react-bootstrap';
import {
  EMPLOYEE_SHIRT_SIZE,
  HAS_VISA
} from '../../../services/Constants/getDefaults';
import { Select, MenuItem, TextField, ListSubheader } from '@material-ui/core';
import DatePickerModule from '../../../components/DatePickerModal';
import { transformUTCDateNoTime } from '../../../services/Utils/getUtils';
export const Personal = ({
  idEmployee,
  personal,
  employee,
  clickParent,
  setInfo,
  info,
  edit,
  setEdit,
  updateField,
  handleChangePersonal,
  formatStringDate,
  formPersonal,
  asterix,
  formatDate,
  cities,
  countries,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading
}) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedNationality, setSelectedNationality] = useState('');
  const [startDate, setStartDate] = useState('');

  useEffect(() => {
    if (employee?.dateOfBirth) {
      setStartDate(new Date(transformUTCDateNoTime(employee?.dateOfBirth)));
    }
  }, [employee]);

  useEffect(() => {
    if (employee?.contactInformation?.country) {
      const selectedCountry = countries?.filter(
        el => el.country === employee?.contactInformation?.country
      );
      if (selectedCountry.length > 0) {
        setSelectedCountry(selectedCountry?.[0]?.label);
      } else {
        const selectedCountryCode = countries?.filter(
          el => el.label === employee?.contactInformation?.country
        );
        setSelectedCountry(selectedCountryCode?.[0]?.label);
      }
    }

    if (employee?.contactInformation?.nationality) {
      const selectedNationality = countries?.filter(
        el => el.country === employee.contactInformation.nationality
      );
      if (selectedNationality.length > 0) {
        setSelectedNationality(selectedNationality?.[0]?.label);
      } else {
        const selectedNationalityCode = countries?.filter(
          el => el.label === employee.contactInformation.nationality
        );
        setSelectedNationality(selectedNationalityCode?.[0]?.label);
      }
    }
  }, [employee, countries]);

  return (
    <div className="personal" onClick={clickParent}>
      <div className="heading-info">
        <h4 className="heading-h4">Personal:</h4>
      </div>

      <div className="section-info">
        {personal?.map((item, i) => (
          <div className="generalDiv" key={i}>
            <div className="question text-bold">
              <div>
                {item.title.includes('Name') ? (
                  <p>
                    {item.title}
                    <span className="asterix">{asterix}</span>
                  </p>
                ) : (
                  item.title
                )}
              </div>
            </div>
            <div className="answer text-normal">
              {item.title === edit ? (
                item.edit === 'date' ? (
                  <div className="edit-calendar">
                    <DatePickerModule
                      principalClass={'inner-Calendar'}
                      selected={startDate}
                      maxDate={new Date().setMonth(new Date().getMonth() + 24)}
                      onChange={value => {
                        if (value) {
                          setStartDate(value);
                          if (!idEmployee) {
                            handleChangePersonal(
                              formatStringDate(value),
                              item.input
                            );
                          } else {
                            setInfo(formatStringDate(value));
                            updateField(item, formatStringDate(value));
                          }
                        } else {
                          setStartDate(new Date());
                        }
                      }}
                      minDate={new Date().setFullYear(
                        new Date().getFullYear() - 80
                      )}
                    />
                  </div>
                ) : item.edit === 'list' ? (
                  <Select
                    id="search-select"
                    labelId="search-select-label"
                    value={idEmployee ? info : formPersonal[item.input]}
                    MenuProps={{ autoFocus: false }}
                    displayEmpty
                    variant="outlined"
                    className="select"
                    onChange={e =>
                      idEmployee
                        ? e.target.value
                          ? updateField(item, e.target.value)
                          : null
                        : handleChangePersonal(e.target.value, item.input)
                    }
                  >
                    {!item.title.includes('T-Shirt size') && (
                      <ListSubheader>
                        <TextField
                          autoFocus
                          fullWidth
                          value={formPersonal[item.input]}
                          onChange={e =>
                            handleChangePersonal(e.target.value, item.input)
                          }
                          onKeyDown={e => {
                            if (e.key !== 'Escape') {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                    )}
                    {item.field.includes('city') ? (
                      cities.map((el, i) => (
                        <MenuItem key={i} value={el.city}>
                          {el.city}
                        </MenuItem>
                      ))
                    ) : item.field.includes('country') ? (
                      countries.map((el, i) => (
                        <MenuItem key={i} value={el.label}>
                          {el.label}
                        </MenuItem>
                      ))
                    ) : item.field.includes('T-Shirt size') ? (
                      EMPLOYEE_SHIRT_SIZE.map((el, i) => (
                        <MenuItem key={i} value={el}>
                          {el}
                        </MenuItem>
                      ))
                    ) : item.field.includes('US Visa(valid)') ? (
                      HAS_VISA.map((el, i) => (
                        <MenuItem key={i} value={el === 'Yes' ? true : false}>
                          {el}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={info}>{info}</MenuItem>
                    )}
                  </Select>
                ) : (
                  <div className="edit">
                    <Form.Control
                      value={
                        idEmployee
                          ? info !== item.placeholder
                            ? info
                            : item.value(
                                item.table === 'employee'
                                  ? employee && employee
                                  : employee && employee[item.table]
                              )
                          : formPersonal[item.input]
                      }
                      onChange={e =>
                        idEmployee
                          ? setInfo(e.target.value)
                          : handleChangePersonal(e.target.value, item.input)
                      }
                      type={item.type}
                      autoFocus
                    />
                    {idEmployee && (
                      <div>
                        <Button
                          className="btn-success"
                          onClick={e => updateField(item, e.target.value)}
                          disabled={
                            (item.input === 'firstName' && info.length < 1) ||
                            (item.input === 'lastName' && info.length < 1) ||
                            info === item.placeholder ||
                            getEmployeeLoading ||
                            updateProfileLoading
                          }
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div className="normal">
                  <p
                    onClick={() => {
                      setEdit(item.title);
                      setInfo(item.placeholder);
                    }}
                  >
                    <span>
                      {(employee && employee?.[item.table]
                        ? item.input === 'country'
                          ? selectedCountry
                          : item.input === 'nationality'
                          ? selectedNationality
                          : employee?.[item.table][item.input]
                        : item.input === 'dateOfBirth' && employee?.[item.input]
                        ? formatDate(employee?.[item.input])
                        : employee?.[item.input]) ||
                        formPersonal[item.input] || (
                          <span className="placeHolder">
                            {item.placeholder}
                          </span>
                        )}
                    </span>
                  </p>
                  {itemLoading &&
                    item.title.includes(itemLoading?.title) &&
                    (getEmployeeLoading || updateProfileLoading) && (
                      <div
                        className="questionInside"
                        style={{
                          textAlign: 'center',
                          marginLeft: '10px'
                        }}
                      >
                        <CircularProgress className="circular-progress-employee" />
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
