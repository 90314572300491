import React from 'react';
import { useLocation } from 'react-router';

import Companies from '../../../components/Icons/Companies';
import Keys from '../../../components/Icons/Keys';
import Roles from '../../../components/Icons/Roles';
import Schedule from '../../../components/Icons/Schedule';
import { routesOwner } from '../../../services/Constants/paths';
import { ContentSideConfig } from './styles';
import { Link } from 'react-router-dom';
// import Tools from '../../../components/Icons/Tools';

const SideConfig = ({ comapnyName }) => {
  const location = useLocation();

  return (
    <ContentSideConfig>
      <div className="name-company">
        <h3 className="sub-heading">{comapnyName}</h3>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.companyDetail()
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Companies />
          <Link to={routesOwner.companyDetail()}>
            <span className="text-bold">Company Details</span>
          </Link>
        </div>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.roles()
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Roles />
          <Link to={routesOwner.roles()}>
            <span className="text-bold">Roles</span>
          </Link>
        </div>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.permissions()
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Keys />
          <Link to={routesOwner.permissions()}>
            <span className="text-bold">Permissions</span>
          </Link>
        </div>
      </div>
      {/* <div
        className="side-link"
        style={{
          borderLeft: location.state.site === 'tools' ? '2px solid black' : ''
        }}
        onClick={() =>
          navigate('/config/external-tools', {
            state: { site: 'tools' },
            replace: true
          })
        }
      >
        <Tools />
        <span className="text-bold">External Tools</span>
      </div> */}
      {/* <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.teams()
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Teams />
          <Link to={routesOwner.teams()}>
            <span className="text-bold">Teams</span>
          </Link>
        </div>
      </div> */}
      <div
        className="side-link"
        style={{
          marginBottom: '30px'
        }}
      >
        <div
          className={
            location.pathname === routesOwner.ptoHolidays()
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Schedule color="black" />
          <Link to={routesOwner.ptoHolidays()}>
            <span className="text-bold">{'PTO and Holiday'}</span>
          </Link>
        </div>
      </div>
    </ContentSideConfig>
  );
};

export default SideConfig;
