import React from 'react';


const Copy = () => {
  return (

    <svg 
    width="18" 
    height="18" viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >


    <path 
    d="M7.5 12.75H13.5C14.7426 12.75 15.75 11.7426 15.75 10.5V4.5C15.75 3.25736 14.7426 2.25 13.5 2.25H7.5C6.25736 2.25 5.25 3.25736 5.25 4.5V10.5C5.25 11.7426 6.25736 12.75 7.5 12.75Z" 
    stroke="#323232" 
    strokeWidth="1.13" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    />

    <path 
    d="M12 15.091C11.7913 15.3003 11.5433 15.4662 11.2703 15.5793C10.9972 15.6924 10.7045 15.7504 10.409 15.75H4.5C3.90326 15.75 3.33097 15.5129 2.90901 15.091C2.48705 14.669 2.25 14.0967 2.25 13.5V7.591C2.24961 7.29547 2.30762 7.00277 2.42072 6.72973C2.53381 6.45669 2.69975 6.2087 2.909 6"
    stroke="#323232" 
    strokeWidth="1.13" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    />

     </svg>

  );
};

export default Copy;