import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import Coin from '../../../components/Icons/Coin';
import User from '../../../components/Icons/User';
import File from '../../../components/Icons/File';
import Schedule from '../../../components/Icons/Schedule';
import { ContentSideDetailEmployee } from './styles';
import { routesOwner } from '../../../services/Constants/paths';
import { useGetNameEmployee } from '../../../graphql/container/query/employees';
import { Link } from 'react-router-dom';

const SideDetailEmployee = id => {
  const location = useLocation();
  const params = useParams();
  const employeeId = params?.id;

  const { data: nameEmployeeData } = useGetNameEmployee(employeeId);

  useEffect(() => {}, [nameEmployeeData]);

  return (
    <ContentSideDetailEmployee>
      <div className="name-employee">
        <h3 className="sub-heading">
          {nameEmployeeData?.getEmployee?.fullName}
        </h3>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.editEmployee(employeeId)
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <User color="black" />
          <Link to={routesOwner.editEmployee(employeeId)}>
            <span className="text-bold">Profile</span>
          </Link>
        </div>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.pto(employeeId)
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Schedule color="black" />
          <Link to={routesOwner.pto(employeeId)}>
            <span className="text-bold">PTO</span>
          </Link>
        </div>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.salary(employeeId)
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <Coin color="black" />
          <Link to={routesOwner.salary(employeeId)}>
            <span className="text-bold">Salary</span>
          </Link>
        </div>
      </div>
      <div className="side-link">
        <div
          className={
            location.pathname === routesOwner.files(employeeId)
              ? 'sideBorder'
              : 'sideNoBorder'
          }
        >
          <File color="black" />
          <Link to={routesOwner.files(employeeId)}>
            <span className="text-bold">Files</span>
          </Link>
        </div>
      </div>
    </ContentSideDetailEmployee>
  );
};

export default SideDetailEmployee;
