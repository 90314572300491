import {useState, useEffect} from "react";
import { isMobile } from "../services/Utils/getUtils";

export const useMobile = () => {
    const [mobile, setMobile] = useState(false);
    const [width, setWidth] = useState(0);
  
    useEffect(() => {
      setMobile(isMobile());
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [width]);
  
    return {
      mobile,
      width
    };
  };

  