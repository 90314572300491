import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  GET_COMPANY,
  GET_ROLE_BY_COMPANY_ID_QUERY
} from '../../../queries/roles/index';

export const useRolesQuery = companyId =>
  useQuery(GET_COMPANY, {
    variables: { id: companyId }
  });

export const useRolesLazyQuery = companyId =>
  useLazyQuery(GET_COMPANY, {
    variables: { id: companyId }
  });

export const useRolesByCompanyQuery = companyId => {
  return useQuery(GET_ROLE_BY_COMPANY_ID_QUERY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
