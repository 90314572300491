import React from 'react';

const Filter = ({ width = 9, height = 16, color = '#24292E' }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4487_55370)">
        <path
          d="M4.49801 13.9497C3.51904 12.9708 2.85236 11.7235 2.58226 10.3656C2.31216 9.00777 2.45079 7.6003 2.9806 6.32122C3.51041 5.04213 4.40762 3.94888 5.55877 3.17971C6.70991 2.41054 8.06329 2 9.44776 2C10.8322 2 12.1856 2.41054 13.3367 3.17971C14.4879 3.94888 15.3851 5.04213 15.9149 6.32122C16.4447 7.6003 16.5833 9.00777 16.3133 10.3656C16.0432 11.7235 15.3765 12.9708 14.3975 13.9497C13.0847 15.2625 11.3043 16 9.44776 16C7.59124 16 5.81077 15.2625 4.49801 13.9497"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9478 6H7.94775"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6978 8.625H7.19775"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4478 11.25H6.44775"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4487_55370">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.447754)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Filter;
