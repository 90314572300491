import gql from 'graphql-tag';

export const EMPLOYEES_QUERY = gql`
  query EMPLOYEES_QUERY($where: EmployeeWhereInput, $limit: Int, $skip: Int!) {
    employees(
      where: $where
      # first: $limit
      last: $skip
      orderBy: { startDate: desc }
    ) {
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      username
      schedule
      employmentType
      status
      employeeRole {
        id
        name
      }
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      salary
      startDate
      dateOfBirth
      systemsEnabled {
        id
        name
        logo
      }
      customInfo {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;

export const EMPLOYEES_QUERY_CUSTOM = gql`
  query EMPLOYEES_QUERY_CUSTOM(
    $where: EmployeeWhereInput
    $limit: Int
    $skip: Int!
  ) {
    employeesCustom(
      where: $where
      limit: $limit
      skip: $skip #orderBy: { startDate: desc }
    ) {
      countEmployees
      employees {
        id
        firstName
        middleName
        lastName
        mothersMaidenName
        username
        schedule
        employmentType
        status
        employeeRole {
          id
          name
        }
        contactInformation {
          id
          personalEmail
          phone
          addressOne
          addressTwo
          state
          postalCode
          idType
          documentId
          country
          city
        }
        salary
        startDate
        dateOfBirth
        systemsEnabled {
          id
          name
          logo
        }
        customInfo {
          id
          name
          value
        }
        paymentInformation {
          id
          bankName
          bankAccount
          routingNumber
          bankCountry
          bankAddressOne
          bankState
          bankPostalCode
          bankCity
          bankSwiftCode
        }
      }
    }
  }
`;

export const ALL_EMPLOYEES_QUERY = gql`
  query ALL_EMPLOYEES_QUERY($companyId: String!, $search: String) {
    getAllEmployeesByCompany(companyId: $companyId, search: $search) {
      id
      firstName
      lastName
      middleName
      mothersMaidenName
      assignamentDate
      status
      user {
        id
        email
        photo
      }
      employeeRoleInfo {
        id
        name
      }
      permissionEmployee {
        id
        description
      }
    }
  }
`;

export const EMPLOYEE_QUERY = gql`
  query EMPLOYEE_QUERY($id: String!) {
    employee(where: { id: $id }) {
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      username
      schedule
      employmentType
      status
      company {
        id
      }
      employeeRole {
        id
        name
      }
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      salary
      startDate
      dateOfBirth
      systemsEnabled {
        id
        name
        logo
      }
      separation {
        id
        availableForRehire
        separationDate
      }
      documentsSigned {
        id
        name
        url
        type
        source
        signedOn
        createdBy {
          name
        }
      }
      customInfo {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;
export const EMPLOYEE_EMAIL_QUERY = gql`
  query EMPLOYEE_QUERY($username: String!) {
    employee(where: { username: $username }) {
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      username
      schedule
      employmentType
      employeeRole {
        id
        name
      }
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      customInfo {
        id
        name
        value
      }
      salary
      startDate
      dateOfBirth
      systemsEnabled {
        id
        name
        logo
      }
      documentsSigned {
        id
        name
        url
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;
export const EMPLOYEE_ACTIVITY_LOG_QUERY = gql`
  query EMPLOYEE_AVTIVITY_LOG_QUERY($id: String!) {
    activityLogs(
      where: { employeeId: { equals: $id }, companyRoleId: null }
      orderBy: { createdAt: desc }
    ) {
      id
      title
      description
      icon
      read
      iconType
      createdAt
      employee {
        firstName
        lastName
      }
      companyRole {
        role
      }
      createdBy {
        id
        name
      }
    }
  }
`;

export const EMPLOYEE_ACTIVITY_LOG_QUERY_BY_EMAIL = gql`
  query EMPLOYEE_ACTIVITY_LOG_QUERY_BY_EMAIL($id: String!) {
    activityLogs(
      where: { companyRole: { id: { equals: $id } } }
      orderBy: { createdAt: desc }
    ) {
      id
      title
      description
      icon
      read
      iconType
      createdAt
      abscenceRequest {
        id
        startDate
        endDate
        createdAt
        status
        type
        message
      }
      employee {
        id
        firstName
        lastName
      }
      createdBy {
        id
        name
      }
    }
  }
`;

export const ACTIVITY_LOGS_BY_COMPANY_QUERY = gql`
  query ACTIVITY_LOGS_BY_COMPANY_QUERY($id: String) {
    activityLogs(
      where: { employee: { company: { id: { equals: $id } } } }
      orderBy: { createdAt: desc }
    ) {
      id
      title
      description
      icon
      read
      iconType
      createdAt
      abscenceRequest {
        id
        startDate
        endDate
        createdAt
        status
        type
        message
      }
      employee {
        id
        firstName
        lastName
        username
      }
      createdBy {
        id
        name
      }
    }
  }
`;

export const EMPLOYEE_SALARY_HISTORY = gql`
  query EMPLOYEE_SALARY_HISTORY($employee: String!) {
    getHistorySalaryByEmployeeId(employee: $employee) {
      id
      description
      amount
      transactionDate
      type
      createdAt
    }
  }
`;

export const EMPLOYEE_ME_SALARY_HISTORY = gql`
  query EMPLOYEE_ME_SALARY_HISTORY($employee: String!) {
    getMeHistorySalaryByEmployeeId(employee: $employee) {
      id
      description
      amount
      transactionDate
      type
      createdAt
    }
  }
`;

export const EMPLOYEE_NOTES_QUERY = gql`
  query EMPLOYEE_NOTES_QUERY($id: String!) {
    employeeNotes(where: { employee: { id: { equals: $id } } }) {
      id
      note
      createdAt
      employee {
        firstName
        lastName
      }
      createdBy {
        id
        name
      }
    }
  }
`;

export const ALL_COUNT_EMPLOYEES_ROLES = gql`
  query ALL_COUNT_EMPLOYEES_ROLES($id: ID!) {
    allCountEmployeesRoles(id: $id) {
      count
    }
  }
`;

export const ALL_COUNT_EMPLOYEES = gql`
  query ALL_COUNT_EMPLOYEES(
    $id: ID!
    $name: String
    $rolId: String
    $status: Boolean
  ) {
    allCountEmployees(id: $id, name: $name, rolId: $rolId, status: $status) {
      count
    }
  }
`;

export const LIST_EMPLOYEE_PAGE = gql`
  query LIST_EMPLOYEE_PAGE(
    $id: ID!
    $name: String
    $rolId: String
    $status: Boolean
    $skip: Int
    $limit: Int
  ) {
    countEmployeesAndRoles(
      id: $id
      name: $name
      rolId: $rolId
      status: $status
      skip: $skip
      limit: $limit
    ) {
      roles {
        id
        name
      }
    }
  }
`;

export const PROFILE_PAGE = gql`
  query PROFILE_PAGE($id: String, $username: String) {
    profileAndAbscence(where: { id: $id, username: $username }) {
      countAbscenceRequests
      abscenceNow {
        id
        dateTakenStart
        dateTakenEnd
        type
      }
      employee {
        id
        firstName
        middleName
        lastName
        mothersMaidenName
        username
        schedule
        dateOfBirth
        salary
        employmentType
        status
        startDate
        paymentInformation {
          id
          bankName
          bankAccount
          routingNumber
          bankCountry
          bankAddressOne
          bankState
          bankPostalCode
          bankCity
          bankSwiftCode
        }
        employeeRole {
          id
          name
        }
        contactInformation {
          id
          personalEmail
          phone
          addressOne
          addressTwo
          state
          postalCode
          idType
          documentId
          country
          city
        }
        systemsEnabled {
          id
          name
          logo
        }
        customInfo {
          id
          name
          value
        }
      }
    }
  }
`;

export const EMPLOYEE_PROFILE_PTO = gql`
  query EMPLOYEE_PROFILE_PTO($id: String, $username: String) {
    meProfilePTO(where: { id: $id, username: $username }) {
      abscences {
        id
        daysOff
        dateTakenEnd
        dateTakenStart
        note
        type
        createdAt
        updatedAt
        abscenceRequest {
          id
          status
          type
          message
          daysOff
          startDate
          endDate
        }
      }
      abscenceRequests {
        id
        status
        type
        message
        daysOff
        createdAt
        updatedAt
        startDate
        endDate
        employee {
          id
        }
        abscence {
          id
          daysOff
          dateTakenEnd
          dateTakenStart
        }
      }
      timeOffAccrual {
        id
        employee {
          id
          middleName
          firstName
        }
        daysOff
        daysUsed
        daysSick
        createdAt
        updatedAt
      }
      # additionalDaysOff {
      #   id
      #   daysOff
      #   note
      #   createdAt
      #   updatedAt
      # }
    }
  }
`;

export const GET_PTO_EMPLOYEE = gql`
  query GET_PTO_EMPLOYEE($username: String!) {
    getPtoEmployee(username: $username) {
      declined {
        startDate
        status
        endDate
        type
        message
        updatedAt
        id
      }
      pending {
        startDate
        status
        endDate
        type
        message
        id
      }
      approved {
        updatedAt
        dateTakenEnd
        dateTakenStart
        daysOff
        note
        type
        id
        abscenceRequest
      }
      timeOffAccrual {
        id
        daysOff
        daysUsed
        daysSick
        createdAt
        updatedAt
        ptoNextYear
        sickNextYear
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query GET_EVENTS($date: String!) {
    getEvents(date: $date) {
      anniversaries {
        id
        employee_id
        middleName
        firstName
        lastName
        startDate
        username
        typeEvent
        photo
      }
      birthdays {
        id
        employee_id
        middleName
        firstName
        lastName
        startDate
        username
        dateOfBirth
        typeEvent
        photo
      }
      pto {
        abscence_id
        middleName
        firstName
        lastName
        dateTakenEnd
        dateTakenStart
        daysOff
        type
        employee_id
        typeEvent
        photo
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_COMPANY_QUERY = gql`
  query GET_EMPLOYEES_BY_COMPANY_QUERY(
    $companyId: String!
    $limit: Float
    $offset: Float
    $search: String
    $orderField: String
    $sort: String
    $offSeparated: Boolean
    $filterOwners: Boolean
  ) {
    getEmployeesByCompany(
      allEmployeesInput: {
        companyId: $companyId
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        sort: $sort
        offSeparated: $offSeparated
        filterOwners: $filterOwners
      }
    ) {
      employees {
        id
        firstName
        lastName
        middleName
        mothersMaidenName
        fullName
        salary
        startDate
        schedule
        permissionId
        dateOfBirth
        employeeTeam {
          teamId
          id
          isManager
          team {
            id
            name
          }
        }
        employeeRoleInfo {
          id
          name
        }
        user {
          id
          photo
          email
        }
        paymentInformation {
          id
          bankName
          bankAccount
          bankSwiftCode
          routingNumber
          intermediaryRouting
        }
        permissionEmployee {
          id
          description
        }
        contactInformation {
          id
          documentId
          addressOne
          addressTwo
          city
          country
          state
          postalCode
          nationality
          personalEmail
          phone
        }
        customInformation {
          id
          value
        }
        paymentInformation {
          id
          bankName
          bankAccount
          bankSwiftCode
          routingNumber
          intermediaryRouting
        }
        startDate
        status
      }
      totalEmployees
    }
  }
`;

export const GET_RECENT_EMPLOYEES_QUERY = gql`
  query GET_RECENT_EMPLOYEES_QUERY($companyId: String!) {
    getRecentEmployeesByCompany(companyId: $companyId) {
      id
      fullName
      startDate
      user {
        id
        photo
        email
      }
    }
  }
`;
export const GET_NAME_EMPLOYEE = gql`
  query GET_EMPLOYEE($employeeId: String!) {
    getEmployee(employeeId: $employeeId) {
      id
      fullName
    }
  }
`;
export const GET_EMPLOYEE = gql`
  query GET_EMPLOYEE($employeeId: String!) {
    getEmployee(employeeId: $employeeId) {
      id
      fullName
      firstName
      lastName
      startDate
      salary
      schedule
      employmentForm
      employeeRole
      dateOfBirth
      status
      employeeTeam {
        id
        isManager
        team {
          id
          name
        }
      }
      user {
        id
        photo
        email
        socialSignIn
      }
      contactInformation {
        id
        documentId
        personalEmail
        phone
        addressOne
        addressTwo
        city
        country
        state
        nationality
        postalCode
      }
      customInformation {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        bankSwiftCode
        routingNumber
        intermediaryRouting
      }
      employeeRoleInfo {
        name
        salaryMid
        salaryLow
        salaryHigh
        status
        id
      }
      informationPending {
        employeeId
        value
        field
        table
        id
        createdAt
      }
      timeOffAccrual {
        employee
        daysOff
      }
    }
  }
`;

export const CREATE_HISTORY_SALARY_BY_EMPLOYEE = gql`
  mutation CREATE_HISTORY_SALARY_BY_EMPLOYEE(
    $transactionDate: DateTime!
    $type: TypeTransacction!
    $employee: String!
    $amount: Float!
    $description: String
    $newSalary: Float
  ) {
    createHistorySalaryByEmployeeId(
      SalaryHistoryInput: {
        transactionDate: $transactionDate
        type: $type
        employee: $employee
        amount: $amount
        description: $description
        newSalary: $newSalary
      }
    )
  }
`;

export const UPDATE_HISTORY_SALARY_MUTATION = gql`
  mutation UPDATE_HISTORY_SALARY_MUTATION(
    $id: String!
    $transactionDate: DateTime!
    $employee: String!
    $amount: Float!
    $type: String!
    $description: String
    $newSalary: Float
  ) {
    updateHistorySalaryById(
      id: $id
      UpdateSalaryHistoryInput: {
        transactionDate: $transactionDate
        employee: $employee
        amount: $amount
        type: $type
        description: $description
        newSalary: $newSalary
      }
    )
  }
`;
