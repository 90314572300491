export const companyInfo = [
  {
    title: 'Name',
    edit: 'textbox',
    field: 'name',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Address',
    edit: 'textbox',
    field: 'addressOne',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Address Line 2',
    edit: 'textbox',
    field: 'addressTwo',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'State',
    edit: 'textbox',
    field: 'state',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'City',
    edit: 'textbox',
    field: 'city',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Country',
    edit: 'list',
    field: 'country',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'ZIP',
    edit: 'textbox',
    field: 'postalCode',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Website',
    edit: 'textbox',
    field: 'website',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Email',
    edit: 'textbox',
    field: 'email',
    value(obj) {
      return obj[this.field];
    }
  },
  {
    title: 'Phone',
    edit: 'textbox',
    field: 'phone',
    value(obj) {
      return obj[this.field];
    }
  }
];
