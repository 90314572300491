import styled from 'styled-components';

export const ContentActivateEmail = styled.div`
  background: #ffffff;
  height: 100vh;
  text-align: center;
  padding-top: 10%;

  .btn-success {
    font-size: 14px;
    line-height: 20px;
  }
`;
