import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import SelectCheckbox from '../../Icons/SelectCheckbox';
import { ButtonFilterEmployeesContainer } from './styles';
import BiggerVector from '../../Icons/BiggerVector';
import SliderOn from '../../Icons/SliderOn';
import BiggerVectorRight from '../../Icons/BiggerVectorRight';
import SliderOff from '../../Icons/SliderOff';
import EyeOpen from '../../Icons/EyeOpen';
import Checked from '../../Icons/Checked';
import EyeClose from '../../Icons/EyeClose';
import Filter from '../../Icons/Filter';
import { useEffect } from 'react';
import NoChecked from '../../Icons/NoChecked';

const ButtonFilterEmployees = ({
  icon = true,
  data = [],
  setData,
  classButton,
  activeRoles,
  setActiveRoles,
  initialRoles,
  sliderOn,
  setSliderOn,
  activeMenu = false,
  iconColor
}) => {
  const [columns, setColumns] = useState(data);
  const [filterMenu, setFilterMenu] = useState(false);
  const [fieldsMenu, setFieldsMenu] = useState(false);
  const [rolesMenu, setRolesMenu] = useState(false);
  const activeFields = columns?.filter(el => el.active);

  const onSetColumns = item => {
    setColumns(prev => {
      const colTable = prev;
      colTable.forEach(col => {
        if (activeFields.length > 1) {
          if (col.name === item.name) {
            col.active = !item.active;
          }
        } else {
          if (col.name === item.name) {
            col.active = true;
          }
        }
      });
      return [...colTable];
    });
    setData(columns);
  };

  useEffect(() => {
    setData(columns);
    // eslint-disable-next-line
  }, [columns]);

  useEffect(() => {
    setActiveRoles(activeRoles);
    // eslint-disable-next-line
  }, [activeRoles]);

  window.addEventListener('click', function(event) {
    if (
      typeof event.target.className !== 'object' &&
      event?.target.className !== 'green-button btn btn-primary' &&
      event?.target.className !== 'pad-icon' &&
      event?.target.className !== 'filterContent' &&
      event?.target.className !== 'filterMenuFirst' &&
      event?.target.className !== 'activeFilte' &&
      event?.target.className !== 'slider' &&
      event?.target.className !== 'noButtonStyle' &&
      event?.target.className !== 'filterMenu' &&
      event?.target.className !== 'fieldsRolesButton' &&
      event?.target.className !== 'selectChoice' &&
      event?.target.className !== 'fieldsMenuInner' &&
      event?.target.className !== 'fieldsBac' &&
      event?.target.className !== 'backFields' &&
      event?.target.className !== 'vectorFilter' &&
      event?.target.className !== 'line' &&
      event?.target.className !== 'itemsField' &&
      event?.target.className !== 'rolesMenuInner' &&
      event?.target.className !== 'rolesBack' &&
      event?.target.className !== 'buttonFilter'
    ) {
      if (filterMenu || filterMenu || rolesMenu) {
        setFieldsMenu(false);
        setFilterMenu(false);
        setRolesMenu(false);
      }
    }
  });

  const handleBUttonFilter = () => {
    setFilterMenu(!filterMenu);
    setFieldsMenu(false);
  };

  const handleSelectFields = () => {
    setFieldsMenu(!fieldsMenu);
    setFilterMenu(!filterMenu);
  };

  const handleSelectRoles = () => {
    setRolesMenu(!rolesMenu);
    setFilterMenu(false);
  };

  const handleFieldsBack = () => {
    setFieldsMenu(!fieldsMenu);
    setFilterMenu(!filterMenu);
  };

  const handleRolesBack = () => {
    setRolesMenu(!rolesMenu);
    setFilterMenu(true);
  };

  const handleRoles = (item, value) => {
    setActiveRoles({
      ...activeRoles,
      [item]: value
    });
  };

  return (
    <ButtonFilterEmployeesContainer>
      <div className="filterMenuButton">
        <Dropdown
          onToggle={(isOpen, e) => {
            if (e?.source === 'rootClose') {
              setColumns(JSON.parse(JSON.stringify(data)));
            }
          }}
        >
          <button
            onClick={() => handleBUttonFilter()}
            className={classButton || 'btn-success'}
          >
            {icon ?? <SelectCheckbox />}
            <div className="buttonFilter">
              <Filter color={iconColor} />
              <span className="pad-icon">Filter</span>
            </div>
          </button>
          {filterMenu && (
            <div className="filterContent">
              {activeMenu && (
                <div className="filterMenuFirst">
                  <div className="activeFilter">
                    <span>Active Only</span>
                    <div className="slider">
                      {sliderOn ? (
                        <button
                          className="noButtonStyle"
                          onClick={() => setSliderOn(!sliderOn)}
                        >
                          <SliderOn />
                        </button>
                      ) : (
                        <button
                          className="noButtonStyle"
                          onClick={() => setSliderOn(!sliderOn)}
                        >
                          <SliderOff />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="filterMenu">
                <button
                  className="fieldsRolesButton"
                  onClick={() => handleSelectFields()}
                >
                  <span className="selectChoice">Select Fields</span>
                  <div>
                    <BiggerVectorRight />
                  </div>
                </button>
              </div>

              <div className="filterMenu">
                <button
                  className="fieldsRolesButton"
                  onClick={() => handleSelectRoles()}
                >
                  <span className="selectChoice">By Roles</span>
                  <div>
                    <BiggerVectorRight />
                  </div>
                </button>
              </div>
            </div>
          )}
          {fieldsMenu && (
            <div className="fieldsMenuInner">
              <button className="fieldsBack" onClick={() => handleFieldsBack()}>
                <div className="backFields">
                  <div className="vectorFilter">
                    <BiggerVector />
                  </div>
                  Fields
                </div>
              </button>
              <div className="line" />

              {columns.map((item, i) => {
                return (
                  <div
                    key={`${item.name}-${i}`}
                    onClick={e => {
                      e.stopPropagation();
                      onSetColumns(item);
                    }}
                  >
                    <div className="itemsField">
                      {item.name}
                      <div>{item.active ? <EyeOpen /> : <EyeClose />}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {rolesMenu && (
            <div className="rolesMenuInner">
              <button className="rolesBack" onClick={() => handleRolesBack()}>
                <div className="backFields">
                  <div className="vectorFilter">
                    <BiggerVector />
                  </div>
                  Roles
                </div>
              </button>
              <div className="line" />

              {initialRoles?.map((item, i) => {
                return (
                  <div
                    key={`${item.name}-${i}`}
                    onClick={e => {
                      e.stopPropagation();
                      handleRoles(item.name, !activeRoles[item.name]);
                    }}
                  >
                    <div className="itemsField">
                      {item.name}

                      {activeRoles[item.name] ? (
                        <div>
                          <Checked />
                        </div>
                      ) : (
                        <div>
                          <NoChecked />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Dropdown>
      </div>
    </ButtonFilterEmployeesContainer>
  );
};

export default ButtonFilterEmployees;
