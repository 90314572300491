import React from 'react';

const Cherry = ({ color = 'white' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99849 6.74699C7.86599 6.74699 6.95099 5.83199 6.95099 4.69949"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95026 4.6995C6.95026 3.807 7.35526 3.1635 8.17276 3.1635"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99847 6.74699C10.131 6.74699 11.046 5.83199 11.046 4.69949"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0475 4.6995C11.0475 3.807 10.6275 3.1635 9.81 3.1635"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.81827 3.16424C9.40577 3.16424 9.17327 3.29174 8.99327 3.29174C8.81327 3.29174 8.58077 3.15674 8.16827 3.15674"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.29249V2.54249C9 1.95749 9.465 1.49249 10.05 1.49249"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95625 4.77899C4.65 5.41499 3 7.09649 3 9.08474V9.12974C3 10.1152 3.6525 11.0122 4.5975 11.2042C5.325 11.3467 6 11.0842 6.45 10.5967C6.75 10.2667 7.245 10.2892 7.5525 10.6117C7.9155 10.9942 8.4225 11.2342 8.985 11.2342C9.5475 11.2342 10.0575 10.9942 10.425 10.6042C10.725 10.2742 11.22 10.2517 11.5275 10.5817C11.97 11.0617 12.645 11.3242 13.38 11.1817C14.325 10.9942 14.9775 10.0867 14.9775 9.10424V9.05174C14.9775 7.06424 13.3275 5.38574 11.0227 4.73924"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3913 11.2117L12.8888 14.4765C12.7763 15.204 12.1463 15.744 11.4038 15.744H6.57753C5.83503 15.744 5.20503 15.1965 5.09253 14.469L4.59003 11.2042"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Cherry;
