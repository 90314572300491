import React from 'react';

const Flags = ({ color = 'white' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 9.74998L7.905 10.9725C8.1075 11.58 8.6775 11.9925 9.3225 11.9925H14.241C15.066 11.9925 15.741 11.3175 15.741 10.4925V6.10498C15.741 5.27248 15.066 4.60498 14.241 4.60498H10.542"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 15.75L2.9025 4.22399C2.5725 3.25274 3.3 2.24399 4.32 2.24399H9.0375C9.8625 2.24399 10.5375 2.91524 10.5375 3.74399V8.24399C10.5375 9.06899 9.8625 9.74399 9.0375 9.74399H4.73625"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Flags;
