import React from 'react';

const SwitchRole = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9999_281391)">
        <path
          d="M1.125 8.44141L2.25 7.31641L3.375 8.44141"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.875 9.5625L15.75 10.6875L14.625 9.5625"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5083 5.09085C4.40856 3.81374 5.72461 2.88913 7.2313 2.47518C8.73799 2.06124 10.3417 2.18368 11.7681 2.82157C13.1945 3.45945 14.3549 4.57314 15.0508 5.97212C15.7468 7.3711 15.935 8.96842 15.5833 10.4909"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4887 12.9078C13.5884 14.1849 12.2724 15.1095 10.7657 15.5235C9.25898 15.9374 7.65527 15.815 6.22889 15.1771C4.8025 14.5392 3.64208 13.4255 2.94614 12.0265C2.25019 10.6276 2.06197 9.03024 2.41367 7.50781"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.9998C11.9128 11.7803 11.7775 11.5832 11.604 11.423V11.423C11.3117 11.1513 10.9275 11.0002 10.5285 11H7.47225C7.073 11.0003 6.68858 11.1513 6.396 11.423C6.22293 11.5836 6.08768 11.7806 6 11.9998"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.375C9.93198 9.375 10.6875 8.61948 10.6875 7.6875C10.6875 6.75552 9.93198 6 9 6C8.06802 6 7.3125 6.75552 7.3125 7.6875C7.3125 8.61948 8.06802 9.375 9 9.375Z"
          stroke="#24292E"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9999_281391">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwitchRole;
