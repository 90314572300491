import styled from 'styled-components';

export const ContentProfile = styled.div`
  margin: 15px;
  border-radius: 10px;
  background: #ffffff;

  .generalDiv {
    height: 39px;
    display: flex;
    align-items: flex-start;
  }

  .form-control {
    width: 150px;
    height: 25px;
  }

  .little-title {
    display: flex !important;
    flex-direction: column;
    padding: 20px;
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 0px;
    border-bottom: 1px solid rgb(225, 228, 232);

    h5 {
      color: #172c45;
      line-height: 30px;
      font-weight: 600;
      font-size: 20px;
      padding-left: 0px;
    }

    p {
      color: #6A737D
      line-height: 20px;
      font-size: 14px;
      padding-left: 10px;
    }

    .little-subtitle {
      display: flex !important;
      flex-direction: row;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  .heading-section {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-bottom: 1px solid #E1E4E8;
    border-top: 1px solid #E1E4E8;
  }

  .section-content {
    padding: 10px 20px;

    .info-teams {
      background: #F0F5FA;
      padding: 10px;
      border-radius: 10px;
      max-width: 191px;

      span{
        color: #1B1F23
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .teams-grid {
      gap: 8px;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .teams {
      background: #F0F5FA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 10px;
      border-radius: 10px;
      width: 360px
      .teams-content {
        display: flex;
        align-items: center;
        gap: 10px
        h5{
          font-size: 14px;
          margin: 0;
        }
      }
      button {
        min-width: 96px;
      }
      .button-main{  
        min-width: 81px;
        display: flex;
        align-items: center;
        gap: 12px;
        background: #D1DFF
      }
    }

    .no-teams{
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 20px 10px;
      border: 1px dashed #D1D5DA;
      border-radius: 10px;
    }
    
  }

  .PrivateSwitchBase-root-5 {
    padding-top: 0px;
    padding-bottom: 1px;
  }
  .PrivateSwitchBase-root-1 {
    padding-top: 3px !important;
  }
  .PrivateSwitchBase-root-12 {
    padding-top: 3px;
  }
  .MuiIconButton-root {
    padding: 0px 6px !important;
  }

  .withQuestion {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: -15px;
  }
  .questionInside {
    position: relative;
    display: inline-flex;
    margin-left: 10px !important;
    padding-top: 2px;
  }
  .textQuestion {
    background-color: #24292e;
    color: white;
    border-radius: 5px;
    position: absolute;
    left: 35px;
    top: -12px;
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
    font-family: SF Pro Text;
    padding: 5px 10px;
    text-align: left !important;
    visibility: hidden;
    transition: visibility 0.5s;
  }

  .questionInside:hover .textQuestion {
    visibility: visible;
    z-index: 1;
  }

  .asideQuestionClass {
    display: flex;
  }

  .buttonToolStyle {
    border: none;
    background-color: transparent;
    color: #ffffff;
    weight: 400;
    font-size: 12px;
  }

  .MuiSvgIcon-root {
    color: #172c45;
    border-radius: 4px;
    width: 16px;
    height: 16px;
  }
  .MuiIconButton-label {
    width: 0px;
  }
  .questionButton {
    border: none;
    background-color: transparent;
  }
  .buttonsCreate {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .buttonsEdition {
    border-radius: 10px;
    margin-top: 20px;
    background: white;
  }

  .singleButtons {
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-danger {
    height: 44px;
    width: 88px;
    border: 1px solid #E5E5E5;
    background-color: #FFFFFF !important;
    color: #D73A49;
  }
  .btn-danger:hover {
    color: #D73A49;
    border: 1px solid #E5E5E5;
  }

  .btn:disabled {
    background-color: #F6F8FA !important;
    color: #959DA5 !important;
  }

  .btn-danger-disabled:disabled {
    background: #E78992 !important;
    border: none;
  }
  
  .btn-success {
    height: 44px;
    width: 157px;
  }
  .singleButtonsId {
    width: 170px;
    height: 40px;
  }
  .borderBottom {
    border-bottom: 1px solid #e1e4e8;
  }
  .buttonsInnerEdition {
    padding: 20px;
  }

  .innerTagEdition{
    height: 20px;
    border-radius: 100px;
    color: #24292E;
    font-size: 14px;
    padding: 2px 6px;
    display: inline-flex;
    align-items: center;
  }
  
  .active {
    background: rgba(24, 211, 153, 0.3);
  }

  .separated {
    background: rgba(253, 174, 183, 1);
  }

  .innerTextEdition {
    margin: 20px;
  }
  .innerTitleEdition {
    font-size: 18px;
    line-height: 5px;
    margin-top: 5px;
    font-weight: 600;
  }
  .innerTextSmall {
    font-size: 14px;
    font-weight: 400;
  }
  .main-data,
  .personal,
  .contact,
  .banking {
    padding: 20px;
  }

  .main-data,
  .personal,
  .contact {
    border-bottom: 1px solid #e1e4e8;
  }
  .banking {
    border-bottom: 1px solid #e1e4e8;
  }

  .circular-progress-employee {
    width: 20px !important;
    height: 20px !important;
  }

  .validate-email {
    width: 100%;
    font-size: 12px;
    position: absolute;
    left: 162px;
    color: red;
  }

  .validateEmailChild {
    font-size: 12px;
    white-space: nowrap;
    color: red;
    margin-left: 10px;
  }

  .main-data {
    .photo,
    .info {
      vertical-align: top;
      display: inline-block;
    }

    .info {
      padding-left: 10px;

      p,
      h2 {
        margin: 0;
      }
    }

    .photo {
      width: 120px;
      cursor: pointer;
      img {
        border-radius: 50%;
      }
    }
  }

  .personal,
  .contact,
  .banking {
    > div,
    h4 {
      display: inline-block;
      padding-left: 10px;
    }

    .check-email {
      margin-left: 20px;
      display: inline-block;
    }

    .heading-info {
      vertical-align: top;
      width: 20%;
    }
    .asterix {
      color: red;
    }
    .placeHolder {
      color: rgba(106, 115, 125, 1);
    }
    .realText {
      color: rgba(36, 41, 46, 1);
    }
    .section-info {
      vertical-align: top;
      width: 80%;
      > div {
        width: 100%;
        p {
          margin-bottom: 12px;
        }
        .question {
          width: 25%;
          text-align: right;
          display: inline-block;
        }
        .answer {
          width: 75%;
          padding-left: 10px;
          display: inline-block;

          .edit {
            cursor: pointer;
            width: 40%;
            display: inline-flex;
            position: relative;
            button {
              margin-left: 10px;
              background: #18d399;
              border: none;
              color: black;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          .edit-calendar {
            width: 20%;
          }

          .normal {
            cursor: pointer;
            position: relative;
            display: flex;
          }

          .select {
            > div:nth-child(1) {
              padding: 2px 32px 5px 10px !important;
            }
          }
        }
        .circular-progress-box {
          position: absolute;
        }
      }
    }
  }
`;
