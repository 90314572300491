import React, { useState } from 'react';

import Table from '../../../components/GenericTable';
import Edit from '../../../components/Icons/Edit';
import Roles from '../../../components/Icons/Roles';

const NoData = () => {
  return (
    <div className="noData">
      <Roles size="48" />
      <div>
        <span className="text-normal">You don't have roles</span>
      </div>
    </div>
  );
};

const TableRoles = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected,
  onClickEdit,
  setSortChoiceRole,
  setItemRole,
  sortChoiceRole
}) => {
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'positionName':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: item.name
            });
          break;
        case 'low':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: '$ ' + (Math.trunc(item.low) || 0).toLocaleString('en-US')
            });
          break;
        case 'mid':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <span>
                  {'$ ' + (Math.trunc(item.mid) || 0).toLocaleString('en-US')}
                </span>
              )
            });
          break;
        case 'high':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: '$ ' + (Math.trunc(item.high) || 0).toLocaleString('en-US')
            });
          break;
        case 'action':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onClickEdit(item)}
                >
                  <Edit />
                </div>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
      checkbox
      sort
      setSortChoiceRole={setSortChoiceRole}
      sortChoiceRole={sortChoiceRole}
      setItemRole={setItemRole}
    />
  );
};

export default TableRoles;
