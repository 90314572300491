import styled from 'styled-components';

export const DashboardContainer = styled.div`
  
  width: 100%
  overflow: hidden ; 
  overflow-x: auto ;

  .dashboardContainer{
    font-family: 'system-ui';
    width: 100px;
  }

  .dashboardContainer-loader{
    display: flex;
    flex-direction: column;
    justify-contet: center;
    align-items: center;
  }

  .dashboardTooltip{
    background: #FAFBFC;
    border-radius: 4px;
    border: 1px solid #E1E4E8;
    padding: 10px;
  }

  .dashboardTooltip > p {
    font-size: 12px;
  }

  .dashboardTooltip-title{
    color: #24292E;
    font-weight: 600;
    margin-bottom: 10px;
  }

  tspan{
    font-size: 12px !important;
  }
`;
