import gql from 'graphql-tag';

export const QUERY_URL_FILE_BY_KEY = gql`
  query QUERY_URL_FILE_BY_KEY($id: ID!, $name: String!, $employeeId: ID!) {
    urlForm(id: $id, name: $name, employeeId: $employeeId)
  }
`;

export const GET_SIGNED_URL_TO_PUT_QUERY = gql`
  query GET_SIGNED_URL_QUERY(
    $id: String!
    $name: String!
    $contentType: String!
    $section: String!
    $acl: String!
  ) {
    getSignedUrlToPuts3(
      updateFileInput: {
        id: $id
        name: $name
        contentType: $contentType
        section: $section
        acl: $acl
      }
    )
  }
`;

export const GET_DOC_BY_EMPLOYEE_ID_QUERY = gql`
  query GET_DOC_BY_EMPLOYEE_ID_QUERY($employeeId: String!) {
    getDocumentsByEmployeeId(employee_id: $employeeId) {
      id
      name
      url
      source
      type
      createdBy
      createdAt
      user {
        name
      }
    }
  }
`;

export const GET_SIGNED_URL_TO_GET_QUERY = gql`
  query Query($url: String!, $filename: String!) {
    getSignedUrlToGets3(url: $url, filename: $filename)
  }
`;
