import React from 'react';

const ArrowAsc = () => {
  return (
     
   <svg 
width="18" 
height="18" 
viewBox="0 0 18 18" 
fill="none" 
xmlns="http://www.w3.org/2000/svg">

<path 
d="M9.125 15L9.125 2.25" 
stroke="#0366D6" 
strokeWidth="1.13" 
strokeLinecap="round" 
strokeLinejoin="round"
/>

<path d="M9.125 15L11 13.125" 
stroke="#0366D6" 
strokeWidth="1.13" 
strokeLinecap="round" 
strokeLinejoin="round"
/>

<path d="M7.25 13.125L9.125 15" 
stroke="#0366D6" 
strokeWidth="1.13" 
strokeLinecap="round" 
strokeLinejoin="round"
/>


</svg>

  );
};
export default ArrowAsc;
