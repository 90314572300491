import React, { useState, useRef, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { AccountCircle } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

import { ContentCreateEditTeams } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import Search from '../../../components/Icons/Search';
import Add from '../../../components/Icons/Add';
import Subtract from '../../../components/Icons/Subtract';
import { useGetAllEmployeesQuery } from '../../../graphql/container/query/employees';
import {
  CREATE_TEAM_MUTATION,
  DELETE_TEAM_MUTATION,
  EDIT_TEAM_MUTATION
} from '../../../graphql/mutations/teams/index';
import Teams from '../../../components/Icons/Teams';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { getImageError } from '../../../services/Constants/getDefaults';
import { useGetTeamDataByIdLazyQuery } from '../../../graphql/container/query/teams';
import { routesManager } from '../../../services/Constants/paths';
import Trash from '../../../components/Icons/Trash';
import { ModalAlert } from '../../../components/Modals';
import SearchEmployee from '../../../components/SearchEmployee';
import { useTeamsByCompanyQuery } from '../../../graphql/container/query/teams';
import Error from '../../../components/Icons/Error';
import ErrorHover from '../../../components/Icons/ErrorHover';
import Breadcrumbs from '../../../components/Breadcrumbs';

const CreateEditTeams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;
  const [toAdd, setToAdd] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');
  const [currentManager, setCurrentManager] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [query, setQuery] = useState('');
  const [searchEmployee, setSearchEmployee] = useState('');
  const [teamName, setTeamName] = useState('');
  const [removedEmployee, setRemovedEmployee] = useState([]);
  const [change, setChange] = useState(true);
  const [reloadFirst, setReloadFirst] = useState(true);
  const teamId = location?.state?.teamId;
  const [allTeams, setAllTeams] = useState([]);
  const [iconNameTeams, setIconNameTeams] = useState(true);
  const tab = location?.state?.tab ;

  const [createTeam, { loading: createLoading }] = useMutation(
    CREATE_TEAM_MUTATION
  );

  const [removeTeams, { loading: removeTeamsLoading }] = useMutation(
    DELETE_TEAM_MUTATION
  );

  const [editTeam, { loading: editTeamLoading }] = useMutation(
    EDIT_TEAM_MUTATION
  );

  const {
    data: { getAllTeamsByCompanyIdInfoTable }
  } = useTeamsByCompanyQuery(companyId);

  const {
    data: { getAllEmployeesByCompany },
    loading: loadingEmployees,
    refetch: refetchGetEmployees
  } = useGetAllEmployeesQuery(companyId);

  const {
    data: getAllEmployeesByCompanyTeams,
    loading: loadingEmployeesTeams
  } = useGetAllEmployeesQuery(companyId);

  const [
    getTeamDataById,
    { data: dataTeamById, loading: loadingEmployeesData }
  ] = useGetTeamDataByIdLazyQuery();

  const toolTip = phrase => {
    return <p>{phrase}</p>;
  };

  useEffect(() => {
    if (teamId) {
      getTeamDataById({
        variables: {
          teamId: teamId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (dataTeamById?.getTeamDataById) {
      const info = dataTeamById?.getTeamDataById;
      setTeamName(info?.name);
    }
    // eslint-disable-next-line
  }, [dataTeamById?.getTeamDataById]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchEmployee(query), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    refetchGetEmployees({
      companyId: companyId,
      teamId,
      search: searchEmployee
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmployee]);

  useEffect(() => {
    if (!createLoading && isMounted.current) {
      navigate(routesManager.listTeams(), {
        state: { site: 'teams', tab : tab === 0 ? 0 : 1 },
        replace: true
      });
    }
    // eslint-disable-next-line
  }, [createLoading]);

  useEffect(() => {
    if (!removeTeamsLoading && isMounted.current) {
      navigate(routesManager.listTeams(), {
        state: { site: 'teams', tab : tab === 0 ? 0 : 1  },
        replace: true
      });
    }
    // eslint-disable-next-line
  }, [removeTeamsLoading]);

  useEffect(() => {
    if (!editTeamLoading && isMounted.current) {
      navigate(routesManager.listTeams(), {
        state: { site: 'teams', tab : tab === 0 ? 0 : 1  },
        replace: true
      });
    }
    // eslint-disable-next-line
  }, [editTeamLoading]);

  useEffect(() => {
    const updateManager = getAllEmployeesByCompany?.find(
      el => el.id === selectedManager
    );
    if (updateManager) {
      setCurrentManager(updateManager);
    }
  }, [getAllEmployeesByCompany, selectedManager]);

  useEffect(() => {
    if (!removedEmployee.length) {
      /* update Teams */
      if (getAllEmployeesByCompany && dataTeamById) {
        const currentEmployeesID = dataTeamById?.getTeamDataById?.members?.map(
          el => el?.employeeId
        );
        const managerId = dataTeamById?.getTeamDataById?.manager?.employeeId;

        const othersList = getAllEmployeesByCompany?.filter(
          el =>
            !currentEmployeesID?.includes(el?.id) &&
            el?.status === 'ACTIVE' &&
            (el?.permissionEmployee
              ? el?.permissionEmployee.description === 'Admin' ||
                el?.permissionEmployee.description === 'Owner'
                ? false
                : true
              : true) &&
            el.id !== managerId &&
            el.id !== selectedManager
        );

        setToAdd(othersList);

        const valueSelectedManager = getAllEmployeesByCompany?.find(
          el => el.id === selectedManager
        );
        const valueMangerId = getAllEmployeesByCompany?.find(
          el => el.id === managerId
        );

        if (valueSelectedManager) {
          setCurrentManager(valueSelectedManager);
        } else if (valueMangerId) {
          setCurrentManager(valueMangerId);
        }

        isMounted.current = true;
        setReloadFirst(false);
      }
      /* create new Teams */
      if (getAllEmployeesByCompany && !dataTeamById) {
        setToAdd(
          getAllEmployeesByCompany?.filter(
            el =>
              el?.status === 'ACTIVE' &&
              (el?.permissionEmployee
                ? el?.permissionEmployee.description === 'Admin' ||
                  el?.permissionEmployee.description === 'Owner'
                  ? false
                  : true
                : true) &&
              el.id !== selectedManager
          )
        );
        setReloadFirst(false);
        isMounted.current = true;
      }
    } else {
      if (getAllEmployeesByCompany && dataTeamById) {
        const removedID = removedEmployee.map(el => el.id);
        const currentEmployeesID = dataTeamById?.getTeamDataById?.members?.map(
          el => el?.employeeId
        );
        const othersList = getAllEmployeesByCompany?.filter(
          el =>
            !currentEmployeesID?.includes(el?.id) &&
            el?.status === 'ACTIVE' &&
            (el?.permissionEmployee
              ? el?.permissionEmployee.description === 'Admin' ||
                el?.permissionEmployee.description === 'Owner'
                ? false
                : true
              : true) &&
            el.id !== selectedManager
        );
        const othersListTwo = getAllEmployeesByCompany?.filter(el =>
          removedID.includes(el?.id)
        );
        const otherListThree = othersList.concat(othersListTwo);
        const employeesId = employees.map(el => el.id);

        setToAdd(otherListThree.filter(el => !employeesId.includes(el.id)));

        isMounted.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllEmployeesByCompany, dataTeamById, currentManager]);

  useEffect(() => {
    if (getAllEmployeesByCompanyTeams && dataTeamById) {
      const currentEmployeesID = dataTeamById?.getTeamDataById?.members?.map(
        el => el?.employeeId
      );

      const managerId = dataTeamById?.getTeamDataById?.manager?.employeeId;

      const filterEmployess = async data => {
        const currentList = data?.filter(
          el =>
            currentEmployeesID?.includes(el?.id) &&
            el?.status === 'ACTIVE' &&
            (el?.permissionEmployee
              ? el?.permissionEmployee.description === 'Admin' ||
                el?.permissionEmployee.description === 'Owner'
                ? false
                : true
              : true) &&
            el.id !== managerId &&
            el.id !== selectedManager
        );
        setEmployees(currentList);
      };
      filterEmployess(getAllEmployeesByCompanyTeams?.getAllEmployeesByCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllEmployeesByCompanyTeams, dataTeamById]);

  const addNewEmployee = employee => {
    setEmployees(prev => {
      const added = prev;
      added.push(employee);
      return [...added];
    });

    toAdd.forEach((item, i) => {
      if (item.id === employee.id) {
        setToAdd(prev => {
          const empl = prev;
          empl.splice(i, 1);
          return [...empl];
        });
      }
    });
    setChange(false);
  };

  let removedEmployees = removedEmployee;
  const removeEmployee = employee => {
    let newAdded = employees.filter(item => item.id !== employee.id);
    setEmployees([...newAdded]);

    const added = newAdded.map(item => item.id);
    const othersList = getAllEmployeesByCompany?.filter(
      el => !added?.includes(el?.id)
    );
    removedEmployees.push(employee);
    setRemovedEmployee(removedEmployees);
    setToAdd(othersList);
    setChange(false);
  };

  const onCreateTeam = () => {
    createTeam({
      variables: {
        teamName,
        companyId: companyId,
        members: employees?.map(item => ({
          id: item.id
        })),
        manager: { id: selectedManager }
      }
    });
  };

  const deleteTeam = () => {
    removeTeams({
      variables: {
        list: [{ id: teamId }]
      }
    });
  };

  const onEditTeam = () => {
    editTeam({
      variables: {
        id: teamId,
        name: teamName,
        currentManager: {
          id: currentManager?.id
        },
        currentMembers: employees?.map(item => ({
          id: item.id
        }))
      }
    });
  };

  useEffect(() => {
    if (getAllTeamsByCompanyIdInfoTable)
      setAllTeams(getAllTeamsByCompanyIdInfoTable);
  }, [getAllTeamsByCompanyIdInfoTable]);

  useEffect(() => {
    /*
    validations update  teams
    */
    const info = dataTeamById?.getTeamDataById;
    if (dataTeamById) {
      const idMembersTeam = info?.members?.map(el => el?.employeeId).sort();
      const idMembersEmployees = employees?.map(el => el?.id).sort();
      (info?.name === teamName &&
        info?.manager?.employeeId === currentManager?.id &&
        JSON.stringify(idMembersTeam) === JSON.stringify(idMembersEmployees)) ||
      teamName?.length <= 0 ||
      (!getAllEmployeesByCompanyTeams?.getAllEmployeesByCompany?.find(
        el => el.id === selectedManager
      ) &&
        selectedManager !== '')
        ? setChange(true)
        : setChange(false);
    } else {
      /*
  validations new teams
  */
      !teamName?.length <= 0 &&
      getAllEmployeesByCompanyTeams?.getAllEmployeesByCompany?.find(
        el => el.id === selectedManager
      )
        ? setChange(false)
        : setChange(true);
    }

    const nameTeam = document.getElementById('name-teams');
    const iconTeam = document.getElementById('name-teams-help');

    if (nameTeam && info?.name !== teamName) {
      if (allTeams?.find(el => el.name === teamName)) {
        // condicion se debe agregar al modificar o crear un team
        nameTeam.setAttribute(
          'style',
          'box-shadow:none; width: 311px; border:1px solid #D73A49; border-right:none; border-top-right-radius: 0rem !important; border-bottom-right-radius: 0rem !important; width=300px !important;'
        );
        iconTeam.setAttribute('style', 'display:flex;');
        setChange(true);
      } else {
        nameTeam.removeAttribute('style');
        iconTeam.setAttribute('style', 'display:none;');
      }
    }
  }, [
    dataTeamById,
    teamName,
    selectedManager,
    currentManager,
    employees,
    getAllEmployeesByCompanyTeams,
    allTeams
  ]);

  if (
    (dataTeamById ||
      employees.length === 0 ||
      loadingEmployeesTeams ||
      loadingEmployees ||
      loadingEmployeesData ||
      !isMounted.current) &&
    reloadFirst
  ) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <>
      <ModalAlert
        show={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={deleteTeam}
        content={
          <div
            className="content"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <p className="text-normal">
              Are you sure you want to delete this team?
            </p>
          </div>
        }
        centered
        disabled={removeTeamsLoading}
        confirmLabel="Delete Team"
      />
      <Breadcrumbs
        routes={routesManager.Teams}
        section={'Team'}
        subtitle={teamName ? teamName : 'Create New Team'}
      />
      <ContentCreateEditTeams>
        <div className="create-edit-title-style">
          {/* <button
            style={{ border: 'none', background: 'transparent' }}
            onClick={() =>
              navigate(routesOwner.teams(), {
                state: { site: 'teams' },
                replace: true
              })
            }
          >
            {backArrow}
          </button> */}
          <span className="text-bold">
            {location?.state?.teamId ? 'Edit Team' : 'New Team'}
          </span>
        </div>
        <div className="create-edit-header">
          <div>
            <p>Team Name</p>
            <InputGroup>
              <Form.Control
                value={teamName}
                onChange={e => setTeamName(e.target.value)}
                id="name-teams"
                placeholder={`Example: HR`}
              />
              <InputGroup.Text
                className="iconTeamName"
                id="name-teams-help"
                onMouseOver={() => setIconNameTeams(false)}
                onMouseOut={() => setIconNameTeams(true)}
              >
                {iconNameTeams ? <Error /> : <ErrorHover />}
                <div className="textQuestion">
                  {toolTip('There is already a team with this name assigned')}
                </div>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="selectManager">
            <p>Select Manager</p>
            <SearchEmployee
              infoUser={false}
              icon={false}
              incommingClass={'fatherClass'}
              searchPlaceHolder={
                currentManager
                  ? currentManager.firstName + ' ' + currentManager?.lastName
                  : 'Enter the name of the manager'
              }
              dropdownClass={'dropdownTeam'}
              saveInformation
              setSelectedManager={setSelectedManager}
              excludeSeparate={true}
              excludeEmployee={true}
              currentList={employees}
            />
          </div>
        </div>
        {
          <div className="add-remove-employees">
            <div className="employees-to-add">
              <InputGroup>
                <div className="search-bar">
                  <InputGroup.Text id="basic-addon1">
                    <Search />
                  </InputGroup.Text>
                  <FormControl
                    onChange={({ target }) => setQuery(target.value)}
                    placeholder="Search Employee"
                    aria-label="Search employee"
                  />
                </div>
              </InputGroup>
              {!loadingEmployees ? (
                toAdd.map((item, i) => {
                  const name = `${item.firstName || ''} ${item.middleName ||
                    ''} ${item.lastName || ''} ${item.mothersMaidenName || ''}`;
                  return (
                    <div key={`to-add-${i}`} className="to-add">
                      <div className="card-icon">
                        <span className="icon-profile">
                          {item?.user?.photo ? (
                            <img
                              src={item.user.photo}
                              alt="Profile"
                              onErrorCapture={e =>
                                (e.target.onErrorCapture = null)(
                                  (e.target.src = `${getImageError(companyId)}`)
                                )
                              }
                              referrerPolicy="no-referrer"
                            />
                          ) : (
                            <AccountCircle
                              style={{ fontSize: 43, margin: '-3px' }}
                            />
                          )}
                        </span>
                        <div className="icon-label">
                          <p className="text-bold">{name}</p>
                          <p className="text-small">{item.name}</p>
                          <p className="text-small">
                            {item?.employeeRoleInfo?.name}
                          </p>
                        </div>
                      </div>
                      <div className="card-button">
                        <Button
                          className="btn-success"
                          onClick={() => addNewEmployee(item)}
                        >
                          <Add />
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'inline-block'
                  }}
                  className="add-search-employee"
                >
                  <CircularProgress className="circular-progress" />
                </div>
              )}
            </div>
            <div className="employees-added">
              {!loadingEmployeesData ? (
                employees?.length ? (
                  <>
                    <p className="teamName">{teamName}</p>
                    {employees.map((item, i) => {
                      const name = `${item.firstName || ''} ${item.middleName ||
                        ''} ${item.lastName || ''} ${item.mothersMaidenName ||
                        ''}`;
                      return (
                        <div key={`to-add-${i}`} className="to-add">
                          <div className="card-icon">
                            <span className="icon-profile">
                              {item?.user?.photo ? (
                                <img
                                  src={item?.user?.photo}
                                  alt="Profile"
                                  onErrorCapture={e =>
                                    (e.target.onErrorCapture = null)(
                                      (e.target.src = `${getImageError(
                                        companyId
                                      )}`)
                                    )
                                  }
                                  referrerPolicy="no-referrer"
                                />
                              ) : (
                                <AccountCircle
                                  style={{ fontSize: 43, margin: '-3px' }}
                                />
                              )}
                            </span>
                            <div className="icon-label">
                              <p>{name}</p>
                              <p className="text-bold">{item.name}</p>
                              <p className="text-small">
                                {item?.employeeRoleInfo?.name}
                              </p>
                            </div>
                          </div>
                          <div className="card-button">
                            <Button
                              className="btn-danger"
                              onClick={() => removeEmployee(item)}
                            >
                              <Subtract />
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="noData">
                    <Teams />
                    <div>
                      <span className="text-normal">
                        No employees in this team
                      </span>
                    </div>
                  </div>
                )
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    display: 'inline-block'
                  }}
                  className="add-search-employee"
                >
                  <CircularProgress className="circular-progress" />
                </div>
              )}
            </div>
          </div>
        }
        <div className="create-edit-buttons">
          <div className="card-button-remove">
            {teamId && (
              <Button className="btn-danger" onClick={() => setOpenModal(true)}>
                <span style={{ paddingBottom: '' }}>
                  <Trash color="#D73A49" />
                </span>
                <span style={{ marginLeft: '13px' }}>Delete Team</span>
              </Button>
            )}
          </div>
          <div className="card-button-confirm">
            <Button
              className="btn-danger-without-bg"
              onClick={() =>
                navigate(routesManager.listTeams(), {
                  state: { site: 'teams', tab : tab === 0 ? 0 : 1 },
                  replace: true
                })
              }
            >
              Cancel
            </Button>
            <Button
              className="btn-success"
              onClick={() => (teamId ? onEditTeam() : onCreateTeam())}
              disabled={
                !teamId
                  ? change || !teamName || createLoading || editTeamLoading
                  : change || editTeamLoading
              }
            >
              {teamId ? 'Update Team' : 'Create team'}
            </Button>
          </div>
        </div>
      </ContentCreateEditTeams>
    </>
  );
};

export default CreateEditTeams;
