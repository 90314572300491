
export const personal = [
  {
    title: 'First Name:',
    edit: 'textbox',
    table: 'employee',
    placeholder: 'First Name',
    input: 'firstName',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Last Name:',
    edit: 'textbox',
    table: 'employee',
    placeholder: 'Last Name',
    input: 'lastName',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'ID (National ID or Tax ID):',
    edit: 'textbox',
    table: 'contactInformation',
    placeholder: '123456789',
    input: 'documentId',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Address:',
    edit: 'textbox',
    table: 'contactInformation',
    placeholder: 'Adress Line 1',
    input: 'addressOne',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Address 2:',
    edit: 'textbox',
    table: 'contactInformation',
    placeholder: 'Adress Line 2',
    input: 'addressTwo',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'City:',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'city',
    placeholder: 'City',
    input: 'city',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Country:',
    edit: 'list',
    table: 'contactInformation',
    field: 'country',
    placeholder: 'Select  ▾',
    input: 'country',
  },
  {
    title: 'State:',
    edit: 'textbox',
    table: 'contactInformation',
    placeholder: 'Enter state',
    input: 'state',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'ZIP:',
    edit: 'textbox',
    table: 'contactInformation',
    placeholder: 'Enter Zip',
    input: 'postalCode',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Nationality:',
    edit: 'list',
    table: 'contactInformation',
    field: 'country',
    placeholder: 'Select  ▾',
    input: 'nationality',
  },
  {
    title: 'Birthday:',
    edit: 'date',
    table: 'employee',
    placeholder: 'MM/DD/YYYY',
    input: 'dateOfBirth',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'T-Shirt size:',
    edit: 'list',
    table: 'customInformation',
    field: 'T-Shirt size',
    placeholder: 'Select  ▾',
    input: 'value',
  }

];

export const contact = [
  {
    title: 'Company Email:',
    edit: 'textbox',
    table: 'user',
    type: 'email',
    field: 'email',
    placeholder: 'email@company.com',
    input: 'companyEmail',
    value(obj) {
      return obj[this.field];
    },
    check(text, reg) {
      return reg.test(text);
    }
  },
  {
    title: 'Personal Email:',
    edit: 'textbox',
    table: 'contactInformation',
    type: 'email',
    field: 'personalEmail',
    placeholder: 'personal@mail.com',
    input: 'personalEmail',
    value(obj) {
      return obj[this.field];
    },
    check(text, reg) {
      return reg.test(text);
    }
  },
  {
    title: 'Phone:',
    edit: 'textbox',
    table: 'contactInformation',
    type: 'number',
    placeholder: '+00 000 000 0000',
    field: 'phone',
    input: 'phone',
    value(obj) {
      return obj[this.field];
    },
  }
];
export const banking = [
  {
    title: 'Bank Name:',
    edit: 'textbox',
    table: 'paymentInformation',
    placeholder: 'Enter Bank Name',
    input: 'bankName',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Account Number:',
    edit: 'textbox',
    table: 'paymentInformation',
    placeholder: '000 000 000 000',
    input: 'bankAccount',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'SWIFT Code:',
    edit: 'textbox',
    table: 'paymentInformation',
    placeholder: '000000000',
    input: 'bankSwiftCode',
    type: 'text',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Routing (US banks only):',
    edit: 'textbox',
    table: 'paymentInformation',
    placeholder: '000000000',
    input: 'routingNumber',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Intermediary Bank:',
    edit: 'checkbox',
    table: 'paymentInformation',
    placeholder: '000000000',
    input: 'intermediaryBank',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
  {
    title: 'Intermediary Bank Routing:',
    edit: 'textbox',
    table: 'paymentInformation',
    placeholder: '000000000',
    input: 'intermediaryRouting',
    type: 'number',
    value(obj) {
      return obj[this.input];
    },
  },
]
export const employment = [
  {
    title: 'Employment Form:',
    table: 'employee',
    edit: 'list',
    placeholder: 'W8-BEN  ▾',
    input: 'employmentForm',
  },
  {
    title: 'Dedication:',
    edit: 'list',
    table: 'employee',
    placeholder: 'Select  ▾',
    input: 'schedule',
  },
  {
    title: 'Start Date:',
    edit: 'date',
    table: 'employee',
    placeholder: 'MM/DD/YYYY',
    input: 'startDate',
  },
  {
    title: 'Role:',
    edit: 'list',
    table: 'employee',
    placeholder: 'Select  ▾',
    field: 'employeeRole',
    input: 'name',
    role: 'employeeRoleInfo',
  },
  {
    title: 'Salary:',
    edit: 'textbox',
    table: 'employee',
    placeholder: '$',
    input: 'salary',
  },
  {
    title: 'Team(s):',
    edit: 'list',
    table: 'employeeTeam',
    placeholder: 'Select  ▾',
    field: 'employeeTeam',
    input: 'teamIds',
  }
];

export const inicialpto = [
  {
    title: 'Available Days:',
    edit: 'text',
    table: 'timeOffAccrual',
    placeholder: 'Actual Days',
    input: 'daysOff',
    type: 'number',
  }
]
