import React from 'react';

const SelectCheckbox = () => {
  return (
    <svg
      width="7"
      height="16"
      viewBox="0 0 7 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333374"
        y="0.888916"
        width="6.22222"
        height="6.22222"
        rx="2"
        fill="#172C45"
      />
      <path
        d="M4.79224 2.74904C4.9896 2.54964 5.32055 2.58287 5.47431 2.81753V2.81753C5.58692 2.98938 5.56589 3.21624 5.42361 3.36445L3.10689 5.77785L1.50063 4.26264C1.34037 4.11146 1.31397 3.86612 1.43841 3.68431V3.68431C1.5883 3.4653 1.89475 3.4241 2.09717 3.59573L3.10689 4.45186L4.79224 2.74904Z"
        fill="white"
      />
      <rect
        x="0.840698"
        y="9.38892"
        width="5.22222"
        height="5.22222"
        rx="1.5"
        stroke="#172C45"
      />
    </svg>
  );
};

export default SelectCheckbox;
