import React, { useState } from 'react';
import Table from '../../../../components/GenericTable';
import Groups from '../../../../components/Icons/Groups';
import { AccountCircle } from '@material-ui/icons';
import Copy from '../../../../components/Icons/Copy';
const NoData = () => {
  return (
    <div className="noData">
      <Groups color="black" size="48" />
      <div>
        <span className="text-normal">
          You don't have people in your team yet
        </span>
      </div>
    </div>
  );
};

const EmployeeTeams = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected,
  setSortChoiceTeam,
  setItemEmployeesTeam,
  sortChoiceTeam
}) => {
  const attributes = ['id', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  dataSource.forEach((item, i) => {
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'nameEmployeeTeam':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <span className="icon-profile">
                  {item?.photo ? (
                    <img
                      src={item.photo}
                      alt="Profile"
                      onErrorCapture={e => (e.target.onErrorCapture = null)}
                      referrerPolicy="no-referrer"
                    />
                  ) : (
                    <AccountCircle style={{ fontSize: 43, margin: '-3px' }} />
                  )}
                  <span className="name-description">{item?.nameEmployee}</span>
                </span>
              )
            });
          break;
        case 'rolEmployeeTeam':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: <span className="text-description"> {item?.role} </span>
            });
          break;
        case 'emailEmployeeTeam':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <div>
                  <span className="text-description">
                    {item?.emailEmployee}
                  </span>
                  <span
                    className="copy-email"
                    onClick={() => {
                      navigator.clipboard.writeText(item?.emailEmployee);
                    }}
                  >
                    <Copy />
                  </span>
                </div>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
      sort
      setItemEmployeesTeam={setItemEmployeesTeam}
      setSortChoiceTeam={setSortChoiceTeam}
      sortChoiceTeam={sortChoiceTeam}
    />
  );
};

export default EmployeeTeams;
