import gql from 'graphql-tag';

export const GET_PROFILE = gql`
  query GET_PROFILE($employeeId: String!) {
    getProfileInfo(employee_id: $employeeId) {
      company
      employmentType
      firstName
      lastName
      mothersMaidenName
      username
      salary
      startDate
      dateOfBirth
      schedule
      contactInformation {
        id
        employee
        documentId
        addressOne
        addressTwo
        city
        country
        state
        postalCode
        phone
        personalEmail
      }
      paymentInformation {
        bankName
        bankCountry
        bankSwiftCode
        routingNumber
      }
      user {
        email
        photo
      }
      employeeRoleInfo {
        name
        salaryHigh
        salaryLow
        salaryMid
      }
      customInformation {
        employee
        name
        value
      }
      informationPending {
        employeeId
        value
        field
        table
        id
        createdAt
      }
    }
  }
`;

export const GET_ME_PROFILE = gql`
  query GET_ME_PROFILE($employeeId: String!) {
    getMeProfileInfo(employee_id: $employeeId) {
      company
      employmentType
      firstName
      lastName
      mothersMaidenName
      username
      salary
      startDate
      dateOfBirth
      schedule
      contactInformation {
        id
        employee
        documentId
        addressOne
        addressTwo
        city
        country
        state
        nationality
        postalCode
        phone
        personalEmail
      }
      paymentInformation {
        bankName
        bankAccount
        bankCountry
        bankSwiftCode
        routingNumber
      }
      user {
        email
        photo
      }
      employeeRoleInfo {
        name
        salaryHigh
        salaryLow
        salaryMid
      }
      customInformation {
        employee
        name
        value
      }
      informationPending {
        employeeId
        value
        field
        table
        id
        createdAt
      }
    }
  }
`;

export const GET_CITIES_OF_THE_EMPLOYEES = gql`
  query GetCitiesOfTheEmployees {
    getCitiesOfTheEmployees {
      city
    }
  }
`;
