import styled from 'styled-components';

export const LoginContainer = styled.section`
  width: 100vw;
  height: 100vh;
  background-color: #172c45;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 450px;
  max-width: 92%;
  height: 346px;
  background-color: white;
`;

export const LoginHeader = styled.div`
  background-color: #d7e1ea;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #172c45;
    margin: 0 0 0 10px;
    font-size: 34px;
    font-weight: 500;
  }
  img {
    width: 45px;
  }
`;

export const LoginContent = styled.div`
  height: calc(100% - 128px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled.button`
  font-family: 'system-ui';
  height: 52px;
  width: 295px;
  max-width: 80%;
  outline: none;
  border: 2px solid #172c45;
  background-color: #ffffff;
  color: #172c45;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 400;
  cursor: pointer;
  svg {
    height: 30px;
    width: 30px;
    margin-left: 5px;
    margin-right: 20px;
  }
  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`;

export const H1 = styled.h1`
  font-family: 'system-ui';
  font-size: 24px;
  line-height: 48px;
  color: #172c45;
  font-weight: normal;
  margin-top: -30px;
  img {
    width: 23px;
  }
`;
