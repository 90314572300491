import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import Trash from '../../../components/Icons/Trash';
import { ContentTeams } from './styles';
import TableTeams from './TableTeams';
import { AppContext } from '../../../Context/App/AppContext';
import { DELETE_GROUPS_MUTATION } from '../../../graphql/queries/groups';
import { ModalAlert } from '../../../components/Modals';
import { routesOwner } from '../../../services/Constants/paths';
import { useTeamsByCompanyQuery } from '../../../graphql/container/query/teams';
import { SearchEmployeeContainer } from '../../../components/SearchEmployee/styles.js';
import Search from '../../../components/Icons/Search';
import { readCookies } from '../../../services/Utils/getUtils';

const initialsColumns = [
  {
    name: 'Team Name',
    key: 'teamName',
    active: true
    // width: '40%'
  },
  {
    name: 'Employees',
    key: 'employees',
    active: true
  },
  {
    name: 'Manager',
    key: 'managerCompany',
    active: true
  },
  {
    name: '',
    key: 'edit',
    active: true
  }
];

const Team = ({setCurrentPage, offset = 0 , setOffset , limit = 10 , setLimit, handlePage, setAllTeamsPagination, tabActive}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;
  const [columnsTable] = useState(initialsColumns);
  const [cacheFiltersTeams, setCacheFiltersTeams] = useState(
    readCookies('TeamsTable')
  );
  const [allTeams, setAllTeams] = useState([]);
  const [selected, setSelected] = useState([]);
  const [sortChoiceTeam, setSortChoiceTeam] = useState(
    cacheFiltersTeams?.[2] ? JSON.parse(cacheFiltersTeams[2]) : true
  );
  const [itemTeam, setItemTeam] = useState(
    cacheFiltersTeams?.[1] ? JSON.parse(cacheFiltersTeams[1]) : ''
  );
  const [openModal, setOpenModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  useEffect(() => {

    setCurrentPage(1)
    setOffset(0)
    setLimit(10)
    setAllTeamsPagination(allTeams)

  }, [allTeams, setCurrentPage, setOffset, setLimit, setAllTeamsPagination ]);

  const {
    data: { getAllTeamsByCompanyIdInfoTable }
  } = useTeamsByCompanyQuery(companyId);

  const [removeGroups] = useMutation(DELETE_GROUPS_MUTATION);

  useEffect(() => {
    if (getAllTeamsByCompanyIdInfoTable)
      if (itemTeam !== '') {
        handleSortTeam(itemTeam);
      } else {
        setAllTeams(getAllTeamsByCompanyIdInfoTable);
        setAllTeamsPagination(allTeams);
      }
    // eslint-disable-next-line
  }, [getAllTeamsByCompanyIdInfoTable]);

  useEffect(() => {
    handleSortTeam(itemTeam);
    // eslint-disable-next-line
  }, [sortChoiceTeam, itemTeam]);


  const orderBySortField = (data, field ) => {

    const sortTeam = 
    sortChoiceTeam 
    ? data &&
      data.sort((a, b) => {
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      })
    : data &&
      data.sort((a, b) => {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return 1;
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
  return setAllTeams(sortTeam);
    
  }

  const orderBySortNumberField = (data, field ) => {

    const sortTeamEmployees = sortChoiceTeam
    ? data &&
      data.sort((a, b) => {
        return a[field] - b[field];
      })
    : data &&
      data.sort((a, b) => {
        return b[field] - a[field];
      });
  return setAllTeams(sortTeamEmployees);
    
  }

  const handleSortTeam = value => {

    const data = allTeams?.length < getAllTeamsByCompanyIdInfoTable?.length
    ? allTeams
    : getAllTeamsByCompanyIdInfoTable;

    if (value === 'Team Name') {
      orderBySortField(data,'name')
    }
    if (value === 'Employees') {
      orderBySortNumberField(data,'employees')
    }
    if (value === 'Manager') {
      orderBySortField(data,'manager')
    }
  };

  useEffect(() => {
    if (itemTeam) {
      saveCookies();
      setCacheFiltersTeams(readCookies('TeamsTable'));
    }
    // eslint-disable-next-line
  }, [itemTeam, sortChoiceTeam]);

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `TeamsTable=; expires=${dateExpires}`;
    document.cookie = `TeamsTable=***${JSON.stringify(
      itemTeam
    )}***${JSON.stringify(
      sortChoiceTeam
    )}; expires=31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
  }

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;
  }, [isMounted]);

  const deleteGroup = () => {
    setOpenModal(false);
    removeGroups({
      variables: {
        list: selected
      }
    });
    setLoadingTable(true);
    setSelected([]);
  };

  return (
    <>
      <ContentTeams>
        <div className="permission-header">
          <div>
            <h3 className="sub-heading">Teams ({allTeams?.length})</h3>
          </div>
          <div className="content-search">
            <SearchTeams
              setAllTeams={setAllTeams}
              TeamsData={getAllTeamsByCompanyIdInfoTable}
              handlePage={handlePage}
            />
          </div>
          <div>
            <Button
              className="btn-danger trash"
              hidden={!selected.length}
              onClick={() => setOpenModal(true)}
            >
              <Trash color="white" />
              <span>{'Delete'}</span>
            </Button>
            <Button
              className="btn-success"
              onClick={() => {
                window.history.pushState(null, null, location.pathname);
                navigate(routesOwner.createEditTeams(), {
                  state: { teamId: null },
                  replace: true
                });
              }}
            >
              <span>{'Create New Team'}</span>
            </Button>

            <ModalAlert
              show={openModal}
              onClose={() => setOpenModal(false)}
              onConfirm={deleteGroup}
              content={
                <div className="content" style={{ padding: '0 6%' }}>
                  <p className="text-normal">
                    Are you sure you want to delete the next team?:
                  </p>
                  <ul>
                    {selected?.map(item => {
                      return (
                        <li key={`li-${item.id}`} className="text-normal">
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              }
              centered
            />
          </div>
        </div>
        <div className="teams-table">
          <TableTeams
            columns={columnsTable}
            dataSource={allTeams?.slice(offset, offset + limit)}
            loading={loadingTable}
            selected={selected}
            setSelected={setSelected}
            setItemTeam={setItemTeam}
            setSortChoiceTeam={setSortChoiceTeam}
            sortChoiceTeam={sortChoiceTeam}
            tabActive= { tabActive }
          />
        </div>
      </ContentTeams>
    </>
  );
};

/**
 * This function is a React component that renders a search bar that filters the data in the table
 * below
 */
const SearchTeams = ({ setAllTeams, TeamsData, handlePage }) => {
  const [labelSearch, setLabelSearch] = useState('');

  const searchTeam = search => {
    if (search.trim() === '') {
      handlePage(1);
      setAllTeams(TeamsData);
    } else {
      const newState = TeamsData.filter(obj => {
        if (obj?.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return obj;
        } else {
          return false;
        }
      });
      handlePage(1);
      setAllTeams(newState);
    }
  };
  return (
    <div>
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setLabelSearch(target.value);
                searchTeam(target.value);
              }}
              placeholder={'Search Team'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search-permissions"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default Team;
