import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { AccountCircle } from '@material-ui/icons';
import { Calendar } from 'react-widgets';
import 'react-calendar-datetime-picker/dist/index.css';

import { Col, Row } from '../../../components/Grid/Grid';
import { ContentSideCalendar, ButtonContentSideCalendar } from './styles';
import { IconEvents } from '../../../components/Icons/IconEvents';
import { useEventsQuery } from '../../../graphql/container/query/employees';
import CalendarIcon from '../../../components/Icons/Calendar';
import { CircularProgress } from '@material-ui/core';
import {
  DATE_FORMAT,
  PermissionsEmployee
} from '../../../services/Constants/getDefaults';
import { AppContext } from '../../../Context/App/AppContext';
import { isAfter, isSameOrBefore } from '../../../services/Utils/getUtils';
import { useNavigate } from 'react-router-dom';
import { routesOwner, routesEmployee } from '../../../services/Constants/paths';
import ClosedCalendar from '../../../components/Icons/ClosedCalendar';
import OpenCalendar from '../../../components/Icons/OpenCalendar';
import { routesAdmin } from '../../../services/Constants/paths';

const currentDate = moment()
  .utc()
  .format(DATE_FORMAT);

const Events = () => {
  const location = useLocation();
  const [date, setDate] = useState(currentDate);
  const [listNextDays, setListNextDays] = useState([]);
  const [listToday, setListToday] = useState([]);
  const [height, setHeight] = useState(0);
  const {
    roleUser,
    watchEvents,
    setWatchEvents,
    calendar,
    heightContainer
  } = useContext(AppContext);
  const admin =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN;

  const {
    data: { getEvents },
    loading,
    refetch
  } = useEventsQuery(date);

  const locationSkip =
    routesAdmin.home.includes(location.pathname) ||
    routesEmployee.pto.includes(location.pathname);

  useEffect(() => {
    refetch({ date });
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    calendar
      ? setHeight(window.innerHeight)
      : setHeight(
          routesEmployee.pto.includes(location.pathname)
            ? heightContainer
            : '100%'
        );
  }, [height, calendar, heightContainer, location.pathname]);

  useEffect(() => {
    const thisYear = new Date().getFullYear();
    if (getEvents) {
      const { anniversaries, birthdays, pto } = getEvents;
      const today = [];
      const nextDays = [];
      anniversaries.forEach(item => {
        const startDate = moment(item.startDate)
          .utc()
          .format('MM-DD');
        if (
          isSameOrBefore(
            moment(startDate)._i,
            moment(date)
              .add(5, 'days')
              .utc()
              .format('MM-DD')
          ) &&
          isAfter(
            moment(startDate)._i,
            moment(date)
              .utc()
              .format('MM-DD')
          )
        ) {
          nextDays.push({ ...item, typeEvent: 'year' });
        } else if (
          moment(startDate)._i ===
          moment(date)
            .utc()
            .format('MM-DD')
        ) {
          today.push({ ...item, typeEvent: 'year' });
        }
      });
      birthdays.forEach(item => {
        const dateOfBirth = moment(item.dateOfBirth)
          .utc()
          .format('MM-DD');
        if (
          isSameOrBefore(
            moment(dateOfBirth)._i,
            moment(date)
              .add(5, 'days')
              .utc()
              .format('MM-DD')
          ) &&
          isAfter(
            moment(dateOfBirth)._i,
            moment(date)
              .utc()
              .format('MM-DD')
          )
        ) {
          nextDays.push({ ...item, typeEvent: 'birthday' });
        } else if (
          moment(dateOfBirth)._i ===
          moment(date)
            .utc()
            .format('MM-DD')
        ) {
          today.push({ ...item, typeEvent: 'birthday' });
        }
      });
      pto.forEach(item => {
        const ptoYear = new Date(item.dateTakenStart).getFullYear();
        if (ptoYear === thisYear) {
          const dateTakenStart = moment(item.dateTakenStart)
            .utc()
            .format('MM-DD');
          const dateTakenEnd = moment(item.dateTakenEnd)
            .utc()
            .format('MM-DD');

          if (
            isSameOrBefore(
              moment(dateTakenStart)._i,
              moment(date)
                .add(5, 'days')
                .utc()
                .format('MM-DD')
            ) &&
            isAfter(
              moment(dateTakenStart)._i,
              moment(date)
                .utc()
                .format('MM-DD')
            )
          ) {
            nextDays.push({ ...item, typeEvent: 'pto' });
          } else if (
            moment(dateTakenStart)._i ===
              moment(date)
                .utc()
                .format('MM-DD') ||
            moment(dateTakenEnd)._i ===
              moment(date)
                .utc()
                .format('MM-DD') ||
            (moment(date)
              .utc()
              .format('MM-DD') < moment(dateTakenEnd)._i &&
              moment(date)
                .utc()
                .format('MM-DD') > moment(dateTakenStart)._i)
          ) {
            today.push({ ...item, typeEvent: 'pto' });
          }
        }
      });
      setListToday(today);
      setListNextDays(nextDays);
    }
    // eslint-disable-next-line
  }, [getEvents, loading]);
  const navigate = useNavigate();

  const handleNavigate = event => {
    navigate(routesOwner.editEmployee(event.employee_id), {
      state: { site: 'profile', user: event },
      replace: true
    });
  };

  return (
    <>
      <ButtonContentSideCalendar>
        <div
          className={
            locationSkip ? 'button-calendar-watchEvents' : 'button-calendar'
          }
          onClick={() => {
            setWatchEvents(!watchEvents);
          }}
        >
          {watchEvents ? <ClosedCalendar /> : <OpenCalendar />}
        </div>
      </ButtonContentSideCalendar>
      <ContentSideCalendar>
        <div
          style={{ height: height, backgroundColor: 'white' }}
          className={
            locationSkip || watchEvents
              ? calendar
                ? 'aside-menu-mobile'
                : 'aside-menu-border'
              : 'aside-menu-border-none'
          }
        >
          <Calendar
            value={new Date(date)}
            onChange={value =>
              setDate(
                moment(value)
                  .utc()
                  .format(DATE_FORMAT)
              )
            }
          />
          <div className="underCalendar">
            <Row className="title-day title-calendar">
              {!listToday.length &&
              date === currentDate &&
              listNextDays.length ? null : (
                <Col>
                  <span className="sub-heading">
                    {date === currentDate ? 'Today' : date}
                  </span>
                </Col>
              )}
            </Row>
            {loading ? (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress className="circular-progress" />
              </div>
            ) : (
              <>
                <Row className="title-day">
                  <Col className="card-section-event">
                    {listToday?.length ? (
                      listToday.map(event => {
                        return event.typeEvent === 'year' &&
                          moment(date).year() <=
                            moment(event.startDate).year() ? null : (
                          <Row
                            className="card-event"
                            key={event.abscence_id || event.id}
                          >
                            <div className="card-event-type">
                              <IconEvents
                                icon={
                                  event.type?.toLowerCase() === 'sick'
                                    ? roleUser === PermissionsEmployee.EMPLOYEE
                                      ? event.typeEvent
                                      : 'sick'
                                    : event.typeEvent
                                }
                              />
                            </div>
                            <div className="card-event-icon">
                              <span className="icon-profile">
                                {event.photo ? (
                                  <img
                                    src={event.photo}
                                    alt="PhotoUser"
                                    onErrorCapture={e =>
                                      (e.target.onErrorCapture = null)
                                    }
                                    referrerPolicy="no-referrer"
                                  />
                                ) : (
                                  <AccountCircle
                                    style={{ fontSize: 43, margin: '-3px' }}
                                  />
                                )}
                              </span>
                            </div>
                            <Col className="card-event-info">
                              {admin ? (
                                <button
                                  className="noButtonStyle"
                                  onClick={() => {
                                    window.history.pushState(
                                      null,
                                      null,
                                      location.pathname
                                    );
                                    handleNavigate(event);
                                  }}
                                >
                                  <div className="card-event-name">
                                    {event.firstName} {event.lastName}
                                  </div>
                                </button>
                              ) : (
                                <div>
                                  {event.firstName} {event.lastName}
                                </div>
                              )}

                              <div>
                                <span className="text-normal">
                                  {event.typeEvent === 'year'
                                    ? moment(date).diff(
                                        event.startDate,
                                        'years'
                                      ) + ' Years'
                                    : event.typeEvent === 'birthday'
                                    ? '  ' +
                                      moment(event.dateOfBirth)
                                        .utc()
                                        .format('MM/DD')
                                    : event.typeEvent === 'pto'
                                    ? '  ' +
                                      moment(event.dateTakenStart)
                                        .utc()
                                        .format('MM/DD/YYYY') +
                                      ' - ' +
                                      moment(event.dateTakenEnd)
                                        .utc()
                                        .format('MM/DD/YYYY')
                                    : null}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        );
                      })
                    ) : listNextDays.length && date !== currentDate ? (
                      <div className="event-no-data">
                        <CalendarIcon color="black" />
                        <h5 className="text-normal">No events or activities</h5>
                      </div>
                    ) : listNextDays.length ? null : (
                      <div className="event-no-data">
                        <CalendarIcon color="black" />
                        <h5 className="text-normal">No events or activities</h5>
                      </div>
                    )}
                  </Col>
                </Row>
                {listNextDays.length ? (
                  <>
                    <Row className="title-next-days title-calendar">
                      <Col>
                        <span className="sub-heading">{'Next 5 Days'}</span>
                      </Col>
                    </Row>
                    <Row className="title-next-days">
                      <Col className="card-section-event">
                        {listNextDays.map((event, i) => (
                          <Row
                            className="card-event"
                            key={`card-${i}-${event.id}`}
                          >
                            <div className="card-event-type">
                              <IconEvents
                                icon={
                                  event.type?.toLowerCase() === 'sick'
                                    ? roleUser === PermissionsEmployee.EMPLOYEE
                                      ? event.typeEvent
                                      : 'sick'
                                    : event.typeEvent
                                }
                              />
                            </div>
                            <div className="card-event-icon">
                              <span className="icon-profile">
                                {event.photo ? (
                                  <img
                                    src={event.photo}
                                    alt="PhotoUser"
                                    onErrorCapture={e =>
                                      (e.target.onErrorCapture = null)
                                    }
                                    referrerPolicy="no-referrer"
                                  />
                                ) : (
                                  <AccountCircle
                                    style={{ fontSize: 43, margin: '-3px' }}
                                  />
                                )}
                              </span>
                            </div>
                            <Col className="card-event-info">
                              {admin ? (
                                <button
                                  className="noButtonStyle"
                                  onClick={() => {
                                    window.history.pushState(
                                      null,
                                      null,
                                      location.pathname
                                    );
                                    handleNavigate(event);
                                  }}
                                >
                                  <div className="card-event-name">
                                    {event.firstName} {event.lastName}
                                  </div>
                                </button>
                              ) : (
                                <div>
                                  {event.firstName} {event.lastName}
                                </div>
                              )}

                              <div>
                                <span className="text-normal">
                                  {event.typeEvent === 'year'
                                    ? moment(date).diff(
                                        event.startDate,
                                        'years'
                                      ) +
                                      1 +
                                      ' Years'
                                    : event.typeEvent === 'birthday'
                                    ? moment(event.dateOfBirth)
                                        .utc()
                                        .format('MM/DD')
                                    : event.typeEvent === 'pto'
                                    ? moment(event.dateTakenStart)
                                        .utc()
                                        .format('MM/DD/YYYY') +
                                      ' - ' +
                                      moment(event.dateTakenEnd)
                                        .utc()
                                        .format('MM/DD/YYYY')
                                    : null}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </ContentSideCalendar>
    </>
  );
};

export default Events;
