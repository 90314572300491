import styled from 'styled-components';

export const ContentSideConfig = styled.div`

  background-color: white;
  border-radius: 10px;
  padding: 10px 0;

  .name-company {
    h3 {
      margin-top: 0;
      font-weight: 600;
    }
    padding-left: 20px;
    border-bottom: 1px solid #e1e4e8;
  }

  .side-link {
    cursor: pointer;
    padding-left: 5px;
    margin-top: 20px;
    margin-left: 15px;
    span {
      vertical-align: middle;
      margin-left: 5px;
    }
  }


  a{
    text-decoration: none;
  }
`;
