import React from 'react';
import { DropdownState } from './styles';
import Moment from 'react-moment';
import { transformUTCDate } from '../../services/Utils/getUtils';
import BiggerVector from '../Icons/BiggerVector';
import { DATE_FORMAT } from '../../services/Constants/getDefaults';
import NoTeam from '../Icons/NoTeam';
import IconProfileTeams from '../Icons/IconProfileTeams';
import Close from '../Icons/Close';

const DropdownPto = ({ id, item, teams, children }) => {
  return (
    <DropdownState>
      <input
        type="checkbox"
        id={`pto-item-${id}`}
        className="pto-item__input"
      ></input>
      <label htmlFor={`pto-item-${id}`} className="pto-item-label">
        {children}
        <div className="d-flex align-items-center">
          <hr className="pto-item__separate" />
          <div className="pto-item__icon">
            <BiggerVector />
          </div>
        </div>
      </label>
      <label htmlFor={`pto-item-${id}`} className="pto-item-label-movile">
        {children}
        <div className="d-flex align-items-center">
          <hr className="pto-item__separate" />
          <div className="pto-item__icon">
            <Close width="14" height="15" />
          </div>
        </div>
      </label>
      <div className="pto-item__container">
        <div className="pto-item__content">
          <div className="pto-item__delimiter">
            <div>
              <div className="pto-item__small">
                <p className="text-bold mb-1">Request Date:</p>
                <Moment format={DATE_FORMAT}>
                  {transformUTCDate(item?.createdAt)}
                </Moment>
              </div>
              <div className="pto-item__small mb-2">
                <p className="text-bold mb-1">Status:</p>
                <span
                  className={`pto-item__status ${
                    item?.status === 'PENDING'
                      ? 'pending'
                      : item?.status === 'DECLINED'
                      ? 'declined'
                      : 'approved'
                  }`}
                >
                  {item?.status === 'PENDING'
                    ? 'Pending'
                    : item?.status === 'DECLINED'
                    ? 'Declined'
                    : 'Approved'}
                </span>
              </div>
              <div className="pto-item__medium mb-2">
                <p className="text-bold mb-1">Note:</p>
                <span>
                  {item?.message ||
                    item?.note ||
                    'No additional notes were provided.'}
                </span>
              </div>
            </div>
            <div>
              <div className="pto-item__small mb-2">
                <p className="text-bold mb-1">Main Manager:</p>
                <span>
                  {item?.manager ?? (
                    <div className="py-2">
                      <NoTeam width="36" height="36" />
                      <span className="ms-1">Not assigned</span>
                    </div>
                  )}
                </span>
              </div>
              <div className="pto-item__large">
                <p className="text-bold mb-1">Teams:</p>
                <div className="teams-container d-flex flex-wrap">
                  {teams?.length ? (
                    teams?.map(({ manager, name }, i) => (
                      <div
                        className="pto-item__small py-2 d-flex align-items-center"
                        id={`requested-teams-${i}`}
                        key={`${id}-${i}`}
                      >
                        <IconProfileTeams width="36" height="36" />
                        <div className=" ms-2">
                          <p className="text-bold mb-0">{name}</p>
                          <span>
                            Manager:{' '}
                            {manager.split(' ').length === 2
                              ? manager
                              : ` ${manager.split(' ')[0]} ${
                                  manager.split(' ')[
                                    manager.split(' ').length - 2
                                  ]
                                }`}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <NoTeam width="36" height="36" />
                      <span className="ms-1">Not assigned</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DropdownState>
  );
};

export default DropdownPto;
