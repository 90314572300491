import React, { useState, useContext, useEffect, useRef } from 'react';
import { FormControl, InputGroup, Dropdown } from 'react-bootstrap';
import { AccountCircle } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router';

import Search from '../Icons/Search';
import { SearchEmployeeContainer } from './styles';
import { AppContext } from '../../Context/App/AppContext';
import { useGetEmployeesByCompanyQuery } from '../../graphql/container/query/employees';
import { getImageError } from '../../services/Constants/getDefaults';
import Persons from '../Icons/Persons';
import ArrowLeft from '../Icons/ArrowLeft';
import { routesOwner } from '../../services/Constants/paths';

const SearchEmployee = ({
  infoUser = true,
  icon = true,
  incommingClass,
  secondClass,
  searchPlaceHolder,
  dropdownClass,
  saveInformation,
  setSelectedManager,
  excludeSeparate = false,
  excludeAdmin = false,
  excludeEmployee = false,
  excludeManager = false,
  excludeOwner = false,
  currentList = [],
  arrow = false,
  setSearch,
  search,
  mobile
}) => {
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const location = useLocation();
  const { currentUser } = useContext(AppContext);
  const [listEmployees, setListEmployees] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState('');
  const [labelSearch, setlabelSearch] = useState('');
  const [query, setQuery] = useState('');
  const companyId = currentUser?.companyRole[0].to;
  const [filterOwners, setFilterOwners] = useState(false);
  const limit = 150,
    offset = 0,
    orderField = 'fullName',
    sort = 'ASC',
    offSeparated = false;

  useEffect(() => {
    if (location.pathname === routesOwner.createEditTeams()) {
      setFilterOwners(false);
    } else {
      setFilterOwners(true);
    }
    return () => {
      setlabelSearch('');
      setQuery('');
    };
  }, [location]);

  const {
    data: { getEmployeesByCompany },
    loading: loadingEmployees,
    refetch: refetchEmployees
  } = useGetEmployeesByCompanyQuery(
    companyId,
    limit,
    offset,
    searchEmployee,
    sort,
    orderField,
    offSeparated,
    filterOwners
  );

  useEffect(() => {
    if (getEmployeesByCompany) {
      const { employees } = getEmployeesByCompany;

      if (
        excludeSeparate ||
        excludeAdmin ||
        excludeEmployee ||
        excludeManager ||
        excludeOwner
      ) {
        setListEmployees(
          employees?.filter(
            el =>
              (excludeSeparate
                ? el?.status === 'SEPARATED'
                  ? false
                  : true
                : true) &&
              (excludeAdmin
                ? el?.permissionEmployee?.description === 'Admin'
                  ? false
                  : true
                : true) &&
              (excludeOwner
                ? el?.permissionEmployee?.description === 'Owner'
                  ? false
                  : true
                : true) &&
              (excludeEmployee
                ? el?.permissionEmployee?.description === 'Employee'
                  ? false
                  : true
                : true) &&
              (el?.permissionEmployee?.description ? true : false) &&
              (currentList.length > 0
                ? !currentList?.find(employee => employee.id === el?.id)
                : true)
          )
        );
      } else {
        setListEmployees(employees);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesByCompany, currentList]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchEmployee(query), 500);
    saveInformation && setSelectedManager(query);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (mobile && !search) {
      setlabelSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, search]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      refetchEmployees({
        companyId: companyId,
        limit,
        offset,
        search: searchEmployee,
        filterOwners
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmployee, setSelectedManager]);

  const handleClick = item => {
    setlabelSearch(item.fullName);
    if (saveInformation) {
      return setSelectedManager(item.id);
    } else {
      window.history.pushState(null, null, location.pathname);
      navigate(routesOwner.editEmployee(item.id), {
        state: { user: item, site: 'profile' },
        replace: true
      });
    }
  };

  return (
    (routesOwner.home.includes(location.pathname) ||
      (routesOwner.employees.includes(location.pathname) && incommingClass) ||
      location.pathname === routesOwner.createEditTeams()) && (
      <div className={incommingClass || 'motherClass'}>
        <SearchEmployeeContainer>
          <div className={dropdownClass || ''}>
            {arrow && (
              <div
                className="arrowLeft"
                onClick={() => {
                  setSearch(false);
                }}
              >
                <ArrowLeft color="white" />
              </div>
            )}
            <Dropdown>
              <Dropdown.Toggle>
                <div className="search-employees">
                  <InputGroup>
                    {icon && (
                      <InputGroup.Text id="input-employee">
                        <Search />
                      </InputGroup.Text>
                    )}
                    <FormControl
                      onChange={({ target }) => {
                        setQuery(target.value);
                        setlabelSearch(target.value);
                      }}
                      placeholder={searchPlaceHolder || 'Search employees'}
                      value={labelSearch}
                      aria-label="Search employees"
                      className="input-search"
                    />
                  </InputGroup>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu id="dropdown-list-employees">
                {!loadingEmployees ? (
                  listEmployees.length ? (
                    listEmployees
                      .filter((item, idx) => idx < 10)
                      .map((item, i) => (
                        <Dropdown.Item key={`item-employee-${i}`}>
                          <div
                            className="item-employee"
                            onClick={() => {
                              handleClick(item);
                            }}
                          >
                            <div>
                              {item.user?.photo ? (
                                <img
                                  src={item.user.photo}
                                  alt="Profile"
                                  onErrorCapture={e =>
                                    (e.target.onErrorCapture = null)(
                                      (e.target.src = `${getImageError(
                                        companyId
                                      )}`)
                                    )
                                  }
                                  referrerPolicy="no-referrer"
                                />
                              ) : (
                                <AccountCircle
                                  style={{ fontSize: 43, margin: '-3px' }}
                                />
                              )}
                            </div>
                            <div>
                              <p className="text-bold">{item.fullName}</p>
                              <p className="text-small">
                                {item.employeeRoleInfo.name}
                              </p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      ))
                  ) : (
                    <div className="no-Data">
                      <Persons color="black" size="48" />
                      <div>
                        <span className="text-normal">No data available</span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="loading-data">
                    <CircularProgress />
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* {infoUser && (
          <div className="info-user">
            <div>
              <p className="text-bold">{currentUser?.employee?.fullName}</p>
            </div>
            <div>
              {currentUser && currentUser.photo ? (
                <img
                  src={currentUser.photo}
                  onErrorCapture={e => (
                    (e.target.onErrorCapture = null),
                    (e.target.src = `${getImageError(companyId)}`)
                  )}
                  alt="Profile"
                  referrerPolicy="no-referrer"
                />
              ) : (
                <AccountCircle style={{ fontSize: 40 }} />
              )}
            </div>
          </div>
        )} */}
        </SearchEmployeeContainer>
      </div>
    )
  );
};

export default SearchEmployee;
