//Abscence
export const ABSCENCE_NOTE_LABEL = 'Note...';
export const ABSCENCE_TYPE_ABSCENCE_LABEL = 'Type';
export const ABSCENCE_START_DATE_PLACEHOLDER = 'Start Date';
export const ABSCENCE_END_DATE_PLACEHOLDER = 'End Date';
export const ABSCENCE_NOTE_ERROR = 'PTO note is required.';
export const ABSCENCE_TYPE_ABSCENCE_ERROR = 'The day off type is required.';
export const ABSCENCE_START_DATE_ERROR = 'The PTO start date is required';
export const ABSCENCE_END_DATE_ERROR = 'The PTO end date is required';
export const ABSCENCE_DATES_NOT_CORRECTED_ERROR = 'The dates are not corrected';
export const ABSCENCE_REMINDER_LABEL = 'Reminder';
export const SUCCESS_REMINDER_MESSAGE = 'a reminder had been sent';

//Abscence request
export const ABSCENCE_REQUEST_EXCEED_DAYS =
  'The date does not exceed the days in advance';
export const ABSCENCE_REQUEST_CONTAINS_USED_DAYS =
  'The date contains used days';
export const EXCEEDED_AMOUNT_OF_DAYS =
  'The date cannot be longer than the days available';
export const CHOOSE_START_AND_END_DATE =
  'You need to choose Start Date and End Date';
export const CHOOSE_START_GREATER_END_DATE =
  'You need to choose Start Date and End Date greater';
export const ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY =
  'The date contains a company holiday ';
export const YOU_CAN_NOT_REQUEST_SAME_DAY =
  'You can not request the same day you asked before';
export const YOU_CAN_NOT_UPDATE_BOOKED_DECLINED =
  'You can not update the PTO day you declined';
export const YOU_CAN_NOT_REQUEST_BOOKED_DAYS =
  'Seems like you already booked those dates';
export const YOU_DO_NOT_HAVE_DAYS_LEFT =
  'Seems like you do not have days off left';
export const YOU_CAN_NOT_REQUEST_WEEKENDS =
  'Please fix dates, remember that Saturdays, Sundays and holidays are not working days.';
export const YOU_UPDATED_THE_REGISTER = 'Upgraded successfully';
export const ABSCENCE_REQUEST_TYPE_APPLICATION_LABEL = 'Type of application';
export const ABSCENCE_REQUEST_DESCRIPTION_LABEL = 'Note';
export const ABSCENCE_REQUEST_TYPE_APPLICATION_ERROR =
  'The type off application is required.';
export const ABSCENCE_REQUEST_DESCRIPTION_ERROR =
  'The description is required.';
export const ABSCENCE_REQUEST_PLACEHOLDER = 'Type note';
export const ABSCENCE_REQUEST_DELETE = 'Your Abscence has been deleted.';

//Activity log
export const ACTIVITIY_LOG_IS_EMPTY = 'Activity log is empty for this employee';
export const ACTIVITIY_LOG_ERROR_FETCHING = 'Error fetching activity log !';

//Additional days
export const ADDITIONAL_DAYS_LIST_TITLE = 'List of additional PTO';
export const ADDITIONAL_DAYS_LIST_TABLE_HEADER = [
  'Extra Days',
  'Note',
  'Created'
];
export const ADDITIONAL_DAYS_DELETE = 'Your additional PTO has been deleted.';
export const ADDITIONAL_DAYS_TITLE_FORM = 'Add PTO for employee';
export const ADDITIONAL_DAYS_DAYS_OFF_LABEL = 'Days';
export const ADDITIONAL_DAYS_NOTE_LABEL = 'Note';
export const ADDITIONAL_DAYS_DAYS_OFF_ERROR = 'The PTO is required.';
export const ADDITIONAL_DAYS_NOTE_ERROR = 'The note is required.';
export const NO_ADDITIONAL_DAYS_OFF = 'No Additional PTO added';

//Alert
export const REVERT_THIS = "You won't be able to revert this!";
export const ARE_YOU = 'Are you sure?';
export const CANCEL_BUTTON_TEXT = 'No, cancel!';
export const CONFIRMATION_BUTTON_TEXT = 'Yes, delete it!';
export const ALERT_DELETE_TITLE = 'Deleted!';
export const ROLE_EXIST =
  'You cannot create a role with the name of an existing role.';

//Bonus
export const BONUS_NOTE_LABEL = 'Note';
export const BONUS_AMOUNT_LABEL = 'Amount';
export const BONUS_TYPE_LABEL = 'Type';
export const BONUS_NOTE_ERROR = 'The bonus note is required.';
export const BONUS_AMOUNT_ERROR = 'The bonus amount is required.';
export const BONUS_TYPE_ERROR = 'The bonus type is required.';
export const BONUS_DELETE = 'Your Bonus has been deleted.';
export const DATE_LABEL = 'Date';
export const ERROR_BONUS = 'ERROR';
export const BONUS_YES_CONFIRMATION = 'Yes, Confirm';
export const BONUS_NO_CHANGE = 'No, Change';
export const BONUS_TABLE_HEADER = ['Date', 'Type', 'Amount', 'Note'];

//Breadcrumbs
export const BREADCRUMBS_SETTINGS = 'Settings';
export const BREADCRUMBS_ASSETS = 'Company Assets';
export const BREADCRUMBS_PTO = 'PTO';
export const BREADCRUMBS_PROFILE = 'Profile';

//PTO
export const TO = 'to';
export const REVIEW = 'Review';
export const LAST_EDIT_ON = 'Last edit on';
export const ACCEPT = 'Accept';
export const DECLINE = 'Decline';
export const ERROR_DAYS_OFF =
  'Error updating absence request (check that dates do not exceed available days)';
export const MY_PTO_TITLE = 'My PTO';
export const NOT_PTO_TITLE = '0 Days Used';

//Team
export const TEAM_EMAIL_PLACEHOLDER = 'Type email';
export const SEND_INVITE = 'Assign Admin';
export const TEAM_FORM_LIST_TABLE_HEADER = ['Name', 'Role', 'Email'];

//Toast
export const NO_NEW_DATA = 'No new data was entered.';
export const YOUR_CHANGES_BEEN_SAVED = 'Your changes have been saved.';

//Onboarding Checklist
export const ADD_ITEM_TITLE = 'Add Item to Onboarding Checklist';
export const SELECT_ROLE = 'Select Role';
export const ADD_ITEM = 'Add Item';
export const NO_ITEMS = 'No items';

//Company
export const COMPANY_LIST_TITLE = 'Companies list';
export const COMPANY_lOADING = 'Process...';
export const COMPANY_FORM_EDIT_TITLE = 'Add Documents and Forms';
export const COMPANY_FORM_LIST_TITLE = 'Documents and Forms';
export const UPLOAD_LOGO = 'Upload Logo';
export const COMPANY_FORM_LIST_TABLE_HEADER = [
  'icon',
  'Description',
  'Url',
  'Type'
];
export const CREATE_COMPANY_STEPS_NAVBAR = [
  'Create Company',
  'Add Roles',
  'Add employee'
];
export const COMPANY_ERROR_lOGO =
  'Incorrect file format, allowed: jpg, jpeg, png or gif';
export const STEP_ONE_TITLE = 'General Information';
export const COMPANY_NAME_LABEL = 'Name Company';
export const COMPANY_NAME_PLACEHOLDER = 'Enter your company name';
export const COMPANY_INDUSTRY_PLACEHOLDER = 'Your industry';
export const COMPANY_INDUSTRY_LABEL = 'Industry';
export const COMPANY_EMAIL_LABEL = 'Company Email';
export const COMPANY_EMAIL_PlACEHOLDER = 'info@hellobuild.co';
export const COMPANY_WEBSITE_LABEL = 'Website';
export const COMPANY_URL_PLACEHOLDER = 'Hellobuild.co';
export const COMPANY_LINKEDIN_PLACEHOLDER = 'Hellobuild';
export const COMPANY_LINKEDIN_LABEL = 'LinkedIn';
export const COMPANY_TWITTER_LABEL = 'Twitter';
export const COMPANY_TWITTER_PLACEHOLDER = 'Username';
export const COMPANY_COUNTRY_LABEL = 'Country';
export const COMPANY_CITY_LABEL = 'City';
export const COMPANY_LOGO_PLACEHOLDER = 'Logocompany.png';
export const COMPANY_NAME_ERROR = 'The company name is required.';
export const COMPANY_LOGO_ERROR = 'The company logo is required.';
export const COMPANY_FORM_CREATE_TITLE = 'PTO';
export const COMPANY_CREATE = 'Create Company';
export const DELETE_LOGO = 'Delete Logo';
export const EDIT_COMPANY = 'Edit Company';
export const EXAMPLE = 'Example';
export const COMPANY_ADDRESS = 'Address';
export const COMPANY_ADDRESS2 = 'Address Line 2';
export const COMPANY_SLOGAN = 'Slogan';
export const COMPANY_SLOGAN_DESCRIPTION = 'Your slogan';
//Dashboard
export const TEAMONBOARD = 'TeamOnBoard';
export const WELCOME_TO_TEAMONBOARD = 'Welcome To TeamOnBoard';
export const GO_TO_DASHBOARD = 'Go to Dashboard';
export const TOTAL_EMPLOYEES = 'Total employees';
export const NEW_HIRES = 'New hires last month';
export const JOBS_OPENINGS = 'Jobs openings';
export const HIRED_IN_LAST_FEW_MONTHS = 'Hiring Pipeline';
export const HAVE_NOT_ADDED_EMPLOYEES = "You haven't added employees";
export const NO_HIRING_3_MONTHS = 'No Hiring in 3 Months';
export const NO_HIRING_5_MONTHS = 'No Hiring in 5 Months';
export const ROLES = 'Roles';
export const REPORTED_AS_SICK = 'Sick';
export const NO_SICK_EMPLOYEES = 'No sick employees';
export const HIRED_IN_LAST_3_MONTHS = 'Last Hired';
export const HOLIDAY_EMPLOYEES = 'Holiday Employees';
export const OUT_ON_PTO = 'PTO';
export const MY_PTO = 'My PTO';
export const TEAM_PTO = 'Team PTO';
export const NO_OUT_ON_PTO = "There's no employee out";
export const START_TEAMONBOARD = 'Start your arrival at TeamOnBoard';

export const NO_HOLIDAY_EMPLOYEES = 'No holiday employees';
export const UPCOMING_WORK_ANNIVERSARIES = 'Work Anniversaries';
export const NO_ANNIVERSARIES = 'No anniversaries';
export const AVERAGE_SALARY = 'Compensation';
export const UPCOMING_BIRTHDAYS = 'Birthdays';
export const NO_BIRTHDAYS = 'No birthdays employees';

//search Employee
export const EMPLOYEE_QUICK_SEARCH = 'Employee Quick Search';
export const SEARCH_EMPLOYEE_PLACEHOLDER = 'Search...';
export const SEARCH_BUTTON = 'Search';

//Select fields table
export const FIELDS_LABEL = 'Fields';
export const HIDE_ALL = 'Hide All';
export const VIEW_ALL = 'View All';
export const SORT_FIELD = { iconShow: -1, field: '' };
export const WIDTH_TABLE_PERCENTAGE = 88;
export const WIDTH_SEVEN_PERCENTAGE = 12;

//Settings
export const MEMBERS_LABEL = 'Members of this company can edit and manage';
export const GO_TEAM = 'Go to the Team';
export const CONNECT_GOOGLE = 'Connected to Google';
export const ADMINS = 'Admins';
export const COMPANY_ASSETS = 'Assets';
export const ASSETS_LABEL = 'Assets';
export const DOCUMENT_AND_ROLES_LABEL = 'Files';
export const ROLES_LABEL = 'Roles';
export const COUNTRIES_LABEL = 'Countries';
export const COUNTRIES_WHERE_LABEL = 'Countries';
export const SOFTWARE_OR_PERMISSIONS_LABEL = 'External Tools';
export const ADD_SOFTWARE_PERMISSIONS =
  'Add software or services that require permissions';
export const ACTIVE_LABEL = 'Active';
export const TASK_LABEL = 'Tasks';
export const INACTIVE_LABEL = 'Inactive';
//Assets
export const ASSETS_LIST_TABLE_HEADER = [
  { label: 'Brand', visible: true, field: 'brand' },
  { label: 'Type', visible: true, field: 'type' },
  { label: 'Item', visible: false, field: 'itemDescription' },
  { label: 'Year', visible: false, field: 'year' },
  { label: 'Chip', visible: true, field: 'chip' },
  { label: 'Ram', visible: true, field: 'ram' },
  { label: 'Disk', visible: false, field: 'disk' },
  { label: 'Purchase Date', visible: false, field: 'datePurchased' },
  { label: 'Loan Date', visible: false, field: 'loanDate' },
  { label: 'Cost', visible: false, field: 'price' },
  { label: 'User', visible: true, field: 'employeeAssigned' },
  { label: 'Grant Date', visible: true, field: 'grantDate' },
  { label: 'Serial', visible: false, field: 'serial' },
  { label: 'Name', visible: false, field: 'name' },
  { label: 'Notes', visible: false, field: 'details' }
];
export const ADD_ASSETS = 'Add Assets';
export const EDIT_ASSETS = 'Edit Assets';
export const TITLE_COMPANY_ASSETS = 'Company Assets';
export const ASSETS_BRAND = 'Brand';
export const ASSETS_DESCRIPTION = 'Item Description';
export const ASSETS_DETAILS = 'Note';
export const ASSETS_PRICE = 'Cost';
export const ASSETS_DATE = 'Purchased Date';
export const ASSETS_LOAN_DATE = 'Loan Date';
export const ASSETS_GRANT_DATE = ' Grant Date';
export const ASSETS_YEAR = 'Year';
export const ASSETS_SERIAL = 'Serial';
export const ASSETS_NAME = 'Name';
export const ASSETS_ASSIGNED = 'User';
export const ASSETS_CHIP = 'Chip';
export const ASSETS_RAM = 'Ram';
export const ASSETS_DISK = 'Disk';
export const ASSETS_TYPE = 'Type';
export const ASSETS_PURCHASED_DATE_ERROR = 'The purchased date is required.';
export const ASSETS_PRICE_ERROR = 'The price is required.';
export const ASSET_DELETE = 'The Asset has been deleted.';
export const TITLE_ASSET_DELETE = 'Delete Asset';
export const DESCRIPTION_ASSET_DELETE =
  'if you do this all your data will be erased and you will not be able to recover it.';
export const ONBOARDING = 'Onboarding Tasks';

//PTO
export const SELECT_DATE = 'Select date';
export const SICK = 'Sick';
export const USED = 'Used';
export const OUT_HEALTH = 'Out sick';
export const AVAILABLE = 'Available';
export const ADD_DAYS = 'Add Days';
export const ALL_FIELDS_REQUIRED = 'All fields are required';
export const NOT_ENOUGH_DAYS_LEFT = 'No PTO left';
export const DAYS_OFF = 'PTO';
export const DAYS_ = 'PTO';
export const DAYS_WEEK = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

//Document signed
export const DOCUMENTS = 'Documents';
export const HR_DOCUMENTS = 'HR Documents';
export const HR_FORMS = 'HR Forms';
export const DOWNLOAD_DOCUMENT = 'Download document';
export const UPLOAD_DOCUMENTS = 'Upload Documents';
export const NO_DOCUMENTS_ADDED = 'No document added';
export const DOCUMENT_FORM_FILE_NAME_LABEL = 'File Name';
export const DOCUMENT_FORM_FILE_NAME_PLACEHOLDER = 'Input name';
export const DOCUMENT_FORM_GOOGLE_DRIVE_LINK_LABEL = 'Google Drive Link';
export const DOCUMENT_FORM_GOOGLE_DRIVE_LINK_PLACEHOLDER = 'Input name';
export const DOCUMENT_FORM_SEARCH_LABEL = 'Search';
export const DOCUMENT_FORM_FILTER_TYPE_LABEL = 'Filter Type';
export const DOCUMENT_OPTION_TYPE = ['Doc', 'Sheet', 'Slide', 'Other'];
export const DOCUMENT_TITLE_UPLOAD_BY = 'Uploaded by';
export const DOCUMENT_TITLE_UPLOAD_FILE = 'Upload File';
export const DOCUMENT_TITLE_GOOGLE_DRIVE = 'Google Drive';
export const DOCUMENT_TITLE_NEW = 'New';
export const DOCUMENT_TITLE_NO_FILES = 'No Files';

//Employee
export const PERSONAL_INFORMATION_TITLE = 'Personal Information';
export const GUION = '-';
export const EMPLOYEE_FIRST_NAME_PLACEHOLDER = 'Input name';
export const EMPLOYEE_DOCUMENT_NUMBER_PLACEHOLDER = 'Only numbers';
export const EMPLOYEE_EMAIL_COMPANY_PLACEHOLDER = '@hellobuild.co';
export const EMPLOYEE_PERSONAL_EMAIL_PLACEHOLDER = 'example@email.com';
export const EMPLOYEE_PHONE_PLACEHOLDER = '000 000 00 00';
export const INIT_PHONE_NUMBER = ['+57', '+32'];
export const EMPLOYEE_T_SHIRT_LABEL = 'T-shirt Size';
export const EMPLOYEE_T_SHIRT_SELECT = ['S', 'M', 'L', 'XL'];
export const EMPLOYEE_SALARY_PLACEHOLDER = '1500';
export const EMPLOYEE_BANKING_INFORMATION_LABEL = 'Banking Information:';
export const EMPLOYEE_BANK_NUMBER_LABEL = 'Bank Account Number';
export const EMPLOYEE_BANK_NUMBER_PLACEHOLDER = '000 0000 000';
export const EMPLOYEE_BANK_ACCOUNT_NUMBER_LABEL = 'Account Number';
export const EMPLOYEE_BANK_NAME_LABEL = 'Bank Name';
export const EMPLOYEE_BANK_NAME_PLACEHOLDER = 'Example: Bank of America';
export const EMPLOYEE_SWIFT_CODE_LABEL = 'SWIFT Code';
export const EMPLOYEE_BANK_ROUTING_NUMBER_LABEL = 'Routing Number';
export const EMPLOYEE_BANK_ADDRESS_LABEL = 'Bank Address';
export const EMPLOYEE_ADDRESS_PLACEHOLDER = 'Ej: Casa 3 - 6';
export const EMPLOYEE_BANK_STATE_LABEL = 'Bank State/Department/Province';
export const EMPLOYEE_BANK_STATE_PLACEHOLDER = 'Ej: Bogotá';
export const EMPLOYEE_BANK_POSTAL_CODE_LABEL = 'ZIP/Postal Code';
export const EMPLOYEE_BANK_CITY_LABEL = 'Bank City';
export const EMPLOYEE_CITY_PLACEHOLDER = 'City';
export const EMPLOYEE_BANK_LABEL = 'Bank';
export const MARK_AS_SIGNED = 'Mark as signed';
export const SIGNED = 'Signed';
export const VALIDATE_EMAIL = 'Please validate your email format';
export const EMPLOYEE_BIRTH_DATE_ERROR = 'The employee birth date is required.';
export const EMPLOYEE_FILE_ERROR = 'The employee must have at least one file.';
export const EMPLOYEE_START_DATE_ERROR = 'The employee start date is required.';
export const EMPLOYEE_FIRST_NAME_LABEL = 'First Name';
export const EMPLOYEE_MIDDLE_NAME_LABEL = 'Middle Name';
export const EMPLOYEE_LAST_NAME_LABEL = 'Last Name';
export const EMPLOYEE_MOTHERS_MAIDEN_NAME_LABEL = 'Mothers Maiden Name';
export const EMPLOYEE_ROLE_LABEL = 'Role';
export const EMPLOYEE_EMAIL_LABEL = 'Company Email';
export const EMPLOYEE_EMPLOYMENT_TYPE_LABEL = 'Employment Type';
export const EMPLOYEE_SCHEDULE_LABEL = 'Schedule';
export const EMPLOYEE_SALARY_LABEL = 'Salary';
export const EMPLOYEE_ID_DOCUMENT_LABEL = 'Document Type';
export const EMPLOYEE_DOCUMENT_NUMEBER_LABEL = 'ID (National ID or tax ID)';
export const EMPLOYEE_COUNTRY_LABEL = 'Country';
export const EMPLOYEE_ADDRESS_ONE_LABEL = 'Address';
export const EMPLOYEE_ADDRESS_TWO_LABEL = 'Address Line 2';
export const EMPLOYEE_CITY_LABEL = 'City';
export const EMPLOYEE_STATE_LABEL = 'State';
export const EMPLOYEE_STATE_PLACEHOLDER = 'Type the state';
export const EMPLOYEE_ZIP_CODE_LABEL = 'Zip/Postal Code';
export const EMPLOYEE_ZIP_CODE_PLACEHOLDER = 'Input name';

export const EMPLOYEE_START_DATE_PLACEHOLDER = 'Start Date';
export const EMPLOYEE_DATE_OF_BIRTH_PLACEHOLDER = 'Birthday';
export const EMPLOYEE_PERSONAL_EMAIL_LABEL = 'Personal Email';
export const EMPLOYEE_PHONE_LABEL = 'Phone';
export const EMPLOYEE_FIRST_NAME_REQUIRED_ERROR =
  'The employee first name is required.';
export const EMPLOYEE_FIRST_NAME_NOT_SPACES_ERROR =
  'Invalid employee first name (not spaces allowed).';
export const EMPLOYEE_LAST_NAME_REQUIRED_ERROR =
  'The employee last name is required.';
export const EMPLOYEE_LAST_NAME_NOT_SPACES_ERROR =
  'Invalid employee last name (not spaces allowed).';
export const EMPLOYEE_ROLE_ERROR = 'The employee role is required.';
export const EMPLOYEE_COUNTRY_ERROR = 'The employee country is required.';
export const EMPLOYEE_SALARY_ERROR = 'The employee salary is required.';
export const EMPLOYEE_CITY_ERROR = 'The employee city is required.';
export const CHANGE_EMAIL = 'Choose a different address';
export const EMPLOYEE_PERSONAL_EMAIL_REQUIRED_ERROR =
  'The employee personal email is required.';
export const EMPLOYEE_CITY_REQUIRED_ERROR = 'The city is required.';
export const EMPLOYEE_STATE_REQUIRED_ERROR = 'The state is required.';
export const EMPLOYEE_PERSONAL_EMAIL_INVALID_ERROR =
  'Invalid employee personal email.';
export const EMPLOYEE_PHONE_NUMBER_ERROR =
  'The employee phone number is required.';
export const EMPLOYEE_DOCUMENTS_TITLE = 'HR Documents';
export const EMPLOYEE_PERSONAL_TITLE = 'Personal';
export const EMPLOYEE_FORMS_TITLE = 'HR Forms';
export const EMPLOYEE_NOT_FOUND = 'Employee not found';
export const EMPLOYEE_DELETE = 'Your employee has been deleted.';
export const USER_PHOTO_DELETE = 'Your photo has been deleted.';
export const USER_ERROR_PHOTO =
  'Incorrect file format, allowed: jpg, jpeg, png or gif';
export const TITLE_EMPLOYEE_DELETE = 'Delete employee';
export const DESCRIPTION_EMPLOYEE_DELETE =
  'By deleting this profile the data will be deleted and it will not be possible to recover it.';
export const EMPLOYEE_LIST_TABLE_HEADER = [
  { label: 'Name', visible: true, field: 'name' },
  { label: 'Role', visible: true, field: 'employeeRole.name' },
  { label: 'Start Date', visible: true, field: 'startDate' },
  { label: 'Status', visible: true, field: 'status' },
  { label: 'Company Email', visible: false, field: 'username' },
  {
    label: 'Personal Email',
    visible: false,
    field: 'contactInformation.personalEmail'
  },
  { label: 'Employment Type', visible: false, field: 'employmentType' },
  { label: 'Schedule', visible: false, field: 'schedule' },
  { label: 'Salary', visible: false, field: 'salary' },
  {
    label: 'National ID',
    visible: false,
    field: 'contactInformation.documentId'
  },
  { label: 'Document', visible: false, field: 'contactInformation.idType' },
  { label: 'Country', visible: false, field: 'contactInformation.country' },
  { label: 'State', visible: false, field: 'contactInformation.state' },
  { label: 'City', visible: false, field: 'contactInformation.city' },
  {
    label: 'Postal Code',
    visible: false,
    field: 'contactInformation.postalCode'
  },
  { label: 'Address', visible: false, field: 'contactInformation.addressOne' },
  {
    label: 'Address 2',
    visible: false,
    field: 'contactInformation.addressTwo'
  },
  { label: 'Phone', visible: false, field: 'contactInformation.phone' },
  { label: 'Birthday', visible: false, field: 'dateOfBirth' },
  { label: 'T-shirt Size', visible: false, field: 'tShirt' },
  { label: 'Permissions', visible: true, field: 'systemsEnabled' }
];
export const EMPLOYEES = 'Employees';
export const ADD_EMPLOYEE = 'Add Employee';
export const UPDATE_EMPLOYEE = 'Update Employee';
export const EDIT_EMPLOYEE = 'Edit Employee';
export const LIMIT_PAGE = ['50', '40', '20', '10'];
export const EMPLOYEE_FILES_LIST_TABLE_HEADER = ['Name', 'Uploaded', 'Date'];
export const EMPLOYEE_FILES_LIST_FILTER = [
  'Doc',
  'Sheet',
  'Presentation',
  'Pdf',
  'Other'
];
export const DEFAULT_BANK = 'Bancolombia';
export const OTHER_BANK = 'Other';
export const NOT_EDITABLE_FIELD = 'You can not edit this field';
//Separate employee
export const EMPLOYEE_ACTIVATE = 'Activate';
export const EMPLOYEE_REHIRED = ['No', 'Yes'];
export const EMPLOYEE_INACTIVE_TITLE = 'Inactive Employee';
export const EMPLOYEE_INACTIVE_SUBTITLE = 'Employee will start on';
export const EMPLOYEE_ACTIVE_TITLE = 'Active Employee';
export const EMPLOYEE_ACTIVE_SUBTITLE = 'This Employee is active from';
export const EMPLOYEE_SEPARATION_PENDING = 'This employee will be separated on';
export const EMPLOYEE_SEPARATED_SUBTITLE = 'This Employee is separated from';
export const EMPLOYEE_SEPARATE_TITLE = 'Separate Employee';
export const EMPLOYEE_SEPARATION_DATE = 'Separation Date';
export const EMPLOYEE_SEPARATION_CANCEL = 'Cancel';
export const EMPLOYEE_SEPARATE_ELEGIBLE = 'Elegible for rehire?';
export const EMPLOYEE_SEPARATE_CONFIRMATION = 'Does everything look right?';
export const EMPLOYEE_SEPARATE_AVAILABLE = 'Available to be hired';
export const EMPLOYEE_CANNOT_BE_REHIRED = 'Cannot be rehired';
export const EVERYTHING_LOOK = 'Does everything look right?';
export const SEPARATION_CANCEL_BUTTON = 'No';
export const SEPARATION_CONFIRMATION_BUTTON = 'Yes, Confirm';
export const SEPARATION_EMPLOYEE = 'Employee';
export const SEPARATION_DATE = 'Separation date';
export const SEPARATION_DELETE = 'The separation has been deleted.';
export const EMPLOYEE_WAS_SEPARATED = 'was separated from the company';

//Views employee
export const EMPLOYEE_PROFILE_EMAIL = 'Email Build';
export const EMPLOYEE_PROFILE_SCHEDULE = 'Schedule';
export const EMPLOYEE_PROFILE_EMPLOYMENT_TYPE = 'Employment Type';
export const EMPLOYEE_PROFILE_PERSONAL_EMAIL = 'Personal Email';
export const EMPLOYEE_PROFILE_PHONE = 'Phone';
export const EMPLOYEE_PROFILE_ADDRESS_ONE = 'Address One';
export const EMPLOYEE_PROFILE_ADDRESS_TWO = 'Address Two';
export const EMPLOYEE_PROFILE_COUNTRY = 'Country';
export const EMPLOYEE_PROFILE_CITY = 'City';
export const EMPLOYEE_PROFILE_STATE = 'State';
export const EMPLOYEE_PROFILE_POSTAL_CODE = 'Postal Code';

//File
export const UPLOAD_FILE = 'Upload';
export const ADD_FILE = 'Add File';
export const DROP_FILE_HERE = 'drop files here';
export const FILE_DELETE = 'Your file has been deleted.';
export const LABEL_FILE_SIZE_LIMIT = 'Maximum file size is 4.5 MB';
export const NUMBER_FILE_SIZE_LIMIT = 4.5;

//Form Model
export const FORM_URL_LABEL = 'Url form or document';
export const FORM_DESCRIPTION_LABEL = 'Description';
export const FORM_DESCRIPTION_PLACEHOLDER = 'Name of form or Document';
export const FORM_TYPE_LABEL = 'Type';
export const FORM_ICON_LABEL = 'Icon';
export const FORM_URL_ERROR = 'The url is required.';
export const FORM_DESCRIPTION_ERROR = 'The description is required.';
export const FORM_TYPE_ERROR = 'The type is required.';
export const FORM_ICON_ERROR = 'The icon is required.';
export const FORMS_EDIT = 'Edit Forms';
export const FORMS_DELETE = 'Form deleted.';
export const NO_FORMS_ADDED = 'No form added';
export const NO_DOCUMENTS_OR_DOCUMENTS_ADDED = 'No form or document added';

//Swal alert
export const INFO = 'Info';

//Navbar
export const HOME = 'Home';
export const NABVAR_ITEM_DASHBOARD = 'Dashboard';
export const NABVAR_ITEM_ADMIN = 'Admin';
export const NABVAR_ITEM_MY_DASHBOARD = 'My Dashboard';
export const NABVAR_ITEM_JOBS = 'Jobs';
export const NABVAR_ITEM_EMPLOYEES = 'Employees';
export const NABVAR_ITEM_MY_PROFILE = 'My Profile';

//Menu
export const SIGN_OUT = 'Sign out';
export const SETTINGS = 'Company Settings';

//Notification
export const NO_NOTIFICATIONS_AVAILABLE = 'No notifications available';
export const NO_NOTIFICATIONS = 'No Notifications';
export const NOTIFICATIONS_SETTING_TITLE = 'Notifications Settings';
export const NOTIFICATIONS_SETTING_DESCRIPTION =
  'What Notifications You Receive via email';
export const NOTIFICATIONS_TITLE = 'Notifications';
export const NOTIFICATIONS_MARK_ALL_READ_TITLE = 'Mark all as read';
export const NOTIFICATIONS_PERSONAL_TITLE = 'Personal';
export const NOTIFICATIONS_COMPANY_TITLE = 'Company';
export const NOTIFICATIONS_VIEW_ALL_TITLE = 'View All Notifications';

//Note
export const ADD_NOTE = 'Add';
export const NEWEST = 'Newest';
export const NOTES = 'Note';
export const NO_NOTES = 'No Notes';
export const NOTE_DELETE_MESSAGE = 'Your Note has been deleted.';
export const NOTE_NOTE_LABEL = 'Add Note';
export const NOTE_NOTE_ERROR = 'The employee note text is required.';

//Permission
export const PERMISSION_DENIED_TITLE = 'You need permission.';
export const ACCESS_REQUEST_SUBMITTED = 'Access request submitted';
export const ACCESS_REQUEST_DESCRIPTION =
  'We will confirm your request with an email.';
export const PERMISSION_DENIED_DESCRIPTION_1 =
  'Ask an administrator to give you permissions to access your account';
export const PERMISSION_DENIED_DESCRIPTION_2 = 'or switch your account.';
export const PERMISSION_DENIED_REQUEST = 'Request Access';
export const PERMISSIONS = 'Permissions';

//Profile Admin
export const ADMIN_EMPLOYEE_PROFILE_NAV = [
  'Profile',
  'Activity',
  'Files',
  'Salary',
  'PTO',
  'Notes'
];

//Profile Employee
export const VIEW_PROFILE = 'View Profile';
export const EMPLOYEE_PROFILE_NAV = ['Profile', 'PTO', 'Files'];
export const EDIT_PROFILE = 'Edit profile';
export const Hi = 'Hi';

//Edit Photo Profile
export const DELETE_PHOTO = 'Delete Photo';
export const SIZE_PHOTO = 'JPG or PNG Max size 1mb';
export const UPLOAD_PHOTO = 'Upload Photo';
export const UPLOAD_NEW_PHOTO = 'Upload New Photo';
export const UPLOAD_PHOTO_PLACEHOLDER = 'no file selected';

//Role
export const DEFINE_ROLES = 'Define Roles';
export const ROLE = 'Role';
export const ROLE_ADD = 'Add';
export const ROL_NAME_PLACEHOLDER = 'Rol name';
export const ROLE_DELETE = 'Role deleted.';
export const ROLE_ADMIN_DELETE = 'Admin deleted.';
export const ROLE_FORM_TITLE = 'Add Roles';
export const ROLE_NAME_LABEL = 'Role name';
export const ROLE_LOW_SALARY_LABEL = 'Low Salary';
export const ROLE_LOW_SALARY_PLACEHOLDER = 'Example: $500';
export const ROLE_HIGH_SALARY_LABEL = 'High Salary';
export const ROLE_HIGH_SALARY_PLACEHOLDER = 'Example: $3000';
export const ROLE_MID_SALARY_LABEL = 'Mid Salary';
export const ROLE_MID_SALARY_PLACEHOLDER = 'Example: $1500';
export const ROLE_NAME_ERROR = 'The role name is required.';
export const ROLE_LOW_SALARY_ERROR = 'The low salary is required.';
export const ROLE_HIGH_SALARY_ERROR = 'The high salary is required.';
export const ROLE_MID_SALARY_ERROR = 'The mid salary is required.';
export const ROLE_LIST_TITLE = 'Roles';
export const ROLE_LIST_TABLE_HEADER = ['Role name', 'Low', 'High', 'Mid'];
export const MANAGE_ROLES = 'Manage Roles';
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const NO_ROLES_ADDED = 'No roles';
export const EDIT_ROLES = 'Edit Roles';
export const AVAILABLE_ROLES = 'Roles';

//Salary
export const SALARY_RANGE = ['Top', 'Average', 'Bottom'];
export const SALARY = 'Salary';

//Sign in
export const SIGN_GOOGLE = 'Sign in with Google';
export const WELCOME = 'Welcome';

//Shared
export const ADD = 'Add';
export const DONE = 'Done';
export const ADD_TO = 'Add to';
export const ADD_NEW = 'Add new';
export const BEFORE = 'Before';
export const CANCEL = 'Cancel';
export const SAVE = 'Save';
export const SKIP = 'Skip';
export const UPDATE = 'Update';
export const SINCE = 'Since';
export const SORT_BY = 'Sort by';
export const SUBMIT_REQUEST = 'Submit request';
export const NEXT = 'Next';
export const CREATED_AT = 'Created at';
export const EDIT = 'Edit';
export const ERROR = 'Error';
export const LOADING = 'loading ...';
export const BACK = 'Back';
export const SELECT_FILE = 'Select File';
export const EXPORT = 'Export';
export const YOU = 'You';

//Setup
export const SETUP_CREATE_COMPANY = 'Create Company';
export const SETUP_DEFINE_ROLES = 'Define Roles';
export const SETUP_CONFIGURE_PERMISSIONS = 'Configure software permissions';
export const SETUP_PTO = 'Define time off rules';
export const SETUP_COUNTRIES = 'Select countries';
export const SETUP_EMPLOYEE = 'Create first employee';
export const SETUP_CLOSE = 'Close setup guide?';
export const SETUP_DESCRIPTION_CLOSE =
  'It will not show again. You can complete the tasks in the Settings.';
export const SETUP_BUTTON_NO = 'No, Cancel';
export const SETUP_BUTTON_YES = 'Yes, Close';

//Step Default Values
export const THESE_FIELDS_ARE_NOT_REQUIRED =
  'These fields are not required, you can do it later or use by default.';

//Select Country
export const SELECT_CONTRY_TITLE =
  'In which countries does your company operate?';

//Time Off rule
export const TIME_OFF_RULES_EDIT = 'Edit PTO';
export const TIME_OFF_RULES_LABEL_VIEW = 'PTO Rules';
export const TIME_OFF_RULES_LABEL = 'Number Days';
export const TIME_OFF_RULES_DAYS_ADVANCE_TITLE = 'Min Advance Days';
export const TIME_OFF_RULES_DAYS_ADVANCE_TITLE_LARGE =
  'Min. days in advance to request PTO';
export const DESCRIPTION_TIME_OFF_RULES_DAYS_ADVANCE_TITLE =
  'This is the number of days a team member needs to have in advance of any time off request.';
export const TIME_OFF_RULES_INITIAL_DAYS_LABEL = 'Initial PTO in a year';
export const DESCRIPTION_TIME_OFF_RULES_INITIAL_DAYS_LABEL =
  'This is the number of PTO all team members get on the first day of the year.';
export const TIME_OFF_RULES_DAYS_MONTH_LABEL = 'Days Per Month';
export const TIME_OFF_RULES_ROLL_OVER_DAYS_LABEL = 'Roll Over Days per year';

export const TIME_OFF_RULES_ROLL_OVER_DAYS_DESCRIPTION =
  'This is the number of days a team member can roll over from one year to the next.';
export const TIME_OFF_RULES_DAY_PER_MONTH_LABEL = 'Accrued Days per Month';
export const TIME_OFF_RULES_DAY_PER_MONTH_DESCRIPTION =
  'This is the number of PTO a team member accrues per month.';

export const TIME_OFF_RULES_DAYS_ADVANCE_ERROR =
  'The days in advance are required.';
export const TIME_OFF_RULES_INITIAL_DAYS_ERROR = 'The total PTO are required.';
export const TIME_OFF_RULES_DAYS_MONTH_ERROR =
  'The days per month are required.';
export const TIME_OFF_RULES_ROLL_OVER_DAYS_ERROR =
  'The roll over days  per year are required';
export const EDIT_TIME_OFF_RULE = 'PTO';

//Url component
export const URLPLACEHOLDER = 'https://';
export const LINKEDINPLACEHOLDER = 'https://www.linkedin.com/company/';
export const TWITTERPLACEHOLDER = 'https://www.twitter.com/';

export const CITIES = {
  CO: ['Armenia', 'Bogotá', 'Pereira', 'Medellín'],
  US: ['Miami']
};

//Page not found
export const PAGE_NOT_FOUND = 'Page not found';
export const GO_BACK = 'Go Back';

//403
export const PERMISSION_DENIED_TITLE_403 = 'Permission denied';
export const PERMISSION_DENIED_DESCRIPTION_403 =
  'You do not have permission to access this page.';

//graphQL Errors

export const CONTEXT_CREATION_FAILED_TOKEN =
  'Context creation failed: invalid token';
export const CONTEXT_CREATION_FAILED_DENIED =
  'Context creation failed: Access denied';
export const JWT_EXPIRED = 'Context creation failed: jwt expired';

//Calendar

export const FULL_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const YEAR = new Date().getFullYear() + 1;
export const START = new Date().getFullYear();
