import moment from 'moment';
import { DATE_FORMAT } from '../../../services/Constants/getDefaults';

export const personal = [
  {
    title: 'ID (National ID or Tax ID)',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'documentId',
    placeholder: '123456789',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Address',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'addressOne',
    placeholder: 'Adress Line 1',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Address Line 2',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'addressTwo',
    placeholder: 'Adress Line 2',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'City',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'city',
    placeholder: 'City',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Country',
    edit: 'list',
    table: 'contactInformation',
    field: 'country',
    placeholder: 'Select  ▾',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'State',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'state',
    placeholder: 'Enter state',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Zip/Postal Code',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'postalCode',
    placeholder: 'Enter Zip',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Nationality',
    edit: 'list',
    table: 'contactInformation',
    field: 'nationality',
    placeholder: 'Select  ▾',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Birthday',
    edit: 'date',
    table: 'employee',
    field: 'dateOfBirth',
    placeholder: 'MM/DD/YYYY',
    value(obj) {
      return obj[this.field]
        ? moment(obj[this.field])
          .utc()
          .format(DATE_FORMAT)
        : '-';
    }
  },
  {
    title: 'T-Shirt size',
    edit: 'list',
    table: 'customInformation',
    field: 'value',
    placeholder: 'Select  ▾',
    value(obj) {
      return obj[this.table][this.field];
    }
  }
];

export const contact = [
  {
    title: 'Email Company',
    edit: 'textbox',
    table: 'user',
    field: 'email',
    type: 'email',
    placeholder:'email@company.com',
    value(obj) {
      return obj[this.table][this.field];
    },
    check(text, reg) {
      return reg.test(text);
    }
  },
  {
    title: 'Personal Email',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'personalEmail',
    type: 'email',
    placeholder:'personal@mail.com',
    value(obj) {
      return obj[this.table][this.field];
    },
    check(text, reg) {
      return reg.test(text);
    }
  },
  {
    title: 'Phone',
    edit: 'textbox',
    table: 'contactInformation',
    field: 'phone',
    type: 'text',
    placeholder:'+00 000 000 0000',
    value(obj) {
      return obj[this.table][this.field];
    }
  }
];

export const banking = [
  {
    title: 'Bank Name',
    edit: 'textbox',
    table: 'paymentInformation',
    field: 'bankName',
    placeholder: 'bankName',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Account Number:',
    edit: 'textbox',
    table: 'paymentInformation',
    field: 'bankAccount',
    placeholder: '000 000 000 000',
    value(obj) {
      return obj[this.table][this.field];
    },
  },
  {
    title: 'SWIFT Code',
    edit: 'textbox',
    table: 'paymentInformation',
    field: 'bankSwiftCode',
    placeholder: 'bankSwiftCode',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Routing Number',
    edit: 'textbox',
    table: 'paymentInformation',
    field: 'routingNumber',
    placeholder: '000000000',
    value(obj) {
      return obj[this.table][this.field];
    }
  },
  {
    title: 'Country',
    edit: 'list',
    table: 'paymentInformation',
    field: 'bankCountry',
    placeholder: 'Select  ▾',
    value(obj) {
      return obj[this.table][this.field];
    }
  }
];
