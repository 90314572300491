import gql from 'graphql-tag';

export const GET_ALL_PERMISSIONS = gql`
  query GET_ALL_PERMISSIONS {
    getAllPermissions {
      id
      name
      label
    }
  }
`;

export const GET_EMPLOYEE_PERMISSIONS = gql`
  query GET_EMPLOYEE_PERMISSIONS {
    getEmployeePermissions {
      id
      description
    }
  }
`;

export const DELETE_GROUPS_MUTATION = gql`
  mutation DELETE_GROUPS_MUTATION($list: [removeListInput!]!) {
    removeGroup(list: $list)
  }
`;