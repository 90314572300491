import styled from 'styled-components';

export const HeaderRoles = styled.div`
  .header-w{
    display:flex;
    justify-content: flex-end;
    margin: 29px 17px 0 17px;
    
  }
  .pagination {
    display: flex;
    align-items: center !important;
  }
  .btnPagination {
    cursor: pointer;
    font-size: 18px;
    margin-right: 22px;
  }
  .buttons-paginations {
    display: flex;
  }
  .select-pagination{
    border-radius: 5px;
    border-color: #D1D5DA;
    width:64px;
    height:36px;
    appearance: none;
    background-image: url("/images/icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.9rem auto;
    padding-left: 9px;
    color: #24292e;
  }
  .text-header{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 22px;
   }
  }
`;
export const ContentRoles = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 5px;
  margin: 13px 15px 15px 15px;

  .roles-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e4e8;
  }

  .sub-heading-role {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #172c45;
    letter-spacing: 0.3px;
    margin: 2px 2px 0 0;
  }
  .content-search {
    width: 250px;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 250px;
    margin-left: 20px;
  }

  .buttons-search {
    display: flex;
  }

  .trash {
    margin-right: 10px;
    height: 36px;
  }

  .success {
    height: 36px;
  }

  .color-box {
    background-color: white;
  }
  .create-roles-table {
    table {
      thead {
        tr {
          th {
            padding-top: 15px;
            padding-bottom: 13px;
          }
        }
        th:nth-child(1) {
          padding-right: 10px;
          .checkbox {
            label:after {
              width: 11px;
              height: 6px;
              border-bottom: 2px solid white;
              border-left: 2px solid white;
            }
            label:before {
              width: 18px;
              height: 18px;
              border: 1px solid #24292e;
            }
          }
        }
        th:nth-child(2) {
          padding-left: 0;
        }
      }
      tbody {
        tr {
          td {
            padding-top: 8.5px;
            padding-bottom: 8.5px;
          }
        }
        td:nth-child(1) {
          .checkbox {
            label:after {
              width: 11px;
              height: 6px;
              border-bottom: 2px solid white;
              border-left: 2px solid white;
            }
            label:before {
              width: 18px;
              height: 18px;
              border: 1px solid #bebebe;
            }
          }
          padding: 8.5px 0 8.5px 17px;
        }
        td:nth-child(2) {
          padding-left: 0;
          width: 26%;
        }
        td:nth-child(3) {
          width: 20%;
        }
        td:nth-child(4) {
          width: 20%;
        }
        td:nth-child(5) {
          width: 20%;
        }
        td:nth-child(6) {
          width: 7%;
        }
      }
    }
  }

  .create-roles {
    > div {
      display: inline-block;
    }

    > div:nth-child(1) {
      .form-control {
        border: 1px solid #444d56;
        border-radius: 5px;
        height: 36px;
      }
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding: 20px;
      width: 40%;
    }

    > div:nth-child(2) {
      button {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        width: 156px;
        height: 36px;
      }
    }

    > div:nth-child(3) {
      padding: 20px 20px 20px 0px;
      width: 25%;
    }

    > div:nth-child(4) {
      padding: 20px 20px 20px 0;
      width: 10%;
      button {
        width: 100%;
        background: #18d399;
        border: none;
        color: black;
      }
    }
  }

  .title-search {
    display: flex;
  }

  .noData {
    width: 100%;
    text-align: center;
    height: 600px;
    padding: 250px;

    svg {
      margin-bottom: 20px;
    }
  }
`;
