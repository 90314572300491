import React from 'react';

const Teams = ({ width = '47', height = '42' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.248 8.55575C17.248 5.11981 20.0336 2.33203 23.4718 2.33203C26.9077 2.33203 29.6955 5.11757 29.6955 8.55575C29.6955 11.9917 26.9099 14.7795 23.4718 14.7795C20.0336 14.775 17.248 11.9894 17.248 8.55575Z"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M2.16602 33.4425C2.16602 30.0065 4.95155 27.2188 8.38973 27.2188C11.8257 27.2188 14.6135 30.0043 14.6135 33.4425C14.6112 36.8784 11.8279 39.6662 8.38973 39.6662C4.95155 39.6662 2.16602 36.8807 2.16602 33.4425V33.4425Z"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M32.334 33.4425C32.334 30.0065 35.1195 27.2188 38.5577 27.2188C41.9936 27.2188 44.7814 30.0043 44.7814 33.4425C44.7814 36.8784 41.9959 39.6662 38.5577 39.6662C35.1173 39.6662 32.334 36.8807 32.334 33.4425V33.4425Z"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M15.5998 20.8203L11.0918 27.8626"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M26.8359 13.7773L31.3439 20.8197"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M32.3316 33.4453H23.4727"
        stroke="#24292E"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Teams;
