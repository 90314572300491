import styled from 'styled-components';

export const ContentTable = styled.div`
  width: 100%;
  background: #fff;

  .classSalaryDate {
    text-align: left;
    white-space: nowrap;
  }

  .lowerBorder {
    border-bottom: 1px solid #e1e4e8;
  }

  a {
    text-decoration: none;
    color: #24292e;
  }
  a:hover {
    text-decoration: none;
    color: #24292e;
  }
  .check-employee {
    display: flex;
    height: 39px;
    align-items: center;
    justify-content: flex-start;
  }
  .input-checkbox-employee {
    width: 18px;
    height: 18px;
    accent-color: #172c45;
    border-radius: 4px;
  }

  table {
    width: 100%;
    border-top: 1px solid #e1e4e8;

    thead {
      border-bottom: 1px solid #e1e4e8;
      th {
        padding: 10px 20px;
      }
      .roles-left {
        padding-left: 18px;
      }
      .teams-left {
        padding-left: 20px;
      }
    }

    tbody {
      width: 100%;
      .row-data:hover {
        background: #d1dff0;
      }
      .lowerBorder {
        border-bottom: 1px solid #e1e4e8;
      }
      .statusSeparate {
        background: #f5f5f5;
        color: #676d73;
      }
      .ButtonStatusSeparate {
        color: #676d73;
      }
      .radio {
        padding-left: 20px;
      }
      td {
        padding: 7px 0 7px 20px;
      }
      .columnPermission {
        width: 10px;
        position: relative;
      }
      .radioContainer {
        position: absolute;
        cursor: pointer;
        font-size: 22px;
        -moz-user-select: none;
        -ms-user-select: none;
        width: 50px;
        height: 50px;
        top: 100%;
        left: 47%;
        margin: -25px 0 0 -25px;
      }

      .radioContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: -4px;
        left: 20px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #bebebe;
      }

      .radioContainer:hover input ~ .checkmark {
        background-color: #ccc;
      }

      .radioContainer input:checked ~ .checkmark {
        background-color: #172c45;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      .radioContainer input:checked ~ .checkmark:after {
        display: block;
      }

      .radioContainer .checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }

    .td-permissions {
      padding: 7px 20px 7px 20px;
      white-space: nowrap;
    }

    .th-align {
      padding: 10px 20px;
    }
    .border-distance {
    }
    .employees-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    .second-row {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #24292e;
    }
    .green-button {
      background: #18d399;
      border-radius: 6px;
      outline: none;
      border: 0px;
      gap: 8px;
      align-items: center;
      padding: 6px 16px;
      margin-left: 10px;
    }
    .upper-row {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      width: 100%;
    }
    .sortTitle {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .sortTitleSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: #0366d6;
    }
    .sortTitleCheck {
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
    .arrowSort {
      padding-left: 10px;
    }
    .buttonSort {
      border: none;
      background: transparent;
      cursor: pointer;
    }
    .labelStatusActive {
      border-radius: 12px;
      background: #b9f1e0;
      color: #24292e;
      padding: 6px;
    }
    .labelStatusSeparate {
      border-radius: 12px;
      background: #f8c5c5;
      color: #24292e;
      padding: 6px;
    }
  }
`;
