import styled from 'styled-components';

export const ButtonFilterEmployeesContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  .dropdown {
    margin-left: 5px;
    .dropdown-toggle {
      background-color: #18d399 !important;
      border-color: #18d399 !important;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      padding: 0 1rem !important;
    }

    .btn-apply {
      text-align: center;
      margin-top: 7px;
      button {
        width: 90%;
        background: #14d399;
        color: black;
        margin: 0;
      }
    }
  }
  .filterMenu {
    position: relative;
  }
  .filterContent {
    position: absolute;
    background-color: white;
    margin-top: 2px;
    margin-left: 8px;
    width: 166px;
    height: auto;
    padding: 10px;
    border: 1px solid #e1e4e8;
    box-shadow: 0px 14px 13px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 1;
  }
  .filterMenuButton {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .filterMenu {
    padding: 5px 0px;
    margin: 2px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .filterMenuFirst {
    padding: 0px 0px 5px 0px;
    margin: 0px 0px 2px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .selectChoice {
    cursor: pointer;
  }
  .fieldsMenuInner {
    margin-left: 10px;
    margin-top: 2px;
    position: absolute;
    background-color: white;
    width: 166px;
    border: 1px solid #e1e4e8;
    box-shadow: 0px 14px 13px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 3px 10px 10px;
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }
  .fieldsBack {
    height: 25px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .rolesMenuInner {
    margin-left: 10px;
    margin-top: 2px;
    position: absolute;
    background-color: white;
    width: 166px;
    border: 1px solid #e1e4e8;
    box-shadow: 0px 14px 13px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 3px 10px 10px;
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }
  .rolesBack {
    height: 25px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .vectorFilter {
    margin-right: 5px;
  }
  .backFields {
    display: flex;
    direction: row;
  }
  .activeFilter {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .slider {
    height: 100%;
  }

  .fieldsRolesButton {
    display: flex;
    width: 100%;
    justify-content: space-between;
    width: 158px;
    margin-left: -7px;
    border: none;
    background-color: transparent;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e1e4e8;
    margin-left: -9px;
    position: absolute;
  }
  .itemsField {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 0px;
  }
  .buttonFilter {
    display: flex;
    align-items: center;
  }

  .button_blue-800 .pad-icon{
    color: #FFFFFF;
  }
  
`;
