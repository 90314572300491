import React from 'react';

const TeamOnBoardLogo = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 22.5V11.5M33 22.5L22.5 33V22.5M33 22.5H22.5M33 11.5H22.5M33 11.5L22.5 22.5M22.5 11.5V1M22.5 11.5L11.5 22.5M22.5 11.5V22.5M22.5 11.5H11.5M22.5 1H11.5M22.5 1L11.5 11.5M11.5 1L1 11.5H11.5M11.5 1V11.5M11.5 11.5V22.5M11.5 22.5H22.5"
        stroke="url(#paint0_linear_1201_4128)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1201_4128"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37E0B0" />
          <stop offset="1" stopColor="#A1FCB9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TeamOnBoardLogo;
