import gql from 'graphql-tag';

export const ADD_ROLE_TO_COMPANY = gql`
  mutation ADD_ROLE_TO_COMPANY($nameRole: String!, $companyId: String!) {
    createNewRole(createRole: { nameRole: $nameRole, companyId: $companyId })
  }
`;

export const DELETE_ROLE_FROM_COMPANY = gql`
  mutation DELETE_ROLE_FROM_COMPANY($list: [deleteRoleInput!]!) {
    deleteRole(list: $list)
  }
`;

export const UPDATE_ROLE_FROM_COMPANY = gql`
  mutation UPDATE_ROLE_FROM_COMPANY(
    $id: String!
    $name: StringFieldUpdateOperationsInput!
    $salaryLow: NullableFloatFieldUpdateOperationsInput
    $salaryHigh: NullableFloatFieldUpdateOperationsInput
    $salaryMid: FloatFieldUpdateOperationsInput!
    $companyId: String!
  ) {
    updateOneEmployeeRole(
      data: {
        name: $name
        salaryLow: $salaryLow
        salaryHigh: $salaryHigh
        salaryMid: $salaryMid
        company: { connect: { id: $companyId } }
      }
      where: { id: $id }
    ) {
      id
    }
  }
`;

export const UPDATE_ROLE_MUTATION = gql`
  mutation UPDATE_ROLE_MUTATION(
    $id: String!
    $nameRole: String!
    $status: String!
  ) {
    editRole(editRole: { id: $id, nameRole: $nameRole, status: $status })
  }
`;

export const UPDATE_ROLE_DELETE_MUTATION = gql`
  mutation UPDATE_ROLE_DELETE_MUTATION($nameRole: String!, $status: String!) {
    editRoleDelete(editRoleDelete: { nameRole: $nameRole, status: $status })
  }
`;
