import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import 'react-circular-progressbar/dist/styles.css';
import { useGetPtoRulesByCompanyQuery } from '../../../graphql/container/query/timeOffRules';
import Moment from 'react-moment';
import moment from 'moment';
import { CircularProgress, FormControlLabel, Radio } from '@material-ui/core';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

import {
  calculateDaysOffFromStartAndEnd,
  formatDateToCalendar,
  verifyHolidays
} from '../../../services/Utils/getUtils';
import {
  useGetEmployeeLazyQuery,
  useGetPTOEmployeeLazyQuery
} from '../../../graphql/container/query/employees';
import { Col, Row } from '../../../components/Grid/Grid';
import { ContentPTO } from './styles';
import { IconEvents } from '../../../components/Icons/IconEvents';
import { transformUTCDateNoTime } from '../../../services/Utils/getUtils';
import {
  DATE_FORMAT,
  getImageError,
  COPY_BY_MAIL_TO_THE_POSITION
} from '../../../services/Constants/getDefaults';
import Check from '../../../components/Icons/Check';
import Wrong from '../../../components/Icons/Wrong';
import Edit from '../../../components/Icons/Edit';
import { AppContext } from '../../../Context/App/AppContext';
import {
  DATE_FORMAT_BETWEEN,
  ABSCENCE_TYPE,
  ABSCENCE_REQUEST_STATUS
} from '../../../services/Constants/getDefaults';
import {
  ABSCENCE_REQUEST_EXCEED_DAYS,
  ABSCENCE_REQUEST_CONTAINS_USED_DAYS,
  EXCEEDED_AMOUNT_OF_DAYS,
  YOU_CAN_NOT_REQUEST_WEEKENDS,
  ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY,
  YOU_CAN_NOT_REQUEST_SAME_DAY,
  YOU_CAN_NOT_REQUEST_BOOKED_DAYS,
  CHOOSE_START_AND_END_DATE,
  YOU_DO_NOT_HAVE_DAYS_LEFT,
  YOU_CAN_NOT_UPDATE_BOOKED_DECLINED,
  CHOOSE_START_GREATER_END_DATE,
  YOU_UPDATED_THE_REGISTER
} from '../../../services/Constants/getLabels';
import { ACCEPT_OR_DECLINE_PTO_MUTATION } from '../../../graphql/queries/timeOffAccruals';
import {
  formatZeroGmtDate,
  simpleFormatDate,
  transformUTCDate,
  formatStringDate
} from '../../../services/Utils/getUtils';
import {
  CREATE_ABSCENCE_REQUEST_MUTATION,
  DELETE_PTO_MUTATION,
  EDIT_PTO_MUTATION
} from '../../../graphql/mutations/abscenceRequest';
import CheckMark from '../../../components/Icons/CheckMark';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { routesAdmin } from '../../../services/Constants/paths';
import { ModalAlert } from '../../../components/Modals';
import DatePickerModule from '../../../components/DatePickerModal';
import Exclamation from '../../../components/Icons/Exclamation';
import CalendarPto from '../../../components/Icons/CalendarPto';
import TabsPto from '../../../components/TabsPto';
import {
  useTeamsByCompanyQueryEmployee,
  useTeamsByCompanyQueryEmployeeByTeams
} from '../../../graphql/container/query/teams';
import { useTeamsByCompanyQueryEmployeeNotTeamsForRole } from '../../../graphql/container/query/teams';
import DropdownPto from '../../../components/DropdownPto';
import Trash from '../../../components/Icons/Trash';

const Ptoadmin = () => {
  const isMounted = useRef(false);
  const params = useParams();
  const { currentUser } = useContext(AppContext);
  const [check, setCheck] = useState(false);
  const [listPending, setListPending] = useState([]);
  const [employee, setEmployee] = useState({});
  const [ptoselect, setptoselect] = useState({});
  const [statusPto, setStatusPto] = useState();
  const [listPast, setListPast] = useState([]);
  const [infoTimeOff, setInfoTimeOff] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();
  const [editInitialsStartDate, seteditInitialsStartDate] = useState(
    new Date()
  );
  const [editInitialsEndDate, seteditInitialsEndDate] = useState(new Date());
  const [editStartDate, seteditStartDate] = useState(new Date());
  const [editEndDate, seteditEndDate] = useState(new Date());
  const [note, setnote] = useState('');
  const [deleteAlert, setdeleteAlert] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [description, setDescription] = useState('');
  const [typeTime, setTypeTime] = useState(ABSCENCE_TYPE.DAY_OFF);
  const [loadingTable, setLoadingTable] = useState(false);
  const idEmployee = params?.id;
  const companyId = currentUser?.companyRole?.[0].company?.id;
  const userEmail = employee?.user?.email;
  const [alert, setAlert] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [message, setMessage] = useState('');
  const [approvedDays, setApprovedDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const currentDate = moment();
  const currentYear = currentDate.year();

  const {
    data: { getAllManagerByCompanyByEmployeeByTeams }
  } = useTeamsByCompanyQueryEmployeeByTeams(companyId, idEmployee);

  const {
    data: { getAllTeamsByCompanyByEmployee }
  } = useTeamsByCompanyQueryEmployee(companyId, idEmployee);

  const {
    data: { getAllEmailsOwnerAndAdminByCompany }
  } = useTeamsByCompanyQueryEmployeeNotTeamsForRole(companyId);

  const [getEmployee, { data: getEmployeeData }] = useGetEmployeeLazyQuery(
    idEmployee
  );
  const [deletePtoMutation, { loading: deletePtoLoading }] = useMutation(
    DELETE_PTO_MUTATION
  );
  const [editPtoMutation] = useMutation(EDIT_PTO_MUTATION);

  useEffect(() => {
    if (idEmployee) {
      getEmployee(idEmployee);
    }
  }, [idEmployee, getEmployee]);

  useEffect(() => {
    if (getEmployeeData) {
      setEmployee(getEmployeeData?.getEmployee);
    }
  }, [getEmployeeData]);

  const [
    getPtoEmployee,
    { data: getPtoEmployeeData, refetch }
  ] = useGetPTOEmployeeLazyQuery(idEmployee, userEmail);

  const {
    data: { getPtoRulesByCompany }
  } = useGetPtoRulesByCompanyQuery(companyId);

  useEffect(() => {
    setHolidays(
      getPtoRulesByCompany?.holidays
        ?.map(el => formatDateToCalendar(el.date))
        .flat()
    );
  }, [getPtoRulesByCompany]);

  useEffect(() => {
    if (userEmail) {
      getPtoEmployee(idEmployee, userEmail);
    }
  }, [idEmployee, userEmail, getPtoEmployee]);

  const [
    createOneAbscenceRequest,
    { loading: createOneAbscenceRequestLoading }
  ] = useMutation(CREATE_ABSCENCE_REQUEST_MUTATION);

  const [
    acceptOrDeclinePto,
    { loading: acceptOrDeclinePtoLoading }
  ] = useMutation(ACCEPT_OR_DECLINE_PTO_MUTATION);

  useEffect(() => {
    if (isMounted.current) {
      if (!acceptOrDeclinePtoLoading) {
        refetch({ idEmployee, userEmail });
      }
      if (userEmail) {
        getPtoEmployee(idEmployee, userEmail);
      }
    }
    // eslint-disable-next-line
  }, [acceptOrDeclinePtoLoading, isMounted.current]);

  useEffect(() => {
    if (getPtoEmployeeData?.getPtoEmployee) {
      isMounted.current = true;
      const {
        approved,
        pending,
        declined,
        timeOffAccrual
      } = getPtoEmployeeData?.getPtoEmployee;
      if (pending) setListPending(pending);
      if (approved || declined) {
        const order = [...approved, ...declined].sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setListPast(order.slice(0, 20));
      }
      if (timeOffAccrual) setInfoTimeOff(timeOffAccrual);
      setLoadingTable(false);
    }
  }, [getPtoEmployeeData]);

  useEffect(() => {
    const startApproved = getPtoEmployeeData?.getPtoEmployee?.approved.map(el =>
      formatDateToCalendar(
        el.dateTakenStart,
        moment(el.dateTakenEnd).diff(moment(el.dateTakenStart), 'days') + 1
      )
    );

    const startAndEndPending = getPtoEmployeeData?.getPtoEmployee?.pending?.map(
      el => calculateDaysOffFromStartAndEnd(el.startDate, el.endDate)
    );

    const startPending = getPtoEmployeeData?.getPtoEmployee?.pending
      ?.map((el, i) =>
        formatDateToCalendar(el.startDate, startAndEndPending[i])
      )
      .flat()
      .concat(holidays);

    setApprovedDays(startApproved?.flat().concat(startPending));
  }, [getPtoEmployeeData, holidays]);

  useEffect(() => {
    if (loadingTable && !createOneAbscenceRequestLoading) refetch();
    // eslint-disable-next-line
  }, [createOneAbscenceRequestLoading]);

  const deletePto = async (ptoselect, employeeId) => {
    const startDate =
      ptoselect.status === 'DECLINED'
        ? moment(ptoselect.startDate)
            .utc()
            .year()
        : moment(ptoselect.dateTakenStart)
            .utc()
            .year();
    const endDate =
      ptoselect.status === 'DECLINED'
        ? moment(ptoselect.endDate)
            .utc()
            .year()
        : moment(ptoselect.dateTakenEnd)
            .utc()
            .year();
    const ptoCurrentYear =
      currentYear === startDate && currentYear === endDate ? true : false;
    const ptoIdAbscenceRequest =
      ptoselect.status === 'DECLINED'
        ? ptoselect.id
        : ptoselect.abscenceRequest;

    const amountDaysOff = ptoselect?.daysOff
      ? ptoselect?.daysOff?.toString()
      : '0';

    try {
      await deletePtoMutation({
        variables: {
          PtoId: ptoIdAbscenceRequest,
          AmountDays: amountDaysOff,
          Type: ptoselect.type,
          EmployeeId: employeeId,
          Status: statusPto,
          PtoCurrentYear: ptoCurrentYear
        }
      });
      setdeleteAlert(false);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const isBetweenDate = (arrayDate, dateTakenStart, dateTakenEnd) => {
    let isBetween = false;
    const isBetweenArray = arrayDate.filter(item => {
      return moment(item)
        .utc()
        .isBetween(
          moment(dateTakenStart)
            .utc()
            .format(DATE_FORMAT_BETWEEN),
          moment(dateTakenEnd)
            .utc()
            .format(DATE_FORMAT_BETWEEN),
          null,
          '[]'
        );
    });
    if (isBetweenArray.length > 0) {
      isBetween = true;
    }

    return isBetween;
  };

  const isValidDateAvalibledDays = (dateStart, dateEnd, type) => {
    let isAvalible = false;
    let dateCurrent = dateStart;
    let arrayDate = [];
    let weekend = 0;
    while (
      dateCurrent.utc().format(DATE_FORMAT_BETWEEN) <
      dateEnd.utc().format(DATE_FORMAT_BETWEEN)
    ) {
      if (
        moment(dateCurrent)
          .utc()
          .format('ddd') === 'Sat' ||
        moment(dateCurrent)
          .utc()
          .format('ddd') === 'Sun'
      )
        weekend += 1;
      arrayDate.push(
        moment(dateCurrent)
          .utc()
          .format(DATE_FORMAT_BETWEEN)
      );
      dateCurrent = moment(dateCurrent).add(1, 'day');
    }
    const abscencesArray =
      getPtoEmployeeData?.getPtoEmployee.approved?.filter(abc => {
        return isBetweenDate(arrayDate, abc.dateTakenStart, abc.dateTakenEnd);
      }) || [];
    const abscencesRequestArray =
      getPtoEmployeeData?.getPtoEmployee?.pending?.filter(request => {
        return isBetweenDate(arrayDate, request.startDate, request.endDate);
      }) || [];
    if (abscencesArray?.length > 0 || abscencesRequestArray?.length > 0) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_USED_DAYS}!.`);
      isAvalible = true;
      return isAvalible;
    }
    if (
      infoTimeOff?.daysOff < arrayDate.length - weekend &&
      type === ABSCENCE_TYPE.DAY_OFF
    ) {
      setMessage(`${EXCEEDED_AMOUNT_OF_DAYS}: ${infoTimeOff?.daysOff}`);
      isAvalible = true;
      return isAvalible;
    }

    return isAvalible;
  };

  const resetFunction = () => {
    endDate && setEndDate();
  };

  const onSubmitPtoPending = (item, status) => {
    let emails = '';

    const employeeHr = getAllEmailsOwnerAndAdminByCompany?.filter(
      item =>
        item.role.toLowerCase() === COPY_BY_MAIL_TO_THE_POSITION.toLowerCase()
    );

    employeeHr.forEach(item => {
      emails = emails + item?.emailEmployee + ';';
    });

    acceptOrDeclinePto({
      variables: {
        status,
        id: item.id,
        emails: emails.slice(0, emails.length - 1)
      }
    });
  };

  /**
   * It validates the rules of the calendar when editing a request is called by function editPto
   * @returns - A boolean value.
   */
  const validateCalendarRulesPtoEdit = () => {
    const initialStartRequest = simpleFormatDate(editInitialsStartDate);
    const initialEndRequest = simpleFormatDate(editInitialsEndDate);
    const startRequest = simpleFormatDate(editStartDate);
    const endRequest = simpleFormatDate(editEndDate);
    const starts = listPending.map(el => el.startDate.slice(0, 10));
    const ends = listPending.map(el => el.endDate.slice(0, 10));
    let dateCurrent = transformUTCDate(editStartDate);
    let dateEnd = transformUTCDate(editEndDate);
    let arrayDate = [];
    while (
      dateCurrent.utc().format(DATE_FORMAT_BETWEEN) <
      dateEnd.utc().format(DATE_FORMAT_BETWEEN)
    ) {
      arrayDate.push(
        moment(dateCurrent)
          .utc()
          .format(DATE_FORMAT_BETWEEN)
      );
      dateCurrent = moment(dateCurrent).add(1, 'day');
    }

    const startsApproved = getPtoEmployeeData?.getPtoEmployee?.approved
      .map(el => el.dateTakenStart.slice(0, 10))
      .filter(item => item !== initialStartRequest);
    const endsApproved = getPtoEmployeeData?.getPtoEmployee?.approved
      .map(el => el.dateTakenEnd.slice(0, 10))
      .filter(item => item !== initialEndRequest);
    const abscencesArray =
      getPtoEmployeeData?.getPtoEmployee.approved
        ?.filter(
          pto =>
            pto.dateTakenStart !== editInitialsStartDate.toISOString() &&
            pto.dateTakenEnd !== editInitialsEndDate.toISOString()
        )
        ?.filter(abc => {
          return isBetweenDate(arrayDate, abc.dateTakenStart, abc.dateTakenEnd);
        }) || [];

    const abscencesRequestArray =
      getPtoEmployeeData?.getPtoEmployee?.pending?.filter(request => {
        return isBetweenDate(arrayDate, request.startDate, request.endDate);
      }) || [];

    if (
      initialStartRequest === startRequest &&
      initialEndRequest === endRequest
    ) {
      return false;
    }

    if (abscencesArray?.length > 0 || abscencesRequestArray?.length > 0) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_USED_DAYS}!.`);
      setAlert(true);
      return false;
    }
    if (editStartDate > editEndDate) {
      setMessage(`${CHOOSE_START_GREATER_END_DATE}`);
      setAlert(true);
      return false;
    }
    if (
      editStartDate?.toString().includes('Sat') ||
      editStartDate?.toString().includes('Sun') ||
      editEndDate?.toString().includes('Sat') ||
      editEndDate?.toString().includes('Sun')
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_WEEKENDS}`);
      setAlert(true);
      return false;
    }
    if (
      verifyHolidays(editStartDate, getPtoRulesByCompany.holidays) ||
      verifyHolidays(editEndDate, getPtoRulesByCompany.holidays)
    ) {
      setMessage(`${ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY}`);
      setAlert(true);
      return false;
    }
    if (
      starts.includes(startRequest) ||
      starts.includes(endRequest) ||
      ends.includes(startRequest) ||
      ends.includes(endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_SAME_DAY}`);
      setAlert(true);
      return false;
    }
    if (
      startsApproved.includes(startRequest || endRequest) ||
      endsApproved.includes(startRequest || endRequest)
    ) {
      setMessage(`${YOU_CAN_NOT_REQUEST_BOOKED_DAYS}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  /**
   * The function editPto is an async function that takes in two parameters, ptoselect and employeeId.
   * The function then calls the editPtoMutation function and passes in the variables startDate, endDate,
   * message, ptoId, amountDays, type, and employeeId. The function then sets the editModal to false and
   * calls the refetch function
   */
  const editPto = async (ptoselect, employeeId) => {
    if (validateCalendarRulesPtoEdit()) {
      try {
        const updatePto = await editPtoMutation({
          variables: {
            startDate: formatZeroGmtDate(editStartDate),
            endDate: formatZeroGmtDate(editEndDate),
            message: note ? note : '',
            ptoId: ptoselect.abscenceRequest,
            amountDays: ptoselect.daysOff.toString(),
            type: ptoselect.type,
            employeeId: employeeId
          }
        });
        if (updatePto.data.EditPto) {
          setMessage(`${YOU_UPDATED_THE_REGISTER}`);
          setAlert(true);
        } else {
          setMessage(`${EXCEEDED_AMOUNT_OF_DAYS}`);
          setAlert(true);
        }
        seteditModal(false);
        refetch();
      } catch (error) {
        console.log(error);
      }
    }
    seteditModal(false);
  };
  /* The function editPTOvalue is called  by function  editPTOvalue  
   Validate if  DECLINED or date end is  greater than  Yesterday
*/
  const validateEditPTO = item => {
    if (item.status === 'DECLINED') {
      setMessage(`${YOU_CAN_NOT_UPDATE_BOOKED_DECLINED}`);
      setAlert(true);
      return false;
    }
    return true;
  };

  /**
   * The function editPTOvalue is called when the user clicks on the edit button for a PTO request. The
   * function sets the start and end dates for the PTO request, sets the note for the PTO request, sets
   * the PTO request to be edited, and then opens the edit modal if the PTO request is valid
   */
  const editPTOvalue = item => {
    seteditInitialsStartDate(
      new Date(transformUTCDateNoTime(item.dateTakenStart))
    );
    seteditInitialsEndDate(new Date(transformUTCDateNoTime(item.dateTakenEnd)));
    seteditEndDate(new Date(transformUTCDateNoTime(item.dateTakenEnd)));
    seteditStartDate(new Date(transformUTCDateNoTime(item.dateTakenStart)));
    setnote(item.note);
    setptoselect(item);
    if (validateEditPTO(item)) {
      seteditModal(true);
    }
  };

  const validationForm = params => {
    const {
      newDateStart,
      newDateEnd,
      today,
      daysAdvanceToRequestTimeOff,
      type
    } = params;
    let response = {
      valid: true
    };
    if (
      newDateStart.diff(today, 'days') + 1 < daysAdvanceToRequestTimeOff &&
      type === ABSCENCE_TYPE.DAY_OFF
    ) {
      response = {
        message: `${ABSCENCE_REQUEST_EXCEED_DAYS} (${daysAdvanceToRequestTimeOff}) to generate a day off!.`,
        valid: false
      };
      setMessage(
        `${ABSCENCE_REQUEST_EXCEED_DAYS} (${daysAdvanceToRequestTimeOff}) to generate a day off!.`
      );
      setAlert(true);
    } else if (isValidDateAvalibledDays(newDateStart, newDateEnd, typeTime)) {
      response = {
        message: `${ABSCENCE_REQUEST_CONTAINS_USED_DAYS}!.`,
        valid: false
      };
      setAlert(true);
    }

    return response;
  };

  const onSubmit = (item = '') => {
    const startRequest = simpleFormatDate(startDate);
    const endRequest = simpleFormatDate(endDate);
    const starts = listPending.map(el => el.startDate.slice(0, 10));
    const ends = listPending.map(el => el.endDate.slice(0, 10));
    const startsApproved = getPtoEmployeeData?.getPtoEmployee?.approved.map(
      el => el.dateTakenStart.slice(0, 10)
    );
    const endsApproved = getPtoEmployeeData?.getPtoEmployee?.approved.map(el =>
      el.dateTakenEnd.slice(0, 10)
    );

    if (
      (infoTimeOff?.daysOff > 0 && infoTimeOff?.daysUsed >= 0) ||
      typeTime === 'SICK'
    ) {
      if (startDate === null && endDate === null) {
        setMessage(`${CHOOSE_START_AND_END_DATE}`);
        setAlert(true);
        return;
      }
      if (startRequest > endRequest) {
        setMessage(`${CHOOSE_START_GREATER_END_DATE}`);
        setAlert(true);
        return;
      }
      if (
        startDate?.toString().includes('Sat') ||
        startDate?.toString().includes('Sun') ||
        endDate?.toString().includes('Sat') ||
        endDate?.toString().includes('Sun')
      ) {
        setMessage(`${YOU_CAN_NOT_REQUEST_WEEKENDS}`);
        setAlert(true);
        return;
      }
      if (
        verifyHolidays(startDate, getPtoRulesByCompany.holidays) ||
        verifyHolidays(endDate, getPtoRulesByCompany.holidays)
      ) {
        setMessage(`${ABSCENCE_REQUEST_CONTAINS_COMPANY_HOLIDAY}`);
        setAlert(true);
        return;
      }
      if (
        starts.includes(startRequest) ||
        starts.includes(endRequest) ||
        ends.includes(startRequest) ||
        ends.includes(endRequest)
      ) {
        setMessage(`${YOU_CAN_NOT_REQUEST_SAME_DAY}`);
        setAlert(true);
        return;
      }
      if (
        startsApproved.includes(startRequest || endRequest) ||
        endsApproved.includes(startRequest || endRequest)
      ) {
        setMessage(`${YOU_CAN_NOT_REQUEST_BOOKED_DAYS}`);
        setAlert(true);
        return;
      }
      setStartDate(new Date());
      setEndDate(null);
      const newDateStart = transformUTCDate(startDate);
      const newDateEnd = transformUTCDate(endDate);
      const today = transformUTCDate(new Date());
      const {
        daysAdvanceToRequestTimeOff
      } = currentUser.companyRole[0].company.timeOffRules;

      let emailsManagerTeams = '';

      if (getAllManagerByCompanyByEmployeeByTeams?.length !== 0) {
        getAllManagerByCompanyByEmployeeByTeams.forEach(teamManager => {
          emailsManagerTeams =
            emailsManagerTeams + teamManager.emailEmployee + ';';
        });
      } else {
        getAllEmailsOwnerAndAdminByCompany.forEach(teamManager => {
          emailsManagerTeams =
            emailsManagerTeams + teamManager.emailEmployee + ';';
        });
      }

      let variables = {
        employeeId: idEmployee,
        dateTakenStart: formatZeroGmtDate(startDate),
        dateTakenEnd: formatZeroGmtDate(endDate || startDate),
        type: typeTime,
        message: description,
        status: ABSCENCE_REQUEST_STATUS.PENDING,
        emails: emailsManagerTeams.slice(0, emailsManagerTeams.length - 1),
        idAbscenceRequest: item
      };
      const correctForm = validationForm({
        newDateStart,
        newDateEnd,
        today,
        daysAdvanceToRequestTimeOff,
        typeTime, // Validar
        startDate,
        endDate
      });
      if (correctForm.valid) {
        createOneAbscenceRequest({ variables });
        setLoadingTable(true);
      } else {
        console.log('Fail');
      }
    } else {
      setMessage(`${YOU_DO_NOT_HAVE_DAYS_LEFT}`);
      setAlert(true);
      return;
    }
  };

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  const userTeams = getAllTeamsByCompanyByEmployee
    ?.filter(({ managerName, nameEmployee }) => managerName !== nameEmployee)
    .map(({ managerName, nameTeam }) => ({
      manager: managerName,
      name: nameTeam
    }))
    .slice(0, 6);

  return (
    <>
      <ModalAlert
        show={alert}
        onClose={() => setAlert(false)}
        content={message}
        buttonCancel={'Ok, Close'}
        confirmButton={false}
        buttonCancelStyle={'buttonAlertPto'}
        alertClass={'alertIcon'}
        oneButton
      />
      <Breadcrumbs
        routes={routesAdmin.employees}
        subtitle={
          (employee?.firstName ? employee?.firstName : '') +
          ' ' +
          (employee?.lastName ? employee?.lastName : '')
        }
        section="Employees"
      />
      <ContentPTO>
        <div className="card-main-pto">
          <div className="card-pto-title">
            <span className="sub-heading">
              PTO
              <div className="little-subtitle">
                <Exclamation />
                <p>These days will be renewed the following year</p>
              </div>
            </span>
          </div>
          <div className="card-pto-available">
            <div className="avaliable">
              <div>Avaliable:</div>
              <span>{infoTimeOff?.daysOff || 0}</span>
            </div>
            <div className="used">
              <div>PTO days used:</div>
              <span>{infoTimeOff?.daysUsed || 0}</span>
            </div>
            <div>
              <div>Sick days used:</div>
              <span>{infoTimeOff?.daysSick || 0}</span>
            </div>
          </div>
          <div className="card-pto-title">
            <span className="sub-heading">
              Make your request here
              <div className="little-subtitle">
                <Exclamation />
                <p>
                  Your PTO request must be created for at least 10 days
                  anticipated.{' '}
                </p>
              </div>
            </span>
          </div>
          <div className="card-pto-filter">
            <div className="pto-check">
              <div>
                <FormControlLabel
                  value="pto"
                  label="PTO"
                  control={
                    <Radio
                      color="default"
                      checked={!check}
                      onClick={() => {
                        setCheck(!check);
                        setTypeTime(ABSCENCE_TYPE.DAY_OFF);
                      }}
                    />
                  }
                />
              </div>
              <div>
                <FormControlLabel
                  value="sick"
                  label="SICK"
                  control={
                    <Radio
                      color="default"
                      checked={check}
                      onClick={() => {
                        setCheck(!check);
                        setTypeTime(ABSCENCE_TYPE.SICK);
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className="pto-fields">
              <span>Start Date</span>
              <div className="pto-calendar-fields">
                <InputGroup.Text>
                  <CalendarPto />
                </InputGroup.Text>
                <DatePickerModule
                  principalClass={'inner-Calendar-pto'}
                  weekendDisabled
                  onChange={value => setStartDate(value)}
                  onClick={() => resetFunction()}
                  minDate={new Date(new Date().setMonth(0, 1))}
                  maxDate={new Date('2000-01-01').setFullYear(
                    new Date().getFullYear() // + 1
                  )}
                  selected={startDate}
                  placeholderText="MM/DD/YYYY"
                  excludeDates={approvedDays}
                />
              </div>
            </div>
            <div className="pto-fields">
              <span>End Date</span>
              <div className="pto-calendar-fields">
                <InputGroup.Text>
                  <CalendarPto />
                </InputGroup.Text>
                <DatePickerModule
                  principalClass={'inner-Calendar-pto'}
                  weekendDisabled
                  onChange={value => setEndDate(value)}
                  onClick={() => resetFunction()}
                  minDate={startDate}
                  maxDate={new Date('2000-01-01').setFullYear(
                    new Date().getFullYear() // + 1
                  )}
                  selected={endDate}
                  placeholderText={'MM/DD/YYYY'}
                  excludeDates={approvedDays}
                />
              </div>
            </div>
            <div className="note-pto">
              <span>Note</span>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <Edit />
                </InputGroup.Text>
                <FormControl
                  onChange={({ target }) => setDescription(target.value)}
                  placeholder="Add Note"
                  aria-label="Note"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="btn-pto">
              <Button
                className="btn-success"
                disabled={startDate && endDate ? false : true}
                onClick={() => onSubmit()}
              >
                Add
              </Button>
            </div>
          </div>
          <Row className="card-pto-data">
            {loadingTable ? (
              <div className="loading">
                <CircularProgress className="circular-progress" />
              </div>
            ) : (
              <>
                <TabsPto
                  tabActive={tabActive}
                  setTabActive={value => {
                    setTabActive(value);
                  }}
                />
                <div className="content-tabs">
                  {tabActive === 0 ? (
                    listPending?.length ? (
                      acceptOrDeclinePtoLoading ? (
                        <div className="loading">
                          <CircularProgress className="circular-progress" />
                        </div>
                      ) : listPending?.length ? (
                        <div>
                          {listPending.map((item, i) => (
                            <DropdownPto
                              key={`ptoadmin-pending-${i}`}
                              id={`ptoadmin-pending-${i}`}
                              item={item}
                              teams={userTeams}
                            >
                              <div className="pto-item">
                                <div className="d-flex">
                                  <div className="pto-icon">
                                    <IconEvents
                                      icon={
                                        item.type === 'SICK'
                                          ? item.type.toLowerCase()
                                          : 'pto'
                                      }
                                    />
                                  </div>

                                  <div className="card-icon">
                                    <span className="icon-profile">
                                      {employee?.user?.photo ? (
                                        <img
                                          src={employee?.user?.photo}
                                          alt="Profile"
                                          onErrorCapture={e =>
                                            (e.target.onErrorCapture = null)(
                                              (e.target.src = `${getImageError(
                                                companyId
                                              )}`)
                                            )
                                          }
                                          referrerPolicy="no-referrer"
                                        />
                                      ) : (
                                        <AccountCircle
                                          style={{
                                            fontSize: 43,
                                            margin: '-3px'
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>

                                  <div className="pto-date-status">
                                    <div>
                                      <span className="pto-name-employee">
                                        {' '}
                                        {(employee?.firstName
                                          ? employee?.firstName
                                          : '') +
                                          ' ' +
                                          (employee?.lastName
                                            ? employee?.lastName
                                            : '')}
                                      </span>
                                    </div>
                                    <div className="pto-dates">
                                      <Moment format={DATE_FORMAT}>
                                        {transformUTCDate(item.startDate)}
                                      </Moment>
                                      {' - '}
                                      <Moment format={DATE_FORMAT}>
                                        {transformUTCDate(item.endDate)}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>

                                <div className="pto-buttons">
                                  <Button
                                    className="btn-danger"
                                    onClick={() =>
                                      onSubmitPtoPending(item, 'DECLINED')
                                    }
                                  >
                                    <span className="text-bold">Decline</span>
                                  </Button>
                                  <Button
                                    className="btn-success"
                                    onClick={() =>
                                      onSubmitPtoPending(item, 'APPROVED')
                                    }
                                  >
                                    <span className="text-bold">Accept</span>
                                  </Button>
                                </div>
                              </div>
                            </DropdownPto>
                          ))}
                        </div>
                      ) : null
                    ) : (
                      <div className="card-pto-no-data">
                        <CheckMark color="black" />
                        <h5 className="text-normal">
                          You have no pending approval
                        </h5>
                      </div>
                    )
                  ) : null}

                  {tabActive === 1 ? (
                    listPast?.length ? (
                      <>
                        <div>
                          {listPast.map((item, i) => {
                            return (
                              <DropdownPto
                                key={`pto-item-past-${i}`}
                                id={`pto-item-past-${i}`}
                                item={item}
                                teams={userTeams}
                              >
                                <div className="pto-item">
                                  <div className="d-flex">
                                    <div className="pto-icon">
                                      <IconEvents
                                        icon={
                                          item.type === 'SICK'
                                            ? item.type.toLowerCase()
                                            : 'pto'
                                        }
                                      />
                                    </div>
                                    <div className="card-icon">
                                      <span className="icon-profile">
                                        {employee?.user?.photo ? (
                                          <img
                                            src={employee?.user?.photo}
                                            alt="Profile"
                                            onErrorCapture={e =>
                                              (e.target.onErrorCapture = null)(
                                                (e.target.src = `${getImageError(
                                                  companyId
                                                )}`)
                                              )
                                            }
                                            referrerPolicy="no-referrer"
                                          />
                                        ) : (
                                          <AccountCircle
                                            style={{
                                              fontSize: 43,
                                              margin: '-3px'
                                            }}
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="pto-date-status">
                                      <div>
                                        <span className="pto-name-employee">
                                          {' '}
                                          {(employee?.firstName
                                            ? employee?.firstName
                                            : '') +
                                            ' ' +
                                            (employee?.lastName
                                              ? employee?.lastName
                                              : '')}
                                        </span>
                                      </div>
                                      <div className="pto-dates">
                                        <Moment format={DATE_FORMAT}>
                                          {transformUTCDate(
                                            item.dateTakenStart ||
                                              item.startDate
                                          )}
                                        </Moment>
                                        {' - '}
                                        <Moment format={DATE_FORMAT}>
                                          {transformUTCDate(
                                            item.dateTakenEnd || item.endDate
                                          )}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="card-buttons-edit">
                                      <Button
                                        className="btn-danger-declined me-3"
                                        onClick={() => {
                                          setStatusPto(
                                            item.status === 'DECLINED'
                                              ? 'DECLINED'
                                              : 'APPROVED'
                                          );
                                          setptoselect(item);
                                          setdeleteAlert(true);
                                        }}
                                      >
                                        <Trash color="#fff" />
                                        <span className="text-bold text-white">
                                          Delete
                                        </span>
                                      </Button>
                                      <Button
                                        className="btn-success-accept"
                                        onClick={() => editPTOvalue(item)}
                                      >
                                        <img
                                          src="/images/editPTO.svg"
                                          alt="editPto"
                                        />
                                        <span className="text-bold">Edit</span>
                                      </Button>
                                    </div>
                                    <div className="ptobtns-edit">
                                      <div
                                        className={
                                          item.status
                                            ? 'icon-status-declined'
                                            : 'icon-status-approved'
                                        }
                                      >
                                        <Col>
                                          {item.status ? <Wrong /> : <Check />}
                                          <span>
                                            {item.status
                                              ? 'Declined'
                                              : 'Approved'}
                                          </span>
                                        </Col>
                                      </div>
                                    </div>
                                    <div className="btn-actions">
                                      <img
                                        src="/images/editPTO.svg"
                                        alt=""
                                        onClick={() => {
                                          editPTOvalue(item);
                                        }}
                                      />
                                      <img
                                        src="/images/deletePTO.svg"
                                        alt=""
                                        onClick={() => {
                                          setStatusPto(
                                            item.status === 'DECLINED'
                                              ? 'DECLINED'
                                              : 'APPROVED'
                                          );
                                          setptoselect(item);
                                          setdeleteAlert(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </DropdownPto>
                            );
                          })}
                        </div>
                        <ModalAlert
                          show={deleteAlert}
                          onClose={() => setdeleteAlert(false)}
                          confirmLabel="Delete activity"
                          onConfirm={() => {
                            deletePto(ptoselect, employee.id);
                          }}
                          buttonCancel="Cancel"
                          buttonCancelStyle="btn-danger-without-bg"
                          content={
                            <div>
                              <div className="content-alert">
                                <p className="label-alert">
                                  Are you sure you want to delete this{' '}
                                  {ptoselect.type === 'DAY_OFF'
                                    ? 'PTO'
                                    : 'SICK DAY'}{' '}
                                  activity from
                                  <b>
                                    {' '}
                                    {ptoselect.dateTakenStart
                                      ? formatStringDate(
                                          ptoselect.dateTakenStart
                                        )
                                      : formatStringDate(
                                          ptoselect.startDate
                                        )}{' '}
                                  </b>{' '}
                                  to{' '}
                                  <b>
                                    {' '}
                                    {ptoselect.dateTakenEnd
                                      ? formatStringDate(ptoselect.dateTakenEnd)
                                      : formatStringDate(ptoselect.endDate)}
                                  </b>
                                  ? <br></br>
                                  <br></br>
                                  {(currentYear ===
                                    (ptoselect.startDate
                                      ? moment(ptoselect.startDate)
                                          .utc()
                                          .year()
                                      : moment(ptoselect.dateTakenStart)
                                          .utc()
                                          .year()) &&
                                  currentYear ===
                                    (ptoselect.endDate
                                      ? moment(ptoselect.endDate)
                                          .utc()
                                          .year()
                                      : moment(ptoselect.dateTakenEnd)
                                          .utc()
                                          .year())
                                  ? true
                                  : false)
                                    ? `Remember that these days will be returned to the employee.`
                                    : `These days won't be returned to the employee.`}
                                </p>
                              </div>
                            </div>
                          }
                          centered
                          disabled={deletePtoLoading}
                          disableUpdate={deletePtoLoading}
                        />
                        <ModalAlert
                          show={editModal}
                          onClose={() => seteditModal(false)}
                          noIcon
                          onConfirm={() => {
                            editPto(ptoselect, employee.id);
                          }}
                          disableUpdate={
                            editStartDate && editEndDate ? false : true
                          }
                          buttonCancel="Cancel"
                          confirmLabel="Update PTO"
                          buttonCancelStyle="btn-danger-without-bg"
                          content={
                            <div className="text-left">
                              <div>
                                <span className="sub-heading-editpto">
                                  Edit PTO
                                </span>
                              </div>
                              <div className="content-alert-edit">
                                <div className="innerLabel">
                                  <label className="label-input-edit">
                                    Start Date
                                  </label>
                                  <DatePickerModule
                                    principalClass={'inner-calendar-pto-alert'}
                                    weekendDisabled
                                    onChange={value => seteditStartDate(value)}
                                    onClick={() => resetFunction()}
                                    minDate={
                                      new Date(new Date().setMonth(0, 1))
                                    }
                                    selected={editStartDate}
                                    maxDate={new Date().setMonth(
                                      new Date().getMonth() + 24
                                    )}
                                    placeholderText="MM/DD/YYYY"
                                  />
                                </div>
                                <div className="innerLabel">
                                  <label>End Date</label>
                                  <DatePickerModule
                                    principalClass={'inner-calendar-pto-alert'}
                                    weekendDisabled
                                    onChange={value => seteditEndDate(value)}
                                    onClick={() => resetFunction()}
                                    minDate={new Date(editStartDate)}
                                    selected={editEndDate}
                                    maxDate={new Date().setMonth(
                                      new Date().getMonth() + 24
                                    )}
                                    placeholderText="MM/DD/YYYY"
                                  />
                                </div>
                                <div className="innerLabel ">
                                  <label className="label-input-edit">
                                    Note
                                  </label>
                                  <div className="note-ptoadmin bottom-ptoadmin">
                                    <textarea
                                      onChange={({ target }) =>
                                        setnote(target.value)
                                      }
                                      placeholder="Text Example"
                                      rows="6"
                                      cols="43"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </>
                    ) : (
                      <div className="card-pto-no-data">
                        <CheckMark color="black" />
                        <h5 className="text-normal">
                          No PTO or sick Days request
                        </h5>
                      </div>
                    )
                  ) : null}
                </div>
              </>
            )}
          </Row>
        </div>
      </ContentPTO>
    </>
  );
};

export default Ptoadmin;
