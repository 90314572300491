import styled from 'styled-components';

export const TabsPtoFragment = styled.div`


.tabs-pto {
      display: flex;
       border-bottom: 1px solid #e1e4e8;
       cursor: default;

        .pto-title {
           padding: 20px 20px 15px 20px;
           height: 59px;
           width: 210px;
           font-weight: 700;
           text-align: center;
           border-bottom: 0px;
           margin: 0px;

           span {
            color: #172c45;
            font-size: 16px;
            }

        }
        
        .pto-title:hover {
          border-bottom: 5px solid #959DA5;
        }

          .pto-title-selected {
            padding: 20px 20px 15px 20px;
            height: 59px;
            width: 210px;
            font-weight: 700;
            text-align: center;
            border-bottom: 5px solid #18d399;
            background-color: #f0fff4;
            margin: 0px;
            
            span {
              color: #172c45;
              font-size: 16px;
              }
          }
}

.content-tabs {
                height: 408px;
                padding: 20px;
                padding-left: 10px;

                & .card-pto-data {
                     height: 72%;
                     overflow-y: auto;
                     overflow-x: hidden;
    
                   .loading {
                    text-align: center;
                    padding: 50px;
                    }
                 }
    
                & .pto-item {
                      width: 100%
                      display: inline-flex;
                      padding: 7px 2px;
                      padding-left: 10px;
                      border-radius: 5px;
          
    
                      & .pto-icon {
                        margin-top: 3px;
                        padding: 0;
                        width: auto;

                       }

                       & .pto-date-status {
                        width: 70%;
                        padding: 0 10px;

                         & .pto-dates{
                          font-weight: 600;
                          font-size: 14px;
                          color: #24292e;
                         }
                          & .pto-status {
                          color: #24292e;
                          font-size: 14px;
                        }
                      }

                      .ptobtns- {
                        margin-left: 220px;
                        margin-top: 10px;
                        width: 87px;
                        height: 32px;

                          & .icon-status-approved {
                           text-align: right;

                            & span {
                             font-weight: 600;
                             margin-left: 5px;
                             color: #24292E;
                           }
 
                        }

                          & .icon-status-declined {
                             text-align: right;

                               & span {
                              font-weight: 600;
                              margin-left: 12px;
                              color: #24292E;
                           }

                        }


                      }

                  }

                }
                .pto-item:hover {
                  background: #d1dff0;
                }
}
 @media screen and (max-width: 1024px){
    .tabs-pto {
      width: 100%;
      .pto-title {
        width: auto;
      }
      .pto-title-selected {
        width: auto;
      }

    }
    .mobile-tab{
      width: 50%;
    }
  }
    `;

