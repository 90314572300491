import React from 'react';

const AddUser = ({ color = 'white', width = "17", height = "15" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5001 14V13.4375C13.5001 11.675 12.0676 10.25 10.3126 10.25H4.68755H4.68005C2.91755 10.25 1.49255 11.675 1.49255 13.4375V13.9925"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 1.25C6.70435 1.25 5.94129 1.56607 5.37868 2.12868C4.81607 2.69129 4.5 3.45435 4.5 4.25C4.5 5.04565 4.81607 5.80871 5.37868 6.37132C5.94129 6.93393 6.70435 7.25 7.5 7.25C8.29565 7.25 9.05871 6.93393 9.62132 6.37132C10.1839 5.80871 10.5 5.04565 10.5 4.25C10.5 3.45435 10.1839 2.69129 9.62132 2.12868C9.05871 1.56607 8.29565 1.25 7.5 1.25Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 5.75V8.75"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 7.25H12.75"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddUser;
