import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { ContentTable } from '../../../components/GenericTable/style';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PERMISSION_MUTATION } from '../../../graphql/mutations/permissions';
import { ModalAlert } from '../../../components/Modals';
import { routesOwner, routesManager } from '../../../services/Constants/paths';
import { useNavigate } from 'react-router-dom';
import ArrowSort from '../../../components/Icons/ArrowSort';

const TablePermissions = ({
  loading,
  columns,
  currentEmployees,
  setCurrentEmployees,
  setCurrentSearch,
  setOrder,
  order,
  setOrderBy
}) => {
  const navigate = useNavigate();
  const [updatePermission] = useMutation(UPDATE_PERMISSION_MUTATION);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTeams, setOpenModalTeams] = useState(false);
  const [teams, setTeams] = useState([]);
  const [newPermission, setNewPermission] = useState();
  const [employeeId, setEmployeeId] = useState();

  const openModalFunction = (permission, employeeId, oldPermission) => {
    const oldPermissionId = columns
      ?.filter(el => el.id === oldPermission)
      ?.map(el => el?.id);
    const employee = currentEmployees?.filter(el => el.id === employeeId);
    const teamsIsManager = employee?.[0]?.employeeTeam?.filter(
      el => el?.isManager === true
    );
    if (oldPermission === permission?.id) {
      return;
    }
    if (oldPermissionId?.length < 1) {
      permissionUpdate(permission, employeeId);
    }
    if (oldPermissionId?.[0] === '151e7240-17c8-49b3-9d0f-2702f63a3437') {
      setNewPermission(permission);
      setEmployeeId(employeeId);
      setOpenModal(true);
    }
    if (
      oldPermissionId.length > 0 &&
      oldPermissionId?.[0] !== '151e7240-17c8-49b3-9d0f-2702f63a3437'
    ) {
      if (
        teamsIsManager?.length &&
        permission?.id === '151e7240-17c8-49b3-9d0f-2702f63a3437'
      ) {
        setTeams(teamsIsManager);
        setOpenModalTeams(true);
      } else {
        setNewPermission(permission);
        setEmployeeId(employeeId);
        setOpenModal(true);
      }
    }
  };

  const permissionUpdate = (permission, employeeId) => {
    try {
      updatePermission({
        variables: {
          permissionId: permission?.id,
          employeeId
        }
      });
      setCurrentEmployees(prevState => {
        const newState = prevState.map(obj => {
          if (obj.id === employeeId) {
            return { ...obj, permissionEmployee: permission };
          }
          return obj;
        });

        return newState;
      });

      setCurrentSearch(prevState => {
        const newState = prevState.map(obj => {
          if (obj.id === employeeId) {
            return { ...obj, permissionEmployee: permission };
          }
          return obj;
        });

        return newState;
      });
      
      setOpenModal(false);
    } catch (error) {}
  };

  const goToTeam = teamId => {
    navigate(routesOwner.createEditTeams(), {
      state: { teamId: teamId },
      replace: true
    });
  };

  return (
    <>
      <ModalAlert
        show={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={() => permissionUpdate(newPermission, employeeId)}
        alertClass="alertPermissions"
        buttonCancelStyle="btn-danger-without-bg-width"
        buttonSuccess="btn-success-width"
        flexButtons="flexButtons"
        content={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className="text-normal">
              ¿Are you sure you want to change this User permissions?
            </p>
          </div>
        }
        centered
        confirmLabel="Change permissions"
      />
      <ModalAlert
        show={openModalTeams}
        onClose={() => setOpenModalTeams(false)}
        onConfirm={() => navigate(routesManager.listTeams(), {
          state: { tab: 1 },
        })}
        alertClass="alertPermissions"
        buttonCancelStyle="btn-danger-without-bg-width"
        buttonSuccess="btn-success-width"
        flexButtons="flexButtons"
        content={
          <div className="modalPermission">
            <div>
              <p className="text-normal">
                To make this change first you must assign a new manager for the
                following teams:
              </p>
            </div>
            <div className="permissionsTeams">
              <span className="weightPermissions">
                <b>Team's:</b>
              </span>
              <ul>
                {teams.map(el => (
                  <li
                    className="icon-add-input"
                    onClick={() => goToTeam(el?.teamId)}
                  >
                    <u>{el?.team?.name}</u>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
        centered
        confirmLabel="Go To Teams"
      />
      <ContentTable>
        {!loading ? (
          <table>
            <thead>
              <tr>
                <th
                  key={`column-table1`}
                  className="th-align sorting_disabled text-bold"
                  style={{
                    width: '3%'
                  }}
                >
                  <div className="sortTitle">
                    <span>Name</span>
                    <div className="arrowSort">
                      <button
                        className="buttonSort"
                        onClick={() => {
                          setOrder(!order);
                          setOrderBy('name');
                        }}
                      >
                        <ArrowSort />
                      </button>
                    </div>
                  </div>
                </th>
                <th
                  key={`column-table`}
                  className="th-align sorting_disabled text-bold"
                  style={{
                    width: '3%'
                  }}
                >
                  <div className="sortTitle">
                    <span>Role</span>
                    <div className="arrowSort">
                      <button
                        className="buttonSort"
                        onClick={() => {
                          setOrder(!order);
                          setOrderBy('role');
                        }}
                      >
                        <ArrowSort />
                      </button>
                    </div>
                  </div>
                </th>
                {columns?.length
                  ? columns.map((item, i) => {
                      return (
                        <th
                          key={`column-table-${i}`}
                          className="sorting_disabled text-bold"
                          style={{
                            width: item.width
                          }}
                        >
                          <div className="sortTitleCheck">
                            <span>{item.description}</span>
                          </div>
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            <tbody>
              {currentEmployees?.map((employee, i) => (
                <tr key={`check-${i}`}>
                  <td className="td-permissions">{employee?.fullName}</td>
                  <td className="td-permissions">
                    {employee?.employeeRoleInfo?.name}
                  </td>
                  {columns.map((col, index) => (
                    <td key={index} className="columnPermission td-permissions">
                      <div
                        className="radioContainer"
                        onClick={() =>
                          openModalFunction(
                            col,
                            employee.id,
                            employee?.permissionEmployee?.id
                          )
                        }
                      >
                        <div>
                          <input
                            type="radio"
                            checked={
                              col?.id === employee?.permissionEmployee?.id
                            }
                          />

                          <span className="checkmark"></span>
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '1000px'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </div>
        )}
      </ContentTable>
    </>
  );
};

export default TablePermissions;
