import gql from 'graphql-tag';

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CREATE_EMPLOYEE_MUTATION(
    $createEmployeeInput: CreateEmployeeInput!
  ) {
    createEmployee(createEmployeeInput: $createEmployeeInput) {
      message
      code
      employee {
        id
        fullName
      }
    }
  }
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation CREATE_TEAM_MUTATION(
    $teamName: String!
    $companyId: String!
    $members: [MembersTeamInput!]!
    $manager: MembersTeamInput!
  ) {
    createTeam(
      CreateTeamInput: {
        name: $teamName
        companyId: $companyId
        members: $members
        manager: $manager
      }
    )
  }
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation DELETE_TEAM_MUTATION($list: [removeListInput!]!) {
    removeTeams(list: $list)
  }
`;

export const EDIT_TEAM_MUTATION = gql`
  mutation EDIT_TEAM_MUTATION(
    $id: String!
    $name: String!
    $currentManager: MembersTeamInput!
    $currentMembers: [MembersTeamInput!]
  ) {
    editTeam(
      UpdateTeamInput: {
        id: $id
        name: $name
        currentManager: $currentManager
        currentMembers: $currentMembers
      }
    )
  }
`;
