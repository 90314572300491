import React from 'react';

const Coin = ({ color = 'white', size = '18' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 4.5V5.625"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7732 4.22246C16.4057 6.85496 16.4057 11.13 13.7732 13.7625C11.1332 16.395 6.8582 16.395 4.2257 13.7625C1.5857 11.1225 1.5857 6.84746 4.22495 4.21496C6.85745 1.57496 11.1325 1.57496 13.765 4.21421"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5V12.375"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75004 10.6911C6.75004 11.6211 7.50004 12.3786 8.43754 12.3786H9.66754C10.5375 12.3786 11.2425 11.6661 11.2425 10.7961C11.2425 10.0686 10.7498 9.43865 10.0425 9.25865L7.92754 8.72615C7.22254 8.54615 6.72754 7.9184 6.72754 7.18865C6.72754 6.31115 7.43254 5.6084 8.30254 5.6084H9.53254C10.4625 5.6084 11.22 6.3584 11.22 7.2959"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Coin;
