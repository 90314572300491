import styled from 'styled-components';

export const InputContainer2 = styled.div`
  &.bankCity {
    width: 48%;
  }
  &.bankPostalCode {
    width: 17%;
  }
  .icon input {
    padding-left: 37px;
  }
  .inputRelative {
    position: relative;

    svg {
      position: absolute;
      top: 9px;
      left: 6px;
      color: #72809d;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100%;
  }

  /** active label */
  &.active {
    label {
      //move the x coordinate and reduce size
      transform: translate(0, 4px) scale(0.75);
    }
  }
`;

export const CheckboxContainer = styled.div`
  height: 20px;
  .checkbox {
    position: relative;
    display: inline-block;
    label {
      position: relative;
      min-height: 21px;
      display: block;
      padding-left: 21px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 4px;
        width: 13px;
        height: 13px;
        transition: transform 0.28s ease;
        border-radius: 3px;
        border: 1px solid #172c45;
      }
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 4px;
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        transform: rotate(-45deg) scale(0);
        transition: transform ease 0.25s;
        position: absolute;
        top: 8px;
        left: 7px;
      }
    }
    input[type='checkbox'] {
      width: auto;
      opacity: 0.00000001;
      position: absolute;
      left: 0;
      margin-left: -20px;
      &:checked ~ label {
        &:before {
          border: 1px solid #172c45;
          background: #172c45;
          border-radius: 4px;
        }
        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }
      &:focus + label::before {
        outline: 0;
      }
    }
  }
`;

export const InputContainer = styled.input`
  padding: 2% 8%;
  border: 1px solid #d1d5da;
  border-radius: 5px;
`;
