import styled from 'styled-components';

export const ContentSelectProfile = styled.div`
    margin-top: 100px;
    text-align:center
    .content-text {
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items: center;
        .title-select {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            align-items: center;
            text-align: center;
            letter-spacing: 0.4px;
            color: #000000;        
        }
        .text-select {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.15px;
            font-style: normal;
            text-align: center;
            width: 413px;
            margin-bottom: 40px;
            margin-top:10px;
        }
    }
    .cards-select{
        display:flex;
        justify-content:center;
        .card-select {
            height: 304px;
            width: 335px;
            background-color:#FFFFFF;
            border-radius: 10px;
            border: 1px solid #D1D5DA;
            padding: 27.9px 73px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            .btn-select {
                height: 44px;
                width: 194px;
                left: 461px;
                top: 627px;
                border-radius: 6px;
                padding: 12px 20px 12px 20px;
                background-color: #18D399;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                margin-top:30px;
            }
        }
        .card-select:hover {
            border-radius: 10px;
            border: 1px solid #34D058;
        }
    }
`;