import React, { createContext, useState } from 'react';

export const AlertMessageContext = createContext({
  successMessage: [],
  errorMessage: [],
  setSuccessMessage: () => null,
  setErrorMessage: () => null
});

export const AlertMessageProvider = props => {
  const [successMessage, setSuccessMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleErrorMessage = (value, remove) => {
    if (remove) {
      setErrorMessage(prev => {
        const previous = prev;
        const res = previous.slice(1);
        return [...res];
      });
    } else {
      setErrorMessage(prev => {
        const previous = prev;
        previous.push({ value, key: Math.random() });
        return [...previous];
      });
    }
  };

  const handleSuccessMessage = (value, remove) => {
    if (remove) {
      setSuccessMessage(prev => {
        const previous = prev;
        const res = previous.slice(1);
        return [...res];
      });
    } else {
      setSuccessMessage(prev => {
        const previous = prev;
        previous.push({ value, key: Math.random() });
        return [...previous];
      });
    }
  };

  return (
    <AlertMessageContext.Provider
      value={{
        successMessage,
        setSuccessMessage: (value, remove = false) =>
          handleSuccessMessage(value, remove),
        errorMessage,
        setErrorMessage: (value, remove = false) =>
          handleErrorMessage(value, remove)
      }}
    >
      {props.children}
    </AlertMessageContext.Provider>
  );
};
