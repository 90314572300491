import styled from 'styled-components';
import * as react from 'react-bootstrap';

export const Col = styled(react.Col)`
  padding: ${props => (props.padding ? props.padding : 'none')};
  border: ${props => (props.border ? 'solid' : 'none')};
  display: ${props => (props.display ? 'none' : props.display)};
  background: ${props => (props.background ? props.background : 'none')};
  height: ${props => (props.height ? props.height : 'fit-content')};
  margin: ${props => (props.margin ? props.margin : '0%')};
`;

export const Row = styled(react.Row)`
  padding: ${props => (props.padding ? props.padding : 'none')};
  border: ${props => (props.border ? 'solid' : 'none')};
  display: ${props => (props.display ? 'none !important' : props.display)};
  background: ${props => (props.background ? props.background : 'none')};
  height: ${props => (props.height ? props.height : 'fit-content')};
  margin: ${props => (props.margin ? props.margin : '0%')};
`;
