import React from 'react';

const EyeClose = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00007 14.2499C8.36857 14.2499 7.73632 14.1164 7.12207 13.8787"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6615 9.35107C14.2425 11.9753 11.6212 14.2501 9 14.2501"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3091 6.69067C14.8273 7.29742 15.2878 7.95892 15.6613 8.64967C15.7791 8.86792 15.7791 9.13267 15.6613 9.35092"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 14.25L14.25 3.75"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32957 10.6703C6.40707 9.74783 6.40707 8.25158 7.32957 7.32908C8.25207 6.40658 9.74832 6.40658 10.6708 7.32908"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7828 5.217C11.6226 4.31925 10.3108 3.75 8.99981 3.75C6.37856 3.75 3.75731 6.02475 2.33831 8.64975C2.22056 8.868 2.22056 9.13275 2.33831 9.351C3.04781 10.6628 4.05731 11.8868 5.21681 12.7838"
        stroke="#D1D5DA"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeClose;
