import React from 'react';


const Add = () => {
  return (
    <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >  
        <path 
        d="M9.5 2V15" 
        stroke="#24292E" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />

        <path 
        d="M3 8.5H16" 
        stroke="#24292E" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />


    </svg>
  );
};

export default Add;