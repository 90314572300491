import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'react-bootstrap';

import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { ACTIVATE_EMAIL_PROFILE_MUTATION } from '../../../graphql/mutations/profile';
import WellDone from '../../../components/Icons/WellDone';
import { ContentActivateEmail } from './styles';
import AnyError from '../AnyError';
import { routesAdmin, routesEmployee } from '../../../services/Constants/paths';
import { AppContext } from '../../../Context/App/AppContext';

const ActivateEmail = () => {
  const { isAdmin } = useContext(AppContext);
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search.slice(1);
  const params = search
    .split('id=')
    .pop()
    .split('&email=');
  const employeeId = params[0];
  const email = params[1];
  const [couldActivated, setCouldActivated] = useState();

  const [activateEmailProfile, { loading }] = useMutation(
    ACTIVATE_EMAIL_PROFILE_MUTATION
  );

  const submitActivateEmailProfile = async () => {
    try {
      await activateEmailProfile({
        variables: {
          employeeId,
          email
        }
      });
      setCouldActivated(true);
    } catch (error) {
      setCouldActivated(false);
    }
  };

  useEffect(() => {
    if (email && employeeId) {
      submitActivateEmailProfile();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [email, employeeId]);

  if (!isMounted.current || loading) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  } else if (couldActivated) {
    return (
      <ContentActivateEmail>
        <WellDone />
        <p className="heading-h1">Congrats!</p>
        <p className="heading-h3">Your email has been verified</p>
        <Button
          className="btn-success"
          onClick={() =>
            navigate(isAdmin ? routesAdmin.home : routesEmployee.profile)
          }
        >
          <span>Go To Profile</span>
        </Button>
      </ContentActivateEmail>
    );
  } else return <AnyError />;
};

export default ActivateEmail;
