import React from 'react';



const Subtract = () => {
  return (
        <svg 
        width="15" 
        height="3" 
        viewBox="0 0 15 3" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >

        <path 
        d="M1 1.5H14" 
        stroke="white" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>



    </svg>
  );
};

export default Subtract;