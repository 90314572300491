import React from 'react';

const Roles = ({ size = '18' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 15.7503L14.2425 15.7495C15.0675 15.7495 15.7425 15.0745 15.7425 14.2495"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 14.25V11.25"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 15.75H10.5"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49999 2.25H3.74999H3.74249C2.90999 2.25 2.24249 2.9175 2.24249 3.75V6"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 8.25019H11.25L11.2425 8.24944C10.41 8.24194 9.74249 7.57444 9.74249 6.74194V3.74194C9.73499 2.90944 10.41 2.23444 11.235 2.23444H14.235H14.2275C15.0525 2.22694 15.7275 2.90194 15.7275 3.72694V6.72694H15.7267C15.7267 7.55194 15.0517 8.21944 14.2267 8.22694L14.25 8.25019Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7707 4.61182L12.4957 5.88682L11.7232 5.12182"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49999 15.7504H2.62499H2.61749C2.40749 15.7429 2.24249 15.5779 2.24249 15.3679V15.0604H2.23499C2.23499 14.1904 2.93249 13.4854 3.80249 13.4854H6.28499C7.14749 13.4854 7.85249 14.1829 7.85249 15.0529V15.3529C7.85249 15.5554 7.67999 15.7279 7.47749 15.7279L7.49999 15.7504Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.06249 11.6249L5.05499 11.6242C4.22249 11.6167 3.55499 10.9492 3.55499 10.1167C3.55499 9.2842 4.22249 8.6167 5.05499 8.6167C5.87999 8.6167 6.54749 9.2842 6.54749 10.1092C6.54749 10.9342 5.87999 11.6092 5.04749 11.6092C5.03999 11.6092 5.03999 11.6092 5.03999 11.6092L5.06249 11.6249Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Roles;
