import { css } from 'styled-components';

export const typography = css`
  .heading-h1,
  .heading-h2,
  .heading-h4,
  .text-normal,
  .text-bold,
  .text-small,
  .text-medium-bold {
    color: #24292e;
    font-style: normal;
  }

  .heading-h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  }

  .heading-h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .heading-h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .heading-h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .text-normal {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .manager-ptos {
    width: 28px;
    height: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    border-radius: 20px;
    margin-left: 10px;
    background: rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }

  .text-small {
    color: #24292e !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .text-medium-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }

  .text-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .sub-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #24292e;
    letter-spacing: 0.3px;
  }

  .text-gray-light {
    color: #6a737d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .text-underline {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0366d6;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .text-create-role-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-top: 22px;
  }

  .text-create-role-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }

  .create-role-input {
    height: 44px;
  }
`;
