import React from 'react';

const SliderOn = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="16" rx="8" fill="#172C45" />
      <g filter="url(#filter0_dd_4429_75250)">
        <rect x="15" y="3" width="10" height="10" rx="4.5" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_dd_4429_75250"
          x="8"
          y="0"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.263201 0 0 0 0 0.335725 0 0 0 0 0.393257 0 0 0 0.123689 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4429_75250"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.264489 0 0 0 0 0.275745 0 0 0 0 0.28861 0 0 0 0.01 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4429_75250"
            result="effect2_dropShadow_4429_75250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4429_75250"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SliderOn;
