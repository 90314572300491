import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import SelectCheckbox from '../../Icons/SelectCheckbox';
import { ButtonFilterContainer } from './styles';

const ButtonFilter = ({
  icon = true,
  data = [],
  setData,
  classButton,
  label = 'APPLY'
}) => {
  const [columns, setColumns] = useState(data);
  const activeFields = columns.filter(el => el.active);
  const onSetColumns = item => {
    setColumns(prev => {
      const colTable = prev;
      colTable.forEach(col => {
        if (activeFields.length > 1) {
          if (col.name === item.name) {
            col.active = !item.active;
          }
        } else {
          if (col.name === item.name) {
            col.active = true;
          }
        }
      });
      return [...colTable];
    });
  };

  return (
    <ButtonFilterContainer>
      <Dropdown
        onToggle={(isOpen, e) => {
          if (e?.source === 'rootClose') {
            setColumns(JSON.parse(JSON.stringify(data)));
          }
        }}
      >
        <Dropdown.Toggle className={classButton || 'btn-success'}>
          {icon ?? <SelectCheckbox />}
          <span className="pad-icon">Fields</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {columns.map((item, i) => {
            return (
              <Dropdown.Item
                key={`${item.name}-${i}`}
                onClick={e => {
                  e.stopPropagation();
                  onSetColumns(item);
                }}
              >
                <FormControlLabel
                  control={<Checkbox color="default" checked={item.active} />}
                  label={item.name}
                />
              </Dropdown.Item>
            );
          })}
          <Dropdown.Item className="btn-apply">
            <Button className="btn-success" onClick={() => setData(columns)}>
              {label}
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ButtonFilterContainer>
  );
};

export default ButtonFilter;
