import React from 'react';

import Schedule from './Schedule';
import Cherry from './Cherry';
import Flags from './Flags';
import Doc from './Doc';
import Pdf from './Pdf';
import Zip from './Zip';
import Excel from './Excel';
import FileImg from './FileImg';
import File from './File';
import AddUser from './AddUser';
import Heart from './Heart';
import { ContentIcons } from './styles';

const IconEvents = ({ icon }) => {
  const optionIcons = item => {
    switch (item) {
      case 'pto':
        return (
          <div className="circle-events pto">
            <Schedule />
            <div>PTO</div>
          </div>
        );
      case 'sick':
        return (
          <div className="circle-events pto">
            <Heart />
            <div>SICK</div>
          </div>
        );
      case 'birthday':
        return (
          <div className="circle-events bday">
            <Cherry />
            <div>BDAY</div>
          </div>
        );
      case 'year':
        return (
          <div className="circle-events year">
            <Flags />
            <div>YEAR</div>
          </div>
        );
      case 'new-profile':
        return (
          <div className="circle-events add-user">
            <AddUser />
            <div>New</div>
          </div>
        );
      default:
        break;
    }
  };

  return <ContentIcons>{optionIcons(icon)}</ContentIcons>;
};

const SelectIconTypeFile = ({ type }) => {
  const optionIcons = item => {
    switch (item) {
      case 'doc':
      case 'docx':
      case 'pages':
      case 'txt':
        return <Doc />;
      case 'pdf':
        return <Pdf />;
      case 'zip':
      case 'rar':
        return <Zip />;
      case 'csv':
      case 'xls':
      case 'xlsx':
        return <Excel />;
      case 'png':
      case 'jpg':
        return <FileImg />;
      default:
        return <File />;
    }
  };

  return <>{optionIcons(type)}</>;
};

export { IconEvents, SelectIconTypeFile };
