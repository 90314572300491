import gql from 'graphql-tag';

export const UPDATE_ME_PROFILE_MUTATION = gql`
  mutation UPDATE_ME_PROFILE_MUTATION(
    $employeeId: String!
    $field: String!
    $table: String!
    $value: String!
    $userId: String!
    $list: [String!]
  ) {
    updateMeProfile(
      profileInput: {
        employeeId: $employeeId
        field: $field
        table: $table
        value: $value
        userId: $userId
        list: $list
      }
    )
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UPDATE_PROFILE_MUTATION(
    $employeeId: String!
    $field: String!
    $table: String!
    $value: String!
    $userId: String!
    $list: [String!]
  ) {
    updateProfile(
      profileInput: {
        employeeId: $employeeId
        field: $field
        table: $table
        value: $value
        userId: $userId
        list: $list
      }
    )
  }
`;

export const ACTIVATE_EMAIL_PROFILE_MUTATION = gql`
  mutation ACTIVATE_EMAIL_PROFILE_MUTATION(
    $employeeId: String!
    $email: String!
  ) {
    activateEmailProfile(
      activateEmailInput: { employeeId: $employeeId, email: $email }
    )
  }
`;
