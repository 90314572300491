import React from 'react';

const Pdf = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="5" fill="#E94848" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32664 5.59998C8.8023 5.59998 8.37109 6.03119 8.37109 6.55553C8.37109 7.20736 8.73326 8.01659 9.11463 8.7772C8.81652 9.71038 8.47825 10.7104 8.0456 11.5543C7.16009 11.9017 6.37039 12.16 5.8956 12.5427C5.8882 12.5503 5.88122 12.5582 5.8747 12.5666C5.7012 12.744 5.59998 12.9856 5.59998 13.2444C5.59998 13.7688 6.03119 14.2 6.55553 14.2C6.81122 14.2 7.05802 14.1046 7.23636 13.9223C7.24254 13.9175 7.24851 13.9126 7.25428 13.9073C7.60377 13.49 8.01586 12.733 8.38303 12.041C9.22925 11.7081 10.1162 11.3694 10.972 11.1661C11.5968 11.6696 12.5006 12.0022 13.2444 12.0022C13.7688 12.0022 14.2 11.571 14.2 11.0466C14.2 10.5223 13.7688 10.0911 13.2444 10.0911C12.6479 10.0911 11.7811 10.304 11.1183 10.5271C10.5811 10.0227 10.0875 9.39285 9.73275 8.72345C9.98622 7.94262 10.2822 7.16133 10.2822 6.55553C10.2822 6.03119 9.85099 5.59998 9.32664 5.59998ZM9.32664 6.17331C9.54113 6.17331 9.70887 6.34104 9.70887 6.55553C9.70887 6.84233 9.55529 7.37038 9.37741 7.9381C9.13972 7.38582 8.94442 6.85595 8.94442 6.55553C8.94442 6.34104 9.11215 6.17331 9.32664 6.17331ZM9.49088 9.4431C9.77828 9.90025 10.1149 10.3256 10.4823 10.7032C9.9156 10.8582 9.36217 11.0567 8.81602 11.2646C9.0798 10.6693 9.29097 10.0515 9.49088 9.4431ZM13.2444 10.6644C13.4589 10.6644 13.6266 10.8322 13.6266 11.0466C13.6266 11.2611 13.4589 11.4289 13.2444 11.4289C12.8138 11.4289 12.2018 11.2345 11.7125 10.963C12.2741 10.8042 12.8869 10.6644 13.2444 10.6644ZM7.54095 12.3755C7.27223 12.8551 7.00513 13.3028 6.81831 13.5281C6.75449 13.5891 6.66959 13.6266 6.55553 13.6266C6.34104 13.6266 6.17331 13.4589 6.17331 13.2444C6.17331 13.1435 6.21621 13.0432 6.27484 12.9787C6.499 12.8052 6.98667 12.5973 7.54095 12.3755Z"
        fill="white"
      />
    </svg>
  );
};

export default Pdf;
