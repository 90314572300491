import React from 'react';

const Schedule = ({ color = 'white', size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 6H2.25"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 9.75H13.1175C14.9775 9.7425 16.4925 11.2575 16.4925 13.1175C16.4925 14.9775 14.9775 16.4925 13.1175 16.4925C11.25 16.4925 9.74251 14.9775 9.73501 13.1175C9.72751 11.25 11.2425 9.735 13.1025 9.735C13.1025 9.73425 13.1025 9.73425 13.1025 9.73425"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0025 15.75H4.49253L4.48503 15.7493C3.24003 15.7418 2.23503 14.7368 2.23503 13.4993V4.49925C2.22753 3.25425 3.24003 2.24175 4.47753 2.24175H13.4775H13.47C14.7075 2.23425 15.72 3.24675 15.72 4.49175V10.9965"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0448 11.9873V13.2998L14.0798 13.932"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.305 8.5875H10.2975C10.275 8.5875 10.26 8.6025 10.26 8.6175C10.26 8.6325 10.275 8.6475 10.29 8.6475C10.305 8.64 10.32 8.625 10.32 8.61C10.32 8.5875 10.2975 8.5725 10.2825 8.5725"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.05501 8.5875C5.03251 8.58 5.01751 8.6025 5.01751 8.6175C5.01001 8.6175 5.01001 8.6175 5.01751 8.6175L5.01001 8.61C5.01001 8.625 5.02501 8.64 5.04001 8.64C5.05501 8.6325 5.07001 8.6175 5.07001 8.6025C5.06251 8.58 5.04751 8.565 5.03251 8.565C5.02501 8.565 5.02501 8.565 5.02501 8.565"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.045 8.5875C8.0225 8.58 8.0075 8.6025 8.0075 8.6175C8 8.6175 8 8.6175 8.0075 8.6175L8 8.61C8 8.625 8.015 8.64 8.03 8.64C8.045 8.6325 8.06 8.6175 8.06 8.6025C8.0525 8.58 8.0375 8.565 8.0225 8.565C8.015 8.565 8.015 8.565 8.015 8.565"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.045 11.0225C8.0225 11.015 8.0075 11.0375 8.0075 11.0525C8 11.0525 8 11.0525 8.0075 11.0525L8 11.045C8 11.06 8.015 11.075 8.03 11.075C8.045 11.0675 8.06 11.0525 8.06 11.0375C8.0525 11.015 8.0375 11 8.0225 11C8.015 11 8.015 11 8.015 11"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.045 11.0225C5.0225 11.015 5.0075 11.0375 5.0075 11.0525C5 11.0525 5 11.0525 5.0075 11.0525L5 11.045C5 11.06 5.015 11.075 5.03 11.075C5.045 11.0675 5.06 11.0525 5.06 11.0375C5.0525 11.015 5.0375 11 5.0225 11C5.015 11 5.015 11 5.015 11"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.05503 13.0883C5.03253 13.0808 5.01753 13.1032 5.01753 13.1182C5.01003 13.1182 5.01003 13.1182 5.01678 13.1182L5.00928 13.1108C5.00928 13.1258 5.02428 13.1408 5.03928 13.1408C5.05428 13.1333 5.06928 13.1183 5.06928 13.1033C5.06178 13.0808 5.04678 13.0658 5.03178 13.0658C5.02428 13.0658 5.02428 13.0658 5.02428 13.0658"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Schedule;
