import React, { useEffect, useState } from 'react';
import { AccountCircle } from '@material-ui/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { NavBarContainer } from './styles';
import Profile from '../Icons/Profile';
import File from '../Icons/File';
import TeamOnBoardLogo from '../Icons/TeamOnBoardLogo';
import Home from '../Icons/Home';
import Persons from '../Icons/Persons';
import {
  routesOwner,
  routesEmployee,
  routesPublic,
  routesAdmin,
  routesManager
} from '../../services/Constants/paths';
import { PermissionsEmployee } from '../../services/Constants/getDefaults';
import SearchEmployee from '../SearchEmployee';

import Settings from '../Icons/Settings';
import { useContext } from 'react';
import { AppContext } from '../../Context/App/AppContext';

import LogOut from '../Icons/LogOut';
import Avatar from '../Icons/Avatar';
import Team from '../Icons/Team';
import SwitchRole from '../Icons/SwitchRole';
import { updateRole } from '../../services/Utils/employeeService';
import { usePtosManager } from '../../graphql/container/query/teams';
import BurgerMenu from '../Icons/BurgerMenu';
import SearchMobile from '../Icons/SearchMobile';
import CalendarMobile from '../Icons/CalendarMobile';
import CrossBurger from '../Icons/CrossBurger';
import ArrowLeft from '../Icons/ArrowLeft';
import { useMobile } from '../../customHooks/useMobile';

const Navbar = ({ user, photo, hideLinks = false }) => {
  const navigate = useNavigate();
  const [popMenu, setPopMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [height, setHeight] = useState(0);
  const swicth = window.localStorage.getItem('roleAdminEmployee');
  const { mobile, width } = useMobile();

  const {
    currentUser,
    roleUser,
    setroleUser,
    setIsAdmin,
    calendar,
    setCalendar,
    burgerMenu,
    setBurgerMenu
  } = useContext(AppContext);
  const roleOwnerOrAdmin =
    roleUser === PermissionsEmployee.OWNER ||
    roleUser === PermissionsEmployee.ADMIN;
  const roleOwner = roleUser === PermissionsEmployee.OWNER;
  const roleManger = roleUser === PermissionsEmployee.MANAGER;

  useEffect(() => {
    setHeight(window.innerHeight);
  }, [height, window.innerHeight]);

  useEffect(() => {
    if (!mobile) {
      setCalendar(false);
      setBurgerMenu(false);
      setSearch(false);
    }
    if (mobile) {
      setPopMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const handleNavigate = () => {
    if (roleOwnerOrAdmin) {
      return routesOwner.home;
    } else {
      return routesEmployee.pto;
    }
  };

  const handleMenu = () => {
    setPopMenu(!popMenu);
  };

  const updatePermission = role => {
    updateRole(role);
    setroleUser(role);
    if (role === PermissionsEmployee.EMPLOYEE) {
      setIsAdmin(false)
      navigate(routesEmployee.pto);
    }
    if (role === PermissionsEmployee.ADMIN) {
      setIsAdmin(true)
      navigate(routesAdmin.home);
    }
  };

  const handleClose = () => {
    localStorage.clear();
    navigate(routesPublic.login);
    document.location.reload();
  };

  window.addEventListener('click', function(event) {
    if (
      typeof event.target.className !== 'object' &&
      event?.target.className !== 'pop-menu' &&
      event?.target.className !== 'links-pop-menu' &&
      event?.target.className !== 'company-link' &&
      event?.target.className !== 'profile-link' &&
      event?.target.className !== 'text-pop' &&
      event?.target.className !== 'nameMenu' &&
      event?.target.id !== 'upperMenuRight' &&
      event?.target.id !== 'image-upper-menu' &&
      event?.target.id !== 'nameMenu'
    ) {
      if (popMenu) {
        setPopMenu(false);
      }
    }
    if (
      search &&
      event?.target.className !== 'motherClass' &&
      event?.target.className !== 'form-control' &&
      typeof event?.target.className !== 'object'
    ) {
      setSearch(false);
    }
  });

  const location = useLocation();
  const company = user?.companyRole[0].company;
  const companyId = currentUser?.companyRole[0].to;

  const {
    data: { getCantPtosManager }
  } = usePtosManager(currentUser?.employee?.id, companyId);

  return (
    <NavBarContainer>
      <div className="header">
        <div className="main-logo">
          <div className="image-logo">
            <Link to={handleNavigate()}>
              <TeamOnBoardLogo />
              <span className="text-normal">team</span>
              <span className="text-normal">on</span>
              <span className="text-normal">board</span>
            </Link>
          </div>
        </div>

        {!hideLinks && (
          <div className="sites-link">
            {roleOwnerOrAdmin ? (
              <div className="header-menu-nav">
                <Link to={routesOwner.home}>
                  <div
                    className={
                      routesOwner.home.includes(location.pathname)
                        ? 'buttons-uper-menu-mark'
                        : 'buttons-uper-menu'
                    }
                  >
                    <Home
                      color={
                        routesOwner.home.includes(location.pathname)
                          ? 'black'
                          : 'white'
                      }
                    />

                    <span
                      className={
                        routesOwner.home.includes(location.pathname)
                          ? 'text-mark'
                          : 'text-normal'
                      }
                    >
                      Home
                    </span>
                  </div>
                </Link>
                <Link to={routesOwner.employees}>
                  <div
                    className={
                      routesOwner.employees.includes(location.pathname)
                        ? 'buttons-uper-menu-mark'
                        : 'buttons-uper-menu'
                    }
                  >
                    <Persons
                      color={
                        routesOwner.employees.includes(location.pathname)
                          ? 'black'
                          : 'white'
                      }
                    />

                    <span
                      className={
                        routesOwner.employees.includes(location.pathname)
                          ? 'text-mark'
                          : 'text-normal'
                      }
                    >
                      Employees
                    </span>
                  </div>
                </Link>
                {roleOwnerOrAdmin && (
                  <Link to={routesManager.Teams}>
                    <div
                      className={
                        routesManager.Teams.includes(location.pathname)
                          ? 'buttons-uper-menu-mark'
                          : 'buttons-uper-menu'
                      }
                    >
                      <Team
                        color={
                          routesManager.Teams.includes(location.pathname)
                            ? 'black'
                            : 'white'
                        }
                      />

                      <span
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-mark'
                            : 'text-normal'
                        }
                      >
                        Team
                      </span>

                      <div
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-mark manager-ptos'
                            : 'text-normal manager-ptos'
                        }
                      >
                        {getCantPtosManager
                          ? getCantPtosManager < 9
                            ? getCantPtosManager
                            : '+' + getCantPtosManager
                          : ''}
                      </div>
                    </div>
                  </Link>
                )}
                {/* <div
                  className={
                    routesOwner.assets.includes(location.pathname)
                      ? 'buttons-uper-menu-mark'
                      : 'buttons-uper-menu'
                  }
                >
                  <div
                onClick={() => navigate(routesAdmin.assets, { replace: true })}
              > 
                <Assets
                  color={
                    routesOwner.assets.includes(location.pathname)
                      ? 'black'
                      : 'white'
                  }
                />
                <span
                  className={
                    routesOwner.assets.includes(location.pathname)
                      ? 'text-mark'
                      : 'text-normal'
                  }
                >
                  Assets
                </span>
                </div> */}
              </div>
            ) : (
              <>
                <Link to={routesEmployee.pto}>
                  <div>
                    <div
                      className={
                        routesEmployee.pto.includes(location.pathname)
                          ? 'buttons-uper-menu-mark'
                          : 'buttons-uper-menu'
                      }
                    >
                      <Home
                        color={
                          routesEmployee.pto.includes(location.pathname)
                            ? 'black'
                            : 'white'
                        }
                      />

                      <span
                        className={
                          routesEmployee.pto.includes(location.pathname)
                            ? 'text-mark'
                            : 'text-normal'
                        }
                      >
                        Home
                      </span>
                    </div>
                  </div>
                </Link>
                <Link to={routesEmployee.files}>
                  <div
                    className={
                      routesEmployee.files.includes(location.pathname)
                        ? 'buttons-uper-menu-mark'
                        : 'buttons-uper-menu'
                    }
                  >
                    <File
                      color={
                        routesEmployee.files.includes(location.pathname)
                          ? 'black'
                          : 'white'
                      }
                    />

                    <span
                      className={
                        routesEmployee.files.includes(location.pathname)
                          ? 'text-mark'
                          : 'text-normal'
                      }
                    >
                      Files
                    </span>
                  </div>
                </Link>
                <Link to={routesEmployee.profile}>
                  <div
                    className={
                      routesEmployee.profile.includes(location.pathname)
                        ? 'buttons-uper-menu-mark'
                        : 'buttons-uper-menu'
                    }
                  >
                    <Profile
                      color={
                        routesEmployee.profile.includes(location.pathname)
                          ? 'black'
                          : 'white'
                      }
                    />

                    <span
                      className={
                        routesEmployee.profile.includes(location.pathname)
                          ? 'text-mark'
                          : 'text-normal'
                      }
                    >
                      Profile
                    </span>
                  </div>
                </Link>
                {roleManger && (
                  <Link to={routesManager.Teams}>
                    <div
                      className={
                        routesManager.Teams.includes(location.pathname)
                          ? 'buttons-uper-menu-mark'
                          : 'buttons-uper-menu'
                      }
                    >
                      <Team
                        color={
                          routesManager.Teams.includes(location.pathname)
                            ? 'black'
                            : 'white'
                        }
                      />

                      <span
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-mark'
                            : 'text-normal'
                        }
                      >
                        Team
                      </span>

                      <div
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-mark manager-ptos'
                            : 'text-normal manager-ptos'
                        }
                      >
                        {getCantPtosManager
                          ? getCantPtosManager < 9
                            ? getCantPtosManager
                            : '+' + getCantPtosManager
                          : ''}
                      </div>
                    </div>
                  </Link>
                )}
              </>
            )}
          </div>
        )}
        <div className="info-user">
          <div className="nameMenu" onClick={() => handleMenu()}>
            <p id="nameMenu" className="text-bold">
              {currentUser?.employee?.fullName}
            </p>
          </div>
          <div
            id="upperMenuRight"
            className= { popMenu ? "upper-menu-right-active" : "upper-menu-right" }
            onClick={() => handleMenu()}
          >
            {currentUser && currentUser.photo ? (
              <img
                id="image-upper-menu"
                src={currentUser.photo}
                alt="Profile"
                referrerPolicy="no-referrer"
              />
            ) : (
              <div onClick={() => handleMenu()}>
                <Avatar />
              </div>
            )}
          </div>
          {popMenu && (
            <div id="principal" className="pop-menu">
              <div className="profile-link">
                {roleOwner && (
                  <div
                    className="company-link"
                    onClick={() => {
                      window.history.pushState(null, null, location.pathname);
                      if (roleOwner) {
                        navigate(routesOwner.companyDetail(), {
                          state: { site: 'companies' },
                          replace: true
                        });
                      } else {
                        navigate(routesEmployee.profile, {
                          state: { site: 'profile' },
                          replace: true
                        });
                      }
                    }}
                  >
                    <span className="icon-profile-main">
                      {photo ? (
                        <img
                          src={photo}
                          alt="PhotoUser"
                          referrerPolicy="no-referrer"
                        />
                      ) : (
                        <AccountCircle style={{ fontSize: 28 }} />
                      )}
                    </span>

                    <span className="company-name">
                      {roleOwnerOrAdmin ? company.name : user.name}
                    </span>

                    <div className="company-settings">
                      <Settings />
                    </div>
                  </div>
                )}
                {swicth && (
                  <div
                    className="swicth-profile"
                    onClick={() => {
                      window.history.pushState(null, null, location.pathname);
                      updatePermission(
                        swicth === '1'
                          ? PermissionsEmployee.EMPLOYEE
                          : PermissionsEmployee.ADMIN
                      );
                    }}
                  >
                    <span>
                      Switch to {swicth === '1' ? 'Employee' : 'Admin'}
                    </span>{' '}
                    <SwitchRole />
                  </div>
                )}
                {/* <div className="links-pop-menu">
                  <QuestionMark />
                  <span className="text-pop">Support</span>
                </div> */}
                <div className="links-pop-menu"  onClick={() => handleClose()}>
                  <LogOut />

                  <div
                    className="text-burger-company"
                  >
                    Log out
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mobile-header">
        <div className="mobile-menu">
          <div>
            {!search && !calendar && (
              <Link to={handleNavigate()}>
                <TeamOnBoardLogo />
              </Link>
            )}
            {calendar && (
              <button
                className="buttonCalendar"
                onClick={() => setCalendar(false)}
              >
                {' '}
                <ArrowLeft />
                Return
              </button>
            )}
          </div>
          <div className="mobile-items">
            {roleOwnerOrAdmin && !search && (
              <div
                className="mobile-search"
                onClick={() => {
                  setCalendar(false);
                  setBurgerMenu(false);
                  setSearch(!search);
                }}
              >
                <SearchMobile width="23" height="23" />
              </div>
            )}
            <div
              className="mobile-item"
              onClick={() => {
                setSearch(false);
                setBurgerMenu(false);
                setCalendar(!calendar);
              }}
            >
              <div className={calendar ? 'calendarPush' : ''}>
                <CalendarMobile
                  width={calendar ? '23' : '20'}
                  height={calendar ? '25' : '21'}
                  color={calendar ? '#24292E' : 'white'}
                />
              </div>
            </div>
            <div
              className="mobile-item"
              onClick={() => {
                setCalendar(false);
                setSearch(false);
                setBurgerMenu(!burgerMenu);
              }}
            >
              {burgerMenu ? (
                <div className="compensation-margin">
                  <CrossBurger width="17" height="17" />
                </div>
              ) : (
                <BurgerMenu width="20" height="21" />
              )}
            </div>
          </div>
        </div>

        {burgerMenu && (
          <div style={{ height: height }} className="burgerStyle">
            <div className="burgerHeader">
              <div className="burgerAvatar">
                {currentUser && currentUser.photo ? (
                  <div className="borderGreen">
                    <img
                      className="borderImage"
                      src={currentUser.photo}
                      alt="Profile"
                      referrerPolicy="no-referrer"
                    />
                  </div>
                ) : (
                  <div className="borderGreen">
                    <Avatar width="60" height="60" color="grey" />
                  </div>
                )}
              </div>
              <div className="burgerProfile">
                <p className="burgerName">{currentUser?.employee?.fullName}</p>
                <p className="burgerText">{currentUser?.email}</p>
              </div>
            </div>
            <div className="burgerPrimaryRoutes">
              {roleOwnerOrAdmin ? (
                <>
                  <Link to={routesOwner.home}>
                    <div
                      className={
                        routesOwner.home.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <Home
                        width="21"
                        height="21"
                        color={
                          routesOwner.home.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesOwner.home.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Home
                      </span>
                    </div>
                  </Link>

                  <Link to={routesOwner.employees}>
                    <div
                      className={
                        routesOwner.employees.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <Persons
                        size="21"
                        color={
                          routesOwner.employees.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesOwner.employees.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Employees
                      </span>
                    </div>
                  </Link>
                  <Link to={routesManager.Teams}>
                    <div
                      className={
                        routesManager.Teams.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <Team
                        width="21"
                        height="21"
                        color={
                          routesManager.Teams.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Team
                      </span>

                      <div
                        className={
                          routesManager.Teams.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        {getCantPtosManager
                          ? getCantPtosManager < 9
                            ? getCantPtosManager
                            : '+' + getCantPtosManager
                          : ''}
                      </div>
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link to={routesEmployee.pto}>
                    <div
                      className={
                        routesEmployee.pto.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <Home
                        width="21"
                        height="21"
                        color={
                          routesEmployee.pto.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesEmployee.pto.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Home
                      </span>
                    </div>
                  </Link>

                  <Link to={routesEmployee.files}>
                    <div
                      className={
                        routesEmployee.files.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <File
                        size="21"
                        color={
                          routesEmployee.files.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesEmployee.files.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Files
                      </span>
                    </div>
                  </Link>
                  <Link to={routesEmployee.profile}>
                    <div
                      className={
                        routesEmployee.profile.includes(location.pathname)
                          ? 'buttons-burger-menu-mark'
                          : 'buttons-burger-menu'
                      }
                    >
                      <Profile
                        size="21"
                        color={
                          routesEmployee.profile.includes(location.pathname)
                            ? '#24292E'
                            : '#6A737D'
                        }
                      />

                      <span
                        className={
                          routesEmployee.profile.includes(location.pathname)
                            ? 'text-burger-mark'
                            : 'text-burger-normal'
                        }
                      >
                        Profile
                      </span>
                    </div>
                  </Link>
                </>
              )}
            </div>
            <div className="ownerBurgerAccess">
              {roleOwner && (
                <>
                  <div
                    className="company-link-burger"
                    onClick={() => {
                      window.history.pushState(null, null, location.pathname);
                      if (roleOwner) {
                        navigate(routesOwner.companyDetail(), {
                          state: { site: 'companies' },
                          replace: true
                        });
                      } else {
                        navigate(routesEmployee.profile, {
                          state: { site: 'profile' },
                          replace: true
                        });
                      }
                    }}
                  >
                    <span className="icon-profile-burger">
                      {photo ? (
                        <img
                          src={photo}
                          alt="PhotoUser"
                          referrerPolicy="no-referrer"
                        />
                      ) : (
                        <AccountCircle style={{ fontSize: 28 }} />
                      )}
                    </span>

                    <span className="company-name">
                      {roleOwnerOrAdmin ? company.name : user.name}
                    </span>

                    <div className="company-settings-burger">
                      <Settings />
                    </div>
                  </div>
                </>
              )}
              <div className="links-burger">
                <LogOut />

                <div
                  onClick={() => handleClose()}
                  className="text-burger-company"
                >
                  Log out
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {roleOwnerOrAdmin &&
        location.pathname !== routesOwner.createEditTeams() && (
          <div className={search ? 'searchMobile' : 'searchContainer'}>
            <SearchEmployee
              dropdownClass={'dropdownClass'}
              arrow={search && mobile}
              setSearch={setSearch}
              search={search}
              mobile={mobile}
            />
          </div>
        )}
    </NavBarContainer>
  );
};

export default Navbar;
