import React from 'react';
import { AlertMessageProvider } from './AlertMessageContext';
import LoginProvider from './App/AppContext';

const composeProviders = (...providers) => ({ children }) => {
  return providers.reduceRight(
    (child, Provider) => <Provider>{child}</Provider>,
    children
  );
};

const Providers = composeProviders(LoginProvider, AlertMessageProvider);

export { Providers };
