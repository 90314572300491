import gql from 'graphql-tag';

export const GET_COMPANY = gql`
  query GET_COMPANY($id: String!) {
    company(where: { id: $id }) {
      name
      employeeRoles {
        id
        name
      }
    }
  }
`;

export const SALARIES_MEDIA_GROUP_ROLE = gql`
  query SALARIES_MEDIA_GROUP_ROLE($id: ID!, $employeeId: ID) {
    salariesByRole(id: $id, employeeId: $employeeId) {
      rolesSalaries {
        name
        median
      }
      bonusHistory {
        id
        description
        type
        amount
        createdAt
        initDate
      }
    }
  }
`;

export const GET_ROLE_BY_COMPANY_ID_QUERY = gql`
  query GET_ROLE_BY_COMPANY_ID_QUERY($companyId: String!) {
    getAllRolesByCompanyId(companyId: $companyId) {
      id
      name
      mid
      high
      low
      totalEmployee
      status
    }
  }
`;
