import LogRocket from 'logrocket';
import React, { createContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN } from '../../graphql/queries/users';
import { useMeQuery } from '../../graphql/container/query/user';
import { alertError } from '../../utils/customSwal';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  routesAdmin,
  routesEmployee,
  routesPublic
} from '../../services/Constants/paths';
import { PermissionsEmployee } from '../../services/Constants/getDefaults';

export const AppContext = createContext();

const ContextProvider = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuth, setIsAuth] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [roleUser, setroleUser] = useState();
  const token = window.localStorage.getItem('token');
  useEffect(() => setIsAuth(token), [token]);

  const [company, setCompany] = useState({ id: '', name: '' });
  const [currentUser, setCurrentUser] = useState(null);
  const [dontNavigate, setDontNavigate] = useState(false);
  const [pathRequested, setPathRequested] = useState();
  const [calendar, setCalendar] = useState(false);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [Login, { loading: loadingLogin }] = useMutation(LOGIN);
  const [refetchUser, { loading: loadingUser, data: dataUser }] = useMeQuery();
  const [watchEvents, setWatchEvents] = useState(true);
  const [heightContainer, setContainerHeight] = useState();

  useEffect(() => {
    if (dataUser) {
      setCurrentUser(dataUser.me);
      if (dataUser.me) {
        try {
          LogRocket.identify(dataUser.me.id, {
            name: dataUser.me.name,
            email: dataUser.me.email
          });
          if (!dontNavigate) {
            const swicthAdminEmployee = window.localStorage.getItem(
              'roleAdminEmployee'
            );
            let userRole = dataUser.me.employee.permissionEmployee.description;
            if (swicthAdminEmployee) {
              if (window.localStorage.getItem('roleAdminEmployee') === '1') {
                userRole = PermissionsEmployee.ADMIN;
              } else {
                userRole = PermissionsEmployee.EMPLOYEE;
              }
            }
            setroleUser(userRole);
            const roleOwnerOrAdmin =
              userRole === PermissionsEmployee.OWNER ||
              userRole === PermissionsEmployee.ADMIN;
            const roleAdminEmployee =
              userRole === PermissionsEmployee.ADMIN_EMPLOYEE;
            const roleEmployee =
              userRole === PermissionsEmployee.MANAGER ||
              userRole === PermissionsEmployee.EMPLOYEE;
            setIsAdmin(roleOwnerOrAdmin);
            if (
              [routesPublic.login, routesPublic.default].includes(
                location.pathname
              )
            ) {
              if (roleAdminEmployee) {
                let path;
                if (!swicthAdminEmployee) {
                  path = Object.values(routesAdmin).includes(pathRequested)
                    ? pathRequested
                    : routesAdmin.selectProfile;
                } else {
                  if (
                    window.localStorage.getItem('roleAdminEmployee') === '1'
                  ) {
                    path = '/home';
                  } else {
                    path = '/pto';
                  }
                }
                setPathRequested();
                navigate(path, { replace: true });
              } else if (roleOwnerOrAdmin) {
                const path = Object.values(routesAdmin).includes(pathRequested)
                  ? pathRequested
                  : '/home';
                setPathRequested();
                navigate(path, { replace: true });
              } else if (roleEmployee) {
                let path = '/pto';
                Object.keys(routesEmployee).forEach(route => {
                  if (typeof routesEmployee[route] === 'function') {
                    const rep = /undefined/gi;
                    if (rep.test(routesEmployee[route]())) {
                      // TODO fix the conditions when the route comes with params
                      const _route = routesAdmin[route]().replace(rep, '');
                      if (_route === pathRequested) path = pathRequested;
                    } else {
                      if (routesEmployee[route]() === pathRequested)
                        path = pathRequested;
                    }
                  } else {
                    if (routesEmployee[route] === pathRequested)
                      path = pathRequested;
                  }
                });
                setPathRequested();
                navigate(path, { replace: true });
              }
            }
          } else {
            setDontNavigate(false);
          }
        } catch (ex) {
          console.log(ex);
          // We have an error in LogRocket init. Do not display on console
        }
      }
      // } else if (window.sessionStorage.getItem('token')) {
    } else if (window.localStorage.getItem('token')) {
      refetchUser();
    }
    // eslint-disable-next-line
  }, [dataUser]);

  const activateAuth = (token, params, email, name, photo, cb) => {
    Login({
      variables: { email: email, name: name, token: token, photo: photo }
    }).then(
      response => {
        const { data } = response;
        if (data?.login?.companyRole) {
          setCompany(data.login.companyRole.company);
        }
        window.localStorage.setItem('token', data.login.token);
        window.localStorage.setItem('expiresIn', data.login.expiresIn);
        refetchUser();
        setIsAuth(true);
      },
      error => {
        cb && cb();
        console.log(error.message);
        if (error.message === 'GraphQL error: Permission denied') {
          window.location.href += `401?email=${email}&name=${name}`;
        } else {
          alertError(error.message);
        }
      }
    );
  };

  const removeAuth = () => {
    setIsAuth(false);
    // window.sessionStorage.removeItem('token');
    window.localStorage.removeItem('token');
  };

  return (
    <AppContext.Provider
      value={{
        isAuth,
        refetchUser,
        loadingUser,
        loadingLogin,
        activateAuth,
        removeAuth,
        company,
        setCompany,
        currentUser,
        setCurrentUser,
        dontNavigate,
        setDontNavigate,
        pathRequested,
        setPathRequested,
        isAdmin,
        roleUser,
        setroleUser,
        setIsAdmin,
        watchEvents,
        setWatchEvents,
        calendar,
        setCalendar,
        burgerMenu,
        setBurgerMenu,
        heightContainer,
        setContainerHeight
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
