import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-widgets/styles.css';
import { isWeekDay } from '../../services/Utils/getUtils';
import { CalendarContent } from './styles';
import ArrowLeft from '../Icons/ArrowLeft';
import ArrowRight from '../Icons/ArrowRight';

/* 
doc: https://reactdatepicker.com/
A function that returns a DatePicker component.
*/
const DatePickerModule = ({
  principalClass,
  weekendDisabled,
  disabled,
  onChange,
  onClick,
  minDate,
  maxDate,
  selected,
  placeholderText,
  placeholder = 'MM/DD/YYYY',
  excludeDates
}) => {
  return (
    <CalendarContent>
      <DatePicker
        className={principalClass ?? 'datepicker-input'}
        selected={selected}
        filterDate={weekendDisabled && isWeekDay}
        excludeDates={excludeDates}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        minDate={
          minDate ||
          new Date('01-01-2020').setFullYear(new Date().getFullYear())
        }
        maxDate={maxDate}
        placeholder={placeholder}
        placeholderText={placeholderText}
        valueFormat={{
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }}
        initialYearCalendar={80}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <button
              style={{ border: 'none', background: 'white' }}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <ArrowLeft
                color={prevMonthButtonDisabled ? '#E5E5E5' : '#24292E'}
              />
            </button>
            <button
              style={{ border: 'none', background: 'white' }}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <ArrowRight
                color={nextMonthButtonDisabled ? '#E5E5E5' : '#24292E'}
              />
            </button>
            <div className="monthCalendar">
              <DatePicker
                selected={date}
                dateFormat="MMMM"
                disabled={disabled}
                minDate={
                  minDate ||
                  new Date('01-01-2020').setFullYear(new Date().getFullYear())
                }
                maxDate={maxDate}
                renderCustomHeader={() => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>Months</span>
                  </div>
                )}
                onChange={date => {
                  changeMonth(date.getMonth());
                }}
                showMonthYearPicker
              />
            </div>
            <div className="yearCalendar">
              <DatePicker
                selected={date}
                disabled={disabled}
                minDate={
                  minDate ||
                  new Date('01-01-2020').setFullYear(new Date().getFullYear())
                }
                maxDate={maxDate}
                onChange={date => changeYear(date.getFullYear())}
                showYearPicker
                dateFormat="yyyy"
                yearItemNumber={12}
                popperPlacement="bottom-end"
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0]
                    }
                  }
                ]}
              />
            </div>
          </div>
        )}
      />
    </CalendarContent>
  );
};

export default DatePickerModule;
