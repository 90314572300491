import { PermissionsEmployee } from "../Constants/getDefaults";

export function magic() {
    console.log("MAGIC!!!")
    return true;
}


export const updateRole = (role) => {
    window.localStorage.setItem('roleAdminEmployee', role === PermissionsEmployee.ADMIN ? 1 : 0);
}

export const getRole = () => {
    const roleUser = window.localStorage.getItem('role')
    return roleUser;
}
