import React from 'react';

const Warning = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 54.6663V39.083"
        stroke="#24292E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9958 67.1875C49.4124 67.1875 48.9541 67.6458 48.9541 68.2292C48.9541 68.7708 49.4124 69.2708 49.9958 69.2708C50.5374 69.2708 51.0374 68.7708 51.0374 68.2292C51.0374 67.6458 50.5374 67.1875 49.9541 67.1875"
        stroke="#24292E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4538 17.2455L90.3288 73.0955C94.0371 79.5538 89.3288 87.6663 81.8746 87.6663H18.0413C10.5621 87.6663 5.8746 79.5872 9.5871 73.0955L41.4579 17.208C45.1663 10.6247 54.6246 10.6247 58.3663 17.208L58.4538 17.2455Z"
        stroke="#24292E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Warning;
