import styled from 'styled-components';

export const ContentPTO = styled.div`
  margin: 15px;
  .CircularProgressbar {
    width: 100%;

    & .CircularProgressbar-path {
      stroke: #25446a;
    }

    & .CircularProgressbar-text {
      fill: #25446a;
    }
  }

  .your-progress {
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;

    & .progress-bar-circle {
      text-align: center;
      padding: 18px;
    }

    & .progress-list {
      display: flex;
      flex-direction: column;
      padding: 2px 10px;
      flex-flow: column wrap;
    }
  }

  .pto-buttons {
    display: flex;
    align-items: center
      
    .btn-danger{
      height: 36px;
      width: 72px;
      margin-right: 10px;
   }
  
     .btn-success {
      height: 36px;
      width: 69px;
     }
  }



  .list-check {
    display: inline-block;
    padding: 0% !important;
    margin: 10px;
    & span {
      font-size: 18px;
    }
  }

  .circle-list {
    display: inline-block !important;
    background: #d1dff0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 7px;
    text-align: center;
    font-size: 22px;
    margin-right: 10px;
  }

  .card-icon {
    vertical-align: top;
    text-align: center;
    max-width: 36px;
    height: 36px;
    margin-left: 8px;

    & img {
      margin-top: 4px
      border-radius: 50%;
      width: 100%;
    }
  }

  .card-main-pto {
    margin-top: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    height: 100%;

    & .card-pto-title {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;

      .little-subtitle {
        display: flex !important;
        flex-direction: row;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 10px;
        
        p {
          color: #6A737D
          line-height: 20px;
          font-size: 14px;
          padding-left: 10px;
          margin-bottom: 0px;
        }
      }
    }

    & .card-pto-available {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;
      color: #24292E;

      & > div {
        display: inline-block;
        width: 10%;
        margin: 0 1% 0 0;
      }

      & span {
        font-weight: 600;
        font-size: 32px;
        color: #24292E;
      }

      & .avaliable {
        width: 8%;
      }

      & .avaliable,
      .used {
        border-right: 1px solid #e1e4e8;
      }
    }

    & .card-pto-filter {
      border-bottom: 1px solid #e1e4e8;
      width: 100%;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;

      .pto-check{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 27px;

        span{
          font-weight: 400;
          size: 14px;
          color: #24292E;
        }
        
      }
      .pto-fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        margin-right: 10px;

        span {
          margin-bottom: 8px;
          font-weight: 600;
          size: 14px;
          color: #24292E;
        }

        .pto-calendar-fields {
          display: flex;

          span {
            margin-bottom: 0px;
          }

          & .input-group-text {
            border: 1px solid #efefef;
            border-radius: 0.25rem 0.25rem 0rem 0.25rem;
            background-color: white !important;
            border-right: none !important;
            border-top-right-radius: 0;
            padding-right: 5px;
          }
        }
      }
     
     
      & .note-pto {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        margin: 0;
        margin-right: 10px;

        span{
          margin-bottom: 8px;
          font-weight: 600;
          size: 14px;
          color: #24292E;
        }

        & .input-group {
          height: 44px;
          width: 522px;

          span{
            margin-bottom: 0px;
          }

          & .input-group-text {
            background-color: white !important;
            border-right: none !important;
            padding-right: 5px;
          }

          & .form-control {
            border-left: none !important;
            padding-left: 0px;
          }
        }
      }

      & .btn-pto{

        padding: 0;
        margin: 0;
        margin-top: 29px;

        & button {
          height: 44px;
          width: 120px;
          color: black;
        }
      }
    }


    & .card-pto-data {
      height: 72%;
      overflow-y: auto;
      overflow-x: hidden;

      .loading {
        text-align: center;
        padding: 50px;
      }

      & .btn-bell {
        padding: 0;
        display: inline-block;
        width: 50%

        & span {
          margin-left: 5px;
          color: black;
        }
      }

      & .icon-status {
        text-align: right;
        margin-right:20px;
        margin-top: 5px;
        min-width: 87px;

        & span {
          font-weight: 600;
          margin-left: 5px;
          color: black;
        }
      }

    }
  }

  .rw-btn {
    border-right: none !important;
    padding: 0 5px !important;
  }

  .rw-widget-input  {
    box-shadow: none !important;
    text-align: left !important;
    padding: 0 5px !important;
  }

  .card-pto-no-data {
    text-align: center;
    padding: 100px 0;
    & svg {
      margin-bottom: 20px;
    }
  }
  .modal-body {
    &.text-left {
      text-align: left;
    }
  }
  
  .modal-body {
    &.content-alert{
      display:flex;
      justify-content:center;
  
      &.label-alert {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.15000000596046448px;
        text-align: left;
        width: 309px;
        margin-bottom: 42px;
        margin-top: 42px;
      }
    }
  }

  .content-tabs {
    max-height: 70vh;
    padding: 20px;

    & .card-pto-data {
         height: 72%;
         overflow-y: auto;
         overflow-x: hidden;

       .loading {
        text-align: center;
        padding: 50px;
        }
     }

    & .pto-item {
      display: flex;
      justify-content: space-between;
      width: 100%


          & .pto-icon {
            padding-top: 4px;
            vertical-align: text-top;

           }

           & .pto-date-status {
            min-width: 160px;
            padding: 0 10px;

            .pto-name-employee {
              color: #24292E;
              font-weight: 600;
            } 

             & .pto-dates{
              font-weight: 400;
              font-size: 14px;
              color: #586069;
             }
              & .pto-status {
              color: #24292e;
              font-size: 14px;
            }

          }

          .card-buttons-edit{
            display: none;
          }

          .ptobtns-edit {
            background: #F6F8FA;
            border-radius: 6px;
            height: 32px;
            width: 115px;
            display: flex;
            align-items: center;
            padding: 8px 10px;

              & .icon-status-approved {
               text-align: right;

                & span {
                 font-weight: 600;
                 margin-left: 5px;
                 color: #24292E;
               }

            }

              & .icon-status-declined {
                text-align: right;

                & span {
                  font-weight: 600;
                  margin-left: 12px;
                  color: #24292E;
                }
              }
          }
        .btn-actions {
          display: flex;

          img{
            margin: 0px 5px;
            cursor: pointer;
          }
        }

      }

    }
}
`;
