import React from 'react';
import { Form, Button } from 'react-bootstrap';

export const InitialPto = ({
  inicialpto,
  clickParent,
  setInfo,
  info,
  edit,
  setEdit,
  updateField,
  employee,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading
}) => {
  return (
    <div className="personal" onClick={clickParent}>
      <div className="heading-info">
        <h4 className="heading-h4">Initial PTO:</h4>
      </div>

      <div className="section-info">
        {inicialpto?.map((item, i) => {
          return (
            <div className="generalDiv" key={i}>
              <div className="question text-bold">
                <div>{item.title}</div>
              </div>
              <div className="answer text-normal">
                {item.title === edit ? (
                  <div className="edit">
                    <Form.Control
                      value={info}
                      onChange={e => setInfo(e.target.value)}
                      type={item.type}
                      placeholder={
                        (employee && employee[item.table]?.[0]?.[item.input]) ||
                        item.placeholder
                      }
                      autoFocus
                    />

                    <Button
                      className="btn-success"
                      onClick={e => updateField(item, e.target.value)}
                      disabled={
                        info === item.placeholder ||
                        getEmployeeLoading ||
                        updateProfileLoading
                      }
                    >
                      Save
                    </Button>
                  </div>
                ) : (
                  <div className="normal">
                    <p
                      onClick={() => {
                        setEdit(item.title);
                        setInfo(item.placeholder);
                      }}
                    >
                      <span>
                        {(employee &&
                          employee[item.table]?.[0]?.[item.input]) || (
                          <span className="placeHolder">
                            {item.placeholder}
                          </span>
                        )}
                      </span>
                    </p>
                    {itemLoading &&
                      item.title.includes(itemLoading?.title) &&
                      (getEmployeeLoading || updateProfileLoading) && (
                        <div
                          className="questionInside"
                          style={{
                            textAlign: 'center',
                            marginLeft: '10px'
                          }}
                        >
                          <CircularProgress className="circular-progress-employee" />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
