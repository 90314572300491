import styled from 'styled-components';

export const HeaderPermissions = styled.div`
  .header-w{
    display:flex;
    justify-content: flex-end;
    margin: 29px 17px 0 17px;
  }
  .pagination{
    display: flex;
    align-items: center;
  }
  .btnPagination {
    cursor: pointer;
    font-size: 18px;
    margin-right: 22px;
  }
  .buttons-paginations {
    display: flex;
  }
  .select-pagination{
    border-radius: 5px;
    border-color: #D1D5DA;
    width:64px;
    height:36px;
    appearance: none;
    background-image: url("/images/icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.9rem auto;
    padding-left: 9px;
    color: #24292e;
  }
  .text-header{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 22px;
   }
  }
`;
export const ContentPermissions = styled.div`
  margin: 13px 15px 15px 15px;
  border-radius: 10px;
  background: #ffffff;
  padding-bottom: 5px;

  .permission-header {
    padding: 0 15px 0 20px;

    .header {
      display: flex !important;
      width: 100% !important;
      justify-content: space-between;
    }
    .content-search {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .search {
      background: white;
      width: 250px;
    }

    .input-search-permissions {
      padding: 8px;
      height: 36px;
      border-left: none;
      padding-left: 0;
    }
    .content-select {
      display: flex;
      flex: 1;
      justify-content: end;
      align-items: center;
    }
    .select-permissions {
      height: 36px;
      width: 188px;
      left: 508px;
      top: 152px;
      border-radius: 5px;
      color: #24292e;
      margin-left: 10px;
    }
    .btn-permissions {
      height: 36px;
      width: 147px;
      left: 1045px;
      top: 152px;
      border-radius: 6px;
      padding: 8px 10px 8px 10px;
    }
    .boxClass {
      height: 36px;
      width: 250px;
      left: 508px;
      top: 152px;
      border-radius: 5px;
    }

    .color-box {
      background-color: white !important;
    }

    .sub-heading {
      margin: 0 2px 0 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.30000001192092896px;
      color: #172c45;
    }

    > div:nth-child(1) {
      display: inline-block;
      width: 70%;
    }

    > div:nth-child(2) {
      text-align: right;
      display: inline-block;
      width: 30%;

      button {
        display: inline-block;
        margin-left: 5px;
        border: none;
        span {
          margin-left: 5px;
          color: black;
        }
      }
    }
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 250px;
    margin-left: 20px;
  }

  .green-button {
    background: #18d399;
    border-radius: 6px;
    outline: none;
    border: 0px;
    gap: 8px;
    align-items: center;
    padding: 6px 16px;
    margin: 5px;
  }
  .green-button:focus {
    background: #18d399 !important;
  }
  .green-button:hover {
    background: #18d399 !important;
    border: none !important;
  }
  .pad-icon {
    margin-left: 6px;
    color: #24292e;
  }
  table {
    th:nth-child(n + 2) {
      width: 10%;
    }
    tr {
      height: 37px;
      border-bottom: 1px solid #e1e4e8;
    }
    tr:last-child {
      border-bottom: none;
    }
    tbody {
      tr {
        td:nth-child(n + 2) {
          width: 10% !important;
        }
        td:nth-child(2) {
          width: 20% !important;
        }
      }
      tr:hover {
        background-color: #d1dff0;
      }
    }
  }
  .csv-export {
    text-decoration: none;
  }
  .pad-icon {
    margin-left: 6px;
    color: #24292e;
  }
  .noData {
    width: 100%;
    text-align: center;
    height: 600px;
    padding: 250px;

    svg {
      margin-bottom: 20px;
    }
  }
`;
