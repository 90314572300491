import React, { useState } from 'react';
import moment from 'moment';

import DownloadFile from '../../../components/Document/DownloadFile';
import Table from '../../../components/GenericTable';
import Download from '../../../components/Icons/Download';
import { SelectIconTypeFile } from '../../../components/Icons/IconEvents';
import { DATE_FORMAT } from '../../../services/Constants/getDefaults';
import File from '../../../components/Icons/File';

const NoData = () => {
  return (
    <div className="noData">
      <File color="black" size="48" />
      <div>
        <span className="text-normal">No files, upload one</span>
      </div>
    </div>
  );
};

const TableFiles = ({
  dataSource = [],
  loading = false,
  columns = [],
  selected,
  setSelected
}) => {
  const attributes = ['id', 'url', 'name'];
  const [selectAll, setSelectAll] = useState(false);
  const columnsActives = columns.filter(item => item.active);
  const data = [];

  dataSource.forEach((item, i) => {
    const pathSplit = item.url.split('.');
    const extension = pathSplit[pathSplit.length - 1];
    data[i] = { dataRow: [] };
    columnsActives.forEach(col => {
      switch (col.key) {
        case 'filename':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <DownloadFile url={item.url} filename={item.name}>
                  <SelectIconTypeFile type={extension} />
                  <span style={{ paddingLeft: '5px' }}>{item.name}</span>
                </DownloadFile>
              )
            });
          break;
        case 'owner':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <DownloadFile url={item.url} filename={item.name}>
                  {item.user.name}
                </DownloadFile>
              )
            });
          break;
        case 'type':
          if (col.active)
            data[i].dataRow.push({
              key: col.key,
              value: (
                <DownloadFile url={item.url} filename={item.name}>
                  {extension}
                </DownloadFile>
              )
            });
          break;
        case 'date':
          if (col.active)
            data[i].dataRow.push({
              key: 'date',
              value: (
                <DownloadFile url={item.url} filename={item.name}>
                  {String(moment(item.createdAt).format(DATE_FORMAT))}
                </DownloadFile>
              )
            });
          break;
        case 'download':
          if (col.active)
            data[i].dataRow.push({
              key: 'download',
              value: (
                <DownloadFile url={item.url} filename={item.name}>
                  <div style={{ paddingLeft: '10px', borderLeft: '1px solid #959da5', cursor: 'pointer' }}>
                    <Download />
                  </div>
                </DownloadFile>
              )
            });
          break;
        default:
          break;
      }
    });
    data[i].dataRow.push({
      key: 'id',
      value: item.id
    });
  });

  return (
    <Table
      data={data}
      dataSource={dataSource}
      columns={columnsActives}
      loading={loading}
      component={<NoData />}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      dataSelected={selected}
      setDataSelected={setSelected}
      attributesSelected={attributes}
      checkbox
    />
  );
};

export default TableFiles;
