import React from 'react';

const FileImg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="5"
        fill="url(#paint0_linear_1348_2112)"
      />
      <path
        opacity="0.4"
        d="M9.70812 8.43198C9.70812 7.40826 8.82855 6.53992 7.9305 7.03136C6.77997 7.66096 6 8.88276 6 10.2865C6 12.3341 7.66051 13.9946 9.70812 13.9946C11.1116 13.9946 12.3337 13.2145 12.9636 12.064C13.4555 11.1656 12.5864 10.2856 11.5622 10.2856V10.2856C10.5385 10.2856 9.70812 9.45571 9.70812 8.43198V8.43198Z"
        fill="white"
      />
      <path
        d="M10.2856 7.85453C10.2856 8.87872 11.1159 9.70901 12.1401 9.70901V9.70901C13.164 9.70901 14.0326 8.82953 13.5414 7.93121C13.2008 7.30848 12.6867 6.79421 12.0641 6.45336C11.1657 5.96154 10.2856 6.83031 10.2856 7.85453V7.85453Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1348_2112"
          x1="0"
          y1="0"
          x2="0"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD7541" />
          <stop offset="1" stopColor="#EB5F29" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileImg;
