export const initialsColumns = [
  {
    name: 'Name',
    key: 'name',
    active: true
  },
  {
    name: 'Role',
    key: 'role',
    active: true
  },
  {
    name: 'Salary',
    key: 'salary',
    active: true
  },
  {
    name: 'Start Date',
    key: 'startDate',
    active: true
  },
  {
    name: 'Dedication',
    key: 'schedule',
    active: false
  },
  {
    name: 'Teams',
    key: 'teams',
    active: true
  },
  {
    name: 'Managers',
    key: 'managers',
    active: true
  },
  {
    name: 'Status',
    key: 'status',
    active: false
  },
  {
    name: 'ID',
    key: 'documentId',
    active: false
  },
  {
    name: 'Address',
    key: 'address',
    active: false
  },
  {
    name: 'Address2',
    key: 'addressTwo',
    active: false
  },
  {
    name: 'City',
    key: 'city',
    active: false
  },
  {
    name: 'State',
    key: 'state',
    active: false
  },
  {
    name: 'Country',
    key: 'country',
    active: false
  },
  {
    name: 'ZIP',
    key: 'postalCode',
    active: false
  },
  {
    name: 'Nationality',
    key: 'nationality',
    active: false
  },
  {
    name: 'Birthday',
    key: 'dateOfBirth',
    active: false
  },
  {
    name: 'T-shirt Size',
    key: 'value',
    active: false
  },
  {
    name: 'Company Email',
    key: 'companyEmail',
    active: false
  },
  {
    name: 'Personal Email',
    key: 'personalEmail',
    active: false
  },
  {
    name: 'Phone',
    key: 'phone',
    active: false
  },
  {
    name: 'Bank Name',
    key: 'bankName',
    active: false
  },
  {
    name: 'Account Number',
    key: 'bankAccount',
    active: false
  },
  {
    name: 'SWIFT Code',
    key: 'bankSwiftCode',
    active: false
  },
  {
    name: 'Routing',
    key: 'routingNumber',
    active: false
  },
  {
    name: 'Intermediary Bank Routing',
    key: 'intermediaryRouting',
    active: false
  }
];
