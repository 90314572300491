export const EMPLOYEE_DOCUMENT_TYPES = [
  'US PASSPORT',
  'COL PASSPORT',
  'US ID',
  'COL ID',
  'US LICENSE',
  'COL LICENSE'
];
export const EMPLOYEE_SHIRT_SIZE = ['XS', 'S', 'M', 'L', 'XL'];
export const HAS_VISA = ['Yes', 'No'];
export const MIN_AGE = 16;
export const EMPLOYEE_EMPLOYMENT_TYPES = ['EMPLOYEE', 'CONTRACTOR', 'TEMP'];
export const EMPLOYEE_FORM = ['W8-BEN', 'W-2'];
export const EMPLOYEE_SCHEDULES = ['FULL', 'PART', 'HOURLY'];
export const EMPLOYEE_SCHEDULES_LOWER = ['Full-Time', 'Part-Time', 'Hourly'];
export const FORM_ICONS_NAMES = ['money', 'sheet', 'computer', 'beach', 'hand'];
export const DAYS_OFF_TYPE_NAMES = ['DAY_OFF', 'SICK'];
export const FORM_TYPE_NAMES = ['DOCUMENT', 'FORM'];
export const BONUS_TYPE_NAMES = ['BONUS', 'SALARY_ADJUSTMENT'];

export const COPY_BY_MAIL_TO_THE_POSITION = 'HR';
export const EMPLOYEE_FIRST_SALARY = 'First Salary';
export const DATE_FORMAT_BETWEEN = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM/DD/YYYY';

// Models
export const ABSCENCE_REQUEST_MODEL = {
  typeTime: 'typeTime',
  description: 'description'
};
export const ADDITIONAL_DAYS_MODEL = {
  daysOff: 'daysOff',
  note: 'note'
};
export const ABSCENCE_MODEL = {
  note: 'note',
  typeTime: 'typeTime'
};
export const BONUS_MODEL = {
  note: 'note',
  amount: 'amount',
  type: 'type'
};
export const COMPANY_MODEL = {
  name: 'name',
  logo: 'logo',
  industry: 'industry',
  website: 'website',
  linkedIn: 'linkedIn',
  twitter: 'twitter',
  country: 'country',
  city: 'city',
  email: 'email',
  slogan: 'slogan',
  phone: 'phone',
  addressOne: 'addressOne',
  addressTwo: 'addressTwo'
};
export const EMPLOYEE_MODEL = {
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  mothersMaidenName: 'mothersMaidenName',
  role: 'role',
  email: 'email',
  phone: 'phone',
  country: 'country',
  city: 'city',
  idType: 'idType',
  documentId: 'documentId',
  addressOne: 'addressOne',
  addressTwo: 'addressTwo',
  state: 'state',
  postalCode: 'postalCode',
  employmentType: 'employmentType',
  schedule: 'schedule',
  salary: 'salary',
  companyEmail: 'companyEmail',
  shirtSize: 'shirtSize',
  tShirtSize: 'tShirtSize',
  bankingInformation: 'bankingInformation',
  bankName: 'bankName',
  bankNumber: 'bankNumber',
  bankAccountNumber: 'bankAccountNumber',
  bankSwiftCode: 'bankSwiftCode',
  bankRoutingNumber: 'bankRoutingNumber',
  bankCountry: 'bankCountry',
  bankAddress: 'bankAddress',
  bankState: 'bankState',
  bankPostalCode: 'bankPostalCode',
  bankCity: 'bankCity',
  idTShirtCustomInfo: 'idtShirtSize',
  idPaymentInformation: 'idPaymentInformation'
};
export const FORM_MODEL = {
  url: 'url',
  description: 'description',
  type: 'type',
  icon: 'icon'
};
export const ONBOARDING_MODEL = {
  role: 'role',
  name: 'name'
};
export const NOTE_MODEL = {
  note: 'note'
};
export const ROLE_MODEL = {
  name: 'name',
  salaryLow: 'salaryLow',
  salaryHigh: 'salaryHigh',
  salaryMid: 'salaryMid'
};
export const TIME_OFF_RULE_MODEL = {
  daysAdvanceToRequestTimeOff: 'daysAdvanceToRequestTimeOff',
  initialDaysOff: 'initialDaysOff',
  daysPerMonth: 'daysPerMonth',
  rollOverDaysPerYear: 'rollOverDaysPerYear'
};

// Type of Data
export const ABSCENCE_REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
};
export const ABSCENCE_TYPE = {
  DAY_OFF: 'DAY_OFF',
  SICK: 'SICK'
};
export const COMPANY_SYSTEMS = [
  {
    name: 'GSuite',
    type: 'email',
    logo: 'google'
  },
  {
    name: 'GitHub',
    type: 'repository',
    logo: 'github'
  },
  {
    name: 'AWS',
    type: 'infraestructure',
    logo: 'aws'
  },
  {
    name: 'Invision',
    type: 'prototyping',
    logo: 'invision'
  },
  {
    name: 'Udemy',
    type: 'training',
    logo: 'udemyIcon'
  }
];
export const EMPLOYEE_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SEPARATED: 'SEPARATED'
};
export const SEPARATION_STATUS = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED'
};
export const FORM_TYPE = {
  FORM: 'FORM',
  DOCUMENT: 'DOCUMENT'
};
export const FORM_ICONS = {
  money: '/images/money.svg',
  sheet: '/images/documents.svg',
  computer: '/images/computer.svg',
  beach: '/images/beach.svg',
  hand: '/images/oncoming-fist.svg'
};
export const ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE'
};
export const DOC_TYPE = {
  SHEET: 'SHEET',
  SLIDE: 'SLIDE',
  PDF: 'PDF',
  DOC: 'DOC',
  OTHER: 'OTHER'
};
export const DOC_SOURCE = {
  S3: 'S3',
  GOOGLE_DRIVE: 'GOOGLE_DRIVE'
};
export const ASSETS_MODEL = {
  brand: 'brand',
  description: 'description',
  price: 'price',
  date: 'date',
  serial: 'serial',
  assigned: 'assigned',
  details: 'details',
  type: 'type',
  name: 'name',
  year: 'year',
  chip: 'chip',
  ram: 'ram',
  disk: 'disk'
};
export const SEPARATION_MODEL = {
  employee: 'employee',
  note: 'note',
  availableForRehire: 'availableForRehire',
  createdAt: 'createdAt'
};

export const USER_MODEL = {
  name: 'name',
  email: 'email'
};

//Permissions
export const PermissionsEmployee = {
  ADMIN: 'Admin',
  EMPLOYEE: 'Employee',
  MANAGER: 'Employee & Manager',
  ADMIN_EMPLOYEE: 'Admin & Employee',
  OWNER: 'Owner'
};

export const ASSETS_TYPES = ['COMPUTER', 'MONITOR'];

export const SCREEN_MID_SIZE = 768;

export const URL_S3 = process.env.REACT_APP_URL_S3;

export const getImageError = companyId =>
  //`${URL_S3}/companyLogos/${companyId}/img_profile_error.png`;
  null;

export const optionsTransaction = [
  { type: 'salaryAdjustment', label: 'Salary adjustment' },
  { type: 'bonus', label: 'Bonus' },
  { type: 'startingSalary', label: 'Starting salary' }
];

export const MAX_FILE_SIZE = 20000000;
