import styled from 'styled-components';

export const ContentCreateEditTeams = styled.div`
  margin: 15px;
  border-radius: 10px;
  background: #ffffff;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;

  .selectManager{
    width: 350px;
    margin-left: 10px;
  }

  .teamName{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #172C45;
    margin: 20px;
  }

  .create-edit-title-style{
    padding: 10px 20px;
    border-bottom: 1px solid #e1e4e8;
    span{
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .create-edit-header {
    padding: 18px 20px 12px;
    border-bottom: 1px solid #e1e4e8;
    display: flex;

    > div:nth-child(1) {
      p{
        margin-bottom: 8px;
        font-weight: 600;
        line-height: 20px;
      }
      display: inline-block;
      .form-control{
        padding: 8px 9px;
        height: 36px;
        color: #24292e;
        width: 350px;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }
    }

    > div:nth-child(2) {
      p{
        margin-bottom: 8px;
        font-weight: 600;
        line-height: 20px;
      }
      display: inline-block;

      .fatherClass {
        .search-employees{
          input{
            padding-left: 9px;
            color: #24292e;
            border: 1px solid #D1D5DA;
           
            ::placeholder { 
              color: #24292e;
            }
          }
        }
      }

      > div {
        display: inline-block;
      }

      > div:nth-child(1) {
        padding-left: 3%;
        width: 30%;
      }

      > div:nth-child(2) {
        width: 350px;
        height: 36px;
        margin-top: 0px;
      }
    }
  }
  .MuiOutlinedInput-input {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 0px;
  }
  
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 0px;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 0px;
  }

  .add-search-employee {
    height: 81%;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 20% 0px;
  }

  .add-remove-loading {
    height: 81%;
    border-bottom: 1px solid #e1e4e8;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 20% 0px;
  }

  .add-remove-employees {
    height: 975px;
    border-bottom: 1px solid #e1e4e8;

    & .input-group {
      margin-top: 17px;
      margin-bottom: 15px;

      .search-bar{
        display: flex;
        width: 94%;
        margin-left: 20px;
        height: 38px;
      }

      & .input-group-text {
        background-color: white !important;
        border-right: none !important;
        padding: 11px;
        border-radius: 5px 0px 0px 5px;
        svg{
          width: 20px;
          height 20px;
        }
      }

      & .form-control {
        padding-left: 0;
        border-left: none !important;
        color: #24292e;
        border-radius: 0px 5px 5px 0px;
      }
    }

    .to-add {
      border-top: 1px solid #E1E4E8; 
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;

      .icon-profile {
        img {
          width: 36px;
          border-radius: 50%;
        }    
      
      }
      .icon-label {
        padding-left: 10px;
        display: inline-block;
        vertical-align: middle;

        p {
          margin: 0;
        }
      }

      &:hover {
        background-color: #D1DFF0;
        .card{
          background-color: #D1DFF0;
            }
        .card-icon{
          background-color: #D1DFF0;
        }
      }

    }


    .employees-to-add {
      overflow: scroll;
      height: 100%;
      display: inline-block;
      width: 50%;
      vertical-align: bottom;
      border-right: 1px solid #e1e4e8;
    }

    .employees-added {
      vertical-align: top;
      overflow: scroll;
      height: 100%;
      display: inline-block;
      width: 50%;

      .noData {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        div {
          margin-top: 3%;
        }
      }
    }

    .card-icon,
    .card-button {
      display: inline-block;
        .btn-success {
          padding: 5px 7px !important;
          width: 44px;
        }
        .btn-danger {
          padding: 5px 7px !important;
          background-color: #D73A49 !important;
          width: 44px;
          height: 44px;
        }
    }

    .card-icon {
      width: 65%;
    }

    .card-button {
      margin-top: 15px
      margin-bottom: 15px;
      width: 30%;
      text-align: right;
      vertical-align: middle;

      button {
        border: none;
        color: black;
      }
      p {
        margin: 0;
      }
    }
  }

  .iconTeamName{
    background-color: #FFFFFF;
    border: 1px solid #D73A49;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-left: none
    display: none;
  }

  .textQuestion{
    display: flex;
    background-color: #CB2431;
    color: white;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: -44px;
    font-size: 12px;
    line-height: 18px;
    font-family: SF Pro Text;
    padding: 2px 5px 2px 5px;
    visibility: hidden;
    transition: visibility 0.5s;
    z-index: 1;
      p{
        font-weight: 400 !important;
        line-height: 18px !important;
        width: 195px;
        Height: 30px
        white-space: normal;
        text-align: right;
      }
  } 

  .iconTeamName:hover  .textQuestion {
    visibility: visible;
  }

  .create-edit-buttons {
    padding: 16px 20px;

    & > div {
      & > button{
        padding: 12px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
    
      }
      display: inline-block;
    }

    .card-button-remove {
      width: 20%;
      .btn-danger {
        background-color: #FFFFFF !important;
        border: 1px solid #E1E4E8;

        span{
          color:#D73A49 !important;
        }
      
      }
    }

    .card-button-confirm {
      width: 80%;
      text-align: right;

      > button:nth-child(1) {
        margin-right: 10px;
      }
    }

    .card-button-confirm,
    .card-button-remove,{
      button{
        // padding: 12px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        
      }
    }
  }
`;
