import React, { useState,Suspense, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { GlobalStyles } from './styles/GlobalStyles';
import Login from './containers/Login/index';
import Pto from './containers/Views/Pto/Pto';
import Profile from './containers/Views/Profile';
import Files from './containers/Views/Files';
import CompanyDetail from './containers/Views/CompanyDetail';
import Roles from './containers/Views/Roles';
import Home from './containers/Views/Home';
import Employees from './containers/Views/Employees';
import Permissions from './containers/Views/Permissions';
import Teams from './containers/Views/Teams';
import TeamsManager from './containers/Views/TeamManager';
import PtosTeam from './containers/Views/TeamManager/PtosTeam';
import PtoHolidays from './containers/Views/PtoHolidays';
import ActivateEmail from './containers/Views/ActivateEmail';
import CreateEditEmployee from './containers/Views/CreateEditEmployee';
import Salary from './containers/Views/Salary';
import Ptoadmin from './containers/Views/Ptoadmin/Ptoadmin';
import {
  routesEmployee,
  routesAdmin,
  routesPublic,
  routesOwner,
  routesManager
} from './services/Constants/paths';
import CreateEditTeams from './containers/Views/CreateEditTeams';
import AnyError from './containers/Views/AnyError';
import Loadingsimpleteamonboardnew from './components/Loader';
import { AppContext } from './Context/App/AppContext';
import Selectprofile from './containers/Views/SelectProfile';
import Layout from './containers/Layout';
import LinkError from './containers/Views/LinkError';
import ExpirationToken from './containers/Views/ExpirationToken';

const Assets = () => <div>Assets</div>;

export const AppRouter = () => {
  const location = useLocation();
  const { currentUser, isAuth, setPathRequested } = useContext(AppContext);
  const token = localStorage.getItem('token');
  const tokenExpiresIn = localStorage.getItem('expiresIn');
  const permissionEmployee =  currentUser?.employee.permissionEmployee.description 
  const [validateToken, setValidateToken] = useState(null);
  const [urlTokenExpired, setUrlTokenExpired] = useState(null);

  useEffect(() => {
    if (!isAuth && !Object.values(routesPublic).includes(location.pathname))
      setPathRequested(location.pathname);
    // eslint-disable-next-line
    setUrlTokenExpired(location.pathname.includes('401'))
    setValidateToken(isAuth)

    const millisecondsCurrentDate = new Date().getTime();
    const currentDateTokenExpiresInDate = new Date(tokenExpiresIn).getTime();
    const timeLeft = currentDateTokenExpiresInDate - millisecondsCurrentDate;

    if (timeLeft > 0) { setTimeout(tokenSessionExpired,timeLeft)} 

  }, [isAuth, location.pathname, setPathRequested, tokenExpiresIn]);

  function validatePermission( routes ) {
    return routes?.role.includes(permissionEmployee) ? true : false
  }

  const tokenSessionExpired = () => {
    window.localStorage.removeItem('token');
    window.location = '/401';
  };


  const PrivateRender = component =>
    token ? (
      Object.keys(
        currentUser === undefined || currentUser === null ? {} : currentUser
      ).length !== 0 && <Layout>{component}</Layout>
    ) : (
      <Navigate to="/login" replace={true} />
    );

  return (
    <>
      <GlobalStyles />
      <Suspense fallback={<Loadingsimpleteamonboardnew loop={true} />}>
        <Routes>


          <Route
            path={routesPublic?.activateEmail}
            element={<ActivateEmail />}
          />

          <Route 
          path={routesPublic?.tokenExpired} 
          element={<ExpirationToken />} 
          />

          <Route 
          path={routesPublic?.linkError} 
          element={<AnyError />} 
          />

          {
currentUser ? (
          <>    
          {   
validatePermission(routesEmployee) ? (
          <>
          <Route 
          path={routesEmployee.pto} 
          element={PrivateRender(<Pto />)} 
          />

          <Route
            path={routesEmployee.files}
            element={PrivateRender(<Files />)}
          />

          <Route
            path={routesEmployee.profile}
            element={PrivateRender(<Profile />)}
          />

          <Route
            path={routesEmployee.salary()}
            element={PrivateRender(<Salary />)}
          />               
         </>
          )        
             : null
          }

          {
 validatePermission(routesManager) ? (
          <>

          <Route
            path={routesManager.Teams}
            element={PrivateRender(<TeamsManager />)}
          />

          <Route
            path={routesManager.ptosTeam(':id')}
            element={PrivateRender(<PtosTeam />)}
          />

          </>
          )        
          : null
         }

          {
 validatePermission(routesOwner) ? (

          <>
          <Route
            path={routesOwner.companyDetail()}
            element={PrivateRender(<CompanyDetail />)}
          />  

          <Route
          path={routesOwner.roles()}
          element={PrivateRender(<Roles />)}
          />

          <Route
            path={routesOwner.permissions()}
            element={PrivateRender(<Permissions />)}
          />

          <Route
            path={routesOwner.teams()}
            element={PrivateRender(<Teams />)}
          />

          <Route
            path={routesOwner.createEditTeams()}
            element={PrivateRender(<CreateEditTeams />)}
          />

          <Route
            path={routesOwner.ptoHolidays()}
            element={PrivateRender(<PtoHolidays />)}
          />
         
         </>
          )        
             : null
          }

          {
 validatePermission(routesAdmin) ? (

            <>
            <Route 
            path={routesAdmin.home} 
            element={PrivateRender(<Home />)} 
            />


           <Route
            path={routesAdmin.selectProfile}
            element={PrivateRender(<Selectprofile />)}
          />

          <Route
            path={routesAdmin.employees}
            element={PrivateRender(<Employees />)}
          />

          <Route
            path={routesAdmin.assets}
            element={PrivateRender(<Assets />)}
          />

          <Route
            path={routesAdmin.createEmployee()}
            element={PrivateRender(<CreateEditEmployee />)}
          />

          <Route
            path={routesAdmin.editEmployee(':id')}
            element={PrivateRender(<CreateEditEmployee />)}
          />

          <Route
            path={routesAdmin.salary(':id')}
            element={PrivateRender(<Salary />)}
          />

          <Route
            path={routesAdmin.pto(':id')}
            element={PrivateRender(<Ptoadmin />)}
          />

          <Route
            path={routesAdmin.files(':id')}
            element={PrivateRender(<Files />)}
          />  

            <Route
            path={routesAdmin.createEditTeams()}
            element={PrivateRender(<CreateEditTeams />)}
          />
          </>
          )        
             : null
          }

          <Route 
          path={routesPublic?.default} 
          element={<Login />} 
          />

          <Route 
          path={routesPublic?.login} 
          element={<Login />} 
          />

          <Route 
          path="*" 
          element={<LinkError />} 
          />

          </>
) : urlTokenExpired ? <Route path="*" element={<ExpirationToken />}/>  
  : validateToken || token  ? null 
  : urlTokenExpired === null ? null
  :  (<>

          <Route 
          path={routesPublic?.default} 
          element={<Login />} 
          />

          <Route 
          path={routesPublic?.login} 
          element={<Login />} 
          />

           <Route 
           path="*" 
           element={<Login />}  
           />

    </>)   
          }


        </Routes>
      </Suspense>
    </>
  );
};
