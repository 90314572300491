import { useQuery } from '@apollo/react-hooks';
import {
  GET_PROFILE,
  GET_ME_PROFILE,
  GET_CITIES_OF_THE_EMPLOYEES
} from '../../../queries/profile';

export const useGetProfileQuery = (employeeId, isAdmin) => {
  return useQuery(isAdmin ? GET_PROFILE : GET_ME_PROFILE, {
    variables: { employeeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetCitiesOfTheEmployeesQuery = () => {
  return useQuery(GET_CITIES_OF_THE_EMPLOYEES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
