import styled from 'styled-components';

export const DropdownCheckboxContainer = styled.div`
  display: inline-block;

  .show .dropdown-toggle {
    border: 1px solid #444d56 !important;

    svg {
      transform: rotate(180deg);
    }
  }
  .dropdown {
    .dropdown-toggle {
      background-color: white !important;
      gap: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 180px;

      svg {
        transition: transform 0.3s ease;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      padding: 5px 10px !important;
    }

    .dropdown-item:active {
      background: white;
    }

    .btn-apply {
      text-align: center;
      margin-top: 7px;
      button {
        width: 100%;
        background: #14d399;
        color: black;
        margin: 0;
      }
    }

    .MuiFormControlLabel-root {
      margin: 0;
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }

    .list-group {
      height: 200px;
      overflow: auto;
    }
  }

  .btn-success:focus {
    box-shadow: none !important;
  }
`;
