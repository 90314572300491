import React from 'react';

const Search = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 6.72795C11.5348 7.76295 11.5348 9.45045 10.4998 10.4929C9.45728 11.5279 7.76978 11.5279 6.72728 10.4929C5.68478 9.45045 5.68478 7.76295 6.72653 6.72045C7.76153 5.67795 9.44903 5.67795 10.4915 6.7197"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L10.5 10.5L12 12Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7098 4.28248C16.3123 6.88498 16.3123 11.1 13.7098 13.71C11.0998 16.3125 6.88484 16.3125 4.27484 13.71C1.66484 11.1 1.66484 6.88498 4.26734 4.27498C6.86984 1.66498 11.0848 1.66498 13.6948 4.27423"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Search;
