import moment from 'moment';
import {
  WIDTH_TABLE_PERCENTAGE,
  WIDTH_SEVEN_PERCENTAGE,
  LABEL_FILE_SIZE_LIMIT,
  NUMBER_FILE_SIZE_LIMIT
} from '../Constants/getLabels';
import { alertError } from '../../utils/customSwal';
export const URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENTE_ID = process.env.REACT_APP_CLIENTE_ID;
export const LOGROCKET_PROJECT = process.env.REACT_APP_LOGROCKET;

export const FORMAT_DATE = 'MM/DD/YYYY';
export const transformUTCDate = date => {
  return moment(date).utc();
};

export const formatStringDate = (date, format = FORMAT_DATE) => {
  return transformUTCDate(date).format(format);
};
export const transformUTCDateNoTime = date => {
  return transformUTCDate(formatStringDate(date, 'YYYY-MM-DD'));
};

export const simpleFormatDate = date => {
  const formatDate = moment(date).format('YYYY-MM-DD');
  return formatDate
}
export const formatZeroGmtDate = date => {
  const formatDate = moment(date).format('YYYY-MM-DD');
  return transformUTCDate(`${formatDate}T00:00+00:00`);
};

export const verifyHolidays = (ptoDay, holidays) => {
  var dayHoliday = false;
  holidays.map((day) => {
    if (new Date(ptoDay).toLocaleDateString('en-US')
      === new Date(day?.date).toLocaleDateString('en-US')
    ) {
      return dayHoliday = true;
    }
  })
  return dayHoliday;
}

export const isBetweenDate = (date, startDate, endDate) => {
  let response = false;
  if (date.isBetween(startDate, endDate, undefined, [])) response = true;
  return response;
};

export const calculateWidthColumnTable = (index, numberItemsTable) => {
  return `${WIDTH_TABLE_PERCENTAGE / numberItemsTable +
    (index + 1 === numberItemsTable ? WIDTH_SEVEN_PERCENTAGE : 0)}%`;
};

export const transformObject = object => {
  let objectUpdate = {};
  for (let property in object) {
    objectUpdate[property] = object[property]['set'];
  }
  return objectUpdate;
};

export const validateSizeFile = sizeFile => {
  let response = true;
  const sizeMb = sizeFile / 1024 / 1024;
  if (sizeMb > NUMBER_FILE_SIZE_LIMIT) {
    response = false;
    alertError(LABEL_FILE_SIZE_LIMIT);
  }
  return response;
};

export const keyExtraction = (columns) => {
  let keyCol = columns.map(el => el.key).toString()
  return keyCol
}

export const firstUpper = el => {
  let first = el?.split('')[0];
  let rest = el?.slice(1)?.toLowerCase();
  return first + rest;
};

export const getFormattedDate = date => {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

export const formatDate = (date) => {

  const year =  date?.substring(0, 4) 
  const month =  date?.substring(5, 7) 
  const day =  date?.substring(8, 10) 
  
    return date !== null ?  month + '/' + day + '/' + year : '';
  
}

export const isAfter = (dateOne, dateTwo) => {
  const dateOneConvert = parseInt(dateOne.replace('-', 0))
  const dateTwoConvert = parseInt(dateTwo.replace('-', 0))
  if (dateOneConvert > dateTwoConvert) {
    return true
  }
  return false;
}

export const isSameOrBefore = (dateOne, dateTwo) => {
  const dateOneConvert = parseInt(dateOne.replace('-', 0))
  const dateTwoConvert = parseInt(dateTwo.replace('-', 0))
  if (dateOneConvert <= dateTwoConvert) {
    return true
  }
  return false; 
  
}
export const range = (start, year) => {
  const rangeYears = [];
  for (let i = start; i <= year; i++) {
    rangeYears.push(i);
  }
  return rangeYears;
};

export const isWeekDay = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};


export const emailValidation = email => {
  const regexValidateEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexValidateEmail.test(email);
};

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'long' });
}

export const formatDateToCalendar  = (date, daysOff) => {
  const month = date?.split('-')[1]
  const year = date?.split('-')[0]
  const monthName = getMonthName(month)
  const initialDate = new Date(new Date (date).setHours(0,0,0,0))
  let arrayDays = []
  
  if(daysOff){
    
    for(let i = 0; i<= daysOff-1; i++){
      arrayDays.push(new Date(initialDate.setDate(initialDate.getDate()+1)));
    }
  } else{
    const onlyDay = parseInt(date?.split('-')[2]?.slice(0,2));
    arrayDays.push(new Date(monthName+' '+ onlyDay +', '+ year))
  }

  arrayDays.filter(el => !el.toString().includes('Sat')).filter(el => !el.toString().includes('Sun'))

  return arrayDays
  
}

export const dateBetweenTwoDate = (start, end) => {
  var current = start;
  var dates = [];
  while (current?.isSameOrBefore(end)) {
    dates.push(current?.format('YYYY-MM-DD'));
    current.add(1, 'days');
  }
  return dates;
};

export const calculateDaysOffFromStartAndEnd = (startDate, endDate) => {
  const startDateMoment = moment(startDate).utc();
  const endDateMoment = moment(endDate).utc();

  let dates = dateBetweenTwoDate(startDateMoment, endDateMoment);
  let days = 0;
  dates.map((item) => {
    switch (moment(item).isoWeekday()) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7: 
        let holidayCompany = false;
       
        if (!holidayCompany) {
          days++;
        }
        break;
    }
  });

  return days;
};


export function readCookies(value) {
  const filters = document?.cookie
    ?.split(';')
    ?.filter(e => e.includes(value))[0]
    ?.split('***');
  return filters ? filters : [];
}

export const isMobile = () => {
  return window.innerWidth <= 1024;
};

