import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  COMPANY_QUERY,
  All_COMPANY_ROLES,
  GET_COUNTRIES,
  GET_COMPANY_DETAIL_BY_ID,
  GET_PTO_PENDING_BY_COMPANY,
  GET_PTO_REQUEST_BY_COMPANY,
  GET_PTO_PENDING_BY_TEAM,
  GET_EMPLOYEE_WITH_TEAM_BY_COMPANY_BY_TEAMS
} from '../../../queries/CompanyCreate';

export const useCompanyQuery = companyId =>
  useQuery(COMPANY_QUERY, {
    variables: { id: companyId }
  });

export const useCompanyLazyQuery = companyId =>
  useLazyQuery(COMPANY_QUERY, {
    variables: { id: companyId }
  });

export const useAllcompanyRole = companyId =>
  useLazyQuery(All_COMPANY_ROLES, {
    variables: { id: companyId }
  });

export const useGetCountriesQuery = () => useQuery(GET_COUNTRIES);

export const useGetCompanyDetailById = companyId => {
  return useQuery(GET_COMPANY_DETAIL_BY_ID, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetPtoPendingQuery = companyId => {
  return useQuery(GET_PTO_PENDING_BY_COMPANY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetPtoRequestQuery = companyId => {
  return useQuery(GET_PTO_REQUEST_BY_COMPANY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
export const useGetEmployeeWithTeamsQuery = (companyId, idTeam) => {
  return useQuery(GET_EMPLOYEE_WITH_TEAM_BY_COMPANY_BY_TEAMS, {
    variables: { companyId, idTeam },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetPtoPendingByTeam = (companyId, teamId) => {
  return useQuery(GET_PTO_PENDING_BY_TEAM, {
    variables: { companyId, teamId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
