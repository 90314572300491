import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { ContentTable } from './style';
import { Checkbox } from '../Input';
import {
  firstUpper,
  formatDate,
  formatStringDate
} from '../../services/Utils/getUtils';
import ArrowSort from '../Icons/ArrowSort';
import ArrowAsc from '../Icons/ArrowAsc';
import ArrowDesc from '../Icons/ArrowDesc';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Table = ({
  data = [],
  columns = [],
  loading,
  component,
  checkbox,
  sort,
  selectAll = false,
  setSelectAll,
  dataSource,
  dataSelected = [],
  setDataSelected,
  attributesSelected = [],
  currentEmployees = [],
  sortState,
  setSortState,
  redirectEdit,
  setSortAscDesc,
  setSortChoiceRole,
  setSortChoiceTeam,
  sortChoiceRole,
  sortChoiceTeam,
  setItemRole,
  setItemTeam,
  setItemEmployeesTeam,
  sortAscDesc,
  onClick,
  teams,
  countries
}) => {
  const [sortChoice, setSortChoice] = useState(true);
  const location = useLocation();
  const onHandleAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setDataSelected([]);
    } else {
      setSelectAll(true);
      const allData = dataSource.map(item => {
        const newObj = {};
        attributesSelected.forEach(el => {
          newObj[el] = item[el];
        });
        return newObj;
      });
      setDataSelected(allData);
    }
  };

  const onHandleChange = id => {
    const _dataSelected = dataSource.slice();

    const filterElement = dataSource?.filter(el => el.id === id.value);

    const objConstruction = {
      id: filterElement.map(el => el?.id)[0],
      name: filterElement.map(el => el?.name)[0],
      url: filterElement.map(el => el?.url)[0]
    };
    const sameOne = dataSelected.find(el => el.id === objConstruction.id);

    if (filterElement) {
      if (sameOne) {
        setDataSelected(
          dataSelected.filter(el => el.id !== objConstruction.id)
        );
      } else {
        setDataSelected([...dataSelected, objConstruction]);
      }
    } else {
      const newSelected = {};
      dataSource.forEach(item => {
        if (item.id === id) {
          attributesSelected.forEach(el => {
            newSelected[el] = item[el];
          });
        }
      });
      _dataSelected.push(newSelected);
      setDataSelected(_dataSelected);
    }
  };

  const handleSort = item => {
    if (
      item?.name === 'Team Name' ||
      item?.name === 'Employees' ||
      item?.name === 'Manager'
    ) {
      setItemTeam(item?.name);
      return setSortChoiceTeam(!sortChoiceTeam);
    } else if (item?.name === 'Team Name' || item?.name === 'Members') {
      setItemTeam(item?.name);
      return setSortChoiceTeam(!sortChoiceTeam);
    }

    if (
      item?.key === 'positionName' ||
      item?.name === 'Low' ||
      item?.name === 'Mid' ||
      item?.name === 'High'
    ) {
      setItemRole(item?.name);
      return setSortChoiceRole(!sortChoiceRole);
    } else if (
      item?.key === 'nameEmployeeTeam' ||
      item?.key === 'rolEmployeeTeam' ||
      item?.key === 'emailEmployeeTeam'
    ) {
      setItemEmployeesTeam(item?.name);
      return setSortChoiceTeam(!sortChoiceTeam);
    } else {
      setSortState(item.key);
      setSortChoice(!sortChoice);
      setSortAscDesc(sortChoice ? 'ASC' : 'DESC');
    }
  };

  let columnName = columns
    ?.filter(el => el.key === 'name')
    ?.map(el => el.active);
  let columnRole = columns
    ?.filter(el => el.key === 'role')
    ?.map(el => el.active);
  let columnSalary = columns
    ?.filter(el => el.key === 'salary')
    ?.map(el => el.active);
  let columnDate = columns
    ?.filter(el => el.key === 'startDate')
    ?.map(el => el.active);
  let columnDedication = columns
    ?.filter(el => el.key === 'schedule')
    ?.map(el => el.active);
  let columnTeams = columns
    ?.filter(el => el.key === 'teams')
    ?.map(el => el.active);
  let columnTeamsManager = columns
    ?.filter(el => el.key === 'managers')
    ?.map(el => el.active);
  let columnStatus = columns
    ?.filter(el => el.key === 'status')
    ?.map(el => el.active);
  let columnId = columns
    ?.filter(el => el.key === 'documentId')
    ?.map(el => el.active);
  let columnAdress = columns
    ?.filter(el => el.key === 'address')
    ?.map(el => el.active);
  let columnAddressTwo = columns
    ?.filter(el => el.key === 'addressTwo')
    ?.map(el => el.active);
  let columnCity = columns
    ?.filter(el => el.key === 'city')
    ?.map(el => el.active);
  let columnState = columns
    ?.filter(el => el.key === 'state')
    ?.map(el => el.active);
  let columnCountry = columns
    ?.filter(el => el.key === 'country')
    ?.map(el => el.active);
  let columnZip = columns
    ?.filter(el => el.key === 'postalCode')
    ?.map(el => el.active);
  let columnNationality = columns
    ?.filter(el => el.key === 'nationality')
    ?.map(el => el.active);
  let columnBirthday = columns
    ?.filter(el => el.key === 'dateOfBirth')
    ?.map(el => el.active);
  let columnTshirt = columns
    ?.filter(el => el.key === 'value')
    ?.map(el => el.active);
  let columnCompanyemail = columns
    ?.filter(el => el.key === 'companyEmail')
    ?.map(el => el.active);
  let columnPersonalemail = columns
    ?.filter(el => el.key === 'personalEmail')
    ?.map(el => el.active);
  let columnPhone = columns
    ?.filter(el => el.key === 'phone')
    ?.map(el => el.active);
  let columnBankName = columns
    ?.filter(el => el.key === 'bankName')
    ?.map(el => el.active);
  let columnAccount = columns
    ?.filter(el => el.key === 'bankAccount')
    ?.map(el => el.active);
  let columnSwift = columns
    ?.filter(el => el.key === 'bankSwiftCode')
    ?.map(el => el.active);
  let columnRouting = columns
    ?.filter(el => el.key === 'routingNumber')
    ?.map(el => el.active);
  let columnBankRouting = columns
    ?.filter(el => el.key === 'intermediaryRouting')
    ?.map(el => el.active);

  return (
    <ContentTable>
      <table>
        <thead>
          <tr>
            {checkbox ? (
              <th
                className="teams-left"
                style={{
                  width: '3%'
                }}
              >
                <div className="custom-control custom-checkbox">
                  <Checkbox
                    id="select-all-items"
                    value={selectAll}
                    onChange={() => onHandleAll()}
                  />
                </div>
              </th>
            ) : null}

            {columns.length
              ? columns?.map((item, i) => {
                  return (
                    <th
                      key={`column-table-${i}`}
                      className="sorting_disabled text-bold"
                      style={{
                        width: item.width
                      }}
                    >
                      <div
                        className={
                          item.key === sortState
                            ? 'sortTitleSelected'
                            : 'sortTitle'
                        }
                      >
                        <span>{item?.name}</span>
                        {sort
                          ? item?.name !== '' && (
                              <div className="arrowSort">
                                <button
                                  className="buttonSort"
                                  onClick={() => {
                                    handleSort(item);
                                  }}
                                >
                                  {item.key === sortState ? (
                                    sortAscDesc === 'ASC' ? (
                                      <ArrowAsc />
                                    ) : (
                                      <ArrowDesc />
                                    )
                                  ) : (
                                    <ArrowSort />
                                  )}
                                </button>
                              </div>
                            )
                          : null}
                      </div>
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data?.length ? (
              data?.map((item, i) => {
                const rowId = item?.dataRow?.find(el => el.key === 'id');
                const checked = dataSelected?.filter(
                  el => el.id === rowId.value
                );
                return (
                  <tr key={`row-table-${i}`} className={'row-data lowerBorder'}>
                    {checkbox && (
                      <td>
                        <Checkbox
                          id={`select-item-${rowId.value}`}
                          value={Object.keys(checked || {})?.length}
                          onChange={() => onHandleChange(rowId)}
                        />
                      </td>
                    )}
                    {item.dataRow.map(data => {
                      if (columns.find(el => el.key === data.key)) {
                        return (
                          <td
                            key={`row-data-${data.key}`}
                            className={(
                              (data.key === 'low' ||
                                data.key === 'mid' ||
                                data.key === 'high') &&
                              'classSalaryDate'
                            ).toString()}
                          >
                            {data.key === 'teamName' ||
                            data.key === 'groupName' ? (
                              <button
                                className="noButtonStyle"
                                onClick={() => {
                                  if (
                                    item.dataRow[1]?.value?.props?.children < 1
                                  ) {
                                    return;
                                  } else {
                                    window.history.pushState(
                                      null,
                                      null,
                                      location.pathname
                                    );
                                    onClick(rowId);
                                  }
                                }}
                              >
                                {data.value}
                              </button>
                            ) : (
                              data.value
                            )}
                          </td>
                        );
                      } else return null;
                    })}
                  </tr>
                );
              })
            ) : currentEmployees?.length ? (
              currentEmployees?.map((el, i) => {
                return (
                  <tr
                    key={'row' + i}
                    className={
                      el?.status === 'ACTIVE'
                        ? 'row-data lowerBorder'
                        : 'row-data lowerBorder statusSeparate'
                    }
                    onClick={() => {
                      window.history.pushState(null, null, location.pathname);
                      onClick(el);
                    }}
                  >
                    {columnName?.length ? (
                      redirectEdit ? (
                        <td>
                          <button
                            className={
                              el?.status === 'ACTIVE'
                                ? 'noButtonStyle'
                                : 'noButtonStyle ButtonStatusSeparate'
                            }
                            onClick={() => {
                              window.history.pushState(
                                null,
                                null,
                                location.pathname
                              );
                              onClick(el);
                            }}
                          >
                            {el?.fullName}
                          </button>
                        </td>
                      ) : (
                        <td>{el?.fullName}</td>
                      )
                    ) : null}
                    {columnRole?.length ? (
                      <td className="classSalaryDate">
                        {el?.employeeRoleInfo?.name}
                      </td>
                    ) : null}
                    {columnSalary?.length ? (
                      <td className="classSalaryDate">
                        ${el?.salary?.toLocaleString('en-US')}
                      </td>
                    ) : null}
                    {columnDate?.length ? (
                      <td className="classSalaryDate">
                        {formatStringDate(el?.startDate)}
                      </td>
                    ) : null}
                    {columnDedication?.length ? (
                      <td className="classSalaryDate">{el?.schedule}</td>
                    ) : null}
                    {columnTeams?.length ? (
                      <td className="classSalaryDate">
                        {el?.employeeTeam?.length ? el?.teamsForName : ''}
                      </td>
                    ) : null}
                    {columnTeamsManager?.length ? (
                      <td className="classSalaryDate">
                        {el?.employeeTeam?.length ? el?.managersForName : ''}
                      </td>
                    ) : null}
                    {columnStatus?.length ? (
                      <td onClick={() => onClick(el)}>
                        <span
                          className={
                            el?.status === 'ACTIVE'
                              ? 'labelStatusActive'
                              : 'labelStatusSeparate'
                          }
                        >
                          {firstUpper(el?.status)}
                        </span>
                      </td>
                    ) : null}
                    {columnId?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.documentId}
                      </td>
                    ) : null}
                    {columnAdress?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.addressOne}
                      </td>
                    ) : null}
                    {columnAddressTwo?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.addressTwo}
                      </td>
                    ) : null}
                    {columnCity?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.city}
                      </td>
                    ) : null}
                    {columnState?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.state}
                      </td>
                    ) : null}
                    {columnCountry?.length ? (
                      <td className="classSalaryDate">
                        {countries?.filter(
                          item =>
                            item.country === el?.contactInformation?.country
                        )?.length
                          ? countries
                              ?.filter(
                                item =>
                                  item.country ===
                                  el?.contactInformation?.country
                              )
                              ?.map(country => country.label)
                          : el?.contactInformation?.country}
                      </td>
                    ) : null}
                    {columnZip?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.postalCode}
                      </td>
                    ) : null}
                    {columnNationality?.length ? (
                      <td className="classSalaryDate">
                        {countries?.filter(
                          item =>
                            item.country === el?.contactInformation?.nationality
                        )?.length
                          ? countries
                              ?.filter(
                                item =>
                                  item.country ===
                                  el?.contactInformation?.nationality
                              )
                              ?.map(country => country.label)
                          : el?.contactInformation?.nationality}
                      </td>
                    ) : null}
                    {columnBirthday?.length ? (
                      <td className="classSalaryDate">
                        {formatDate(el?.dateOfBirth)}
                      </td>
                    ) : null}
                    {columnTshirt?.length ? (
                      <td className="classSalaryDate">
                        {el?.customInformation?.value}
                      </td>
                    ) : null}
                    {columnCompanyemail?.length ? (
                      <td className="classSalaryDate">{el?.user?.email}</td>
                    ) : null}
                    {columnPersonalemail?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.personalEmail}
                      </td>
                    ) : null}
                    {columnPhone?.length ? (
                      <td className="classSalaryDate">
                        {el?.contactInformation?.phone}
                      </td>
                    ) : null}

                    {columnBankName?.length ? (
                      <td className="classSalaryDate">
                        {el?.paymentInformation?.bankName}
                      </td>
                    ) : null}
                    {columnAccount?.length ? (
                      <td className="classSalaryDate">
                        {el?.paymentInformation?.bankAccount}
                      </td>
                    ) : null}
                    {columnSwift?.length ? (
                      <td className="classSalaryDate">
                        {el?.paymentInformation?.bankSwiftCode}
                      </td>
                    ) : null}
                    {columnRouting?.length ? (
                      <td className="classSalaryDate">
                        {el?.paymentInformation?.routingNumber}
                      </td>
                    ) : null}
                    {columnBankRouting?.length ? (
                      <td className="classSalaryDate">
                        {el?.paymentInformation?.intermediaryRouting}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns?.length + 1 || '1'}>
                  {component || <div>No data</div>}
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={columns?.length + 1 || '1'}>
                <div style={{ textAlign: 'center', padding: '120px' }}>
                  <CircularProgress className="circular-progress" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </ContentTable>
  );
};

export default Table;
