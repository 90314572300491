import gql from 'graphql-tag';

export const ME = gql`
  query ME {
    me {
      id
      name
      email
      photo
      companyRole {
        id
        role
        to
        company {
          id
          name
          logo
          timeOffRules {
            id
            initialDaysOff
            daysAdvanceToRequestTimeOff
            daysPerMonth
            rollOverDaysPerYear
          }
        }
      }
      employee {
        id
        firstName
        lastName
        employmentType
        username
        status
        fullName
        permissionEmployee {
          id
          description
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $name: String!
    $token: String!
    $photo: String!
  ) {
    login(login: { email: $email, name: $name, token: $token, photo: $photo }) {
      expiresIn
      token
      companyRole {
        id
        to
        from
        role
        user {
          id
          email
          name
          photo
        }
        company {
          id
          name
          nameLogo
          phone
          logo
        }
      }
      permission {
        description
      }
    }
  }
`;

export const ALL_USER_NOT_EMPLOYEES = gql`
  query ALL_USER_NOT_EMPLOYEES($id: String) {
    users(
      where: {
        companyRole: {
          some: {
            to: { id: { equals: $id } }
            OR: [{ role: { equals: ADMIN } }]
          }
        }
      }
    ) {
      name
      id
      email
      photo
      companyRole {
        id
        role
      }
    }
  }
`;
