import React from 'react';

const LoginWithEmployee = () => {
  return (
    <svg
      width="205"
      height="200"
      viewBox="0 0 205 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4426_357088)">
        <path
          d="M119.885 8.35209C119.885 8.35209 84.1217 2.63916 54.7064 27.7363C25.2954 52.8335 15.6659 93.6185 6.99504 126.462C-1.67583 159.311 12.2287 188.51 39.9685 197.855C67.7041 207.203 80.9738 183.384 116.055 175.845C151.135 168.301 161.555 171.587 187.127 146.24C212.699 120.892 209.271 72.8611 179.73 42.3231C150.19 11.7893 119.885 8.35209 119.885 8.35209Z"
          fill="#E1E4E8"
        />
        <path
          opacity="0.7"
          d="M119.885 8.35209C119.885 8.35209 84.1217 2.63916 54.7064 27.7363C25.2954 52.8335 15.6659 93.6185 6.99504 126.462C-1.67583 159.311 12.2287 188.51 39.9685 197.855C67.7041 207.203 80.9738 183.384 116.055 175.845C151.135 168.301 161.555 171.587 187.127 146.24C212.699 120.892 209.271 72.8611 179.73 42.3231C150.19 11.7893 119.885 8.35209 119.885 8.35209Z"
          fill="#E1E4E8"
        />
        <path
          d="M3.35938 179.633H201.131"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.6163 17.1129C44.6163 17.1129 44.6163 13.3949 39.4086 8.18292C34.2009 2.97521 30.8543 0 30.8543 0C30.8543 0 28.2504 8.55428 33.0868 13.762C37.9231 18.9697 44.6206 23.806 44.6206 23.806V17.1086L44.6163 17.1129Z"
          fill="#18D399"
        />
        <path
          d="M37.0596 40.3433C37.0596 40.3433 37.0596 36.6254 31.8519 31.4134C26.6442 26.2057 23.2976 23.2305 23.2976 23.2305C23.2976 23.2305 20.6938 31.7847 25.5301 36.9968C30.3665 42.2045 37.0639 47.0408 37.0639 47.0408V40.3433H37.0596Z"
          fill="#18D399"
        />
        <path
          d="M44.2937 23.4455C44.2937 23.4455 44.6867 19.7448 50.4169 15.1157C56.1471 10.4867 59.7916 7.88281 59.7916 7.88281C59.7916 7.88281 61.4757 16.666 56.1168 21.3339C50.758 26.0018 43.5898 30.1041 43.5898 30.1041L44.298 23.4455H44.2937Z"
          fill="#18D399"
        />
        <path
          d="M35.1608 50.8835C35.1608 50.8835 35.9381 47.2476 42.126 43.2447C48.3096 39.2417 52.2089 37.0352 52.2089 37.0352C52.2089 37.0352 52.9603 45.9478 47.1437 50.0242C41.3228 54.1049 33.7617 57.4298 33.7617 57.4298L35.1651 50.8835H35.1608Z"
          fill="#18D399"
        />
        <path
          d="M36.8774 80.8004C36.8774 80.8004 36.8774 77.0781 42.0851 71.8704C47.2928 66.6627 50.6393 63.6875 50.6393 63.6875C50.6393 63.6875 53.2432 72.2418 48.4069 77.4495C43.5705 82.6572 36.873 87.4935 36.873 87.4935V80.7961L36.8774 80.8004Z"
          fill="#18D399"
        />
        <path
          d="M36.7871 88.0994C36.7871 88.0994 37.3139 84.416 32.9008 78.5217C28.4876 72.6274 25.5944 69.2031 25.5944 69.2031C25.5944 69.2031 21.8031 77.304 25.8492 83.1465C29.8953 88.9889 35.8371 94.7278 35.8371 94.7278L36.7871 88.0994Z"
          fill="#18D399"
        />
        <path
          d="M31.6528 109.742C31.6528 109.742 31.6528 106.024 36.8605 100.812C42.0682 95.6041 45.4147 92.6289 45.4147 92.6289C45.4147 92.6289 48.0186 101.183 43.1822 106.391C38.3459 111.599 31.6484 116.435 31.6484 116.435V109.737L31.6528 109.742Z"
          fill="#18D399"
        />
        <path
          d="M35.1337 129.361C35.1337 129.361 34.1924 125.764 37.9103 119.403C41.6282 113.047 44.1155 109.32 44.1155 109.32C44.1155 109.32 48.8007 116.938 45.4412 123.199C42.0816 129.46 36.8308 135.838 36.8308 135.838L35.1337 129.361Z"
          fill="#18D399"
        />
        <path
          d="M45.0918 16.1562C44.7852 20.1333 44.3361 24.2442 43.6668 27.7073C41.607 38.343 36.4598 45.2045 34.4043 54.8124C32.3446 64.4204 38.2561 76.7962 36.6714 89.1375C35.0866 101.479 30.2848 108.34 31.658 116.575C33.0312 124.81 40.2339 147.113 40.2339 147.113"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.2422 25.9228L49.8515 21.6133"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.4995 20.9608L42.1504 16.7852"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.7109 54.3754C34.7109 54.3754 39.4091 50.8517 44.2411 47.3281"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.0603 47.0418C37.0603 47.0418 33.1437 39.3641 28.709 34.2773"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.0605 86.0909C37.0605 86.0909 40.7137 78.9141 47.1089 72.6484"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.1495 91.3104C36.1495 91.3104 33.8004 85.6968 31.0584 81.6506C28.3164 77.6045 27.9277 77.082 27.9277 77.082"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.6543 116.575C31.6543 116.575 33.537 111.804 35.8861 108.017C38.2352 104.23 41.3658 100.84 41.3658 100.84"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.8266 135.834C36.8266 135.834 36.2782 131.378 38.2344 127.595C40.1905 123.808 42.41 118.851 43.3255 116.891"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5138 66.8107C10.5138 66.8107 10.1079 64.4788 12.8111 60.6486C15.51 56.8184 17.289 54.5859 17.289 54.5859C17.289 54.5859 19.8497 59.6684 17.384 63.4598C14.9184 67.2511 11.2436 71.0122 11.2436 71.0122L10.5182 66.815L10.5138 66.8107Z"
          fill="#18D399"
        />
        <path
          d="M17.7736 80.5567C17.7736 80.5567 17.3677 78.2249 20.0709 74.3947C22.7697 70.5645 24.5488 68.332 24.5488 68.332C24.5488 68.332 27.1095 73.4145 24.6438 77.2058C22.1781 80.9972 18.5034 84.7583 18.5034 84.7583L17.7779 80.5611L17.7736 80.5567Z"
          fill="#18D399"
        />
        <path
          d="M11.4045 70.7472C11.4045 70.7472 10.7568 68.4716 6.66319 66.1916C2.56957 63.9073 0.000267946 62.668 0.000267946 62.668C0.000267946 62.668 -0.103368 68.3593 3.76571 70.7041C7.63478 73.0488 12.5748 74.8452 12.5748 74.8452L11.4089 70.7472H11.4045Z"
          fill="#18D399"
        />
        <path
          d="M20.1101 86.9602C20.1101 86.9602 19.2249 84.7623 14.9153 82.9271C10.6015 81.0875 7.91992 80.1289 7.91992 80.1289C7.91992 80.1289 8.41651 85.7987 12.5058 87.7246C16.5994 89.6505 21.6992 90.9157 21.6992 90.9157L20.1101 86.9646V86.9602Z"
          fill="#18D399"
        />
        <path
          d="M22.2808 105.908C22.2808 105.908 21.8749 103.577 18.0447 100.873C14.2145 98.1746 11.792 96.6719 11.792 96.6719C11.792 96.6719 11.0882 102.32 14.6852 105.062C18.2822 107.804 23.0063 110.11 23.0063 110.11L22.2808 105.913V105.908Z"
          fill="#18D399"
        />
        <path
          d="M23.1285 110.477C23.1285 110.477 22.3988 108.227 24.5276 104.047C26.6565 99.8715 28.0987 97.4102 28.0987 97.4102C28.0987 97.4102 31.359 102.078 29.4546 106.18C27.5503 110.283 24.4499 114.527 24.4499 114.527L23.1329 110.473L23.1285 110.477Z"
          fill="#18D399"
        />
        <path
          d="M29.6653 129.292C29.6653 129.292 29.9373 126.939 33.6078 124.024C37.2782 121.109 39.61 119.473 39.61 119.473C39.61 119.473 40.6334 125.073 37.2005 128.014C33.7632 130.955 29.1816 133.528 29.1816 133.528L29.6696 129.296L29.6653 129.292Z"
          fill="#18D399"
        />
        <path
          d="M28.6988 123.487C28.6988 123.487 28.2929 121.155 24.4627 118.452C20.6325 115.753 18.21 114.25 18.21 114.25C18.21 114.25 17.5061 119.898 21.1032 122.64C24.7002 125.382 29.4243 127.688 29.4243 127.688L28.6988 123.491V123.487Z"
          fill="#18D399"
        />
        <path
          d="M28.6467 136.168C28.6467 136.168 28.8453 133.81 25.8226 130.226C22.7999 126.642 20.8351 124.578 20.8351 124.578C20.8351 124.578 18.7235 129.864 21.5087 133.426C24.2939 136.989 28.2796 140.417 28.2796 140.417L28.638 136.172L28.6467 136.168Z"
          fill="#18D399"
        />
        <path
          d="M10.1133 66.2617C10.7351 68.7231 11.4649 71.2535 12.2637 73.3478C14.7078 79.7948 18.6805 83.5387 21.0166 89.338C23.3484 95.1373 20.9864 103.54 23.3182 111.11C25.65 118.676 29.4068 122.458 29.4414 127.77C29.4759 133.081 27.3773 147.849 27.3773 147.849"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7064 72.2892L7.7207 70.1992"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.002 69.2118L12.0254 66.3359"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7699 89.1003C20.7699 89.1003 17.4406 87.3989 14.0293 85.7148"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5039 84.7564C18.5039 84.7564 20.1275 79.5185 22.3557 75.8438"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7406 109.247C22.7406 109.247 19.6704 105.141 14.9766 101.906"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8789 112.421C23.8789 112.421 24.7425 108.647 26.0207 105.81C27.2989 102.977 27.4889 102.605 27.4889 102.605"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2598 131.804C29.2598 131.804 31.9025 128.566 33.5477 127.352C35.1929 126.139 36.3329 124.93 36.3329 124.93"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.4416 127.774C29.4416 127.774 27.7446 124.985 25.8575 122.869C23.9748 120.749 21.6387 118.961 21.6387 118.961"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.288 140.413C28.288 140.413 28.1499 137.559 26.509 135.4C24.8724 133.241 22.9422 130.369 22.1562 129.242"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.6043 183.265H21.871C20.9253 183.265 20.161 182.496 20.161 181.555L14.7676 145.559H50.7076L45.3143 181.555C45.3143 182.5 44.5456 183.265 43.6043 183.265Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.269 5.22133L138.994 9.49609L143.269 13.7709L147.544 9.49609L143.269 5.22133Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.13 59.6315L190.855 63.9062L195.13 68.181L199.405 63.9062L195.13 59.6315Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.2904 163.268L60.0156 167.543L64.2904 171.818L68.5652 167.543L64.2904 163.268Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M119.087 26.425L116.754 28.7578L119.087 31.0906L121.42 28.7578L119.087 26.425Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.4499 23.7514C73.4039 23.7514 74.1772 22.9781 74.1772 22.0241C74.1772 21.0702 73.4039 20.2969 72.4499 20.2969C71.496 20.2969 70.7227 21.0702 70.7227 22.0241C70.7227 22.9781 71.496 23.7514 72.4499 23.7514Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M201.132 117.455C202.086 117.455 202.859 116.681 202.859 115.727C202.859 114.773 202.086 114 201.132 114C200.178 114 199.404 114.773 199.404 115.727C199.404 116.681 200.178 117.455 201.132 117.455Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.86 30.228C165.814 30.228 166.587 29.4546 166.587 28.5007C166.587 27.5468 165.814 26.7734 164.86 26.7734C163.906 26.7734 163.133 27.5468 163.133 28.5007C163.133 29.4546 163.906 30.228 164.86 30.228Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4284 38.8647C13.3824 38.8647 14.1557 38.0914 14.1557 37.1374C14.1557 36.1835 13.3824 35.4102 12.4284 35.4102C11.4745 35.4102 10.7012 36.1835 10.7012 37.1374C10.7012 38.0914 11.4745 38.8647 12.4284 38.8647Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M191.281 35.668H27.8649C25.8426 35.668 24.2031 37.3074 24.2031 39.3298V157.375C24.2031 159.398 25.8426 161.037 27.8649 161.037H191.281C193.304 161.037 194.943 159.398 194.943 157.375V39.3298C194.943 37.3074 193.304 35.668 191.281 35.668Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.815 170.706V156.586H88.3326V170.706C88.3326 175.314 86.7435 179.787 83.8201 183.402L81.959 185.703H137.188L135.327 183.402C132.404 179.787 130.815 175.314 130.815 170.706Z"
          fill="white"
        />
        <path
          d="M85.7246 165.712H133.423C131.722 162.698 130.815 159.3 130.815 155.824V141.703H88.3328V155.824C88.3328 159.3 87.426 162.702 85.7246 165.712Z"
          fill="black"
        />
        <path
          d="M130.815 170.706V156.586H88.3326V170.706C88.3326 175.314 86.7435 179.787 83.8201 183.402L81.959 185.703H137.188L135.327 183.402C132.404 179.787 130.815 175.314 130.815 170.706V170.706Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.197 185.707H81.959V188.229H137.197V185.707Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2031 141.551H194.943V157.373C194.943 159.393 193.302 161.034 191.281 161.034H27.8649C25.844 161.034 24.2031 159.393 24.2031 157.373V141.551Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M186.36 43.4688H32.793V138.758H186.36V43.4688Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M154.773 52.3906H184.383"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 119.27H184.383"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 56.9453H176.93"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 59.0117H170.923"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 86.9727H176.93"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 89.0391H170.923"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 65.8516H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 67.918H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 69.9922H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 76.1992H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 78.2734H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 80.3398H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 96.0781H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 98.1523H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M154.773 100.219H181.278"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M186.243 43.4688H32.793V48.128H186.243V43.4688Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M176.239 45.8472C176.239 46.3654 175.82 46.7842 175.302 46.7842C174.784 46.7842 174.365 46.3654 174.365 45.8472C174.365 45.329 174.784 44.9102 175.302 44.9102C175.82 44.9102 176.239 45.329 176.239 45.8472Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M180.292 45.8472C180.292 46.3654 179.873 46.7842 179.355 46.7842C178.837 46.7842 178.418 46.3654 178.418 45.8472C178.418 45.329 178.837 44.9102 179.355 44.9102C179.873 44.9102 180.292 45.329 180.292 45.8472Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.347 45.8472C184.347 46.3654 183.928 46.7842 183.41 46.7842C182.892 46.7842 182.473 46.3654 182.473 45.8472C182.473 45.329 182.892 44.9102 183.41 44.9102C183.928 44.9102 184.347 45.329 184.347 45.8472Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M152.769 48.0781H32.793V138.76H152.769V48.0781Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.48 134.156H34.4512V136.747H38.48V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M52.5835 134.156H48.5547V136.747H52.5835V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M66.691 134.156H62.6621V136.747H66.691V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M80.7984 134.156H76.7695V136.747H80.7984V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M94.9019 134.156H90.873V136.747H94.9019V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M142.117 134.156H138.088V136.747H142.117V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M126.281 134.156H122.252V136.747H126.281V134.156Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
        />
        <path
          d="M152.402 92.3047H114.203V130.853H152.402V92.3047Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.508 102.289H124.096V120.874H142.508V102.289Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.891 112.019L132.451 112.757V115.274H131.613V107.77H132.451V111.392L134.446 107.77H135.292L133.397 111.19L135.357 115.274H134.498L132.891 112.014V112.019Z"
          fill="black"
        />
        <path
          d="M112.72 92.3047H74.5254V130.853H112.72V92.3047Z"
          fill="#BABDBF"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M112.781 130.782V124.391C110.79 123.515 105.241 121.084 102.56 120.086C99.2562 118.86 95.1928 116.403 87.8304 117.914C87.8304 117.914 78.1059 123.204 76.1195 123.96C75.3423 124.258 74.8154 124.478 74.457 124.642V130.782H112.781Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M82.2377 123.129L80.9336 126.7L85.3727 127.641L82.3931 130.548"
          fill="#788084"
        />
        <path
          d="M82.2377 123.129L80.9336 126.7L85.3727 127.641L82.3931 130.548"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M105.276 129.729L105.863 128.684L102.465 126.982L106.243 125L104.589 120.885C103.825 120.574 103.13 120.298 102.564 120.086C99.2607 118.86 95.1973 116.403 87.8349 117.914C87.8349 117.914 85.7103 119.067 83.2447 120.376L82.584 122.181"
          fill="#788084"
        />
        <path
          d="M105.276 129.729L105.863 128.684L102.465 126.982L106.243 125L104.589 120.885C103.825 120.574 103.13 120.298 102.564 120.086C99.2607 118.86 95.1973 116.403 87.8349 117.914C87.8349 117.914 85.7103 119.067 83.2447 120.376L82.584 122.181"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.2528 130.782H97.4987C97.9607 129.577 98.4487 128.161 98.8762 126.602C100.198 121.787 99.2519 118.859 99.2519 118.859L87.0703 118.574C87.0703 118.574 87.0703 123.013 89.0523 128.113C89.4496 129.136 89.8555 130.017 90.2485 130.782H90.2528Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.8561 130.783H96.7857L95.568 128.589L97.4593 125.096L94.7216 121.223L90.6582 124.811L93.4909 128.969L92.8561 130.783Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.0707 118.578C87.0707 118.578 87.0707 123.017 89.0528 128.117C89.1305 128.316 89.2082 128.51 89.2859 128.7L94.1525 123.678L98.272 128.601C98.4793 127.966 98.6823 127.301 98.8723 126.606C100.194 121.791 99.2479 118.863 99.2479 118.863L87.0664 118.578H87.0707Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.5449 119.145C87.5449 119.145 88.5856 122.448 90.2826 123.864C91.9797 125.281 94.9462 124.788 96.1381 124.149C97.684 123.316 99.761 119.814 99.1608 119.805C98.3533 119.792 87.5449 119.145 87.5449 119.145Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.6173 108.098C84.6173 108.098 83.5766 106.492 82.7303 106.967C81.8796 107.437 81.6896 109.044 82.0696 110.84C82.4496 112.636 85.373 113.388 85.373 113.388L84.6173 108.098Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M101.713 108.098C101.713 108.098 102.753 106.492 103.6 106.967C104.45 107.437 104.64 109.044 104.26 110.84C103.88 112.636 100.957 113.388 100.957 113.388L101.713 108.098Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.356 98.558C87.356 98.558 85.374 99.3137 84.8083 100.73C84.2426 102.146 84.6183 109.228 84.9983 111.59C85.374 113.952 86.039 117.541 86.6996 118.957C87.3603 120.373 91.9894 123.206 95.2928 122.26C98.5962 121.315 100.768 119.238 100.863 116.785C100.958 114.328 102.28 103.187 102.28 101.771C102.28 100.354 96.4242 97.051 93.4964 96.671C90.5687 96.2953 87.3603 98.558 87.3603 98.558H87.356Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.5977 105.564C87.5977 105.564 88.7981 104.812 90.7456 105.115L87.5977 105.564Z"
          fill="white"
        />
        <path
          d="M87.5977 105.564C87.5977 105.564 88.7981 104.812 90.7456 105.115"
          stroke="black"
          strokeWidth="1.32925"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M95.2441 105.489C95.2441 105.489 97.6191 104.927 99.2168 105.787L95.2441 105.489Z"
          fill="white"
        />
        <path
          d="M95.2441 105.489C95.2441 105.489 97.6191 104.927 99.2168 105.787"
          stroke="black"
          strokeWidth="1.32925"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.9269 106.398H87.2781C86.5388 106.398 85.9395 106.998 85.9395 107.737V109.119C85.9395 109.858 86.5388 110.458 87.2781 110.458H90.9269C91.6662 110.458 92.2656 109.858 92.2656 109.119V107.737C92.2656 106.998 91.6662 106.398 90.9269 106.398Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M99.425 106.398H95.7761C95.0368 106.398 94.4375 106.998 94.4375 107.737V109.119C94.4375 109.858 95.0368 110.458 95.7761 110.458H99.425C100.164 110.458 100.764 109.858 100.764 109.119V107.737C100.764 106.998 100.164 106.398 99.425 106.398Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M91.4141 106.398H95.2875"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.2637 107.25H94.4357"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M85.9385 107.25H84.6172"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M100.762 107.25H101.612"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M89.9987 108.474C89.9987 108.893 89.8087 109.23 89.5755 109.23C89.3423 109.23 89.1523 108.893 89.1523 108.474C89.1523 108.056 89.3423 107.719 89.5755 107.719C89.8087 107.719 89.9987 108.056 89.9987 108.474Z"
          fill="black"
        />
        <path
          d="M97.7396 109.23C97.9733 109.23 98.1628 108.892 98.1628 108.474C98.1628 108.057 97.9733 107.719 97.7396 107.719C97.5059 107.719 97.3164 108.057 97.3164 108.474C97.3164 108.892 97.5059 109.23 97.7396 109.23Z"
          fill="black"
        />
        <path
          d="M91.0378 109.703C91.0378 109.703 89.4314 111.78 88.2051 111.97"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M96.418 109.609C96.418 109.609 97.6443 111.216 99.2507 111.686"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M92.1701 113.575C92.1701 113.575 89.4324 113.95 88.7717 115.367C88.1887 116.619 88.6335 119.637 89.0092 120.976C90.8055 122.021 93.2885 122.833 95.2878 122.258C96.5616 121.896 97.6628 121.36 98.5437 120.704C99.0705 119.175 99.2216 116.645 98.2155 115.267C97.1748 113.851 93.9664 113.285 92.1744 113.566L92.1701 113.575Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M93.4915 114.424C93.4915 114.424 90.4688 114.99 90.1882 115.651C89.9075 116.311 90.1882 117.538 90.7538 118.199C91.3195 118.859 91.8852 119.144 92.3602 118.764C92.8309 118.389 92.4552 117.724 93.3059 117.724C94.1565 117.724 94.4372 117.629 94.7222 117.914C95.0072 118.199 94.8172 118.859 95.8536 118.669C96.8943 118.479 97.3649 117.443 97.4599 116.402C97.5549 115.361 94.9122 114.325 93.4959 114.42L93.4915 114.424Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M90.9434 116.029C90.9434 116.029 93.5861 116.784 96.2331 115.934"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M91.2285 111.875C91.2285 111.875 91.6042 114.047 93.4956 113.952C95.3826 113.857 95.7626 112.16 95.7626 112.16"
          fill="white"
        />
        <path
          d="M91.2285 111.875C91.2285 111.875 91.6042 114.047 93.4956 113.952C95.3826 113.857 95.7626 112.16 95.7626 112.16"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M100.197 104.698L100.482 106.115L100.823 106.646C100.823 106.646 100.862 108.852 101.428 108.757C101.994 108.662 102.939 104.413 102.939 103.658C102.939 102.902 100.107 103.753 100.202 104.698H100.197Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M86.974 100.355C86.974 100.355 90.1824 103.283 94.6215 104.795C99.0606 106.306 103.875 104.7 104.251 102.813C104.627 100.925 99.4362 95.0701 94.3365 93.4637C89.2367 91.8574 85.4584 98.0928 85.4584 98.0928C85.4584 98.0928 83.6663 97.9978 83.0013 99.7941C82.3406 101.586 83.0013 103.758 83.472 104.894C83.9427 106.025 84.1327 107.917 84.1327 107.917C84.1327 107.917 84.3227 108.767 84.6034 108.107C84.8884 107.446 85.169 105.935 85.169 105.935C85.169 105.935 87.8118 104.518 87.8118 103.197C87.8118 101.875 86.9611 100.364 86.9611 100.364L86.974 100.355Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M84.9802 128.418H74.8066V130.827H84.9802V128.418Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M112.72 92.3047H74.5254V130.853H112.72V92.3047Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.0457 92.3047H34.8516V130.853H73.0457V92.3047Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.7898 124.417C57.9744 123.234 54.4292 122.781 52.8876 122.6C51.3417 122.418 48.6169 122.962 48.6169 122.962C48.6169 122.962 38.9874 128.14 35.8093 129.051C35.4681 129.146 35.1615 129.237 34.8809 129.323V130.791H73.0966V129.5C69.8105 128.148 62.9144 125.307 60.7942 124.417H60.7898Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M47.6411 130.787H58.7604C60.2847 129.431 61.239 127.527 61.239 125.415C61.239 125.134 61.2218 124.858 61.1872 124.586C61.0404 124.525 60.9022 124.469 60.7856 124.417C57.9702 123.239 54.425 122.781 52.8834 122.6C51.3375 122.418 48.6127 122.962 48.6127 122.962C48.6127 122.962 47.1575 123.744 45.1927 124.754C45.1712 124.974 45.1582 125.195 45.1582 125.415C45.1582 127.527 46.1125 129.431 47.6368 130.787H47.6411Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M46.0684 128.595L47.977 127.234"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M47.7969 120.961C47.7969 120.961 47.9782 124.411 48.0689 125.141C48.1596 125.866 51.0657 128.682 52.7023 129.321C54.3389 129.956 56.7916 127.684 57.6984 126.687C58.6052 125.689 58.8773 120.965 58.8773 120.965C58.8773 120.965 55.1507 123.418 53.1557 122.965C51.1607 122.511 47.7969 120.965 47.7969 120.965V120.961Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M52.888 122.895C50.8714 122.373 47.7969 120.961 47.7969 120.961C47.7969 120.961 47.9782 124.411 48.0689 125.141C48.1078 125.465 48.708 126.203 49.5025 126.976C49.7228 126.946 49.9387 126.903 50.146 126.847C52.6462 126.186 55.4141 125.003 55.4141 125.003L52.8837 122.895H52.888Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.0713 112.605C45.0713 112.605 44.2552 111.517 43.5254 112.515C42.8 113.512 43.7068 115.33 44.0695 116.332C44.4322 117.329 45.8875 117.329 45.8875 117.329L45.0713 112.605Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.4658 112.646C60.4658 112.646 61.2085 111.501 61.9987 112.451C62.7889 113.401 62.0073 115.275 61.7094 116.295C61.4114 117.318 59.9648 117.413 59.9648 117.413L60.4658 112.646Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.0724 112.604C45.0724 112.604 45.8885 118.601 46.4369 119.962C46.981 121.326 50.8889 124.776 51.2517 125.139C51.6144 125.502 53.7951 126.413 54.8876 125.955C55.9757 125.502 56.8869 124.137 56.8869 124.137C56.8869 124.137 59.793 120.778 60.1557 119.413C60.5184 118.049 60.5184 110.509 60.5184 110.509C60.5184 110.509 60.065 105.695 59.4303 104.878C58.7955 104.062 53.4323 103.605 50.3449 103.514C47.2574 103.423 45.2581 103.423 45.0767 104.788C44.8953 106.152 45.0767 112.599 45.0767 112.599L45.0724 112.604Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.6092 105.519C58.6092 105.519 58.3371 106.974 58.6092 107.518C58.8812 108.062 59.6067 108.973 59.6067 108.973C59.6067 108.973 59.516 112.333 59.6067 112.609C59.6974 112.881 60.2415 113.244 60.3321 112.609C60.4228 111.975 60.7855 109.612 60.9669 109.068C61.1483 108.524 61.783 107.341 61.783 105.8C61.783 104.258 60.6042 103.438 60.6042 103.438L58.6049 105.527L58.6092 105.519Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M44.7993 111.603C44.7993 111.603 43.4347 107.06 42.8906 104.516C42.3465 101.973 43.072 99.1576 44.4365 98.2465C45.8011 97.3397 47.6147 97.1583 47.6147 97.1583C47.6147 97.1583 49.4326 95.0683 52.0667 94.7056C54.7008 94.3429 56.6094 94.887 58.246 95.9795C59.8826 97.0677 61.7869 96.7956 63.0608 96.7956C64.3346 96.7956 64.516 100.246 63.0608 102.064C61.6055 103.882 58.6994 106.153 56.2467 106.697C53.794 107.241 50.4345 106.244 49.0699 105.609C47.7054 104.974 47.0706 104.521 47.0706 104.521C47.0706 104.521 48.2495 106.611 47.7054 107.336C47.1613 108.062 45.8874 109.336 45.8874 109.336C45.8874 109.336 46.3408 112.876 45.8874 113.243C45.434 113.606 44.7993 111.607 44.7993 111.607V111.603Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.4141 102.304C49.9945 103.496 52.5897 104.925 55.8499 104.813C61.4031 104.627 62.9749 99.4453 62.9749 99.4453"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M46.6953 100.738C46.6953 100.738 46.9199 101.006 47.3474 101.399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.1543 102.041C61.2209 101.536 61.7736 100.832 61.7736 100.832"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.8203 99.168C48.8203 99.168 52.1496 101.02 55.9453 102.221C57.0594 102.575 58.018 102.601 58.8212 102.463"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M54.5204 110.879C54.5204 110.879 54.067 114.148 54.2484 115.331C54.4297 116.51 54.9738 117.874 53.9763 118.056C52.9788 118.237 51.6143 117.33 51.7957 116.691"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.0664 118.417C50.0664 118.417 51.703 119.963 53.793 119.782C55.883 119.601 56.8805 118.055 56.8805 118.055"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M50.2503 113.601C50.5007 113.601 50.7037 113.236 50.7037 112.785C50.7037 112.334 50.5007 111.969 50.2503 111.969C49.9999 111.969 49.7969 112.334 49.7969 112.785C49.7969 113.236 49.9999 113.601 50.2503 113.601Z"
          fill="black"
        />
        <path
          d="M56.6998 112.785C56.6998 113.238 56.4968 113.601 56.2464 113.601C55.9959 113.601 55.793 113.234 55.793 112.785C55.793 112.336 55.9959 111.969 56.2464 111.969C56.4968 111.969 56.6998 112.336 56.6998 112.785Z"
          fill="black"
        />
        <path
          d="M48.2057 111.467C48.2057 111.467 48.7497 110.923 49.9329 110.742C51.1118 110.56 52.295 111.195 52.295 110.832C52.295 110.47 51.1161 109.559 49.7516 109.559C48.387 109.559 47.7522 111.104 48.2057 111.467Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.142 110.741C58.142 110.741 57.5979 110.197 56.4148 110.015C55.2359 109.834 54.0527 110.469 54.0527 110.106C54.0527 109.743 55.2316 108.832 56.5961 108.832C57.9607 108.832 58.5954 110.378 58.142 110.741Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.916 114.156L50.4878 114.528"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M55.7598 114.433L57.6123 114.066"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.3494 128.418H35.1758V130.827H45.3494V128.418Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.0457 92.3047H34.8516V130.853H73.0457V92.3047Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.402 52.3477H114.203V90.8959H152.402V52.3477Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.097 72.8967C124.097 72.8967 122.154 74.7535 120.737 77.4049C119.321 80.0562 121.532 84.301 125.422 85.5403C129.313 86.7796 138.329 87.3971 140.804 84.8321C143.278 82.2671 143.723 78.2901 142.661 76.3469C141.598 74.4038 139.478 73.959 137.267 71.8388C135.056 69.7186 128.428 67.9481 124.097 72.9011V72.8967Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M122.77 69.8927C122.77 69.8927 121.799 69.6293 121.799 71.1321C121.799 72.6348 122.77 74.4009 123.565 74.755C124.359 75.1091 125.068 74.4916 125.068 74.4916L124.005 69.8927H122.766H122.77Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M140.45 69.8927C140.45 69.8927 141.421 69.6293 141.421 71.1321C141.421 72.6348 140.45 74.4009 139.655 74.755C138.861 75.1091 138.152 74.4916 138.152 74.4916L139.215 69.8927H140.454H140.45Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M151.499 90.8239V89.6968C151.499 89.6968 137.267 83.863 136.822 83.2412C136.382 82.6237 136.204 78.3789 136.204 78.3789L127.452 78.556V82.3559C127.452 83.5952 127.452 83.6816 126.212 84.7439C124.973 85.8061 114.631 90.3143 114.277 90.6684C114.255 90.69 114.238 90.7418 114.221 90.8239H151.495H151.499Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M127.888 82.3621C127.888 82.6644 127.888 82.8932 127.871 83.0875L133.567 81.7791L132.669 79.2141C132.669 79.2141 132.042 78.8471 131.364 78.4844L127.888 78.5535V82.3535V82.3621Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M151.499 90.8213V89.6943C151.499 89.6943 144.137 86.6759 139.84 84.75C139.253 85.9634 137.116 89.8886 133.652 90.8213H151.499Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.543 90.8235C127.205 89.9857 125.365 86.7342 124.808 85.5898C121.63 87.2696 114.574 90.3744 114.281 90.668C114.259 90.6896 114.242 90.7414 114.225 90.8235H130.543Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M126.683 90.8207L125.906 84.9609C123.798 86.3168 114.609 90.3327 114.277 90.6652C114.255 90.6868 114.238 90.7386 114.221 90.8207H126.679H126.683Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.403 90.8205V89.6934C152.403 89.6934 140.778 85.2975 137.66 83.7344L137.229 90.8248H152.403V90.8205Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.097 72.2802C124.097 72.2802 125.245 76.3479 125.776 77.5829C126.308 78.8222 130.462 82.0047 131.347 82.3588C132.232 82.7129 133.998 82.4451 134.793 81.6506C135.587 80.8561 137.712 78.2047 138.152 77.2288C138.593 76.2572 139.478 69.8016 139.478 69.8016C139.478 69.8016 140.096 68.2082 139.478 66.7054C138.861 65.2027 134.085 61.5798 131.17 60.4268C128.251 59.2782 127.456 60.4268 125.336 63.8727C123.216 67.3186 122.948 68.3809 123.125 69.1754C123.302 69.97 123.92 71.9175 123.92 71.9175L124.097 72.2715V72.2802Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M132.583 69.0977L132.76 72.3665C132.76 72.3665 133.468 73.4288 132.937 73.6922C132.405 73.9556 131.08 73.7785 130.816 73.3381"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M129.49 75.7252C129.49 75.7252 131.17 76.6968 132.496 76.3427C133.821 75.9886 134.707 75.3711 134.707 75.3711"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M129.578 70.2477C129.578 70.6881 129.38 71.0422 129.138 71.0422C128.896 71.0422 128.697 70.6881 128.697 70.2477C128.697 69.8072 128.896 69.4531 129.138 69.4531C129.38 69.4531 129.578 69.8072 129.578 70.2477Z"
          fill="black"
        />
        <path
          d="M135.057 70.2477C135.057 70.6881 134.858 71.0422 134.616 71.0422C134.374 71.0422 134.176 70.6881 134.176 70.2477C134.176 69.8072 134.374 69.4531 134.616 69.4531C134.858 69.4531 135.057 69.8072 135.057 70.2477Z"
          fill="black"
        />
        <path
          d="M127.189 68.3906C127.189 68.3906 128.252 67.5054 130.195 67.7731"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M133.467 67.5039C133.467 67.5039 135.233 67.1498 136.472 68.4755"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.193 62.0249C130.193 62.0249 129.044 65.3845 127.097 66.8872C125.149 68.3899 124.091 68.8303 124.091 68.8303V72.2762C124.091 72.7167 123.828 72.1899 123.297 71.4817C122.766 70.7735 122.766 69.8019 121.086 68.5626C119.406 67.3233 118.257 65.5572 119.143 63.614C120.028 61.6708 122.062 56.895 126.039 54.8611C130.016 52.8272 133.021 53.3584 135.146 54.1529C137.266 54.9475 142.836 60.5179 143.631 62.4611C144.425 64.4042 142.659 67.4097 141.597 68.2949C140.535 69.1801 139.563 69.1801 137.883 68.3813C136.204 67.5867 131.873 64.5813 131.341 64.0501C130.81 63.519 130.193 62.0163 130.193 62.0163V62.0249Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M121.405 66.7109C120.52 66.9916 120.312 66.9096 120.312 66.9096"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M129.412 59.3477C129.412 59.3477 128.643 63.3204 124.796 65.2419C123.941 65.6694 123.241 65.989 122.676 66.2308"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.762 66.5859C142.094 66.9357 142.232 67.2639 142.103 67.5532"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.309 58.4492C130.309 58.4492 131.716 62.0376 135.309 63.3201C137.326 64.0412 139.385 64.9221 140.711 65.7642"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.615 60.7969C141.64 63.1675 142.742 65.3698 142.742 65.3698"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.18 56.2734C130.18 56.2734 136.204 57.1716 138.769 59.8618"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M128.517 57.8086C128.517 57.8086 126.337 59.0911 125.698 60.6284C125.058 62.1656 124.031 64.4715 122.623 65.1149"
          stroke="#3C464C"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M138.505 68.4752L138.946 71.8347L140.539 68.3888L138.328 68.1211L138.505 68.4752Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M124.613 88.0859H114.439V90.4955H124.613V88.0859Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M152.402 52.3477H114.203V90.8959H152.402V52.3477Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M112.721 52.3477H74.5273V90.8959H112.721V52.3477Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.7212 73.4319H88.1685L87.6719 69.8047H88.0648L88.488 72.8922L88.8939 69.809H89.2912L89.6885 72.8965L90.0987 69.8047H90.4528L89.9605 73.4319H89.425L89.0796 70.9706L88.7212 73.4319Z"
          fill="white"
        />
        <path
          d="M91.1828 69.8086V73.4359H90.7812V69.8086H91.1828Z"
          fill="white"
        />
        <path
          d="M91.5977 73.4319V69.8047H91.9992V73.0606H93.0356V73.4276H91.5977V73.4319Z"
          fill="white"
        />
        <path
          d="M94.9127 70.6759V70.7753H94.5284V70.6543C94.5284 70.3262 94.4031 70.1362 94.1138 70.1362C93.8245 70.1362 93.6993 70.3262 93.6993 70.65C93.6993 71.4618 94.917 71.5396 94.917 72.563C94.917 73.1114 94.6536 73.4698 94.1009 73.4698C93.5482 73.4698 93.2891 73.1114 93.2891 72.563V72.3557H93.6691V72.5846C93.6691 72.9127 93.8029 73.1027 94.0922 73.1027C94.3816 73.1027 94.5154 72.9127 94.5154 72.5846C94.5154 71.7771 93.302 71.6993 93.302 70.6803C93.302 70.1189 93.5611 69.7734 94.1052 69.7734C94.6493 69.7734 94.9127 70.1318 94.9127 70.6803V70.6759Z"
          fill="white"
        />
        <path
          d="M95.2422 70.6807C95.2422 70.1279 95.5185 69.7695 96.0713 69.7695C96.624 69.7695 96.9047 70.1279 96.9047 70.6807V72.5547C96.9047 73.1032 96.6283 73.4659 96.0713 73.4659C95.5142 73.4659 95.2422 73.1032 95.2422 72.5547V70.6807ZM95.6395 72.5763C95.6395 72.9088 95.7776 73.0988 96.0713 73.0988C96.3649 73.0988 96.5031 72.9088 96.5031 72.5763V70.6591C96.5031 70.3309 96.3692 70.1366 96.0713 70.1366C95.7733 70.1366 95.6395 70.3352 95.6395 70.6591V72.5763Z"
          fill="white"
        />
        <path
          d="M98.6222 73.4276H98.2336L97.6334 70.5431V73.4319H97.2793V69.8047H97.845L98.4409 72.6979L99.0152 69.8047H99.5809V73.4319H99.1966V70.5172L98.6222 73.4276Z"
          fill="white"
        />
        <path
          d="M73.0457 52.3516H34.8516V90.8998H73.0457V52.3516Z"
          fill="#A6ABAD"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M64.1886 58.1659C62.7291 57.0173 60.9543 56.2875 60.9543 56.2875C60.9543 56.2875 62.5175 55.6613 62.103 54.9316C61.6841 54.2018 56.675 52.9495 54.4857 52.9495C52.2964 52.9495 50.8326 53.0532 50.8326 53.0532C50.8326 53.0532 51.5062 52.5566 51.5839 52.25H50.051C48.6648 52.7595 46.808 54.0852 45.409 56.3954C45.409 56.3954 40.7151 58.0666 39.8774 59.4225C39.044 60.7784 41.3369 60.4675 41.3369 60.4675C41.3369 60.4675 38.5215 65.4765 38.5215 67.3549C38.5215 69.2333 40.2962 69.0261 40.2962 69.0261C40.2962 69.0261 40.5035 75.7063 41.8594 76.7469C43.2153 77.7919 43.7378 76.6433 43.7378 76.6433C43.7378 76.6433 46.5533 78.729 50.1028 79.8776C53.6523 81.0262 59.7021 79.2515 61.6841 78.2065C63.6661 77.1615 65.1257 76.0172 65.1257 76.0172C65.1257 76.0172 65.333 77.2694 66.0627 76.2244C66.7925 75.1794 67.1077 69.0261 67.1077 69.0261C67.1077 69.0261 68.0448 69.4449 68.2563 68.3999C68.4636 67.3549 67.6302 65.8954 67.6302 65.8954C67.6302 65.8954 68.9861 66.9404 68.7788 64.8504C68.5716 62.7647 65.6482 59.3188 64.1886 58.1702V58.1659Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.0756 90.8232V83.9875C70.1047 82.813 59.8275 78.7453 58.3507 78.171C56.6234 77.5017 50.1073 77.4023 50.1073 77.4023C50.1073 77.4023 35.9179 83.7285 34.7692 84.3071C34.7649 84.3071 34.7563 84.3114 34.752 84.3157V90.8232H73.0756Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M42.434 89.1957L42.9695 90.8193H64.0291L64.4869 89.4807L62.0903 86.6048L64.4869 83.3445L61.4037 79.3675C59.9183 78.7802 58.7739 78.3312 58.3551 78.1671C56.6278 77.4978 50.1117 77.3984 50.1117 77.3984C50.1117 77.3984 47.7108 78.4693 44.8047 79.7691L42.3477 84.6832L45.0336 86.6004L42.447 89.187L42.434 89.1957Z"
          fill="#18D399"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.5413 90.8228V78.2483C58.4679 78.2181 58.4032 78.1922 58.3513 78.1749C56.6241 77.5056 50.108 77.4062 50.108 77.4062C50.108 77.4062 49.3177 77.7603 48.0957 78.3044V90.8228H58.5457H58.5413Z"
          fill="white"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M53.5608 90.8248H54.1567C54.8389 89.8619 56.4841 87.4912 56.9073 86.3901C57.5551 84.7189 58.8246 81.2342 58.1251 77.9006C57.8703 76.6958 57.5291 75.016 57.5291 75.016C57.5291 75.016 51.1599 76.1085 48.8756 73.8242C48.8756 73.8242 49.1735 75.7156 48.5776 77.2053C47.7701 79.2219 48.461 81.4803 49.1303 83.4321C49.7133 85.1378 52.628 89.4603 53.5608 90.8248Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.9454 76.1953C48.9454 76.1953 45.9529 82.4782 51.9379 83.3764C53.7385 83.6485 57.7242 77.4908 57.7242 77.4908L48.9454 76.1953Z"
          fill="black"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M49.3722 60.25C49.3722 60.25 45.5117 65.9888 45.5117 66.4077C45.5117 66.8266 46.0342 72.2502 46.5567 73.714C47.0792 75.1736 49.6874 77.8897 51.3585 78.8267C53.0296 79.7638 55.0117 79.2456 56.3676 78.7231C57.7235 78.2006 59.8092 76.3222 60.3317 74.6554C60.8541 72.9843 61.0614 66.7229 61.0614 66.7229L54.174 62.5473C54.174 62.5473 54.0703 63.5923 53.3405 63.0698C52.6108 62.5473 49.3765 60.2543 49.3765 60.2543L49.3722 60.25Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.8234 69.0156C45.8234 69.0156 43.9709 70.3543 44.2861 70.8768C44.597 71.3992 45.0936 73.6058 46.2422 73.0833L45.8234 69.0156Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M61.0614 69.1211L60.6426 73.2449C60.6426 73.2449 62.5858 71.8718 62.7283 70.7922C62.8103 70.192 61.0571 69.1211 61.0571 69.1211H61.0614Z"
          fill="#788084"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M51.0428 68.1821C51.0428 68.67 50.8571 69.0673 50.6239 69.0673C50.3908 69.0673 50.2051 68.67 50.2051 68.1821C50.2051 67.6941 50.3908 67.2969 50.6239 67.2969C50.8571 67.2969 51.0428 67.6941 51.0428 68.1821Z"
          fill="black"
        />
        <path
          d="M56.6795 68.1821C56.6795 68.67 56.4938 69.0673 56.2607 69.0673C56.0275 69.0673 55.8418 68.67 55.8418 68.1821C55.8418 67.6941 56.0275 67.2969 56.2607 67.2969C56.4938 67.2969 56.6795 67.6941 56.6795 68.1821Z"
          fill="black"
        />
        <path
          d="M48.4355 66.3026C48.4355 66.3026 49.5842 65.0503 51.5662 65.5728"
          stroke="black"
          strokeWidth="1.32925"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M55.1113 65.5728C55.1113 65.5728 56.7825 64.7394 58.242 66.199"
          stroke="black"
          strokeWidth="1.32925"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M51.3945 72.9519C51.3945 72.9519 52.4913 73.8113 53.8991 73.7335C55.3068 73.6558 56.0107 72.6367 56.0107 72.6367"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M55.1246 70.7773C55.1246 70.7773 53.6478 71.7619 52.7583 71.2696L51.873 70.7773"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48.5215 69.5938H50.5899"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M56.209 69.8906H58.1781"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M45.3494 88.0859H35.1758V90.4955H45.3494V88.0859Z"
          fill="#263238"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M73.0457 52.3516H34.8516V90.8998H73.0457V52.3516Z"
          stroke="black"
          strokeWidth="0.443082"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4426_357088">
          <rect width="204.292" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoginWithEmployee;
