import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
// import App from './App';
import { AppRouter } from './Router.jsx';
import { Providers } from './Context/index';
import { Client } from './apollo/Client';
/* src/analytics.js */
import Analytics from 'analytics';
import simpleAnalyticsPlugin from 'analytics-plugin-simple-analytics';
// LogRocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import 'bootstrap/dist/css/bootstrap.min.css';

Analytics({
  app: 'awesome-app',
  plugins: [
    // Load simple analytics! 🎉
    simpleAnalyticsPlugin()
  ]
});

LogRocket.init(process.env.REACT_APP_LOGROCKET);
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={Client}>
      <ApolloHooksProvider client={Client}>
        <Providers>
          <AppRouter />
        </Providers>
      </ApolloHooksProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
