import React, { useContext, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Rectangle } from 'recharts';
import { DashboardContainer } from './styles';
import { useRolesByCompanyQuery } from '../../../graphql/container/query/roles';
import Compensation from '../../Icons/Compensation';
import { AppContext } from '../../../Context/App/AppContext';
import Point from '../../Icons/Point';

const BarChartAdmin = ({ bars, format, sortBy }) => {
  const { currentUser } = useContext(AppContext);

  const [activeBar, setActiveBar] = useState();

  const companyId = currentUser?.companyRole[0].to;

  const {
    data: { getAllRolesByCompanyId },
    loading: loadingRoles
  } = useRolesByCompanyQuery(companyId);

  function sortByDescending(data) {
    return data?.sort((a, b) => b[sortBy] - a[sortBy]);
  }

  const dataFilter = getAllRolesByCompanyId?.filter(({name})=> name!== "Unassigned").map(
    ({ name, low, high, totalEmployee }) => ({
      name: name,
      nameDesc: name.length < 11 ? name : `${name.substring(0, 10)}`,
      high: Number(high),
      dashboardhigh: Number(high)-Number(low),
      low: Number(low),
      totalEmployee: Number(totalEmployee)
    })
  );

  const dataDashboard = sortByDescending(dataFilter);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="dashboardTooltip">
          <p className="dashboardTooltip-title">{`${payload[0]?.payload?.name}`}</p>
          {payload.reverse().map(({dataKey, value, color, payload})=>
            <p className='text-capitalize pb-1' key={dataKey} style={{color: '#24292E', fontWeight: 600, margin: 0, paddingBottom: 8}}>
              <span className='me-2 pb-3'>
                <Point color={color}/>
              </span>
              {dataKey.includes('dashboard')? `${dataKey.substr(9)} :${format ? `$ ${payload[dataKey.substr(9)]?.toLocaleString("en-US")}` : payload[dataKey.substr(9)] }` : `${dataKey} : ${format ? `$ ${value.toLocaleString("en-US")}` : value }`}</p>
          )}
        </div>
      );
    }
  
    return null;
  };

  return (
    <DashboardContainer>
      {loadingRoles || !getAllRolesByCompanyId ? (
        <div className="dashboardContainer-loader">
          <Compensation />
          <span className="underLogo">Average Salaries</span>
        </div>
      ) : (
        <div className='dashboardContainer'>
          <BarChart
            width={dataDashboard.length * 85}
            height={210}
            data={dataDashboard}
            barSize={50}
            margin={{
              top: 10,
              right: 5,
              left:  format ? 20 : 5,
              bottom: 10
            }}
            onMouseMove={ bar => setActiveBar(bar.activeLabel)}
            onMouseLeave={ () => setActiveBar(undefined)}
            >
            <CartesianGrid vertical={false} stroke={'#F6F8FA'} />
            <XAxis
              dataKey="nameDesc"
              axisLine={false}
              tickLine={false}
              tickMargin={9}
              />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickMargin={22}
              tickFormatter={value => {
                const formattedValue = format ? `$ ${value / 1000}k` : value ;
                return formattedValue;
                
              }}/>
            <Tooltip cursor={false} content={<CustomTooltip/>} />
            { bars?.map(({dataKey, fill, stackId, radius}) =>
              <Bar 
                dataKey={dataKey}
                stackId={stackId} 
                fill={fill} 
                radius={radius}
                key={dataKey}
                shape={props => <Rectangle {...props} fill={activeBar ? activeBar === props.payload.nameDesc ? fill: '#E0E4EA': fill} />}
              />)
            }
          </BarChart>
        </div>
      )}
    </DashboardContainer>
  );
};

export default BarChartAdmin;
