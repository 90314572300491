import React from 'react';

const Team = ({ color = 'white', width = '17', height = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5562_50015)">
        <path
          d="M6.41602 3.85192C6.41602 2.7066 7.34453 1.77734 8.49059 1.77734C9.6359 1.77734 10.5652 2.70586 10.5652 3.85192C10.5652 4.99723 9.63665 5.92649 8.49059 5.92649C7.34453 5.92499 6.41602 4.99648 6.41602 3.85192Z"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.38867 12.1488C1.38867 11.0035 2.31718 10.0742 3.46324 10.0742C4.60856 10.0742 5.53782 11.0027 5.53782 12.1488C5.53707 13.2941 4.60931 14.2234 3.46324 14.2234C2.31718 14.2234 1.38867 13.2949 1.38867 12.1488V12.1488Z"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4443 12.1488C11.4443 11.0035 12.3728 10.0742 13.5189 10.0742C14.6642 10.0742 15.5935 11.0027 15.5935 12.1488C15.5935 13.2941 14.665 14.2234 13.5189 14.2234C12.3721 14.2234 11.4443 13.2949 11.4443 12.1488V12.1488Z"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.86692 7.94141L4.36426 10.2889"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.6123 5.59375L11.115 7.94119"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4442 12.1484H8.49121"
          stroke={color}
          strokeWidth="1.13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5562_50015">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Team;
