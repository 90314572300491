import styled from 'styled-components';

export const ContentCompanyDetail = styled.div`
  background: #ffffff;
  border-radius: 10px;
  margin: 15px;

  .company-header {
    margin-top: 31px;
    padding: 20px;
    border-bottom: 1px solid #e1e4e8;
    .info,
    .photo {
      vertical-align: middle;
      display: inline-block;
    }

    .photo:hover::after {
      content: 'EDIT';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 70px;
      left: 2px;
      width: 100px;
      padding: 6px 35px;
      font-weight: 600;
      font-size: 12px;
      color: white;
    }

    .photo {
      width: 100px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100px;
        border-radius: 50%;
      }
    }

    .info {
      .heading-h2{
        margin: 0;
      }
      padding-left: 10px;
    }
  }

  .company-data {
    padding: 20px;
    border-bottom: 1px solid #e1e4e8;

    > div,
    h4 {
      display: inline-block;
    }

    .heading-info {
      vertical-align: top;
      width: 20%;
    }

    .section-info {
      vertical-align: top;
      width: 80%;

      .circular-progress {
        vertical-align: text-top;
        width: 25px !important;
        height: 25px !important;
        color: #172c45;
      }

      > div {
        width: 100%;
        p {
          margin-bottom: 12px;
        }
        .question {
          width: 25%;
          text-align: right;
          display: inline-block;
        }
        .answer {
          width: 75%;
          padding-left: 10px;
          display: inline-block;

          .edit {
            cursor: pointer;
            width: 40%;
            display: inline-flex;
            button {
              margin-left: 10px;
              background: #18d399;
              border: none;
              color: black;
            }
          }

          .edit-calendar {
            width: 20%;
          }

          .normal {
            cursor: pointer;
          }

          .select {
            display: inline-block;
            vertical-align: text-top;
            > div:nth-child(1) {
              padding: 2px 32px 5px 10px !important;
            }
          }
        }
      }
    }
  }

  .company-delete {
    padding: 20px;

    button {
      padding: 10px 30px;
      border-radius: 6px;
    }

    div {
      vertical-align: middle;
      display: inline-block;
      margin-left: 50px;
      p {
        margin: 0;
      }
    }
  }
`;
