import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EMPLOYEE_SCHEDULES_LOWER,
  EMPLOYEE_FORM
} from '../../../services/Constants/getDefaults';
import { Select, MenuItem } from '@material-ui/core';
import { Form, Button } from 'react-bootstrap';
import DatePickerModule from '../../../components/DatePickerModal';
import { useEffect } from 'react';
import { transformUTCDateNoTime } from '../../../services/Utils/getUtils';
import { routesAdmin, routesOwner} from '../../../services/Constants/paths';
import { PermissionsEmployee } from '../../../services/Constants/getDefaults';
import { AppContext } from '../../../Context/App/AppContext';

export const Employment = ({
  idEmployee,
  employment,
  employee,
  formatDate,
  clickParent,
  setInfo,
  info,
  edit,
  setEdit,
  updateField,
  handleChangeEmployment,
  formatStringDate,
  teams,
  roles,
  toolTip,
  multipleInfo,
  formEmployment,
  asterix,
  updateTeam,
  QuestionMark,
  QuestionMarkHover,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [iconQuestionMarkRole, setIconQuestionMarkRole] = useState(true);
  const [iconQuestionMarkSalary, setIconQuestionMarkSalary] = useState(true);
  const navigate = useNavigate();
  const { roleUser,} = useContext(AppContext);
  const isOwner = roleUser.includes(PermissionsEmployee.OWNER) ;

  useEffect(() => {
    if (employee?.startDate) {
      setStartDate(new Date(transformUTCDateNoTime(employee?.startDate)));
    }
  }, [employee]);

  function iconQuestionMarkStatus(status, item) {
    if (item.title.includes('Role')) {
      setIconQuestionMarkRole(status);
    } else if (item.title.includes('Salary')) {
      setIconQuestionMarkSalary(status);
    }
  }

  function renderName(item) {
    if (!idEmployee && item.title.includes('Salary')) {
      return formEmployment[item.input]
        ? '$ ' + formEmployment[item.input]?.toLocaleString('en-US')
        : '$ ' + 0;
    }
    if (!idEmployee && formEmployment[item.input]) {
      return formEmployment[item.input];
    }
    if (!idEmployee && item.title.includes('Role')) {
      if (formEmployment[item.field]) {
        let filterRole = roles.find(el => el.id === formEmployment[item.field]);
        return filterRole.name;
      }
    }
    if (idEmployee) {
      if (employee?.[item.table]) {
        if (employee?.[item.table][item.input]) {
          return employee?.[item.table][item.input];
        }
      }
      if (item.input === 'startDate' && employee?.[item.input]) {
        return formatDate(employee?.[item.input]);
      }
      if (item.input === 'salary' && employee?.[item.input]) {
        return '$ ' + employee?.[item.input]?.toLocaleString('en-US');
      }
      if (item.input === 'employmentForm') {
        // return formEmployment[item.input];
        return employee?.[item.input];
      }
      if (item.title.includes('Role')) {
        if (employee?.[item.role]) {
          return employee?.[item.role]?.name;
        }
        return <span className="placeHolder">{item.placeholder}</span>;
      }
      return (
        employee?.[item.input] || (
          <span className="placeHolder">{item.placeholder}</span>
        )
      );
    }
    return <span className="placeHolder">{item.placeholder}</span>;
  }

  return (
    <div className="banking" onClick={clickParent}>
      <div className="heading-info">
        <h4 className="heading-h4">Employment:</h4>
      </div>
      <div className="section-info">
        {employment
          .filter(item => item.input !== 'teamIds')
          .map((item, i) => {
            return (
              <div className="generalDiv" key={i}>
                <div className="question text-bold">
                  <div>
                    {item.title.includes('Role') ? (
                      <div>
                        <p>
                          {item.title}
                          {item.title.includes('Role') && (
                            <span className="asterix">{asterix}</span>
                          )}
                        </p>
                      </div>
                    ) : item.title.includes('Start Date') ||
                      item.title.includes('Salary') ? (
                      <p>
                        {item.title}
                        <span className="asterix">{asterix}</span>
                      </p>
                    ) : (
                      <p>{item.title}</p>
                    )}
                  </div>
                </div>
                <div className="answer text-normal">
                  {item.title === edit ? (
                    item.edit === 'date' ? (
                      <div className="edit-calendar">
                        <DatePickerModule
                          principalClass={'inner-Calendar'}
                          weekendDisabled
                          selected={startDate}
                          minDate={new Date().setFullYear(
                            new Date().getFullYear() - 10
                          )}
                          maxDate={new Date().setMonth(
                            new Date().getMonth() + 24
                          )}
                          placeholderText="MM/DD/YYYY"
                          onChange={value => {
                            if (value) {
                              setStartDate(value);
                              if (!idEmployee) {
                                handleChangeEmployment(
                                  formatStringDate(value),
                                  item.input
                                );
                              } else {
                                setInfo(formatStringDate(value));
                                updateField(item, formatStringDate(value));
                              }
                            } else {
                              setStartDate(new Date());
                            }
                          }}
                        />
                      </div>
                    ) : item.edit === 'list' ? (
                      <div className="asideQuestionClass">
                        <div>
                          <Select
                            id="search-select"
                            labelId="search-select-label"
                            value={
                              idEmployee && item.input === 'teamIds'
                                ? multipleInfo
                                : idEmployee
                                ? info
                                : formEmployment[item.input]
                            }
                            MenuProps={{
                              autoFocus: false,
                              PaperProps: {
                                style: {
                                  maxHeight: 200
                                }
                              }
                            }}
                            displayEmpty
                            multiple={item.input === 'teamIds'}
                            variant="outlined"
                            className="select"
                            onChange={e =>
                              idEmployee
                                ? e.target.value
                                  ? updateField(item, e.target.value)
                                  : null
                                : handleChangeEmployment(
                                    e.target.value,
                                    item.input
                                  )
                            }
                          >
                            {item.title.includes('Dedication') ? (
                              EMPLOYEE_SCHEDULES_LOWER.map((el, i) => (
                                <MenuItem
                                  key={i}
                                  value={el.split('-')[0].toUpperCase()}
                                >
                                  <span className="placeHolder">{el}</span>
                                </MenuItem>
                              ))
                            ) : item.title.includes('Employment Form') ? (
                              EMPLOYEE_FORM.map((el, i) => (
                                <MenuItem key={i} value={el}>
                                  {el}
                                </MenuItem>
                              ))
                            ) : item.title.includes('Role') ? (
                              roles?.map((el, i) => (
                                <MenuItem key={i} value={el.id}>
                                  <span className="placeHolder">{el.name}</span>
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={info}>{info}</MenuItem>
                            )}
                          </Select>
                        </div>
                        {
                          <div>
                            {(item.title.includes('Role') ||
                              item.title.includes('Salary')) && (
                              <div className="questionInside">
                                <QuestionMark />
                                <div
                                  className="textQuestion"
                                  style={{ width: '222px', height: '28px' }}
                                >
                                  {item.title.includes('Role')
                                    ? isOwner ? toolTip(<div style={{ cursor: 'pointer' , display: 'flex' }} onClick={() => navigate(routesOwner.roles())}>Create or edit Roles here</div>) : toolTip('Only owners can edit roles')
                                    : idEmployee ? toolTip(<div style={{ cursor: 'pointer' , display: 'flex' }} onClick={() => navigate(routesAdmin.salary(idEmployee))}>Edit Salary here</div>):  toolTip('This is the starting salary') } 
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    ) : (
                      <div className="edit">
                        <Form.Control
                          value={
                            idEmployee
                              ? info !== item.placeholder
                                ? info
                                : ''
                              : formEmployment[item.input]
                          }
                          type={item.input === 'salary' ? 'number' : 'text'}
                          disabled={idEmployee && item.input === 'salary'}
                          onChange={e => {
                            let value = e.target.value;
                            idEmployee
                              ? setInfo(value)
                              : handleChangeEmployment(value, item.input);
                          }}
                          autoFocus
                        />
                        {idEmployee && item.input !== 'salary' && (
                          <Button
                            className="btn-success"
                            onClick={e => updateField(item, e.target.value)}
                            disabled={
                              info === item.placeholder ||
                              getEmployeeLoading ||
                              updateProfileLoading
                            }
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    )
                  ) : (
                    <div className="normal">
                      <div
                        onClick={() => {
                          setEdit(item.title);
                          setInfo(item.placeholder);
                        }}
                      >
                        {renderName(item)}
                      </div>
                      <div>
                        {(item.title.includes('Role') ||
                          item.title.includes('Salary')) && (
                          <div
                            className="questionInside"
                            onMouseOver={() =>
                              iconQuestionMarkStatus(false, item)
                            }
                            onMouseOut={() =>
                              iconQuestionMarkStatus(true, item)
                            }
                          >
                            {item.title.includes('Role') ? (
                              iconQuestionMarkRole ? (
                                <QuestionMark />
                              ) : (
                                <QuestionMarkHover />
                              )
                            ) : (
                              ''
                            )}
                            {item.title.includes('Salary') ? (
                              iconQuestionMarkSalary ? (
                                <QuestionMark />
                              ) : (
                                <QuestionMarkHover />
                              )
                            ) : (
                              ''
                            )}
                            <div
                              className="textQuestion"
                              style={{ width: '222px', height: '28px' }}
                            >
                              {item.title.includes('Role')
                                ? isOwner ? toolTip(<div style={{ cursor: 'pointer' , display: 'flex' }} onClick={() => navigate(routesOwner.roles())}>Create or edit Roles here</div>) : toolTip('Only owners can edit roles')
                                : idEmployee ? toolTip(<div style={{ cursor: 'pointer' , display: 'flex' }} onClick={() => navigate(routesAdmin.salary(idEmployee))}>Edit Salary here</div>):  toolTip('This is the starting salary') } 
                            </div>
                          </div>
                        )}
                      </div>
                      {itemLoading &&
                        item.title.includes(itemLoading?.title) &&
                        (getEmployeeLoading || updateProfileLoading) && (
                          <div
                            className="questionInside"
                            style={{
                              textAlign: 'center',
                              marginLeft: '10px'
                            }}
                          >
                            <CircularProgress className="circular-progress-employee" />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
