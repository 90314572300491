import { useQuery } from '@apollo/react-hooks';
import {
  GET_ALL_PERMISSIONS,
  GET_EMPLOYEE_PERMISSIONS
} from '../../../queries/groups';


export const useGetAllPermissions = () => {
  return useQuery(GET_ALL_PERMISSIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetEmployeePermissions = () => {
  return useQuery(GET_EMPLOYEE_PERMISSIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

