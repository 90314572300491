import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ErrorLink from '../../../components/Icons/ErrorLink';
import { ContentLinkError } from './styles';

const LinkError = () => {
  const navigate = useNavigate();

  return (
    <ContentLinkError>
      <ErrorLink />
      <p className="heading-h1">Oops!</p>
      <p className="heading-h3">This page doesn't exist or was removed!</p>
      <Button className="btn-success" onClick={() => navigate(-1)}>
        <span>Go Back</span>
      </Button>
    </ContentLinkError>
  );
};

export default LinkError;
