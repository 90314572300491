import styled from 'styled-components';

export const ContentPtoTeam = styled.div`

width: 100%;
height: calc(100% - 66px);

.title-teams {
    margin: 29px;
    margin-bottom: 20px;
    span{
      padding-left: 10px;
      font-weight: 600;
      font-size: 20px;
      size: 20px;
      color: #172C45;
    }
}

.section-scroll{
  overflow-y: auto;
}

.section-activity {
  background: #ffffff;
  margin-top: 0px;
  margin-left: 25px;
  margin-bottom: 20px;
  height: 552px;
  border-radius: 8px;
  contain: content;

  .permission-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;

    .sub-heading {
      font-weight: 600;
      margin: 0;
    }

    > div:nth-child(1) {
      display: inline-block;
    }

    > div:nth-child(2) {
      margin-left: 20px;
      text-align: left;
      display: inline-block;

      .input-group-text {
        background-color: #ffffff;
        border-right: none;
      }

      .form-control {
        border-left: none;
      }

      .search {
        margin-right: 340px;
      }
    }

    > div:nth-child(3) {
      text-align: right;
      width: 100% button {
        display: inline-block;
        padding: 8px 10px;
        border: none;
        line-height: 20px;
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        span {
          color: #24292e;
         }
       }
     }
   }

   

  .teams-table {
    .noData {
      width: 100%;
      text-align: center;
      height: 600px;
      padding: 250px;
  
      svg {
        margin-bottom: 20px;
      }
    }

    table {
      
      tbody {
        td:nth-child(1) {
          width: 30%;
        }
        td:nth-child(2) {
          width: 20%;
        }
        td:nth-child(3) {
          width: 30%;
        }
      }

      .icon-profile {
        img {
          width: 36px;
          border-radius: 50%;
        } 

      .name-description{
        margin-left: 8px;
        color: #24292E;
      }
    }
      .copy-email {
      cursor: pointer;
      }

      .text-description {
        color: #24292E;
      }
  }
}
  .teams-table {
    table {
      tbody {
        td {
          padding-left: 20px;
        }
      }
    }
  }


  .activities-header {
    padding: 15px;
    border-bottom: 1px solid #e1e4e8;

    .sub-heading {
      margin: 0;

      .little-subtitle {
        display: flex !important;
        flex-direction: row;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 10px;

        p {
          color: #6A737D
          line-height: 20px;
          font-size: 14px;
          padding-left: 10px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .pto-buttons {
    display: flex;
    align-items: center;
      
    .btn-danger{
      height: 36px;
      width: 72px;
      margin-right: 10px;
   }
  
     .btn-success {
      height: 36px;
      width: 69px;
     }
  }
}
  .section-pending-activities {
    padding-button: 15px;
    height: 500px;
    overflow-y: auto;

    .content-tabs {
      height: 408px;
      padding: 20px;
      overflow-y: auto;
      & .card-pto-data {
           height: 72%;
           overflow-y: auto;
           overflow-x: hidden;
  
         .loading {
          text-align: center;
          padding: 50px;
          }
       }
  
      & .pto-item {
        display: flex;
        justify-content: space-between;
        width: 100%
        & .pto-icon {
          padding-top: 4px;
          vertical-align: text-top;

         }

         & .pto-date-status {
          min-width: 160px;
          padding: 0 10px;

           & .pto-dates{
            font-weight: 400;
            font-size: 14px;
            color: #586069;
           }
            & .pto-status {
            color: #24292e;
            font-size: 14px;
          }

        }

        .ptobtns- {
          background: #F6F8FA;
          border-radius: 6px;
          height: 32px;
          width: 115px;
          display: flex;
          align-items: center;
          padding: 8px 10px;

            & .icon-status-approved {
              text-align: right;

              & span {
                font-weight: 600;
                margin-left: 5px;
                color: #24292E;
              }

          }

            & .icon-status-declined {
                text-align: right;

                  & span {
                font-weight: 600;
                margin-left: 12px;
                color: #24292E;
              }

            }
          }

          .card-buttons-edit{
            display: none;
          }
          
          .ptobtns-edit {
            background: #F6F8FA;
            border-radius: 6px;
            height: 32px;
            width: 115px;
            display: flex;
            align-items: center;
            padding: 8px 10px;

              & .icon-status-approved {
                text-align: right;

                & span {
                  font-weight: 600;
                  margin-left: 5px;
                  color: #24292E;
                }

            }

              & .icon-status-declined {
                  text-align: right;

                    & span {
                  font-weight: 600;
                  margin-left: 12px;
                  color: #24292E;
                }

              }
            }

          .btn-actions {
            display: flex;
          img{
            margin: 0px 5px;
            cursor: pointer;
          }
        }
        .btn-actions-none{
          display: none ; 
        }
  
        }
  
      }

    .card-info {
      vertical-align: top;
      margin-left: 8px;
      flex-grow: 1;

      p {
        margin: 0;
      }
    }

    .card-buttons {
      flex-grow: 0.1;
      margin: auto;
      text-align: right;

      > button:nth-child(2) {
        margin-left: 3%;
      }
    }

    .card-icon {
      vertical-align: top;
      text-align: center;
      max-width: 36px;
      max-height: 36px;
      margin-left: 8px;
      margin-top: 3px;

      & img {
        border-radius: 50%;
        width: 100%;
      }
    }

    .no-Data {
      width: 100%;
      height: 200px;
      text-align: center;
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        margin-top: 1%;
      }
    }

    .loading {
      text-align: center;
      padding: 30px;
    }
  }
.manager-pto{
  display: flex;
  flex-direction: column;
  color: #24292e;
  align-items: flex-start;
}
.innerDate{
  padding: 1px 5px 0 0;
  
}
.innerManager{
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.section-resume {
  padding: 15px;

  .card-compensation {
    background: #ffffff;
    border-radius: 10px;
    width: 49%;
    display: inline-block;
    text-align: -moz-center;
    text-align: -webkit-center;

    .header {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;

      .sub-heading {
        margin: 0;
        text-align: left;
      }
    }
  }

  .card-roles {
    background: #ffffff;
    border-radius: 10px;
    margin-left: 2%;
    width: 49%;
    display: inline-block;
    text-align: -moz-center;
    text-align: -webkit-center;

    .header {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;

      .sub-heading {
        margin: 0;
        text-align: left;
      }
    }
  }
}

.green-button{
  background: #18D399;
  border-radius: 6px;
  outline:none;
  border: 0px;
  gap: 8px;
  align-items: center;
  padding: 7px 10px;
  margin-top: 27px;
  
 }
.green-button:focus{
  background: #18D399 !important;
}
.green-button:hover{
  background: #18D399 !important;
  border: none !important;
}
.create-employee-span{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}


@media screen and (max-width: 1024px){
  .section-activity{
    margin: 0px;
  }
  .card-buttons {
    visibility: hidden;
  }
  .card-buttons-movil{
    display: flex;
  }
  .btn-danger-movil{
    width: 38px;
    height: 36px
    background-color: #D73A49;
    border-color: #D73A49;
    display: flex;
    align-items: center;
    :hover{
      background-color: #D73A49;
      border-color: #D73A49;
    }
  }
  
  .btn-success-movil{
    width: 38px;
    height: 36px
    background-color: #18D399;
    border-color: #18D399;
    margin-left: 10px;
    display: flex;
    align-items: center;
    :hover{
      background-color: #18D399;
      border-color: #18D399;
    }
  }
 
}

@media screen and (min-width: 1024px){
  .card-buttons-movil{
    visibility: hidden;
    width: 0px;
  }
}



    `;
