import React from 'react';

const QuestionMarkHover = () => {
  return (

    <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >

    <path d="M9 16C5.13367 16 2 12.8663 2 9C2 5.13367 5.13367 2 9 2C12.8663 2 16 5.13367 16 9C16 12.8663 12.8663 16 9 16Z" fill="#24292E" stroke="#24292E" 
    strokeWidth="1.13" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    />

    <path d="M9 9.93775V9.75025C9 9.1375 9.37875 8.80525 9.75825 8.55025C10.1287 8.3005 10.5 7.975 10.5 7.375C10.5 6.54625 9.82875 5.875 9 5.875C8.17125 5.875 7.5 6.54625 7.5 7.375" 
    stroke="white" 
    strokeWidth="1.13" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    />

    <path 
    d="M8.99802 11.5C8.72203 11.5 8.49803 11.724 8.50003 12C8.50003 12.276 8.72403 12.5 9.00002 12.5C9.27602 12.5 9.50002 12.276 9.50002 12C9.50002 11.724 9.27602 11.5 8.99802 11.5Z" 
    fill="white"/>

    </svg>
  );
};

export default QuestionMarkHover;
