import React from 'react';

const Edit = ({ color = '#24292E' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.239 3.56325L12.1815 2.50575C11.8882 2.2125 11.409 2.2125 11.1165 2.50575L2.99023 10.6313V12.7388H5.09773L13.2232 4.61325C13.5157 4.32 13.5157 3.84525 13.2232 3.552L13.239 3.56325Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 15.75H15.75"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9025 5.9625L9.78748 3.8475"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
