import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';

import { InputNumber } from '../../../components/Input';
import { useGetPtoRulesByCompanyQuery } from '../../../graphql/container/query/timeOffRules';
import { ContentPtoHolidays } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import {
  CREATE_HOLIDAY_BY_COMPANY,
  REMOVE_HOLIDAY_BY_COMPANY,
  UPDATE_HOLIDAY_BY_ID,
  UPDATE_PTO_RULES_MUTATION
} from '../../../graphql/queries/timeOffRules';
import { CircularProgress } from '@material-ui/core';

import TableHolidays from './TableHolidays';
import Trash from '../../../components/Icons/Trash';
import { ModalAlert, ModalEditHoliday } from '../../../components/Modals';
import DatePickerModule from '../../../components/DatePickerModal';

const initialsColumns = [
  {
    name: 'Description',
    key: 'description',
    active: true
  },
  {
    name: 'Date',
    key: 'date',
    active: true
  },
  {
    name: 'Actions',
    key: 'action',
    active: true
    // width: '10%'
  }
];

const initialPtoRules = {
  minAdvanceDays: 0,
  initialPto: 0,
  rollOverDays: 0,
  accruedDays: 0
};

const PtoHolidays = () => {
  const isMounted = useRef(false);
  const { currentUser, setDontNavigate } = useContext(AppContext);
  const [ptoRules, setPtoRules] = useState(initialPtoRules);
  const [holidays, setHolidays] = useState();
  const [description, setDescription] = useState('');
  const [date, setDate] = useState();
  const [columnsTable] = useState(initialsColumns);
  const [selected, setSelected] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [holidayToEdit, setHolidayToEdit] = useState({});
  const companyId = currentUser?.companyRole[0].to;
  const holidayExist = date
    ? holidays
        ?.map(el =>
          new Date(el.date)
            .toString()
            .slice(0, 15)
            .includes(date.toString().slice(0, 15))
        )
        .includes(true)
    : false;

  const {
    data: { getPtoRulesByCompany },
    loading: loadingPtoRules,
    refetch: refetchPtoRules
  } = useGetPtoRulesByCompanyQuery(companyId);

  const [updatePtoRules, { loading: updatePtoLoading }] = useMutation(
    UPDATE_PTO_RULES_MUTATION
  );

  const [createHoliday, { loading: createHolidayLoading }] = useMutation(
    CREATE_HOLIDAY_BY_COMPANY
  );

  const [removeHoliday, { loading: removeHolidayLoading }] = useMutation(
    REMOVE_HOLIDAY_BY_COMPANY
  );

  const [updateHoliday, { loading: updateHolidayLoading }] = useMutation(
    UPDATE_HOLIDAY_BY_ID
  );

  useEffect(() => {
    if (
      loadingTable &&
      !createHolidayLoading &&
      !removeHolidayLoading &&
      !updateHolidayLoading
    )
      refetchPtoRules({ companyId });
    // eslint-disable-next-line
  }, [createHolidayLoading, removeHolidayLoading, updateHolidayLoading]);

  useEffect(() => {
    if (getPtoRulesByCompany) {
      const { timeOffRulles, holidays: _holidays } = getPtoRulesByCompany;
      isMounted.current = true;
      setPtoRules({
        minAdvanceDays: parseFloat(timeOffRulles.daysAdvanceToRequestTimeOff),
        initialPto: parseFloat(timeOffRulles.initialDaysOff),
        rollOverDays: parseFloat(timeOffRulles.rollOverDaysPerYear),
        accruedDays: parseFloat(timeOffRulles.daysPerMonth)
      });
      setHolidays(_holidays);
      setLoadingTable(false);
    }
  }, [getPtoRulesByCompany]);

  useEffect(() => {
    if (isMounted.current) {
      if (!updatePtoLoading) {
        refetchPtoRules({ companyId });
      }
    }
    // eslint-disable-next-line
  }, [updatePtoLoading]);

  const onChangeRulesPto = (value, attribute) => {
    setPtoRules(prev => {
      const newValues = { ...prev, [`${attribute}`]: parseFloat(value) };
      return { ...newValues };
    });
  };

  const submitNewPtoRules = () => {
    setDontNavigate(true);
    updatePtoRules({
      variables: {
        id: getPtoRulesByCompany?.timeOffRulles?.id,
        ...ptoRules
      }
    });
    setDescription('');
    setDate();
  };

  const submitNewHoliday = () => {
    setDontNavigate(true);
    setLoadingTable(true);
    createHoliday({
      variables: {
        companyId,
        description,
        date
      }
    });
    setDescription('');
    setDate();
  };

  const deleteHoliday = () => {
    setOpenModal(false);
    removeHoliday({
      variables: {
        list: selected
      }
    });
    setLoadingTable(true);
    setSelected([]);
    setDescription('');
    setDate();
  };

  const editHoliday = holiday => {
    if (holiday.date !== null && holiday.date !== 'undefined') {
      updateHoliday({
        variables: {
          id: holiday.id,
          date: holiday.date,
          description: holiday.name
        }
      });
      setOpenModalEdit(false);
      setLoadingTable(true);
      setHolidayToEdit({});
      setSelected([]);
      setDescription('');
      setDate();
    } else {
      setOpenModalEdit(false);
    }
  };

  if (loadingPtoRules && !isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <ContentPtoHolidays>
      <div className="pto-holidays-header">
        <h3 className="sub-heading">PTO Rules</h3>
      </div>
      <div className="pto-holidays-rules">
        {loadingPtoRules || updatePtoLoading ? (
          <div className="loading-rules">
            <CircularProgress className="circular-progress" />
          </div>
        ) : (
          <>
            <div className="rule">
              <div>
                <InputNumber
                  id="input-min-advance-days"
                  min={0}
                  max={50}
                  value={ptoRules.minAdvanceDays}
                  onChange={e =>
                    onChangeRulesPto(e.target.value, 'minAdvanceDays')
                  }
                />
              </div>
              <div>
                <p className="heading-h4">Days Before PTO Request </p>
                <span className="text-normal">
                  This is the minimum number of days before any time off
                  request.
                </span>
              </div>
            </div>
            <div className="rule">
              <div>
                <InputNumber
                  id="input-initial-pto"
                  min={0}
                  max={50}
                  value={ptoRules.initialPto}
                  onChange={e => onChangeRulesPto(e.target.value, 'initialPto')}
                />
              </div>
              <div>
                <p className="heading-h4">Total PTO in Year</p>
                <span className="text-normal">Yearly PTO for the company.</span>
              </div>
            </div>
            <div className="rule">
              <div>
                <InputNumber
                  id="input-roll-over-days"
                  min={0}
                  max={50}
                  value={ptoRules.rollOverDays}
                  onChange={e =>
                    onChangeRulesPto(e.target.value, 'rollOverDays')
                  }
                />
              </div>
              <div>
                <p className="heading-h4">Roll Over Days</p>
                <span className="text-normal">
                  If unused, days that can be rolled over from one year to the
                  next.
                </span>
              </div>
            </div>
            {/* <div className="rule">
              <div>
                <p className="heading-h4">Accrued Days per Month</p>
                <span className="text-normal">
                  This is the number of PTO a team member accrues per month.
                </span>
              </div>
              <div>
                <InputNumber
                  id="input-accrued-days"
                  min={0}
                  max={50}
                  value={ptoRules.accruedDays}
                  onChange={e =>
                    onChangeRulesPto(e.target.value, 'accruedDays')
                  }
                />
              </div>
            </div> */}
          </>
        )}

        <div className="save-rules">
          <Button className="btn-success" onClick={() => submitNewPtoRules()}>
            Save
          </Button>
        </div>
      </div>
      <div className="pto-holidays-header-middle">
        <h3 className="sub-heading">Company Holidays</h3>
      </div>
      <div className="pto-holidays-company-values">
        <div>
          <p className="pto-holidays-company-subheadears">Description</p>
          <Form.Control
            value={description}
            type="text"
            placeholder={`Example: New Year's Day`}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div>
          <p className="pto-holidays-company-subheadears">Date</p>
          <DatePickerModule
            principalClass={'inner-Calendar form-control'}
            weekendDisabled
            onChange={value => setDate(value)}
            selected={date}
            placeholderText={`MM/DD/YYYY`}
          />
        </div>

        <div>
          <Button
            className="btn-success"
            onClick={() => submitNewHoliday()}
            disabled={!date || !description.length || holidayExist}
          >
            Add
          </Button>
        </div>
        <div>
          <Button
            className="btn-danger trash"
            hidden={!selected.length}
            onClick={() => setOpenModal(true)}
          >
            <Trash color="white" />
            <span>{'Delete'}</span>
          </Button>
          {/* <ButtonFilter
            //It's necessary copy deep the data for that filter of the fields works correctly
            data={JSON.parse(JSON.stringify(columnsTable))}
            setData={setColumnsTable}
          /> */}
        </div>
      </div>
      <div className="info-holidays">
        <TableHolidays
          dataSource={holidays}
          loading={loadingTable}
          columns={columnsTable}
          selected={selected}
          setSelected={setSelected}
          onClickEdit={item => {
            setHolidayToEdit(item);
            setOpenModalEdit(true);
          }}
        />
      </div>
      <ModalAlert
        show={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={deleteHoliday}
        confirmLabel="Delete holiday"
        content={
          <div className="content" style={{ padding: '0 6%' }}>
            <p className="text-normal">
              Are you sure you want to delete the next holidays?:
            </p>
            <ul>
              {selected?.map(item => {
                return (
                  <li key={`li-${item.id}`} className="text-normal">
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
        }
        centered
      />
      <ModalEditHoliday
        holiday={holidayToEdit}
        show={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
        onConfirm={res => editHoliday(res)}
      />
    </ContentPtoHolidays>
  );
};

export default PtoHolidays;
