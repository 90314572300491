import gql from 'graphql-tag';

export const GET_TEAMS_BY_COMPANY_ID_QUERY = gql`
  query GET_TEAMS_BY_COMPANY_ID_QUERY($companyId: String!) {
    getAllTeamsByCompanyIdInfoTable(companyId: $companyId) {
      id
      name
      employees
      manager
    }
  }
`;

export const GET_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE = gql`
  query GET_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE(
    $companyId: String!
    $idEmployee: String!
  ) {
    getAllTeamsByCompanyByEmployee(
      companyId: $companyId
      idEmployee: $idEmployee
    ) {
      idEmployee
      nameEmployee
      emailEmployee
      isManager
      idTeam
      nameTeam
      managerName
      photoManager
    }
  }
`;

export const GET_EMPLOYEES_BY_TEAMS_AND_COMPANY = gql`
  query GET_EMPLOYEES_BY_TEAMS_AND_COMPANY(
    $companyId: String!
    $idTeam: String!
  ) {
    getAllEmployeeByTeams(companyId: $companyId, idTeam: $idTeam) {
      idTeam
      nameTeam
      nameEmployee
      emailEmployee
      isManager
      role
      photo
    }
  }
`;

export const GET_MANAGER_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE = gql`
  query GET_MANAGER_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE(
    $companyId: String!
    $idEmployee: String!
  ) {
    getAllManagerByCompanyByEmployeeByTeams(
      companyId: $companyId
      idEmployee: $idEmployee
    ) {
      emailEmployee
    }
  }
`;

export const GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY = gql`
  query GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY(
    $companyId: String!
  ) {
    getAllEmailsOwnerAndAdminByCompany(companyId: $companyId) {
      emailEmployee
    }
  }
`;

export const GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY_FOR_ROLE = gql`
  query GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY_FOR_ROLE(
    $companyId: String!
  ) {
    getAllEmailsOwnerAndAdminByCompany(companyId: $companyId) {
      emailEmployee
      role
    }
  }
`;

export const GET_TEAMS_BY_MANAGER_ID_QUERY = gql`
  query GET_TEAMS_BY_MANAGER_ID_QUERY(
    $managerId: String!
    $companyId: String!
  ) {
    getAllTeamsByManager(managerId: $managerId, companyId: $companyId) {
      id
      name
      employees
      manager
      managerId
      cantPto
    }
  }
`;

export const GET_PTOS_MANAGER = gql`
  query GET_PTOS_MANAGER($managerId: String!, $companyId: String!) {
    getCantPtosManager(managerId: $managerId, companyId: $companyId)
  }
`;

export const GET_TEAM_DATA_BY_ID = gql`
  query GET_TEAM_DATA_BY_ID($teamId: String!) {
    getTeamDataById(teamId: $teamId) {
      id
      name
      members {
        id
        employeeId
      }
      manager {
        id
        employeeId
      }
    }
  }
`;
