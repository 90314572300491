import styled from 'styled-components';

export const SearchEmployeeContainer = styled.div`
  > div {
    display: inline-block;
    vertical-align: middle;
  }
  .arrowLeft {
    position: absolute;
    left: 25px;
    top: 20px;
    cursor: pointer;
  }

  .dropdown {
    button:focus {
      box-shadow: 0 0 0 0 !important;
    }

    .dropdown-toggle {
      width: 100%;
      background-color: transparent !important;
      border: 0;
      padding: 0;
    }

    .dropdown-toggle:after {
      content: none;
    }

    .search-employees {
      span {
        background-color: #ffffff;
        border: none;
      }
      input {
        border: none;
        padding-left: 0px;
      }
    }

    .dropdown-menu {
      width: 100%;
      min-width: 375px;
      .item-employee {
        div {
          display: inline-block;
          vertical-align: top;
        }
        > div:nth-child(1) {
          margin-left: 8px;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }
        > div:nth-child(2) {
          margin-left: 8px;
          p {
            margin: 0;
          }
        }
      }

      .loading-data {
        text-align: center;
        padding: 50px;
      }

      .no-Data {
        text-align: center;
        padding: 20px;
      }
      .FormControl {
        padding-left: 0px;
      }
    }
  }

  > div:nth-child(1) {
    width: 100%;
  }

  .info-user {
    width: 30%;
    text-align: right;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .input-search {
    padding: 8px;
    height: 36px;
    border-left: none;
    padding-left: 0;
  }

  .input-group-text {
    background-color: white !important;

    padding-right: 7px;
    padding-left: 11px;
  }

  .form-control {
    padding-left: 0px;
    transition: none;
  }

  .form-control:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
`;
