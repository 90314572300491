import React from 'react';
import { useNavigate } from 'react-router-dom';
import Vector from '../Icons/Vector';
import { BreadcrumbsContainer } from './styles';

const Breadcrumbs = ({ routes, subtitle, section, home }) => {
  const navigate = useNavigate();
  return (
    <BreadcrumbsContainer>
      {!home && (
        <p className="title-style-bread">
          <button
            style={{ border: 'none', background: 'transparent' }}
            onClick={() => navigate(routes)}
          >
            <Vector size="25" />
            {section}
          </button>
          <span className="slashSeparator">{'/'}</span>
          <span className="text-bold">{subtitle}</span>
        </p>
      )}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
