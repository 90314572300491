import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Oops from '../../../components/Icons/Oops';
import { ContentAnyError } from './styles';

const AnyError = () => {
  const navigate = useNavigate();
  return (
    <ContentAnyError>
      <Oops />
      <p className="heading-h1">Oops!</p>
      <p className="heading-h3">Something went wrong</p>
      <Button className="btn-success" onClick={() => navigate(-1)}>
        <span>Go Back</span>
      </Button>
    </ContentAnyError>
  );
};

export default AnyError;
