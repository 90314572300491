import styled from 'styled-components';

export const ContentFiles = styled.div`
  margin: 30px 15px 15px 15px;
  border-radius: 10px;
  background: #ffffff;
  padding-bottom: 5px;
  height: -webkit-fill-available;

  .header-files {
    padding: 10px 15px 10px 25px;

    .sub-heading {
      margin: 0;
    }

    > div:nth-child(1) {
      display: inline-block;
      width: 70%;
    }

    > div:nth-child(2) {
      text-align: right;
      display: inline-block;
      width: 30%;

      .trash {
        margin-right: 5px;
      }

      button {
        display: inline-block;
        margin-left: 5px;
        border: none;
        height: 35px;
        span {
          margin-left: 5px;
          color: black;
        }
      }
    }

    .btn-danger {
      background-color: #ff5656;
    }
  }

  .noData {
    width: 100%;
    text-align: center;
    height: 600px;
    padding: 250px;

    svg {
      margin-bottom: 20px;
    }
  }
`;
