import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  QUERY_URL_FILE_BY_KEY,
  GET_SIGNED_URL_TO_PUT_QUERY,
  GET_DOC_BY_EMPLOYEE_ID_QUERY,
  GET_SIGNED_URL_TO_GET_QUERY
} from '../../../queries/files/index';

export const useUrlFileLazyQuery = (id, name, employeeId) =>
  useLazyQuery(QUERY_URL_FILE_BY_KEY, {
    variables: { id, name, employeeId }
  });

export const useGetSignedUrlToPutS3Query = (
  id,
  name,
  contentType,
  section,
  acl='public-read'
) =>
  useLazyQuery(GET_SIGNED_URL_TO_PUT_QUERY, {
    variables: { id, name, contentType, section, acl }
  });

export const useGetDocByEmployeeIdQuery = employeeId => {
  return useQuery(GET_DOC_BY_EMPLOYEE_ID_QUERY, {
    variables: { employeeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetSignedUrlToGetS3Query = (url, filename) =>
  useLazyQuery(GET_SIGNED_URL_TO_GET_QUERY, {
    variables: { url, filename },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });
