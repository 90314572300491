import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  EMPLOYEES_QUERY,
  EMPLOYEE_QUERY,
  ALL_COUNT_EMPLOYEES_ROLES,
  ALL_COUNT_EMPLOYEES,
  ALL_EMPLOYEES_QUERY,
  LIST_EMPLOYEE_PAGE,
  PROFILE_PAGE,
  EMPLOYEE_PROFILE_PTO,
  EMPLOYEE_EMAIL_QUERY,
  EMPLOYEES_QUERY_CUSTOM,
  GET_PTO_EMPLOYEE,
  GET_EVENTS,
  GET_EMPLOYEES_BY_COMPANY_QUERY,
  GET_RECENT_EMPLOYEES_QUERY,
  GET_EMPLOYEE,
  GET_NAME_EMPLOYEE
} from '../../../queries/employees';
const getWhereEmployees = (companyId, option) => {
  const { name, status, rolId } = option;
  const arrayName = name.split(' ');
  let firstName, lastName;
  if (arrayName.length > 1 && arrayName[0] !== '') {
    firstName = arrayName[0];
    lastName = arrayName[1];
  } else {
    firstName = option.name;
    lastName = '-1';
  }

  let statusFilter = { status: { equals: 'ACTIVE' } };
  if (!status) {
    statusFilter = {
      status: { in: ['INACTIVE', 'SEPARATED'] }
    };
  }
  return {
    AND: [
      { company: { id: { equals: companyId } } },
      { employeeRole: { id: { contains: rolId } } },
      statusFilter,
      {
        OR: [
          {
            OR: [
              { firstName: { contains: name } },
              { lastName: { contains: name } }
            ]
          },
          {
            AND: [
              { firstName: { contains: firstName } },
              { lastName: { contains: lastName } }
            ]
          }
        ]
      }
    ]
  };
};

export const useEmployeeCompanyQuery = (companyId, option) => {
  const where = getWhereEmployees(companyId, option);
  const { skip, limit } = option;
  return useQuery(EMPLOYEES_QUERY, {
    variables: {
      where,
      skip,
      limit
    }
  });
};

export const useEmployeeCustomCompanyQuery = (companyId, option) => {
  const where = getWhereEmployees(companyId, option);
  const { skip, limit } = option;
  return useQuery(EMPLOYEES_QUERY_CUSTOM, {
    variables: {
      where,
      skip,
      limit
    }
  });
};

export const useEmployeeCustomCompanyLazyQuery = (companyId, option) => {
  const where = getWhereEmployees(companyId, option);
  const { skip, limit } = option;
  return useLazyQuery(EMPLOYEES_QUERY_CUSTOM, {
    variables: {
      where,
      skip,
      limit
    }
  });
};

export const readCacheEmployeeCompanyQuery = (companyId, option) => {
  const where = getWhereEmployees(companyId, option);
  const { skip, limit } = option;
  return {
    query: EMPLOYEES_QUERY_CUSTOM,
    variables: {
      where,
      skip,
      limit
    }
  };
};
export const useEmployeeCompanyLazyQuery = (companyId, option) => {
  const where = getWhereEmployees(companyId, option);
  const { skip, limit } = option;
  return useLazyQuery(EMPLOYEES_QUERY, {
    variables: {
      where,
      skip,
      limit
    }
  });
};

export const useEmployeeQuery = employeeId =>
  useQuery(EMPLOYEE_QUERY, {
    variables: {
      id: employeeId
    }
  });

export const useEmployeeLazyQuery = employeeId =>
  useLazyQuery(EMPLOYEE_QUERY, {
    variables: {
      id: employeeId
    }
  });

export const useEmployeeEmailQuery = email =>
  useQuery(EMPLOYEE_EMAIL_QUERY, {
    variables: { username: email }
  });

export const useEmployeeEmailLazyQuery = (email, fetchPolicy = 'cache-first') =>
  useLazyQuery(EMPLOYEE_EMAIL_QUERY, {
    variables: { username: email },
    fetchPolicy
  });

export const useCountEmployeesRolesLazyQuery = id =>
  useLazyQuery(ALL_COUNT_EMPLOYEES_ROLES, {
    variables: { id: id }
  });

export const useCountEmployeesRolesQuery = id =>
  useQuery(ALL_COUNT_EMPLOYEES_ROLES, {
    variables: { id: id }
  });

export const useCountEmployeesQuery = (id, name, rolId, checked) =>
  useQuery(ALL_COUNT_EMPLOYEES, {
    variables: { id: id, name: name, rolId: rolId, status: checked }
  });

export const useGetAllEmployeesQuery = (companyId, search) => {
  return useQuery(ALL_EMPLOYEES_QUERY, {
    variables: { companyId, search },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useListEmployeesPageQuery = (
  companyId,
  name,
  rolId,
  checked,
  skip,
  limit
) => {
  return useQuery(LIST_EMPLOYEE_PAGE, {
    variables: {
      id: companyId,
      name,
      rolId,
      status: checked,
      skip,
      limit
    }
  });
};

export const useProfileAndAbscenceQuery = (employeeId, employeeEmail) =>
  useQuery(PROFILE_PAGE, {
    variables: { id: employeeId, username: employeeEmail }
  });

export const useMeProfilePTOQuery = (id, username) => {
  return useQuery(EMPLOYEE_PROFILE_PTO, {
    variables: { id, username }
  });
};

export const useGetPTOEmployeeLazyQuery = (id, username) => {
  return useLazyQuery(GET_PTO_EMPLOYEE, {
    variables: { id, username }
  });
};

export const useGetPTOEmployeeQuery = (id, username) => {
  return useQuery(GET_PTO_EMPLOYEE, {
    variables: { id, username }
  });
};

export const useEventsQuery = date => {
  return useQuery(GET_EVENTS, {
    variables: { date },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetEmployeesByCompanyQuery = (
  companyId,
  limit,
  offset,
  search,
  sort,
  orderField,
  offSeparated = false,
  filterOwners = true
) => {
  return useQuery(GET_EMPLOYEES_BY_COMPANY_QUERY, {
    variables: {
      companyId,
      limit,
      offset,
      search,
      sort,
      orderField,
      offSeparated,
      filterOwners
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetNameEmployee = employeeId =>
  useQuery(GET_NAME_EMPLOYEE, {
    variables: { employeeId: employeeId }
  });

export const useGetEmployeesSortByCompanyQuery = (
  companyId,
  //limit,
  offset,
  orderField,
  sort,
  offSeparated = false
) => {
  return useQuery(GET_EMPLOYEES_BY_COMPANY_QUERY, {
    variables: {
      companyId,
      offset,
      orderField,
      sort,
      offSeparated
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
export const useGetRecentEmployeesQuery = companyId => {
  return useQuery(GET_RECENT_EMPLOYEES_QUERY, {
    variables: { companyId }
  });
};

export const useGetEmployeeLazyQuery = employeeId => {
  return useLazyQuery(GET_EMPLOYEE, {
    variables: { employeeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetEmployeeByIdQuery = employeeId => {
  return useQuery(GET_EMPLOYEE, {
    variables: { employeeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
