import gql from 'graphql-tag';

// const deleteDocInput = gql`
//   input deleteDocInput {
//     id: String!
//     url: String!
//     name: String
//   }
// `;

export const ADD_DOCUMENT_TO_EMPLOYEE_MUTATION = gql`
  mutation ADD_DOCUMENT_TO_EMPLOYEE_MUTATION(
    $url: String!
    $name: String!
    $employee: ID!
    $type: Document_type!
    $source: Document_source!
    $company: ID!
  ) {
    createOneDocumentsSigned(
      url: $url
      name: $name
      type: $type
      source: $source
      employee: $employee
      company: $company
    ) {
      id
      name
      url
      type
      source
      signedOn
      createdAt
      createdBy {
        name
      }
      employee {
        company {
          id
        }
      }
    }
  }
`;
export const DELETE_DOCUMENT_SIGNED_MUTATION = gql`
  mutation DELETE_DOCUMENT_SIGNED_MUTATION($id: String!) {
    deleteOneDocumentsSigned(where: { id: $id }) {
      id
    }
  }
`;

export const ADD_DOCUMENT_MUTATION = gql`
  mutation Mutation(
    $employeeId: String!
    $createdBy: String!
    $url: String!
    $source: String
    $name: String!
  ) {
    createDocument(
      createDocInput: {
        employeeId: $employeeId
        createdBy: $createdBy
        url: $url
        source: $source
        name: $name
      }
    )
  }
`;

export const DELETE_DOCUMENTS_MUTATION = gql`
  mutation DELETE_DOCUMENTS_MUTATION($list: [deleteDocInput!]!) {
    deleteDocuments(list: $list)
  }
`;
