import styled from 'styled-components';

export const ContentHome = styled.div`
  width: 100%;
  height: calc(100% - 66px);

  .section-header-activity{
    margin-top: 85px;
  }
  
  .section-new-Employee{
    background: #ffffff;
    margin-top: 20px;
    margin-left: 25px;
    height: 200px;
    border-radius: 8px;
    contain: content;

    .content-tabs {
      height: 150px;
      padding: 0px 20px;
    }

    .activities-header {
      padding: 15px 20px;
      border-bottom: 1px solid #e1e4e8;
      margin-bottom: 20px;

      .sub-heading {
        margin: 0;
      }
    }

    .new-Employee{
      overflow-y: auto;
      height: 110px

      & .pto-item {
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        border-radius: 10px;
        height: 55px;

        .pto-icon {
          padding-top: 4px;
          vertical-align: text-top;
        }

        .card-icon-request-newEmployee {
          padding-top: 4px;
          vertical-align: top;
          text-align: center;
          max-width: 36px;
          max-height: 36px;
          margin-left: 8px;

          & img {
            border-radius: 50%;
            width: 100%;
          }
        }

        .card-info {
          vertical-align: top;
          margin-left: 8px;
          flex-grow: 1;

          p {
            margin: 0;
          }
        }
      }

      .pto-item:hover {
        background: #d1dff0;
      }
    }
  }

  .section-activity {
    background: #ffffff;
    margin-top: 20px;
    margin-left: 25px;
    height: 552px;
    border-radius: 8px;
    contain: content;
 
    .activities-header {
      padding: 17px 20px;
      border-bottom: 1px solid #e1e4e8;

      .sub-heading {
        margin: 0;
      }
    }

    .section-pending-activities {
      height: 700px;

      .content-tabs {
        height: 440px;
        padding: 20px;
        overflow-y: auto;

        & .card-pto-data {
          height: 72%;
          overflow-y: auto;
    
          .loading {
            text-align: center;
            padding: 50px;
          }
        }
    
        & .pto-item {
          display: flex;
          justify-content: space-between;
          width: 100%;

          & .pto-icon-request {
            padding-top: 4px;
            vertical-align: text-top;
          }

          & .pto-date-status {
            min-width: 160px;
            padding: 0 10px;

            & .pto-dates{
              font-weight: 400;
              font-size: 14px;
              color: #586069;
            }
            & .pto-status {
              color: #24292e;
              font-size: 14px;
            }
          }
    
          .ptobtns- {
            background: #F6F8FA;
            border-radius: 6px;
            height: 32px;
            width: 115px;
            display: flex;
            align-items: center;
            padding: 8px 10px;

            & .icon-status-approved {
              text-align: right;

              & span {
                font-weight: 600;
                margin-left: 12px;
                color: #24292E;
              }
            }

            & .icon-status-declined {
              text-align: right;

              & span {
                font-weight: 600;
                margin-left: 12px;
                color: #24292E;
              }
            }
          }
          .card-buttons-edit{
            display: none;
          }

          .ptobtns-edit {
            background: #F6F8FA;
            border-radius: 6px;
            height: 32px;
            width: 115px;
            display: flex;
            align-items: center;
            padding: 8px 10px;

            & .icon-status-approved {
              text-align: right;

              & span {
                font-weight: 600;
                margin-left: 12px;
                color: #24292E;
              }
            }

            & .icon-status-declined {
              text-align: right;

              & span {
                font-weight: 600;
                margin-left: 12px;
                color: #24292E;
              }
            }
          }
  
          .btn-actions {
            display: flex;
            img{
              margin: 0px 5px;
              cursor:pointer;
            }
          }

          .btn-actions-none{
            display: none ; 
          }
        }
      }


      .pto-icon {
        padding-top: 4px;
        vertical-align: text-top;
      }

      .card-info {
        vertical-align: top;
        margin-left: 8px;
        flex-grow: 1;

        p {
          margin: 0;
        }
      }

      .card-buttons {
        display: flex;
        > button:nth-child(2) {
          margin-left: 3%;
        }
      }

      .card-icon {
        padding-top: 14px;
        vertical-align: top;
        text-align: center;
        max-width: 36px;
        max-height: 36px;
        margin-left: 8px;

        & img {
          border-radius: 50%;
          width: 100%;
        }
      }


      .card-icon-request {
        padding-top: 4px;
        vertical-align: top;
        text-align: center;
        max-width: 36px;
        max-height: 36px;
        margin-left: 8px;

        & img {
          border-radius: 50%;
          width: 100%;
        }
      }

      .card-icon-request-newEmployee {
        vertical-align: top;
        text-align: center;
        max-width: 36px;
        max-height: 36px;
        margin-left: 8px;

        & img {
          border-radius: 50%;
          width: 100%;
        }
      }

      .no-Data {
        width: 100%;
        height: 200px;
        text-align: center;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          margin-top: 1%;
        }
      }

      .loading {
        text-align: center;
        padding: 30px;
      }
    }
  }
  
  .innerManager{
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  .section-resume {
    padding: 15px;

    .card-compensation {
      background: #ffffff;
      border-radius: 10px;
      width: 49%;
      display: inline-block;
      text-align: -moz-center;
      text-align: -webkit-center;

      .header {
        padding: 15px;
        border-bottom: 1px solid #e1e4e8;

        .sub-heading {
          margin: 0;
          text-align: left;
        }
      }
    }

    .card-roles {
      background: #ffffff;
      border-radius: 10px;
      margin-left: 2%;
      width: 49%;
      display: inline-block;
      text-align: -moz-center;
      text-align: -webkit-center;

      .header {
        padding: 15px;
        border-bottom: 1px solid #e1e4e8;

        .sub-heading {
          margin: 0;
          text-align: left;
        }
      }
    }
  }
  
  .green-button{
    background: #18D399;
    border-radius: 6px;
    outline:none;
    border: 0px;
    gap: 8px;
    align-items: center;
    padding: 7px 10px;
    margin-top: 27px;  
  }
  .green-button:focus{
    background: #18D399 !important;
  }
  .green-button:hover{
    background: #18D399 !important;
    border: none !important;
  }
  .create-employee-span{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  

  @media screen and (max-width: 1024px){
    .section-header-activity{
      margin-top: 17px;
    }
    .section-new-Employee{
      margin: 0px 0px 17px 0px;
    }  
    .section-activity{
      margin: 0px;
    }
    .card-buttons {
      display: none !important;
    }
    .card-buttons-movil{
      display: flex;
    }
    .btn-danger-movil{
      width: 38px;
      height: 36px
      background-color: #D73A49;
      border-color: #D73A49;
      display: flex;
      align-items: center;
      :hover{
        background-color: #D73A49;
        border-color: #D73A49;
      }
    }
    
    .btn-success-movil{
      width: 38px;
      height: 36px
      background-color: #18D399;
      border-color: #18D399;
      margin-left: 10px;
      display: flex;
      align-items: center;
      :hover{
        background-color: #18D399;
        border-color: #18D399;
      }
    }
   
  }

  @media screen and (min-width: 1024px){
    .card-buttons-movil{
      visibility: hidden;
      width: 0px;
    }
  }

 

`;
