import React from 'react';

const NotTeams = () => {
  return (
            <svg 
            width="47" 
            height="34" 
            viewBox="0 0 47 34" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            >

            <path 
            d="M2.16699 31.9318C2.16699 27.2384 6.00699 23.3984 10.7003 23.3984H19.2336C23.927 23.3984 27.767 27.2384 27.767 31.9318" 
            stroke="#24292E" 
            strokeWidth="3" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            />
          
            <path 
            d="M20.3003 4.1998C23.2869 7.18646 23.2869 11.8798 20.3003 14.6531C17.3136 17.4264 12.6203 17.6398 9.84696 14.6531C7.07363 11.6665 6.8603 7.18646 9.63363 4.1998C12.407 1.21314 17.3136 1.42647 20.3003 4.1998Z" 
            stroke="#24292E" 
            strokeWidth="3" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            />

            <path 
            d="M32.0332 21.2656H38.4332C42.0599 21.2656 44.8332 24.039 44.8332 27.6656" 
            stroke="#24292E" 
            strokeWidth="3" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            />

            <path 
            d="M39.0736 5.6939C41.207 7.82723 41.207 11.2406 39.0736 13.1606C36.9403 15.0806 33.527 15.2939 31.607 13.1606C29.687 11.0272 29.4737 7.6139 31.607 5.6939C33.527 3.77391 36.9403 3.77391 39.0736 5.6939" 
            stroke="#24292E" 
            strokeWidth="3" 
            strokeLinecap="round" 
            strokeLinejoin="round"
            />
            </svg>

  );
};

export default NotTeams;
