import styled from 'styled-components';

export const DropdownEmployeeRoleContainer = styled.div`
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: white;
      border: 1px solid #ced4da;

      svg {
        margin-top: 5px;
      }

      span {
        color: #24292e;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      width: 100%;
      overflow: auto;
      height: 200px;
  }
    }
  }

  .show {
    .dropdown-toggle {
      background-color: white !important;
      border: 1px solid #ced4da !important;
    }

    .dropdown-toggle:focus {
      box-shadow: none;
    }

    .btn-primary.dropdown-toggle:focus {
      box-shadow: none;
    }
  }

  .btn-primary:focus {
    box-shadow: none;
  }
  
 }
`;
