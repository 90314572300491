import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
  GET_PTOS_MANAGER,
  GET_TEAMS_BY_COMPANY_ID_QUERY,
  GET_TEAMS_BY_MANAGER_ID_QUERY,
  GET_TEAM_DATA_BY_ID,
  GET_EMPLOYEES_BY_TEAMS_AND_COMPANY,
  GET_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE,
  GET_MANAGER_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE,
  GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY,
  GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY_FOR_ROLE
} from '../../../queries/teams';

export const useTeamsByCompanyQuery = companyId => {
  return useQuery(GET_TEAMS_BY_COMPANY_ID_QUERY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useTeamsByCompanyQueryEmployeeByTeams = (
  companyId,
  idEmployee
) => {
  return useQuery(GET_MANAGER_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE, {
    variables: { companyId, idEmployee },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useTeamsByCompanyQueryEmployee = (companyId, idEmployee) => {
  return useQuery(GET_TEAMS_BY_COMPANY_ID_QUERY_EMPLOYEE, {
    variables: { companyId, idEmployee },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useEmployeesByTeamsAndCompany = (companyId, idTeam) => {
  return useQuery(GET_EMPLOYEES_BY_TEAMS_AND_COMPANY, {
    variables: { companyId, idTeam },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useTeamsByCompanyQueryEmployeeNotTeams = companyId => {
  return useQuery(
    GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY,
    {
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  );
};

export const useTeamsByCompanyQueryEmployeeNotTeamsForRole = companyId => {
  return useQuery(
    GET_EMAILS_SEND_EMAIL_WHEN_EMPLOYEE_NOT_BELONG_TEAMS_BY_COMPANY_FOR_ROLE,
    {
      variables: { companyId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  );
};

export const useTeamsByManagerQuery = (managerId, companyId) => {
  return useQuery(GET_TEAMS_BY_MANAGER_ID_QUERY, {
    variables: { managerId, companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const usePtosManager = (managerId, companyId) => {
  return useQuery(GET_PTOS_MANAGER, {
    variables: { managerId, companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetTeamDataByIdLazyQuery = teamId => {
  return useLazyQuery(GET_TEAM_DATA_BY_ID, {
    variables: { teamId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
