import React from 'react';

const WarningRound = ({ width = '100', height = '101' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 89.3871C28.5203 89.3871 11.1111 71.9779 11.1111 50.4983C11.1111 29.0186 28.5203 11.6094 50 11.6094C71.4796 11.6094 88.8889 29.0186 88.8889 50.4983C88.8889 71.9779 71.4796 89.3871 50 89.3871Z"
        stroke="#24292E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M49.9834 28.2773C47.6834 28.2773 45.8167 30.144 45.8334 32.444C45.8334 34.744 47.7 36.6107 50 36.6107C52.3 36.6107 54.1667 34.744 54.1667 32.444C54.1667 30.144 52.3 28.2773 49.9834 28.2773Z"
        fill="#24292E"
      />

      <path
        d="M50 44.9453V65.7786"
        stroke="#24292E"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningRound;
