import React from 'react';

const SearchMobile = ({ width = '28', height = '28' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7449_57776)">
        <path
          d="M23.3329 23.3349L18.7246 18.7266"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M12.9025 21.139C17.4508 21.139 21.138 17.4518 21.138 12.9035C21.138 8.35513 17.4508 4.66797 12.9025 4.66797C8.35415 4.66797 4.66699 8.35513 4.66699 12.9035C4.66699 17.4518 8.35415 21.139 12.9025 21.139Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_7449_57776">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchMobile;
