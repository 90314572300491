import React from 'react';

const Checked = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4234_213776)">
        <rect width="18" height="18" rx="4" fill="#172C45" />
        <g filter="url(#filter0_d_4234_213776)">
          <path
            d="M7.64177 12.1967C8.48977 12.7267 8.48979 12.7267 8.48983 12.7266L8.49002 12.7263L8.49079 12.7251L8.49388 12.7202L8.50614 12.7005L8.55407 12.6239L8.73653 12.3324C8.89394 12.0809 9.11928 11.7213 9.3909 11.2885C9.93418 10.4228 10.6624 9.26492 11.4023 8.09534C12.1426 6.92519 12.8932 5.74572 13.4818 4.83603C13.7765 4.38063 14.0281 3.99668 14.2165 3.71659C14.2676 3.6406 14.3131 3.57374 14.3528 3.51617C14.3916 3.52577 14.4288 3.53916 14.4596 3.55502C14.4507 3.59044 14.4322 3.64443 14.3944 3.7201C14.2534 4.00217 13.7724 4.78959 13.0833 5.87591C12.4097 6.93774 11.5733 8.2295 10.7562 9.48177C9.93943 10.7335 9.14362 11.9432 8.55187 12.8402C8.25604 13.2887 8.01129 13.6588 7.84053 13.9168L7.64278 14.2154L7.6406 14.2187C7.23353 13.6411 6.48648 12.8245 5.80628 12.081C5.71333 11.9793 5.62162 11.8791 5.53221 11.781C5.12037 11.3293 4.7389 10.9052 4.44323 10.5475C4.29548 10.3687 4.1788 10.2181 4.09455 10.0972C4.06883 10.0604 4.04882 10.03 4.03347 10.0058C4.04348 9.99558 4.05557 9.98411 4.07009 9.97158C4.13958 9.91163 4.23598 9.85299 4.34354 9.81079C4.37673 9.79777 4.40773 9.78758 4.43605 9.77969C4.4981 9.83005 4.57838 9.90234 4.67639 10.0003C4.95537 10.2793 5.28414 10.6714 5.60977 11.0901C5.93128 11.5035 6.23205 11.9198 6.45356 12.2345C6.56395 12.3914 6.65381 12.5219 6.71575 12.6126C6.7467 12.658 6.77063 12.6933 6.78661 12.717L6.80449 12.7436L6.80873 12.75L6.80964 12.7513L6.80972 12.7514L6.80978 12.7515L7.66887 14.0402L8.48977 12.7267L7.64177 12.1967ZM4.32234 9.69967C4.32221 9.69915 4.32781 9.70174 4.33953 9.70899C4.32833 9.70381 4.32247 9.70019 4.32234 9.69967ZM7.80091 14.433C7.8024 14.4341 7.80313 14.4347 7.80313 14.4347C7.80312 14.4348 7.80237 14.4342 7.80091 14.433ZM14.4997 3.58058C14.4996 3.58059 14.499 3.58007 14.498 3.57896C14.4992 3.58001 14.4997 3.58057 14.4997 3.58058ZM14.4675 3.5081C14.4669 3.50366 14.467 3.50131 14.4672 3.50125C14.4673 3.50119 14.4676 3.50341 14.4675 3.5081Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4234_213776"
          x="1"
          y="1.5"
          width="16.4678"
          height="16.8831"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4234_213776"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4234_213776"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4234_213776">
          <rect width="18" height="18" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Checked;
