import gql from 'graphql-tag';

export const ADD_EMPLOYEE_TO_COMPANY = gql`
  mutation ADD_EMPLOYEE_TO_COMPANY(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $mothersMaidenName: String
    $username: String!
    $salary: Float
    $schedule: String
    $employmentType: String
    $dateOfBirth: DateTime
    $startDate: String!
    $systemsEnabled: SystemCreateManyWithoutEmployeesInput!
    $contactInformation: ContactInformationCreateOneWithoutEmployeeInput!
    $employeeRole: EmployeeRoleCreateOneWithoutEmployeesInput!
    $paymentInformation: PaymentInformationCreateManyWithoutEmployeeInput
    $customInfo: CustomInformationCreateManyWithoutEmployeeInput!
  ) {
    addNewEmployeeToCompany(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      mothersMaidenName: $mothersMaidenName
      username: $username
      salary: $salary
      schedule: $schedule
      employmentType: $employmentType
      dateOfBirth: $dateOfBirth
      startDate: $startDate
      systemsEnabled: $systemsEnabled
      contactInformation: $contactInformation
      employeeRole: $employeeRole
      paymentInformation: $paymentInformation
      customInfo: $customInfo
    ) {
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      username
      schedule
      employmentType
      status
      employeeRole {
        id
        name
      }
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      salary
      startDate
      dateOfBirth
      systemsEnabled {
        id
        name
        logo
      }
      customInfo {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;

export const DELETE_EMPLOYEE_MUTATION = gql`
  mutation DELETE_EMPLOYEE_MUTATION($employeeId: String!) {
    deleteEmpleyeeById(employeeId: $employeeId)
  }
`;

export const DELETE_CONTACT_INFORMATION_MUTATION = gql`
  mutation DELETE_CONTACT_INFORMATION_MUTATION($id: String!) {
    deleteOneContactInformation(where: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UPDATE_EMPLOYEE_MUTATION(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $mothersMaidenName: String
    # $status: EMPLOYEE_STATUS!
    $username: String!
    $schedule: String
    $employmentType: String
    $salary: Float
    $dateOfBirth: DateTime
    $startDate: DateTime!
    $systemsEnabled: SystemUpdateManyWithoutEmployeesInput!
    $contactInformation: ContactInformationUpdateOneWithoutEmployeeInput
    $employeeRole: EmployeeRoleUpdateOneRequiredWithoutEmployeesInput!
    $paymentInformation: PaymentInformationUpdateManyWithoutEmployeeInput
    $id: String!
    $salaryHistory: SalaryHistoryInput
    $customInfo: CustomInformationUpdateManyWithoutEmployeeInput!
  ) {
    updateOneEmployee(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      mothersMaidenName: $mothersMaidenName
      # status: $status
      username: $username
      salary: $salary
      schedule: $schedule
      employmentType: $employmentType
      dateOfBirth: $dateOfBirth
      startDate: $startDate
      systemsEnabled: $systemsEnabled
      contactInformation: $contactInformation
      employeeRole: $employeeRole
      paymentInformation: $paymentInformation
      salaryHistory: $salaryHistory
      customInfo: $customInfo
      where: { id: $id }
    ) {
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      username
      schedule
      employmentType
      status
      employeeRole {
        id
        name
      }
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      salary
      startDate
      dateOfBirth
      systemsEnabled {
        id
        name
        logo
      }
      customInfo {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_PROFILE_MUTATION = gql`
  mutation UPDATE_EMPLOYEE_PROFILE_MUTATION(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $mothersMaidenName: String
    $contactInformation: ContactInformationUpdateOneWithoutEmployeeInput!
    $paymentInformation: PaymentInformationUpdateManyWithoutEmployeeInput
    $isProfile: Boolean
    $dateOfBirth: DateTime!
    $id: EmployeeActionsInput
    $customInfo: CustomInformationUpdateManyWithoutEmployeeInput!
  ) {
    updateOneEmployee(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      mothersMaidenName: $mothersMaidenName
      contactInformation: $contactInformation
      paymentInformation: $paymentInformation
      isProfile: $isProfile
      customInfo: $customInfo
      where: $id
    ) {
      # id
      # firstName
      id
      firstName
      middleName
      lastName
      mothersMaidenName
      contactInformation {
        id
        personalEmail
        phone
        addressOne
        addressTwo
        state
        postalCode
        idType
        documentId
        country
        city
      }
      dateOfBirth
      customInfo {
        id
        name
        value
      }
      paymentInformation {
        id
        bankName
        bankAccount
        routingNumber
        bankCountry
        bankAddressOne
        bankState
        bankPostalCode
        bankCity
        bankSwiftCode
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_STATUS = gql`
  mutation UPDATE_EMPLOYEE_STATUS($id: String!, $status: String!) {
    updateOneEmployeeStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const UPDATE_ACTIVITY_LOG_MUTATION = gql`
  mutation UPDATE_ACTIVITY_LOG_MUTATION(
    $id: String
    $companyRole: CompanyRoleUpdateManyWithoutFromInput
  ) {
    updateOneUser(where: { id: $id }, data: { companyRole: $companyRole }) {
      id
    }
  }
`;

export const ADD_EMPLOYEE_FIRST_SALARY_HISTORY_MUTATION = gql`
  mutation ADD_EMPLOYEE_FIRST_SALARY_HISTORY_MUTATION(
    $amount: Float!
    $description: String!
    $employeeId: String!
  ) {
    createOneSalaryHistory(
      data: {
        amount: $amount
        description: $description
        employee: { connect: { id: $employeeId } }
      }
    ) {
      id
    }
  }
`;

export const CREATE_EMPLOYEE_NOTE = gql`
  mutation CREATE_EMPLOYEE_NOTE(
    $createdAt: DateTime!
    $createdBy: String!
    $employee: String!
    $note: String!
  ) {
    CreateNoteEmployee(
      CreateNoteEmployee: {
        createdAt: $createdAt
        createdBy: $createdBy
        employee: $employee
        note: $note
      }
    )
  }
`;

export const DELETE_EMPLOYEE_NOTE = gql`
  mutation DELETE_EMPLOYEE_NOTE($employeeNoteId: String!) {
    deleteOneEmployeeNote(where: { id: $employeeNoteId }) {
      id
    }
  }
`;
