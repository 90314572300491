import { useQuery } from '@apollo/react-hooks';
import {
  QUERY_SEPARATION,
  GET_EMPLOYEE_SEPARATE
} from '../../../queries/separation';

export const useSeparationQuery = Id =>
  useQuery(QUERY_SEPARATION, {
    variables: { id: Id }
  });

export const useEmployeeSeparation = Id => {
  return useQuery(GET_EMPLOYEE_SEPARATE, {
    variables: { employeeId: Id }
  });
};
