export const initialsColumns = [
    {
      name: 'Admin',
      key: 'admin',
      active: true
    },
    {
      name: 'Admin & Employee',
      key: 'admin&employee',
      active: true
    },
    {
      name: 'Employee',
      key: 'employee',
      active: true
    },
    {
      name: 'Employee & Manager',
      key: 'employee&manager',
      active: true
    },
]