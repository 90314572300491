import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountCircle } from '@material-ui/icons';
import WarningRound from '../../../components/Icons/WarningRound';
import { useMutation } from '@apollo/react-hooks';
import { useGetSignedUrlToPutS3Query } from '../../../graphql/container/query/files';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../components/DeleteEmployeeAlert/styles.css';
import { Select, MenuItem, CircularProgress } from '@material-ui/core';
import { ContentProfile } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import { useGetCitiesOfTheEmployeesQuery } from '../../../graphql/container/query/profile';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import {
  emailValidation,
  transformUTCDateNoTime
} from '../../../services/Utils/getUtils';
import Exclamation from '../../../components/Icons/Exclamation';
import { personal, contact, banking, employment, inicialpto } from './format';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { UPDATE_PROFILE_MUTATION } from '../../../graphql/mutations/profile';
import { CREATE_EMPLOYEE_SEPARATE } from '../../../graphql/mutations/separation';
import { DELETE_EMPLOYEE_SEPARATE } from '../../../graphql/mutations/separation';
import { useEmployeeSeparation } from '../../..//graphql/container/query/separation';
import { useGetCountriesQuery } from '../../../graphql/container/query/company';
import {
  ModalAlert,
  ModalAlertNote,
  ModalCustomImg
} from '../../../components/Modals';
import {
  HAS_VISA,
  getImageError,
  DATE_FORMAT
} from '../../../services/Constants/getDefaults';
import {
  firstUpper,
  formatDate,
  formatStringDate
} from '../../../services/Utils/getUtils';

import { useRolesByCompanyQuery } from '../../../graphql/container/query/roles';
import { useTeamsByCompanyQuery } from '../../../graphql/container/query/teams';
import { CREATE_EMPLOYEE_MUTATION } from '../../../graphql/mutations/teams';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuestionMark from '../../../components/Icons/QuestionMark';
import QuestionMarkHover from '../../../components/Icons/QuestionMarkHover';
import moment from 'moment';
import { useGetEmployeeLazyQuery,  } from '../../../graphql/container/query/employees';
import { DELETE_EMPLOYEE_MUTATION, CREATE_EMPLOYEE_NOTE } from '../../../graphql/mutations/employees';
import {
  routesAdmin,
  routesOwner,
  routesManager
} from '../../../services/Constants/paths';
import { AlertMessageContext } from '../../../Context/AlertMessageContext';
import { InitialPto } from './InitialPto';
import { Employment } from './Employment';
import { Banking } from './Banking';
import { Contact } from './Contact';
import { Personal } from './Personal';
import DatePickerModule from '../../../components/DatePickerModal';
import DropdownEmployeeRole from '../../../components/DropdownEmployeeRole';
import { DESCRIPTION_EMPLOYEE_DELETE } from '../../../services/Constants/getLabels';
import Teams from './Teams';
import SwitchRole from '../../../components/Icons/SwitchRole';
import Trash from '../../../components/Icons/Trash';

const CreateEditEmployee = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const isMounted = useRef(false);

  const { currentUser } = useContext(AppContext);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const companyId = currentUser?.companyRole[0].to;
  const [profile] = useState();
  const [edit, setEdit] = useState();
  const [info, setInfo] = useState();
  const [multipleInfo, setMultipleInfo] = useState([]);
  const [cities, setCities] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleUserSeparate, setRoleUserSeparate] = useState([]);
  const [itemLoading, setItemLoading] = useState();
  const [countries, setCountries] = useState([]);
  const [employee, setEmployee] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [secondAlert, setSecondAlert] = useState(false);
  const [valCompanyEmail, setValComapnyEmail] = useState(false);
  const [valPersonalEmail, setValPersonalEmail] = useState(false);
  const [teamsIsManager, setTeamsIsManager] = useState([]);
  const [modalCustomProfile, setModalCustomProfile] = useState(false);
  const [fileLoad, setFileLoad] = useState();
  const idEmployee = params?.id;
  const [startDate, setStartDate] = useState(new Date());
  const [imageProfile, setImageProfile] = useState();


  const regexValidateEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const bankingFiltered = isChecked
    ? banking
    : banking
        .filter(el => el.title !== 'Intermediary Bank Routing:')
        .map(el => el);

  const [formPersonal, setFormPersonal] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    documentId: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    nationality: '',
    dateOfBirth: null,
    value: '',
    usVisa: false
  });

  const [formContact, setFormContact] = useState({
    companyEmail: '',
    personalEmail: '',
    phone: ''
  });

  const [formBanking, setFormBanking] = useState({
    bankName: '',
    bankAccount: '',
    bankSwiftCode: '',
    routingNumber: '',
    intermediaryRouting: ''
  });

  const [formEmployment, setFormEmployment] = useState({
    employmentForm: 'W8-BEN',
    schedule: '',
    startDate: '',
    employeeRole: '',
    salary: 0,
    teamIds: []
  });

  const {
    data: { getAllTeamsByCompanyIdInfoTable }
  } = useTeamsByCompanyQuery(companyId);

  const {
    data: { getAllRolesByCompanyId }
  } = useRolesByCompanyQuery(companyId);

  const {
    data: { getCitiesOfTheEmployees },
    refetch: refetchCities
  } = useGetCitiesOfTheEmployeesQuery();

  const {
    data: { getCountries },
    refetch: refetchCountries
  } = useGetCountriesQuery();

  const [
    getEmployee,
    {
      data: getEmployeeData,
      loading: getEmployeeLoading,
      refetch: refetchEmployee
    }
  ] = useGetEmployeeLazyQuery(idEmployee);

  const [updateProfile, { loading: updateProfileLoading }] = useMutation(
    UPDATE_PROFILE_MUTATION
  );

  const [createEmployeeSeparate] = useMutation(CREATE_EMPLOYEE_SEPARATE);

  const [deleteEmployeeSeparate] = useMutation(DELETE_EMPLOYEE_SEPARATE);

  const [createEmployeeNote] = useMutation(CREATE_EMPLOYEE_NOTE);

  const {
    data: { getEmployeeSeparate }
  } = useEmployeeSeparation(idEmployee ? idEmployee : '0');

  const [separateAlert, setSeparateAlert] = useState({
    Date: `${new Date().toLocaleDateString('en-US')}`,
    Note: getEmployeeSeparate?.note,
    Eligible: getEmployeeSeparate?.availableForRehire
  });

  useEffect(() => {
    if (getEmployeeSeparate) {
      setSeparateAlert({
        ...separateAlert,
        ['Note']: getEmployeeSeparate?.note,
        ['Eligible']:
          getEmployeeSeparate?.availableForRehire === 0 ? 'No' : 'Yes'
      });
    }
  }, [getEmployeeSeparate]);

  const [createEmployee, { loading: createEmployeeLoading }] = useMutation(
    CREATE_EMPLOYEE_MUTATION
  );

  const [deleteEmployee, { loading: deleteEmployeeLoading }] = useMutation(
    DELETE_EMPLOYEE_MUTATION
  );
  const [
    getUrl,
    { data, loading: getSignedUrlLoading }
  ] = useGetSignedUrlToPutS3Query();

  useEffect(() => {
    if (isMounted.current) {
      if (!updateProfileLoading) {
        refetchEmployee();
      }
    }
    // eslint-disable-next-line
  }, [updateProfileLoading]);

  useEffect(() => {
    setImageProfile(
      getEmployeeData?.getEmployee?.user?.photo + '?not-use-cache'
    );
  }, [getEmployeeData, getEmployee]);

  useEffect(() => {
    if (idEmployee) {
      getEmployee(idEmployee);
    }
  }, [idEmployee, getEmployee]);

  useEffect(() => {
    if (getEmployeeData) {
      setEmployee(getEmployeeData?.getEmployee);
      setMultipleInfo(
        getEmployeeData?.getEmployee?.employeeTeam?.map(el => ({
          id: el?.team?.id,
          name: el?.team?.name,
          isManager: el?.isManager
        }))
      );
      setIsChecked(
        getEmployeeData?.getEmployee?.paymentInformation?.intermediaryRouting
          ? true
          : false
      );
    }
  }, [getEmployeeData]);

  useEffect(() => {
    if (idEmployee && employee?.employmentForm === null) {
      const itemFilter = employment.filter(el =>
        el.title.includes('Employment Form')
      );
      updateField(itemFilter[0], 'W8-BEN');
    }
  }, [employee]);

  useEffect(() => {
    if (formContact.personalEmail) {
      setValPersonalEmail(emailValidation(formContact.personalEmail));
    }
    if (formContact.companyEmail) {
      setValComapnyEmail(emailValidation(formContact.companyEmail));
    }
  }, [formContact]);

  useEffect(() => {
    if (getAllTeamsByCompanyIdInfoTable)
      setTeams(
        getAllTeamsByCompanyIdInfoTable?.map(item => ({
          ...item,
          active: false
        }))
      );
  }, [getAllTeamsByCompanyIdInfoTable]);

  useEffect(() => {
    if (getCitiesOfTheEmployees) setCities(getCitiesOfTheEmployees);
  }, [getCitiesOfTheEmployees]);

  useEffect(() => {
    if (getCountries) setCountries(getCountries);
  }, [getCountries]);

  useEffect(() => {
    handleSeparateAlert(roleUserSeparate.id, 'RoleId');
  }, [roleUserSeparate]);

  useEffect(() => {
    if (getAllRolesByCompanyId) setRoles(getAllRolesByCompanyId);
  }, [getAllRolesByCompanyId]);

  useEffect(() => {
    if (!deleteEmployeeLoading && isMounted.current) {
      navigate(routesAdmin.employees);
    }
  }, [deleteEmployeeLoading]);

  useEffect(() => {
    if (data?.getSignedUrlToPuts3 && fileLoad && !getSignedUrlLoading) {
      async function fetchData() {
        try {
          await fetch(data?.getSignedUrlToPuts3, {
            method: 'PUT',
            body: fileLoad.buffer,
            headers: {
              'Content-Type': fileLoad.type
            }
          });
          updateFieldPhoto(
            {
              field: 'photo',
              table: 'user'
            },
            data.getSignedUrlToPuts3?.split('?')[0]
          );
          setFileLoad();
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [data]);

  const trasnformToCode = value => {
    const selectedCountry = countries?.filter(el => el.label === value);
    return selectedCountry?.[0]?.country;
  };

  const handleSubmit = async () => {
    formPersonal.country = trasnformToCode(formPersonal.country);
    formPersonal.nationality = trasnformToCode(formPersonal.nationality);
    const result = await createEmployee({
      variables: {
        createEmployeeInput: {
          companyId,
          personalEmployeeInput: {
            ...formPersonal,
            fullName: formPersonal.firstName + ' ' + formPersonal.lastName
          },
          contactEmployeeInput: formContact,
          bankingEmployeeInput: formBanking,
          employmentEmployeeInput: formEmployment
        }
      }
    });
    const {
      data: { createEmployee: response }
    } = result;
    setSuccessMessage(response.message);
    navigate(routesAdmin.employees);
  };

  const removeEmployee = async () => {
    await deleteEmployeeSeparate({
      variables: {
        employeeId: idEmployee
      }
    });

    const result = await deleteEmployee({
      variables: {
        employeeId: idEmployee
      }
    });
    if (result.data.deleteEmpleyeeById) {
      setSuccessMessage('The employee was successfully Delete.');
      navigate(routesAdmin.employees);
    }
  };
  const updateFieldPhoto = async (item, value) => {
    setModalCustomProfile(false);
    await updateProfile({
      variables: {
        employeeId: idEmployee,
        field: item.field,
        table: item.table,
        value: value ? value || info : '',
        userId: employee?.user?.id
      }
    });
    refetchEmployee();
  };

  const updateField = async (item, value, list = []) => {
    setItemLoading(item);
    const currentTeams = list.filter(el => el.active).map(item => item.id);
    setEdit();
    await updateProfile({
      variables: {
        employeeId: idEmployee,
        field:
          item.input === 'companyEmail' || item.field === 'employeeRole'
            ? item.field
            : item.input,
        table: item.table,
        value: value ? value : info && info !== item.placeholder ? info : '',
        userId: employee?.user?.id,
        list: currentTeams
      }
    });
    setInfo();
    setMultipleInfo([]);
    refetchCities();
    refetchCountries();
    refetchEmployee();
    //window.history.replaceState({}, document.title)
  };

  const updateTeam = value => {
    setMultipleInfo([...multipleInfo, ...value]);
  };

  const updateStatus = async (field, table, value) => {
    setEdit();
    if (value === 'SEPARATED') {
      updateField(
        {
          title: 'Team(s):',
          edit: 'list',
          table: 'employeeTeam',
          placeholder: 'Select  ▾',
          field: 'employeeTeam',
          input: 'teamIds'
        },
        null,
        teams
      );

      createEmployeeSeparate({
        variables: {
          availableForRehire: separateAlert?.Eligible === 'Yes' ? 1 : 0,
          createdAt: new Date(),
          employee: idEmployee,
          note: separateAlert?.Note,
          separationDate: new Date(separateAlert?.Date),
          status: 'PROCESSED'
        }
      });
    } else {    
      await createEmployeeNote({
        variables: {
        createdAt: new Date(),
        createdBy : currentUser?.id,
        employee : idEmployee,
        note : separateAlert?.Note
        }
      })

      await deleteEmployeeSeparate({
        variables: {
          employeeId: idEmployee
        }
      });

      await updateProfile({
        variables: {
          employeeId: idEmployee,
          field: 'employeeRole',
          table: 'employee',
          value: separateAlert?.RoleId,
          userId: employee?.user?.id
        }
      });
    }
    await updateProfile({
      variables: {
        employeeId: idEmployee,
        field,
        table,
        value,
        userId: employee?.user?.id
      }
    });
    setInfo();
    refetchCities();
    refetchCountries();
    refetchEmployee();
    setIsStatus(false);
    setSecondAlert(false);
    setIsDelete(false);
  };

  const clickParent = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setEdit();
      setInfo();
    }
  };
  const asterix = ' * ';
  const handleImageEdited = ({ data, file64 }) => {
    if (data.state.file.name && !(data.state.status === 'EMPTY')) {
      const buffer = Buffer.from(
        file64.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
      const file = data.state.file;
      const fileSplited = file.name.split('.');
      const ext = fileSplited[fileSplited.length - 1];
      setFileLoad({ buffer, type: data.state.file.type });
      getUrl({
        variables: {
          id: idEmployee,
          contentType: data.state.file.type,
          name: `profile.${ext}`,
          section: 'employee',
          acl: 'public-read'
        }
      });
    } else if (data.state.status === 'EMPTY') {
      updateFieldPhoto(
        {
          field: 'photo',
          table: 'user'
        },
        null
      );
    } else {
      setModalCustomProfile(false);
    }
  };
  function handleChangePersonal(value, input) {
    //const correctedValue = value.replace(/[^a-zA-Z0-9]+/gi, '');
    setFormPersonal({
      ...formPersonal,
      [input]: value
    });
  }

  function handleChangeContact(value, input) {
    setFormContact({
      ...formContact,
      [input]: value
    });
  }

  function handleChangeBanking(value, input) {
    setFormBanking({
      ...formBanking,
      [input]: value
    });
  }

  function handleChangeEmployment(value, input) {
    value = input === 'salary' ? parseInt(value) : value;
    let teamsAdded = [];
    if (input === 'teamIds') {
      teamsAdded = value.filter(el => el.active).map(item => item.id);
      setMultipleInfo(value.filter(el => el.active).map(item => item));
    }

    setFormEmployment({
      ...formEmployment,
      [input === 'name' ? 'employeeRole' : input]:
        input === 'teamIds' ? teamsAdded : value
    });
  }

  function handleSeparateAlert(value, string) {
    setSeparateAlert({
      ...separateAlert,
      [string]: value
    });
  }

  function validateSeparate() {
    const listTeams = employee?.employeeTeam
      ?.filter(list => list?.isManager)
      .map(item => ({ ...item }));
    setTeamsIsManager(listTeams);
    listTeams?.length !== 0 ? setIsValidate(true) : setIsStatus(true);
  }

  function concatenateTeams(valueTeams) {
    let nameTeams = '';
    valueTeams.map(function(teams) {
      nameTeams = nameTeams + teams?.team?.name + ', ';
    });
    nameTeams !== ''
      ? (nameTeams = nameTeams.substring(0, nameTeams.length - 2) + '.')
      : (nameTeams = '');
    return nameTeams;
  }
  const toolTip = phrase => {
    return <div>{phrase}</div>;
  };

  if (getEmployeeLoading && !isMounted.current && !employee) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <>
      <ModalAlertNote
        show={isStatus}
        onClose={() => setIsStatus(false)}
        onConfirm={() => {
          setIsStatus(false);
          setSecondAlert(true);
        }}
        buttonCancelStyle="btn-danger-without-bg-width"
        buttonSuccessStyle="btn-success-width"
        flexButtons="flexButtons"
        buttonSuccess={
          employee && employee?.status === 'ACTIVE'
            ? 'Separate Employee'
            : 'Activate Employee'
        }
        disableSuccess={
          employee?.status === 'ACTIVE'
            ? (startDate && separateAlert?.Eligible) ||
              (startDate && !(employee && employee?.status === 'ACTIVE'))
              ? false
              : true
            : startDate && roleUserSeparate.length <= 0
        }
        content={
          <div className="contentSeparate">
            <p className="separateTitle">
              {employee && employee?.status === 'ACTIVE'
                ? 'Separate Employee'
                : 'Activate Employee'}
            </p>
            <div>
              <div className="labelsSeparation">
                <label className="firstLabes">
                  {employee && employee?.status === 'ACTIVE'
                    ? 'Separate Date'
                    : 'Activation Date*'}
                </label>
                <div className="datepicker-container">
                  <DatePickerModule
                    principalClass={'inner-Calendar-Separate'}
                    onChange={value => {
                      setStartDate(value);
                      handleSeparateAlert(formatStringDate(value), 'Date');
                    }}
                    selected={startDate}
                    placeholder={
                      separateAlert?.Date ? separateAlert?.Date : 'MM/DD/YY'
                    }
                  />
                </div>
              </div>

              {employee?.status === 'SEPARATED' ? (
                <div>
                  <div className="labelsSeparation">
                    <label className="firstLabes">Role*</label>
                    <DropdownEmployeeRole
                      roles={roles}
                      roleUserSeparate={roleUserSeparate}
                      setRoleUserSeparate={setRoleUserSeparate}
                    />
                  </div>
                </div>
              ) : null}
              {employee && employee?.status === 'ACTIVE' ? (
                <div className="labelsSeparation">
                  <label className="firstLabes">Eligible for rehire?</label>
                  <Select
                    disableUnderline={true}
                    className="inputsSeparate"
                    value={
                      separateAlert?.Eligible ? separateAlert?.Eligible : ''
                    }
                    placeholder={
                      separateAlert?.Eligible
                        ? ' ' + separateAlert?.Eligible
                        : 'Select'
                    }
                    onChange={e =>
                      handleSeparateAlert(e.target.value, 'Eligible')
                    }
                  >
                    {HAS_VISA.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              ) : null}
              <div className="labelsSeparation">
                <label className="firstLabes">Note:</label>
                <textarea
                  rows="8"
                  cols="20"
                  className="inputNote inputsSeparate"
                  placeholder="Text Example"
                  defaultValue={separateAlert?.Note}
                  onChange={e => handleSeparateAlert(e.target.value, 'Note')}
                ></textarea>
              </div>
            </div>
          </div>
        }
        centered
      />
      <ModalAlert
        show={secondAlert}
        confirmLabel={
          employee && employee?.status === 'ACTIVE'
            ? 'Separate Employee'
            : 'Active Employee'
        }
        onClose={() => setSecondAlert(false)}
        onConfirm={() => {
          employee && employee?.status === 'ACTIVE'
            ? updateStatus('status', 'employee', 'SEPARATED')
            : updateStatus('status', 'employee', 'ACTIVE');
        }}
        buttonCancel="Cancel"
        buttonCancelStyle="btn-danger-without-bg"
        subtitle={
          <div>
            <p className="subtitleAlert">
              Are you sure you want to{' '}
              {employee?.status === 'ACTIVE' ? 'separate' : 'active'}{' '}
              {employee?.fullName}?
            </p>
          </div>
        }
        content={
          <div>
            <div>
              <div className="labelsSeparationConfirm">
                <p className="me-1">Date: </p>
                <p style={{ fontWeight: '400' }}>
                  {separateAlert?.Date ? ' ' + separateAlert?.Date : ''}
                </p>
              </div>
              {employee && employee?.status === 'ACTIVE' ? (
                <div className="labelsSeparationConfirm">
                  <p className="me-1">Eligible for rehire?: </p>
                  <p style={{ fontWeight: '400' }}>
                    {separateAlert?.Eligible
                      ? ' ' + separateAlert?.Eligible
                      : ''}
                  </p>
                </div>
              ) : (
                <div className="labelsSeparationConfirm">
                  <p className="me-1">Rol: </p>
                  <p style={{ fontWeight: '400' }}>{roleUserSeparate?.name}</p>
                </div>
              )}
              <div className="labelsSeparationConfirm flex-column align-items-start">
                <p className="mb-1">Note: </p>
                <p style={{ fontWeight: '400' }}>
                  {separateAlert?.Note ? ' ' + separateAlert?.Note : ''}
                </p>
              </div>
            </div>
          </div>
        }
        centered
      />
      <ModalAlert
        show={isDelete}
        confirmLabel="Delete Employee"
        onClose={() => setIsDelete(false)}
        onConfirm={() => {
          setIsDelete(false);
          removeEmployee();
        }}
        subtitle={
          <div>
            <p className="subtitleAlert">
              {`Are you sure you want to delete ${employee?.firstName} ${employee?.lastName}?`}
            </p>
          </div>
        }
        content={
          <div>
            <div className="labelsSeparationConfirm">
              <p className="me-1">Date: </p>
              <p style={{ fontWeight: '400' }}>
                {separateAlert?.Date ? ' ' + separateAlert?.Date : ''}
              </p>
            </div>

            <div className="labelsSeparationConfirm">
              <p className="me-1">Eligible for rehire?: </p>
              <p style={{ fontWeight: '400' }}>
                {separateAlert?.Eligible && separateAlert?.Eligible}
              </p>
            </div>

            <div className="labelsSeparationConfirm flex-column align-items-start">
              <p className="mb-1">Note: </p>
              <p style={{ fontWeight: '400' }}>
                {separateAlert?.Note ? ' ' + separateAlert?.Note : ''}
              </p>
            </div>
          </div>
        }
        centered
      />

      <ModalAlert
        show={isValidate}
        icon={<WarningRound />}
        confirmLabel="Go to Teams"
        onClose={() => setIsValidate(false)}
        onConfirm={() => {
          setIsValidate(false);
          navigate(routesManager.listTeams(), {
            state: { tab: 1 }
          });
          //removeEmployee();
        }}
        content={
          <div>
            <p className="titleLabelsSeparationConfirmTeams">
              {
                'To separate this employee, first, you must assign a new manager to the teams that are linked to this person.'
              }
            </p>
            <div className="labelsSeparationConfirmTeams">
              <p className="title-list">
                <b>Related work teams:</b>
              </p>
              <ul>
                {teamsIsManager ? (
                  teamsIsManager.map((item, i) => {
                    return (
                      <li key={i}>
                        {' '}
                        <u
                          onClick={() => {
                            window.history.pushState(
                              null,
                              null,
                              location.pathname
                            );
                            navigate(routesOwner.createEditTeams(), {
                              state: { teamId: item?.team?.id },
                              replace: true
                            });
                          }}
                        >
                          {' '}
                          {item?.team?.name}{' '}
                        </u>{' '}
                      </li>
                    );
                  })
                ) : (
                  <p></p>
                )}
              </ul>
            </div>
          </div>
        }
      />
      <Breadcrumbs
        routes={routesAdmin.employees}
        subtitle={
          idEmployee
            ? (employee?.firstName ? employee?.firstName : '') +
              ' ' +
              (employee?.lastName ? employee?.lastName : '')
            : 'New Employee'
        }
        section="Employees"
      />
      <ContentProfile>
        <div className="little-title">
          {idEmployee ? (
            <h5> Employee Profile </h5>
          ) : (
            <h5> Create New Employee </h5>
          )}
          <div className="little-subtitle">
            <Exclamation />
            <p>Type or click on any field to edit</p>
          </div>
        </div>
        {idEmployee && (
          <div className="main-data" onClick={clickParent}>
            <div className="photo" onClick={() => setModalCustomProfile(true)}>
              {employee?.user?.photo ? (
                <img
                  src={imageProfile}
                  alt="Profile"
                  onErrorCapture={e => (
                    (e.target.onErrorCapture = null),
                    (e.target.src = `${getImageError(companyId)}`)
                  )}
                  className="profile-photo"
                  referrerPolicy="no-referrer"
                  crossOrigin="anonymous"
                />
              ) : (
                <AccountCircle
                  style={{ fontSize: 100, width: '100px', height: '100px' }}
                />
              )}
            </div>
            <div className="info">
              <h2 className="heading-h2">{`${
                employee?.firstName ? employee?.firstName : ''
              } ${employee?.lastName ? employee?.lastName : ''} ${
                employee?.mothersMaidenName ? employee?.mothersMaidenName : ''
              }`}</h2>
              <p className="text-normal">
                {employee?.employeeRoleInfo?.name} |{' '}
                {employee?.schedule === 'FULL'
                  ? 'Full-Time'
                  : employee?.schedule === 'HOURLY'
                  ? 'Hourly'
                  : 'Part-Time'}{' '}
                |{' '}
                {employee?.employeeTeam
                  ? `Teams: ${concatenateTeams(employee?.employeeTeam)} `
                  : ''}
              </p>
              <p className="text-normal">
                Salary: {'$ ' + employee?.salary?.toLocaleString('en-US')}
              </p>
              <p className="text-normal">
                Start date:{' '}
                {moment(
                  new Date(transformUTCDateNoTime(employee?.startDate))
                ).format(DATE_FORMAT)}{' '}
                -{' '}
                {new Date() > new Date(employee?.startDate)
                  ? Math.abs(moment().diff(employee?.startDate, 'months'))
                  : '0'}{' '}
                months
              </p>
            </div>
          </div>
        )}

        <Personal
          idEmployee={idEmployee}
          personal={personal}
          employee={employee}
          clickParent={clickParent}
          setInfo={setInfo}
          info={info}
          edit={edit}
          setEdit={setEdit}
          updateField={updateField}
          handleChangePersonal={handleChangePersonal}
          formatStringDate={formatStringDate}
          formPersonal={formPersonal}
          roles={roles}
          toolTip={toolTip}
          asterix={asterix}
          formatDate={formatDate}
          cities={cities}
          countries={countries}
          CircularProgress={CircularProgress}
          getEmployeeLoading={getEmployeeLoading}
          updateProfileLoading={updateProfileLoading}
          itemLoading={itemLoading}
        />

        <Contact
          idEmployee={idEmployee}
          contact={contact}
          clickParent={clickParent}
          setInfo={setInfo}
          info={info}
          edit={edit}
          setEdit={setEdit}
          updateField={updateField}
          formContact={formContact}
          toolTip={toolTip}
          QuestionMark={QuestionMark}
          QuestionMarkHover={QuestionMarkHover}
          handleChangeContact={handleChangeContact}
          employee={employee}
          asterix={asterix}
          regexValidateEmail={regexValidateEmail}
          valCompanyEmail={valCompanyEmail}
          valPersonalEmail={valPersonalEmail}
          CircularProgress={CircularProgress}
          getEmployeeLoading={getEmployeeLoading}
          updateProfileLoading={updateProfileLoading}
          itemLoading={itemLoading}
          emailValidation={emailValidation}
        />

        <Banking
          idEmployee={idEmployee}
          bankingFiltered={bankingFiltered}
          clickParent={clickParent}
          setInfo={setInfo}
          info={info}
          edit={edit}
          setEdit={setEdit}
          updateField={updateField}
          formBanking={formBanking}
          toolTip={toolTip}
          QuestionMark={QuestionMark}
          QuestionMarkHover={QuestionMarkHover}
          handleChangeBanking={handleChangeBanking}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          employee={employee}
          CircularProgress={CircularProgress}
          getEmployeeLoading={getEmployeeLoading}
          updateProfileLoading={updateProfileLoading}
          itemLoading={itemLoading}
        />

        <Employment
          idEmployee={idEmployee}
          employment={employment}
          employee={employee}
          formatDate={formatDate}
          clickParent={clickParent}
          setInfo={setInfo}
          info={info}
          edit={edit}
          setEdit={setEdit}
          updateField={updateField}
          handleChangeEmployment={handleChangeEmployment}
          formatStringDate={formatStringDate}
          teams={teams}
          multipleInfo={multipleInfo}
          setMultipleInfo={setMultipleInfo}
          formEmployment={formEmployment}
          roles={roles}
          toolTip={toolTip}
          asterix={asterix}
          updateTeam={updateTeam}
          QuestionMark={QuestionMark}
          QuestionMarkHover={QuestionMarkHover}
          CircularProgress={CircularProgress}
          getEmployeeLoading={getEmployeeLoading}
          updateProfileLoading={updateProfileLoading}
          itemLoading={itemLoading}
        />

        <Teams
          idEmployee={idEmployee}
          employment={employment}
          clickParent={clickParent}
          updateField={updateField}
          handleChangeEmployment={handleChangeEmployment}
          teams={teams}
          multipleInfo={multipleInfo}
          toolTip={toolTip}
          asterix={asterix}
          CircularProgress={CircularProgress}
          getEmployeeLoading={getEmployeeLoading}
          updateProfileLoading={updateProfileLoading}
          itemLoading={itemLoading}
        />

        {idEmployee && (
          <InitialPto
            inicialpto={inicialpto}
            clickParent={clickParent}
            setInfo={setInfo}
            info={info}
            edit={edit}
            setEdit={setEdit}
            updateField={updateField}
            employee={employee}
            CircularProgress={CircularProgress}
            getEmployeeLoading={getEmployeeLoading}
            updateProfileLoading={updateProfileLoading}
            itemLoading={itemLoading}
          />
        )}

        <ModalCustomImg
          image={imageProfile}
          show={modalCustomProfile}
          size={400}
          onClose={() => setModalCustomProfile(false)}
          onConfirm={handleImageEdited}
        />
        {!idEmployee && (
          <div className="buttonsCreate">
            <button
              className="singleButtons btn-danger btn btn-primary"
              onClick={() => navigate(routesAdmin.employees)}
            >
              Cancel
            </button>
            <button
              className="singleButtons btn-success btn btn-primary"
              onClick={handleSubmit}
              disabled={
                !formPersonal.firstName ||
                !formPersonal.lastName ||
                !formContact.companyEmail ||
                (formContact.companyEmail &&
                  !emailValidation(formContact.companyEmail)) ||
                !formContact.personalEmail ||
                (formContact.personalEmail &&
                  !emailValidation(formContact.personalEmail)) ||
                !formEmployment.salary ||
                !formEmployment.startDate ||
                formEmployment.startDate === 'Invalid date' ||
                !formEmployment.employeeRole ||
                createEmployeeLoading
              }
            >
              Create Employee
            </button>
          </div>
        )}
        {idEmployee && (
          <section className="buttonsEdition">
            <div className="buttonsInnerEdition borderBottom">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="d-flex flex-md-column">
                    <div className="d-flex align-items-center">
                      <SwitchRole width="18" height="18" />
                      <p className="innerTitleEdition ms-2 m-0">Status: </p>
                    </div>
                    <div className="mt-3">
                      <p
                        className={`innerTagEdition ${
                          employee?.status === 'ACTIVE' ? 'active' : 'separated'
                        } m-0`}
                      >
                        {employee?.status === 'ACTIVE' ? 'Active' : 'Separated'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-9 col-sm-12 d-flex align-items-center">
                  <button
                    onClick={() => validateSeparate()}
                    className="singleButtonsId btn-success btn btn-primary"
                  >
                    {employee && employee?.status === 'ACTIVE'
                      ? 'Separate Employee'
                      : 'Activate Employee'}
                  </button>
                </div>
              </div>
            </div>
            <div className="buttonsInnerEdition">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="d-md-flex align-items-center">
                    <Trash />
                    <p className="innerTitleEdition ms-2 m-0">
                      Delete Employee:
                    </p>
                  </div>

                  <p className="innerTextSmall mt-3 mb-0">
                    {DESCRIPTION_EMPLOYEE_DELETE}
                  </p>
                </div>
                <div className="col-md-9 col-sm-12 d-flex align-items-end">
                  <button
                    disabled={
                      employee && employee?.status === 'ACTIVE' ? true : false
                    }
                    className="singleButtonsId btn-danger btn-danger-disabled btn-primar"
                    onClick={() => {
                      setIsDelete(true);
                    }}
                  >
                    Delete Employee
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}
      </ContentProfile>
    </>
  );
};

export default CreateEditEmployee;
