import React from 'react';

const CalendarPto = () => {
  return (
        
        <svg 
        width="18" 
        height="18" 
        viewBox="0 0 18 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
        
        <path 
        d="M13.6667 2H4.33333C3.04467 2 2 3.04467 2 4.33333V13.6667C2 14.9553 3.04467 16 4.33333 16H13.6667C14.9553 16 16 14.9553 16 13.6667V4.33333C16 3.04467 14.9553 2 13.6667 2Z" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        

        <path 
        d="M16 5.89062H2" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M13.1227 8.6092C13.1227 8.62476 13.0994 8.64809 13.0838 8.64809C13.0605 8.64809 13.0449 8.62476 13.0449 8.6092C13.0449 8.58587 13.0605 8.57031 13.0838 8.57031" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M13.083 8.57184H13.0752C13.0908 8.56406 13.1141 8.58739 13.1141 8.61073" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M10.3991 8.6092C10.3991 8.62476 10.3757 8.64809 10.3602 8.64809C10.3368 8.64809 10.3213 8.62476 10.3213 8.6092C10.3213 8.58587 10.3368 8.57031 10.3602 8.57031" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M10.3535 8.57184H10.3457C10.3613 8.56406 10.3846 8.58739 10.3846 8.61073" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M7.67739 8.6092C7.67739 8.62476 7.65405 8.64809 7.6385 8.64809C7.61516 8.64809 7.59961 8.62476 7.59961 8.6092C7.59961 8.58587 7.61516 8.57031 7.6385 8.57031" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M7.63082 8.57184H7.62305C7.6386 8.56406 7.66194 8.58739 7.66194 8.61073" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M4.95473 10.9451C4.95473 10.9607 4.9314 10.984 4.91584 10.984C4.89251 10.984 4.87695 10.9607 4.87695 10.9451C4.87695 10.9218 4.89251 10.9062 4.91584 10.9062" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        troke-linejoin="round"
        />
        
        <path 
        d="M4.90914 10.9039H4.90137C4.91692 10.8961 4.94026 10.9194 4.94026 10.9428" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M7.67739 10.9451C7.67739 10.9607 7.65405 10.984 7.6385 10.984C7.61516 10.984 7.59961 10.9607 7.59961 10.9451C7.59961 10.9218 7.61516 10.9062 7.6385 10.9062" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M7.63082 10.9039H7.62305C7.6386 10.8961 7.66194 10.9194 7.66194 10.9428" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M10.3991 10.9451C10.3991 10.9607 10.3757 10.984 10.3602 10.984C10.3368 10.984 10.3213 10.9607 10.3213 10.9451C10.3213 10.9218 10.3368 10.9062 10.3602 10.9062" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M10.3535 10.9039H10.3457C10.3613 10.8961 10.3846 10.9194 10.3846 10.9428" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M13.1227 10.9451C13.1227 10.9607 13.0994 10.984 13.0838 10.984C13.0605 10.984 13.0449 10.9607 13.0449 10.9451C13.0449 10.9218 13.0605 10.9062 13.0838 10.9062" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M13.083 10.9039H13.0752C13.0908 10.8961 13.1141 10.9194 13.1141 10.9428" 
        stroke="#24292E"
         strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
        
        <path 
        d="M13.1227 13.2772C13.1227 13.2927 13.0994 13.3161 13.0838 13.3161C13.0605 13.3161 13.0449 13.2927 13.0449 13.2772C13.0449 13.2538 13.0605 13.2383 13.0838 13.2383" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M13.083 13.2398H13.0752C13.0908 13.232 13.1141 13.2554 13.1141 13.2787" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
        
        <path 
        d="M10.3991 13.2772C10.3991 13.2927 10.3757 13.3161 10.3602 13.3161C10.3368 13.3161 10.3213 13.2927 10.3213 13.2772C10.3213 13.2538 10.3368 13.2383 10.3602 13.2383" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M10.3535 13.2398H10.3457C10.3613 13.232 10.3846 13.2554 10.3846 13.2787" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
        
        <path 
        d="M7.67739 13.2772C7.67739 13.2927 7.65405 13.3161 7.6385 13.3161C7.61516 13.3161 7.59961 13.2927 7.59961 13.2772C7.59961 13.2538 7.61516 13.2383 7.6385 13.2383" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M7.63082 13.2398H7.62305C7.6386 13.232 7.66194 13.2554 7.66194 13.2787" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
        
        <path 
        d="M4.95473 13.2772C4.95473 13.2927 4.9314 13.3161 4.91584 13.3161C4.89251 13.3161 4.87695 13.2927 4.87695 13.2772C4.87695 13.2538 4.89251 13.2383 4.91584 13.2383" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
        
        <path 
        d="M4.90914 13.2398H4.90137C4.91692 13.232 4.94026 13.2554 4.94026 13.2787" 
        stroke="#24292E" 
        strokeWidth="1.125" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />

    </svg>
  );
};

export default CalendarPto;
