import React from 'react';

const EyeOpen = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59146 4.41006C10.4697 5.28831 10.4697 6.71406 9.59146 7.59381C8.71321 8.47206 7.28746 8.47206 6.40771 7.59381C5.52946 6.71556 5.52946 5.28981 6.40771 4.41006C7.28746 3.53031 8.71246 3.53031 9.59146 4.41006Z"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 6C1.25 5.50575 1.364 5.01675 1.5845 4.566C2.72075 2.24325 5.23175 0.75 8 0.75C10.7682 0.75 13.2793 2.24325 14.4155 4.566C14.636 5.01675 14.75 5.50575 14.75 6C14.75 6.49425 14.636 6.98325 14.4155 7.434C13.2793 9.75675 10.7682 11.25 8 11.25C5.23175 11.25 2.72075 9.75675 1.5845 7.434C1.364 6.98325 1.25 6.49425 1.25 6Z"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOpen;
