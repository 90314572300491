import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';

import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { URL } from '../services/Utils/getUtils';
import {
  CONTEXT_CREATION_FAILED_TOKEN,
  CONTEXT_CREATION_FAILED_DENIED,
  JWT_EXPIRED
} from '../services/Constants/getLabels';
import { alertError } from '../utils/customSwal';
const httpLink = new HttpLink({
  uri: URL
});

/* A middleware that is executed after the request is made. */
const afterWareLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      switch (error.message) {
        case CONTEXT_CREATION_FAILED_TOKEN:
          // sessionStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.href = '403';
          break;

        case CONTEXT_CREATION_FAILED_DENIED:
          // sessionStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.href = '403';
          break;

        case JWT_EXPIRED:
          // sessionStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.href = '401';
          break;

        // we need access to email and name, to implement this here
        // case 'GraphQL error: Permission denied':
        //   window.location.href += `403?email=${email}&name=${name}`;
        //   break;

        default:
          // TODO modify all the call with graph and add Alert
          const errorTemporal = error.message.includes('Error: Error')
            ? error.message.replace('Error: Error: ', 'Error: ')
            : error.message;
          console.log('-----> ~ afterWareLink ~ error', error);
          alertError(errorTemporal);
          // window.location.href = `${window.location.origin}/home`;
          // window.location.href = `${window.location.origin}${routesPublic.linkError}`;
          // window.location.href = `${window.location.origin}/404?error=${error.message}`;
          break;
      }
    });
  } else {
    console.log(networkError);
  }
});

const authLink = new ApolloLink((operation, forward) => {
  // Get the authentication token from local storage if it exists
  // const token = window.sessionStorage.getItem('token');
  const token = window.localStorage.getItem('token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

export const Client = new ApolloClient({
  // uri: URL,
  link: from([authLink, afterWareLink, httpLink]),
  cache: new InMemoryCache()
});
