import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TokenExpiration from '../../../components/Icons/TokenExpiration';
import { ContentLinkError } from './styles';

const ExpirationToken = () => {
  const navigate = useNavigate();

  return (
    <ContentLinkError>
      <TokenExpiration />
      <p className="heading-h1">Your session has expired</p>
      <p className="heading-h3">Please login again to continue working.</p>
      <Button className="btn-success" onClick={() => navigate('/')}>
        <span>Go to Login</span>
      </Button>
    </ContentLinkError>
  );
};

export default ExpirationToken;
