import styled from 'styled-components';

export const ButtonFilterContainer = styled.div`
  display: inline-block;
  .dropdown {
    margin-left: 5px;
    .dropdown-toggle{
      background-color: #18D399 !important;
      border-color: #18D399 !important; 
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      padding: 0 1rem !important;
    }

    .btn-apply {
      text-align: center;
      margin-top: 7px;
      button {
        width: 90%;
        background: #14d399;
        color: black;
        margin: 0;
      }
    }
  }
  
`;
