import React from 'react';

const LogOut = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00002 2H12C12.7367 2 13.3334 2.59667 13.3334 3.33333V12.6667C13.3334 13.4033 12.7367 14 12 14H4.00002C3.26335 14 2.66669 13.4033 2.66669 12.6667V3.33333C2.66669 2.59667 3.26335 2 4.00002 2Z"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7.33331V8.66665"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66669 3.3353V12.6653C2.66669 13.6566 3.71002 14.3013 4.59602 13.858L7.26269 12.5246C7.71469 12.298 8.00002 11.8366 8.00002 11.3313V4.66864C8.00002 4.1633 7.71469 3.70197 7.26269 3.47597L4.59602 2.14264C3.71002 1.69931 2.66669 2.34397 2.66669 3.3353Z"
        stroke="#24292E"
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogOut;
