import React from 'react';

const CrossBurger = ({ width = '21', height = '21' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L18.67 18.6667M2 18.6667L17.8365 2"
        stroke="white"
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossBurger;
