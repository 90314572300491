import React from 'react';

const Avatar = ({ width = '36', height = '36', color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0C6.272 0 0 6.272 0 14C0 21.728 6.272 28 14 28C21.728 28 28 21.728 28 14C28 6.272 21.728 0 14 0ZM14 5.6C16.702 5.6 18.9 7.798 18.9 10.5C18.9 13.202 16.702 15.4 14 15.4C11.298 15.4 9.1 13.202 9.1 10.5C9.1 7.798 11.298 5.6 14 5.6ZM14 25.2C11.158 25.2 7.798 24.052 5.404 21.168C7.77 19.32 10.752 18.2 14 18.2C17.248 18.2 20.23 19.32 22.596 21.168C20.202 24.052 16.842 25.2 14 25.2Z"
        fill={color}
      />
    </svg>
  );
};

export default Avatar;
