import React from 'react';
import { ContainerLoading } from './styles';
import Loader from '../Icons/Loader';
const Loadingsimpleteamonboardnew = () => {
  return (
    <ContainerLoading>
      <Loader/>

    </ContainerLoading>
  );
};
export default Loadingsimpleteamonboardnew;
