import styled from 'styled-components';

export const ContentLinkError = styled.div`
  background: #ffffff;
  height: 100vh;
  text-align: center;
  padding-top: 10%;

  svg {
    margin-bottom: 3%;
  }

  .btn-success {
    font-size: 14px;
    line-height: 20px;
    width: 176px;
    height: 44px;
  }
`;
