import React, {useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import { VALIDATE_EMAIL } from '../../../services/Constants/getLabels';


export const Contact = ({
  idEmployee,
  contact,
  clickParent,
  setInfo,
  info,
  edit,
  setEdit,
  updateField,
  formContact,
  toolTip,
  handleChangeContact,
  QuestionMark,
  QuestionMarkHover,
  employee,
  asterix,
  regexValidateEmail,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading,
  valPersonalEmail,
  valCompanyEmail
}) => {
  const [iconQuestionMark, setIconQuestionMark] = useState(true);
  return (
    <div className="contact" onClick={clickParent}>
      <div className="heading-info">
        <h4 className="heading-h4">Contact:</h4>
      </div>
      <div className="section-info">
        {contact?.map((item, i) => {
          let disabled = true;
          if (info?.length) {
            disabled = false;
            if (['personalEmail', 'email'].includes(item.field)) {
              disabled = !item.check(info, regexValidateEmail);
            }
          }

          return (
            <div className="generalDiv" key={i}>
              <div className="question text-bold">
                <div>
                  {item.title.includes('Email') ? (
                    <p>
                      {item.title}
                      <span className="asterix">{asterix}</span>
                    </p>
                  ) : (
                    item.title
                  )}
                </div>
              </div>
              <div className="answer text-normal">
                {item.title === edit ? (
                  <div className="edit">
                    <Form.Control
                      type={item.type}
                      value={
                        idEmployee
                          ? info !== item.placeholder
                            ? info
                            : item.value(employee[item.table])
                          : formContact[item.input]
                      }
                      onChange={e =>
                        idEmployee
                          ? setInfo(e.target.value)
                          : handleChangeContact(e.target.value, item.input)
                      }
                      autoFocus
                    />
                    {idEmployee && (
                      <div className="asideQuestionClass">
                        <div>
                          {(item.title.includes('Personal Email') ||
                            item.title.includes('Company Email')) &&
                          !item.check(info, regexValidateEmail) ? (
                            <span className="validate-email">
                              {VALIDATE_EMAIL}
                            </span>
                          ) : (
                            <Button
                              className="btn-success"
                              onClick={e => updateField(item, e.target.value)}
                              disabled={
                                (item.input === 'companyEmail' &&
                                  info.length < 1) ||
                                (item.input === 'personalEmail' &&
                                  info.length < 1) ||
                                info === item.placeholder ||
                                getEmployeeLoading ||
                                updateProfileLoading
                              }
                            >
                              Save
                            </Button>
                          )}
                        </div>
                        {item.title.includes('Phone') && (
                          <div className="questionInside">
                            <QuestionMark />
                            <div
                              className="textQuestion"
                              style={{ width: '222px', height: '46px' }}
                            >
                              {toolTip(
                                'Cell phone used for messaging (WhatsApp, etc).'
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="normal">
                    <div
                      onClick={() => {
                        setEdit(item.title);
                        setInfo(item.placeholder);
                      }}
                    >
                      <span>
                        {(employee && employee?.[item.table]
                          ? employee?.[item.table][item.field]
                          : employee?.[item.input]) ||
                          formContact[item.input] || (
                            <span className="placeHolder">
                              {item.placeholder}
                            </span>
                          )}
                      </span>
                      {formContact[item.input] &&
                        item.title.includes('Personal Email') &&
                        !valPersonalEmail && (
                          <span className="validate-email">
                            {VALIDATE_EMAIL}
                          </span>
                        )}
                      {formContact[item.input] &&
                        item.title.includes('Company Email') &&
                        !valCompanyEmail && (
                          <span className="validate-email">
                            {VALIDATE_EMAIL}
                          </span>
                        )}
                    </div>
                    {item.title.includes('Phone') && (
                      <div className="questionInside"
                      onMouseOver={() => setIconQuestionMark(false)}
                      onMouseOut={() => setIconQuestionMark(true)}
                      >
                    {iconQuestionMark ? <QuestionMark /> : <QuestionMarkHover />}
                        
                        <div
                          className="textQuestion"
                          style={{ width: '222px', height: '46px' }}
                        >
                          {toolTip(
                            'Cell phone used for messaging (WhatsApp, etc).'
                          )}
                        </div>
                      </div>
                    )}
                    {item.title.includes('Personal Email:') &&
                    employee?.informationPending?.length
                      ? employee?.informationPending?.map((element, key) => {
                          if (element.field === item.field) {
                            return (
                              <div key={key} className="check-email">
                                <span className="text-normal">{'Check '}</span>
                                <span className="text-bold">
                                  {element.value}
                                </span>
                                <span className="text-normal">
                                  {' to validate'}
                                </span>
                              </div>
                            );
                          } else return null;
                        })
                      : null}
                    {itemLoading &&
                      item.title.includes(itemLoading?.title) &&
                      (getEmployeeLoading || updateProfileLoading) && (
                        <div
                          className="questionInside"
                          style={{
                            textAlign: 'center',
                            marginLeft: '10px'
                          }}
                        >
                          <CircularProgress className="circular-progress-employee" />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
