import styled from 'styled-components';

export const HeaderEmployees = styled.div`
  .header-w{
    display:flex;
    justify-content: flex-end;
    margin: 0 17px;
    margin-top: 29px;
  }
  

  .header-w-watchEvents{
    display:flex;
    justify-content: flex-end;
    margin: 0 17px;
    margin-top: 0px;
  }
  
  .pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
  }

  .btnPagination {
    cursor: pointer;
    font-size: 18px;
    margin-right: 22px;
  }
  .buttons-paginations {
    display: flex;
  }
  .select-pagination{
    border-radius: 5px;
    border-color: #D1D5DA;
    width:64px;
    height:36px;
    appearance: none;
    background-image: url("/images/icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.9rem auto;
    padding-left: 9px;
    color: #24292e;
  }
  .text-header{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-right: 22px;
   }
  }
`;
export const ContentEmployees = styled.div`
  width: 100%;

  .search-employees {
    width: 350px;
    padding: 15px;
    background: #25446a;
    span {
      background-color: #ffffff;
      border: none;
    }
    input {
      border: none;
      padding-left: 5px;
    }
  }
  .search {
    width: 250px;
    height: 36px;
    margin-left: 20px;
  }

  .section-activity {
    background: #ffffff;
    border-radius: 10px;
    margin: 15px;

    .activities-header {
      padding: 15px;
      border-bottom: 1px solid #e1e4e8;

      .sub-heading {
        margin: 0;
      }
    }

    .section-pending-activities {
      padding: 15px;

      .pto-item {
        display: flex;
        padding: 5px 5px;
        border-radius: 5px;
      }

      .pto-item:hover {
        background: #d1dff0;
      }

      .pto-title {
        margin-bottom: 1%;
        margin-top: 5px;
      }

      .pto-icon {
        vertical-align: text-top;
      }

      .card-info {
        vertical-align: top;
        margin-left: 8px;
        flex-grow: 1;

        p {
          margin: 0;
        }
      }

      .card-buttons {
        flex-grow: 0.1;
        margin: auto;
        text-align: right;

        > button:nth-child(2) {
          margin-left: 3%;
        }
      }

      .card-icon {
        vertical-align: top;
        text-align: center;
        width: 36px;
        height: 36px;
        margin-left: 8px;

        & img {
          border-radius: 50%;
          width: 100%;
        }
      }
    }
  }
  .section-employees {
    margin: 25px 15px 30px 15px;
    background: #ffffff;
    border-radius: 8px;
  }
  .employees-inner-table {
    overflow-y: auto;
  }

  .pagination-text {
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 16px;
    cursor: default;
  }
  .pagination-text-active {
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 16px;
  }
  .symbol-left {
    margin-right: 16px;
  }
  .symbol-right {
    margin-left: 16px;
  }
  .button-pages {
    width: 32px;
    height: 32px;
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 400;
    margin: 2px;
    border-style: none;
    background-color: transparent;
  }

  .button-pages-active {
    width: 32px;
    height: 32px;
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 400;
    margin: 2px;
    background-color: #172c45;
    border-style: none;
    border-radius: 6px;
    color: white;
  }
  .prevNext {
    border-style: none;
    background-color: transparent;
    cursor: default;
    color: #959da5;
  }
  .prevNext-active {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
    color: #172c45;
  }
  .upper-table {
    width: 100%;
  }
  .employees-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    display: flex;
  }
  .second-row {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #24292e;
  }
  .green-button {
    background: #18d399;
    border-radius: 6px;
    outline: none;
    border: 0px;
    gap: 8px;
    align-items: center;
    padding: 6px 16px;
    margin-left: 10px;
  }
  .green-button:focus {
    background: #18d399 !important;
  }
  .green-button:hover {
    background: #18d399 !important;
    border: none !important;
  }
  .upper-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px;
    width: 100%;
    height: 56px;
  }
  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px;
    width: 100%;
    height: 30px;
  }
  .select {
    width: 61px;
    height: 28px;
  }
  .csv-export {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .pad-icon {
    margin-left: 6px;
    color: #24292e;
  }
  a {
    text-decoration: none;
  }
  .employeesSearchClass {
    background-color: white;
    margin-left: 13px;
    .search-employees {
      background-color: white;
      border: 1px solid #d1d5da;
      border-radius: 5px;
      width: 250px;
      height: 36px;
      padding: 0px;
    }
  }
`;
