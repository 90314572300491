import React, { useState } from 'react';
import QuestionMark from '../../../components/Icons/QuestionMark';
import QuestionMarkHover from '../../../components/Icons/QuestionMarkHover';
import Trash from '../../../components/Icons/Trash';
import Check from '../../../components/Icons/Check';
import DropdownCheckbox from '../../../components/DropdownCheckbox';
import Teams from '../../../components/Icons/Teams';
import WarningRound from '../../../components/Icons/WarningRound';
import { useNavigate } from 'react-router-dom';
import { routesManager } from '../../../services/Constants/paths';

const TeamsC = ({
  idEmployee,
  employment,
  clickParent,
  updateField,
  handleChangeEmployment,
  teams,
  multipleInfo,
  toolTip,
  CircularProgress,
  getEmployeeLoading,
  updateProfileLoading,
  itemLoading
}) => {
  const [iconQuestionMarkTeams, setIconQuestionMarkTeams] = useState(true);
  const navigate = useNavigate();
  const teamsByEmployee = teams.filter(team =>
    multipleInfo?.some(info => info.id === team.id)
  );

  function iconQuestionMarkStatus(status) {
    setIconQuestionMarkTeams(status);
  }
  return (
    <section className="teams" onClick={clickParent}>
      <div className="heading-section">
        <Teams width="18" height="18" />
        <h4 className="heading-h4 m-0">Teams</h4>
      </div>
      <div className="section-content">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="d-flex align-items-center">
              Team(s):
              <div
                className="questionInside"
                onMouseOver={() => iconQuestionMarkStatus(false)}
                onMouseOut={() => iconQuestionMarkStatus(true)}
              >
                {iconQuestionMarkTeams ? (
                  <QuestionMark />
                ) : (
                  <QuestionMarkHover />
                )}
                <div
                  className="textQuestion"
                  style={{ width: '170px', height: '28px' }}
                >
                  {toolTip(<div style={{ cursor: 'pointer' , display: 'flex' }} onClick={() => navigate(routesManager.listTeams(), { state: { site: 'teams', tab :  1  },replace: true })}>Create or edit Teams here</div>)}
                </div>
              </div>
            </div>

            <div className="info-teams mt-3">
              <span>Add teams</span> to the employee, then <span>select</span>{' '}
              one as <span>the main team</span>. (If you don't select the main
              team, the requests will go to the owner and the admin)
            </div>
          </div>
          <div className="col-sm-12 col-md-9 p-0">
            <div className="d-flex align-items-center">
              {employment
                ?.filter(
                  item => item.edit === 'list' && item.input === 'teamIds'
                )
                .map((item, i) => {
                  return (
                    <DropdownCheckbox
                      key={`teams-${i}`}
                      data={JSON.parse(
                        JSON.stringify(
                          teams.map(team => ({
                            ...team,
                            isManager: multipleInfo?.filter(
                              el => el.id === team.id
                            )?.[0]?.isManager,
                            active: multipleInfo
                              ?.map(el => el.id)
                              .includes(team.id)
                          }))
                        )
                      )}
                      setData={value =>
                        idEmployee
                          ? updateField(item, null, value)
                          : handleChangeEmployment(value, item.input)
                      }
                    />
                  );
                })}
              {itemLoading &&
                'Team(s):'.includes(itemLoading?.title) &&
                (getEmployeeLoading || updateProfileLoading) && (
                  <div
                    className="questionInside ms-2"
                    style={{
                      textAlign: 'center',
                      marginLeft: '10px'
                    }}
                  >
                    <CircularProgress className="circular-progress-employee" />
                  </div>
                )}
            </div>
            <div className="teams-grid">
              {teamsByEmployee?.length > 0 ? (
                teamsByEmployee.map(({ manager, name, mainManager }, i) => (
                  <div className="teams" key={`teams-card${i}`}>
                    <div className="teams-content">
                      <div className="teams-content-icon">
                        <Trash />
                      </div>
                      <div>
                        <h5>{name}</h5>
                        <p className="m-0">Manager: {manager}</p>
                      </div>
                    </div>
                    {mainManager ? (
                      <button className="button button_md button-main">
                        <Check /> Main
                      </button>
                    ) : (
                      <button
                        className="button button_md button_green-300"
                        disabled
                        onClick={() => {}}
                      >
                        Make main
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div className="no-teams">
                  <WarningRound width="18" height="18" />
                  No teams assigned
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamsC;
