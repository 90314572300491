import React from 'react';

const Exclamation = ({ size = '18' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16C5.13367 16 2 12.8663 2 9C2 5.13367 5.13367 2 9 2C12.8663 2 16 5.13367 16 9C16 12.8663 12.8663 16 9 16Z"
        fill="#18D399"
        stroke="#18D399"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M8.99702 5C8.58302 5 8.24702 5.336 8.25002 5.75C8.25002 6.164 8.58602 6.5 9.00002 6.5C9.41402 6.5 9.75002 6.164 9.75002 5.75C9.75002 5.336 9.41402 5 8.99702 5Z"
        fill="#24292E"
      />

      <path
        d="M9 8V11.75"
        stroke="#24292E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Exclamation;
