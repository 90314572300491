import React, { useEffect, useContext } from 'react';
import { Alert } from 'react-bootstrap';

import { AlertMessageContext } from '../../Context/AlertMessageContext';
import Bell from '../Icons/Bell';
import { AlertFragment } from './styles';

const AlertMessage = ({ errorMessage = [], successMessage = [] }) => {
  const { setErrorMessage, setSuccessMessage } = useContext(
    AlertMessageContext
  );

  useEffect(() => {
    if (errorMessage?.length) {
      setTimeout(() => {
        setErrorMessage(null, true);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage?.length) {
      setTimeout(() => {
        setSuccessMessage(null, true);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [successMessage]);

  return (
    <AlertFragment>
      {errorMessage.map((errorMsg, i) => (
        <Alert
          show={Boolean(errorMessage?.length)}
          variant="danger"
          key={`alert-${errorMsg.key}`}
          style={{ top: `${i * 60 + 80}px` }}
          className="alert-danger"
        >
          <Bell color="#CB2431" />
          <span>{errorMsg.value}</span>
        </Alert>
      ))}
      {successMessage.map((successMsg, i) => (
        <Alert
          show={Boolean(successMessage?.length)}
          variant="primary"
          key={`alert-${successMsg.key}`}
          style={{ top: `${i * 60 + 80}px` }}
          className="alert-success"
        >
          <Bell color="#22863A" />
          <span>{successMsg.value}</span>
        </Alert>
      ))}
    </AlertFragment>
  );
};

export default AlertMessage;
