import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { AccountCircle, Add } from '@material-ui/icons';
import { Form, Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';

import {
  emailValidation,
  transformUTCDateNoTime
} from '../../../services/Utils/getUtils';
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ListSubheader,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import { useNavigate } from 'react-router';

import { ContentProfile } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import { useGetProfileQuery } from '../../../graphql/container/query/profile';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import {
  DATE_FORMAT,
  EMPLOYEE_SHIRT_SIZE,
  getImageError
} from '../../../services/Constants/getDefaults';
import { personal, contact, banking } from './format';
import { UPDATE_ME_PROFILE_MUTATION } from '../../../graphql/mutations/profile';
import { formatStringDate } from '../../../services/Utils/getUtils';
import { useGetCountriesQuery } from '../../../graphql/container/query/company';
import { ModalCustomImg } from '../../../components/Modals';
import { useGetSignedUrlToPutS3Query } from '../../../graphql/container/query/files';
import { routesEmployee } from '../../../services/Constants/paths';
import DatePickerModule from '../../../components/DatePickerModal';
import {
  NOT_EDITABLE_FIELD,
  VALIDATE_EMAIL
} from '../../../services/Constants/getLabels';

const Profile = () => {
  const location = useLocation();
  const regexValidateEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isMounted = useRef(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;
  const [profile, setProfile] = useState();
  const [edit, setEdit] = useState();
  const [info, setInfo] = useState();
  const [countries, setCountries] = useState([]);
  const [modalCustomProfile, setModalCustomProfile] = useState(false);
  const [fileLoad, setFileLoad] = useState();
  const [imgPorfile, setImgProfile] = useState(null);
  const [imageProfile, setImageProfile] = useState();

  const [
    getUrl,
    { data, loading: getSignedUrlLoading }
  ] = useGetSignedUrlToPutS3Query();

  useEffect(() => {
    if (data?.getSignedUrlToPuts3 && fileLoad && !getSignedUrlLoading) {
      async function fetchData() {
        try {
          await fetch(data?.getSignedUrlToPuts3, {
            method: 'PUT',
            body: fileLoad.buffer,
            headers: {
              'Content-Type': fileLoad.type
            }
          });
          updateField(
            {
              field: 'photo',
              table: 'user'
            },
            data.getSignedUrlToPuts3?.split('?')[0]
          );
          setFileLoad();
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [data]);

  const {
    data: { getMeProfileInfo },
    loading,
    refetch
  } = useGetProfileQuery(currentUser?.employee?.id);

  const {
    data: { getCountries },
    refetch: refetchCountries
  } = useGetCountriesQuery();

  const [updateProfile, { loading: updateProfileLoading }] = useMutation(
    UPDATE_ME_PROFILE_MUTATION
  );

  useEffect(() => {
    if (getMeProfileInfo) {
      setProfile(getMeProfileInfo);
    }
  }, [getMeProfileInfo]);

  useEffect(() => {
    setImageProfile(getMeProfileInfo?.user?.photo + '?not-use-cache');
  }, [getMeProfileInfo]);

  useEffect(() => {
    if (getCountries) setCountries(getCountries);
  }, [getCountries]);

  useEffect(() => {
    if (!loading) {
      if (!isMounted.current) {
        isMounted.current = true;
      }
      setEdit();
      setInfo();
    }
  }, [loading]);

  if (!isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  const updateField = async (item, value) => {
    setModalCustomProfile(false);
    await updateProfile({
      variables: {
        employeeId: currentUser?.employee?.id,
        field: item.field,
        table: item.table,
        value: value ? value : info ? info : '',
        userId: currentUser?.id
      }
    });
    refetch();
    //document.location.reload();
  };

  const clickParent = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setEdit();
      setInfo();
    }
  };

  const handleImageEdited = ({ data, file64 }) => {
    if (data.state.file.name && !(data.state.status === 'EMPTY')) {
      const buffer = Buffer.from(
        file64.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
      const file = data.state.file;
      const fileSplited = file.name.split('.');
      const ext = fileSplited[fileSplited.length - 1];
      setFileLoad({ buffer, type: data.state.file.type });
      getUrl({
        variables: {
          id: currentUser?.employee?.id,
          contentType: data.state.file.type,
          name: `profile.${ext}`,
          section: 'employee',
          acl: 'public-read'
        }
      });
    } else if (data.state.status === 'EMPTY') {
      updateField(
        {
          field: 'photo',
          table: 'user'
        },
        null
      );
    } else {
      setModalCustomProfile(false);
    }
  };

  const onChangeValue = (value, field) => {
    if (['phone'].includes(field)) {
      const text = value.slice(1);
      const regx = /^[0-9]+$/;
      if (regx.test(text)) {
        setInfo(`+${text}`);
      } else setInfo(`+`);
    } else {
      setInfo(value);
    }
  };

  return (
    <ContentProfile>
      <div className="main-data" onClick={clickParent}>
        <div className="photo" onClick={() => setModalCustomProfile(true)}>
          {profile?.user?.photo ? (
            <img
              src={imageProfile}
              alt="Profile"
              onErrorCapture={e => (
                (e.target.onErrorCapture = null),
                (e.target.src = `${getImageError(companyId)}`)
              )}
              className="profile-photo"
              referrerPolicy="no-referrer"
              crossOrigin="anonymous"
            />
          ) : (
            <AccountCircle style={{ fontSize: 100 }} />
          )}
        </div>
        <div className="info">
          <h2 className="heading-h2">{`${profile?.firstName} ${
            profile?.lastName
          } ${
            profile?.mothersMaidenName !== null
              ? profile?.mothersMaidenName
              : ''
          }`}</h2>
          <p className="text-normal">
            {profile?.employeeRoleInfo?.name} |{' '}
            {profile?.schedule === 'FULL' ? 'Full-Time' : 'Hourly'}
          </p>
          <span className="text-normal">
            Salary: ${' '}
            {profile?.salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
          {'  '}
          <span
            className="text-underline"
            onClick={() => {
              window.history.pushState(null, null, location.pathname);
              navigate(routesEmployee.salary(), {
                replace: true
              });
            }}
          >
            View Story
          </span>
          <p className="text-normal">
            Start date:{' '}
            {moment(
              new Date(transformUTCDateNoTime(profile?.startDate))
            ).format(DATE_FORMAT)}{' '}
            -{' '}
            {new Date() > new Date(profile?.startDate)
              ? Math.abs(moment().diff(profile?.startDate, 'months'))
              : '0'}{' '}
            months
          </p>
        </div>
      </div>
      <div className="personal" onClick={clickParent}>
        <p className="text-small">Click on any field below to edit.</p>
        <div className="heading-info">
          <h4 className="heading-h4">Personal:</h4>
        </div>
        <div className="section-info">
          {profile
            ? personal?.map((item, i) => (
                <div key={i}>
                  <div className="question text-bold">
                    <p>{item.title}</p>
                  </div>
                  <div className="answer text-normal">
                    {item.title === edit ? (
                      item.edit === 'date' ? (
                        <>
                          <div className="edit-calendar">
                            <DatePickerModule
                              principalClass={'inner-Calendar'}
                              disabled={updateProfileLoading || loading}
                              onChange={value => {
                                setInfo(formatStringDate(value));
                                updateField(item, formatStringDate(value));
                              }}
                              selected={new Date(info)}
                            />
                          </div>
                          {updateProfileLoading || loading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </>
                      ) : item.edit === 'list' ? (
                        <>
                          <Select
                            id="search-select"
                            labelId="search-select-label"
                            value={info}
                            MenuProps={{ autoFocus: false }}
                            displayEmpty
                            variant="outlined"
                            className="select"
                            onChange={e =>
                              e.target.value
                                ? updateField(item, e.target.value)
                                : null
                            }
                            disabled={updateProfileLoading || loading}
                          >
                            <ListSubheader>
                              <TextField
                                autoFocus
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Add
                                        className="icon-add-input"
                                        onClick={() => updateField(item, info)}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={e =>
                                  e.target ? setInfo(e.target.value) : null
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {item.field.includes('country') ? (
                              countries.map((el, i) => (
                                <MenuItem key={i} value={el.label}>
                                  {el.label}
                                </MenuItem>
                              ))
                            ) : item.field.includes('nationality') ? (
                              countries.map((el, i) => (
                                <MenuItem key={i} value={el.label}>
                                  {el.label}
                                </MenuItem>
                              ))
                            ) : item.title.includes('T-Shirt size') ? (
                              EMPLOYEE_SHIRT_SIZE.map((el, i) => (
                                <MenuItem key={i} value={el}>
                                  {el}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={info}>{info}</MenuItem>
                            )}
                          </Select>
                          {updateProfileLoading || loading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className="edit">
                            <Form.Control
                              value={info}
                              placeholder={`${item.value(profile)}`}
                              onChange={e => setInfo(e.target.value)}
                            />

                            <Button
                              className="btn-success"
                              onClick={() => updateField(item)}
                              disabled={
                                !info ||
                                info?.length < 1 ||
                                updateProfileLoading ||
                                loading
                              }
                            >
                              Save
                            </Button>
                          </div>
                          {updateProfileLoading || loading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </>
                      )
                    ) : (
                      <div className="normal">
                        <p
                          onClick={() => {
                            setEdit(item.title);
                            setInfo(item.value(profile) || null);
                          }}
                        >
                          {item.field === 'country' ? (
                            countries.map(el =>
                              el.country.includes(item.value(profile))
                                ? el.label
                                : null
                            )
                          ) : item.value(profile) ? (
                            item.value(profile)
                          ) : (
                            <span className="placeHolder">
                              {item.placeholder}
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="contact" onClick={clickParent}>
        <div className="heading-info">
          <h4 className="heading-h4">Contact:</h4>
        </div>
        <div className="section-info">
          {profile
            ? contact.map((item, i) => {
                let disabled = true;
                if (info?.length) {
                  disabled = false;
                  if (['personalEmail', 'email'].includes(item.field)) {
                    disabled = !item.check(info, regexValidateEmail);
                  }
                }
                return (
                  <div key={i}>
                    <div className="question text-bold">
                      <p>{item.title}</p>
                    </div>
                    <div className="answer text-normal">
                      {item.title === edit ? (
                        <>
                          <div className="edit">
                            {!item.title.includes('Email Company') ? (
                              <>
                                <Form.Control
                                  placeholder={`${item.value(profile)}`}
                                  type={item.type}
                                  value={info}
                                  onChange={e =>
                                    onChangeValue(e.target.value, item.field)
                                  }
                                />
                                <div>
                                  {item.title.includes('Personal Email') &&
                                  !emailValidation(info) ? (
                                    <span className="validate-email">
                                      {VALIDATE_EMAIL}
                                    </span>
                                  ) : (
                                    <Button
                                      className="btn-success"
                                      onClick={() => updateField(item)}
                                      disabled={
                                        !info ||
                                        info?.length < 1 ||
                                        updateProfileLoading ||
                                        loading ||
                                        disabled
                                      }
                                    >
                                      Save
                                    </Button>
                                  )}
                                </div>
                              </>
                            ) : (
                              <span style={{ color: 'red' }}>
                                {NOT_EDITABLE_FIELD}
                              </span>
                            )}
                          </div>

                          {updateProfileLoading || loading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="normal">
                          <span
                            onClick={() => {
                              setEdit(item.title);
                              setInfo(item.value(profile) || null);
                            }}
                          >
                            {item.field === 'country' ? (
                              countries.map(el =>
                                el.country === item.value(profile)
                                  ? el.label
                                  : null
                              )
                            ) : item.value(profile) ? (
                              item.value(profile)
                            ) : (
                              <span className="placeHolder">
                                {item.placeholder}
                              </span>
                            )}
                          </span>
                          {profile.informationPending?.length
                            ? profile.informationPending.map((element, key) => {
                                if (element.field === item.field) {
                                  return (
                                    <div key={key} className="check-email">
                                      <span className="text-normal">
                                        {'Check '}
                                      </span>
                                      <span className="text-bold">
                                        {element.value}
                                      </span>
                                      <span className="text-normal">
                                        {' to validate'}
                                      </span>
                                    </div>
                                  );
                                } else return null;
                              })
                            : null}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="banking" onClick={clickParent}>
        <div className="heading-info">
          <h4 className="heading-h4">Banking:</h4>
        </div>
        <div className="section-info">
          {profile
            ? banking.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="question text-bold">
                      <p>{item.title}</p>
                    </div>
                    <div className="answer text-normal">
                      {`${item.table}${item.field}` === edit ? (
                        item.edit === 'list' ? (
                          <>
                            <Select
                              id="search-select"
                              labelId="search-select-label"
                              value={info}
                              MenuProps={{ autoFocus: false }}
                              displayEmpty
                              variant="outlined"
                              className="select"
                              onChange={e =>
                                e.target.value
                                  ? updateField(item, e.target.value)
                                  : null
                              }
                              placeholder={item.placeholder}
                              disabled={updateProfileLoading || loading}
                            >
                              <ListSubheader>
                                <TextField
                                  autoFocus
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Add
                                          className="icon-add-input"
                                          onClick={() =>
                                            updateField(item, info)
                                          }
                                        />
                                      </InputAdornment>
                                    )
                                  }}
                                  onChange={e =>
                                    e.target ? setInfo(e.target.value) : null
                                  }
                                  onKeyDown={e => {
                                    if (e.key !== 'Escape') {
                                      e.stopPropagation();
                                    }
                                  }}
                                />
                              </ListSubheader>
                              {item.field.includes('bankCountry') ? (
                                countries.map((el, i) => (
                                  <MenuItem key={i} value={el.label}>
                                    {el.label}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value={info}>{info}</MenuItem>
                              )}
                            </Select>
                            {updateProfileLoading || loading ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginLeft: '10px',
                                  display: 'inline-block',
                                  verticalAlign: 'middle'
                                }}
                              >
                                <CircularProgress className="circular-progress" />
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <div className="edit">
                              <Form.Control
                                value={info || ''}
                                placeholder={`${item.value(profile)}`}
                                onChange={e => setInfo(e.target.value)}
                              />

                              <Button
                                className="btn-success"
                                onClick={() => updateField(item)}
                                disabled={
                                  !info ||
                                  info?.length < 1 ||
                                  updateProfileLoading ||
                                  loading
                                }
                              >
                                Save
                              </Button>
                            </div>
                            {updateProfileLoading || loading ? (
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginLeft: '10px',
                                  display: 'inline-block',
                                  verticalAlign: 'middle'
                                }}
                              >
                                <CircularProgress className="circular-progress" />
                              </div>
                            ) : null}
                          </>
                        )
                      ) : (
                        <div className="normal">
                          <p
                            onClick={() => {
                              setEdit(`${item.table}${item.field}`);
                              setInfo(item.value(profile) || null);
                            }}
                          >
                            {item.field === 'bankCountry' ? (
                              countries.map(el =>
                                el.label === item.value(profile) ||
                                el.country === item.value(profile)
                                  ? el.label
                                  : null
                              )
                            ) : item.value(profile) ? (
                              item.value(profile)
                            ) : (
                              <span className="placeHolder">
                                {item.placeholder}
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <ModalCustomImg
        image={imageProfile}
        show={modalCustomProfile}
        size={400}
        onClose={() => setModalCustomProfile(false)}
        onConfirm={handleImageEdited}
      />
    </ContentProfile>
  );
};

export default Profile;
