import styled from "styled-components";

export const CalendarContent = styled.div`
  .react-datepicker {
    border: 1px solid #18d399;
    border-radius: 6px;
    font-size: 20px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day-names{
    border-top: 1px solid #e1e4e8;
    padding-top: 6px;
    justify-content: space-evenly;
  }

  .react-datepicker__day-name {
    padding: 0 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }

  .datepicker-input {
    padding: 0.5rem;
    border: 1px solid #444d56;
    border-radius: 5px;
  }
  .monthCalendar .react-datepicker-wrapper input, 
  .yearCalendar .react-datepicker-wrapper input {
    padding: 0.5rem;
    border: none;
    background: #f0f4f8;
    border-radius: 6px;
    width: 90px;
    text-align: center
  }

  .monthCalendar .react-datepicker-wrapper input:focus,
  .yearCalendar .react-datepicker-wrapper input:focus {
    outline: 1px solid #444D56;
    background: #D7E1EA !important;
  }

  .react-datepicker__header {
    border: none !important;
    padding-top: 0px !important;
  }

  .react-datepicker__month-container{
    width: auto;
  }

  .monthCalendar .react-datepicker__header {
    border-bottom: 1px solid #e1e4e8;
    height: auto !important;
    padding: 0px !important;
  }

  .yearCalendar .react-datepicker__header {
    border-bottom: 1px solid #e1e4e8;
    height: auto !important;
    padding: 10px !important;
  }

  .react-datepicker__day {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: center;
    margin: 0;
    width: 36px;
  }

  .react-datepicker__day:hover {
    background-color: #f0f4f8;
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected:hover {
    background-color: #24292e;
    border-radius: 50%;
    color: #fff;
  }
  .monthCalendar .react-datepicker__month--selected,
  .monthCalendar .react-datepicker__month--selected:hover {
    background: #24292e;
    border-radius: 6px;
    color: #fff;
    font-weight: 700;
    line-height: 22px;
  }

  .react-datepicker__year-text {
    padding: 4px 0;
  }

  .react-datepicker__year-text--selected {
    background: #24292e;
    border-radius: 6px;
    color: #fff;
    font-weight: 700;
    padding: 4px 0;
    margin: 0;
  }

  .react-datepicker__year-text--selected:hover{
    background: #24292e;
  }

  .react-datepicker__year-text--disabled {
    display: none !important;
  }
`;