import gql from 'graphql-tag';

export const CREATE_EMPLOYEE_SEPARATE = gql`
  mutation CREATE_EMPLOYEE_SEPARATE(
    $availableForRehire: Float!
    $createdAt: DateTime!
    $employee: String!
    $note: String!
    $separationDate: DateTime!
    $status: String!
  ) {
    createEmployeeSeparated(
      createEmployeeSeparateInput: {
        availableForRehire: $availableForRehire
        createdAt: $createdAt
        employee: $employee
        note: $note
        separationDate: $separationDate
        status: $status
      }
    )
  }
`;

export const DELETE_EMPLOYEE_SEPARATE = gql`
  mutation DELETE_EMPLOYEE_SEPARATE($employeeId: String!) {
    deleteEmployeeSeparated(employeeId: $employeeId)
  }
`;
