import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { AccountCircle, Add } from '@material-ui/icons';
import { Form, Button } from 'react-bootstrap';
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ListSubheader,
  CircularProgress
} from '@material-ui/core';

import { AppContext } from '../../../Context/App/AppContext';
import { ContentCompanyDetail } from './styles';
import { companyInfo } from './format';
import { useGetCitiesOfTheEmployeesQuery } from '../../../graphql/container/query/profile';
import {
  useGetCompanyDetailById,
  useGetCountriesQuery
} from '../../../graphql/container/query/company';
import Loadingsimpleteamonboardnew from '../../../components/Loader';
import { UPDATE_COMPANY_DETAILS_MUTATION } from '../../../graphql/mutations/CompanyUpdate';
import { getImageError } from '../../../services/Constants/getDefaults';
import { ModalCustomImg } from '../../../components/Modals';
import { useGetSignedUrlToPutS3Query } from '../../../graphql/container/query/files';

const CompanyDetail = () => {
  const isMounted = useRef(false);
  const { currentUser, setDontNavigate } = useContext(AppContext);
  const companyId = currentUser?.companyRole[0].to;

  const [companyData, setCompanyData] = useState();
  const [edit, setEdit] = useState();
  const [info, setInfo] = useState('');
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryCompany, setCountryCompany] = useState();
  const [modalCustomProfile, setModalCustomProfile] = useState(false);
  const [fileLoad, setFileLoad] = useState();
  const [imageProfile, setImageProfile] = useState();

  const {
    data: { getCompanyDetailById },
    loading: loadingCompany,
    refetch: refetchCompany
  } = useGetCompanyDetailById(companyId);

  const [
    getUrl,
    { data, loading: getSignedUrlLoading }
  ] = useGetSignedUrlToPutS3Query();

  const [updateCompany, { loading: updateCompanyLoading }] = useMutation(
    UPDATE_COMPANY_DETAILS_MUTATION
  );

  useEffect(() => {
    if (isMounted.current) {
      if (!updateCompanyLoading) {
        refetchCompany();
      }
    }
    // eslint-disable-next-line
  }, [updateCompanyLoading]);

  useEffect(() => {
    if (!loadingCompany) {
      if (!isMounted.current) {
        isMounted.current = true;
      }
      setInfo();
      setEdit();
    }
    // eslint-disable-next-line
  }, [loadingCompany, isMounted.current]);

  useEffect(() => {
    setImageProfile(getCompanyDetailById?.logo + '?not-use-cache');
  }, [getCompanyDetailById]);

  useEffect(() => {
    if (getCompanyDetailById) {
      setCompanyData(getCompanyDetailById);
    }
  }, [getCompanyDetailById]);

  const {
    data: { getCitiesOfTheEmployees },
    refetch: refetchCities
  } = useGetCitiesOfTheEmployeesQuery();

  const {
    data: { getCountries },
    refetch: refetchCountries
  } = useGetCountriesQuery();

  useEffect(() => {
    if (getCitiesOfTheEmployees) setCities(getCitiesOfTheEmployees);
  }, [getCitiesOfTheEmployees]);

  useEffect(() => {
    if (getCountries) setCountries(getCountries);
  }, [getCountries]);

  useEffect(() => {
    if (data?.getSignedUrlToPuts3 && fileLoad && !getSignedUrlLoading) {
      async function fetchData() {
        try {
          await fetch(data?.getSignedUrlToPuts3, {
            method: 'PUT',
            body: fileLoad.buffer,
            headers: {
              'Content-Type': fileLoad.type
            }
          });
          updateField(
            { field: 'logo' },
            data.getSignedUrlToPuts3?.split('?')[0]
          );
          setFileLoad();
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (companyData?.country) {
      const selectedCountry = countries?.filter(
        el => el?.country === companyData.country
      );
      setCountryCompany(selectedCountry?.[0]?.label);
    }
  }, [companyData, countries]);

  const updateField = async (item, value) => {
    setModalCustomProfile(false);
    setDontNavigate(true);
    updateCompany({
      variables: {
        id: companyId,
        field: item.field,
        value: value ? value : info
      }
    });
    refetchCities();
    refetchCountries();
  };

  const clickParent = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setEdit();
      setInfo();
    }
  };

  const handleImageEdited = ({ data, file64 }) => {
    if (data.state.file.name && !(data.state.status === 'EMPTY')) {
      const buffer = Buffer.from(
        file64.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
      const file = data.state.file;
      const fileSplited = file.name.split('.');
      const ext = fileSplited[fileSplited.length - 1];
      setFileLoad({ buffer, type: data.state.file.type });
      getUrl({
        variables: {
          id: companyId,
          contentType: data.state.file.type,
          name: `logo.${ext}`,
          section: 'companyLogos',
          acl: 'public-read'
        }
      });
    } else if (data.state.status === 'EMPTY') {
      updateField({ field: 'logo' }, null);
    } else {
      setModalCustomProfile(false);
    }
  };

  if (loadingCompany && !isMounted.current) {
    return (
      <div className="fullPageLoader">
        <Loadingsimpleteamonboardnew loop={true} />
      </div>
    );
  }

  return (
    <ContentCompanyDetail>
      <div className="company-header" onClick={clickParent}>
        <div className="photo" onClick={() => setModalCustomProfile(true)}>
          {companyData?.logo ? (
            <img
              src={imageProfile}
              alt="Profile"
              onErrorCapture={e =>
                (e.target.onErrorCapture = null)(
                  (e.target.src = `${getImageError(companyId)}`)
                )
              }
              referrerPolicy="no-referrer"
              crossOrigin="anonymous"
            />
          ) : (
            <AccountCircle style={{ fontSize: 100 }} />
          )}
        </div>
        <div className="info">
          <h4 className="heading-h2">{companyData?.name}</h4>
          <span className="text-normal">{companyData?.email}</span>
        </div>
      </div>
      <div className="company-data" onClick={clickParent}>
        <p className="text-small">Click on any field below to edit.</p>
        <div className="heading-info">
          <h4 className="heading-h4">Company info:</h4>
        </div>
        <div className="section-info">
          {companyData
            ? companyInfo?.map((item, i) => (
                <div key={i}>
                  <div className="question text-bold">
                    <p>{`${item.title}:`}</p>
                  </div>
                  <div className="answer text-normal">
                    {item.title === edit ? (
                      item.edit === 'list' ? (
                        <>
                          <Select
                            id="search-select"
                            labelId="search-select-label"
                            value={info || ''}
                            MenuProps={{ autoFocus: false }}
                            displayEmpty
                            variant="outlined"
                            className="select"
                            onChange={e =>
                              e.target.value
                                ? updateField(item, e.target.value)
                                : null
                            }
                            disabled={loadingCompany || updateCompanyLoading}
                          >
                            <ListSubheader>
                              <TextField
                                autoFocus
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Add
                                        className="icon-add-input"
                                        onClick={() => updateField(item, info)}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={e =>
                                  e.target ? setInfo(e.target.value) : null
                                }
                                onKeyDown={e => {
                                  if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {item.field.includes('city') ? (
                              cities.map((el, i) => (
                                <MenuItem key={i} value={el.city}>
                                  {el.city}
                                </MenuItem>
                              ))
                            ) : item.field.includes('country') ? (
                              countries.map((el, i) => (
                                <MenuItem key={i} value={el.country}>
                                  {el.label}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={info}>{info}</MenuItem>
                            )}
                          </Select>
                          {loadingCompany || updateCompanyLoading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px',
                                display: 'inline-block'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="edit">
                          <Form.Control
                            value={info || ''}
                            placeholder={`${item.value(companyData)}`}
                            onChange={e => setInfo(e.target.value)}
                          />
                          {/* TODO recordar validar codigo postal, ya que no existe el campo en DB */}
                          <Button
                            className="btn-success"
                            onClick={() => updateField(item)}
                            disabled={
                              info === companyData[item.field] ||
                              info.length === 0 ||
                              loadingCompany ||
                              updateCompanyLoading
                            }
                          >
                            Save
                          </Button>
                          {loadingCompany || updateCompanyLoading ? (
                            <div
                              style={{
                                textAlign: 'center',
                                marginLeft: '10px'
                              }}
                            >
                              <CircularProgress className="circular-progress" />
                            </div>
                          ) : null}
                        </div>
                      )
                    ) : (
                      <div className="normal">
                        <p
                          onClick={() => {
                            setEdit(item.title);
                            setInfo(item.value(companyData) || '');
                          }}
                        >
                          {item.field === 'country'
                            ? countryCompany
                            : item.value(companyData)
                            ? item.value(companyData)
                            : '-'}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="company-delete" onClick={clickParent}>
        <Button className="btn-danger" disabled={true}>
          {'Delete Company'}
        </Button>
        <div>
          <p className="heading-h4">Delete Company</p>
          <span className="text-normal">
            If you do this all your data will be erased and you will not be able
            to recover it.
          </span>
        </div>
      </div>
      <ModalCustomImg
        show={modalCustomProfile}
        size={400}
        onClose={() => setModalCustomProfile(false)}
        onConfirm={handleImageEdited}
        image={imageProfile}
      />
    </ContentCompanyDetail>
  );
};

export default CompanyDetail;
