import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  TIME_OFF_RULES_QUERY,
  GET_PTO_RULES_QUERY
} from '../../../queries/timeOffRules';

export const useTimeOffAccrualsQuery = companyId =>
  useLazyQuery(TIME_OFF_RULES_QUERY, {
    variables: { id: companyId }
  });

export const useGetPtoRulesByCompanyQuery = companyId => {
  return useQuery(GET_PTO_RULES_QUERY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};

export const useGetPtoRulesByCompanyLazyQuery = companyId => {
  return useLazyQuery(GET_PTO_RULES_QUERY, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
};
