import React, { useEffect } from 'react';
import ArrowLeft from '../../components/Icons/ArrowLeft';
import ArrowRight from '../../components/Icons/ArrowRight';

const Pagination = ({
  offset,
  limit,
  setOffset,
  setLimit,
  allElements,
  handlePage,
  currentPage,
  setCurrentPage
}) => {
  const prev = offset - (limit - 1) > 0;
  const amountLimit = [10, 25, 50, 100];
  let next = currentPage * limit < allElements?.length;

  useEffect(() => {
    document.getElementById("selectLimit").selectedIndex = 0
  }, [allElements]);

  const handleLimit = value => {
    let numLimit = parseInt(value);
    setOffset(0)
    setCurrentPage(1)
    setLimit(numLimit);
  };

  return (
    <div className="header-w">
      <div className="pagination">
        <span className="text-header">
          {offset === 0 ? 1 : offset} -{' '}
          {offset + allElements?.slice(offset, offset + limit).length} of{' '}
          {allElements?.length}
        </span>
        <div className="buttons-paginations">
          <div
            className="btnPagination"
            onClick={() => (prev ? handlePage(currentPage - 1) : null)}
          >
            <ArrowLeft />
          </div>
          <span> </span>
          <div
            className="btnPagination"
            onClick={() => (next ? handlePage(currentPage + 1) : null)}
          >
            <ArrowRight />
          </div>
        </div>
        <form>
          <select
            id="selectLimit"
            className="select select-pagination"
            onChange={e => handleLimit(e.target.value)}
          >
            {amountLimit.map((el, i) => {
              return <option key={i}>{el}</option>;
            })}
          </select>
        </form>
      </div>
    </div>
  );
};

export default Pagination;
