import React from 'react';

const Keys = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2725 7.00619C11.2725 6.84869 11.145 6.72119 10.9912 6.72119C10.8337 6.72119 10.71 6.84119 10.71 6.99869C10.71 7.14869 10.83 7.27994 10.9912 7.27619C11.1412 7.27619 11.2725 7.14869 11.2725 6.99494"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37423 11.0886L8.60174 11.8626H7.22174V12.9576H6.11924V14.3301L5.08424 15.3576H2.60999V12.8751L6.89999 8.57014C6.29249 6.99514 6.61499 5.15314 7.88324 3.88639C9.59324 2.17264 12.366 2.17264 14.0707 3.88639C15.7807 5.60014 15.7807 8.37889 14.0707 10.0934C12.7882 11.3684 10.9282 11.6909 9.34574 11.0609L9.37423 11.0886Z"
        stroke="#24292E"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Keys;
