import React, { useState, useContext, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';
import { SearchEmployeeContainer } from '../../../components/SearchEmployee/styles.js';
import { FormControl, InputGroup } from 'react-bootstrap';
import Search from '../../../components/Icons/Search';
import { ContentRoles, HeaderRoles } from './styles';
import { AppContext } from '../../../Context/App/AppContext';
import TableRoles from './TableRoles';
import { useRolesByCompanyQuery } from '../../../graphql/container/query/roles';
import LoadingsImplementTeamOnBoardNew from '../../../components/Loader';
import {
  ADD_ROLE_TO_COMPANY,
  DELETE_ROLE_FROM_COMPANY,
  UPDATE_ROLE_MUTATION,
  UPDATE_ROLE_DELETE_MUTATION
} from '../../../graphql/mutations/roles';
import {
  ModalAlert,
  ModalEditRole,
  ModalAddRole
} from '../../../components/Modals';
import { readCookies } from '../../../services/Utils/getUtils.js';
import Pagination from '../../../components/Pagination/index.jsx';
import { ROLE_EXIST } from '../../../services/Constants/getLabels';

const initialsColumns = [
  {
    name: 'Role',
    key: 'positionName',
    active: true
    // width: '40%'
  },
  {
    name: 'Low',
    key: 'low',
    active: true
  },
  {
    name: 'Mid',
    key: 'mid',
    active: true
  },
  {
    name: 'High',
    key: 'high',
    active: true
  },
  {
    name: '',
    key: 'action',
    active: true
    // width: '10%'
  }
];

const Roles = () => {
  const isMounted = useRef(false);
  const { currentUser } = useContext(AppContext);
  const [cacheFiltersRoles, setCacheFiltersRoles] = useState(
    readCookies('Roles')
  );
  const companyId = currentUser?.companyRole[0].to;
  const [allRoles, setAllRoles] = useState([]);

  const [nameNewRole, setNameNewRole] = useState('');
  const [isActiveAlert, setIsActiveAlert ]= useState(false);
  const [columnsTable] = useState(initialsColumns);
  const [selected, setSelected] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState({});
  const [openModalAddRole, setOpenModalAddRole] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModaldontWork, setopenModaldontWork] = useState(false);
  const [countEmployeeRoles, setcountEmployeeRoles] = useState(0);
  const [allRolesSearch, setAllRolesSearch] = useState([]);
  const [sortChoiceRole, setSortChoiceRole] = useState(
    cacheFiltersRoles?.[2] ? JSON.parse(cacheFiltersRoles[2]) : true
  );
  const [itemRole, setItemRole] = useState(
    cacheFiltersRoles?.[1] ? JSON.parse(cacheFiltersRoles[1]) : ''
  );

  // Pagination Data
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const handlePage = num => {
    setOffset(limit * (num - 1));
    setCurrentPage(num);
  };
  // Finish Pagination Data

  const {
    data: { getAllRolesByCompanyId },
    loading: loadingRoles,
    refetch: refetchRoles
  } = useRolesByCompanyQuery(companyId);

  const [createNewRole, { loading: createRoleLoading }] = useMutation(
    ADD_ROLE_TO_COMPANY
  );

  const [removeRole, { loading: deleteRoleLoading }] = useMutation(
    DELETE_ROLE_FROM_COMPANY
  );

  const [updateRole, { loading: updateRoleLoading }] = useMutation(
    UPDATE_ROLE_MUTATION
  );

  const [updateRoleDelete, { loading: updateRoleDeleteLoading }] = useMutation(
    UPDATE_ROLE_DELETE_MUTATION
  );

  useEffect(() => {
    if (!loadingRoles) {
      if (!isMounted.current) {
        isMounted.current = true;
      }
      setNameNewRole('');
    }
    // eslint-disable-next-line
  }, [loadingRoles, isMounted.current]);

  useEffect(() => {
    if (getAllRolesByCompanyId) {
      if (itemRole !== '') {
        handleSortRoles(getAllRolesByCompanyId);
      } else {
        setAllRoles(
          getAllRolesByCompanyId?.filter(
            el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db'
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [getAllRolesByCompanyId]);

  useEffect(() => {
    const stateToSearch =
      allRolesSearch.length > 0 ? allRoles : getAllRolesByCompanyId;
    handleSortRoles(stateToSearch);
    // eslint-disable-next-line
  }, [sortChoiceRole, cacheFiltersRoles, itemRole]);

  const handleSortRoles = (data, value = 'regular') => {
    if (itemRole === 'Role') {
      const sortRolesName = sortChoiceRole
        ? data &&
          data
            .filter(el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db')
            .sort((a, b) => {
              const elementA = a?.name.toLowerCase();

              const elementB = b?.name.toLowerCase();

              if (elementA > elementB) {
                return 1;
              }
              if (elementA < elementB) {
                return -1;
              } else {
                return 0;
              }
            })
        : data &&
          data
            .filter(el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db')
            .sort((a, b) => {
              const elementA = a?.name.toLowerCase();

              const elementB = b?.name.toLowerCase();

              if (elementA < elementB) {
                return 1;
              }
              if (elementA > elementB) {
                return -1;
              } else {
                return 0;
              }
            });

      value === 'search'
        ? setAllRolesSearch(sortRolesName)
        : setAllRoles(sortRolesName);
    } else {
      const sortRolesItem = sortChoiceRole
        ? data &&
          data
            .filter(el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db')
            .sort((a, b) => {
              const elementA =
                itemRole === 'Low'
                  ? a.low
                  : itemRole === 'Mid'
                  ? a.mid
                  : a.high;
              const elementB =
                itemRole === 'Low'
                  ? b.low
                  : itemRole === 'Mid'
                  ? b.mid
                  : b.high;

              return elementA - elementB;
            })
        : data &&
          data
            .filter(el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db')
            .sort((a, b) => {
              const elementA =
                itemRole === 'Low'
                  ? a.low
                  : itemRole === 'Mid'
                  ? a.mid
                  : a.high;
              const elementB =
                itemRole === 'Low'
                  ? b.low
                  : itemRole === 'Mid'
                  ? b.mid
                  : b.high;

              return elementB - elementA;
            });
      value === 'search'
        ? setAllRolesSearch(sortRolesItem)
        : setAllRoles(sortRolesItem);
    }
    return value === 'search' ? allRolesSearch : allRoles;
  };

  useEffect(() => {
    if (itemRole) {
      saveCookies();
      setCacheFiltersRoles(readCookies('Roles'));
    }
    // eslint-disable-next-line
  }, [itemRole, sortChoiceRole]);

  useEffect(() => {
    if (isMounted.current) {
      if (!createRoleLoading || !deleteRoleLoading || !updateRoleLoading  || !updateRoleDeleteLoading) {
        refetchRoles();
      }
    }
    // eslint-disable-next-line
  }, [createRoleLoading, deleteRoleLoading, updateRoleLoading, updateRoleDeleteLoading]);

  function saveCookies() {
    const dateExpires = `31 Dec ${new Date().getFullYear() - 1} 23:59:59 GMT`;
    document.cookie = `Roles=; expires=${dateExpires}`;
    document.cookie = `Roles=***${JSON.stringify(itemRole)}***${JSON.stringify(
      sortChoiceRole
    )}; expires=31 Dec ${new Date().getFullYear() + 1} 23:59:59 GMT`;
  }

  const submitNewRole = () => {

    const roleExistInActives = allRoles.find(role => role.name.toLowerCase() === nameNewRole.toLowerCase());
    if(!roleExistInActives) {

    updateRoleDelete({
        variables: {
          nameRole: nameNewRole,
          status:  'ACTIVE'
        }
      }).then(resultUpdateRolDelete => {

      if(!resultUpdateRolDelete.data.editRoleDelete) {

      createNewRole({
        variables: {
          nameRole: nameNewRole,
          companyId
        }
      });

        }
      })

       setOpenModalAddRole(false);
    }
    else {
      setIsActiveAlert(true)
    }
  };

  const deleteRole = async () => {
    await removeRole({
      variables: {
        list: selected
      }
    });
    setOpenModalDelete(false);
    setSelected([]);
  };

  const verifyDeleteRole = async () => {
    var filterData = [];
    allRoles.filter(r => {
      return selected.filter(s => {
        if (r.id === s.id) {
          filterData.push(r);
        }
      });
    });

    const employees = filterData.filter(f => f.totalEmployee > 0);
    if (employees.length > 0) {
      var reduce = filterData.reduce(
        (acum, actual) => acum + actual.totalEmployee,
        0
      );
      setcountEmployeeRoles(reduce);
      setopenModaldontWork(true);
    } else {
      setOpenModalDelete(true);
    }
  };

  const editRole = (role, status) => {
    updateRole({
      variables: {
        id: role.id,
        nameRole: role.name,
        status:  status
      }
    });
    setRoleToEdit({});
    setOpenModalEdit(false);
  };

  if (loadingRoles && !isMounted.current) {
    return (
      <div className="fullPageLoader">
        <LoadingsImplementTeamOnBoardNew loop={true} />
      </div>
    );
  }

  return (
    <div>
      <HeaderRoles>
        <Pagination
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          allElements={allRoles}
          handlePage={handlePage}
          currentPage={currentPage}
          setCurrentPage= {setCurrentPage}
        />
      </HeaderRoles>
      <ContentRoles>
        <div className="roles-header">
          <div className="title-search">
            <div>
              <h3 className="sub-heading-role">Roles</h3>
            </div>
            <div className="content-search">
              <SearchRoles
                setAllRoles={setAllRoles}
                rolesData={getAllRolesByCompanyId.filter(
                  el => el.id !== 'a20273e6-ed8a-42be-add4-f3cd71ef48db'
                )}
                handleSortRoles={handleSortRoles}
                handlePage={handlePage}
              />
            </div>
          </div>
          <div className="buttons-search">
            {selected.length ? (
              <div>
                <Button
                  className="btn-danger trash"
                  hidden={!selected.length}
                  onClick={() => verifyDeleteRole()}
                >
                  <span>{'Delete'}</span>
                </Button>
              </div>
            ) : null}
            <div>
              <Button
                className="btn-success success"
                onClick={() => setOpenModalAddRole(true)}
              >
                Create New
              </Button>
            </div>
          </div>
        </div>

        <div className="create-roles-table">
          <TableRoles
            columns={columnsTable}
            dataSource={allRoles?.slice(offset, offset + limit)}
            setSortChoiceRole={setSortChoiceRole}
            sortChoiceRole={sortChoiceRole}
            setItemRole={setItemRole}
            loading={createRoleLoading || loadingRoles || updateRoleLoading}
            selected={selected}
            setSelected={setSelected}
            onClickEdit={item => {
              setRoleToEdit(item);
              setOpenModalEdit(true);
            }}
          />
        </div>
        <ModalEditRole
          role={roleToEdit}
          show={openModalEdit}
          onClose={() => setOpenModalEdit(false)}
          onConfirm={res => editRole(res, 'ACTIVE')}
        />
        <ModalAlert
          show={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          onConfirm={deleteRole}
          confirmLabel="Delete Role"
          content={
            <div className="content">
              <p className="text-normal">
                Are you sure you want to delete the next roles?:
              </p>
              <ul>
                {selected?.map(item => {
                  return (
                    <li
                      key={`li-${item.id}`}
                      className="text-normal"
                      style={{ textAlign: 'initial' }}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        />
        <ModalAddRole
          show={openModalAddRole}
          noIcon
          onClose={() => setOpenModalAddRole(false)}
          onConfirm={() => submitNewRole()}
          confirmLabel="Create"
          closeButton={false}
          content={
            <div className="content">
              <h2 className="text-create-role-title">Create Role</h2>
              <h2 className="text-create-role-name">Role name</h2>
              <Form.Control
                className="create-role-input"
                value={nameNewRole || ''}
                type="text"
                placeholder={`Example: Developer`}
                onChange={e => setNameNewRole(e.target.value)}
              />
            </div>
          }
        />
        <ModalAlert
          show={openModaldontWork}
          ShowBtCancel={false}
          onConfirm={() => setopenModaldontWork(false)}
          confirmLabel="Go back to roles"
          alertClass="svgBox"
          oneButton
          buttonSuccess="buttonDeleteRole"
          content={
            <div className="content" style={{ padding: '0 8%' }}>
              You are not able to delete this role because there are{' '}
              {countEmployeeRoles} employees assigned to it.
            </div>
          }
        />
        <ModalAlert
        show={isActiveAlert}
        onClose={() => setIsActiveAlert(false)}
        content={ ROLE_EXIST }
        buttonCancel={'Try another name'}
        confirmButton={false}
        buttonCancelStyle={'buttonAlertPto'}
        alertClass={'alertIcon'}
        oneButton
      />
      </ContentRoles>
    </div>
  );
};

const SearchRoles = ({
  setAllRoles,
  rolesData,
  handlePage,
  handleSortRoles
}) => {
  const [labelSearch, setLabelSearch] = useState('');

  const searchRole = search => {
    if (search.trim() === '') {
      const sortRoles = handleSortRoles(rolesData, 'search');
      handlePage(1);
      setAllRoles(sortRoles);
    } else {
      const sortRoles = handleSortRoles(rolesData, 'search');
      const newState = sortRoles.filter(obj => {
        if (obj?.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
          return obj;
        } else {
          return false;
        }
      });
      handlePage(1);
      setAllRoles(newState);
    }
  };
  return (
    <div className="search">
      <SearchEmployeeContainer>
        <div>
          <InputGroup className="input-group-search clickLabel">
            <InputGroup.Text id="input-employee" className="color-box">
              <Search />
            </InputGroup.Text>
            <FormControl
              onChange={({ target }) => {
                setLabelSearch(target.value);
                searchRole(target.value);
              }}
              placeholder={'Search Role'}
              value={labelSearch}
              aria-label="Search employee"
              className="input-search"
            />
          </InputGroup>
        </div>
      </SearchEmployeeContainer>
    </div>
  );
};

export default Roles;
