import styled from 'styled-components';

export const ContentPtoHolidays = styled.div`
  margin: 15px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 5px;

  .pto-holidays-company-subheadears{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .pto-holidays-header,
  .pto-holidays-header-middle {
    padding: 8px 21px;
    border-bottom: 1px solid #e1e4e8;
    margin-top: 31px;
    .sub-heading {
      margin: 0;
    }
  }

  .pto-holidays-rules {
    border-bottom: 1px solid #e1e4e8;

    .save-rules {
      text-align: left;
      padding: 5px 15px 15px 21px;
      button {
        padding: 5px 20px;
      }
    }
    > div {
      padding: 15px 0 0 0;
    }
    > div:nth-child(3) {
      padding: 15px 0 15px 0;
    }
    .rule {
      > div:nth-child(2) {
        display: inline-block;
        width: 90%;
        padding: 2px;
        vertical-align: middle;
        p {
          margin: 0.5% 0;
        }
      }
      > div:nth-child(1) {
        width: 10%;
        vertical-align: top;
        display: inline-block;
        padding: 2px 0 2px 21px;
        text-align: left;
        vertical-align: middle;

        input {
          height: 35px;
          width: 80%;
        }

        // .input-rules {
        //   -webkit-appearance: textfield;
        //   -moz-appearance: textfield;
        //   appearance: textfield;
        //   border: solid red;
        // }
        // input[type='number']::-webkit-inner-spin-button,
        // input[type='number']::-webkit-outer-spin-button {
        //   width: 20%;
        //   -webkit-appearance: none;
        //   -moz-appearance: none;
        //   appearance: none;
        //   margin: 0;
        //   border: solid red;
        // }
      }
    }

    .loading-rules {
      text-align: center;
      padding: 100px 20px !important;
    }
  }

  .pto-holidays-company-values {
    border-bottom: 1px solid #e1e4e8;
    padding: 20px 20px 26px 21px;

    > div {
      display: inline-block;
    }
    > div:nth-child(1) {
      width: 30%;
      margin-right: 2%;
    }

    > div:nth-child(2) {
      .inner-Calendar{
        border: 1px solid #ced4da;
        width: 100%;
      }
      width: 30%;
      margin-right: 2%;
    }

    > div:nth-child(3) {
      width: 20%;
    }

    > div:nth-child(4) {
      text-align: right;
    }
  }

  .noData {
    width: 100%;
    text-align: center;
    height: 250px;
    padding: 100px;

    svg {
      margin-bottom: 20px;
    }
  }
`;
