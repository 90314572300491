import React from 'react';

const Heart = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 3.69374C9.57752 3.01349 10.5825 2.24624 12.1125 2.24624C14.79 2.24624 16.5825 4.75874 16.5825 7.10174C16.5825 11.9962 10.4888 15.7462 8.98877 15.7462C7.48877 15.7462 1.39502 11.9962 1.39502 7.10174C1.39502 4.75499 3.18752 2.24249 5.86502 2.24249C7.39502 2.24249 8.40227 2.99999 8.98352 3.68249L9.00002 3.69374Z"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 8.625H6.915L8.16 6.75L9.825 10.5L11.07 8.625H12.735"
        stroke="white"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Heart;
