import gql from 'graphql-tag';


export const GET_PERMISSIONS = gql`
{   
   query GetPermissions {
    EmployeePermissions {
        id
        description
    }
   }
}
`;

export const UPDATE_PERMISSION_MUTATION = gql`
  mutation UPDATE_PERMISSION_MUTATION(
    $employeeId: String!
    $permissionId: String!
  ) {
    updatePermission(
      employeeId: $employeeId
      permissionId: $permissionId
    )
  }
`;

export const UPDATE_PERMISSION_EMPLOYEES_MUTATION = gql`
  mutation UPDATE_PERMISSION_MUTATION(
    $employeesList: [String!]!
    $permissionId: String!
  ) {
    updatePermissionManyEmployees(
      employeesList: $employeesList
      permissionId: $permissionId
    )
  }
`;