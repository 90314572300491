import gql from 'graphql-tag';

export const TIME_OFF_RULES_QUERY = gql`
  query TIME_OFF_RULES_QUERY($id: String!) {
    timeOffRules(where: { ownerCompany: { id: { equals: $id } } }) {
      id
      initialDaysOff
      daysAdvanceToRequestTimeOff
      rollOverDaysPerYear
      daysPerMonth
      createdAt
      updatedAt
    }
  }
`;

export const GET_PTO_RULES_QUERY = gql`
  query GET_PTO_RULES_QUERY($companyId: String!) {
    getPtoRulesByCompany(companyId: $companyId) {
      timeOffRulles {
        id
        daysAdvanceToRequestTimeOff
        daysPerMonth
        initialDaysOff
        ownerCompany
        rollOverDaysPerYear
      }
      holidays {
        id
        companyId
        name
        date
      }
    }
  }
`;

export const UPDATE_PTO_RULES_MUTATION = gql`
  mutation UPDATE_PTO_RULES_MUTATION(
    $id: String!
    $minAdvanceDays: Float!
    $initialPto: Float!
    $rollOverDays: Float!
    $accruedDays: Float!
  ) {
    updatePtoRules(
      UpdatePtoInput: {
        id: $id
        minAdvanceDays: $minAdvanceDays
        initialPto: $initialPto
        rollOverDays: $rollOverDays
        accruedDays: $accruedDays
      }
    )
  }
`;

export const CREATE_HOLIDAY_BY_COMPANY = gql`
  mutation CREATE_HOLIDAY_BY_COMPANY(
    $date: String!
    $description: String!
    $companyId: String!
  ) {
    createHolidayByCompany(
      date: $date
      description: $description
      companyId: $companyId
    )
  }
`;

export const REMOVE_HOLIDAY_BY_COMPANY = gql`
  mutation REMOVE_HOLIDAY_BY_COMPANY($list: [deleteHolidayInput!]!) {
    removeHolidayByCompany(list: $list)
  }
`;

export const UPDATE_HOLIDAY_BY_ID = gql`
  mutation UPDATE_HOLIDAY_BY_ID(
    $id: String!
    $description: String
    $date: String
  ) {
    udpateHolidayByCompany(id: $id, description: $description, date: $date)
  }
`;
