

import React,  { useContext } from 'react';
import { TabsPtoFragment } from './styles';
import { AppContext } from '../../Context/App/AppContext';
import {PermissionsEmployee } from '../../services/Constants/getDefaults';

const TabsPto = ({ tabActive, setTabActive, nameTabs = ['Pending Approval' ,'Requested' ] }) => {
 
const {roleUser} = useContext(AppContext);
  return (
    <TabsPtoFragment>
  <div className="tabs-pto">

      <div className="mobile-tab">
        <div onClick={() => setTabActive(0)} className= { tabActive === 0 ? "pto-title-selected" :  "pto-title" }>
          <span>{nameTabs[0]}</span>
        </div>
      </div> 

      { nameTabs[1] !== 'Company Teams' ?
    
    <div className="mobile-tab">
      <div onClick={() => setTabActive(1)} className= { tabActive === 1 ? "pto-title-selected" :  "pto-title" }>
        <span>{nameTabs[1]}</span>
      </div>
    </div> :null
      }

      { ( ( roleUser ===  PermissionsEmployee.OWNER ) &&  ( nameTabs[1] === 'Company Teams' ) ) || 
        ( ( roleUser ===  PermissionsEmployee.ADMIN ) &&  ( nameTabs[1] === 'Company Teams' ) ) ||
        ( ( roleUser ===  PermissionsEmployee.ADMIN_EMPLOYEE ) && ( nameTabs[1] === 'Company Teams'  ) )   
      ?
    
      <div className="mobile-tab">
        <div onClick={() => setTabActive(1)} className= { tabActive === 1 ? "pto-title-selected" :  "pto-title" }>
          <span>{nameTabs[1]}</span>
        </div>
      </div> :null
     }


    
  </div>
  
    </TabsPtoFragment>
  );
};

export default TabsPto;
