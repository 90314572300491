import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { DropdownCheckboxContainer } from './styles';
import ArrowDown from '../Icons/ArrowDown';

const DropdownCheckbox = ({
  data = [],
  setData,
  classButton,
  label = 'Add Team'
}) => {
  const [columns, setColumns] = useState(data);

  useEffect(() => {
    setColumns(data);
  }, [data]);

  const onSetColumns = item => {
    setColumns(prev => {
      const colTable = prev;
      colTable.forEach(col => {
        if (col.name === item.name) {
          col.active = !item.active;
        }
      });
      return [...colTable];
    });
  };

  return (
    <DropdownCheckboxContainer>
      <Dropdown>
        <Dropdown.Toggle className={classButton || 'btn-success'}>
          <span className="pad-icon">Add teams</span>
          <ArrowDown />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <ul className="list-group">
            {columns.map((item, i) => {
              return (
                <Dropdown.Item
                  key={`${item.name}-${i}`}
                  onClick={e => {
                    e.stopPropagation();
                    onSetColumns(item);
                  }}
                  disabled={item?.isManager}
                >
                  <FormControlLabel
                    control={<Checkbox color="default" checked={item.active} />}
                    label={` ${item.name}`}
                    labelPlacement="start"
                  />
                </Dropdown.Item>
              );
            })}
          </ul>
          <Dropdown.Item className="btn-apply">
            <Button className="btn-success" onClick={() => setData(columns)}>
              {label}
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </DropdownCheckboxContainer>
  );
};

export default DropdownCheckbox;
