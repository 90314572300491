import React from 'react';

const Check = ({ color = '#24292E', width = '16', height = '16' }) => {
  return (

  <svg
    width={width}
    height={height}
    viewBox="0 0 16 12" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >

    <path
    d="M15 1L5.375 10.625L1 6.25" 
    stroke={color}
    strokeWidth="1.125"
    strokeLinecap="round"
    strokeLinejoin="round"
    />

</svg>
 
  );
};

export default Check;
