import styled from 'styled-components';

export const HeaderTeams = styled.div`
  .header-w{
    display:flex;
    justify-content: flex-end;
    margin: 15px;
    margin-top: 30px;
    margin-bottom: 17px
  }
  .btnPagination {
    cursor: pointer;
    font-size: 18px;
    margin-right: 22px;
  }
  .buttons-paginations {
    display: flex;
    margin-top: 3px;
  }
  .select-pagination{
    border-radius: 5px;
    border-color: #D1D5DA;
    width:64px;
    height:36px;
    appearance: none;
    background-image: url("/images/icons/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.9rem auto;
    padding-left: 9px;
    color: #24292e;
  }
  .text-header{
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    margin-right: 22px;
   }
  }
`;

export const ContentTeams = styled.div`
  border-radius: 10px;
  background: #ffffff;
  padding-bottom: 30px;
  height: auto;
  overflow: hidden;

  .teams-table {
    table {
      tbody {
        td:nth-child(1) {
          .title-teams {
            span {
              padding-left: 10px;
            }
          }
          width: 30%;
        }
        td:nth-child(2) {
          width: 20%;
        }
        td:nth-child(3) {
          width: 30%;
        }
        td:nth-child(4) {
          padding: 7px 30px 7px 40px;
          div {
            border-left: 1px solid #959da5;
            padding-left: 12px;
          }
          width: 4%;
        }
      }
    }
  }

  .teams-table {
    table {
      tbody {
        td {
          padding-left: 20px;
        }
      }
    }
  }

  .permission-header {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sub-heading {
      font-weight: 600;
      margin: 0;
    }

    .content-search {
      width: calc(80% - 15px);
    }

    > div:nth-child(1) {
      display: inline-block;
    }

    > div:nth-child(2) {
      text-align: left;
      display: inline-block;

      .input-group-text {
        background-color: #ffffff;
        border-right: none;
      }

      .form-control {
        border-left: none;
      }
    }

    > div:nth-child(3) {
      .btn-success {
        width: 141px;
        height: 36px;
      }
      text-align: right;
      width: 100% button {
        display: inline-block;
        padding: 8px 10px;
        border: none;
        line-height: 20px;
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        span {
          color: #24292e;
        }
      }
    }
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 250px;
    margin-left: 20px;
    height: 36px;
  }

  .number-pto {
    background: #d73a49;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-left: 12px;
  }

  .noButtonStyle {
    font-weight: 400;
    color: #24292e;
  }

  .row-data {
    color: #24292e;
  }

  .noData {
    width: 100%;
    text-align: center;
    height: 600px;
    padding: 250px;

    svg {
      margin-bottom: 20px;
    }
  }
`;
